import { each, isEmpty, isNil } from "lodash";
import { SensorEventSubscriber } from "../channels/sensor_data_channel";
import { WidgetController } from "../controller/widget_controller";
/** Subscribe to sensor updates for the given sensor IDs and registers the subscribers.
 *
 *
 * @export
 * @param {SensorEventSubscriber} caller
 * @param {number[]} sensorIds
 * @return {*}  {void}
 */
export function registerSensorUpdates(
  caller: SensorEventSubscriber,
  sensorIds: number[],
): number[] | undefined {
  const instance = WidgetController.getInstance();
  if (isNil(instance)) {
    return;
  }

  return sensorIds.map((sensorId) => {
    return instance.sensorDataChannel.addEventListener(caller, sensorId);
  });
}

export function unregisterSensorUpdates(
  caller: SensorEventSubscriber,
  sensorIds: number[],
): void {
  const instance = WidgetController.getInstance();
  if (isNil(instance)) {
    return;
  }

  each(sensorIds, (sensorId) => {
    instance.sensorDataChannel.removeEventListener(caller, sensorId);
  });
}

export function unregisterSensorUpdatesForSubscriberIds(
  subscriberIds: number[],
  sensorIds: number[],
): void {
  const instance = WidgetController.getInstance();
  if (isNil(instance) || isEmpty(subscriberIds)) {
    return;
  }

  each(subscriberIds, (subscriberId) => {
    each(sensorIds, (sensorId) => {
      instance.sensorDataChannel.removeEventListenerId(subscriberId, sensorId);
    });
  });
}

import { isNil } from "lodash";
import * as React from "react";

import { Box, Grid, Tooltip } from "@mui/material";
import {
  ChartStatType,
  ChartStatistics,
  chartStatisticsTextLine,
} from "../chart_data/chart_data_statistics";
import { Circle } from "@mui/icons-material";
import { useDebounce } from "@uidotdev/usehooks";

export interface ChartStatisticsDisplayProps {
  chartStatistics: ChartStatistics[];
  color: (index: number) => string;
}

interface StatsLineProps {
  statistics: ChartStatistics;
  statsName: ChartStatType;
  label: string;
  unit?: boolean;
  digits?: number;
}
const StatsLine: React.FunctionComponent<StatsLineProps> = ({
  statistics,
  statsName,
  label,
  digits = 3,
  unit = true,
}) => {
  return (
    <Box component="span" ml={0.5}>
      <Box component="span" mr="2px" fontWeight="bold">
        {label}:
      </Box>
      {chartStatisticsTextLine(
        statsName,
        statistics,
        digits,
        unit ? statistics.unit : "",
      )}
    </Box>
  );
};

export const ChartStatisticsDisplay: React.FunctionComponent<
  ChartStatisticsDisplayProps
> = (props) => {
  return (
    <Grid container spacing={2}>
      {props.chartStatistics?.map((statistics, index) => {
        if (isNil(statistics)) {
          return null;
        } else {
          return (
            <Tooltip
              title={I18n.t("frontend.charts.statistics.tooltip", {
                name: statistics.name,
              })}
              key={index}
            >
              <Grid item xs={12} lg={6} key={index}>
                <Box component="span" ml={1}>
                  <Circle htmlColor={props.color(index)} fontSize="inherit" />
                </Box>
                <Box component="small" ml={1}>
                  <StatsLine
                    statistics={statistics}
                    statsName={"min"}
                    label={"Min"}
                  />
                  <StatsLine
                    statistics={statistics}
                    statsName={"max"}
                    label={"Max"}
                  />
                  <StatsLine
                    statistics={statistics}
                    statsName={"minMaxDiv"}
                    label={"∆"}
                  />
                  <StatsLine
                    statistics={statistics}
                    statsName={"average"}
                    label={"Ø"}
                  />
                  <StatsLine
                    statistics={statistics}
                    statsName={"count"}
                    label={"#"}
                    digits={0}
                    unit={false}
                  />
                </Box>
              </Grid>
            </Tooltip>
          );
        }
      })}
    </Grid>
  );
};

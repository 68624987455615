// FILE: sensor_data_loader.ts
import Bluebird, { CancellationError } from "bluebird";
import { isEmpty, isNil } from "lodash";
import moment, { Moment } from "moment";
import { SensorLoader } from "../../json_api/sensor_loader";
import { logger } from "../../utils/logger";

import { DateRange } from "moment-range";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { IDType } from "../../utils/urls/url_utils";
import { CancelledError } from "@tanstack/react-query";

export const loadSensorData = (
  sensorId: IDType,
  timeRange: DateRange,
  baseValue: number,
  calculationMode: string,
  fallbackToLastValue: boolean,
): Bluebird<{
  baseValue: number;
  value: number;
  timestamp: Moment;
  sensor: SensorJSONAPIAttributes;
} | null> => {
  return Bluebird.all([
    SensorLoader.getInstance().getSensors(sensorId),
    SensorLoader.getInstance().getLastValueWithin(
      sensorId,
      timeRange,
      fallbackToLastValue,
    ),
    calculationMode == "base_value_offset"
      ? null
      : SensorLoader.getInstance().getFirstValueWithin(
          sensorId,
          timeRange,
          fallbackToLastValue,
        ),
  ])
    .then(([sensors, sensorDataAttribute, baseValueSensorDataAttribute]) => {
      if (isNil(sensors) || isEmpty(sensors)) {
        throw new Error(`Requested sensors could not be fetched: ${sensorId}`);
      }
      const sensor = sensors[0];

      const newBaseValue =
        calculationMode === "base_value_offset"
          ? baseValue
          : (baseValueSensorDataAttribute.value as number);

      return {
        baseValue: newBaseValue,
        timestamp: moment(sensorDataAttribute.timestamp),
        value: sensorDataAttribute?.value as number,
        sensor,
      };
    })
    .catch(CancellationError, (): null => {
      logger.trace("Cancellation Error in Value Difference Widget");
      return null;
    })
    .catch(CancelledError, (): null => {
      logger.info("Sensor Fetching Cancelled");
      return null;
    })
    .catch((e): null => {
      logger.error(
        `Error loading sensor data ${sensorId} in Value Difference Widget`,
        e,
      );
      return null;
    });
};

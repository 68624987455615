import { ReduceStore } from "flux/utils";
import { isNil } from "lodash";

/**
 * Base interface for actions.
 */
export interface ActionWithType {
  type: string;
}

/**
 * Handler for reduce store actions.
 */
export interface ActionHandler<TState, TAction extends ActionWithType> {
  (state: TState, action: TAction): TState;
}

/**
 * A reduce store that automatically calls a reduce method with the
 * same name as the action e.g.
 * An action { type: 'FOO_BAR' } is handled by store.FOO_BAR(state, action) method.
 */
export abstract class AutoDispatchReduceStore<
  TState,
  TAction extends ActionWithType,
> extends ReduceStore<TState, TAction> {
  reduce(state: TState, action: TAction): TState {
    const actionHandler = this.getActionHandler(action.type);

    if (isNil(actionHandler)) {
      // handle unknown actions
      return state;
    }

    return actionHandler.call(this, state, action);
  }

  private getActionHandler(actionType: string): ActionHandler<TState, TAction> {
    return (this as any)[actionType];
  }
}

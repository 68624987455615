import { PlotlyChart } from "../components/common/plotly_chart";
import {
  SensorValueRange,
  ValueRangeStatus,
} from "../models/sensor_value_range";
import { PlotlyFont } from "./plotly_styling";

import { defaultTo, isEmpty, isNil } from "lodash";
import * as React from "react";
import { ColorUtils } from "../utils/colors";
import { unitDisplayString } from "../utils/unit_conversion";
import { Plotly } from "./plotly_package";
import { Box } from "@mui/material";

export interface PlotlyGaugeChartProps {
  divId: string;
  value: number;
  maxWidth?: number | string;
  height?: number;
  range?: [number, number];
  unit?: string;
  delta?: number;
  deltaReference?: number;
  // d3 number format for value display
  numberFormat?: string;
  valueRanges?: SensorValueRange[];
  displayValueByTick?: boolean;
  trendValue?: number;
  axisType?: "linear" | "log";

  // if set to true the value ranges will not be used for drawing colored areas
  noStepIntervals?: boolean;
}

const valueRangeStatusToColor: Record<ValueRangeStatus, string> = {
  optimal: "#addd8e",
  normal: null,
  critical: "#fec44f",
  fatal: "#f03b20",
};

const INDICATION_BAR_WIDTH = 0.8;

function getPlotlyData(
  value: number,
  range: [number, number],
  valueRanges: SensorValueRange[],
  noStepIntervals: boolean,
  displayValueByTick: boolean,
  unit: string,
  trendValue?: number,
): Plotly.Data {
  let thresholdProps = {};
  const stepsProps = [];

  // Handle value ranges display
  if (valueRanges && noStepIntervals !== true) {
    for (const valueRange of valueRanges) {
      if (valueRange.status in valueRangeStatusToColor) {
        stepsProps.push({
          range: [valueRange.min, valueRange.max],
          color: defaultTo(
            valueRange.color,
            valueRangeStatusToColor[valueRange.status as ValueRangeStatus],
          ),
        });
      }
    }
  }

  if (displayValueByTick) {
    // Handle display of the value as bar or tick.
    thresholdProps = {
      value,
      line: { color: ColorUtils.getDefaultColorRgb(), width: 4 },
      thickness: INDICATION_BAR_WIDTH,
    };
  }
  let deltaReference = 0;

  if (!isNil(trendValue)) {
    // Set delta reference so that the displayed delta is equal to the trend value.
    deltaReference = value - trendValue;
  }

  let theUnitDisplayString = unitDisplayString(unit);
  if (!isEmpty(theUnitDisplayString)) {
    theUnitDisplayString = ` ${theUnitDisplayString}`;
  } else {
    theUnitDisplayString = null;
  }
  return {
    domain: { x: [0, 1], y: [0, 1] },
    gauge: {
      shape: "angular",
      borderwidth: 1,
      bgcolor: null,

      axis: {
        type: "linear",
        visible: true,
        range,
        ticksuffix: theUnitDisplayString,
      },
      bar: {
        color: displayValueByTick
          ? "#ffffff00"
          : ColorUtils.getDefaultColorRgb(),
      },

      steps: stepsProps,
      threshold: thresholdProps,
    },
    value,
    number: {
      suffix: theUnitDisplayString,
    } as Partial<Plotly.PlotNumber>,
    type: "indicator",

    delta: { reference: deltaReference } as Partial<Plotly.Delta>, // , increasing: { symbol: '+' }, decreasing: { symbol: '-' }
    mode: ("gauge+number" + (isNil(trendValue) ? "" : "+delta")) as
      | "gauge+number"
      | "gauge+number+delta",
  };
}

export const PlotlyGaugeChart: React.FunctionComponent<PlotlyGaugeChartProps> =
  function ({
    value,
    trendValue,
    maxWidth = 250,
    height = 100,
    range = [0, 100],
    axisType = "linear",
    noStepIntervals = false,
    valueRanges,
    displayValueByTick = false,
    unit,
    ...props
  }) {
    const heightValue = defaultTo(height, 150);
    let cssHeightValue;
    if (!isNil(heightValue)) {
      cssHeightValue = heightValue + 30;
    }

    const data = React.useMemo(() => {
      return [
        getPlotlyData(
          value,
          range,
          valueRanges,
          noStepIntervals,
          displayValueByTick,
          unit,
          trendValue,
        ),
      ];
    }, [value, range, valueRanges, noStepIntervals, displayValueByTick]);

    return (
      <Box maxWidth={maxWidth} margin="auto">
        <PlotlyChart
          divId={props.divId}
          data={data}
          layout={{
            height: heightValue,

            margin: {
              l: 50,
              t: 20,
              b: 10,
              r: 50,
              pad: 20,
            } as Plotly.Margin,

            font: PlotlyFont,
          }}
          config={{
            autosizable: true,
            responsive: true,
            displaylogo: false,
            displayModeBar: false,
            locale: I18n.locale,
          }}
        />
      </Box>
    );
  };

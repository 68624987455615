import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { SingleResourceDoc } from "jsonapi-typescript";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { SIALOGICApiResultMessage } from "../../json_api/messages";
import { OrganizationJSONObject } from "../../json_api/organization";
import { UserJSONObject } from "../../json_api/user";
import { UserGroupJSONObject } from "../../json_api/user_group";
import { Organization } from "../../models/organization";
import {
  add_user_organization_user_group_path,
  api_user_group_path,
  search_api_organization_users_path,
} from "../../routes";
import { loadDataFromUrl, sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";

import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { LoadingWrapper } from "../common/loading_wrapper";
import { UsersAutocomplete } from "../users/users_autocomplete";

export interface UserGroupsAddUserFormProps {
  ibox?: boolean;

  userGroupId: IDType;
  onSubmitPressed?: (user: UserJSONObject) => void;
  onAssignFinished?: (
    user: UserJSONObject,
    userGroup: UserGroupJSONObject,
    org: Organization,
  ) => void;
  onAssignFailed?: (
    user: UserJSONObject,
    userGroup: UserGroupJSONObject,
    org: Organization,
    error?: Error,
  ) => void;
  onCancelPressed?: () => void;
}

interface UserGroupsAddUserFormState {
  loading: boolean;
  user: UserJSONObject;
  organization?: Organization;
  userGroup?: UserGroupJSONObject;
}

export class UserGroupsAddUserForm extends React.Component<
  UserGroupsAddUserFormProps,
  UserGroupsAddUserFormState
> {
  static defaultProps = {
    loading: false,
    moveAsset: false,
  };
  constructor(props: UserGroupsAddUserFormProps) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      organization: null,
      userGroup: null,
    };
  }

  componentDidMount(): void {
    if (isNil(this.state.user)) {
      this.setState({ loading: true });
      void loadDataFromUrl<SingleResourceDoc<string, UserGroupJSONObject>>(
        api_user_group_path(this.props.userGroupId, {
          id: this.props.userGroupId,
          include: "organization",
          format: "json",
          _options: true,
        }),
      )
        .then((userGroupResponseDoc) => {
          const userGroup = jsonApiSingleResourceToFlatObject<
            UserGroupJSONObject & { organization?: OrganizationJSONObject }
          >(userGroupResponseDoc);
          this.setState({
            userGroup: userGroup,
            organization: userGroup.organization,
            loading: false,
          });
        })
        .catch((err: Error) => {
          this.setState({ loading: false });
          logger.logError(err);
        });
    }
  }

  handleSubmit(): void {
    if (this.props.onSubmitPressed) {
      this.props.onSubmitPressed(this.state.user);
      return;
    }

    this.setState({ loading: true }, () => {
      void this.requestAssign();
    });
  }

  async requestAssign(): Promise<void> {
    try {
      const result = await sendData<
        object,
        { status?: string; message?: string }
      >(
        add_user_organization_user_group_path(
          this.state.organization?.id,
          this.state.userGroup.id,
          this.state.user.id,
        ),
        null,
        "POST",
      );
      void toasts.success(
        defaultTo(
          result.message,
          I18n.t("frontend.process_successfully_completed"),
        ),
      );
      redirectTo();
    } catch (error) {
      void toasts.error(
        defaultTo(
          (error as SIALOGICApiResultMessage).message,
          I18n.t("frontend.process_failed"),
        ),
      );
      logger.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCancel(): void {
    if (this.props.onCancelPressed) {
      this.props.onCancelPressed();
      return;
    }

    redirectTo("back");
  }

  render(): React.ReactNode {
    const content = (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <LoadingWrapper
            size={"3x"}
            loading={this.state.loading || isNil(this.state.organization?.id)}
          >
            {this.state.loading || isNil(this.state.organization?.id) ? null : (
              <>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {I18n.t(
                        "frontend.user_groups.user_group_add_user_form.add_user_heading",
                        {
                          organization: this.state.organization?.name,
                          user_group: this.state.userGroup?.name,
                        },
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <UsersAutocomplete
                      disabled={isNil(this.state.organization)}
                      usersBaseUrl={
                        isNil(this.state.organization)
                          ? null
                          : search_api_organization_users_path(
                              this.state.organization?.id,
                              {
                                organization_id: toInteger(
                                  this.state.organization?.id,
                                ),
                                exclude_user_group_id: this.state.userGroup?.id,
                                _options: true,
                              },
                            )
                      }
                      variant="outlined"
                      label={I18n.t(
                        "frontend.user_groups.user_group_add_user_form.select_organization_user",
                        { organization: this.state.organization?.name },
                      )}
                      width={"100%"}
                      onSelect={(u) => this.setState({ user: u })}
                      inputId="users-autocomplete"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </LoadingWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonGroup>
                <Button
                  onClick={() => this.handleCancel()}
                  variant="outlined"
                  color="secondary"
                >
                  {I18n.t("frontend.cancel")}
                </Button>
                <Button
                  onClick={() => this.handleSubmit()}
                  disabled={this.state.loading || isNil(this.state.user)}
                  variant="outlined"
                  color="primary"
                >
                  {I18n.t("frontend.ok")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    if (this.props.ibox) {
      return (
        <IBox>
          <IBoxTitle>
            <Typography variant="h5">
              {isNil(this.state.organization)
                ? null
                : this.state.organization.name}
            </Typography>
          </IBoxTitle>
          <IBoxContent>{content}</IBoxContent>
        </IBox>
      );
    } else {
      <>
        <Grid item xs={12}>
          <Typography variant="h6">
            {isNil(this.state.organization)
              ? null
              : this.state.organization.name}
          </Typography>
        </Grid>
      </>;
      return content;
    }
  }
}

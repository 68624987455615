import { Add, KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { TreeItem } from "../../../models/tree_item";
import { getTranslatedProp } from "../../../utils/globalize";
import { MaintenancePlanIcon } from "../../common/maintenance/maintenance_plan_icon";
import { AssetWithIndex } from "../../common/select_asset_modal";
import { AssetIdentifier } from "../data/maintenance_plan_actions";
import { Asset, MaintenancePlan, MaintenanceType } from "../data/models";
import { MaintenancePlanListEntry } from "./maintenance_plan_list_entry";

interface MaintenancePlanListProperties {
  assets: AssetWithIndex[];
  maintenanceTypes: MaintenanceType[];
  asset: Asset;
  assetTree: TreeItem;
  index: number;
  onAddMaintenancePlan: () => void;
  onAddInspectionPlan: () => void;
  onRemoveMaintenancePlan: (maintenancePlanIndex: number) => void;
  onUpdateMaintenancePlan: (
    maintenancePlanIndex: number,
    maintenancePlan: MaintenancePlan,
  ) => void;
  onChangeMaintenancePlanOrder: (
    maintenancePlanIndex: number,
    direction: number,
  ) => void;
  onCopyMaintenancePlan: (
    assetIndices: AssetIdentifier[],
    maintenancePlan: MaintenancePlan,
  ) => void;
  onMoveMaintenancePlan: (
    maintenancePlanIndex: number,
    destAsset: AssetIdentifier,
  ) => void;
  onToggle: (assetIndex: number, open?: boolean) => void;
  isOpen?: boolean;
  visible?: boolean;
}

interface MaintenancePlanListState {}

/**
 * A list of maintenace plans of an asset.
 */
export class MaintenancePlanList extends React.Component<
  MaintenancePlanListProperties,
  MaintenancePlanListState
> {
  constructor(props: MaintenancePlanListProperties) {
    super(props);
  }

  shouldComponentUpdate(
    nextProps: MaintenancePlanListProperties,
    nextState: MaintenancePlanListState,
  ): boolean {
    return (
      this.props.index !== nextProps.index ||
      this.props.asset !== nextProps.asset ||
      this.props.maintenanceTypes !== nextProps.maintenanceTypes ||
      this.props.isOpen !== nextProps.isOpen ||
      this.props.assetTree !== nextProps.assetTree
    );
  }

  render(): React.ReactNode {
    return (
      <Box display={this.props.visible === false ? "none" : null}>
        <Accordion expanded={this.props.isOpen}>
          <AccordionSummary
            onClick={() => this.props.onToggle(this.props.index)}
            expandIcon={<KeyboardArrowDown />}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {getTranslatedProp(this.props.asset, "name")}
                </Typography>
              </Grid>
              <Grid item>
                <ButtonGroup size="small">
                  <Tooltip
                    title={I18n.t(
                      "frontend.maintenance_plan_form.add_maintenance_plan",
                    )}
                  >
                    <Button
                      className="add-maintenance-plan"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        // force open when maintenance plan is added
                        this.props.onToggle(this.props.index, true);
                        this.props.onAddMaintenancePlan();
                        event.stopPropagation();
                      }}
                      variant="outlined"
                      startIcon={<Add />}
                    >
                      <MaintenancePlanIcon maintenancePlanType="MaintenancePlan" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      "frontend.maintenance_plan_form.add_inspection_plan",
                    )}
                  >
                    <Button
                      className="add-inspection-plan mr-2"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        // force open when inspection plan is added
                        this.props.onToggle(this.props.index, true);
                        this.props.onAddInspectionPlan();
                        event.stopPropagation();
                      }}
                      variant="outlined"
                      startIcon={<Add />}
                    >
                      <MaintenancePlanIcon maintenancePlanType="InspectionPlan" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            {this.props.asset.maintenance_plans.length == 0 ? (
              <div className="m-2">
                {I18n.t("frontend.maintenance_plan_form.no_maintenance_plans")}
              </div>
            ) : null}
            {this.props.asset.maintenance_plans?.map(
              (maintenancePlan, maintenacePlanIndex, maintenancePlans) => {
                return (
                  <MaintenancePlanListEntry
                    assetTree={this.props.assetTree}
                    assets={this.props.assets}
                    maintenanceTypes={this.props.maintenanceTypes.filter(
                      (maintenanceType) =>
                        maintenanceType.asset_type_id ==
                        this.props.asset.asset_type_id,
                    )}
                    assetIndex={this.props.index}
                    index={maintenacePlanIndex}
                    isFirst={maintenacePlanIndex == 0}
                    isLast={maintenacePlanIndex == maintenancePlans.length - 1}
                    key={maintenacePlanIndex}
                    maintenancePlan={maintenancePlan}
                    onRemoveMaintenancePlan={() =>
                      this.props.onRemoveMaintenancePlan(maintenacePlanIndex)
                    }
                    onUpdateMaintenancePlan={(maintenancePlan) =>
                      this.props.onUpdateMaintenancePlan(
                        maintenacePlanIndex,
                        maintenancePlan,
                      )
                    }
                    onChangeMaintenancePlanOrder={(direction) =>
                      this.props.onChangeMaintenancePlanOrder(
                        maintenacePlanIndex,
                        direction,
                      )
                    }
                    onCopyMaintenancePlan={this.props.onCopyMaintenancePlan}
                    onMoveMaintenancePlan={(newAsset) =>
                      this.props.onMoveMaintenancePlan(
                        maintenacePlanIndex,
                        newAsset,
                      )
                    }
                  />
                );
              },
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

import { isFunction, isNil } from "lodash";
import * as React from "react";
import { Icon } from "../components/common/icon";
import { SensorValueType } from "../models/sensor";
import { SensorValueRange } from "../models/sensor_value_range";
type IconQueryFunction = (
  value: SensorValueType,
  sensorValueRange: SensorValueRange,
) => string;
const SENSOR_TYPE_ICON_NAMES: Record<string, IconQueryFunction | string> = {
  battery_level: function (value: number, sensorValueRange: SensorValueRange) {
    if (!isNil(value)) {
      if (value < 5) {
        return "battery-empty";
      } else if (value >= 5 && value <= 35) {
        return "battery-quarter";
      } else if (value >= 35 && value <= 65) {
        return "battery-half";
      } else if (value >= 65 && value <= 95) {
        return "battery-three-quarters";
      } else {
        return "battery-full";
      }
    } else {
      return "battery-full";
    }
  },
  temperature: "thermometer-full",
  current_level: "bolt",
  counter: "hashtag",
  voltage: "bolt",
  wind_speed: "wind",
  frequency: "wave-square",
  voltage_level: "bolt",
  distance: "ruler-horizontal",
  direction: "compass",
  precipitation: "cloud-rain",
  setting: "sliders",
  running_time_count: "clock",
  operating_time_count: "clock",
  resource_consumption: "shuffle",
  resource_level: "window-maximize",
  power_consumption: "bolt",
  velocity: "gauge",
  humidity: "smog",
  windspeed: "wind",
  weight: "weight-hanging",
  power_consumption_over_time: "bolt",
  resource_consumption_over_time: "shuffle",
};

const MEASUREMENT_TYPE_ICON_NAMES: Record<string, IconQueryFunction | string> =
  {
    battery_level: function (
      value: number,
      sensorValueRange: SensorValueRange,
    ) {
      if (!isNil(value)) {
        if (value < 5) {
          return "battery-empty";
        } else if (value >= 5 && value <= 35) {
          return "battery-quarter";
        } else if (value >= 35 && value <= 65) {
          return "battery-half";
        } else if (value >= 65 && value <= 95) {
          return "battery-three-quarters";
        } else {
          return "battery-full";
        }
      } else {
        return "battery-full";
      }
    },
    time: "clock",
    temperature: "thermometer-full",
    current: "bolt",
    cloud_coverage: "cloud",
    voltage: "bolt",
    wind_speed: "wind",
    frequency: "wave-square",
    distance: "ruler-horizontal",
    length: "ruler-horizontal",
    thickness: "ruler-horizontal",
    direction: "compass",
    precipitation: "cloud-rain",
    setting: "sliders",
    power: "bolt",
    number: "hashtag",
  };

export function getIconNameForSensorType(
  sensorTypeName: string,
  sensorValue: SensorValueType = null,
  sensorValueRange: SensorValueRange = null,
) {
  const mappedItem = SENSOR_TYPE_ICON_NAMES[sensorTypeName];
  if (isFunction(mappedItem)) {
    return mappedItem(sensorValue, sensorValueRange);
  } else {
    return mappedItem;
  }
}

export function getIconNameForMeasurementType(
  sensorTypeName: string,
  sensorValue: SensorValueType = null,
  sensorValueRange: SensorValueRange = null,
) {
  const mappedItem = MEASUREMENT_TYPE_ICON_NAMES[sensorTypeName];
  if (isFunction(mappedItem)) {
    return mappedItem(sensorValue, sensorValueRange);
  } else {
    return mappedItem;
  }
}

export function getIconForSensorType(
  sensorTypeName: string,
  sensorValue: SensorValueType = null,
  sensorValueRange: SensorValueRange = null,
): React.ReactNode {
  const iconName = getIconNameForSensorType(
    sensorTypeName,
    sensorValue,
    sensorValueRange,
  );

  return isNil(iconName) ? null : <Icon icon={iconName} />;
}

export function getIconForMeasurementType(
  measurementType: string,
  sensorValue: SensorValueType = null,
  sensorValueRange: SensorValueRange = null,
): React.ReactNode {
  const iconName = getIconNameForMeasurementType(
    measurementType,
    sensorValue,
    sensorValueRange,
  );

  return isNil(iconName) ? null : <Icon icon={iconName} />;
}
export function getIconForSensorTypeOrMeasurementType(
  sensorTypeName: string,
  measurementType: string,
  sensorValue: SensorValueType = null,
  sensorValueRange: SensorValueRange = null,
): React.ReactNode {
  return (
    getIconForSensorType(sensorTypeName) ??
    getIconForMeasurementType(measurementType, sensorValue, sensorValueRange)
  );
}

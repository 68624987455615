/// <reference types="../../definitions/index" />
import { defaultTo, isNil } from "lodash";
import { EventNotification } from "../../models/event_notification";
import { getTranslatedProp } from "../../utils/globalize";

/**
 * Request permission to send desktop notifications
 * @return A promise to notification permission.
 * The promise is rejected if the browser does not support notifications.
 */
export function requestDesktopNotifications(): Promise<NotificationPermission | void> {
  try {
    const DesktopNotification = window.Notification;

    // Let's check if the browser supports notifications
    if (isNil(DesktopNotification)) {
      return Promise.reject(
        "This browser does not support desktop notification",
      );
    }

    // Let's check whether notification permissions have already been granted
    if (DesktopNotification.permission === "granted") {
      return Promise.resolve(DesktopNotification.permission);
    }

    // check if browser supports new Notification()
    try {
      new DesktopNotification("");
    } catch (e: any) {
      if ((e as Error).name == "TypeError") {
        return Promise.reject(
          "This browser does not support desktop notification",
        );
      }
    }

    // Request user permission to use desktop notifications
    const returnValuePromise = new Promise<NotificationPermission | void>(
      (resolve, reject) => {
        try {
          let resolveCalled = false;
          // for safari requires a callback to be passed which is actually deprecated and it does not return a promise!!
          const theReturnValue = DesktopNotification.requestPermission(
            (result) => {
              resolveCalled = true;
              resolve(result);
            },
          );

          if (!resolveCalled) {
            void theReturnValue.then(resolve);
          }
        } catch (e) {
          reject(e);
        }
      },
    );
    return returnValuePromise;
  } catch (e) {
    // function should never raise errors
    return Promise.reject(e);
  }
}

/**
 * Create a desktop notification.
 * Returns null if notifications are not supported or not permitted by the user.
 * @param message The push message to show.
 */
export function createDesktopNotification(
  message: EventNotification,
): Notification {
  try {
    const DesktopNotification = window.Notification;

    // do nothing if user disabled desktop notifications or notifications are not supported
    if (
      isNil(DesktopNotification) ||
      DesktopNotification.permission !== "granted"
    ) {
      return null;
    }
    const messageText = defaultTo(getTranslatedProp(message, "name"), "");
    const messageDescription = defaultTo(
      getTranslatedProp(message, "description"),
      "",
    );

    // create a desktop notification from event
    const notification = new DesktopNotification(messageText, {
      tag: `event_${message.event_id}`,
      lang: gon.locale,
      body: messageDescription,
      icon: gon.notificationIcon,
      badge: gon.notificationIcon,
    });

    notification.onclick = (event) => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open(message.url, "_blank");
    };

    return notification;
  } catch (error: any) {
    // catch new Notification not supported errors
    if ((error as Error).name == "TypeError") {
      return null;
    }

    throw error;
  }
}

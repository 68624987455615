import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { ValueRangeStatus } from "../../models/sensor_value_range";
import { getStatusClassForValueRange } from "../../utils/status_helper";
import { getValueString } from "../../utils/value_format";

export interface PercentageBarProps {
  value?: number;
  max?: number;
  min?: number;
  timestamp?: string;

  showValue?: boolean;

  unit?: string;
  status?: ValueRangeStatus;
  color?: string;
  precision?: number;
}
const normalise = (value: number, min: number, max: number) =>
  ((value - min) * 100) / (max - min);

export const PercentageBar: React.FunctionComponent<PercentageBarProps> = (
  props,
) => {
  const max = isNil(props.max) ? 100 : props.max;
  const min = isNil(props.min) ? 0 : props.min;

  const showValue = isNil(props.showValue) ? true : props.showValue;
  const unit = isNil(props.unit) ? "%" : props.unit;
  const statusClass = getStatusClassForValueRange(props.status);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={normalise(props.value, min, max)}
              color={statusClass ? "error" : "primary"}
              sx={{ color: props.color, marginY: "auto" }}
            />
          </Box>
          {showValue ? (
            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant="caption"
                sx={{ marginY: "auto" }}
                className={statusClass ? "value text-" + statusClass : "value"}
              >
                {getValueString(props.value, defaultTo(props.precision, 2))}
              </Typography>
              <span className="unit ml-1">{unit}</span>
            </Box>
          ) : null}
        </Box>
      </Grid>
      {isNil(props.timestamp) ? null : (
        <Grid item xs={12}>
          <small className="timestamp">{props.timestamp}</small>
        </Grid>
      )}
    </Grid>
  );
};

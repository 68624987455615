import { DateRange } from "moment-range";

import { isNil } from "lodash";
import {
  ChartAnnotationOptions,
  buildEqualityLines,
  buildRangeLines,
  buildSingleValueLines,
} from "./chart_data/line_diagram_annotation_builder";
import { ChartData } from "./plotly_time_series_line_diagram_base.types";

export function buildAnnotationsForLineDiagram(
  annotationOptions: Partial<ChartAnnotationOptions>,
  timeRange: DateRange,
): ChartData[] {
  if (isNil(annotationOptions) || annotationOptions.showMinMax == false) {
    return [];
  }

  switch (annotationOptions.annotationMode) {
    case "inside range":
    case "outside range":
      return buildRangeLines(annotationOptions, timeRange);
    case "<":
    case "<=":
    case ">=":
    case ">":
      return buildSingleValueLines(annotationOptions, timeRange);
    case "!=":
    case "==":
      return buildEqualityLines(annotationOptions, timeRange);
  }
  return [];
}

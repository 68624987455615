import {
  Box,
  Button,
  ButtonGroup,
  CardActions,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  GridSize,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  Close,
  Computer,
  Done,
  Speed,
  Wifi,
  WifiOff,
} from "@mui/icons-material";

import * as JSONAPI from "jsonapi-typescript";
import {
  defaultTo,
  defaults,
  isEmpty,
  isNil,
  map,
  sortBy,
  toInteger,
  toNumber,
  toString,
} from "lodash";
import * as React from "react";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
} from "../../json_api/jsonapi_tools";
import { SensorJSONObject } from "../../json_api/sensor";
import { SensorTypeJSONObject } from "../../json_api/sensor_type";
import { Asset } from "../../models/asset";
import { samplingRateFromString } from "../../models/sensor";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
import { SensorValueRange } from "../../models/sensor_value_range";
import { HttpError } from "../../utils/jquery_helper";
import { JSONApiFormRequestMode } from "../../utils/jsonapi_form_tools";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";
import { sensorUrl } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { Icon, LoadingIcon } from "../common/icon";
import { LoadingWrapper } from "../common/loading_wrapper";
import {
  SAMPLING_RATE_SELECT_NO_SAMPLING_VALUE,
  SamplingRateSelect,
} from "../diagram_settings/sampling_rate_select";
import { useLoadSensorTypesQuery } from "../sensor_types/sensor_types_data";
import { useCreateSensor, useUpdateSensor } from "./sensor_data";

export type SensorFormMode = "read" | JSONApiFormRequestMode;

export type SensorErrors = ModelErrors<SensorJSONObject>;

export interface SensorFormProps {
  sensor: SensorJSONObject;
  asset?: Partial<Asset>;
  sensorTypes?: SensorTypeJSONObject[];
  buttonPosition?: "card" | "bottom";
  readOnly?: boolean;
  mode?: SensorFormMode;
  onCancel?: () => void;
  onSubmit?: (sensor: SensorJSONObject) => Promise<unknown>;
  onFinishSave?: (sensor: SensorJSONObject) => void;
}

function createColorBox(color: string): React.ReactElement {
  return (
    <Box
      sx={{
        ...{
          bgcolor: color,
          width: "1.5em",
          height: "1.5em",
          borderRadius: "1.5em",
        },
      }}
      marginX="2"
    >
      &nbsp;
    </Box>
  );
}

function createTextBox(text: string): React.ReactElement {
  return <Typography variant="h6">{text}</Typography>;
}

export const SensorForm: React.FunctionComponent<SensorFormProps> = ({
  readOnly = false,
  buttonPosition = "bottom",
  ...props
}) => {
  const context = React.useContext(AppContext);
  const [sensorData, setSensorData] = React.useState<SensorJSONObject>(
    defaults(props.sensor, { asset_id: props?.asset?.id }),
  );

  const [sensorTypes, setSensorTypes] = React.useState<SensorTypeJSONObject[]>(
    sortBy(props.sensorTypes, (st) => getSensorTypeNameTranslation(st.name)),
  );
  const [isProcessing, setIsProcessing] = React.useState(false);

  const {
    isLoading: sensorTypesLoading,
    data: { items: loadedSensorTypes },
  } = useLoadSensorTypesQuery({
    variables: {},
    placeholderData: {
      items: sensorTypes,
      totalItems: sensorTypes.length,
      totalPages: -1,
    },
  });

  React.useEffect(() => {
    setIsProcessing(sensorTypesLoading);
  }, [sensorTypesLoading]);

  React.useEffect(() => {
    setSensorTypes(loadedSensorTypes);
  }, [loadedSensorTypes]);

  const { mutateAsync: updateSensor } = useUpdateSensor();
  const { mutateAsync: createSensor } = useCreateSensor();

  const [sensorErrors, setSensorErrors] = React.useState<SensorErrors>({});

  const [saveEnabled, setSaveEnabled] = React.useState(true);

  const handleSubmit = React.useCallback(
    async (props: SensorFormProps, sensorData: SensorJSONObject) => {
      setIsProcessing(true);
      try {
        if (props.onSubmit) {
          await props.onSubmit(sensorData);
        } else {
          let sensor: SensorJSONObject;
          if (props.mode == "create") {
            sensor = await createSensor(sensorData);
          } else if (props.mode == "update") {
            sensor = await updateSensor(sensorData);
          }

          await success(
            I18n.t("frontend.success"),
            I18n.t("frontend.sensors.form.saved_successfully"),
          );
          onFinishSave(sensor, props);
        }
      } catch (e) {
        setSensorErrors(
          handleError(
            (e as HttpError).request?.responseJSON as JSONAPI.DocWithErrors,
          ),
        );
      } finally {
        setIsProcessing(false);
      }
      return;
    },
    [],
  );
  React.useEffect(() => {
    if (isProcessing) {
      setSaveEnabled(false);
      return;
    }
    if (isNil(sensorData.key) || isNil(sensorData.sensor_type_id)) {
      setSaveEnabled(false);
    }

    setSaveEnabled(true);
  }, [isProcessing, sensorData]);
  const btnPos = defaultTo(buttonPosition, "card");

  const commonErrorStyle = {
    bgcolor: "#FFCCCC",
    color: "#666",
    fontSize: "0.8rem",
    border: 1,
    borderRadius: 5,
  };

  const createErrorBox = React.useCallback(
    (errorKey: keyof SensorJSONObject): React.ReactElement => {
      if (isEmpty(sensorErrors[errorKey])) return null;
      return (
        <Box sx={{ ...commonErrorStyle }} mt={"2px"}>
          <ul>
            {map(sensorErrors[errorKey], (e, index) => (
              <li key={`${errorKey}_${index}`}>{e}</li>
            ))}
          </ul>
        </Box>
      );
    },
    [sensorErrors],
  );

  const createFormElement = React.useCallback(
    (
      att: keyof SensorJSONObject,
      required = false,
      gridSize: GridSize = 12,
    ) => {
      return (
        <Grid item xs={gridSize} container key={att}>
          <Grid item xs={12}>
            <TextField
              id={att as string}
              required={required}
              value={toString(sensorData[att])}
              fullWidth={true}
              disabled={isProcessing}
              onChange={(e) => {
                const d = {
                  ...sensorData,
                };
                d[att] = e.target.value;
                setSensorData(d);
              }}
              error={!isEmpty(sensorErrors[att])}
              label={I18n.t(`activerecord.attributes.sensor.${att}`)}
            />
          </Grid>
          <Grid item xs={12}>
            {createErrorBox(att)}
          </Grid>
        </Grid>
      );
    },
    [sensorData, isProcessing, sensorErrors],
  );

  return (
    <>
      <IBox>
        <IBoxTitle>
          <h5>{I18n.t("frontend.sensors.edit")}</h5>
        </IBoxTitle>
        <IBoxContent>
          <LoadingWrapper loading={isProcessing}>
            {isProcessing ? null : (
              <>
                <form noValidate autoComplete="off">
                  <Grid container spacing={2}>
                    {isEmpty(sensorErrors) ? null : (
                      <Grid item xs={12}>
                        <Box sx={{ ...commonErrorStyle }} p={1}>
                          {I18n.t("base.errors")}:{" "}
                          {I18n.t("base.please_check_entries")}
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12} lg={6}>
                      <FormControlLabel
                        style={{ pointerEvents: "none" }}
                        control={
                          <Switch
                            size="small"
                            style={{ pointerEvents: "auto" }}
                            id="import_enabled"
                            checked={sensorData.enabled}
                            disabled={isProcessing}
                            onChange={(e) =>
                              setSensorData({
                                ...sensorData,
                                enabled: e.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label={
                          sensorData.enabled ? (
                            <>
                              <span className="mr-1">
                                <Wifi />
                              </span>
                              {I18n.t(
                                "frontend.sensors.form.enabled.import_active",
                              )}
                            </>
                          ) : (
                            <>
                              <span className="mr-1">
                                <WifiOff />
                              </span>
                              {I18n.t(
                                "frontend.sensors.form.enabled.import_disabled",
                              )}
                            </>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            id="derivedSwitch"
                            checked={sensorData.derived == true}
                            disabled={isProcessing}
                            onChange={(e) =>
                              setSensorData({
                                ...sensorData,
                                derived: e.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label={
                          sensorData.derived ? (
                            <>
                              <span className="mr-1">
                                <Computer />
                              </span>
                              {I18n.t(
                                "activerecord.attributes.sensor.computed",
                              )}
                            </>
                          ) : (
                            <>
                              <span className="mr-1">
                                <Speed />
                              </span>
                              {I18n.t(
                                "activerecord.attributes.sensor.measured",
                              )}
                            </>
                          )
                        }
                        checked
                      />
                    </Grid>
                    {[
                      ["name", true],
                      ["short_name", true],
                      ["manufacturer", false],
                      ["serial", false],
                    ].map(([att, required]) =>
                      createFormElement(
                        att as keyof SensorJSONObject,
                        required as boolean,
                        6,
                      ),
                    )}
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        <TextField
                          multiline={true}
                          fullWidth={true}
                          id="description"
                          value={toString(sensorData.description)}
                          label={I18n.t(
                            "activerecord.attributes.sensor.description",
                          )}
                          error={!isEmpty(sensorErrors.description)}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              description: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("description")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6} container>
                      <Grid item xs={12}>
                        <FormControl
                          required
                          fullWidth={true}
                          error={!isEmpty(sensorErrors.sensor_type)}
                          disabled={isProcessing}
                        >
                          <InputLabel id="sensor-type-label">
                            {I18n.t(
                              "activerecord.attributes.sensor.sensor_type",
                            )}
                          </InputLabel>

                          <Select
                            fullWidth={true}
                            id="sensorType"
                            required={true}
                            value={toString(sensorData.sensor_type_id)}
                            label={I18n.t(
                              "activerecord.attributes.sensor.sensor_type",
                            )}
                            onChange={(e) => {
                              setSensorData({
                                ...sensorData,
                                sensor_type_id: toNumber(e.target.value),
                              });
                            }}
                          >
                            {map(sensorTypes || [], (t) => (
                              <MenuItem value={toString(t.id)} key={t.id}>
                                {getSensorTypeNameTranslation(t.name)}
                                {t.measurement_type
                                  ? ` (${I18n.t(
                                      `activerecord.attributes.sensor_type.measurement_types.${t.measurement_type}`,
                                      { defaultValue: t.measurement_type },
                                    )})`
                                  : null}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("sensor_type")}
                        {createErrorBox("sensor_type_id")}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} container spacing={2}>
                      <Grid item xs={12} lg={6} container>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            id="sensorContext"
                            value={toString(sensorData.sensor_context)}
                            label={I18n.t(
                              "activerecord.attributes.sensor.context",
                            )}
                            error={!isEmpty(sensorErrors.sensor_context)}
                            onChange={(e) => {
                              setSensorData({
                                ...sensorData,
                                sensor_context: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {createErrorBox("sensor_context")}
                          {createErrorBox("context")}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6} container>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={true}
                            id="sensorContext2"
                            value={toString(sensorData.sensor_context2)}
                            label={I18n.t(
                              "activerecord.attributes.sensor.context2",
                            )}
                            error={!isEmpty(sensorErrors.sensor_context2)}
                            onChange={(e) => {
                              setSensorData({
                                ...sensorData,
                                sensor_context2: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {createErrorBox("sensor_context2")}
                          {createErrorBox("context2")}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="key"
                          required={true}
                          value={toString(sensorData.key)}
                          fullWidth={true}
                          disabled={isProcessing}
                          error={
                            !isEmpty(sensorErrors.key) ||
                            !isEmpty(sensorErrors["attribute_key.key"])
                          }
                          helperText={I18n.t(
                            "activerecord.help_text.attribute_key.key",
                          )}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              key: e.target.value,
                            })
                          }
                          label={I18n.t(
                            "activerecord.attributes.attribute_key.key",
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("attribute_key") /* new */}
                        {createErrorBox("attribute_key.key") /* edit */}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="exp_data_period"
                          required={false}
                          type="number"
                          value={toString(sensorData.exp_data_period)}
                          fullWidth={true}
                          disabled={isProcessing}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.exp_data_period",
                          )}
                          error={!isEmpty(sensorErrors.exp_data_period)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">ms</InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              exp_data_period: toInteger(e.target.value),
                            })
                          }
                          label={I18n.t(
                            "activerecord.attributes.sensor.exp_data_period",
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("exp_data_period")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {createTextBox(
                        I18n.t(
                          "frontend.manage_sensor_data_form.units_and_precisions",
                        ),
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="import_unit"
                          fullWidth={true}
                          disabled={isProcessing}
                          value={toString(sensorData.attribute_key_unit)}
                          helperText={I18n.t(
                            "activerecord.help_text.attribute_key.unit",
                          )}
                          error={!isEmpty(sensorErrors["attribute_key.unit"])}
                          label={I18n.t(
                            "activerecord.attributes.attribute_key.unit",
                          )}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              attribute_key_unit: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("attribute_key.unit")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="unit"
                          fullWidth={true}
                          value={toString(sensorData.display_unit)}
                          required={false}
                          disabled={isProcessing}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.display_unit",
                          )}
                          label={I18n.t(
                            "activerecord.attributes.sensor.display_unit",
                          )}
                          error={!isEmpty(sensorErrors.display_unit)}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              display_unit: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("display_unit")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="import_precision"
                          type="number"
                          fullWidth
                          disabled={isProcessing}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.import_precision",
                          )}
                          value={defaultTo(sensorData.import_precision, "")}
                          label={I18n.t(
                            "activerecord.attributes.sensor.import_precision",
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {I18n.t("base.digits")}
                              </InputAdornment>
                            ),
                          }}
                          error={
                            !isEmpty(sensorErrors.import_precision) ||
                            !isEmpty(sensorErrors["attribute_key.precision"])
                          }
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              import_precision: toInteger(e.target.value),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("import_precision")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="display_precision"
                          type="number"
                          fullWidth
                          disabled={isProcessing}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.precision",
                          )}
                          value={defaultTo(sensorData.precision, "")}
                          label={I18n.t(
                            "activerecord.attributes.sensor.precision",
                          )}
                          error={!isEmpty(sensorErrors.precision)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {I18n.t("base.digits")}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              precision: toInteger(e.target.value),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("precision")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {createTextBox(
                        I18n.t("activerecord.attributes.sensor.sampling_rate"),
                      )}
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid item xs={12} lg={6}>
                        <SamplingRateSelect
                          samplingRate={
                            isNil(sensorData.sampling_rate_unit) ||
                            isNil(sensorData.sampling_rate_value)
                              ? null
                              : `${sensorData.sampling_rate_value} ${sensorData.sampling_rate_unit}`
                          }
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.sampling_rate",
                          )}
                          selectAggregateFunction={false}
                          onChange={(samplingRateString) => {
                            const samplingRate =
                              samplingRateString ==
                              SAMPLING_RATE_SELECT_NO_SAMPLING_VALUE
                                ? null
                                : samplingRateFromString(samplingRateString);

                            if (isNil(samplingRate)) {
                              setSensorData({
                                ...sensorData,
                                sampling_rate_unit: null,
                                sampling_rate_value: null,
                              });
                            } else {
                              setSensorData({
                                ...sensorData,
                                sampling_rate_unit: samplingRate.unit,
                                sampling_rate_value: samplingRate.value,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("sampling_rate_unit")}
                        {createErrorBox("sampling_rate_value")}{" "}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {createTextBox(
                        I18n.t(
                          "activerecord.attributes.sensor.total_value_range",
                        ),
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="total_value_min"
                          type="number"
                          fullWidth
                          value={defaultTo(
                            sensorData.total_value_range?.min,
                            "",
                          )}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.total_value_min",
                          )}
                          error={
                            !isEmpty(sensorErrors["total_value_range.min"])
                          }
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              total_value_range: {
                                ...sensorData.total_value_range,
                                min: toInteger(e.target.value),
                              },
                            })
                          }
                          disabled={isProcessing}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {isEmpty(sensorData.display_unit)
                                  ? toString(sensorData.attribute_key_unit)
                                  : sensorData.display_unit}
                              </InputAdornment>
                            ),
                          }}
                          label={I18n.t(
                            "activerecord.attributes.sensor_value_range.min",
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("total_value_range.min")}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextField
                          id="total_value_max"
                          type="number"
                          value={defaultTo(
                            sensorData.total_value_range?.max,
                            "",
                          )}
                          helperText={I18n.t(
                            "activerecord.help_text.sensor.total_value_max",
                          )}
                          fullWidth
                          disabled={isProcessing}
                          error={
                            !isEmpty(sensorErrors["total_value_range.max"])
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {isEmpty(sensorData.display_unit)
                                  ? toString(sensorData.attribute_key_unit)
                                  : sensorData.display_unit}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setSensorData({
                              ...sensorData,
                              total_value_range: {
                                ...sensorData.total_value_range,
                                max: toInteger(e.target.value),
                              },
                            })
                          }
                          label={I18n.t(
                            "activerecord.attributes.sensor_value_range.max",
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {createErrorBox("total_value_range.max")}
                      </Grid>
                    </Grid>

                    {isEmpty(sensorData.value_ranges) ? null : (
                      <>
                        <Grid item xs={12}>
                          {createTextBox(
                            I18n.t("activerecord.models.sensor_value_range", {
                              count: 2,
                            }),
                          )}
                        </Grid>
                        {sensorData.value_ranges?.map((vr) => {
                          const value_range = vr as SensorValueRange;
                          return (
                            <Grid
                              item
                              xs={12}
                              xl={6}
                              container
                              key={`value_range_${value_range.id as number}`}
                            >
                              <Grid
                                item
                                xs={1}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                {isNil(value_range.icon_name) ? null : (
                                  <Icon icon={value_range.icon_name} />
                                )}
                              </Grid>
                              <Grid item xs={1}>
                                {createColorBox(
                                  defaultTo(value_range.color, "#ffffffff"),
                                )}
                              </Grid>
                              <Grid item xs={3}>
                                <b>
                                  {I18n.t(
                                    "activerecord.attributes.sensor_value_range.min",
                                  )}
                                  {": "}
                                </b>
                                {defaultTo(value_range.min, null)}
                              </Grid>
                              <Grid item xs={3}>
                                <b>
                                  {I18n.t(
                                    "activerecord.attributes.sensor_value_range.max",
                                  )}
                                  {": "}
                                </b>
                                {defaultTo(value_range.max, null)}
                              </Grid>
                              <Grid item xs={3}>
                                <b>
                                  {I18n.t(
                                    "activerecord.attributes.sensor_value_range.status",
                                  )}
                                  {": "}
                                </b>
                                {defaultTo(value_range.status, null)}
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                </form>
              </>
            )}
          </LoadingWrapper>
        </IBoxContent>
        {btnPos !== "card" ? null : (
          <CardActions>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => {
                  void handleSubmit(props, sensorData);
                }}
                disabled={!saveEnabled}
              >
                {isProcessing ? <LoadingIcon size="1x" /> : <Done />}
              </Button>
              <Button
                onClick={() => onCancel(setIsProcessing, props, context)}
                color="secondary"
              >
                <Close />
              </Button>
            </ButtonGroup>
          </CardActions>
        )}
      </IBox>
      {btnPos !== "bottom" ? null : (
        <FixedBottomArea id="fixed-bottom-area">
          {!readOnly && (
            <FloatingButtons
              isProcessing={isProcessing}
              onSubmit={() => {
                void handleSubmit(props, sensorData);
              }}
              onCancel={() => {
                onCancel(setIsProcessing, props, context);
              }}
              disableSave={!saveEnabled}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.sensors.form.submit_title")}
            />
          )}
        </FixedBottomArea>
      )}
    </>
  );
};

function handleError(e: JSONAPI.DocWithErrors) {
  logger.error(e);
  const errors: Record<keyof SensorJSONObject, string[]> =
    extractErrorsFromJsonApi(e);

  void error(
    I18n.t("base.error"),
    I18n.t("frontend.sensors.form.error_during_submit"),
  );
  return errors;
}

function onCancel(
  setIsProcessing: (boolean: boolean) => void,
  props: SensorFormProps,
  context: SialogicContext,
) {
  if (props.onCancel) {
    props.onCancel();
    return;
  }

  setIsProcessing(false);
  redirectTo(context.referrer);
}

function onFinishSave(sensor: SensorJSONObject, props: SensorFormProps) {
  if (props.onFinishSave) {
    props.onFinishSave(sensor);
    return;
  }
  redirectTo(
    sensorUrl({ ...sensor, assetId: sensor.asset_id as number }, "html"),
  );
}

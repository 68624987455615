import { find, isEmpty, isNil } from "lodash";
import { SensorLoader } from "../../json_api/sensor_loader";
import { Moment } from "moment";
import { convertToUnit } from "../../utils/unit_conversion";
import { logger } from "../../utils/logger";
import { DateRange } from "moment-range";
import { IDType } from "../../utils/urls/url_utils";

export async function loadPercentageWidgetSensorData(
  numeratorSensorId: IDType,
  denominatorSensorId: IDType,
  numeratorUnit: string,
  denominatorUnit: string,
  timeRange: DateRange,
  fallbackToLastValue: boolean,
): Promise<{
  nomValue: number;
  nomTime: Moment;
  denomValue: number;
  denomTime: Moment;
} | null> {
  return Promise.all([
    SensorLoader.getInstance().getLastValueWithin(
      numeratorSensorId,
      timeRange,
      fallbackToLastValue,
    ),
    SensorLoader.getInstance().getLastValueWithin(
      denominatorSensorId,
      timeRange,
      fallbackToLastValue,
    ),
  ])
    .then(([loadedNumeratorSensorValue, loadedDenominatorSensorValue]) => {
      let nomValue: number = loadedNumeratorSensorValue.value as number;
      const nomTime: Moment = loadedNumeratorSensorValue.timestamp
        ? moment(loadedNumeratorSensorValue.timestamp)
        : null;
      let denomValue: number = loadedDenominatorSensorValue.value as number;
      const denomTime: Moment = loadedDenominatorSensorValue
        ? moment(loadedDenominatorSensorValue.timestamp)
        : null;
      if (
        !isNil(loadedDenominatorSensorValue.unit) &&
        !isNil(denominatorUnit)
      ) {
        denomValue = convertToUnit(
          denomValue,
          loadedDenominatorSensorValue.unit,
          denominatorUnit,
        );
      }
      if (!isNil(loadedNumeratorSensorValue.unit) && !isNil(numeratorUnit)) {
        nomValue = convertToUnit(
          nomValue,
          loadedNumeratorSensorValue.unit,
          numeratorUnit,
        );
      }
      return {
        nomValue,
        nomTime,
        denomValue,
        denomTime,
      };
    })
    .catch((e): null => {
      logger.error(
        `Error loading sensor data [${[
          numeratorSensorId,
          denominatorSensorId,
        ].join(" ,")}] for Percentage Widget`,
        e,
      );

      return null;
    });
}

import { Grid } from "@mui/material";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { FaSizeType, LoadingIcon } from "./icon";

export interface LoadingWrapperProps {
  loading: boolean;

  loadingElements?: ReactElement | ReactElement[] | null;
  size?: FaSizeType;

  renderContent?: () => React.ReactNode;
}

export const LoadingWrapper: FunctionComponent<
  PropsWithChildren<LoadingWrapperProps>
> = function (props) {
  return (
    <>
      {defaultTo(props.loading, false) ? (
        <Grid container>
          <Grid item xs={12} className="text-center">
            {isNil(props.loadingElements) ? (
              <LoadingIcon size={defaultTo(props.size, "6x")} />
            ) : (
              props.loadingElements
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          {props.renderContent ? props.renderContent() : null}
          {props.children}
        </>
      )}
    </>
  );
};

import {
  formatString,
  IDType,
  localizedBasePath,
  RequestFormat,
} from "./url_utils";

export function assetMaintenancePlanPath(
  assetId: IDType,
  maintenancePlanId: IDType,
  format: RequestFormat = "",
): string {
  return `${localizedBasePath(format === "json")}/assets/${assetId}/maintenance_plans/${maintenancePlanId}${formatString(format)}`;
}

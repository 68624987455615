import { GridColDef } from "@mui/x-data-grid";
import { AssetJSONObject, AssetStateInfo } from "../../json_api/asset";
import {
  defaultTo,
  each,
  isEmpty,
  isNil,
  toInteger,
  uniqBy,
  upperFirst,
} from "lodash";
import { StateContext } from "../../models/state_context";
import { StateWidget } from "./state_widget";
import * as React from "react";
import {
  generateLinkForItemActionRoute,
  ItemAction,
} from "../../models/item_action";
import { IDType } from "../../utils/urls/url_utils";
import { ItemActionButton } from "../common/item_action_button";

export const addStateContextColumns = (
  columns: GridColDef<AssetJSONObject>[],
  width: number,
  stateContexts: StateContext[],
): GridColDef<AssetJSONObject>[] => {
  if (isEmpty(stateContexts)) {
    return columns;
  } else {
    each(
      uniqBy(stateContexts, (s) => s.id),
      (ctx) => {
        columns.push({
          field: `state_${ctx.id}`,
          width,
          headerName: upperFirst(ctx.name),
          valueGetter: (value, row) => {
            const assetStates = row.asset_states as Record<
              string,
              AssetStateInfo
            >;
            if (isNil(assetStates)) return null;
            const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
            return stateInfo?.name;
          },
          renderCell: (params) => {
            const assetStates = params.row.asset_states as Record<
              string,
              AssetStateInfo
            >;
            if (isNil(assetStates)) return null;
            const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
            if (isNil(stateInfo)) return null;

            return (
              <StateWidget
                inline={true}
                widgetBox={false}
                contextStateMachine={{
                  id: stateInfo.csm_id,
                  current_state: {
                    id: stateInfo.s_id,
                    name: stateInfo.name,
                    icon: stateInfo.icon,
                    color: stateInfo.color,
                    identifier: stateInfo.state,
                    criticality: stateInfo.criticality_val,
                  },
                  state_context: {
                    id: stateInfo.sc_id,
                    identifier: ctx.identifier,
                    name: stateInfo.name,
                  },
                  stateful_item_id: toInteger(params.row.id),
                  stateful_item_type: "Asset",
                }}
              />
            );
          },
        });
      },
    );
  }
  return columns;
};

export function buttonForAssetAction(
  action: ItemAction,
  assetId: IDType,
  key: string | number,
): React.ReactElement {
  const link = linkForAssetAction(action, assetId);
  const title = defaultTo(
    action.title,
    I18n.t(`frontend.organizations.list.actions.${action.action}`),
  );
  if (!isNil(link)) {
    return (
      <ItemActionButton
        key={key}
        action={action}
        link={link}
        title={title}
        onComplete={() => {
          if (action.reload_on_success) {
            window.location.reload();
          }
        }}
      />
    );
  } else {
    return null;
  }
}

const linkForAssetAction = (action: ItemAction, assetId: IDType): string => {
  if (action.route) {
    return generateLinkForItemActionRoute(action.route, action.params, assetId);
  }
};

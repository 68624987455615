import { Autocomplete, TextField } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { SensorTypeJSONAPIAttributes } from "../../json_api/sensor_type";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
import { IDType } from "../../utils/urls/url_utils";
import { useLoadSensorTypesQuery } from "./sensor_types_data";
interface SensorTypeAutocompleteProps {
  assetId?: IDType;
  selectableSensorTypes?: SensorTypeJSONAPIAttributes[];

  onSelectionChange: (
    selectedSensorTypes: SensorTypeJSONAPIAttributes[],
  ) => void;
}
/** Autocomplete for loading and selecting sensors types for a partiular asset
 *
 * When an asset id is provided, the select will only load those types that are defined for the current asset and its subtree
 * @param {*} props
 * @return {*}
 */
export const SensorTypeAutocomplete: React.FunctionComponent<
  SensorTypeAutocompleteProps
> = (props) => {
  const [selectableSensorTypes, setSelectableSensorTypes] = React.useState<
    Array<SensorTypeJSONAPIAttributes | null>
  >(props.selectableSensorTypes);

  const [sensorTypesUrl, setSensorTypesUrl] = React.useState(null);

  const sensorTypesQuery = useLoadSensorTypesQuery({
    variables: {
      in_subtree_of_asset: props.assetId,
    },
    // only load if offline selectable
    enabled: !isNil(props.selectableSensorTypes) || !isNil(props.assetId),
    // use the given values if provided
    placeholderData: {
      items: props.selectableSensorTypes,
      totalItems: props.selectableSensorTypes?.length ?? 0,
      totalPages: -1,
    },
  });

  return (
    <Autocomplete<SensorTypeJSONAPIAttributes | null, true>
      options={sensorTypesQuery.data?.items ?? []}
      multiple
      loading={sensorTypesQuery.isLoading}
      loadingText={I18n.t(
        "frontend.sensor_types.sensor_types_autocomplete.loading_sensor_types",
      )}
      size="small"
      getOptionLabel={(o) => getSensorTypeNameTranslation(o.name)}
      getOptionKey={(o) => o.id}
      color="primary"
      renderInput={(props) => (
        <TextField
          {...props}
          size="small"
          label={I18n.t("frontend.sensors.sensor_filter.sensor_types", {
            count: 2,
          })}
          helperText={I18n.t(
            "frontend.sensors.sensor_filter.sensor_type_filter_helper_text",
          )}
        />
      )}
      onChange={(event, sensorTypes) => {
        props.onSelectionChange(sensorTypes);
      }}
    />
  );
};

import { get, isNil } from "lodash";
import { notifyAirbrake } from "./airbrake_error_handler";

export interface Logger {
  error(message?: any, ...optionalParams: any[]): void;
  logError(err: Error): void;
  debug(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  trace(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
}

export const messages: any[] = [];
function log(message?: any, ...optionalParams: any[]) {
  if (isNil(optionalParams)) {
    messages.push(message);
  } else {
    messages.push([message].concat(...optionalParams).join(" "));
  }
}

class ConsoleLogger implements Logger {
  error(message?: any, ...optionalParams: any[]): void {
    if (message instanceof Error) {
      this.logError(message);
    } else if (optionalParams instanceof Error) {
      console.error(message);
      this.logError(optionalParams);
    } else {
      console.error(message, optionalParams);
    }
  }

  logError(err: Error): void {
    console.error(err);
    void notifyAirbrake(err);
  }

  debug(message?: any, ...optionalParams: any[]) {
    console.debug(message, optionalParams);
  }

  info(message?: any, ...optionalParams: any[]): void {
    console.info(message, optionalParams);
  }
  log(message?: any, ...optionalParams: any[]): void {
    console.log(message, optionalParams);
  }
  trace(message?: any, ...optionalParams: any[]): void {
    console.trace(message, optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, optionalParams);
  }
}

// default logger is console
export let logger: Logger = new ConsoleLogger();

if (
  typeof process !== "undefined" &&
  !isNil(process) &&
  (get(process, "env.NODE_ENV", "production") == "test" ||
    get(process, "env.RAILS_ENV", "production") == "test")
) {
  // store log messages for test environment
  logger = {
    error: log,
    logError: log,
    debug: log,
    info: log,
    log: log,
    trace: log,
    warn: log,
  };
}

import { clamp, defaultTo, isNil, isNumber, toInteger, toNumber } from "lodash";
import {
  MappedSensor,
  SensorMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";
import { percentageValue } from "./utils";
import { Action } from "../../actions/actions.types";

export class RotateMapping extends BaseMapping<
  SVGGraphicsElement | SVGRectElement
> {
  minAngle?: number;
  maxAngle?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  invert?: boolean;

  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, onClick);

    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
    this.minAngle = defaultTo(config.min_angle, 0);
    this.maxAngle = defaultTo(config.max_angle, 360);
    this.invert = config.invert;
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);
    if (isNil(this.element)) return;

    const box = this.element.getBBox();

    this.width = toNumber(defaultTo(box.width, this.width));
    this.height = toNumber(defaultTo(box.height, this.height));
    this.x = toNumber(box.x);

    this.y = toNumber(box.y);
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGElement): void {
    if (isNil(this.element) || isNil(sensorConfig?.value)) {
      return;
    }
    const value = toInteger(defaultTo(sensorConfig?.value, 0.0));
    const min = defaultTo(sensorConfig?.range?.min, 0.0);
    const max = defaultTo(sensorConfig?.range?.max, 100.0);
    // Apply svg transform

    this.element.setAttribute(
      "transform",
      this.getTransformForValue(value, min, max),
    );
  }
  protected getTransformForValue(
    value: number,
    min: number,
    max: number,
  ): string {
    const translateX = this.x + this.width * 0.5;
    const translateY = this.y + this.height * 0.5;
    let percentNormalized =
      clamp(percentageValue(value, min, max), 0, 100) / 100.0;

    if (this.invert) {
      percentNormalized = 1 - percentNormalized;
    }

    let angle = 360 * percentNormalized;
    if (isNumber(this.minAngle) && isNumber(this.maxAngle)) {
      angle =
        this.minAngle + (this.maxAngle - this.minAngle) * percentNormalized;
    }
    return `rotate(${angle} ${translateX} ${translateY})`;
  }
}

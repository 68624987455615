import { Grid, Link } from "@mui/material";
import { isEmpty, isNil, toInteger } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { AssetEventJSONObject } from "../../json_api/asset_event";
import { assetEventPath, assetPath } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { Icon } from "../common/icon";
import { SeverityLevelChip } from "../common/severity_level";

export interface AssetEventWidgetItemProps {
  event?: AssetEventJSONObject;

  descriptionLen?: number;
  onShowDetails?: (event: AssetEventJSONObject) => void;
}

export const AssetEventWidgetItem: FunctionComponent<
  AssetEventWidgetItemProps
> = ({ event, descriptionLen = 100, ...props }) => {
  if (isNil(event)) return null;
  const context = React.useContext(AppContext);

  const data = React.useMemo(() => {
    if (isNil(event)) return {};

    const action = event.action ?? event.event_type?.action ?? null;
    let description =
      event.description ?? event?.event_type?.description ?? "---";
    if (description && descriptionLen) {
      description =
        description.length > descriptionLen
          ? description.substring(0, descriptionLen) + " ..."
          : description;
    }
    let titleDescription =
      isNil(description) || isEmpty(description) ? "" : `: ${description}`;
    if (action && !isEmpty(action)) {
      titleDescription = `${titleDescription}\n\n${I18n.t(
        "activerecord.attributes.asset_event.action",
      )}: ${action}`;
    }

    return {
      name: event.name ?? event?.event_type?.name ?? "---",
      description,
      action,
      message: event.message ?? event?.event_type?.message ?? "---",
      titleDescription,
    };
  }, [event, descriptionLen]);
  const eventPath = assetEventPath(
    event.asset_id ?? event.asset?.id,
    toInteger(event.id ?? event.event_id),
  );

  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
      className="mb-2 pb-2 border-bottom animated fadeIn"
      title={`${data.name}${data.titleDescription}`}
    >
      <Grid item xs={3} sm={3} className="text-center text-primary">
        <p className="mb-1">
          <small className="date">
            {event.from != event.to &&
            !isEmpty(event.to) &&
            !isEmpty(event.from)
              ? `${I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.from),
                )} - ${I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.to),
                )} `
              : I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.from),
                )}
          </small>
        </p>
        <SeverityLevelChip severityLevel={event.severity_level} />
      </Grid>
      <Grid item xs={9} sm={9}>
        <div className="float-left">
          <p className="mb-1">
            <Icon
              className="mr-2"
              icon={
                isEmpty(event.event_type?.icon) || isNil(event.event_type?.icon)
                  ? "calendar"
                  : event.event_type.icon
              }
            />
            {data.name}
            <br />
            <small>
              <Link href={assetPath(event.root_asset?.id)}>
                {event.root_asset?.name}
              </Link>
            </small>
          </p>
          <p className="mb-1">{data.description}</p>
        </div>
        <div className="float-right">
          {isNil(props.onShowDetails) ? (
            <Link variant="body2" href={eventPath}>
              {I18n.t("base.more")}...
            </Link>
          ) : (
            <Link
              component="button"
              variant="body2"
              onClick={(uiEvent) => {
                props.onShowDetails(event);
              }}
            >
              {I18n.t("base.more")}...
            </Link>
          )}
        </div>
        <div className="clearfix" />
      </Grid>
    </Grid>
  );
};

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import JSON from "json-typescript";
import { Dictionary, isEmpty, isNil, map, sortedUniq } from "lodash";
import * as React from "react";
import { AssetPropertyDefinitionJSONObject } from "../../json_api/asset_property_definition";
import { IDType } from "../../utils/urls/url_utils";
import { useLoadAssetTypeQuery } from "../asset_type/asset_type_data";

import AssetPropertiesListEntry from "./asset_properties_list_entry";
import { groupedPropertyDefinitions } from "./asset_properties_utils";
import { useLoadAssetQuery } from "../assets/asset_data";

interface AssetPropertiesListProps {
  assetId: IDType;
  readOnly: boolean;
  tableHeight?: number;
  pageNumber?: number;
  pageSize?: number;
  maxTableHeight?: number | string;
  perPropertyActions?: (
    assetProperty: AssetPropertyDefinitionJSONObject,
  ) => React.ReactElement[];
  useLongTitle?: boolean;
  showTitle?: boolean;
  groupsAllowList?: string[];
  groupsDenyList?: string[];
  hidePropertiesWithoutGroup?: boolean;
  hideGroupNames?: boolean;
  keysAllowList?: string[];
  keysDenyList?: string[];
}
const largeWidth = 250;

/**
 * Component to display a list of asset properties.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.maxTableHeight="75vh"] - The maximum height of the table.
 * @param {boolean} [props.useLongTitle=false] - Whether to use a long title.
 * @param {number} [props.pageNumber=1] - The current page number.
 * @param {number} [props.pageSize=20] - The number of items per page.
 * @param {boolean} [props.showTitle=true] - Whether to show the title.
 * @param {boolean} [props.readOnly=true] - Whether the list is read-only.
 * @param {Array<string>} [props.keysAllowList=[]] - List of allowed keys.
 * @param {Array<string>} [props.keysDenyList=[]] - List of denied keys.
 * @param {Array<string>} [props.groupsAllowList=[]] - List of allowed groups.
 * @param {Array<string>} [props.groupsDenyList=[]] - List of denied groups.
 * @param {boolean} [props.hidePropertiesWithoutGroup=false] - Whether to hide properties without a group.
 * @param {boolean} [props.hideGroupNames=false] - Whether to hide group names.
 * @param {Object} props.assetId - The ID of the asset.
 * @param {Object} [props.assetType] - The type of the asset.
 * @param {Object} [props.assetProperties] - The properties of the asset.
 * @param {boolean} [props.canEdit] - Whether the user can edit the properties.
 * @returns {JSX.Element} The rendered component.
 */
export const AssetPropertiesList: React.FC<AssetPropertiesListProps> = ({
  maxTableHeight = "75vh",
  useLongTitle = false,
  pageNumber = 1,
  pageSize = 20,
  showTitle = true,
  readOnly = true,
  keysAllowList = [],
  keysDenyList = [],
  groupsAllowList = [],
  groupsDenyList = [],
  hidePropertiesWithoutGroup = false,
  hideGroupNames = false,
  ...props
}) => {
  const [pageSizes, setPageSizes] = React.useState(() =>
    sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
  );
  React.useEffect(() => {
    setPageSizes(() =>
      sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
    );
  }, [pageSize]);

  const [isLoading, setLoading] = React.useState<boolean>(true);

  const [assetId, setAssetId] = React.useState<IDType | null>(props.assetId);
  const [assetTypeId, setAssetTypeId] = React.useState<IDType | null>(null);

  const [groupedAssetPropertyDefinitions, setGroupedAssetPropertyDefinitions] =
    React.useState<Dictionary<AssetPropertyDefinitionJSONObject[]>>({});
  const [assetProperties, setAssetProperties] = React.useState<JSON.Object>({});

  // fetch asset and asset_properties
  React.useEffect(() => {
    if (props.assetId) {
      setAssetId(props.assetId);
    }
  }, [props.assetId]);

  const { isLoading: assetIsLoading, data: loadedAsset } = useLoadAssetQuery({
    enabled: assetId !== null,
    variables: {
      id: assetId,
    },
  });

  React.useEffect(() => {
    if (loadedAsset) {
      setAssetTypeId(loadedAsset?.asset_type_id);
      setAssetProperties(loadedAsset?.asset_properties);
    }
  }, [loadedAsset]);

  // fetch asset_type and asset_property_definitions
  const { isLoading: assetTypeIsLoading, data: assetType } =
    useLoadAssetTypeQuery({
      variables: { id: assetTypeId, include: ["asset_property_definitions"] },
      enabled: !isNil(assetTypeId),
    });

  React.useEffect(() => {
    if (assetType && assetType.asset_property_definitions) {
      setGroupedAssetPropertyDefinitions(
        groupedPropertyDefinitions(
          assetType.asset_property_definitions,
          keysAllowList,
          keysDenyList,
          groupsAllowList,
          groupsDenyList,
          hidePropertiesWithoutGroup,
        ),
      );
    }
  }, [assetType, groupsAllowList, groupsDenyList, keysAllowList, keysDenyList]);

  // monitor loading state
  React.useEffect(() => {
    setLoading(assetIsLoading || assetTypeIsLoading);
  }, [assetIsLoading, assetTypeIsLoading]);

  return isLoading ? (
    <Skeleton height={300} />
  ) : (
    <Card>
      {showTitle ? (
        <CardHeader
          title={
            assetType?.name && useLongTitle
              ? I18n.t("frontend.asset_properties.list.title_for_asset", {
                  asset_type_name: assetType.name,
                })
              : I18n.t("frontend.asset_properties.list.title")
          }
        />
      ) : null}
      <CardContent>
        <Box
          height={"auto"}
          width="100%"
          maxHeight={maxTableHeight}
          p={2}
          mb={2}
          overflow="auto"
        >
          {map(
            groupedAssetPropertyDefinitions,
            (propertyDefinitions, groupKey: string) => {
              return groupKey === "null" && !isEmpty(propertyDefinitions) ? (
                <Grid container key="other_props" spacing={3}>
                  {propertyDefinitions.map(
                    (pdItem: AssetPropertyDefinitionJSONObject) => (
                      <Grid
                        item
                        xs={12}
                        key={pdItem.key}
                        marginLeft={hideGroupNames ? 0 : 2}
                      >
                        <AssetPropertiesListEntry
                          assetPropertyDefinition={pdItem}
                          assetProperties={assetProperties}
                          onAssetPropertyChange={setAssetProperties}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              ) : null;
            },
          )}

          {map(
            groupedAssetPropertyDefinitions,
            (propertyDefinitions, groupKey) => {
              return groupKey !== "null" && !isEmpty(propertyDefinitions) ? (
                <Grid container key={groupKey} spacing={2}>
                  {!hideGroupNames ? (
                    <>
                      <Grid item xs={12} my={1}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">{groupKey}</Typography>
                      </Grid>
                    </>
                  ) : null}
                  {map(
                    propertyDefinitions,
                    (pdItem: AssetPropertyDefinitionJSONObject) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          key={pdItem.key}
                          marginLeft={hideGroupNames ? 0 : 2}
                        >
                          <AssetPropertiesListEntry
                            readOnly
                            key={pdItem.key}
                            assetPropertyDefinition={pdItem}
                            assetProperties={assetProperties}
                            onAssetPropertyChange={setAssetProperties}
                          />
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              ) : null;
            },
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

import Bluebird from "bluebird";
import { defaultTo, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { getTimeRangeString, getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { ValueDisplay } from "../common/value_display";
import { computeOffsetValue } from "./algorithm/offset_values";
import { WidgetBox } from "./widget_box";

import { asset_sensor_path } from "../../routes";
import { ValueDifferenceWidgetConfigSerialized } from "../../widgets/value_difference_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { loadSensorData } from "./value_difference_widget.data";
import { ValueDifferenceWidgetProps } from "./value_difference_widget.types";
import { time } from "console";

export const ValueDifferenceWidget: React.FunctionComponent<
  ValueDifferenceWidgetProps
> = ({ calculationMode = "time_offset", ...props }) => {
  const [title, setTitle] = useState<string>(props.title as string);

  // value that is the current value of the sensor for the given time range
  // together with the base value, this value is used to calculate the difference
  const [value, setValue] = useState<number>(props.value as number);

  const [timeScopeName, setTimeScopeName] = useState(props.timeScopeName);
  // the value that is used as the base for the difference calculation
  const [baseValue, setBaseValue] = useState<number>(props.baseValue);
  // the value that is displayed in the widget - i.e. the difference between the base value and the current value
  const [differenceValue, setDifferenceValue] = useState(props.value);
  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);

  const [timeRange, setTimeRange] = useState(props.timeRange);
  const [sensor, setSensor] = useState(props.sensor);
  const [sensorId, setSensorId] = useState(props.sensorId);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ): void => {
      if (!isNumber(value)) {
        return;
      }
      let theValue = value;
      if (!isNil(unit) && !isNil(props.unit)) {
        theValue = convertToUnit(value, unit, props.unit);
      }

      if (
        !props.updateEnabled ||
        isNil(baseValue) ||
        sensorId !== props.sensorId ||
        (!isNil(props.timeRange?.start) &&
          time.isBefore(props.timeRange.start)) ||
        (!isNil(props.timeRange?.end) && time.isAfter(props.timeRange.end))
      ) {
        return;
      }

      setValue(value);
      setTimestamp(time);
    },
    [
      props.baseValue,
      props.unit,
      props.updateEnabled,
      timeRange?.start,
      timeRange?.end,
      props.value,
    ],
  );

  const subscriber = { handleSensorValueUpdate };

  // Update sensor data when sensor link url changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  // Update sensor data when sensor timestamp changes
  useEffect(() => {
    if (props.timestamp !== timestamp) {
      setTimestamp(props.timestamp);
    }
  }, [props.timestamp]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value as number);
    }
  }, [props.value]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    setSensor(props.sensor);
  }, [props.sensor]);

  // Update sensor data when sensor id changes
  useEffect(() => {
    setSensorId(props.sensorId);
  }, [props.sensorId]);

  // Update sensor data when sensor time range changes
  useEffect(() => {
    if (
      !timeRange ||
      !timeRange.start?.isSame(props.timeRange?.start) ||
      !timeRange.end?.isSame(props.timeRange?.end)
    ) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange]);

  useEffect(() => {
    const promise = loadSensorData(
      sensorId,
      timeRange,
      props.baseValue,
      calculationMode,
      props.fallbackToLastValue,
    ).then((res) => {
      if (res) {
        const { baseValue, timestamp, sensor, value } = res;
        setBaseValue(baseValue);
        setTimestamp(timestamp);
        setSensor(sensor);
        setValue(value);
      }
    });

    return () => {
      // cancel the promise if the component is unmounted or anything changed
      promise.cancel();
    };
  }, [
    sensorId,
    timeRange?.start,
    timeRange?.end,
    calculationMode,
    props.baseValue,
    props.fallbackToLastValue,
  ]);

  useEffect(() => {
    setDifferenceValue(computeOffsetValue(baseValue, value, calculationMode));
  }, [baseValue, calculationMode, value]);

  useEffect(() => {
    const instance = WidgetController.getInstance();
    let subscriptionId = null;
    if (instance) {
      if (
        props.updateEnabled &&
        (isNil(timeRange) || timeRange.contains(moment()))
      ) {
        subscriptionId =
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            subscriber,
            props.sensorId as number,
          );
      }
    }
    return () => {
      WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
        subscriptionId,
      );
    };
  }, [props.updateEnabled, props.sensorId, timeRange?.start, timeRange?.end]);

  useEffect(() => {
    if (sensor) {
      const url = asset_sensor_path(props.assetId, props.sensorId);
      setTitleLinkUrl(url);
      setContentLinkUrl(url);
      setTitle(sensor.name);
    }
  }, [sensor]);
  return (
    <WidgetBox
      {...props}
      title={title || props.title}
      titleLinkUrl={titleLinkUrl}
      contentLinkUrl={contentLinkUrl}
      footer={
        !props.showTimeRange || isNil(props.timeRange?.start) ? null : (
          <small
            title={I18n.t(
              "frontend.widgets.value_difference_widget.time_range",
            )}
          >
            {getTimeRangeString(props.timeRange.start, props.timeRange.end)}
          </small>
        )
      }
    >
      <ValueDisplay
        mode="rows"
        vertical={props.vertical}
        value={differenceValue}
        unit={props.unit}
        precision={props.precision}
        hideValue={props.hideValue}
        sensorType={props.sensorType ?? sensor?.sensor_type_name}
        measurementType={props.measurementType ?? sensor?.measurement_type}
        iconName={props.iconName}
        iconSize={props.iconSize}
        timestamp={getTimeString(timeScopeName, timestamp)}
        shadowText={props.textShadow}
      />
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: ValueDifferenceWidgetConfigSerialized,
): ValueDifferenceWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetId: config.asset_id,
    baseValue: config.base_value,
    offsetValue: config.offset_value,
    calculationMode: config.calc_mode,

    iconName: config.icon_name,
    iconSize: config.icon_size,

    measurementType: config.measurement_type,
    minWidthPx: config.min_width_px,
    sensorId: config.sensor_id,
    precision: config.precision,
    sensorType: config.sensor_type,
    showTimeRange: defaultTo(config.show_time_range, false),

    textShadow: config.text_shadow,
    timeScopeName: config.timescope,
    timestamp: config.timestamp ? moment(config.timestamp) : null,
    totalValueRange: config.total_value_range,
    unit: config.unit,
    updateEnabled: !config.disable_update,
    value: config.value,
    vertical: config.vertical,
    fallbackToLastValue: config.fallback_to_last_value,
    ignoreTimeScope: config.ignore_time_scope,
  } as ValueDifferenceWidgetProps);
}

export const ValueDifferenceWidgetDefinition: SialogicWidgetDefinition<
  typeof ValueDifferenceWidget,
  typeof serializedConfigToProps
> = {
  Component: ValueDifferenceWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import JQuery from "jquery";
import * as React from "react";

import * as JSONAPI from "jsonapi-typescript";
import { isEmpty } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { ReportJSONAPIAttributes } from "../../json_api/report";
import { AppRoot } from "../common/app_root";
import { ReportAttributes } from "./data/models";
import { ReportList } from "./views/report_list";

const reportListRoots: Root[] = [];
export function initializeReportList(
  itemOrSelector: JQuery | string = '[data-toggle="report-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);
    const reportsJSON = el.data("items") as JSONAPI.CollectionResourceDoc<
      string,
      ReportJSONAPIAttributes
    >;
    let reports: ReportAttributes[] = undefined;
    if (reportsJSON && !isEmpty(reportsJSON.data)) {
      reports = reportsJSON.data.map((report, index) => {
        return { ...report.attributes, id: report.id };
      });
    }

    const reportsUrl = el.data("items-url") as string;
    let currentPage = parseInt(el.data("current-page")) || 1;
    currentPage = isNaN(currentPage) ? 1 : currentPage;
    const totalPages = parseInt(el.data("total-pages"));
    const pageSize = parseInt(el.data("page-size"));
    const root = createRoot(element);
    reportListRoots.push(root);
    root.render(
      <AppRoot>
        <ReportList
          reports={reports}
          reportsUrl={reportsUrl}
          page={currentPage}
          pageSize={pageSize}
          totalPages={totalPages}
        />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Report List in given container
 */
export function destroyReportList(): void {
  reportListRoots.forEach((root) => {
    root.unmount();
  });
  reportListRoots.length = 0;
}

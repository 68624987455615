import { Grid, Link, Tooltip } from "@mui/material";
import { defaultTo, isEmpty, isNil, toInteger } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { EventNotification } from "../../models/event_notification";
import { assetEventPath } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { Icon } from "../common/icon";
import { SeverityLevelIcon } from "../common/severity_level";
import { AssetEventJSONObject } from "../../json_api/asset_event";

export interface AssetEventWidgetItemCompactProps {
  event: AssetEventJSONObject;
  onShowDetails?: (event: AssetEventJSONObject) => void;
}

export const AssetEventWidgetItemCompact: FunctionComponent<
  AssetEventWidgetItemCompactProps
> = ({ event, onShowDetails }) => {
  if (isNil(event)) return null;

  const context = React.useContext(AppContext);

  const eventPath = assetEventPath(event.asset.id, toInteger(event.event_id));

  const data = React.useMemo(() => {
    if (isNil(event)) return {};

    const action = event.action ?? event.event_type?.action ?? null;
    let description =
      event.description ?? event?.event_type?.description ?? "---";
    if (description) {
      description =
        description.length > 100
          ? description.substring(0, 100) + " ..."
          : description;
    }
    let titleDescription =
      isNil(description) || isEmpty(description) ? "" : `: ${description}`;
    if (action && !isEmpty(action)) {
      titleDescription = `${titleDescription}\n\n${I18n.t(
        "activerecord.attributes.asset_event.action",
      )}: ${action}`;
    }

    return {
      name: event.name ?? event?.event_type?.name ?? "---",
      description,
      action,
      message: event.message ?? event?.event_type?.message ?? "---",
      titleDescription,
    };
  }, [event]);
  return (
    <Tooltip title={`${data.name}${data.titleDescription}`}>
      <Grid
        item
        container
        xs={12}
        className="mb-1 border-bottom animated fadeIn"
        justifyContent="space-between"
      >
        <Grid item xs={1} className="px-2">
          <SeverityLevelIcon severityLevel={event.severity_level} />
        </Grid>
        <Grid item xs={2} className="px-1">
          <small className="date">
            {event.from != event.to &&
            !isEmpty(event.to) &&
            isEmpty(!event.from)
              ? `${I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.from),
                )} - ${I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.to),
                )} `
              : I18n.l(
                  "time.formats.sialogic_short_no_year",
                  new Date(event.from),
                )}
          </small>
        </Grid>
        <Grid item xs={9}>
          <div className="float-left">
            <Link
              underline="none"
              href={eventPath}
              title={I18n.t("base.more") as string}
            >
              <Icon
                className="mr-2"
                icon={
                  isEmpty(event.icon) || isNil(event.icon)
                    ? "calendar"
                    : event.event_type.icon
                }
              />
            </Link>
            <span>{event.name}</span>
          </div>
          <div className="float-right">
            <span className="ml-2">
              {isNil(onShowDetails) ? (
                <Link underline="none" href={eventPath}>
                  {I18n.t("base.more")}...
                </Link>
              ) : (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    onShowDetails(event);
                  }}
                >
                  {I18n.t("base.more")}...
                </Link>
              )}
            </span>
          </div>
          <div className="clearfix" />
        </Grid>
      </Grid>
    </Tooltip>
  );
};

import { CollectionResourceDoc } from "jsonapi-typescript";
import { toArray } from "lodash";
import { createQuery } from "react-query-kit";
import {
  ASSET_JSONAPI_RESOURCE_TYPE,
  AssetIncludes,
  AssetJsonApiFilter,
  AssetJSONObject,
  loadAsset,
} from "../../json_api/asset";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import { ORGANIZATION_JSONAPI_RESOURCE_TYPE } from "../../json_api/organization";
import { USER_GROUP_JSONAPI_RESOURCE_TYPE } from "../../json_api/user_group";
import {
  api_asset_type_assets_path,
  api_assets_path,
  api_organization_assets_path,
  api_user_group_assets_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { AssetListWidgetLookupScope } from "../../widgets/asset_list_widget.types";
import { PageSettings } from "../common/page_size";
import { ASSET_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/asset_type";

interface AssetsQueryVariables {
  pageSettings: PageSettings;
  includes?: AssetIncludes[];
  filter?: AssetJsonApiFilter;
  scope?: AssetListWidgetLookupScope;
}

export type AssetsQueryResult = LoadItemsResult<AssetJSONObject>;

export const useLoadAssetsQuery = createQuery<
  AssetsQueryResult,
  AssetsQueryVariables
>({
  queryKey: [ASSET_JSONAPI_RESOURCE_TYPE],

  fetcher: async ({ scope, pageSettings, includes, filter }, { signal }) => {
    const theFilter = { ...filter };

    const options = {
      ...jsonApiFilterParamsArgumentsFromFilterObject(theFilter),
      page: pageSettings,
      include: toArray(includes).join(","),
      _options: true,
    };

    let url: string;
    if (scope) {
      if (scope.type === ORGANIZATION_JSONAPI_RESOURCE_TYPE) {
        url = api_organization_assets_path(scope.id, options as any);
      } else if (scope.type === USER_GROUP_JSONAPI_RESOURCE_TYPE) {
        url = api_user_group_assets_path(scope.id, options as any);
      } else if (scope.type === ASSET_TYPE_JSONAPI_RESOURCE_TYPE) {
        url = api_asset_type_assets_path(scope.id, options as any);
      } else if (scope.type === ASSET_JSONAPI_RESOURCE_TYPE) {
        (options as any).filter = {
          ...((options as any).filter || {}),
          ancestry_root_id: scope.id,
        };
        url = api_assets_path(options as any);
      } else {
        url = api_assets_path(options as any);
      }
    } else {
      url = api_assets_path(options as any);
    }

    const doc = await loadDataFromUrl<
      CollectionResourceDoc<string, AssetJSONObject>
    >(url, "json", signal);
    return loadItemResultForJsonApiCollectionResourceDoc<AssetJSONObject>(doc);
  },
});

interface AssetQueryVariables {
  id: IDType;
  includes?: AssetIncludes[];
}

export const useLoadAssetQuery = createQuery<
  AssetJSONObject,
  AssetQueryVariables
>({
  queryKey: [ASSET_JSONAPI_RESOURCE_TYPE],

  fetcher: ({ id, includes }) => {
    return loadAsset(id, includes);
  },
});

export const useLoadAssetWithSubtreeQuery = createQuery<
  AssetJSONObject,
  { assetId: IDType }
>({
  queryKey: [ASSET_JSONAPI_RESOURCE_TYPE, "subtree"],
  fetcher: async ({ assetId }) => {
    const asset = await loadAsset(assetId, ["subtree"]);
    return asset;
  },
});

import Bluebird, { CancellationError } from "bluebird";
import {
  debounce,
  defaultTo,
  each,
  every,
  findIndex,
  flatten,
  get,
  isEmpty,
  isFunction,
  isNil,
  isString,
  reject,
} from "lodash";
import { DateRange } from "moment-range";
import { SamplingRate, SensorDataSamplingMode } from "../models/sensor";
import { LineChartStatistics, LineConfig } from "./plotly_line_chart";

import moment from "../initializers/moment";

import { fadeIn, fadeOut } from "../utils/jquery_helper";
import { logger } from "../utils/logger";
import { DateValueTrend } from "../utils/trends";
import { unitDisplayString } from "../utils/unit_conversion";
import { IDType } from "../utils/urls/url_utils";
import { buildAnnotationsForLineDiagram } from "./annotation_line_builder";
import { ChartDataLoader } from "./chart_data/chart_data_loader";
import {
  BinaryChartDataLoadResult,
  StateData,
  ValueTrendData,
} from "./chart_data/chart_data_loader.types";
import { ChartAnnotationOptions } from "./chart_data/line_diagram_annotation_builder";
import { Plotly } from "./plotly_package";
import {
  StatesAndTrances,
  buildStateShapesAndTraces,
} from "./plotly_state_diagram";

import {
  ChartStatistics,
  buildStatisticsHTML,
  computeChartStatisticsForTimeData,
} from "./chart_data/chart_data_statistics";

import { Moment } from "moment";
import { buildStateLegendHtml } from "./chart_legend/state_legend";
import {
  DIAGRAM_TREND_FILL_COLOR,
  DIAGRAM_TREND_LINE_COLOR,
  DIAGRAM_TREND_TEXT_COLOR,
  getDiagramLineColor,
} from "./diagram_constants";
import {
  AxisOptions,
  ChartData,
  ChartDataSourceOptions,
  LineDiagramElementRefs,
  StateMachineOptions,
} from "./plotly_time_series_line_diagram_base.types";
import { CancelledError, isCancelledError } from "@tanstack/react-query";

export abstract class PlotlyTimeSeriesLineDiagramBase {
  static fadeTime = 500;

  static axisPadding = 0.06;

  // debounce the data loading to prevent multiple requests in a short time
  public debounceLoadsMs = 0;
  /**
   * Id of line diagram element
   */
  protected id: string;

  /**
   * Displayed time range of chart data
   */
  protected timeRange: DateRange;

  /**
   * Selected zoom range of chart
   */
  protected zoomRange: DateRange;
  /**
   * Sampling rate of chart data
   */
  protected samplingRate: SamplingRate = { value: null, unit: "minutes" };
  protected samplingMode: SensorDataSamplingMode = "avg";

  protected dataIsLoaded: boolean = false;

  protected yAxesPerUnit = false;

  protected offsetYAxis = false;
  protected beginAtZero = true;

  /************** */

  /**
   * Chart data urls
   */
  protected lineConfigs: LineConfig[];

  /**
   * Trend data urls
   */
  protected trendURLs: string[];

  /**
   * Annotation data urls
   */
  protected annotationURLs: string[];
  protected annotationOptions: Partial<ChartAnnotationOptions>;
  /**
   * Loaded chart data
   */
  protected data: ChartData[] = [];
  protected stateData: StateData[];

  /**
   * Value trend lines
   */
  protected trendData: ValueTrendData[] = [];
  protected minMaxLines: ChartData[] = [];
  protected valueTrends: ChartData[] = [];

  shapes: Array<Partial<Plotly.Shape>>;

  /*************************************/
  /*** Context State Machine Settings  */

  protected contextStateMachineIds: IDType[];
  protected activeContextStateMachineId: IDType;

  /**
   * Max loading time until a spinner is shown
   */
  protected timeBeforeShowSpinnerSeconds = 1;
  protected spinner: JQuery;

  protected errorElement: JQuery;
  protected errorTextElement: JQuery;
  protected noDataElement: JQuery;

  protected dataLoader: ChartDataLoader;
  protected chartElement: JQuery<HTMLElement>;
  protected chart: Plotly.PlotlyHTMLElement;

  protected yAxes: Partial<Plotly.LayoutAxis>[] = [];
  protected statisticsElement: JQuery;
  protected statistics: LineChartStatistics = null;

  protected showStatisticsElement = true;

  protected dataRevision = 1;
  protected uiRevision = 1;

  /** Callbacks */

  /** Callback for rendering the statistics externally instead of using the diagram
   *
   *
   * @protected
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  protected onUpdateStatistics?: (
    statistics: LineChartStatistics,
    colorFunction?: (index: number) => string,
  ) => void;

  /**
   * Creates an instance of PlotlyTimeSeriesLineDiagramBase.
   * @param {LineDiagramElementRefs} elementRefs References to html elements used by this diagram
   * @param {ChartDataSourceOptions} datasources Collection of data sources for this particular diagram
   * @param {StateMachineOptions} stateMachineOptions Options for configuration of the state machine display for the line diagram
   * @param {Partial<AxisOptions>} [axisOptions] Options for the axes to be applied
   * @param {(boolean
   *       | ((
   *           stats: LineChartStatistics,
   *           colorFun: (index: number) => string,
   *         ) => void))} [showStatistics=true] Either a boolean indicating if the statistics being shown or a function that is called when a stats update is computed.
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  constructor(
    elementRefs: LineDiagramElementRefs,
    datasources: ChartDataSourceOptions,
    stateMachineOptions: StateMachineOptions,
    axisOptions?: Partial<AxisOptions>,
    showStatistics:
      | boolean
      | ((
          stats: LineChartStatistics,
          colorFun: (index: number) => string,
        ) => void) = true,
  ) {
    this.id = isString(elementRefs.baseElementIdOrElement)
      ? elementRefs.baseElementIdOrElement
      : elementRefs.baseElementIdOrElement.id;
    this.dataLoader = new ChartDataLoader();
    this.yAxesPerUnit = defaultTo(axisOptions?.yAxesPerUnit, false);
    this.offsetYAxis = defaultTo(axisOptions?.offsetYAxis, false);
    this.contextStateMachineIds = stateMachineOptions?.contextStateMachineIds;
    this.activeContextStateMachineId =
      stateMachineOptions?.activeContextStateMachineId;

    void this.initElements(elementRefs, false);
    this.lineConfigs = datasources?.lineConfigs;
    this.trendURLs = datasources.trendURLs || [];
    this.annotationURLs = datasources.annotationURLs || [];
    if (isFunction(showStatistics)) {
      this.onUpdateStatistics = showStatistics;
      this.showStatisticsElement = false;
      this.statisticsElement?.hide();
    } else {
      if (!showStatistics) {
        this.statisticsElement?.hide();
      }
      this.showStatisticsElement = showStatistics;
    }
    this.noDataElement.hide();
    this.chartElement.hide();
  }

  /** Signales a new data revision
   *
   *
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  newDataRevision() {
    this.dataRevision = this.dataRevision + 1;
  }

  newUiRevision() {
    this.uiRevision = this.uiRevision + 1;
  }

  /** Triggers chart destruction
   *
   *
   * @return {*}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  destroyChart() {
    this.dataLoader.cancelLoading();
    if (isEmpty(this.chartElement) || isNil(this.chart)) {
      return;
    }

    Plotly.purge(this.chartElement.get(0));
    this.chartElement = null;
    this.chart = null;
  }
  /** Default parameters for querying data for this diagram.
   * The parameters should be used if there are no individual per line settings.
   *
   *
   * @return {*}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  defaultTimeSeriesQueryParams() {
    return {
      timeRange: this.timeRange,
      samplingRate: this.samplingRate,
      samplingMode: this.samplingMode,
      offsetYAxis: this.offsetYAxis,
    };
  }
  /** Triggers data loading for the configured data sources.
   * By default this function will debounce the loading of data by 1 second. So that multiple calls to this function will only trigger a single data load within the debounceLoadsMs time.
   *
   *
   * @return {*}  {Promise<void>}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  async loadData(): Promise<void> {
    return debounce(
      async () => {
        try {
          this.dataIsLoaded = false;
          const queryParams = this.defaultTimeSeriesQueryParams();
          const timer = setTimeout(() => {
            // show spinner if loading takes a bit longer
            if (!this.dataIsLoaded) {
              this.spinner.show();
            }
          }, this.timeBeforeShowSpinnerSeconds);

          const binaryDataLoading =
            this.dataLoader.loadBinaryChartData<LineConfig>(
              this.lineConfigs,
              queryParams,
              this.lineConfigs,
            );
          const valueTrendDataLoading = this.dataLoader.loadValueTrendData(
            this.trendURLs,
            queryParams,
          );

          let stateDataLoading: Bluebird<StateData[]>;
          if (!isNil(this.activeContextStateMachineId)) {
            stateDataLoading = this.dataLoader.loadStateData(
              [this.activeContextStateMachineId],
              this.timeRange,
            );
          } else {
            stateDataLoading = Bluebird.resolve(null as StateData[]);
            this.stateData = null;
          }
          const loadedData = await Promise.all([
            binaryDataLoading,
            valueTrendDataLoading,
            stateDataLoading,
          ]);
          const [chartDatasets, trendData, stateDatasets] = loadedData;

          if (
            binaryDataLoading.isCancelled() ||
            valueTrendDataLoading.isCancelled() ||
            stateDataLoading.isCancelled()
          ) {
            await this.updateNoData();
            logger.debug("[Line Diagram Base]", "Data loading cancelled");
            return;
          }

          this.setData(
            chartDatasets as BinaryChartDataLoadResult<LineConfig, number>[],
          );

          this.trendData = trendData;
          this.valueTrends = this.buildValueTrends(trendData);
          this.minMaxLines = this.buildAnnotationLines();

          if (
            !isEmpty(stateDatasets) &&
            !isNil(this.activeContextStateMachineId)
          ) {
            this.stateData = stateDatasets;
            const builtStateData: StatesAndTrances[] = stateDatasets
              ?.map((stateData: StateData) =>
                buildStateShapesAndTraces(
                  stateData,
                  this.timeRange,
                  this.getStateYAxis(),
                ),
              )
              .filter((v: StatesAndTrances) => !isNil(v));
            this.shapes = flatten(builtStateData?.map((v) => v.stateShapes));
            each(builtStateData, (stateData) => {
              this.data.push(stateData.shapeTrace);
            });
          } else {
            this.shapes = null;
            this.stateData = null;
          }

          this.dataRevision++;

          // update chart and show to the user
          this.dataIsLoaded = true;
          await this.updateChart();
        } catch (error) {
          this.dataIsLoaded = true;
          if (
            !(error instanceof CancellationError) &&
            !isCancelledError(error)
          ) {
            logger.debug("[Line Diagram Base]", "Request Cancelled");
            // do not log Cancellation errors
            //logger.logError(error as Error);
            return this.showErrorMessage((error as Error).message);
          }
        } finally {
          await this.updateNoData();
        }
      },
      this.debounceLoadsMs,
      { leading: false, maxWait: this.debounceLoadsMs },
    )();
  }

  /**
   * Redraws chart
   * @return Promise<HTMLElement>
   */
  render(): Promise<HTMLElement> {
    if (isNil(this.chart) || !this.dataIsLoaded) {
      return Bluebird.cast(
        Promise.resolve(this.chartElement.get(0)),
      ) as Promise<HTMLElement>;
    }

    return Promise.resolve(Plotly.redraw(this.chartElement.get(0)));
  }

  /** Updates showing either the diagram or a no data message
   *
   *
   * @return {*}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  updateNoData() {
    // show no data message if the diagram would be empty
    if (isEmpty(this.data) || every(this.data, (data) => isEmpty(data.x))) {
      this.dataIsLoaded = true;
      return this.showNoDataMessage();
    } else {
      return this.showDiagram();
    }
  }

  /** Initializes the variables holding the elemets for diagrams, spinner, error display etc.
   *
   *
   * @param {LineDiagramElementRefs} elementRefs
   * @param {boolean} [updateChart=true]
   * @return {*}  {Promise<any>}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  initElements(
    elementRefs: LineDiagramElementRefs,
    updateChart = true,
  ): Promise<any> {
    const containerElement = isString(elementRefs.baseElementIdOrElement)
      ? $("#" + elementRefs.baseElementIdOrElement)
      : $(elementRefs.baseElementIdOrElement);

    this.chartElement = $(containerElement.find(".sensor-diagram").get(0));
    this.spinner = $(containerElement.find(".loading-overlay").get(0));
    this.errorElement = $(
      containerElement.find(".sensor-diagram-error").get(0),
    );
    this.errorTextElement = $(
      containerElement.find(".sensor-diagram-error-message").get(0),
    );
    this.noDataElement = $(
      containerElement.find(".sensor-diagram-no-data").get(0),
    );
    if (isNil(elementRefs.statisticsElementOrId)) {
      const se = containerElement.find(".sensor-diagram-statistics").get(0);
      if (se) {
        this.statisticsElement = $(se);
      } else {
        this.statisticsElement = null;
      }
    } else {
      this.statisticsElement = isString(elementRefs.statisticsElementOrId)
        ? $("#" + elementRefs.statisticsElementOrId)
        : $(elementRefs.statisticsElementOrId);
    }

    if (updateChart) {
      return this.updateChart();
    }

    return Promise.resolve();
  }

  /** Abstract function for updating the chart display using the currently set data
   *
   *
   * @abstract
   * @return {*}  {(Promise<void | HTMLElement>)} The updated plotly html element after the update has been applied
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  public abstract updateChart(): Promise<void | HTMLElement>;

  /**
   * Sets the sampling rate of displayed data
   * @param value Value of sampling rate
   * @param unit Time unit of sampling rate, e.g., 'hours', 'minutes', 'seconds
   * @return Promise that is resolved when chart is updated.
   */
  setSamplingRate(
    samplingRate: SamplingRate,
    samplingMode: SensorDataSamplingMode,
    updateChart = true,
  ): Promise<void> {
    if (this.samplingRate == samplingRate && this.samplingMode == samplingMode)
      return Promise.resolve();

    this.samplingRate = samplingRate;
    this.samplingMode = samplingMode;

    if (updateChart) {
      return this.loadData();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Sets the time range of displayed data
   * @param start Start of time range
   * @param end End of time range
   * @return Promise that is resolved when chart is updated.
   */
  setTimeScopeMinMax(start: Moment, end: Moment): Promise<void> {
    return this.setTimeScope(moment.range(start, end));
  }

  /**
   * Sets the time range of displayed data
   * @param range Time range of data
   * @return Promise that is resolved when chart is updated.
   */
  setTimeScope(range: DateRange): Promise<void> {
    if (isNil(this.timeRange) || !range.isSame(this.timeRange)) {
      this.timeRange = range;
      this.zoomRange = range;
      return this.loadData();
    } else {
      return Promise.resolve();
    }
  }

  /** Signals an updated statistics for the diagram data and renders an updated statistics
   *
   *
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  statisticsUpdated(): void {
    if (this.onUpdateStatistics) {
      this.onUpdateStatistics(this.statistics, getDiagramLineColor);
    } else {
      if (!isNil(this.statisticsElement)) {
        let statisticsHtml = buildStatisticsHTML(
          this.statistics?.chartStatistics,
          getDiagramLineColor,
        );

        statisticsHtml = statisticsHtml.concat(
          buildStateLegendHtml(this.stateData),
        );
        this.statisticsElement.html(statisticsHtml);
      }
    }
  }

  /**
   * Start scale of y axes at zero instead of smallest value
   * @param beginAtZero Enable or disable start at zero
   */
  setBeginAtZero(beginAtZero: boolean): Promise<void | HTMLElement> {
    if (this.beginAtZero === beginAtZero) return Promise.resolve();

    this.beginAtZero = beginAtZero;
    // adjust axis setting
    each(this.yAxes, (axis) => {
      axis.rangemode = this.beginAtZero ? "tozero" : "normal";
    });

    return this.updateChart();
  }

  protected setData(chartDatasets: BinaryChartDataLoadResult<LineConfig>[]) {}
  /**
   * Fade out overlays and show diagram canvas
   * @return Promise that is resolved when animation has finished
   */
  showDiagram(): Promise<void> {
    this.spinner.hide();

    return Promise.all([
      fadeOut(this.errorElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
      fadeOut(this.noDataElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
    ])
      .then(() =>
        fadeIn(this.chartElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
      )
      .then(() => this.resize().then(() => Promise.resolve()));
  }

  getLineConfigs() {
    return this.lineConfigs;
  }
  /**
   * Resize to diagram container
   * @return Promise<HTMLElement> element that has been resized.
   */
  resize(): Bluebird<HTMLElement> {
    const element = this.chartElement?.get(0);
    if (isNil(element)) return Bluebird.resolve<HTMLElement>(null);

    if (
      isNil(this.chart) ||
      !this.dataIsLoaded ||
      window.getComputedStyle(element).display === "none"
    ) {
      return Bluebird.resolve(element);
    }

    return Bluebird.cast(Plotly.relayout(element, {}));
  }

  /**
   * Fade out overlays and diagram canvas and show no data message
   * @return Promise that is resolved when animation has finished
   */
  showNoDataMessage(): Promise<void> {
    this.spinner.hide();

    return Promise.all([
      fadeOut(this.errorElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
      fadeOut(this.chartElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
    ]).then(() => {
      return fadeIn(
        this.noDataElement,
        PlotlyTimeSeriesLineDiagramBase.fadeTime,
      );
    });
  }

  /**
   * Fadeout diagram and overlays and show an error message
   * @param message The error message to show
   * @return Promise that is resolved when animation has finished
   */
  showErrorMessage(message: string): Promise<void> {
    this.spinner.hide();
    this.errorTextElement.text(message);

    return Promise.all([
      fadeOut(this.noDataElement, PlotlyTimeSeriesLineDiagramBase.fadeTime),
      fadeOut($(this.chartElement), PlotlyTimeSeriesLineDiagramBase.fadeTime),
    ]).then(() => {
      return fadeIn(
        this.errorElement,
        PlotlyTimeSeriesLineDiagramBase.fadeTime,
      );
    });
  }

  /** returns the name of the y axis that is used to display the state machine information
   *
   *
   * @return {*}  {Plotly.YAxisName}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  getStateYAxis(): Plotly.YAxisName {
    return `y${
      this.yAxes.length == 0 ? "" : this.getStateYAxisNumber()
    }` as Plotly.YAxisName;
  }

  getStateYAxisNumber(): number {
    return this.yAxes.length + 1;
  }

  protected buildAnnotationLines(): ChartData[] {
    return buildAnnotationsForLineDiagram(
      this.annotationOptions,
      this.timeRange,
    );
  }

  /**
   * Sets chart annotation options
   */
  setChartAnnotationOptions(
    options: Partial<ChartAnnotationOptions>,
    update = true,
  ): Promise<void | HTMLElement> {
    this.annotationOptions = options;

    if (isNil(this.chart) || !this.dataIsLoaded) return Promise.resolve();

    this.minMaxLines = this.buildAnnotationLines();
    this.valueTrends = this.buildValueTrends(this.trendData);

    this.newDataRevision();

    if (update) {
      return this.updateChart();
    } else {
      return Promise.resolve();
    }
  }

  protected getAxisTitle(seriesName: string, unit: string): string {
    return this.yAxesPerUnit
      ? unitDisplayString(unit)
      : `${seriesName} ${isEmpty(unit) ? "" : "in " + unitDisplayString(unit)}`;
  }

  /** Either builds a new axis description or returns an existing axis to be used for the series name
   *
   *
   * @protected
   * @param {string} seriesName
   * @param {string} unit
   * @param {[number, number]} [range]
   * @return {*}  {number}
   * @memberof PlotlyTimeSeriesLineDiagramBase
   */
  protected getOrCreateAxis(
    seriesName: string,
    unit: string,
    range?: [number, number],
  ): number {
    const axisTitle = this.getAxisTitle(seriesName, unit);
    let axisIndex = findIndex(
      this.yAxes,
      (axis) =>
        axis.title === axisTitle || get(axis, "title.text") === axisTitle,
    );
    if (axisIndex >= 0) {
      // resuse axis and update range
      // update range
      if (!isNil(range)) {
        const axis = this.yAxes[axisIndex];
        const newRange = [
          //
          Math.min(
            axis.range[0] as number, // existing range on axis, e.g. from other series with the same unit
            range[0], // new minimal value
            range[1], // new max value, in some cases this could be lower that min
          ),
          Math.max(axis.range[1] as number, range[0], range[1]),
        ];

        axis.range = newRange;
      }

      return axisIndex;
    }

    // axis not defined yet. add a new one

    axisIndex = this.yAxes.length;

    const side = axisIndex % 2 === 0 ? "left" : "right";
    const axis: Partial<Plotly.LayoutAxis> = {
      title: axisTitle,
      side: side,
      autorange: isNil(range),
      range: range,

      //fixedrange: false,
      spikethickness: 2,
      automargin: true,
      ticksuffix: ` ${unitDisplayString(unit)}`,
    };

    // fix all additional y axis to first y axis
    if (axisIndex > 0) {
      axis.overlaying = "y";
    }

    // add position offsets if there are more than 2 axes
    if (axisIndex > 1) {
      axis.anchor = "free";
      axis.position =
        side === "left"
          ? Math.floor(axisIndex / 2) *
            PlotlyTimeSeriesLineDiagramBase.axisPadding
          : 1.0 -
            Math.floor(axisIndex / 2) *
              PlotlyTimeSeriesLineDiagramBase.axisPadding;
    }
    this.yAxes.push(axis);

    return axisIndex;
  }
  protected buildValueTrends(trendData: ValueTrendData[]): ChartData[] {
    const valueTrends: ChartData[] = [];

    trendData.forEach((valueTrend) => {
      const yAxisIndex = this.getOrCreateAxis(
        valueTrend.series_name,
        valueTrend.unit,
      );
      const trend = new DateValueTrend(valueTrend.slope, valueTrend.intercept);

      valueTrends.push({
        visible: this.annotationOptions.showTrend,
        type: "scattergl",
        mode: "text+lines",
        showlegend: false,
        hoverinfo: "none",
        line: {
          color: DIAGRAM_TREND_LINE_COLOR,
          shape: "linear",
        },
        x: [this.timeRange.start.toDate(), this.timeRange.end.toDate()],
        y: [
          trend.getValue(this.timeRange.start.toDate()),
          trend.getValue(this.timeRange.end.toDate()),
        ],
        name: "Trend",
        text: ["Trend", ""],
        textposition: "bottom left",
        textfont: {
          color: DIAGRAM_TREND_TEXT_COLOR,
        },
        fill: "tonexty",
        fillcolor: DIAGRAM_TREND_LINE_COLOR,
        yaxis: yAxisIndex === 0 ? "y" : `y${yAxisIndex + 1}`,
        stackgroup: "trend",
      } as ChartData);

      if (!isNil(this.annotationOptions.minSlope)) {
        const minTrend = new DateValueTrend(
          this.annotationOptions.minSlope,
          valueTrend.intercept,
        );

        valueTrends.push({
          visible: this.annotationOptions.showTrend,
          type: "scattergl",
          mode: "text+lines",
          showlegend: false,
          hoverinfo: "none",
          line: {
            color: DIAGRAM_TREND_LINE_COLOR,
            dash: "longdash",
            shape: "linear",
          },
          x: [this.timeRange.start.toDate(), this.timeRange.end.toDate()],
          y: [
            minTrend.getValue(this.timeRange.start.toDate()),
            minTrend.getValue(this.timeRange.end.toDate()),
          ],
          name: "mintrend",
          text: ["", "min"],
          textposition: "top left",
          textfont: {
            color: DIAGRAM_TREND_TEXT_COLOR,
          },
          fill: "tonexty",
          fillcolor: DIAGRAM_TREND_FILL_COLOR,
          yaxis: yAxisIndex === 0 ? "y" : `y${yAxisIndex + 1}`,
          stackgroup: "trend",
        } as ChartData);
      }

      if (!isNil(this.annotationOptions.maxSlope)) {
        const maxTrend = new DateValueTrend(
          this.annotationOptions.maxSlope,
          valueTrend.intercept,
        );

        valueTrends.push({
          visible: this.annotationOptions.showTrend,
          type: "scattergl",
          mode: "text+lines",
          showlegend: false,
          hoverinfo: "none",
          line: {
            color: DIAGRAM_TREND_LINE_COLOR,
            dash: "longdash",
            shape: "linear",
          },
          x: [this.timeRange.start.toDate(), this.timeRange.end.toDate()],
          y: [
            maxTrend.getValue(this.timeRange.start.toDate()),
            maxTrend.getValue(this.timeRange.end.toDate()),
          ],
          name: "maxtrend",
          text: ["", "max"],
          textposition: "bottom left",
          textfont: {
            color: DIAGRAM_TREND_TEXT_COLOR,
          },
          fill: "tonexty",
          fillcolor: DIAGRAM_TREND_FILL_COLOR,
          yaxis: yAxisIndex === 0 ? "y" : `y${yAxisIndex + 1}`,
          stackgroup: "trend",
        } as ChartData);
      }
    });

    return valueTrends;
  }

  /**
   * Compute chart statistics data
   * @param range
   * @param data
   */
  computeChartStatistics(
    range: DateRange,
    data: ChartData[],
  ): ChartStatistics[] {
    if (isNil(this.showStatisticsElement) && isNil(this.onUpdateStatistics))
      return [];
    return computeChartStatisticsForTimeData(
      range,
      reject(data, (di) => isNil(di.key_id)),
    );
  }

  static getDatasetLabel(dataset: BinaryChartDataLoadResult): string {
    return dataset.options.label?.title ?? dataset.data.series_name;
  }
}

/// <reference types="../definitions/activestorage" />;

import {
  Blob as ActiveStorageBlob,
  DirectUpload,
  DirectUploadDelegate,
} from "@rails/activestorage";
import { isNil } from "lodash";
import { rails_direct_uploads_path } from "../routes";

export interface UploadDelegate {
  onProgress?(progress: number, upload: ASFileUpload): void;
  onStart?(upload: ASFileUpload): void;
  onComplete?(upload: ASFileUpload): void;
}
export class ASFileUpload implements DirectUploadDelegate {
  directUpload: DirectUpload;
  file: File;
  url: string;
  delegate?: UploadDelegate;
  constructor(
    file: File,
    delegate?: UploadDelegate,
    url = rails_direct_uploads_path(),
  ) {
    this.file = file;
    this.url = url;
    this.delegate = delegate;
    this.directUpload = new DirectUpload(file, url, this);
  }

  async upload(): Promise<ActiveStorageBlob> {
    const blob = await new Promise<ActiveStorageBlob>((resolve, reject) => {
      this.directUpload.create((error: Error, blob: ActiveStorageBlob) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(blob);
      });
    });

    return blob;
  }
  directUploadWillCreateBlobWithXHR(xhr: XMLHttpRequest) {}

  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest) {
    if (!isNil(this.delegate?.onComplete)) {
      xhr.upload.addEventListener("loadend", (event) => {
        this.delegate.onComplete(this);
      });
    }
    if (!isNil(this.delegate?.onStart)) {
      xhr.upload.addEventListener("loadstart", (event) => {
        this.delegate.onStart(this);
      });
    }

    if (!isNil(this.delegate?.onProgress)) {
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          this.directUploadDidProgress(event.total / event.loaded);
        }
      });
    }
  }

  directUploadDidProgress(progress: number) {
    if (!isNil(this.delegate?.onProgress)) {
      this.delegate.onProgress(progress, this);
    }
  }
}
export async function uploadFile(
  file: File,
  url = rails_direct_uploads_path(),
  delegate?: DirectUploadDelegate,
): Promise<ActiveStorageBlob> {
  const upload = new DirectUpload(file, url, delegate);

  const blob = await new Promise<ActiveStorageBlob>((resolve, reject) => {
    upload.create((error: Error, blob: ActiveStorageBlob) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(blob);
    });
  });

  return blob;
}

export async function getDataUrl(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve(reader.result.toString());
    });

    reader.addEventListener("error", () => {
      reject(reader.error);
    });

    reader.readAsDataURL(file);
  });
}

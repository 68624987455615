import Bluebird from "bluebird";
import { isEmpty, isNil } from "lodash";
import * as toasts from "../utils/toasts";

import { HttpError, sendData } from "../utils/jquery_helper";
import { logger } from "../utils/logger";
import {
  createOrUpdateNotificationSettingUrl,
  notificationSettingUrl,
} from "../utils/urls";
import { IDType } from "../utils/urls/url_utils";

interface NotificationSetting {
  id: number;
  category: string;
  severityLevel: string;
  notificationType: string;
  type: "global" | "group" | "user";
}

interface CreateOrUpdateNotificationSettingResponse {
  success: string;
  notificationSetting: NotificationSetting;
  defaultSetting?: NotificationSetting;
  html: string;
  message?: string;
}

interface DestroyNotificationSettingResponse {
  defaultSetting?: NotificationSetting;
}

export class NotificationSettingsController {
  element: JQuery<HTMLElement>;
  addNotificationSettingForm: JQuery<HTMLElement>;
  enableNotificationSoundButton: JQuery<HTMLElement>;
  request: Bluebird<any>; // for unit tests
  organizationId?: IDType;
  userGroupId?: IDType;
  userId?: IDType;

  constructor(element: JQuery<HTMLElement>) {
    this.element = element;

    this.element
      .find<HTMLElement>("tr.notification-setting")
      .each((index, row) => {
        this.bindChangeNotificationType($(row));
        this.bindResetToDefault($(row));
      });

    this.organizationId = this.element.data("organization-id") as string;
    this.userGroupId = this.element.data("user-group-id") as string;
    this.userId = this.element.data("user-id") as string;
    this.addNotificationSettingForm = this.element.find<HTMLElement>(
      "#add-notification-setting-form",
    );
    this.bindAddNotificationSetting(this.addNotificationSettingForm);

    this.enableNotificationSoundButton = $("#user_notification_sound_enabled");
    this.enableNotificationSoundButton.change(() =>
      this.handleChangeNotificationSound(),
    );
  }

  private bindChangeNotificationType(row: JQuery<HTMLElement>): void {
    row
      .find<HTMLElement>("td.notification-type-col .dropdown .dropdown-item")
      .each((index, item) => {
        $(item).on("click", () =>
          this.handleChangeNotificationType($(row), $(item)),
        );
      });
  }

  private bindResetToDefault(row: JQuery<HTMLElement>): void {
    row
      .find("#reset-to-default")
      .on("click", () => this.handleResetToDefault(row));
  }

  private bindAddNotificationSetting(form: JQuery<HTMLElement>): void {
    form
      .find("#add-notification-setting")
      .on("click", () => this.handleAddNotificationSetting());
    form
      .find<HTMLElement>("td.notification-type-col .dropdown .dropdown-item")
      .each((index, item) => {
        $(item).on("click", () => {
          this.updateDropDownSelection(
            form.find<HTMLElement>("td.notification-type-col .dropdown-toggle"),
            $(item),
          );
        });
      });
  }

  private handleChangeNotificationType(
    row: JQuery<HTMLElement>,
    selectedType: JQuery<HTMLElement>,
  ): void {
    const category = row.attr("data-category");
    const severityLevel = row.attr("data-severity-level");
    const notificationType = selectedType.attr("data-value");
    const scope = selectedType.attr("data-type");
    this.updateDropDownSelection(
      $(row).find<HTMLElement>("td.notification-type-col .dropdown-toggle"),
      selectedType,
    );
    this.request = this.updateNotificationSetting(
      category,
      severityLevel,
      notificationType,
    )
      .then((response) => {
        row.data("id", response.notificationSetting.id);
        row.data("type", response.notificationSetting.type);
        row.data("defaultSetting", response.defaultSetting);
        const resetButton = row.find("#reset-to-default");
        if (!isNil(response.message)) {
          void toasts.success(
            I18n.t("frontend.notification_settings_controller.success_header", {
              category: I18n.t(
                `activerecord.attributes.notification_setting.categories.${response.notificationSetting.category}`,
              ),
            } as Record<string, string>),
            response.message,
          );
        }
        resetButton.show().prop("disabled", false);
      })
      .catch((error: HttpError) => {
        if (!isEmpty(error.response)) {
          void toasts.error(error.response);
        }
        logger.logError(error);
      });
  }

  private handleAddNotificationSetting(): void {
    const category = this.addNotificationSettingForm
      .find("select#category")
      .val()
      .toString();
    const severityLevel = this.addNotificationSettingForm
      .find("select#severity_level")
      .val()
      .toString();
    const notificationType = this.addNotificationSettingForm
      .find("td.notification-type-col .dropdown-toggle")
      .attr("data-value");

    this.request = this.updateNotificationSetting(
      category,
      severityLevel,
      notificationType,
    )
      .then((response) => {
        const newNotificationSetting = $(response.html);
        const toggleAddSettingButton = this.element.find("#toggle-add-setting");
        this.bindChangeNotificationType(newNotificationSetting);
        this.bindResetToDefault(newNotificationSetting);
        if (!isNil(response.message)) {
          void toasts.success(I18n.t("frontend.success"), response.message);
        }
        if (
          !isNil(response.defaultSetting) &&
          response.defaultSetting.id !== response.notificationSetting.id
        ) {
          // replace default notification setting row
          const notificationRow = this.element.find(
            `tr[data-id=${response.defaultSetting.id}]`,
          );
          newNotificationSetting.insertAfter(notificationRow);
          notificationRow.remove();
        } else {
          // check if notification setting already exists
          const notificationRow = this.element.find(
            `tr[data-id=${response.notificationSetting.id}]`,
          );

          if (notificationRow.length > 0) {
            // replace existing notification setting
            newNotificationSetting.insertAfter(notificationRow);
            notificationRow.remove();
          } else {
            // append new notification setting at the end of the table
            const toggleAddSettingRow = toggleAddSettingButton
              .parent()
              .parent();
            newNotificationSetting.insertBefore(toggleAddSettingRow);
          }
        }

        toggleAddSettingButton.trigger("click");
      })
      .catch((error: HttpError) => {
        if (!isEmpty(error.response)) {
          void toasts.error(error.response);
        }
        logger.logError(error);
      });
  }

  private handleResetToDefault(row: JQuery<HTMLElement>): void {
    const id = row.attr("data-id");
    this.request = this.resetNotificationSetting(id)
      .then((response) => {
        if (!isNil(response.defaultSetting)) {
          // reset element to default settings
          const dropdownButton = row.find(
            "td.notification-type-col .dropdown-toggle",
          );
          const selectedType = row.find(
            `[data-value=${response.defaultSetting.notificationType}]`,
          );
          dropdownButton.html(selectedType.html());
          row.attr("data-id", response.defaultSetting.id);
          dropdownButton.attr(
            "data-value",
            response.defaultSetting.notificationType,
          );
          dropdownButton.attr("data-type", response.defaultSetting.type);
          const resetButton = row.find("#reset-to-default");
          resetButton.hide().prop("disabled", true);
          void toasts.success(
            I18n.t(
              "frontend.notification_settings_controller.reset_success_header",
              {
                category: I18n.t(
                  `activerecord.attributes.notification_setting.categories.${response.defaultSetting.category}`,
                ),
              } as Record<string, string>,
            ),
            I18n.t(
              "frontend.notification_settings_controller.reset_success_message",
              {
                category: response.defaultSetting.category,
                setting_type: I18n.t(
                  `activerecord.attributes.notification_setting.setting_types.${response.defaultSetting.type}`,
                ),
              } as Record<string, string>,
            ),
          );
        } else {
          void toasts.success(
            I18n.t(
              "frontend.notification_settings_controller.reset_deleted_header",
            ),
            I18n.t(
              "frontend.notification_settings_controller.reset_deleted_message",
            ),
          );
          // no default setting -> remove element
          row.remove();
        }
      })
      .catch((error: HttpError) => {
        if (!isEmpty(error.response)) {
          void toasts.error(error.response);
        }
        logger.logError(error);
      });
  }

  private handleChangeNotificationSound(): void {
    const soundEnabled = this.enableNotificationSoundButton.prop(
      "checked",
    ) as boolean;

    this.request = this.updateNotificationSound(soundEnabled);
  }

  private updateDropDownSelection(
    dropdownButton: JQuery<HTMLElement>,
    selectedType: JQuery<HTMLElement>,
  ): void {
    dropdownButton.attr("data-value", selectedType.attr("data-value"));
    dropdownButton.html(selectedType.html());
  }

  private updateNotificationSetting(
    category: string,
    severityLevel: string,
    notificationType: string,
  ): Bluebird<CreateOrUpdateNotificationSettingResponse> {
    return sendData(
      createOrUpdateNotificationSettingUrl(
        this.organizationId,
        this.userGroupId,
        this.userId,
      ),
      {
        notification_setting: {
          category: category,
          severity_level: severityLevel,
          notification_type: notificationType,
        },
      },
    );
  }

  private resetNotificationSetting(
    id: string,
  ): Bluebird<DestroyNotificationSettingResponse> {
    return sendData(notificationSettingUrl(id), {}, "DELETE");
  }

  private updateNotificationSound(soundsEnabled: boolean): Bluebird<void> {
    return sendData("/notification_settings/notification_sounds.json", {
      user: {
        notification_sound_enabled: soundsEnabled,
      },
    }).then(() => {
      if (gon.user) gon.user.notificationSoundEnabled = soundsEnabled;
    });
  }
}

import { RRule } from 'rrule';

const computeMonthlyInterval = (data, rruleObj) => {
  if (rruleObj.freq !== RRule.MONTHLY) {
    return data.repeat.monthly.interval;
  }

  return rruleObj.interval;
};

export default computeMonthlyInterval;

import * as React from "react";
import { IDType } from "../../utils/urls/url_utils";
import { SubscriptionCards } from "./subscription_cards";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
const roots: Root[] = [];
export function initializeSubscriptionCards(): void {
  $('[data-toggle="subscriptions-cards"]').each((index, element) => {
    const organizationId = $(element).data("organization-id") as IDType;
    const root = createRoot(element);
    roots.push(root);

    root.render(
      <AppRoot>
        <SubscriptionCards organizationId={organizationId} />
      </AppRoot>,
    );
  });
}

export function destroySubscriptionCards(): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}

import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";

import { ProductModelAssetTemplateSettingAttributes } from "../models/product_model_asset_template_settings";

import { AssetTemplateJSONObject } from "./asset_template";
import { ProductModelJSONObject } from "./product_model";

export const PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE =
  "product_model_asset_template_settings";
export interface ProductModelAssetTemplateSettingJSONObject
  extends ProductModelAssetTemplateSettingAttributes,
    JSON.Object {
  asset_template?: AssetTemplateJSONObject;
  product_model?: ProductModelJSONObject;
}

export type ProductModelAssetTemplateSettingJSONAPIAttributes =
  JSONAPI.AttributesObject<ProductModelAssetTemplateSettingJSONObject>;

import { isNil } from "lodash";
import {
  IDType,
  RequestFormat,
  formatString,
  localizedBasePath,
} from "./url_utils";

export function notificationSettingsUrl(): string {
  return `${localizedBasePath()}/notification_settings`;
}

export function notificationSettingUrl(
  id: IDType,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/notification_settings/${id}${formatString(
    format,
  )}`;
}

export function createOrUpdateNotificationSettingUrl(
  organizationId?: IDType,
  userGroupId?: IDType,
  userId?: IDType,
  format: RequestFormat = "json",
): string {
  if (!isNil(organizationId) && !isNil(userGroupId)) {
    if (!isNil(userId)) {
      return `${localizedBasePath()}/organizations/${organizationId}/user_groups/${userGroupId}/users/${userId}/notification_settings/create_or_update${formatString(
        format,
      )}`;
    } else {
      return `${localizedBasePath()}/organizations/${organizationId}/user_groups/${userGroupId}/notification_settings/create_or_update${formatString(
        format,
      )}`;
    }
  } else {
    return `${localizedBasePath()}/notification_settings/create_or_update${formatString(
      format,
    )}`;
  }
}

import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";

import { Replay } from "@mui/icons-material";
import { isEmpty, map } from "lodash";
import { EventSeverityLevel } from "../../models/event_notification";
import { SeverityLevelIcon } from "../common/severity_level";
interface EventSeveritySelectProps {
  severity: EventSeverityLevel;
  required?: boolean;
  showReset?: boolean;
  onSeveritySelect(newLevel: EventSeverityLevel): void;
  readonly?: boolean;
  disabled?: boolean;
  error?: string;
  size?: "small" | "medium";
  helperText?: string;
  onBlur?: () => void;
}

export const EventSeveritySelect: React.FunctionComponent<
  EventSeveritySelectProps
> = ({ size = "small", ...props }) => {
  return (
    <Grid container>
      <Grid item xs>
        <TextField
          onBlur={props.onBlur}
          InputProps={{
            readOnly: props.readonly,
          }}
          fullWidth
          disabled={props.readonly || props.disabled}
          select
          size={size}
          value={props.severity || ""}
          error={!isEmpty(props.error)}
          helperText={
            props.error ||
            props.helperText ||
            I18n.t(
              "frontend.asset_events.severity_selection.select_severity_help",
            )
          }
          label={I18n.t("activerecord.attributes.asset_event.severity_level")}
          onChange={(e) => {
            props.onSeveritySelect(e.target.value as EventSeverityLevel);
          }}
          required={props.required}
        >
          {map(["info", "warn", "alarm"], (lvl) => (
            <MenuItem key={lvl} value={lvl}>
              <Box component="span" mr={1}>
                <SeverityLevelIcon severityLevel={lvl as EventSeverityLevel} />
              </Box>
              <Typography component="span">
                {I18n.t(
                  `activerecord.attributes.notification_setting.severity_levels.${lvl}`,
                )}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {isEmpty(props.severity) ||
      props.readonly ||
      props.required ||
      props.showReset == false ? null : (
        <Grid item minWidth={30}>
          <Tooltip title={I18n.t("frontend.remove_selection")}>
            <IconButton
              style={{ marginTop: "auto", marginBottom: "auto" }}
              onClick={() => props.onSeveritySelect(null)}
            >
              <Replay fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

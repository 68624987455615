import { createQuery } from "react-query-kit";
import {
  CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE,
  ContextStateMachineJSONObject,
  loadContextStateMachine,
  loadContextStateMachinesForAsset,
} from "../../json_api/context_state_machines";
import { IDType } from "../../utils/urls/url_utils";
import { CSMIncludes } from "../../utils/urls";

interface LoadStateMachinesVariables {
  id?: IDType;
  itemType?: "Asset";
  itemId?: IDType;
  include?: CSMIncludes[];
}
export const loadStateMachinesForItem = createQuery<
  ContextStateMachineJSONObject[],
  LoadStateMachinesVariables
>({
  queryKey: [CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE],

  fetcher: ({ id, itemType, itemId, include }) => {
    if (itemType === "Asset") {
      return loadContextStateMachinesForAsset(itemId, include);
    }
  },
});

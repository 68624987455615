import { compact, defaultTo, isEmpty, isNil, noop } from "lodash";
import * as React from "react";

import { jsonApiFieldsParamsArray } from "../../json_api/jsonapi_tools";
import { OrganizationJSONObject } from "../../json_api/organization";
import { organizationsJsonApiSearchPath } from "../../utils/urls";
import { applyParamsToBaseUrl } from "../../utils/urls/url_utils";
import { ItemSearchAutocomplete } from "../common/item_autocomplete";

export interface OrganizationsAutocompleProps {
  inputId?: string;
  maxItems: number;
  organization?: OrganizationJSONObject;

  organizations?: OrganizationJSONObject[];
  label?: string;
  variant?: "standard" | "filled" | "outlined";
  width: number | string;

  /** Base url for retrieving organizations. Exected to accept 'search' parameter
   *
   *
   * @type {string}
   * @memberof OrganizationsAutocompleProps
   */
  organizationsBaseUrl?: string;

  searchUrl?: (searchTerm: string, maxItems: number) => string;

  onSelect?: (org: OrganizationJSONObject) => void;
}

export class OrganizationsAutocomplete extends React.Component<OrganizationsAutocompleProps> {
  static defaultProps: Partial<OrganizationsAutocompleProps> = {
    onSelect: noop,
    width: 300,

    maxItems: 4,
    organizationsBaseUrl: null,
  };

  constructor(props: OrganizationsAutocompleProps) {
    super(props);
    this.state = {
      organizations: defaultTo(
        this.props.organizations,
        compact([this.props.organization]),
      ),
      selectedOrganization: this.props.organization,
      searchTerm: defaultTo(this.props.organization?.name, ""),
      lastLookupSearchTerm: null,
      open: false,
    };
  }

  searchUrl(searchTerm: string): string {
    if (!isNil(this.props.searchUrl)) {
      return this.props.searchUrl(searchTerm, this.props.maxItems);
    }
    if (isNil(this.props.organizationsBaseUrl)) {
      return organizationsJsonApiSearchPath(
        searchTerm,
        1,
        this.props.maxItems,
        [],
        ["name", "id", "icon_url"],
      );
    } else {
      return applyParamsToBaseUrl(
        this.props.organizationsBaseUrl,
        1,
        this.props.maxItems,
        [],

        jsonApiFieldsParamsArray<OrganizationJSONObject>("organizations", [
          "id",
          "name",
          "icon_url",
        ]),
      );
    }
  }
  render(): React.ReactNode {
    const label = defaultTo(
      this.props.label,
      I18n.t("frontend.type_to_search"),
    );
    return (
      <ItemSearchAutocomplete<OrganizationJSONObject>
        variant={this.props.variant}
        selectedItem={
          isEmpty(this.props.organization) ? null : this.props.organization
        }
        items={this.props.organizations}
        inputId={this.props.inputId}
        itemType="organizations"
        width={this.props.width}
        label={label}
        onSelect={(organization) => {
          this.props.onSelect(organization);
        }}
        urlForRequest={(searchTerm) => this.searchUrl(searchTerm)}
      />
    );
  }
}

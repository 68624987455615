import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { range } from "lodash";

import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import { MONTHS } from "../../../constants/index";
import translateLabel from "../../../utils/translateLabel";
import { Grid, MenuItem, Radio, TextField } from "@mui/material";

const RepeatYearlyOn = ({
  id,
  mode,
  on,
  hasMoreModes,
  handleChange,
  translations,
}) => {
  let monthNum = MONTHS.indexOf(on.month);
  monthNum = monthNum === -1 ? 0 : monthNum;
  const daysInMonth = moment().month(monthNum).daysInMonth();
  const isActive = mode === "on";

  return (
    <Grid
      item
      container
      spacing={2}
      xs={12}
      className={`${!isActive && "opacity-50"}`}
    >
      <Grid item display={{ xs: "none" }} sm={2} />
      <Grid item sm="auto">
        {hasMoreModes && (
          <Radio
            id={id}
            name="repeat.yearly.mode"
            aria-label="Repeat yearly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
          />
        )}
      </Grid>
      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-month`}
          name="repeat.yearly.on.month"
          aria-label="Repeat yearly on month"
          value={on.month}
          disabled={!isActive}
          size="small"
          label={translateLabel(translations, "repeat.yearly.on")}
          onChange={handleChange}
        >
          {MONTHS.map((month) => (
            <MenuItem key={month} value={month}>
              {translateLabel(translations, `months.${month.toLowerCase()}`)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-day`}
          name="repeat.yearly.on.day"
          aria-label="Repeat yearly on a day"
          size="small"
          value={on.day}
          disabled={!isActive}
          onChange={numericalFieldHandler(handleChange)}
        >
          {range(0, daysInMonth).map((i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};
RepeatYearlyOn.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  on: PropTypes.shape({
    month: PropTypes.oneOf(MONTHS).isRequired,
    day: PropTypes.number.isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatYearlyOn;

import { Box, Grid, Link, Tooltip, Typography } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { useTheme } from "@mui/material/styles";
import { MaintenanceJobJSONObject } from "../../json_api/maintenance_job";
import { MaintenancePlanJSONObject } from "../../json_api/maintenance_plan";
import { assetMaintenancePlanPath } from "../../utils/urls";
import { Icon } from "../common/icon";
import { useMaintenanceStyles } from "../styling/theme";

export interface MaintenanceWidgetItemProps {
  job?: MaintenanceJobJSONObject;
  maintenancePlan?: MaintenancePlanJSONObject;

  descriptionLen?: number;
  onShowDetails?: (job: MaintenanceJobJSONObject) => void;
}

export const MaintenancesWidgetItem: FunctionComponent<
  MaintenanceWidgetItemProps
> = ({
  job,
  maintenancePlan,
  descriptionLen = 100,
}: MaintenanceWidgetItemProps) => {
  if (isNil(job)) return null;

  const data = React.useMemo(() => {
    if (isNil(job)) return {};
    return {
      asset_id: job.asset_id,
      asset_name: job.asset?.name ?? "",
      maintenance_job_id: job.id,
      name: maintenancePlan.name ?? "---",
      maintenance_plan_id: maintenancePlan.id,
      maintenance_job_type: job?.maintenance_job_type ?? "---",
      description: job.description ?? "---",
      done_at: job.done_at ?? "---",
      asset: job.asset_id ?? "---",
      tooltip: I18n.t(
        "frontend.maintenance_jobs.maintenances_widget_item.job_tooltip",
        {
          asset_name: job.asset?.name,
          user_name: job.user?.name ?? "---",
          name: maintenancePlan.name,
        },
      ),
    };
  }, [job, descriptionLen]);

  const theme = useTheme();
  const mStyleClasses = useMaintenanceStyles();
  return (
    <Grid
      key={`${data.maintenance_job_id}_cont`}
      container
      title={`${data.name}`}
      borderTop={1}
      borderColor={theme?.palette?.divider}
      marginY={1}
      paddingY={0.5}
      className={
        data.maintenance_job_type == "InspectionJob"
          ? mStyleClasses.inspectionBorder
          : mStyleClasses.maintenanceBorder
      }
    >
      <Grid item width={80} textAlign="left" paddingX={1}>
        <Typography variant="body2">
          {job.done_at
            ? `${I18n.l(
                "time.formats.day_only",
                new Date(data.done_at as unknown as Date),
              )} `
            : ""}
        </Typography>
      </Grid>
      <Grid item width={60} color={"gray"} textAlign="center">
        <Icon
          icon={data.maintenance_job_type == "InspectionJob" ? "eye" : "wrench"}
        />
      </Grid>

      <Grid item xs zeroMinWidth paddingLeft={1} paddingRight={1}>
        <Box width="100%">
          <Tooltip title={data.tooltip}>
            <Typography
              fontWeight={600}
              variant="body1"
              fontSize="small"
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              noWrap
            >
              {data.name}
            </Typography>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item textAlign="right" paddingLeft={1} paddingRight={1}>
        <Link
          href={assetMaintenancePlanPath(
            data.asset_id,
            data.maintenance_plan_id,
          )}
        >
          {I18n.t("base.more")}...
        </Link>
      </Grid>
    </Grid>
  );
};

import geojson from "geojson";
import { SensorFeatureProperties } from "./map.types";
import { clone, cloneDeep } from "lodash";

export function getFeatureIdFromSensorFeature(
  feature: geojson.Feature<geojson.Geometry, SensorFeatureProperties>,
): string {
  switch (feature.geometry.type) {
    case "LineString":
      return feature.properties.sensor_id?.toString();
    case "Point":
      return `${feature.properties.sensor_id}_${feature.properties.timestamp}`;
    default:
      return feature.properties.sensor_id?.toString();
  }
}

export function createWaypointData(
  data: geojson.FeatureCollection<geojson.LineString> & { crs?: string },
): [
  geojson.FeatureCollection<geojson.Point, SensorFeatureProperties>,
  geojson.FeatureCollection<geojson.LineString, SensorFeatureProperties>,
] {
  const pointFeatureCollection = {
    type: "FeatureCollection",
    features: [] as geojson.Feature[],
    crs: cloneDeep(data.crs),
  } as geojson.FeatureCollection<geojson.Point, SensorFeatureProperties>;
  const lineFeatureCollection = {
    type: "FeatureCollection",
    features: [] as geojson.Feature[],
    crs: cloneDeep(data.crs),
  } as geojson.FeatureCollection<geojson.LineString, SensorFeatureProperties>;

  if (data.type !== "FeatureCollection") {
    return;
  }

  data.features.forEach((feature: geojson.Feature<geojson.LineString>) => {
    if (feature.geometry.type !== "LineString") {
      return;
    }

    const numPoints = feature.geometry.coordinates.length;

    feature.geometry.coordinates.forEach((coordinate, index) => {
      const properties = cloneDeep(feature.properties);
      delete properties.timestamps;
      properties.timestamp = (feature.properties.timestamps as number[])[index];

      pointFeatureCollection.features.push({
        type: "Feature",
        properties: {
          ...properties,
          isLastFeature: index === numPoints - 1,
        },
        geometry: {
          type: "Point",
          coordinates: clone(coordinate),
        },
      });
    });
    lineFeatureCollection.features.push(feature);
  });

  return [pointFeatureCollection, lineFeatureCollection];
}

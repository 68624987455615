import { isNil } from "lodash";
import * as React from "react";
import { FontAwesomeIconClasses } from "../../../utils/font_awesome_icon_classes";
import {
  MaintenancePlan,
  MaintenancePlanType,
} from "../../maintenance_plan_form/data/models";
export interface MaintenancePlanIconProps {
  maintenancePlan?: MaintenancePlan;
  maintenancePlanType?: MaintenancePlanType;
  size?: FontAwesomeIconClasses;
  className?: string;
}

export function MaintenancePlanIcon(props: MaintenancePlanIconProps) {
  return (
    <i
      className={`fa fa-${getIconClass(props)} ${
        isNil(props.size) ? "" : "fa-" + props.size
      } mr-1 ${props.className}`}
    ></i>
  );
}

function getIconClass(props: MaintenancePlanIconProps): string {
  let type = props.maintenancePlanType;
  if (props.maintenancePlan) {
    type = props.maintenancePlan.type;
  }
  switch (type) {
    case "MaintenancePlan":
      return "wrench";
    case "InspectionPlan":
      return "eye";
    default:
      return "";
  }
}

import { clamp, first, isEmpty, isNil, isNumber } from "lodash";
import { Moment } from "moment";

import { SensorValueType } from "../models/sensor";
import {
  AssetMaintenanceInfoWidgetConfigSerialized,
  MaintenanceStatusCalculationInfo,
} from "./asset_maintenance_info_widget.types";
import Widget from "./widget";

/** Widget encapsulating a sensor value display that can be updated programmatically
 * requires a set of spans as child elements to set the values
 * <span class="value"></span><span class="unit"></span>
 * ans <small|span class="timestamp">
 *
 * reads initial values from data attributes if provided:
 * data-sensor-id: sensor id
 * data-attribute-key-id: attribute key id to subscribe
 * data-unit: unit string
 * data-value: value
 * data-time: timestamp in ISO 8601 format
 * data-disable-update: disables the data update
 * @class SensorValuePanel
 * @extends {Widget}
 */
export default class AssetMaintenanceInfoWidget extends Widget {
  status: MaintenanceStatusCalculationInfo[];
  sensorValue: number;
  sensorValueId: number;
  sensorValueUnit: string;
  sensorStatus: string;

  sensorValueBarChartElement: JQuery<HTMLElement>;
  maintenanceValueBarChartElement: JQuery<HTMLElement>;

  static getDomClassName(): string {
    return "asset-maintenance-info-widget";
  }

  protected constructor(element: JQuery<HTMLElement>) {
    super(element);
  }

  protected postInitialize(): void {
    super.postInitialize();
    this.setMaintenanceValue(first(this.status)?.current_value);
    this.setSensorValue(this.sensorValue);
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data(
      "config",
    ) as AssetMaintenanceInfoWidgetConfigSerialized;

    this.maintenanceValueBarChartElement = element.find<HTMLElement>(
      "ul.bar-chart.maintenance-value-bar",
    );
    this.sensorValueBarChartElement = element.find<HTMLElement>(
      "ul.bar-chart.sensor-value-bar",
    );

    if (isNil(config)) return;
    this.status = config.status;

    this.sensorValueId = config.sensor_id;
    this.sensorValueUnit = config.sensor_value_unit;
    this.sensorValue = config.sensor_value;
    this.sensorStatus = config.sensor_status;
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    if (!isNumber(value) && !isNil(value)) {
      return;
    }

    this.updateValue(attributeKeyId, sensorId, value, unit, time);
  }

  updateValue(
    attributeKeyId: number,
    sensorId: number,
    value: number,
    unit: string,
    time: Moment,
  ): void {
    if (!this.updateEnabled) {
      return;
    }
    if (sensorId === this.sensorValueId) {
      if (isNil(value)) {
        this.setSensorValue(0);
      } else {
        this.setSensorValue(value);
      }
    }
  }

  setSensorValue(value: number) {
    this.setValueForChart(
      this.sensorValueBarChartElement,
      value,
      this.sensorValueUnit,
    );
    this.sensorValue = value;
  }

  setMaintenanceValue(value: number): void {
    this.setValueForChart(
      this.maintenanceValueBarChartElement,
      value,
      first(this.status)?.unit,
    );
  }

  setValueForChart(
    chartElement: JQuery<HTMLElement>,
    value: number,
    unit: string,
  ): void {
    // no element available
    if (isEmpty(chartElement)) {
      return;
    }

    const barElement = chartElement.find("span.bar.vertical.chart");
    const labelElement = chartElement.find("span.bar-label");
    const min = parseFloat(barElement.attr("data-min-value"));
    const max = parseFloat(barElement.attr("data-max-value"));

    let labelText;
    const unitText = isNil(unit) ? "" : unit;
    let percentage;
    if (isNil(value) || isNaN(value)) {
      labelText = `--- ${unitText}`;
      percentage = 0;
    } else {
      labelText = `${Math.round(value * 100.0) / 100.0} ${unitText}`;
      percentage = clamp(((value - min) / (max - min)) * 100.0, 0.0, 100.0);
    }
    barElement.animate({ height: `${percentage}%` });
    labelElement.text(labelText);
  }

  getSensorIdsForUpdate(): number[] {
    return [this.sensorValueId];
  }

  cleanup(): void {
    super.cleanup();
  }
}

/// <reference types="../definitions/index" />
import { defaultsDeep, each, get, isEmpty, isNil } from "lodash";
import moment from "../initializers/moment";
import {
  DateRangeCallback,
  DateRangePickerOptions,
  TimeRanges,
} from "../definitions/bootstrap-daterangepicker";

import { getCallback } from "./html_data_attributes";
import { logger } from "./logger";
import {
  createTimeRanges,
  TranslatedTimeScopeWithReverseMap,
} from "./time_scopes";
import { Moment } from "moment";

interface PickerOptions extends DateRangePickerOptions {
  storeRangeInAnchor?: boolean;
}

interface RangePickerOptionMap {
  [id: string]: PickerOptions;
}

interface RangePickerCallbackMap {
  [id: string]: DateRangeCallback;
}

interface NodeJSTimerMap {
  [id: string]: any;
}

let rangePickerOptions: RangePickerOptionMap = {};
let rangePickerCallbacks: RangePickerCallbackMap = {};
let timeouts: NodeJSTimerMap = {};

export function initializeRangePicker(
  id: string,
  options: PickerOptions,
  callback: DateRangeCallback,
  timeout?: number,
): void {
  const rangesWithMaps = createTimeRanges([
    "last_hour",
    "last_two_hours",
    "today",
    "yesterday",
    "current_week",
    "last_two_weeks",
    "current_month",
    "last_month",
  ]);
  const momentLocale = moment.localeData();
  const defaultOptions: PickerOptions = {
    timePicker: true,
    timePickerIncrement: 10,
    timePicker24Hour: true,
    showCustomRangeLabel: true,
    locale: {
      format: "L LT",
      customRangeLabel: I18n.t(
        "frontend.time_range_picker.select_custom_range",
      ),
      applyLabel: I18n.t("frontend.apply"),
      cancelLabel: I18n.t("frontend.cancel"),
      monthNames: momentLocale.months(),
      firstDay: momentLocale.firstDayOfWeek(),
      daysOfWeek: momentLocale.weekdaysShort(),
    },
    ranges: rangesWithMaps.ranges,
    storeRangeInAnchor: false,
  };

  rangePickerCallbacks[id] = callback;
  rangePickerOptions[id] = defaultsDeep(
    options,
    defaultOptions,
  ) as PickerOptions;
  const dateFormat = get(rangePickerOptions[id], "locale.format", "L LT");
  const storeRangeInAnchor = rangePickerOptions[id].storeRangeInAnchor;

  if (storeRangeInAnchor) {
    // load startDate, endDate from anchor
    const timeRange = loadTimeScopeFromAnchor(rangesWithMaps);
    if (!isNil(timeRange)) {
      options.startDate = timeRange[0];
      options.endDate = timeRange[1];

      // reload data
      $(`#${id} #output`).text(
        timeRange[0].format(dateFormat) +
          " - " +
          timeRange[1].format(dateFormat),
      );
      rangePickerCallbacks[id](
        timeRange[0].toDate(),
        timeRange[1].toDate(),
        options.locale.customRangeLabel,
      );
    }
  }

  $(`#${id}`).daterangepicker(rangePickerOptions[id], (start, end, label) => {
    const timeScopeId = rangesWithMaps.labelToIdMap[label] || "custom";
    $(`#${id} #output`).text(
      moment(start).format(dateFormat) + " - " + moment(end).format(dateFormat),
    );
    rangePickerCallbacks[id](start, end, timeScopeId);
    if (storeRangeInAnchor) {
      addTimeScopeToAnchor(moment(start), moment(end), timeScopeId);
    }
  });

  // update time ranges#
  const updateInMs = isNil(timeout)
    ? moment().endOf("hour").diff(moment())
    : timeout;

  timeouts[id] = setTimeout(
    () => updateRangePicker(id, timeout),
    updateInMs, // update after the end of time range
  );
}

/**
 * Stop all update callbacks(used for unit tests)
 */
export function resetTimeouts(): void {
  each(timeouts, (timeoutId) => {
    clearTimeout(timeoutId);
  });
  timeouts = {};
}

/**
 * Reset stored options and callbacks(used for unit tests)
 */
export function reset(): void {
  rangePickerOptions = {};
  rangePickerCallbacks = {};
}

function updateRangePicker(id: string, timeout?: number): void {
  const options = rangePickerOptions[id];
  const callback = rangePickerOptions[id];
  const dateFormat = get(options, "locale.format", "L LT");

  if (isNil(options) || isNil(callback)) {
    return;
  }

  const picker = $(`#${id}`).data("daterangepicker");

  // picker was deleted
  if (isNil(picker)) {
    return;
  }

  // update date ranges
  options.startDate = picker.startDate;
  options.endDate = picker.endDate;
  const translatedRanges = createTimeRanges();
  options.ranges = translatedRanges.ranges;

  // reinitialize date range picker
  $(`#${id}`).daterangepicker(rangePickerOptions[id], (start, end, label) => {
    $(`#${id} #output`).text(
      moment(start).format(dateFormat) + " - " + moment(end).format(dateFormat),
    );
    rangePickerCallbacks[id](
      start,
      end,
      // map display label to identifier
      translatedRanges.labelToIdMap[label] || "custom",
    );
  });

  // update time ranges
  const updateInMs = isNil(timeout)
    ? moment().endOf("hour").diff(moment())
    : timeout;

  timeouts[id] = setTimeout(
    () => updateRangePicker(id, timeout),
    updateInMs, // update after the end of time range
  );
}

export function addTimeScopeToAnchor(
  start: Moment,
  end: Moment,
  label: string,
): void {
  if (label == I18n.t("frontend.time_range_picker.select_custom_range")) {
    window.location.hash = `#${start.toISOString()}_${end.toISOString()}`;
  } else {
    window.location.hash = `#${encodeURIComponent(label)}`;
  }
}

export function loadTimeScopeFromAnchor(
  timeRanges: TranslatedTimeScopeWithReverseMap,
): [Moment, Moment] {
  const timeRange = decodeURIComponent(
    window.location.hash.replace("#", ""),
  ).split("_");

  if (timeRange.length === 1 && !isEmpty(timeRange[0])) {
    return timeRanges.ranges[0] as [Moment, Moment];
  } else if (timeRange.length === 2) {
    const start = moment(timeRange[0]);
    const end = moment(timeRange[1]);

    if (!start.isValid() || !end.isValid()) return null;

    return [start, end];
  }

  return null;
}

export function initAllDateRangePicker(): void {
  $("[data-toggle=daterangepicker]").each((index, element) => {
    const picker = $(element);

    if (!picker.data("datepicker-attached")) {
      try {
        initDateRangePicker(picker);
      } catch (err) {
        logger.logError(err);
      }
    }
  });
}

export function initDateRangePicker(pickerElement: JQuery): void {
  const callback = getCallback(
    pickerElement.attr("data-chart-id"),
    pickerElement.attr("data-callback"),
  );

  const opens = pickerElement.attr("data-opens").toString() as
    | "left"
    | "right"
    | "center";
  const storeInAnchor = pickerElement.attr("data-store-in-anchor") === "true";
  const start = moment(pickerElement.attr("data-start"), moment.ISO_8601);
  const end = moment(pickerElement.attr("data-end"), moment.ISO_8601);
  const parentId = pickerElement.attr("data-parent");
  const parent = isNil(parentId) ? undefined : $(parentId);
  const dateFormat = "L LT";

  pickerElement
    .find("#output")
    .text(`${start.format(dateFormat)} - ${end.format(dateFormat)}`);

  initializeRangePicker(
    pickerElement.attr("id"),
    {
      locale: {
        format: dateFormat,
      },
      startDate: start,
      endDate: end,
      opens: opens,
      storeRangeInAnchor: storeInAnchor,
      parentEl: parent,
    },

    callback as DateRangeCallback,
  );
  pickerElement.data("datepicker-attached", true);
}

export function initAllSamplingRatePicker(): void {
  $("[data-toggle=samplingrate]").each((index, element) => {
    try {
      const samplingrateInput = $(element);
      if (!samplingrateInput.data("samplingrate-attached")) {
        const callback = getCallback(
          samplingrateInput.attr("data-chart-id"),
          samplingrateInput.attr("data-callback"),
        );

        samplingrateInput
          .find("#sampling-rate, #sampling-rate-unit")
          .change(() => {
            const samplingRateValue = parseInt(
              samplingrateInput.find("#sampling-rate").val().toString(),
            );
            const samplingRateUnit = samplingrateInput
              .find("#sampling-rate-unit")
              .val() as string;
            callback(samplingRateValue, samplingRateUnit);
          });
        samplingrateInput.data("samplingrate-attached", true);
      }
    } catch (err) {
      logger.logError(err as Error);
    }
  });
}

export function initAllBeginAtZeroSelector(): void {
  $("[data-toggle=beginatzero]").each((index, element) => {
    try {
      const beginatzeroInput = $(element);

      if (!beginatzeroInput.data("beginatzero-attached")) {
        const callback = getCallback(
          beginatzeroInput.attr("data-chart-id"),
          beginatzeroInput.attr("data-callback"),
        );

        beginatzeroInput.change(() => {
          callback(beginatzeroInput.prop("checked"));
        });
        beginatzeroInput.data("beginatzero-attached", true);
      }
    } catch (err) {
      logger.logError(err as Error);
    }
  });
}

import * as React from "react";

import {
  clone,
  findIndex,
  first,
  flatten,
  isEqual,
  isNil,
  last,
  snakeCase,
  values,
} from "lodash";
import { DateRange } from "moment-range";
import { useState } from "react";
import { loadAsset } from "../../../json_api/asset";
import { SensorLoader } from "../../../json_api/sensor_loader";
import { client_config_api_dashboards_widget_path } from "../../../routes";
import { loadDataFromUrl, sendData } from "../../../utils/jquery_helper";
import { error, success } from "../../../utils/toasts";
import { IDType } from "../../../utils/urls/url_utils";
import { DashboardSettings } from "../../../widgets/widget.types";
import { ErrorBoundary } from "../../common/error_boundary";
import { WidgetEditForm } from "../../widgets/widget_editor/widget_config_editor";
import {
  DashboardConfig,
  DashboardsWidgetClientConfig,
} from "../dashboard.types";
import {
  DashboardContextProviderProps,
  ModifiedDashboardItem,
} from "./dashboard_context_provider.types";

import { Add, Edit } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Moment } from "moment";
import { logger } from "../../../utils/logger";
import { createTimeRanges, TimeScopeNames } from "../../../utils/time_scopes";
import {
  DashboardsWidgetActions,
  dashboardsWidgetActionUrl,
} from "../../../utils/urls";
import { AppContext } from "../../common/app_context/app_context_provider";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { SialogicDialog } from "../../common/sialogic_dialog";
import { DashboardContext } from "./dashboard_context";
import {
  DashboardContextType,
  WidgetActionArgs,
} from "./dashboard_context.types";
import {
  buildDashboardColumns,
  useDashboardWidgetsQuery,
} from "./dashboard_context_data";
import { DashboardActionContext } from "../dashboard_action_context/dashboard_action_context";
import { DashboardActionContextProvider } from "../dashboard_action_context/dashboard_action_context_provider";

const INSTANT_SAVE_CHANGES = true;

/** Extracts the changed items from a given set of widgets and a modified dashboard item
 *
 *
 * @param {DashboardsWidgetClientConfig[]} changedItems
 * @param {DashboardSettings} modifiedDashboardItem
 * @param {DashboardsWidgetClientConfig[]} widgets
 * @return {*}
 */
function changedItemsFromWidget(
  changedItems: ModifiedDashboardItem[],
  modifiedDashboardItem: DashboardsWidgetClientConfig,
  widgets: DashboardsWidgetClientConfig[],
) {
  const theChangedWidgetIndex = findIndex(
    widgets,
    (w) => w.dashboards_widget_id == modifiedDashboardItem.dashboards_widget_id,
  );

  const originalItem = widgets[theChangedWidgetIndex];
  let newChangedItems = [...changedItems];
  const existingChangedItemIndex = findIndex(
    newChangedItems,
    (w) =>
      w.originalSettings.dashboards_widget_id ==
      modifiedDashboardItem.dashboards_widget_id,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { config, ...originalItemWithoutConfig } = originalItem;

  // We need the dashboard information part (DashboardsWidget attributes) to be merged completely and the config to be replaced as a block if the new config is not null or undefined, otherwise the original config should be kept
  const changedItem = {
    ...originalItemWithoutConfig,
    ...modifiedDashboardItem,
    config: isNil(modifiedDashboardItem.config) // we assume that widget configs change in block. They should not be merged into the original config but replaced
      ? originalItem.config
      : modifiedDashboardItem.config,
  };
  if (existingChangedItemIndex == -1) {
    newChangedItems.push({
      originalSettings: { ...originalItem },
      updatedSettings: changedItem,
    });
  } else {
    const existingChangedItem = newChangedItems[existingChangedItemIndex];

    if (isEqual(changedItem, existingChangedItem.originalSettings)) {
      newChangedItems = newChangedItems.filter(
        (a) =>
          a.originalSettings.dashboards_widget_id !==
          changedItem.dashboards_widget_id,
      );
    } else {
      newChangedItems.splice(existingChangedItemIndex, 1, {
        ...existingChangedItem,
        updatedSettings: changedItem,
      });
    }
  }
  return { newChangedItems, changedItem };
}
/**
 * Extracts the widgets from a given dashboard config
 *
 * @param {DashboardConfig} dashboardConfig
 * @return {*}  {DashboardsWidgetClientConfig[]}
 */
function widgetsFromDashboardConfig(
  dashboardConfig: DashboardConfig,
): DashboardsWidgetClientConfig[] {
  return flatten(flatten(values(dashboardConfig.columns)));
}

/** Saves the dashboard item to the backend
 *
 *
 * @param {DashboardSettings} dashboardItem
 * @param {DashboardsWidgetActions} dashboardItemAction
 * @param {WidgetActionArgs} actionArgs
 */
async function saveDashboardConfig(
  dashboardItem: DashboardSettings,
  dashboardItemAction: DashboardsWidgetActions,
  actionArgs: WidgetActionArgs,
) {
  const url = dashboardsWidgetActionUrl(
    dashboardItem.dashboards_widget_id,
    dashboardItemAction,
  );
  if (url) {
    return sendData(url, actionArgs, "PATCH")
      .then((resp) => {
        void success(
          I18n.t("frontend.success"),
          I18n.t("frontend.dashboard_context_provider.item_update_saved"),
        );
      })
      .catch((e: Error) => {
        void error(
          I18n.t("frontend.error"),
          I18n.t("frontend.dashboard_context_provider.error_saving_item"),
        );
        logger.logError(e);
      });
  }
}
export const DashboardContextProvider: React.FunctionComponent<
  DashboardContextProviderProps
> = ({
  assetId,
  dashboardId,
  assetTypeId,
  dashboardType,
  children,
  baseUrl,
  initialTimeScopeName,
  initialTimeRange,
}) => {
  const [editMode, setEditMode] = useState(false);
  const appContext = React.useContext(AppContext);
  // The timerange reported by the loaded dashboard config - needed to avoid initial double loads as load should only be triggered by selecting time ranges
  const [timeRangeFromDashboard, setTimeRange] =
    useState<DateRange>(initialTimeRange);
  // The timeRange that has been selected by users and should be loaded
  const [selectedTimeRange, setSelectedTimeRange] =
    useState<DateRange>(initialTimeRange);
  const [timeScopeName, setTimeScopeName] =
    useState<string>(initialTimeScopeName);

  const [dashboardConfigs, setDashboardConfigs] = useState<{
    configs: DashboardConfig[];
    changedItems: ModifiedDashboardItem[];
  }>({ configs: undefined, changedItems: [] });

  // Load the dashboard configuration and the available time scopes
  const dashboardWidgetsQuery = useDashboardWidgetsQuery({
    variables: {
      assetId,
      dashboardId,
      assetTypeId,
      trStart: selectedTimeRange?.start?.toISOString(),
      trEnd: selectedTimeRange?.end?.toISOString(),
      timeScopeName: timeScopeName,
    },
  });

  React.useEffect(() => {
    if (isNil(dashboardWidgetsQuery.data?.loadedDateRange)) {
      return;
    }
    if (dashboardWidgetsQuery.data?.loadedDateRange) {
      setTimeRange(dashboardWidgetsQuery.data?.loadedDateRange);
    }
  }, [dashboardWidgetsQuery.data?.loadedDateRange]);

  React.useEffect(() => {
    if (isNil(dashboardWidgetsQuery.data?.loadedTimeScopeName)) {
      return;
    }
    if (dashboardWidgetsQuery.data?.loadedTimeScopeName !== timeScopeName) {
      setTimeScopeName(dashboardWidgetsQuery.data?.loadedTimeScopeName);
    }
  }, [dashboardWidgetsQuery.data?.loadedTimeScopeName]);

  // apply the loaded config to the state
  React.useEffect(() => {
    if (dashboardWidgetsQuery.data) {
      setDashboardConfigs((prev) => ({
        ...prev,
        configs: clone(dashboardWidgetsQuery.data?.configs),
        changedItems: [],
      }));
    }
  }, [dashboardWidgetsQuery.data]);

  // Load the asset and its sensors
  const assetQuery = useQuery({
    queryKey: ["dashboardAssetSensors", assetId],
    queryFn: () => {
      return loadAsset(assetId, ["sensors", "subtree", "root"]).then((a) => {
        // avoid unnecessary loads
        SensorLoader.getInstance().addLoadedSensorsToCache(a.sensors);
        return a;
      });
    },
  });

  // apply the time scope and the selected time range to the url
  React.useEffect(() => {
    if (!timeScopeName || !selectedTimeRange) {
      return;
    }
    let components: string[];
    if (baseUrl) {
      components = first(baseUrl.split("?"))?.split("/");
    } else {
      components = window.location.pathname.split("/");
    }

    const lastComponent = components[components.length - 1];
    if (lastComponent == "custom" || TimeScopeNames.includes(lastComponent)) {
      components[components.length - 1] = timeScopeName;
    } else {
      components.push(timeScopeName);
    }

    let params;
    if (timeScopeName == "custom") {
      // store the time scope params
      params = `start_time=${selectedTimeRange.start.toISOString()}&end_time=${selectedTimeRange.end.toISOString()}`;
    }
    // is this a good idea ?
    // we will loose nice urls when the user changes the time range
    window.history.replaceState(
      window.history.state,
      null,
      components.join("/") + (params ? `?${params}` : ""),
    );
  }, [timeScopeName, selectedTimeRange]);

  // memoized context
  const context = React.useMemo<DashboardContextType>(() => {
    return {
      dashboardConfigs: dashboardConfigs?.configs,
      loading: dashboardWidgetsQuery.isLoading || assetQuery.isLoading,
      // the asset, its subtree and sensors
      asset: assetQuery.data,
      availableTimeScopes:
        dashboardWidgetsQuery.data?.availableTimeScopes ?? createTimeRanges(),
      timeRange: isNil(selectedTimeRange)
        ? (timeRangeFromDashboard ?? null)
        : selectedTimeRange,
      timeScopeName,
      canEdit: appContext.user.isAdmin,
      editMode,

      onChangeTimeScope: (
        timeRange: [Moment, Moment],
        selectedTimeScopeName: string,
      ) => {
        setSelectedTimeRange(new DateRange(timeRange));
        setTimeScopeName(selectedTimeScopeName);
      },
      itemSelected: (itemId: IDType, itemType: string, eventInfo: any) => {},

      onUpdateDashboardItem: (
        dashboardItem: DashboardsWidgetClientConfig,
        dashboardItemAction,
        actionArgs,
      ) => {
        setDashboardConfigs((prev) => {
          let newChangedItems = [...prev.changedItems];
          const newDashboardConfigs = prev?.configs.map((dbc) => {
            const widgets = widgetsFromDashboardConfig(dbc);

            const theChangedWidgetIndex = findIndex(
              widgets,
              (w) =>
                w.dashboards_widget_id == dashboardItem.dashboards_widget_id,
            );

            if (theChangedWidgetIndex == -1) {
              // return the original dashboard config when widget not found
              return dbc;
            }

            const adjustedItems = changedItemsFromWidget(
              newChangedItems,
              dashboardItem,
              widgets,
            );

            newChangedItems = adjustedItems.newChangedItems;

            if (INSTANT_SAVE_CHANGES && dashboardItemAction) {
              // if it fails the dashboard and its backend state will be out of sync
              void saveDashboardConfig(
                dashboardItem,
                dashboardItemAction,
                actionArgs,
              );
            }

            // replace the widget config by the merged data
            widgets[theChangedWidgetIndex] = adjustedItems.changedItem;

            return {
              ...dbc,
              columns: buildDashboardColumns(widgets),
            };
          });
          return {
            configs: newDashboardConfigs,
            changedItems: newChangedItems,
          };
        });
      },
      onEditDashboardItem: (dashboardItem: DashboardsWidgetClientConfig) => {
        setEditWidget(dashboardItem);
      },
      onChangeEditMode: (editMode: boolean) => {
        setEditMode(editMode);
      },
      onNewDashboardItem: () => {
        setEditWidget({ widget_id: null, config: null });
      },
    };
  }, [
    dashboardConfigs?.configs,
    selectedTimeRange,
    editMode,
    timeScopeName,
    dashboardWidgetsQuery.isLoading,
    dashboardWidgetsQuery.data?.availableTimeScopes,
    assetQuery.data,
  ]);

  // The widget that is currently being edited
  const [editWidget, setEditWidget] =
    useState<DashboardsWidgetClientConfig>(null);

  return (
    <DashboardContext.Provider value={context}>
      <DashboardActionContextProvider>
        {children}
        {isNil(editWidget) ? null : (
          <SialogicDialog
            maxWidth="xl"
            open={!isNil(editWidget)}
            allowFullScreen
            fullWidth
            onClose={() => setEditWidget(null)}
            title={I18n.t(
              "frontend.dashboard_context_provider.edit_widget_title",
            )}
          >
            <ErrorBoundary>
              <WidgetEditForm
                widgetId={editWidget.widget_id}
                dashboardId={dashboardId}
                dashboardType={dashboardType}
                assetId={assetId}
                assetTypeId={context.asset.asset_type_id}
                widgetTypeIdentifier={snakeCase(
                  last(editWidget.widget_type?.split("::")),
                )}
                onCancel={() => setEditWidget(null)}
                onSaved={(widget, editFinished) => {
                  if (isNil(editWidget.dashboards_widget_id)) {
                    window.location.reload();
                    return;
                  }
                  void loadDataFromUrl<DashboardsWidgetClientConfig>(
                    client_config_api_dashboards_widget_path(
                      editWidget.dashboards_widget_id,
                      {
                        _options: true,
                        asset_id: assetId,
                        time_scope_name: context.timeScope,
                        start_time: context.timeRange?.start?.toISOString(),
                        end_time: context.timeRange?.end?.toISOString(),
                      },
                    ),
                  )
                    .then((updatedSettings) => {
                      context.onUpdateDashboardItem(
                        updatedSettings,
                        "config_change",
                        null,
                      );
                    })
                    .catch((e) => {
                      void error(
                        I18n.t("frontend.error"),
                        I18n.t(
                          "frontend.dashboard_context_provider.error_loading_widget_config",
                        ),
                      );
                    })
                    .finally(() => {
                      if (editFinished) {
                        setEditWidget(null);
                      }
                    });
                }}
              />
            </ErrorBoundary>
          </SialogicDialog>
        )}
        {editMode && isNil(editWidget) ? (
          <FixedBottomArea>
            <FloatingButtons
              showScrollToTopBtn
              onCancel={() => setEditMode(false)}
              submitBtnIcon={editMode ? <Add /> : <Edit />}
              onSubmit={() => {
                if (editMode) {
                  setEditWidget({ widget_id: null, config: null });
                } else {
                  setEditMode(true);
                }
              }}
            ></FloatingButtons>
          </FixedBottomArea>
        ) : null}
      </DashboardActionContextProvider>
    </DashboardContext.Provider>
  );
};

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";
import { DeviceJSONObject } from "../../json_api/device";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { DeviceAssignAssetForm } from "./device_assign_asset_form";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { DeviceBatchCreateForm } from "./device_batch_create_form";
import { DevicePairing } from "./device_pairing";

const deviceAssignAssetFormRoots: Root[] = [];
export function initializeDeviceAssignAssetForm(): void {
  $('[data-toggle="device-assign-asset-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');
    const assetsJsonApiDoc = theElem.data("assets") as CollectionResourceDoc<
      string,
      AssetJSONObject
    >;
    const assets =
      jsonApiResourceCollectionToFlatObjects<AssetJSONObject>(assetsJsonApiDoc);
    const currentAssetJsonApi = theElem.data("asset") as SingleResourceDoc<
      string,
      AssetJSONObject
    >;
    const currentAsset = jsonApiSingleResourceToFlatObject(currentAssetJsonApi);
    const currentAssetId = theElem.data("asset-id") as number;
    const deviceJsonApiDoc = theElem.data("device") as SingleResourceDoc<
      string,
      DeviceJSONObject
    >;
    const device =
      jsonApiSingleResourceToFlatObject<DeviceJSONObject>(deviceJsonApiDoc);
    const root = createRoot(element);
    deviceAssignAssetFormRoots.push(root);
    root.render(
      <AppRoot>
        <DeviceAssignAssetForm
          ibox={true}
          asset={currentAsset}
          assetId={currentAssetId}
          assets={assets}
          device={device}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyDeviceAssignAssetForm() {
  deviceAssignAssetFormRoots.forEach((root) => {
    root.unmount();
  });
  deviceAssignAssetFormRoots.length = 0;
}

const devicePairingFormRoots: Root[] = [];

export function initializeDevicePairingForm(): void {
  $('[data-toggle="device-pairing-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');
    const deviceJsonApiDoc = theElem.data("device") as SingleResourceDoc<
      string,
      DeviceJSONObject
    >;
    const device =
      jsonApiSingleResourceToFlatObject<DeviceJSONObject>(deviceJsonApiDoc);
    const root = createRoot(element);
    devicePairingFormRoots.push(root);
    root.render(
      <AppRoot>
        <DevicePairing ibox={true} device={device} />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyDevicePairingForm() {
  devicePairingFormRoots.forEach((root) => {
    root.unmount();
  });
  devicePairingFormRoots.length = 0;
}

const deviceBatchCreateFormRoots: Root[] = [];

export function initializeDeviceBatchCreateForm(): void {
  $('[data-toggle="device-batch-create-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');
    const organizationJsonApiDoc = theElem.data(
      "organization",
    ) as SingleResourceDoc<string, DeviceJSONObject>;
    const organization = jsonApiSingleResourceToFlatObject<DeviceJSONObject>(
      organizationJsonApiDoc,
    );
    const root = createRoot(element);
    deviceBatchCreateFormRoots.push(root);
    root.render(
      <AppRoot>
        <DeviceBatchCreateForm ibox={true} organization={organization} />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyDeviceBatchCreateForm() {
  deviceBatchCreateFormRoots.forEach((root) => {
    root.unmount();
  });
  deviceBatchCreateFormRoots.length = 0;
}

/// <reference types="../../definitions/index" />;
import { Button, Grid } from "@mui/material";
import { find, has, isEmpty, isNil, some } from "lodash";
import * as React from "react";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { RoleList } from "./role_list";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import FastRewindIcon from "@mui/icons-material/FastRewind";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FastForwardIcon from "@mui/icons-material/FastForward";
import { ErrorMap } from "../../utils/error_map";
import { sendData } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import * as toast from "../../utils/toasts";
import * as url_helper from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { Role } from "./role_list_item";

export interface User {
  id: number | string;
  full_name: string;
  roles: Role[];
}

interface UserRoleFormProps {
  user: User;
  availableRoles: Role[];
  readOnly?: boolean;
}

interface UserRoleFormState {
  unselectedRoles: Role[];
  selectedRoles: Role[];
  isProcessing: boolean;
  errors: ErrorMap;
}

/**
 * A form to assign roles to a user.
 */
export class UserRoleForm extends React.Component<
  UserRoleFormProps,
  UserRoleFormState
> {
  /**
   * Create lists of available and selected roles.
   * @param props
   */
  static getRoleLists(props: UserRoleFormProps): {
    unselectedRoles: Role[];
    selectedRoles: Role[];
  } {
    const unselectedRoles = [] as Role[];
    const selectedRoles = [] as Role[];

    props.availableRoles.forEach((role) => {
      const selectedRole = find(
        props.user.roles,
        (r) =>
          r.name === role.name && r.organization_id === role.organization_id,
      );

      if (selectedRole) {
        selectedRoles.push(role);
      } else {
        unselectedRoles.push(role);
      }
    });

    return {
      unselectedRoles,
      selectedRoles,
    };
  }

  /**
   * Return wether a role within the list is checked.
   * @param roles A list of roles.
   */
  static isRoleChecked(roles: Role[]): boolean {
    return some(roles, (role) => role.checked);
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  constructor(props: UserRoleFormProps) {
    super(props);

    this.state = {
      ...UserRoleForm.getRoleLists(props),
      isProcessing: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps: UserRoleFormProps): void {
    if (
      prevProps.user !== this.props.user ||
      prevProps.availableRoles !== this.props.availableRoles
    ) {
      this.setState({
        ...UserRoleForm.getRoleLists(this.props),
      });
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <h4>
                  {I18n.t("activerecord.models.user.one")} "
                  {this.props.user.full_name}"
                </h4>
              </IBoxTitle>
            </IBox>
            <IBoxContent>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={5}>
                      <RoleList
                        title={I18n.t(
                          "frontend.user_role_form.available_roles",
                        )}
                        roles={this.state.unselectedRoles}
                        onChange={(roles) =>
                          this.onChangeUnselectedRoles(roles)
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid container direction="column" alignItems="center">
                        <Button
                          id="add_all_roles"
                          variant="outlined"
                          title={I18n.t("frontend.user_role_form.add_all")}
                          onClick={() => this.onAddAll()}
                          disabled={isEmpty(this.state.unselectedRoles)}
                        >
                          <FastForwardIcon />
                        </Button>
                        <Button
                          id="add_selected_roles"
                          variant="outlined"
                          title={I18n.t("frontend.user_role_form.add_selected")}
                          onClick={() => this.onAddSelected()}
                          disabled={
                            !UserRoleForm.isRoleChecked(
                              this.state.unselectedRoles,
                            )
                          }
                        >
                          <ArrowRightIcon />
                        </Button>
                        <Button
                          id="remove_selected_roles"
                          variant="outlined"
                          title={I18n.t(
                            "frontend.user_role_form.remove_selected",
                          )}
                          onClick={() => this.onRemoveSelected()}
                          disabled={
                            !UserRoleForm.isRoleChecked(
                              this.state.selectedRoles,
                            )
                          }
                        >
                          <ArrowLeftIcon />
                        </Button>
                        <Button
                          id="remove_all_roles"
                          variant="outlined"
                          title={I18n.t("frontend.user_role_form.remove_all")}
                          onClick={() => this.onRemoveAll()}
                          disabled={isEmpty(this.state.selectedRoles)}
                        >
                          <FastRewindIcon />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <RoleList
                        title={I18n.t("frontend.user_role_form.assigned_roles")}
                        roles={this.state.selectedRoles}
                        onChange={(roles) => this.onChangeSelectedRoles(roles)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </IBoxContent>
          </Grid>
        </Grid>
        <FixedBottomArea id="fixed-bottom-area">
          {this.props.readOnly ? null : (
            <FloatingButtons
              isProcessing={this.state.isProcessing}
              onSubmit={() => {
                void this.onSubmit();
              }}
              onCancel={() => this.onCancel()}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.user_role_form.submit_title")}
            />
          )}
        </FixedBottomArea>
      </>
    );
  }

  private onChangeUnselectedRoles(roles: Role[]): void {
    this.setState({
      unselectedRoles: roles,
    });
  }

  private onChangeSelectedRoles(roles: Role[]): void {
    this.setState({
      selectedRoles: roles,
    });
  }

  private onAddAll(): void {
    this.setState((prevState) => {
      const selectedRoles = [
        ...prevState.selectedRoles,
        ...prevState.unselectedRoles,
      ];

      return {
        unselectedRoles: [] as Role[],
        selectedRoles: selectedRoles,
      };
    });
  }

  private onAddSelected(): void {
    this.setState((prevState) => {
      const selectedRoles = [...prevState.selectedRoles];
      const unselectedRoles = [] as Role[];

      prevState.unselectedRoles.forEach((role) => {
        if (role.checked) {
          selectedRoles.push({
            ...role,
            checked: false,
          });
        } else {
          unselectedRoles.push(role);
        }
      });

      return {
        unselectedRoles: unselectedRoles,
        selectedRoles: selectedRoles,
      };
    });
  }

  private onRemoveSelected(): void {
    this.setState((prevState) => {
      const unselectedRoles = [...prevState.unselectedRoles];
      const selectedRoles = [] as Role[];

      prevState.selectedRoles.forEach((role) => {
        if (role.checked) {
          unselectedRoles.push({
            ...role,
            checked: false,
          });
        } else {
          selectedRoles.push(role);
        }
      });

      return {
        unselectedRoles: unselectedRoles,
        selectedRoles: selectedRoles,
      };
    });
  }

  private onRemoveAll(): void {
    this.setState((prevState) => {
      const unselectedRoles = [
        ...prevState.unselectedRoles,
        ...prevState.selectedRoles,
      ];

      return {
        unselectedRoles: unselectedRoles,
        selectedRoles: [] as Role[],
      };
    });
  }

  private onSubmit(): Promise<any> {
    this.setState({ isProcessing: true });

    // Find roles to add
    const newRoles: Role[] = [...this.state.selectedRoles];

    // Find roles to remove
    this.props.user.roles.forEach((role) => {
      const hasRole = some(
        this.state.selectedRoles,
        (r) =>
          r.name === role.name && r.organization_id === role.organization_id,
      );

      if (!hasRole) {
        newRoles.push({
          name: role.name,
          organization_id: role.organization_id,
          _destroy: true,
        });
      }
    });

    const user = {
      roles: newRoles,
    };

    return sendData(
      url_helper.assignRolesUserPath(this.props.user.id, "json"),
      { user: user },
      "PATCH",
    )
      .then(() => {
        this.setState({ isProcessing: false });
        this.redirect();
      })
      .catch((error: { request: { responseJSON: any } }) => {
        this.setState({ isProcessing: false });
        // load errors from response if present
        if (has(error, "request.responseJSON")) {
          this.setState({
            errors: error.request.responseJSON as ErrorMap,
          });
        }

        void toast.error(
          I18n.t("frontend.user_role_form.error"),
          I18n.t("frontend.user_role_form.error_title"),
          true,
        );
      });
  }

  private onCancel(): void {
    this.setState({ isProcessing: false });
    this.redirect();
  }

  private redirect(): void {
    if (!isNil(this.context.referrer) && !isEmpty(this.context.referrer)) {
      // redirect to referrer
      redirectTo(this.context.referrer);
    } else {
      redirectTo("/");
    }
  }
}

import { isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";

import { DocWithErrors } from "jsonapi-typescript";

import { Delete } from "@mui/icons-material";
import { AssetJSONObject } from "../../json_api/asset";
import {
  AssetEventJSONAPIAttributes,
  AssetEventJSONObject,
  deleteAssetEvent,
  saveAssetEvent,
} from "../../json_api/asset_event";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
} from "../../json_api/jsonapi_tools";
import { ResourcePermission } from "../../models/resource_permission";
import { asset_asset_events_path } from "../../routes";
import { HttpError } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { AssetEventFormFields } from "./asset_event_form_fields";

export interface AssetEventFormProps {
  assetId: IDType;
  asset?: AssetJSONObject;
  assetEventId?: IDType;
  assetEvent?: AssetEventJSONAPIAttributes;

  permissions?: ResourcePermission;

  // Provide a list of assets when assets should be selectable. Otherwise only the provided assetId wil be used.
  selectableAssets?: AssetJSONObject[];
  selectFromAssetTreeWithAssetId?: IDType;
  withCard?: boolean;
  withFloatingButtons?: boolean;
  errors?: ModelErrors<AssetEventJSONObject>;
  onCancel?: () => void;
  onDeleted?: (event: AssetEventJSONObject) => void;
  onSaved?: (assetEvent: AssetEventJSONAPIAttributes) => void;
  onChangeAssetEvent?: (assetEvent: AssetEventJSONObject) => void;
}

export const AssetEventForm: FunctionComponent<AssetEventFormProps> = ({
  withCard = false,
  ...props
}) => {
  const isNew = React.useCallback(
    () => isNil(props.assetEventId) && isNil(props.assetEvent?.id),
    [props.assetEventId, props.assetEvent?.id],
  );

  const [assetEvent, setAssetEvent] = React.useState(props.assetEvent);
  const [errors, setErrors] = React.useState<ModelErrors<AssetEventJSONObject>>(
    {},
  );
  const [loading, setLoading] = React.useState(false);
  const [runningLoads, setRunningLoads] = React.useState(0);

  React.useEffect(() => {
    if (props.onChangeAssetEvent && assetEvent != props.assetEvent) {
      props.onChangeAssetEvent(assetEvent);
    }
  }, [assetEvent]);

  React.useEffect(() => {
    if (assetEvent !== props.assetEvent) {
      setAssetEvent(props.assetEvent);
    }
  }, [props.assetEvent]);
  React.useEffect(() => {
    if (errors !== props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);
  // determine load state
  React.useEffect(() => {
    setLoading(runningLoads > 0);
  }, [runningLoads]);

  // update asset id whenevent asset selection changes in props
  React.useEffect(() => {
    const id = props.assetId || props.asset?.id;
    if (assetEvent?.asset_id != id) {
      setAssetEvent({ ...assetEvent, asset_id: props.assetId });
    }
  }, [props.assetId]);

  const title = isNew()
    ? I18n.t("frontend.asset_events.asset_event_form.title_new")
    : I18n.t("frontend.asset_events.asset_event_form.title_edit");

  let form = (
    <>
      {loading ? (
        <>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rectangular" height={100} />
          </Stack>
        </>
      ) : (
        <AssetEventFormFields
          errors={errors}
          assetEventId={props.assetEventId}
          assetEvent={assetEvent}
          selectableAssets={props.selectableAssets}
          selectFromAssetTreeWithAssetId={props.selectFromAssetTreeWithAssetId}
          onChangeAssetEvent={(e) => setAssetEvent(e)}
        />
      )}
    </>
  );

  if (withCard) {
    form = (
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={4}>
            {form}
          </Grid>
        </CardContent>
        {props.withFloatingButtons ? null : (
          <CardActions>
            <Button
              onClick={() => {
                props.onCancel ? props.onCancel() : redirectTo();
              }}
            >
              {I18n.t("frontend.cancel")}
            </Button>
            <Button
              onClick={() => {
                setRunningLoads(runningLoads + 1);
                saveAssetEvent(assetEvent)
                  .then(() => {
                    void success(
                      I18n.t("frontend.success"),
                      I18n.t("frontend.saved_successfully"),
                    );
                  })
                  .catch((e) => {
                    setErrors(
                      extractErrorsFromJsonApi<AssetEventJSONObject>(
                        (e as HttpError).request.responseJSON as DocWithErrors,
                      ),
                    );
                  })
                  .finally(() => {
                    setRunningLoads(runningLoads - 1);
                  });
              }}
            >
              {I18n.t("frontend.save")}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
  if (props.withFloatingButtons) {
    form = (
      <>
        {form}
        <FixedBottomArea>
          <FloatingButtons
            onCancel={() => {
              props.onCancel ? props.onCancel() : redirectTo();
            }}
            onSubmit={async () => {
              setRunningLoads(runningLoads + 1);
              saveAssetEvent(assetEvent)
                .then(() => {
                  void success(
                    I18n.t("frontend.success"),
                    I18n.t("frontend.saved_successfully"),
                  );
                })
                .catch((e) => {
                  setErrors(
                    extractErrorsFromJsonApi<AssetEventJSONObject>(
                      (e as HttpError).request.responseJSON as DocWithErrors,
                    ),
                  );
                })
                .finally(() => {
                  setRunningLoads(runningLoads - 1);
                });
            }}
          >
            {props.permissions?.destroy && !isNil(assetEvent) ? (
              <Tooltip title={I18n.t("frontend.delete")}>
                <>
                  <div />
                  <Fab
                    size="medium"
                    color="error"
                    onClick={() => {
                      setLoading(true);
                      void deleteAssetEvent(assetEvent?.id)
                        .finally(() => {
                          setLoading(false);
                        })
                        .then(() => {
                          if (props.onDeleted) {
                            props.onDeleted(assetEvent);
                          } else {
                            redirectTo(
                              asset_asset_events_path(assetEvent.asset_id),
                            );
                          }
                        });
                    }}
                  >
                    <Delete />
                  </Fab>
                </>
              </Tooltip>
            ) : null}
          </FloatingButtons>
        </FixedBottomArea>
      </>
    );
  }
  return form;
};

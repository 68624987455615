import { isNil } from "lodash";
import { unitDisplayString } from "../utils/unit_conversion";
import { Plotly } from "./plotly_package";

/**
 * Gets the name of the axis property the in the chart layout
 * @param axisType Type of axis x or y
 * @param index The zero-based index of the axis
 */
export function getAxisPropertyName(
  axisType: "x" | "y",
  index: number,
): string {
  if (index === 0) {
    return `${axisType}axis`;
  }

  return `${axisType}axis${index + 1}`;
}

/**
 * Gets the name of the axis in the chart data
 * @param axisType Type of axis x or y
 * @param index The zero-based index of the axis
 */
export function getAxisChartDataName(
  axisType: "x" | "y",
  index: number,
): string {
  if (index === 0) {
    return `${axisType}`;
  }

  return `${axisType}${index + 1}`;
}

/**
 * Gets the name of the axis in the chart data from the axis property name
 * @param axisType Type of axis x or y
 * @param index The zero-based index of the axis
 */
export function getAxisChartDataNameFromProperty(
  axisPropertyName: string,
): string {
  return axisPropertyName.replace("axis", "");
}

/**
 * Padding between chart axis
 */
export const AxisPadding = 0.06;

/**
 *
 *
 * @export
 * @param {number} axisIndex
 * @param {string} title
 * @param {string} unit Unit
 * @param {[number, number]} [range]
 * @returns {Partial<Plotly.LayoutAxis>}
 */
export function createAxisLayout(
  axisIndex: number,
  title: string,
  unit = "",
  range?: [number, number],
): Partial<Plotly.LayoutAxis> {
  const side = axisIndex % 2 === 0 ? "left" : "right";

  const axis: Partial<Plotly.LayoutAxis> = {
    title: title,
    side: side,
    autorange: isNil(range),
    range: range,
    spikethickness: 2,
    automargin: true,
    ticksuffix: isNil(unit) ? "" : " " + unitDisplayString(unit),
    tickformat: ",.2f",
  };

  // fix all additional y axis to first y axis
  if (axisIndex > 0) {
    axis.overlaying = "y";
  }

  // add position offsets if there are more than 2 axes
  if (axisIndex > 1) {
    axis.anchor = "free";
    axis.position =
      side === "left"
        ? Math.floor(axisIndex / 2) * AxisPadding
        : 1.0 - Math.floor(axisIndex / 2) * AxisPadding;
  }

  return axis;
}

import { isNil } from "lodash";
import moment, { Moment } from "moment";

export function getTimeString(
  timeScopeName: string,
  timestamp: Moment,
): string {
  if (!isNil(timeScopeName)) {
    return timeScopeName;
  }

  if (!isNil(timestamp) && moment.isMoment(timestamp) && timestamp.isValid()) {
    return timestamp.format("L LTS");
  }

  return "";
}

export function getTimeRangeString(
  start: Moment | string,
  end: Moment | string,
): string {
  if (isNil(start)) {
    start = "---";
  } else {
    start = I18n.l("time.formats.sialogic_short", moment(start).toDate());
  }
  if (isNil(end)) {
    end = "---";
  } else {
    end = I18n.l("time.formats.sialogic_short", moment(end).toDate());
  }

  return `${start} - ${end}`;
}

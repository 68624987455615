import * as React from "react";

import { isNil } from "lodash";
import { AppRoot } from "../components/common/app_root";
import { KpiWidget as KpiWidgetComponent } from "../components/widgets/kpi_widget";
import { KpiWidgetConfigSerialized } from "./kpi_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the KpiWidget.
 *
 * @class KpiWidget
 * @extends {Widget}
 */
export default class KpiWidget extends Widget {
  config: KpiWidgetConfigSerialized;
  linkUrl: string;
  static getDomClassName(): string {
    return "kpi-widget";
  }

  static widgetClassName(): string {
    return "KpiWidget";
  }

  constructor(element: JQuery<HTMLElement>) {
    super(element);
    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    this.root.render(
      <AppRoot>
        <KpiWidgetComponent
          widgetId={this.widgetId as number}
          assetId={this.config.asset_id}
          dashboardSettings={this.dashboardSettings}
          icon={this.config.icon}
          value={this.config.value}
          title={this.config.title}
          vertical={this.config.vertical == true}
          titleLinkUrl={this.config.title_link}
          contentLinkUrl={this.config.title_link}
          linkTarget={this.config.title_link_target}
          timestamp={
            isNil(this.config.timestamp) ? null : moment(this.config.timestamp)
          }
          sensors={this.config.sensors}
          units={this.config.units}
          precision={this.config.precision}
        />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    this.config = element.data("config") as KpiWidgetConfigSerialized;
  }

  cleanup(): void {
    super.cleanup();
  }
}

/// <reference types="../definitions/index" />;
import moment from "moment";

export function formatTimestampDuration(timestamp: string): string {
  const duration = moment.duration(
    moment().diff(timestamp, "seconds"),
    "second",
  );
  if (duration.asSeconds() <= 1) {
    return I18n.t("base.timespan.now");
  } else if (duration.asMinutes() < 1) {
    return I18n.t("base.timespan.seconds", {
      time: Math.floor(duration.asSeconds()).toString(),
    });
  } else if (duration.asHours() < 1) {
    return I18n.t("base.timespan.minutes", {
      time: Math.floor(duration.asMinutes()).toString(),
    });
  } else if (duration.asDays() < 1) {
    return I18n.t("base.timespan.hours", {
      time: Math.floor(duration.asHours()).toString(),
    });
  } else {
    return moment(timestamp).format("DD.MM.YYYY, HH:mm:ss");
  }
}

import {
  formatString,
  IDType,
  localizedBasePath,
  RequestFormat,
} from "./url_utils";

export function assetReportPlansReportsPath(
  assetId: IDType,
  reportPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/report_plans/${reportPlanId}/reports${formatString(
    format,
  )}`;
}

export function assetReportsPlanReportPath(
  assetId: IDType,
  reportPlanId: IDType,
  reportId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/report_plans/${reportPlanId}/reports/${reportId}${formatString(
    format,
  )}`;
}

export function assetReportPlanSubscribePath(
  assetId: IDType,
  reportPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/report_plans/${reportPlanId}/subscribe${formatString(
    format,
  )}`;
}

export function assetReportPlanUnsubscribePath(
  assetId: IDType,
  reportPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/report_plans/${reportPlanId}/unsubscribe${formatString(
    format,
  )}`;
}

import {
  interpolateBlues,
  interpolateCool,
  interpolateMagma,
  interpolateOrRd,
  interpolateRdYlGn,
} from "d3-scale-chromatic";
import { isEmpty, isNil, keys, map } from "lodash";
import {
  SensorValueRange,
  ValueRangeStatus,
} from "../models/sensor_value_range";
import { State } from "../models/state";

export type ColorRgb = [number, number, number];

export interface ColorDefinition {
  defaultColor: ColorRgb;
  secondaryColor: ColorRgb;
  colors: ColorRgb[];
}

export type ScaleColorFunc = (
  value: number,
  maxValue?: number,
  minValue?: number,
  fallback?: string,
) => string;

interface ColorInterpolatorHash {
  [interPolatorName: string]: ScaleColorFunc;
}

export interface ColorInterpolator {
  id: string;
  scale: ScaleColorFunc;
}

export interface ColorSchema {
  title: string;
  id: string;
}

export function getRgbaColorString(color: ColorRgb, alpha: number): string {
  return `rgba(${color[0]},${color[1]},${color[2]},${alpha.toFixed(2)})`;
}

export function getRgbColorString(color: ColorRgb): string {
  return `rgb(${color[0]},${color[1]},${color[2]})`;
}

export class ColorUtils {
  static colors: ColorDefinition = {
    defaultColor: [28, 132, 198],
    secondaryColor: [26, 179, 148],
    colors: [
      [28, 132, 198],
      [26, 179, 148],
      [237, 85, 101],
      [248, 172, 89],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [35, 198, 200],
    ],
  };

  static colorSchemes: ColorSchema[] = [
    {
      title: "Red-Yellow-Green",
      id: "interpolateRdYlGn",
    },
    {
      title: "Magma (Blue-Yellow-Red)",
      id: "interpolateMagma",
    },
    { title: "Blues", id: "interpolateBlues" },
    { title: "OrRd - White to dark Red", id: "interpolateOrRd" },
    { title: "Cool", id: "interpolateCool" },
  ];

  static colorInterpolators: ColorInterpolatorHash = {
    interpolateRdYlGn,
    interpolateMagma,
    interpolateBlues,
    interpolateCool,
    interpolateOrRd,
  };

  static colorSchemaNames(): string[] {
    return keys(this.colorInterpolators).sort(function (a: string, b: string) {
      return a.localeCompare(b);
    });
  }

  static getDefaultColorRgb(): string {
    const defaultColor = ColorUtils.colors.defaultColor;
    return getRgbColorString(defaultColor);
  }

  static getDefaultColorRgba(alpha = 1.0): string {
    const defaultColor = ColorUtils.colors.defaultColor;
    return getRgbaColorString(defaultColor, alpha);
  }

  static getColorsRgba(alpha = 1.0): string[] {
    return map(ColorUtils.colors.colors, (color) => {
      return getRgbaColorString(color, alpha);
    });
  }

  static getColorsRgb(alpha = 1.0): string[] {
    return map(ColorUtils.colors.colors, (color) => {
      return getRgbColorString(color);
    });
  }

  static getRedGreenColor(
    value: number,
    maxValue = 100,
    minValue = 0,
    fallback: string = null,
  ) {
    if (isNaN(value) || isNaN(maxValue) || isNaN(minValue)) {
      return fallback;
    }
    return interpolateRdYlGn(
      (maxValue - (value - minValue)) / (maxValue - minValue),
    );
  }

  static getCriticalityColorNormalized(normalizedValue: number): string {
    return interpolateRdYlGn(normalizedValue);
  }

  static getColorFor(
    normalizedValue: number,
    interpolator = "interpolateRdYlGn",
  ): string {
    return this.colorInterpolators[interpolator](normalizedValue);
    //return interpolateRdYlGn(normalizedValue);
  }

  static ValueRangeStatusMap: Record<ValueRangeStatus, number> = {
    fatal: 100,
    critical: 80,
    normal: 20,
    optimal: 0,
  };

  static getColorForNormalizedValue(
    value: number,
    interpolatorId = "interpolateRdYlGn",
  ): string {
    return this.getColorFor(value, interpolatorId);
  }
}

const defaultStateColors = ColorUtils.getColorsRgba(0.5);
export function getStateColor(
  state: Partial<State>,
  index: number = null,
  fallback: string = getRgbaColorString([0.8, 0.8, 0.8], 0.2),
): string {
  if (isNil(state)) return fallback;
  if (!isEmpty(state.color)) {
    return state.color;
  } else if (!isNil(state.criticality)) {
    return ColorUtils.getRedGreenColor(state.criticality, 100, 0);
  } else if (isFinite(index)) {
    return defaultStateColors[index % defaultStateColors.length];
  } else {
    return fallback;
  }
}

export function getColorForSensorValueRangeStatus(
  status: ValueRangeStatus,
  fallback: string = null,
) {
  if (isEmpty(status)) {
    return fallback;
  }
  return ColorUtils.getRedGreenColor(ColorUtils.ValueRangeStatusMap[status]);
}
export function getColorForSensorValueRange(
  range: SensorValueRange,
  fallback: string = null,
) {
  if (isNil(range)) {
    return fallback;
  }

  if (!isEmpty(range.color)) {
    return range.color;
  } else {
    return getColorForSensorValueRangeStatus(range?.status, fallback);
  }
}

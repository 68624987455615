import {
  FormControl,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty, isNil, map } from "lodash";
import * as React from "react";
import { GROUPED_MEASUREMENT_VALUE_UNITS } from "../../models/measurement_value_definition";
export interface MeasurementUnitSelectProperties {
  error?: string;
  allowEdit?: boolean;
  unit?: string;
  labelText?: string;
  id: string;
  onChange?: (newUnit: string) => void;
}

export const MeasurementUnitSelect: React.FunctionComponent<
  MeasurementUnitSelectProperties
> = (props) => {
  return (
    <FormControl fullWidth={true}>
      {props.allowEdit === false ? (
        <TextField
          label={
            props.labelText
              ? props.labelText
              : I18n.t(
                  "activerecord.attributes.measurement_value_definition.unit",
                )
          }
          InputProps={{ readOnly: true }}
          value={!isNil(props.unit) ? props.unit : ""}
        />
      ) : (
        <TextField
          size="small"
          label={
            props.labelText
              ? props.labelText
              : I18n.t(
                  "activerecord.attributes.measurement_value_definition.unit",
                )
          }
          disabled={false}
          select={props.allowEdit}
          value={!isNil(props.unit) ? props.unit : ""}
          id={props.id}
          error={!isEmpty(props.error)}
          onChange={(event) => {
            if (!isNil(props.onChange))
              props.onChange(event.target.value);
          }}
        >
          {map(GROUPED_MEASUREMENT_VALUE_UNITS, (unitsInGroup, groupName) => [
            <ListSubheader key={groupName}>
              <Typography variant="body2">
                {" --- "}
                {I18n.t(`frontend.measurement_unit_group.${groupName}`)}
              </Typography>
            </ListSubheader>,
            unitsInGroup.map((unit, index) => (
              <MenuItem key={`${groupName}${index}`} value={unit}>
                {unit}
              </MenuItem>
            )),
          ])}
        </TextField>
      )}
    </FormControl>
  );
};

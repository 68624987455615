import {
  Add,
  Clear,
  Delete,
  Edit,
  LinkRounded,
  Search,
} from "@mui/icons-material";
import {
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridDensity,
  GridPaginationModel,
} from "@mui/x-data-grid";
import * as JSONAPI from "jsonapi-typescript";
import { defaultTo, first, isNil, toString } from "lodash";
import * as React from "react";

import { AssetJSONObject } from "../../json_api/asset";
import { ExternalReferenceJSONObject } from "../../json_api/external_reference";
import { ExternalReferenceTargetJSONObject } from "../../json_api/external_reference_target";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { OrganizationJSONAPIAttributes } from "../../json_api/organization";
import {
  ExternalReference,
  ExternalReferenceItemType,
} from "../../models/external_reference";
import {
  api_asset_external_references_path,
  api_organization_external_references_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButton, FloatingButtons } from "../common/floating_buttons";
import { ExternalReferenceForm } from "./external_reference_form";
import { confirmAndDeleteExternalReference } from "./utils";
import { SialogicDialog } from "../common/sialogic_dialog";

export interface ExternalReferenceListProps {
  referencedItemId?: IDType;
  referencedItemType?: ExternalReferenceItemType;
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  externalReferences?: ExternalReference[];

  tableHeight?: number;
  maxTableHeight?: string;
  density?: GridDensity;
  enableSearch?: boolean;
  onSelect: (externalReference: ExternalReference) => void;
}

export const ExternalReferenceList: React.FunctionComponent<
  ExternalReferenceListProps
> = ({
  tableHeight = 300,
  pageNumber = 1,
  pageSize = 20,
  maxTableHeight = "75vh",
  enableSearch = true,
  ...props
}: ExternalReferenceListProps) => {
  const [loadCount, setLoadCount] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [formOpen, setFormOpen] = React.useState<boolean>(false);
  const [appliedSearchTerm, setAppliedSearchTerm] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalItems, setTotalItems] = React.useState<number>();
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({ pageSize: 10, page: 1 });

  const [referencedItem, setReferencedItem] = React.useState<
    AssetJSONObject | OrganizationJSONAPIAttributes
  >(null);
  const [externalReferences, setExternalReferences] = React.useState<
    ExternalReference[]
  >(props.externalReferences);
  const [selectedReference, setSelectedReference] =
    React.useState<ExternalReference>(null);

  React.useEffect(() => {
    if (!isNil(props.onSelect)) {
      props.onSelect(selectedReference);
    }
  }, [selectedReference]);

  const loadExternalReferences = React.useCallback(
    async (
      relatedItemId: IDType,
      pageNumber: number,
      pageSize: number,
      searchTerm: string,
    ) => {
      try {
        setLoading(true);

        const pathFunction =
          props.referencedItemType == "Organization"
            ? api_organization_external_references_path
            : api_asset_external_references_path;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment

        const pathOptions: Record<
          string,
          string | number | { number: number; size: number }
        > = {
          locale: I18n.locale,
          page: { number: defaultTo(pageNumber, 0), size: pageSize },
          include: "external_reference_target,referenced_item",
          format: "json",
        };
        let path: string;
        if (props.referencedItemType == "Organization") {
          // current organization is implicit through controller
          pathOptions["organization_id"] = props.referencedItemId;
          path = api_organization_external_references_path(
            props.referencedItemId,
            pathOptions,
          );
        } else {
          pathOptions["asset_id"] = relatedItemId;
          path = api_asset_external_references_path(
            props.referencedItemId,
            pathOptions,
          );
        }

        const jsonApiResponse =
          await loadDataFromUrl<
            JSONAPI.CollectionResourceDoc<string, OrganizationJSONAPIAttributes>
          >(path);
        setTotalItems(jsonApiResponse.meta.record_count as number);
        const externalRefs =
          jsonApiResourceCollectionToFlatObjects<ExternalReferenceJSONObject>(
            jsonApiResponse,
          );

        const firstRef = first(externalRefs);
        if (firstRef) {
          setReferencedItem(firstRef.referencedItem);
        }
        return externalRefs;
      } catch (e) {
        logger.error(e);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  React.useEffect(() => {
    // load references because of page change, search, or page size change

    void loadExternalReferences(
      props.referencedItemId,
      paginationModel.page,
      paginationModel.pageSize,
      appliedSearchTerm,
    ).then((refs) => setExternalReferences(refs));
  }, [
    appliedSearchTerm,
    paginationModel.page,
    paginationModel.pageSize,
    loadCount,
  ]);

  const gridColDef = React.useCallback<() => GridColDef[]>(() => {
    const defaultWidth = 130;
    const largeWidth = 250;
    const columns: GridColDef[] = [
      { field: "id", headerName: "#" },
      {
        field: "external_reference_target_id",
        headerName: I18n.t("activerecord.models.external_reference_target", {
          count: 1,
        }),
        align: "left",
        width: largeWidth,
        renderCell(params) {
          return (
            params.row
              .external_reference_target as ExternalReferenceTargetJSONObject
          ).name;
        },
      },
      {
        field: "external_id",
        headerName: I18n.t(
          "activerecord.attributes.external_reference.external_id",
        ),
        width: largeWidth,
      },
      {
        field: "note",
        headerName: I18n.t("activerecord.attributes.external_reference.note"),
        width: defaultWidth,
      },
      {
        field: "actions",
        headerName: I18n.t("base.actions"),
        width: largeWidth,
        renderCell: (params: GridCellParams) => {
          const link = (
            params.row
              ?.external_reference_target as ExternalReferenceTargetJSONObject
          ).url_template?.replace("{id}", params.row.external_id as string);
          return (
            <ButtonGroup size="small">
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setSelectedReference(params.row as ExternalReference);
                  setFormOpen(true);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => {
                  void confirmAndDeleteExternalReference(
                    props.referencedItemId,
                    params.row.id as number,
                    setLoading,
                  ).then(() => {
                    setLoadCount(loadCount + 1);
                  });
                }}
              >
                <Delete />
              </IconButton>

              <IconButton
                size="small"
                href={link}
                target="_blank"
                title={I18n.t("frontend.external_references.list.follow_link", {
                  link,
                })}
              >
                <LinkRounded />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ];
    return columns;
  }, []);

  return (
    <Grid container>
      {enableSearch ? (
        <Grid item xs={12} className="mb-1">
          <TextField
            size="small"
            className="float-right"
            value={toString(toString(searchTerm))}
            label={I18n.t("frontend.search")}
            onChange={(event) => {
              setSearchTerm(event.currentTarget.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setAppliedSearchTerm(searchTerm);
                    }}
                  >
                    <Search />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="default"
                    onClick={() => {
                      setSearchTerm(null);
                      setAppliedSearchTerm(null);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                setAppliedSearchTerm(searchTerm);
                event.stopPropagation();
              }
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <div
          style={{
            height: defaultTo(tableHeight, 500),
            width: "100%",
            maxHeight: defaultTo(maxTableHeight, 1000),
          }}
        >
          <DataGrid
            paginationMode="server"
            //pagination={isNil(this.state.organizations) ? undefined : true}
            paginationModel={{
              pageSize: paginationModel.pageSize,
              page: (paginationModel.page || 1) - 1,
            }}
            initialState={{
              density: defaultTo(props.density, "standard"),
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={defaultTo(totalItems, 0)}
            //rowsPerPageOptions={pageSizes}

            rows={defaultTo(externalReferences, [])}
            columns={gridColDef()}
            loading={loading}
            onRowClick={(params) =>
              setSelectedReference(params.row as ExternalReference)
            }
            onPaginationModelChange={(paginationModel) =>
              setPaginationModel({
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
              })
            }
          />
        </div>
      </Grid>
      <FixedBottomArea key="bot-buttons">
        <FloatingButtons
          showScrollToTopBtn
          isProcessing={loading}
          //onSubmit={() => void this.onDelete(this.getSelectedSensorIds())}
          onCancel={() => redirectTo()}
          submitBtnColor="secondary"
        >
          <Grid container>
            <Grid item xs={12}>
              <FloatingButton
                size="medium"
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
                  setFormOpen(true)
                }
                title={I18n.t("frontend.create")}
                color="secondary"
              >
                <Add />
              </FloatingButton>
            </Grid>
          </Grid>
        </FloatingButtons>
      </FixedBottomArea>
      <SialogicDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setFormOpen(false);
        }}
        open={formOpen}
        title={I18n.t("activerecord.models.external_reference", { count: 1 })}
      >
        <ExternalReferenceForm
          buttonMode="card"
          externalReferenceId={selectedReference?.id}
          referencedItemId={props.referencedItemId}
          referencedItemType={props.referencedItemType}
          mode={isNil(selectedReference?.id) ? "new" : "show"}
          onCancel={() => {
            setFormOpen(false);
            setSelectedReference(null);
          }}
          onSuccess={(externalRef) => {
            setLoadCount(loadCount + 1);
            // clear selected item

            setFormOpen(false);
            setSelectedReference(null);
          }}
        />
      </SialogicDialog>
    </Grid>
  );
};

import UploadIcon from "@mui/icons-material/CloudUpload";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Blob as ActiveStorageBlob } from "@rails/activestorage";
import { isNil, noop } from "lodash";
import * as React from "react";

import { FileUploadListItem } from "./file_upload_list_item";

export interface UploadedFile {
  name: string;
  type: string;
  size: number;
  url?: string;
  blob?: ActiveStorageBlob;
  signed_id?: string;

  preview_url?: string;
}

interface FileUploadInputProps {
  id?: string;
  files?: Array<File | UploadedFile>;
  onShow?: (file: File | UploadedFile) => void;
  onChange?: (files: Array<File | UploadedFile>) => void;
}

interface FileUploadInputState {
  isHovered: boolean;
}

export class FileUploadInput extends React.Component<
  FileUploadInputProps,
  FileUploadInputState
> {
  static defaultProps: Partial<FileUploadInputProps> = {
    files: [],
    onShow: noop,
    onChange: noop,
  };

  fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: FileUploadInputProps) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      isHovered: false,
    };
  }

  render(): React.ReactNode {
    return (
      <Grid container>
        <Grid item xs={12}>
          <List
            style={{ border: "1px dashed #ccc", borderRadius: "5px" }}
            className={this.getDropContainerClass()}
            onDragOver={(event) => this.onDragOver(event)}
            onDragLeave={(event) => this.onDragLeave(event)}
            onDrop={(event) => this.onDrop(event)}
          >
            {this.props.files.map((file, index) => {
              return (
                <FileUploadListItem
                  key={`file-item-${index}`}
                  file={file}
                  onShow={() => this.props.onShow(file)}
                  onChange={(file) => {
                    const newFiles = [...this.props.files];
                    newFiles[index] = file;
                    this.props.onChange(newFiles);
                  }}
                  onRemove={() => {
                    const newFiles = [...this.props.files];
                    newFiles.splice(index, 1);
                    this.props.onChange(newFiles);
                  }}
                />
              );
            })}
            <ListItem>
              <Grid container>
                <Grid item xs={12} onClick={() => this.onAddFile()}>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    className="auto-hyphen"
                  >
                    <UploadIcon className="mr-2" />
                    {I18n.t(
                      "frontend.common.file_upload_input.drop_file_to_upload",
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <input
              title="file"
              ref={this.fileInputRef}
              style={{ display: "none" }}
              type="file"
              multiple
              onChange={(event) => this.onFileAdded(event)}
            />
          </List>
        </Grid>
      </Grid>
    );
  }

  private onAddFile(): void {
    if (isNil(this.fileInputRef.current)) {
      return;
    }

    this.fileInputRef.current.click();
  }

  private onFileAdded(event: React.ChangeEvent<HTMLInputElement>): void {
    {
      const newFiles = [...this.props.files, ...Array.from(event.target.files)];
      this.props.onChange(newFiles);
    }
  }

  private getDropContainerClass(): string {
    return this.state.isHovered ? "drop-hover" : "";
  }

  private onDragOver(event: React.DragEvent): void {
    event.preventDefault();

    this.setState({
      isHovered: true,
    });
  }

  private onDragLeave(event: React.DragEvent): void {
    event.preventDefault();
    this.setState({
      isHovered: false,
    });
  }

  private onDrop(event: React.DragEvent): void {
    event.preventDefault();
    const files = [
      ...this.props.files,
      ...Array.from(event.dataTransfer.files),
    ];
    this.props.onChange(files);
    this.setState({
      isHovered: false,
    });
  }
}

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FormControlLabel, Grid, Radio } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { findIndex, isEmpty, isNil, toString } from "lodash";
import * as React from "react";
import { MeasurementPlan } from "../../../models/measurement_plan";
import {
  AllMeasurementTypeTypes,
  AllMeasurementTypeTypesUnderscore,
  MeasurementType,
  MeasurementTypes,
} from "../../../models/measurement_type";
import { TypedErrorMap } from "../../../utils/error_map";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";

interface MeasurementPlanFormStep1Properties {
  templates: MeasurementType[];
  measurementPlan: MeasurementPlan;
  createBy: MeasurementPlanCreationMode;
  errors: TypedErrorMap<MeasurementPlan>;
  typeOfMeasurement?: MeasurementTypes;
  templateType: MeasurementType;
  onFinish: (
    createBy: MeasurementPlanCreationMode,
    plan: MeasurementPlan,
    templateType: MeasurementType,
    type: MeasurementTypes,
  ) => void;
  onUpdateMeasurementPlan: (mPlan: MeasurementPlan) => void;
  onSelectCreationMode: (createBy: MeasurementPlanCreationMode) => void;
  onSelectTypeOfMeasurement: (type: MeasurementTypes) => void;

  onSelectTemplate: (template: MeasurementType) => void;
  onCancel: () => void;
}

export type MeasurementPlanCreationMode = "template" | "measurementType";
interface MeasurementPlanFormStep1State {}

/**
 * A list of maintenace plans of an asset.
 */
export const MeasurementPlanFormStep1: React.FC<
  MeasurementPlanFormStep1Properties
> = (props) => {
  const [typeHelpText, setTypeHelpText] = React.useState<string>(null);
  React.useEffect(() => {
    if (!props.typeOfMeasurement) {
      setTypeHelpText(null);
    } else {
      const index = AllMeasurementTypeTypes.indexOf(props.typeOfMeasurement);
      if (index == -1) {
        return;
      }
      setTypeHelpText(
        I18n.t(
          `frontend.measurement_plan_form.help_text.${AllMeasurementTypeTypesUnderscore[index]}`,
        ),
      );
    }
  }, [props.typeOfMeasurement]);
  return (
    <IBox>
      <IBoxTitle>
        <h4>
          {I18n.t(
            "frontend.measurement_plan_form.create_measurement_plan_step_1_heading",
            {},
          )}
        </h4>
      </IBoxTitle>
      <IBoxContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              id="name-text-field"
              label={I18n.t("activerecord.attributes.measurement_plan.title")}
              required={true}
              value={toString(props.measurementPlan?.measurement_type.title)}
              error={!isNil((props.errors?.measurement_type as any)?.title)}
              helperText={(props.errors?.measurement_type as any)?.title}
              onKeyUp={(keyUpEvent) => {
                if (keyUpEvent.key === "Enter") {
                  keyUpEvent.stopPropagation();
                  handleProceed(props);
                }
              }}
              onChange={(changeEvent) => {
                props.onUpdateMeasurementPlan({
                  ...props.measurementPlan,
                  measurement_type: {
                    ...props.measurementPlan?.measurement_type,
                    title: changeEvent.target.value,
                  },
                });
              }}
            ></TextField>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} className="mt-2 mb-2">
              <FormControl size="small">
                <FormControlLabel
                  label={I18n.t(
                    "frontend.measurement_plan_form.create_from_template",
                  )}
                  control={
                    <Radio
                      size="small"
                      disabled={isEmpty(props.templates)}
                      checked={props.createBy === "template"}
                      value="template"
                      onChange={(changeEvent) => {
                        if (changeEvent.target.checked) {
                          props.onSelectCreationMode("template");
                        }
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} className="mb-3">
              <Grid item xs={1} />
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  size="small"
                  select
                  label={I18n.t(
                    "frontend.measurement_plan_form.select_template",
                  )}
                  disabled={props.createBy !== "template"}
                  id="select-template-select"
                  value={currentTemplateSelectionValue(props)}
                  onChange={(event) => {
                    handleTemplateSelection(
                      parseInt(event.target.value),
                      props,
                    );
                  }}
                >
                  {templateSelectionValues(props)}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  label={I18n.t(
                    "frontend.measurement_plan_form.create_as_type",
                  )}
                  control={
                    <Radio
                      size="small"
                      checked={props.createBy === "measurementType"}
                      value="measurementType"
                      onChange={(changeEvent) => {
                        if (changeEvent.target.checked) {
                          props.onSelectCreationMode("measurementType");
                        }
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  label={I18n.t("frontend.measurement_plan_form.select_type")}
                  disabled={props.createBy !== "measurementType"}
                  id="select-type-select"
                  value={toString(props.typeOfMeasurement)}
                  onChange={(event) => {
                    props.onSelectTypeOfMeasurement(
                      event.target.value as MeasurementTypes,
                    );
                  }}
                  helperText={typeHelpText}
                >
                  {AllMeasurementTypeTypes.map((type, index) => (
                    <MenuItem value={type} key={index}>
                      {I18n.t(
                        `activerecord.models.measurement_types/${AllMeasurementTypeTypesUnderscore[index]}`,
                        { count: 1 },
                      )}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </IBoxContent>
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn={true}
          isProcessing={false}
          onCancel={props.onCancel}
          onSubmit={() => {
            handleProceed(props);
          }}
          submitBtnIcon={<NavigateNextIcon />}
        ></FloatingButtons>
      </FixedBottomArea>
    </IBox>
  );
};
function currentTemplateSelectionValue(
  props: MeasurementPlanFormStep1Properties,
) {
  if (isEmpty(props.templates)) return "";

  return !isNil(props.templateType)
    ? findIndex(props.templates, (t) => t.id == props.templateType.id)
    : 0;
}
function templateSelectionValues(props: MeasurementPlanFormStep1Properties) {
  if (isEmpty(props.templates))
    return (
      <MenuItem value={-1} selected={true}>
        {I18n.t("frontend.measurement_plan_form.no_template")}
      </MenuItem>
    );

  return props.templates.map((type, index) => (
    <MenuItem key={index} value={index}>
      {type.title}
    </MenuItem>
  ));
}

function handleTemplateSelection(
  templateIndex: number,
  props: MeasurementPlanFormStep1Properties,
) {
  if (
    !isNaN(templateIndex) &&
    templateIndex != -1 &&
    templateIndex >= 0 &&
    templateIndex < props.templates.length
  ) {
    props.onSelectTemplate(props.templates[templateIndex]);
  }
}

function handleProceed(props: MeasurementPlanFormStep1Properties) {
  props.onFinish(
    props.createBy,
    props.measurementPlan,
    props.templateType,
    props.typeOfMeasurement,
  );
}

/// <reference types="../../definitions/index" />;
import { Card, CardHeader, Divider, List } from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { Role, RoleListItem } from "./role_list_item";

interface RoleListProps {
  title: string;
  roles: Role[];
  onChange(roles: Role[]): void;
}

export function RoleList(props: RoleListProps) {
  return (
    <Card>
      <CardHeader title={props.title} />
      <Divider />
      <List style={{ minHeight: "500px" }}>
        {props.roles.map((role, index) => {
          const key = `role-${role.name}-${role.organization_id}-${role.owner_organization_id}`;

          return (
            <RoleListItem
              key={key}
              role={role}
              onChange={(checked) => {
                const newRole = {
                  ...role,
                  checked,
                };
                const newRoles = [...props.roles];
                newRoles[index] = newRole;
                props.onChange(newRoles);
              }}
            />
          );
        })}
      </List>
    </Card>
  );
}

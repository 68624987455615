import Bluebird from "bluebird";
import { toInteger, toString } from "lodash";
import {
  CastedContextStateChange,
  ContextStateChange,
} from "../../models/context_state_machine";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import {
  StateChangesOptions,
  apiContextStateMachineStateChangesPath,
} from "../../utils/urls/context_state_machines";
import { IDType } from "../../utils/urls/url_utils";

export function loadStateChangeData(
  contextStateMachineId: IDType,
  options: StateChangesOptions,
): Bluebird<CastedContextStateChange[]> {
  return loadDataFromUrl<ContextStateChange[]>(
    apiContextStateMachineStateChangesPath(contextStateMachineId, options),
  ).then((stateChanges) => {
    return Bluebird.resolve(
      stateChanges.map((change) => {
        return {
          ...change,
          state_id: toString(change.state_id),
          previous_state_id: toString(change.previous_state_id),
          time: moment(change.time),
          user_id: toInteger(change.user_id),
        };
      }),
    );
  });
}

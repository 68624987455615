import { MenuItem, TextField } from "@mui/material";
import {
  defaultTo,
  find,
  isNil,
  snakeCase,
  toArray,
  toInteger,
  toString,
} from "lodash";
import * as React from "react";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import { getTranslatedProp } from "../../utils/globalize";
import { IDType } from "../../utils/urls/url_utils";

interface ContextStateMachineSelectProps {
  selectedContextStateMachineId: IDType;
  contextStateMachines?: ContextStateMachineJSONObject[];
  onSelectContextStateMachine?: (csm: ContextStateMachineJSONObject) => void;
}

export const ContextStateMachineSelect: React.FunctionComponent<
  ContextStateMachineSelectProps
> = (props) => {
  const [selectedContextStateMachineId, setSelectedContextStateMachineId] =
    React.useState<IDType>(props.selectedContextStateMachineId);
  const [contextStateMachines, setContextStateMachines] = React.useState<
    ContextStateMachineJSONObject[]
  >(props.contextStateMachines);

  React.useEffect(() => {
    const theCsm = find(
      contextStateMachines,
      (csm) => csm.id == selectedContextStateMachineId,
    );
    props.onSelectContextStateMachine(theCsm);
  }, [selectedContextStateMachineId]);

  React.useEffect(() => {
    setContextStateMachines(props.contextStateMachines);
  }, [props.contextStateMachines]);

  return (
    <TextField
      size="small"
      fullWidth
      select
      value={toString(defaultTo(selectedContextStateMachineId, "---"))}
      label={I18n.t("frontend.diagram_settings.state_context")}
      onChange={(event) => {
        if (event.target.value === "---") {
          setSelectedContextStateMachineId(null);
        } else {
          setSelectedContextStateMachineId(toInteger(event.target.value));
        }
      }}
    >
      <MenuItem key={"no_selection"} value={"---"}>
        {I18n.t("frontend.none")}
      </MenuItem>
      {toArray(contextStateMachines).map((csm, index) => {
        return ctxStateSelectMenuItem(
          csm,
          index,
          contextStateMachines.length > 2,
        );
      })}
    </TextField>
  );
};

function ctxStateSelectMenuItem(
  csm: ContextStateMachineJSONObject,
  index: number,
  showStatefulItem: boolean,
): React.ReactNode {
  let additionalInfo: string = null;
  if (!isNil(csm.stateful_item_type)) {
    if (isNil(csm.stateful_item)) {
      additionalInfo = `${I18n.t(
        `activerecord.models.${snakeCase(csm.stateful_item_type)}`,
        { count: 1 },
      )} ${toString(csm.stateful_item_id)}`;
    } else {
      additionalInfo = getTranslatedProp(csm.stateful_item, "name");
      if (isNil(additionalInfo)) {
        additionalInfo = getTranslatedProp(csm.stateful_item, "title");
      }
    }
  }
  return (
    <MenuItem key={index} value={csm.id}>
      {csm.state_context.name}
      {showStatefulItem ? (
        <>
          {additionalInfo == null ? null : " - "}
          {additionalInfo}
        </>
      ) : null}
    </MenuItem>
  );
}

import { isArray } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { Organization } from "../../models/organization";
import { AppRoot } from "../common/app_root";
import { AssetTemplateTree } from "./models/models";
import { AssetTemplateForm } from "./views/asset_template_form";

const assetTemplateFormRoots: Root[] = [];
/**
 * Initialize react component MaintenanceContainer within all elements with data-toggle="maintenance-form".
 * Initial state is loaded fron "data-maintenance-job-groups".
 * State is expected to be in JSON format.
 */
export function initializeAssetTemplateForm(): void {
  $('[data-toggle="asset-template-form"]').each((index, element) => {
    //const assetTemplateJsonApi = $(element).data('asset-templates');
    let assetTemplateTree = $(element).data("asset-template-tree") as
      | AssetTemplateTree
      | AssetTemplateTree[];
    const organization = $(element).data("organization") as Organization;
    const url = $(element).data("url") as string;
    if (isArray(assetTemplateTree)) {
      assetTemplateTree = assetTemplateTree[0];
    }
    const root = createRoot(element);
    //const assetTemplates = jsonApiSingleResourceToFlatObject(assetTemplateJsonApi) as any;
    root.render(
      <AppRoot>
        <AssetTemplateForm
          assetTemplates={assetTemplateTree}
          url={url}
          organization_id={organization?.id}
        />
      </AppRoot>,
    );
    assetTemplateFormRoots.push(root);
  });
}

/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyAssetTemplateForm(): void {
  assetTemplateFormRoots.forEach((root) => root.unmount());
  assetTemplateFormRoots.length = 0;
  //resetState();
}

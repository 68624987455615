import { Settings } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import Bluebird from "bluebird";
import geojson from "geojson";
import * as L from "leaflet";
import {
  compact,
  defaultTo,
  isEmpty,
  isNil,
  isNumber,
  isString,
  merge,
  toInteger,
} from "lodash";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { getMarkerIconForFeature } from "../../map/feature_icons";
import { getFeaturePopupContent } from "../../map/feature_popup";
import {
  AssetFeatureType,
  SensorFeatureProperties,
  ZoomSettings,
} from "../../map/map.types";
import {
  SamplingRate,
  SensorSamplingRateUnit,
  SensorValueType,
} from "../../models/sensor";
import { State } from "../../models/state";
import { StateContext } from "../../models/state_context";
import { widgetMinHeight } from "../../utils/widget_height_class";
import { AssetMapWidgetConfigSerialized } from "../../widgets/asset_map_widget.types";
import { ItemSelection } from "../../widgets/item_selection";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { DiagramSettings } from "../diagram_settings";
import {
  loadAssetLocations,
  loadLocationSensorData,
} from "./asset_map_widget.data";
import { AssetMapWidgetProps } from "./asset_map_widget.types";
import {
  handleLocationUpdate,
  handleNonLocationSensorValueUpdate,
  initMap,
} from "./asset_map_widget_utils";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";

export const AssetMapWidget: React.FunctionComponent<AssetMapWidgetProps> = ({
  allowFullscreen = true,
  zoom = { min: null, max: null },
  tileZoom = { min: null, max: null },
  ...props
}: AssetMapWidgetProps) => {
  const [timeRange, setTimeRange] = useState(props.timeRange);
  const [sensorIds, setSensorIds] = useState(props.sensorIds);
  const [startPosition, setStartPos] = useState(props.startPosition);
  const [fullscreen, setFullscreen] = useState(false);
  const [renderDiagramSettings, setRenderDiagramSettings] = useState(
    props.renderDiagramSettings,
  );

  const mapNode = useRef<HTMLDivElement>(null);
  const prevMapNode = useRef<HTMLDivElement>(null);
  const map = useRef<L.Map>(null);
  const sensorsLayer = useRef<L.Realtime>(null);
  const assetLayer = useRef<L.FeatureGroup>(null);
  const mapLayer = useRef<L.TileLayer>(null);
  const mapDataLoaded = useRef<Bluebird<any>>(null);

  const assetIdMarkerMap = useRef<Map<number, L.Marker>>(new Map());
  const lineFeatures = useRef<{
    [sensorId: string]: geojson.FeatureCollection;
  }>({});
  const pointFeatures = useRef<{
    [sensorId: string]: geojson.FeatureCollection<
      geojson.Point,
      SensorFeatureProperties
    >;
  }>({});
  const assetSensorIdMarkerMap = useRef<Map<number, L.Marker>>(new Map());
  const assetContextStateMachineIdMarkerMap = useRef<Map<number, L.Marker>>(
    new Map(),
  );

  const samplingRate = useRef<SamplingRate>(props.samplingRate);

  const context = useContext(DashboardContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ref callback
  const mapRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null && !map.current) {
        const { map: createdMap } = initMap(node, {
          tileZoom,
          zoom,
          startPosition,
          startZoom: props.startZoom,
          mapUrl: props.mapUrl,
          attribution: props.attribution,
        });
        map.current = createdMap;
      }
    },
    [map],
  );

  useEffect(() => {
    if (!mapNode.current) {
      return;
    }
    prevMapNode.current = mapNode.current;

    if (prevMapNode.current === mapNode.current) {
      return;
    }
    const newMapValues = initMap(mapNode.current, {
      tileZoom,
      zoom,
      startPosition,
      startZoom: props.startZoom,
      mapUrl: props.mapUrl,
      attribution: props.attribution,
    });
    assetIdMarkerMap.current = newMapValues.assetIdMarkerMap;
    assetSensorIdMarkerMap.current = newMapValues.assetSensorIdMarkerMap;
    assetContextStateMachineIdMarkerMap.current =
      newMapValues.assetContextStateMachineIdMarkerMap;
    map.current = newMapValues.map;

    return () => {
      if (!isNil(map.current)) {
        if (!isNil(mapDataLoaded.current)) {
          mapDataLoaded.current.cancel();
          mapDataLoaded.current = null;
        }
        map.current.off();
        map.current.remove();
        map.current = null;
      }
    };
  }, [mapNode.current]);

  const handleContextStateMachineUpdate = (
    contextStateMachineId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
  ) => {
    const marker = assetContextStateMachineIdMarkerMap.current.get(
      contextStateMachineId,
    );
    if (!isNil(marker)) {
      const feature = marker.feature as AssetFeatureType;
      const stateInfo = feature.properties.states[stateContext.identifier];
      stateInfo.criticality = newState.criticality;
      stateInfo.name = newState.name;
      stateInfo.icon = newState.icon;
      stateInfo.color = newState.color;
      stateInfo.identifier = newState.identifier;
      stateInfo.state_id = toInteger(newState.id);
      marker.setPopupContent(getFeaturePopupContent(feature));
      marker.setIcon(
        getMarkerIconForFeature(
          feature,
          props.markerMappingMode,
          props.markerMappingStateContextIdentifier,
          props.markerMappingSensorKey,
        ),
      );
    }
  };

  const handleSensorValueUpdate = (
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ) => {
    if (!isNil(timeRange) && !timeRange.contains(time)) {
      return;
    }

    if (isNumber(value) || isString(value)) {
      handleNonLocationSensorValueUpdate(
        assetSensorIdMarkerMap.current,
        sensorId,
        value,
        time,
        props,
      );
    } else {
      handleLocationUpdate(
        pointFeatures.current,
        lineFeatures.current,
        sensorsLayer.current,
        sensorId,
        value,
        time,
        attributeKeyId,
      );
    }
  };

  const updateTimeRange = (start: Moment, end: Moment) => {
    if (!isNil(start) || !isNil(end)) {
      setTimeRange(new DateRange(start, end));
      loadMapData();
    } else {
      setTimeRange(props.timeRange);
    }
  };

  const setStartPosition = () => {
    if (map.current && isNil(startPosition)) {
      const assetBounds = !isNil(assetLayer.current)
        ? assetLayer.current.getBounds()
        : null;
      const sensorBounds = !isNil(sensorsLayer.current)
        ? sensorsLayer.current.getBounds()
        : null;

      if (!isNil(assetBounds) && assetBounds.isValid()) {
        map.current.fitBounds(assetBounds, {
          maxZoom: defaultTo(props.startZoom, 7),
        });
      } else if (!isNil(sensorBounds) && sensorBounds.isValid()) {
        map.current.fitBounds(sensorBounds, { maxZoom: props.startZoom });
      } else {
        map.current.setView([51.029672, 10.120477], 6);
      }

      setStartPos([
        map.current.getCenter().lat,
        map.current.getCenter().lng,
        map.current.getZoom(),
      ]);
    }
  };

  const updateSamplingRate = (newSamplingRate: SamplingRate): void => {
    samplingRate.current = newSamplingRate;
    loadMapData();
  };

  const loadMapData = (): void => {
    if (!map.current) return;

    if (!isNil(mapDataLoaded.current)) {
      mapDataLoaded.current.cancel();
    }

    mapDataLoaded.current = Bluebird.all([
      loadAssetLocations(
        props,
        assetIdMarkerMap,
        assetSensorIdMarkerMap,
        assetContextStateMachineIdMarkerMap,
        assetLayer,
        map,
      ),
      loadLocationSensorData(props, sensorsLayer, map),
    ]).then(() => {
      setStartPosition();
    });
  };

  const handleItemSelection = (itemSelection: ItemSelection<any>): void => {
    if (itemSelection.itemType === "Asset" && itemSelection.source !== this) {
      const marker = assetIdMarkerMap.current.get(itemSelection.itemId);
      if (!isEmpty(marker)) {
        map.current.setView(marker.getLatLng(), 13);
        marker.openPopup();
      }
    }
  };

  return (
    <WidgetBox
      {...props}
      title={props.title ?? I18n.t("frontend.widgets.asset_map_widget.title")}
      onFullscreen={(fullscreen) => setFullscreen(fullscreen)}
      tools={[
        <IconButton
          key="dset"
          onClick={() => setRenderDiagramSettings(!renderDiagramSettings)}
        >
          <Settings />
        </IconButton>,
      ]}
    >
      <Grid container>
        <Grid item xs={12} p={2}>
          <DiagramSettings
            showBeginAtZero={false}
            startDate={defaultTo(timeRange?.start, null)}
            endDate={defaultTo(timeRange?.end, null)}
            visible={renderDiagramSettings}
            showTimeRange={!isNil(timeRange)}
            showSamplingRate={isEmpty(sensorIds)}
            onChangeTimeRange={(startTime, endTime) =>
              updateTimeRange(startTime, endTime)
            }
            onChangeSamplingRate={(samplingRate, mode) =>
              updateSamplingRate(samplingRate)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box height={fullscreen ? "75vh" : props.mapHeight}>
            <Box
              ref={mapRef}
              height="100%"
              width="100%"
              minHeight={widgetMinHeight(props.dashboardSettings?.height, 300)}
            />
          </Box>
        </Grid>
      </Grid>
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: AssetMapWidgetConfigSerialized,
): AssetMapWidgetProps {
  // load asset ids from data attribute
  const assetIds = config.asset_ids;
  const assetTypeId = config.asset_type_id;
  const sensorIds = config.sensor_ids;
  const renderDiagramSettings = defaultTo(
    config.render_diagram_settings,
    false,
  );

  const enableAssetClustering = defaultTo(config.enable_asset_clustering, true);

  // load map options from data attributions
  const mapUrl = config.map_url;

  const loadSensorWithTypes = compact(config.load_sensors_with_types);
  //this.loadSensorWithTypes = ['resource_level', 'operating_time_count'];//

  const loadAssetStates = defaultTo(config.load_asset_states, false);
  //this.loadAssetStates = false;
  const markerMappingSensorKey = config.marker_mapping_sensor_key;

  const markerMappingStateContextIdentifier =
    config.marker_mapping_state_context_identifier;
  //this.markerMappingSensorKey = 'ActFill';
  const markerMappingMode = config.marker_mapping_mode;
  //this.markerMappingMode = 'sensor';

  const attribution = config.map_attribution;
  const startPosition = config.start_position;
  const startZoom = config.start_zoom;

  const zoom: ZoomSettings = { min: null, max: null };
  if (!isNil(config.min_zoom)) {
    zoom.min = config.min_zoom;
  }
  if (!isNil(config.max_zoom)) {
    zoom.max = config.max_zoom;
  }
  const tileZoom: ZoomSettings = { min: null, max: null };

  if (!isNil(config.tile_max_zoom)) {
    tileZoom.max = config.tile_max_zoom;
  }
  if (!isNil(config.tile_min_zoom)) {
    tileZoom.min = config.tile_min_zoom;
  }
  // sampling rate
  const samplingRateUnit = config.sampling_rate_unit;
  const samplingRateVal = config.sampling_rate_value;

  let samplingRate: SamplingRate;
  if (!isNil(samplingRateVal)) {
    samplingRate = {
      value: samplingRateVal,
      unit: samplingRateUnit as SensorSamplingRateUnit,
    };
  } else {
    samplingRate = {
      value: null,
      unit: samplingRateUnit as SensorSamplingRateUnit,
    };
  }

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetIds,
    sensorIds,
    assetTypeId,
    zoom,
    tileZoom,
    mapUrl,
    samplingRate,

    attribution,
    startPosition,
    mapHeight: config.height,
    startZoom,
    markerMappingMode,
    markerMappingStateContextIdentifier,
    markerMappingSensorKey,
    loadAssetStates,
    loadSensorWithTypes,
    enableAssetClustering,
    renderDiagramSettings,
  } as AssetMapWidgetProps);
}

export const AssetMapWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetMapWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetMapWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { AssetEventTypeForm } from "./asset_event_type_form";
import { ResourcePermission } from "../../models/resource_permission";
import { AssetEventTypeList } from "./asset_event_type_list";
import { AssetEventTypeDetails } from "./asset_event_type_details";

const roots: Root[] = [];
const listRoots: Root[] = [];
const detailRoots: Root[] = [];
export function initializeAssetEventTypeForm(
  selector = $('[data-toggle="asset-event-type-form"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    roots.push(root);
    const jqElem = $(element);
    const assetId = jqElem.data("asset-id");
    const assetTypeId = jqElem.data("asset-type-id");
    const assetEventTypeId = jqElem.data("asset-event-type-id");
    const fabButtons = jqElem.data("fab-buttons");
    const permissions = jqElem.data("permissions") as ResourcePermission;
    root.render(
      <AppRoot>
        <AssetEventTypeForm
          assetId={assetId}
          assetTypeId={assetTypeId}
          assetEventTypeId={assetEventTypeId}
          permissions={permissions}
          withFloatingButtons
          withCard
        />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventTypeForms() {
  roots.forEach((r) => {
    r.unmount();
  });
  roots.length = 0;
}

export function initializeAssetEventTypesList(
  selector = $('[data-toggle="asset-event-type-list"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    listRoots.push(root);
    const jqElem = $(element);

    const assetId = jqElem.data("asset-id");
    const assetTypeId = jqElem.data("asset-type-id");
    const fabButtons = jqElem.data("fab-buttons");
    const permissions = jqElem.data("permissions") as ResourcePermission;
    root.render(
      <AppRoot>
        <AssetEventTypeList
          assetId={assetId}
          assetTypeId={assetTypeId}
          permissions={permissions}
          withFab={fabButtons}
        />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventTypesList() {
  listRoots.forEach((r) => {
    r.unmount();
  });
  listRoots.length = 0;
}

export function initializeAssetEventTypeDetails(
  selector = $('[data-toggle="asset-event-type-details"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    detailRoots.push(root);
    const jqElem = $(element);
    const assetEventTypeId = jqElem.data("asset-event-type-id");
    root.render(
      <AppRoot>
        <AssetEventTypeDetails
          assetEventTypeId={assetEventTypeId}
          withFabButtons
          wrapInCard
        />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventTypeDetails() {
  detailRoots.forEach((r) => {
    r.unmount();
  });
  detailRoots.length = 0;
}

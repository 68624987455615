import { isNil } from "lodash";
import { IDType, ParamsType, applyParamsToBaseUrl } from "./url_utils";
import {
  api_asset_type_product_models_path,
  api_product_model_path,
  api_product_models_path,
} from "../../routes";

type ProductModelInclude = "file_attachments" | "asset_type" | "manufacturer";

export function apiProductModelsUrl(
  assetTypeId: IDType,
  page: number = null,
  pageSize: number = null,
  includes: ProductModelInclude[] = null,
  params?: ParamsType,
) {
  let url = null;
  if (!isNil(assetTypeId)) {
    url = api_asset_type_product_models_path(assetTypeId);
  } else {
    url = api_product_models_path();
  }

  return applyParamsToBaseUrl(url, page, pageSize, includes, params);
}

export function apiProductModelUrl(
  id: IDType,
  includes?: ProductModelInclude[],
) {
  const url = api_product_model_path(id);

  return applyParamsToBaseUrl(url, null, null, includes);
}

import { concat, isEmpty, isNil, map } from "lodash";
import { CommentFilter } from "../../components/comments/comment_filter";
import moment from "../../initializers/moment";
import {
  IDType,
  RequestFormat,
  applyParamsDataToBaseUrl,
  formatString,
  localizedBasePath,
} from "./url_utils";

export interface CommentAttributes {
  id?: IDType;
  itemId?: IDType;
  itemType?: string;
}

export function commentsUrl(
  comment?: CommentAttributes,
  format: RequestFormat = "json",
  limit?: number,
  filter?: CommentFilter,
  sort?: "asc" | "desc",
): string {
  if (isNil(comment)) {
    return `${localizedBasePath()}/comments${formatString(format)}`;
  }
  let url;
  switch (comment.itemType) {
    case "Asset":
      url = `${localizedBasePath()}/assets/${
        comment.itemId
      }/comments${formatString(format)}`;
      break;
    case "Feedback":
      url = `${localizedBasePath()}/feedback/${
        comment.itemId
      }/comments${formatString(format)}`;
      break;
    case "Logbook":
      url = `${localizedBasePath()}/logbooks/${
        comment.itemId
      }/comments${formatString(format)}`;
      break;
    default:
      url = `${localizedBasePath()}/comments${formatString(format)}`;
  }

  let params: Array<[string, number | string]> = [];
  if (!isNil(limit)) {
    params.push(["limit", limit]);
  }
  if (!isEmpty(filter?.tags)) {
    const tagParams: [string, string][] = map(filter?.tags, (t) => [
      "tags[]",
      t,
    ]);
    params = concat(params, tagParams);
  }

  if (moment.isMoment(filter?.from)) {
    params.push(["from", filter.from.toISOString()]);
  }
  if (moment.isMoment(filter?.to)) {
    params.push(["to", filter.to.toISOString()]);
  }
  if (sort) {
    params.push(["sort", sort]);
  }
  return applyParamsDataToBaseUrl(url, params).toString();
}

export function commentUrl(
  comment: CommentAttributes,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/comments/${comment.id}${formatString(format)}`;
}

export function editCommentUrl(
  comment: CommentAttributes,
  format: RequestFormat = "json",
): string {
  switch (comment.itemType) {
    case "Asset":
      return `${localizedBasePath()}/assets/${comment.itemId}/comments/${
        comment.id
      }/edit${formatString(format)}`;
    case "Feedback":
      return `${localizedBasePath()}/feedback/${comment.itemId}/comments/${
        comment.id
      }/edit${formatString(format)}`;
    default:
      return `${localizedBasePath()}/comments/${comment.id}/edit${formatString(
        format,
      )}`;
  }
}

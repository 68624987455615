import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { cloneDeep, get, isNil } from "lodash";
import * as React from "react";
import { getTranslatedProp } from "../../../utils/globalize";
import { getMaintenancePlanStyle } from "../../../utils/maintenance_plan_utils";
import { IBox, IBoxContent } from "../../common/ibox";
import { MaintenancePlanIcon } from "../../common/maintenance/maintenance_plan_icon";
import { RatingInput } from "../../common/rating_input";
import { InspectionJob, User } from "../data/model";
import { MaintenanceJobListEntryProps } from "./maintenance_job_list_entry";
import { MaintenanceStatusField } from "./maintenance_status_field";
import { UserGuestUserAutocomplete } from "./user_guest_user_autocomplete";
import { onMaintenanceUserUpdated } from "./maintenance_job_list_data";

export interface InspectionJobListEntryProps
  extends MaintenanceJobListEntryProps {
  maintenanceJob: InspectionJob;
  users: User[];
  onUpdateMaintenanceJob: (maintenanceJob: InspectionJob) => void;
}

export class InspectionJobListEntry extends React.Component<InspectionJobListEntryProps> {
  shouldComponentUpdate(nextProps: InspectionJobListEntryProps): boolean {
    return (
      this.props.maintenanceJob !== nextProps.maintenanceJob ||
      this.props.users !== nextProps.users
    );
  }

  render(): React.ReactNode {
    return (
      <form
        name={`maintenance-plan-form-${this.props.maintenanceJob.maintenance_plan.id}`}
      >
        <IBox
          className={`float-top mb-3 maintenance-list-entry${
            this.props.maintenanceJob.hidden ? " d-none" : ""
          } ${getMaintenancePlanStyle(
            this.props.maintenanceJob.maintenance_plan.type,
          )}`}
        >
          <IBoxContent className="pt-2 pb-2">
            <Grid container spacing={4}>
              <Grid item container xs={12} md={7} spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="executed"
                        size="small"
                        checked={this.props.maintenanceJob.executed}
                        color="primary"
                        onChange={(event) => this.onExecutedUpdated(event)}
                      />
                    }
                    label={
                      <Typography>
                        <MaintenancePlanIcon
                          maintenancePlan={
                            this.props.maintenanceJob.maintenance_plan
                          }
                          size="lg"
                          className=""
                        ></MaintenancePlanIcon>

                        {getTranslatedProp(
                          this.props.maintenanceJob.maintenance_plan,
                          "name",
                        )}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} className="mt-2">
                  <UserGuestUserAutocomplete
                    user={this.props.maintenanceJob.user_attributes || null}
                    users={this.props.users}
                    disabled={!this.props.maintenanceJob.executed}
                    onUserSelect={(name, selectedUser) => {
                      this.onUserUpdated(name, selectedUser);
                    }}
                    label={I18n.t("frontend.maintenance_form.inspected_by")}
                    placeholder={I18n.t(
                      "frontend.maintenance_form.inspected_by",
                    )}
                    userErrors={
                      get(this.props.maintenanceJob, "errors.user") as string
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} className="mt-2">
                  <RatingInput
                    name="value"
                    disabled={!this.props.maintenanceJob.executed}
                    scaleRange={[1, 6]}
                    indicateCurrent={true}
                    initialStatusValue={
                      this.props.maintenanceJob.initial_status_value
                    }
                    value={get(
                      this.props.maintenanceJob,
                      "status_measurement.value",
                      this.props.maintenanceJob.initial_status_value,
                    )}
                    onChange={(value) => this.onStatusUpdated(value)}
                  />
                </Grid>
                <Grid item xs={12} className="mb-1">
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!this.props.maintenanceJob.executed}
                    type="text"
                    placeholder={I18n.t(
                      "frontend.maintenance_form.enter_inspection_note",
                    )}
                    label={I18n.t(
                      "activerecord.attributes.inspection_job.description",
                    )}
                    name="note"
                    multiline
                    value={get(
                      this.props.maintenanceJob,
                      "status_measurement.note",
                      "",
                    )}
                    onChange={(event) => this.onNoteUpdated(event)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <MaintenanceStatusField
                  maintenanceJob={this.props.maintenanceJob}
                />
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </form>
    );
  }

  private onExecutedUpdated(event: React.ChangeEvent<HTMLInputElement>): void {
    event.persist();

    const newInspectionJob = cloneDeep(this.props.maintenanceJob);
    newInspectionJob.executed = event.target.checked;
    this.props.onUpdateMaintenanceJob(newInspectionJob);
  }
  private onNoteUpdated(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    event.persist();

    const newInspectionJob = cloneDeep(this.props.maintenanceJob);
    newInspectionJob.status_measurement.note = event.target.value;
    this.props.onUpdateMaintenanceJob(newInspectionJob);
  }

  private onStatusUpdated(value: number): void {
    const newInspectionJob = cloneDeep(this.props.maintenanceJob);
    const oldValue = newInspectionJob.status_measurement.value;
    newInspectionJob.status_measurement.value = value;
    if (!isNil(this.props.onUpdateMaintenanceJob)) {
      this.props.onUpdateMaintenanceJob(newInspectionJob);
    }
  }

  private onUserUpdated(name: string, selectedUser: User): void {
    onMaintenanceUserUpdated(name, selectedUser, this.props);
  }
}

/// <reference types="../../../definitions/index" />;
import { NumericDictionary, isEmpty, isNil, uniq, values } from "lodash";

import { Box, Grid } from "@mui/material";
import { Moment } from "moment";
import * as React from "react";
import {
  ChartStatistics,
  computeChartStatisticsForTimeData,
} from "../../../charting/chart_data/chart_data_statistics";
import { MeasurementChartDataCreator } from "../../../charting/chart_data/measurement_chart_data_creator";
import { getDiagramLineColor } from "../../../charting/diagram_constants";
import { PlotlyLineChart } from "../../../charting/plotly_line_chart";
import { Plotly } from "../../../charting/plotly_package";
import { ChartStatisticsDisplay } from "../../../charting/react/chart_statistics_display";
import { MeasurementTypeJsonAttributes } from "../../../json_api/measurement_type";
import { MeasurementValueAttributes } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";

export interface MeasurementValueDiagramProps {
  title?: string;
  measurementValueDefinitions: MeasurementValueDefinition[];
  measurementCategories: MeasurementCategory[];
  measurementValues: MeasurementValueAttributes[];
  measurementTypesById: NumericDictionary<MeasurementTypeJsonAttributes>;
  mvdIntervalUnit: string;
  startTime?: Moment;
  endTime?: Moment;
  groupMeasurementValues?: boolean;
}

export interface MeasurementValueDiagraState {
  isPrinting: boolean;
  diagramDataUrl: string;
  chartData: {
    chartData: Partial<Plotly.PlotData>[];
    statistics: ChartStatistics[];
    xAxisLayout: Partial<Plotly.Layout>;
  };
}

export class MeasurementValueDiagram extends React.Component<
  MeasurementValueDiagramProps,
  MeasurementValueDiagraState
> {
  static defaultProps: Partial<MeasurementValueDiagramProps> = {
    title: "",
    measurementValues: [],
  };

  private diagramElement: HTMLDivElement;
  private dataRevision = 0;
  private uiRevision = 0;

  constructor(props: MeasurementValueDiagramProps) {
    super(props);

    this.state = {
      isPrinting: false,
      diagramDataUrl: null,
      chartData: this.buildData(),
    };
  }

  buildData() {
    const chartDataCreator = new MeasurementChartDataCreator();
    const measurementTypes = uniq(
      values(this.props.measurementTypesById).map((mt) => mt.type_short),
    );
    const isDistribution =
      measurementTypes.length == 1 &&
      measurementTypes[0] === "distribution_measurement_type";
    chartDataCreator.createChartData(
      this.props.measurementValueDefinitions,
      this.props.measurementCategories,
      this.props.measurementValues,
      this.props.groupMeasurementValues,
      this.props.mvdIntervalUnit,
      isDistribution,
    );
    const chartData = chartDataCreator.getChartData();
    return {
      chartData,
      statistics: computeChartStatisticsForTimeData(null, chartData),
      xAxisLayout: chartDataCreator.getAxisLayout(),
    };
  }

  componentDidMount(): void {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps: MeasurementValueDiagramProps) {
    if (
      prevProps.measurementValueDefinitions !==
        this.props.measurementValueDefinitions ||
      prevProps.measurementValues !== this.props.measurementValues ||
      prevProps.groupMeasurementValues !== this.props.groupMeasurementValues
    ) {
      this.uiRevision++;
      this.dataRevision++;
      this.setState({ chartData: this.buildData() }, () =>
        this.updateDiagramData(),
      );
    }
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Box
              ref={(de: HTMLDivElement) => {
                this.updatePlotlyDiagram(de);
              }}
              minHeight={400}
            />
          </Box>
        </Grid>

        {isNil(this.state.chartData?.statistics) ? null : (
          <Grid item xs={12}>
            <ChartStatisticsDisplay
              color={getDiagramLineColor}
              chartStatistics={this.state.chartData?.statistics}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  private updatePlotlyDiagram(element: HTMLDivElement): void {
    if (element !== this.diagramElement && !isNil(this.diagramElement)) {
      Plotly.purge(this.diagramElement);
    }

    if (this.diagramElement === element) {
      return;
    }
    this.diagramElement = element;

    if (isNil(this.diagramElement)) {
      return;
    }

    this.updateDiagramData();
  }

  updateDiagramData(): void {
    if (
      isNil(this.diagramElement) ||
      isEmpty(this.state.chartData?.chartData)
    ) {
      return;
    }

    const de = Plotly.react(
      this.diagramElement,
      this.state.chartData.chartData,
      {
        ...this.createLayout(),
        ...this.state.chartData.xAxisLayout,
      },
      {
        responsive: true,
        displaylogo: false,

        locale: I18n.locale,
        modeBarButtons: PlotlyLineChart.modeBarButtons,
      },
    );
  }

  private createLayout(): Partial<Plotly.Layout> {
    return {
      title: this.props.title,

      hoverlabel: {
        bgcolor: "rgba(200,200,200,0.8)",

        font: {
          family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
          size: 11,
          color: "rgb(103, 106, 108)",
        },
      },
      margin: {
        t: 40,
        b: 40,
        l: 10,
        r: 10,
      },

      datarevision: this.dataRevision,
      uirevision: this.uiRevision,
      autosize: true,
      showlegend: true,
      legend: {
        orientation: "h",
        yanchor: "bottom",
        xanchor: "center",
        x: 0.5,
        y: 0.98,
      },
      xaxis: {
        title: I18n.t("widgets.line_diagram_widget.x_axis_label"),
        linewidth: 1,
        spikethickness: 2,
        automargin: true,
      },
      yaxis: {
        automargin: true,
      },
      font: {
        family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        size: 11,
        color: "rgb(103, 106, 108)",
      },
    };
  }
}

import { Add, Home, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormHelperText,
  Grid,
  Link,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { isEmpty, isNil, times } from "lodash";
import * as React from "react";
import { useLoadAssetTypesQuery } from "../../components/asset_type/asset_type_data";
import { useLoadAssetQuery } from "../../components/assets/asset_data";
import { Icon } from "../../components/common/icon";
import {
  asset_type_asset_event_types_path,
  asset_type_asset_templates_path,
  asset_type_path,
  asset_type_product_models_path,
  new_asset_asset_asset_type_path,
  new_asset_asset_type_path,
} from "../../routes";
import { IDType } from "../../utils/urls/url_utils";

interface AssetTypeHomeProps {
  organizationId: IDType;
  parentAssetID?: IDType;
  canEdit: boolean;
}

export const AssetTypesIndexPage: React.FC<AssetTypeHomeProps> = ({
  organizationId: propsOrgId,
  parentAssetID,
  canEdit,
}) => {
  const [organizationId, setOrganizationId] = React.useState(propsOrgId);
  React.useEffect(() => {
    setOrganizationId(propsOrgId);
  }, [propsOrgId]);

  const [pageSettings, setPageSettings] = React.useState({
    number: 1,
    size: 20,
  });

  const { data: parentAsset, isLoading: parentAssetLoading } =
    useLoadAssetQuery({
      variables: { id: parentAssetID },
      enabled: !isNil(parentAssetID),
    });
  const [enteredSearchTerm, setEnteredSearchTerm] =
    React.useState<string>(null);
  // debounce search term to prevent too many requests
  const searchTerm = useDebounce(enteredSearchTerm, 1000);
  const {
    isLoading: assetTypeIsLoading,
    data: assetTypes,
    error,
  } = useLoadAssetTypesQuery({
    // organization filter currently not supported
    variables: {
      pageSettings,
      childrenOf: parentAsset?.asset_type_id,
      rootOnly: isNil(parentAssetID),
      search: searchTerm,
    },

    enabled: isNil(parentAssetID) || !isNil(parentAsset?.asset_type_id),
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} textAlign={"right"}>
        <TextField
          label={I18n.t("base.search")}
          type="search"
          fullWidth
          sx={{ maxWidth: 400 }}
          onChange={(e) => setEnteredSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <Search />,
          }}
        />
      </Grid>
      {error ? (
        <Grid item xs={12}>
          <FormHelperText error>{error.message}</FormHelperText>
        </Grid>
      ) : assetTypeIsLoading || parentAssetLoading ? (
        times(5).map((i) => (
          <Grid item xs={12} key={i}>
            <Skeleton height={50} />
          </Grid>
        ))
      ) : isEmpty(assetTypes.items) ? ( // no asset types
        <Grid item xs={12}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">
                {I18n.t("frontend.asset_types.index.no_asset_types")}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ) : (
        <>
          {assetTypes.items?.map((assetType) => (
            <Grid item xs={12} key={assetType.id}>
              <Paper elevation={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container p={2}>
                      <Grid item xs="auto" flex={0}>
                        <Avatar src={assetType.icon_url} variant="square">
                          {assetType.icon ? (
                            <Icon icon={assetType.icon} />
                          ) : (
                            assetType.name?.[0]
                          )}
                        </Avatar>
                      </Grid>
                      <Grid item xs="auto" flex={1}>
                        <Link href={asset_type_path(assetType.id)}>
                          <Typography variant="h5" padding={2}>
                            {assetType.name}
                          </Typography>
                        </Link>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                  {assetType.description ? (
                    <Grid item xs={12}>
                      <Typography padding={2}>
                        {assetType.description}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} p={2}>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            startIcon={<Home />}
                            href={asset_type_path(assetType.id)}
                          >
                            {I18n.t("base.home")}
                          </Button>
                          <Button
                            href={asset_type_product_models_path(assetType.id)}
                          >
                            {I18n.t("activerecord.models.product_model.other")}
                          </Button>
                          <Button
                            href={asset_type_asset_event_types_path(
                              assetType.id,
                            )}
                          >
                            {I18n.t(
                              "activerecord.models.asset_event_type.other",
                            )}
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} p={2}>
                        <ButtonGroup>
                          <Button
                            startIcon={<Add />}
                            href={asset_type_asset_templates_path(assetType.id)}
                          >
                            {I18n.t("activerecord.models.asset_template.other")}
                          </Button>
                          <Button
                            startIcon={<Add />}
                            href={
                              parentAssetID
                                ? new_asset_asset_asset_type_path(
                                    parentAssetID,
                                    assetType.id,
                                  )
                                : new_asset_asset_type_path(assetType.id)
                            }
                          >
                            {I18n.t("frontend.asset_types.index.new_asset")}
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="../definitions/leaflet-realtime" />;
import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import {
  AssetMapWidget as AssetMapWidgetComponent,
  AssetMapWidgetDefinition,
} from "../components/widgets/asset_map_widget";
import { AssetMapWidgetConfigSerialized } from "./asset_map_widget.types";
import Widget from "./widget";

export default class AssetMapWidget extends Widget {
  static getDomClassName(): string {
    return "asset-map-widget";
  }

  protected constructor(element: JQuery<HTMLElement>) {
    super(element);
    this.rootElement = element;
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data("config") as AssetMapWidgetConfigSerialized;
    this.config = config;
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    const props = AssetMapWidgetDefinition.serializedConfigToProps(this.config);

    this.root.render(
      <AppRoot>
        <AssetMapWidgetComponent {...props} encloseInIBox />
      </AppRoot>,
    );
  }

  getSensorIdsForUpdate(): number[] {
    return [];
  }

  cleanup(): void {
    super.cleanup();
  }

  toggleFullscreen() {
    super.toggleFullscreen();
  }
}

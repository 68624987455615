import * as React from "react";

import { RotationInput as CommonRotationComponent } from "../../common/rotation_input/rotation_input";
import { MaintenancePlan } from "../data/models";

interface RotationInputProps {
  maintenancePlan: MaintenancePlan;
  invalid?: boolean;
  onChange: (maintenancePlan: MaintenancePlan) => void;
}

interface RotationInputState {
  maintenancePlan: MaintenancePlan;
  isEditing: boolean;
}

/**
 * An input field to configure the rotation of a maintenance plan.
 * Rotations can be based on a time schedule or a running time sensor.
 * A rrule(time schedule) or a threshold can be configured depending on the rotation type.
 */
export const RotationInput: React.FunctionComponent<RotationInputProps> = (
  props: RotationInputProps,
) => {
  return (
    <CommonRotationComponent
      criticalValue={props.maintenancePlan.critical_value}
      rrule={props.maintenancePlan.rrule}
      variant="text"
      rotationType={props.maintenancePlan.maintenance_plan_type || "unplanned"}
      onApplyChange={(rrule, rotationType, criticalValue) => {
        props.onChange({
          ...props.maintenancePlan,
          rrule,
          maintenance_plan_type: rotationType,
          critical_value: criticalValue,
        });
      }}
    />
  );
};

import { Refresh } from "@mui/icons-material";
import { Timeline } from "@mui/lab";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { defaultTo, isEmpty, isNil, toString } from "lodash";
import { Duration } from "moment";
import moment from "../../initializers/moment";
import { DateRange } from "moment-range";
import * as React from "react";
import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import { CastedContextStateChange } from "../../models/context_state_machine";
import { State } from "../../models/state";
import { notifyAirbrake } from "../../utils/airbrake_error_handler";
import { MaterialUiDateRangePicker } from "../common/data_range_picker";
import { LoadingIcon } from "../common/icon";
import { LoadingWrapper } from "../common/loading_wrapper";
import { ContextStateChangeTimelineItem } from "./context_state_change_timeline_item";
import { loadStateChangeData } from "./state_change_data_loader";
export interface ContextStateChangeListProps {
  timeRange?: DateRange;
  csm?: ContextStateMachineJSONObject;
  csmId: number;
}

interface ContextStateChangeListState {
  csm?: ContextStateMachineJSONObject;
  contextStateChanges?: CastedContextStateChange[];
  possibleStates: Map<string, State>;
}

export const ContextStateChangeList: React.FunctionComponent<
  ContextStateChangeListProps
> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [timeRange, setTimeRange] = React.useState<DateRange>(
    defaultTo(
      props.timeRange,
      new DateRange([moment().startOf("day"), moment().endOf("day")]),
    ),
  );
  const [state, setState] = React.useState<ContextStateChangeListState>({
    csm: props.csm,
    contextStateChanges: null,
    possibleStates: isNil(props.csm)
      ? null
      : new Map<string, State>(
          props.csm.possible_states?.map((value) => [
            toString(value.id),
            value,
          ]),
        ),
  });

  const loadCsmChanges = React.useCallback(() => {
    let csmPromise: Promise<ContextStateMachineJSONObject>;

    let possibleStates: Map<string, State> = state.possibleStates;
    let csm: ContextStateMachineJSONObject = state.csm;
    setHasError(false);
    setLoading(true);
    if (isNil(csm)) {
      csmPromise = loadContextStateMachine(props.csmId).then((csm) => {
        possibleStates = new Map<string, State>(
          csm.possible_states?.map((value) => [toString(value.id), value]),
        );
        return csm;
      });
    } else {
      csmPromise = Promise.resolve(state.csm);
    }
    void csmPromise
      .then((theCsm) => {
        csm = theCsm;
        return loadStateChangeData(csm.id, {
          from: timeRange.start,
          to: timeRange.end,
          sort: "desc",
        });
      })
      .then((res) => {
        setState({ ...state, csm, contextStateChanges: res, possibleStates });
      })
      .catch((e: Error) => {
        setHasError(true);
        void notifyAirbrake(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.csmId, timeRange, state]);
  React.useEffect(() => {
    loadCsmChanges();
  }, [timeRange]);

  return (
    <Card>
      <CardHeader
        title={I18n.t("frontend.context_state_change_list.heading")}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={11} className="mb-3">
            <MaterialUiDateRangePicker
              value={[timeRange.start, timeRange.end]}
              type="datetime"
              dateFormat="L LT"
              name="time-range"
              label={I18n.t(
                "frontend.context_state_change_list.select_time_range",
              )}
              onChange={(event) => {
                setState({
                  ...state,
                  contextStateChanges: null,
                });
                setTimeRange(new DateRange(event.dateRange));
              }}
            />
          </Grid>
          <Grid item xs={1} className="mb-3">
            <IconButton onClick={() => loadCsmChanges()} size="large">
              <Refresh />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <LoadingWrapper
              loading={loading}
              loadingElements={
                <>
                  <Skeleton variant="rectangular" height={50} />
                  <LoadingIcon size="4x" />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                </>
              }
            >
              {loading ? null : (
                <Box minHeight={250}>
                  {hasError ? (
                    I18n.t(
                      "frontend.context_state_change_list.error_loading_state_changes",
                    )
                  ) : isEmpty(state.contextStateChanges) ? (
                    <Typography
                      variant="body1"
                      marginY="auto"
                      textAlign="center"
                    >
                      {I18n.t(
                        "frontend.context_state_change_list.no_state_changes",
                      )}
                    </Typography>
                  ) : (
                    <Timeline position="left">
                      {state.contextStateChanges?.map((csc, index) => {
                        let duration: Duration;
                        if (index < state.contextStateChanges.length - 1) {
                          const prevChange =
                            state.contextStateChanges[index + 1];
                          const prevTime = prevChange.time;
                          duration = moment.duration(
                            csc.time.valueOf() - prevTime.valueOf(),
                          );
                        }
                        return (
                          <ContextStateChangeTimelineItem
                            key={index}
                            stateChange={csc}
                            timeSinceLast={duration}
                            state={state.possibleStates.get(csc.state_id)}
                          />
                        );
                      })}
                    </Timeline>
                  )}
                </Box>
              )}
            </LoadingWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { isEmpty, isNil } from "lodash";
import moment, { Moment } from "moment";
import { DateRange } from "moment-range";
import { SensorJSONAPIAttributes } from "../../../json_api/sensor";
import { SensorLoader } from "../../../json_api/sensor_loader";
import { ValueRangeStatus } from "../../../models/sensor_value_range";
import { logger } from "../../../utils/logger";
import { getValueRangeForValue } from "../../../utils/status_helper";
import { convertToUnit } from "../../../utils/unit_conversion";
import { IDType } from "../../../utils/urls/url_utils";
interface LoadSensorDataResult {
  sensor: SensorJSONAPIAttributes;
  value: number;
  status: ValueRangeStatus;
  timestamp: Moment;
}
/** Load the sensor data for a given sensor id and time range. If the time range is not provided or the ignoreTimeRange is TRUE, the last value is returned.
 *
 *
 * @export
 * @param {IDType} sensorId
 * @param {DateRange} timeRange
 * @param {boolean} [fallbackToLastValue=false]
 * @param {boolean} [ignoreTimeRange=false]
 * @return {*}  {Promise<LoadSensorDataResult>}
 */
export async function loadSensorData(
  sensorId: IDType,
  timeRange: DateRange,
  fallbackToLastValue: boolean = false,
  ignoreTimeRange: boolean = false,
): Promise<LoadSensorDataResult> {
  let sensor: SensorJSONAPIAttributes = null;

  if (isNil(sensorId) || isNaN(sensorId as number)) {
    logger.warn("No sensor id provided for the widget");
    throw new Error("No sensor id provided for the widget");
  }

  return SensorLoader.getInstance()
    .getSensors([sensorId])
    .then((sensors) => {
      if (isNil(sensors) || isEmpty(sensors)) {
        throw new Error(`Error loading sensor ${sensorId}`);
      }

      let theTimeRange = null;
      if (!isNil(timeRange?.start) && !isNil(timeRange?.end)) {
        theTimeRange = timeRange;
      } else if (!isNil(timeRange?.start)) {
        theTimeRange = new DateRange(
          timeRange.start,
          timeRange.start.endOf("day"),
        );
      } else if (!isNil(timeRange?.end)) {
        theTimeRange = new DateRange(timeRange.end, moment());
      }

      sensor = sensors[0];

      if (ignoreTimeRange || isNil(theTimeRange)) {
        // If we ignore the time range, we just return the last value that was attached to the jsonapi sensor object. It is in display unit
        return { ...sensor.last_value, unit: sensor.display_unit };
      } else {
        return SensorLoader.getInstance().getLastValueWithin(
          sensor.id,
          theTimeRange,
          fallbackToLastValue,
        );
      }
    })
    .then((sensorValue) => {
      let value: number = sensorValue.value as number;

      if (!isNil(sensorValue.unit)) {
        value = convertToUnit(value, sensorValue.unit, sensor.display_unit);
      }

      const currentRange = getValueRangeForValue(value, sensor.value_ranges);
      let newStatus: ValueRangeStatus;
      if (!isNil(currentRange)) {
        newStatus = currentRange.status;
      }

      return {
        sensor,
        value: value as number,
        status: newStatus,
        timestamp: moment(sensorValue.timestamp),
      } as LoadSensorDataResult;
    })
    .catch((e) => {
      logger.error("Error during sensor data loading in the Widget", e);
      return null as LoadSensorDataResult;
    });
}

import Bluebird from "bluebird";
import { Container } from "flux/utils";
import { get, has, isEmpty, isNil, reject } from "lodash";
import * as React from "react";
import { SweetAlertResult } from "sweetalert2";
import {
  MeasurementPlanSubmit,
  toSubmitJson,
} from "../../../models/measurement_plan";
import {
  setConfirmLeaveMessage,
  showBeforeUnloadDialog,
} from "../../../utils/before_unload_dialog";
import { dialog } from "../../../utils/dialog";
import "../../../utils/flux_container_adapter";
import { RequestMethod, sendData } from "../../../utils/jquery_helper";
import { redirectTo } from "../../../utils/redirection";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import { AppContext } from "../../common/app_context/app_context_provider";

import { MeasurementErrorResponse } from "../../measurement_form/data/model";
import * as Actions from "../data/measurement_plan_actions";
import MeasurementPlanStore, {
  MeasurementPlanState,
} from "../data/measurement_plan_store";
import { MeasurementPlanForm } from "../views/measurement_plan_form";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";

/**
 * Base class of maintenance plan container.
 * This is never used directly but extended with the Flux.Container mixin.
 */
class MeasurementPlanContainerBase extends React.Component<
  React.PropsWithChildren,
  MeasurementPlanState
> {
  constructor(props: React.PropsWithChildren) {
    super(props);
  }

  static getStores() {
    return [MeasurementPlanStore];
  }

  static calculateState(prevState: MeasurementPlanState) {
    return MeasurementPlanStore.getState();
  }
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  render(): React.ReactNode {
    const state = this.state;

    if (state.hasChanges) {
      // enable before unload dialog if changes are applied
      showBeforeUnloadDialog(true);
      setConfirmLeaveMessage(
        I18n.t("frontend.measurement_plan_form.cancel_confirm_heading"),
        I18n.t("frontend.measurement_plan_form.cancel_confirm_message"),
      );
    }

    return (
      <MeasurementPlanForm
        assetId={this.state.assetId}
        mode={this.state.mode}
        errors={this.state.errors}
        step={this.state.step}
        createBy={this.state.createBy}
        typeOfMeasurement={this.state.typeOfMeasurement}
        templateMeasurementTypes={this.state.templateTypes}
        templateType={state.templateType}
        unit={this.state.unit}
        availableMeasurementCategorizations={
          this.state.availableMeasurementCategorizations
        }
        measurementPlan={state.measurementPlan}
        isProcessing={state.isProcessing}
        onSubmit={() => void this.onSaveMeasurementPlan()}
        onCancel={() => void this.onCancel()}
        onFinishStep1={Actions.finishStep1}
        onUpdateMeasurementPlan={Actions.updateMeasurementPlan}
        onAddMeasurementValueDefinition={Actions.addMeasurementValueDefinition}
        onRemoveMeasurementValueDefinition={
          Actions.removeMeasurementValueDefinition
        }
        onUpdateMeasurementValueDefinition={
          Actions.updateMeasurementValueDefinition
        }
        onMoveMeasurementValueDefinition={
          Actions.moveMeasurementValueDefinition
        }
        onCategorizationSelect={Actions.selectMeasurementCategorization}
        onSelectCreationMode={Actions.selectCreationMode}
        onSelectTemplate={Actions.selectMeasurementTypeTemplate}
        onSelectTypeOfMeasurement={Actions.selectTypeOfMeasurement}
        onBack={Actions.back}
        onUnitSelect={Actions.selectMeasurementUnit}
        onToggleEdit={Actions.enableEdit}
        onDelete={() => {
          this.onDeletePlan();
        }}
      />
    );
  }

  private onSaveMeasurementPlan(): Promise<void> {
    Actions.setProcessing(true);
    Actions.resetErrors();
    const state = this.state;

    let url: string;
    let method: RequestMethod;
    if (!isEmpty(this.state.submitUrl)) {
      url = this.state.submitUrl;
      method = this.state.submitMethod ?? "POST";
    } else if (this.state.mode === "create") {
      url = url_helper.assetMeasurementPlansPath(this.state.assetId, "json");
      method = "POST";
    } else {
      url = url_helper.assetMeasurementPlanPath(
        this.state.assetId,
        this.state.measurementPlan.id,
        "json",
      );
      method = "PATCH";
    }
    const data = {
      measurement_plan: toSubmitJson(
        state.measurementPlan as MeasurementPlanSubmit,
      ),
      measurement_value_definitions_to_delete: reject(
        state.measurementValueDefinitionsToDelete,
        (mvd) => isNil(mvd.id),
      ),
    };
    return sendData(url, data, method)
      .then(() => {
        showBeforeUnloadDialog(false);
        this.redirect();
      })
      .catch((error) => {
        Actions.setProcessing(false);

        // load errors from response if present
        if (has(error, "request.responseJSON")) {
          Actions.setErrors(
            get(error, "request.responseJSON") as MeasurementErrorResponse,
          );
        }

        void toast.error(
          I18n.t("frontend.measurement_plan_form.error"),
          I18n.t("frontend.measurement_plan_form.error_title"),
          true,
        );
      });
  }

  private onCancel(): Promise<any | void> {
    Actions.setProcessing(false);
    const closeAndRedirect = (result: SweetAlertResult<boolean>) => {
      if (result.value) {
        showBeforeUnloadDialog(false);
        this.redirect();
      }
    };

    if (!this.state.hasChanges) {
      closeAndRedirect({
        value: true,
        isDismissed: true,
        isConfirmed: true,
        isDenied: false,
      });
      return;
    }

    return Bluebird.bind(this).then(() =>
      dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          icon: "question",
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          title: I18n.t(
            "frontend.measurement_plan_form.cancel_confirm_heading",
          ),
          html: `<p>${I18n.t(
            "frontend.measurement_plan_form.cancel_confirm_message",
          )}</p>`,
        })
        .then(closeAndRedirect),
    );
  }
  private redirect(url: string = null): void {
    const state = this.state;

    if (!isEmpty(url)) {
      redirectTo(url);
      return;
    }
    if (!isNil(state.assetId) && !isEmpty(state.assetId.toString())) {
      // redirect to maintenance plans overview
      this.redirect(
        url_helper.assetMeasurementPlansPath(state.assetId, "html"),
      );
    } else if (
      !isNil(this.context.referrer) &&
      !isEmpty(this.context.referrer)
    ) {
      // redirect to referrer
      this.redirect(this.context.referrer);
    }
  }

  private onDeletePlan() {
    const id = this.state?.measurementPlan?.id;
    const assetId =
      this.state?.measurementPlan?.asset_id ??
      this.state?.measurementPlan?.asset?.id;
    if (!isNil(id)) {
      void dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          text: I18n.t(
            "frontend.measurement_plan_form.delete_measurement_plan_popup.confirm_text",
          ),

          title: I18n.t(
            "frontend.measurement_plan_form.delete_measurement_plan_popup.popup_title",
          ),
        })
        .then((result) => {
          if (result.isConfirmed) {
            Actions.setProcessing(true);
            const url: string = url_helper.assetMeasurementPlanPath(
              assetId,
              id,
              "json",
            );
            const method: RequestMethod = "DELETE";
            sendData(url, null, method)
              .then(() => {
                this.redirect(url_helper.assetMeasurementPlansPath(assetId));
              })
              .catch((error) => {
                Actions.setProcessing(false);
                void toast.error(
                  I18n.t("frontend.measurement_plan_form.error_on_delete"),
                  I18n.t("frontend.measurement_plan_form.error_title"),
                  true,
                );
              });
          } else {
            Actions.setProcessing(false);
          }
        });
    }
  }
}

/**
 * A Flux container component that connects the maintenance plan form to the store and actions.
 */
export const MeasurementPlanContainer = Container.create(
  // flux should be typed correctly, but it is not, so we are casting this to any -> Replace flux as soon as possible
  MeasurementPlanContainerBase as any,
);

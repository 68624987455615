import { isNil, map } from "lodash";
import { DateRange } from "moment-range";

import * as url_helper from "../utils/urls";
import Widget from "./widget";

import { PlotlyCandleStickChart } from "../charting/plotly_candle_stick_chart";
import { SamplingRate, SensorSamplingRateUnit } from "../models/sensor";
import { CandleStickChartWidgetConfigSerialized } from "./candle_stick_chart_widget.types";
import { Moment } from "moment";

/**
 * Javascript implementation of the CandleStickChartWidget.
 *
 * @class CandleStickChartWidget
 * @extends {Widget}
 */
export default class CandleStickChartWidget extends Widget {
  assetIds: (number | string)[];
  sensorIds: (number | string)[];
  dataUrls: string[];
  trendUrls: string[];
  samplingRate: SamplingRate;
  timeScope: DateRange;
  hideSettings: boolean;
  disableSettings: boolean;
  dataLoaded: Promise<void>;

  config: CandleStickChartWidgetConfigSerialized;
  private chart: PlotlyCandleStickChart;

  static getDomClassName(): string {
    return "candle-stick-chart-widget";
  }

  static widgetClassName(): string {
    return "CandleStickChartWidget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data(
      "config",
    ) as CandleStickChartWidgetConfigSerialized;
    this.config = config;
    this.assetIds = config.asset_ids;
    this.sensorIds = config.sensor_ids;
    this.samplingRate = config.sampling_rate;

    this.dataUrls = this.buildDataUrls();
    //this.trendUrls = this.buildTrendUrls();
    this.createChart(this.rootElement.attr("id"));
    this.dataLoaded = this.chart.setTimeScope(this.timeScope);
  }
  /**
   * @override
   */
  setTimeScope(start: Moment, end: Moment): Promise<any> {
    return this.chart.setTimeScope(new DateRange(start, end));
  }

  /**
   * @override
   */
  setSamplingRate(value: number, unit: SensorSamplingRateUnit): Promise<any> {
    return this.chart.setSamplingRate({ value, unit }, null);
  }

  /**
   * @override
   */
  setBeginAtZero(beginAtZero: boolean): Promise<any> {
    return this.chart.setBeginAtZero(beginAtZero);
  }

  /**
   * @override
   */
  cleanup(): void {
    super.cleanup();
    if (isNil(this.chart)) {
      return;
    }

    this.chart.destroyChart();
    this.chart = null;
  }

  /**
   * @override
   */
  toggleFullscreen(): void {
    super.toggleFullscreen();
    void this.chart.resize();
  }

  protected buildDataUrls(): string[] {
    return this.sensorIds.map((sensorId, index) => {
      return url_helper.sensorDataUrl(
        { id: sensorId, assetId: this.assetIds[index] },
        "bin",
      );
    });
  }

  protected buildTrendUrls(): string[] {
    return map(this.sensorIds, (id, index) => {
      return url_helper.sensorTrendUrl({
        id: id,
        assetId: this.assetIds[index],
      });
    });
  }

  protected createChart(widgetId: string) {
    this.chart = new PlotlyCandleStickChart(
      widgetId,
      this.dataUrls,
      this.trendUrls,
    );
    void this.chart.setSamplingRate(this.samplingRate, null);
  }
}

export function hideShowScrollUpButtons() {
  if (
    document.body.scrollTop > 200 ||
    document.documentElement.scrollTop > 200
  ) {
    $(".show-on-scroll-only").fadeIn();
  } else {
    $(".show-on-scroll-only").fadeOut();
  }
}

// Initializer function called on page load
export function bindScrollFunction() {
  // register scroll function to scroll event
  window.onscroll = function () {
    hideShowScrollUpButtons();
  };
}

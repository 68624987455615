import { defaultTo, isEmpty, isNil, isString, toInteger } from "lodash";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import { ContextStateChangeList } from "./context_state_change_list";

import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";

const contextStateChangeListRoots: Root[] = [];
export function initContextStateChangeList(
  selector:
    | JQuery<HTMLElement>
    | string = '[data-toggle="context-state-change-list"]',
) {
  (isString(selector) ? $(selector) : selector).each((index, element) => {
    //const assetTemplateJsonApi = $(element).data('asset-templates');
    const csmId = toInteger($(element).data("csm-id"));
    const timeRangeFromString = $(element).data("from") as string;
    let from: Moment;
    if (!isEmpty(timeRangeFromString)) {
      from = moment(timeRangeFromString);
    }
    const timeRangeToString = $(element).data("to") as string;
    let to: Moment;
    if (!isEmpty(timeRangeToString)) {
      to = moment(timeRangeToString);
    }

    const root = createRoot(element);
    contextStateChangeListRoots.push(root);
    root.render(
      <AppRoot>
        <ContextStateChangeList
          csmId={csmId}
          timeRange={
            isNil(from) && isNil(to)
              ? null
              : new DateRange([from, defaultTo(to, moment().endOf("day"))])
          }
        />
      </AppRoot>,
    );
  });
}

export function destroyContextStateChangeList() {
  contextStateChangeListRoots.forEach((r) => r.unmount());
  contextStateChangeListRoots.length = 0;
}

/// <reference types="../definitions/index" />;
import Bluebird, { Promise } from "bluebird";
import { find, isNil, map, values } from "lodash";
import moment from "../initializers/moment";

import { convertToUnit } from "../utils/unit_conversion";

import {
  DIAGRAM_DEFAULT_HOVER_LABEL_SETTINGS,
  DIAGRAM_POINT_HOVER_BORDER_COLOR,
  getDiagramFillColor,
  getDiagramLineColor,
} from "./diagram_constants";
import { PlotlyDefaultFontHover } from "./plotly_fonts";
import { PlotlyLineChart } from "./plotly_line_chart";
import { Plotly } from "./plotly_package";
import { PlotlyFont } from "./plotly_styling";
import { PlotlyTimeSeriesLineDiagramBase } from "./plotly_time_series_line_diagram_base";
import { DateRange } from "moment-range";
import { BinaryChartDataLoadResult } from "./chart_data/chart_data_loader.types";
import { ZoomMode } from "./plotly_time_series_line_diagram_base.types";
import { Moment } from "moment";

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires
Plotly.register(require("plotly.js-locales/de"));

export class PlotlyCandleStickChart extends PlotlyTimeSeriesLineDiagramBase {
  /**
   * Max loading time until a spinner is shown
   */
  timeBeforeShowSpinnerSeconds = 1;

  /**
   * Defines the zoom mode for the line diagram.
   *  'xy' provides rectangular zoom on both axis
   *  'x' provides x axis zoom only
   */
  private zoomMode: ZoomMode = "xy";

  constructor(
    id: string,
    baseDataURLs: string[],
    trendURLs?: string[],
    yAxesPerUnit = false,
    offsetYAxis = false,
    zoomMode: ZoomMode = "xy",
  ) {
    super(
      { baseElementIdOrElement: id },
      {
        trendURLs,
        lineConfigs: map(baseDataURLs, (url) => ({ baseUrl: url })),
      },
      null,
      { yAxesPerUnit, offsetYAxis },
    );

    this.offsetYAxis = offsetYAxis;
  }

  setData(chartDatasets: BinaryChartDataLoadResult[]) {
    this.yAxes = [];
    this.data = [];
    chartDatasets.forEach((chartDataLoadResult, index) => {
      const chartData = chartDataLoadResult.data;
      const yAxisIndex = this.getOrCreateAxis(
        chartData.series_name,
        chartData.unit,
        [chartData.minvalue, chartData.maxvalue],
      );

      this.data.push({
        ...chartData,
        name: PlotlyLineChart.getDatasetLabel(chartDataLoadResult),
        uid: `chart_trace_${index}`,
        type: "candlestick",
        line: {
          color: getDiagramLineColor(index),
          simplify: false,
          width: 1.1,
        },
        hoverinfo: "x+y+name",
        hoverlabel: {
          font: PlotlyDefaultFontHover,
          bordercolor: DIAGRAM_POINT_HOVER_BORDER_COLOR,
          bgcolor: getDiagramFillColor(index),
        },
        marker: {
          color: getDiagramFillColor(index),
          size: 3,
        },
        yaxis: yAxisIndex === 0 ? "y" : `y${yAxisIndex + 1}`,
      });
    });
  }

  /**
   * Live update diagram data
   * @param attributeKeyId Attribute key of the incomming value
   * @param sensorId Sensor id of the incomming value
   * @param timestamp Timestamp of incomming value
   * @param value
   * @param unit Unit of incomming value(may differ from dataset value)
   */
  addValue(
    attributeKeyId: number,
    sensorId: number,
    timestamp: Moment,
    value: number,
    unit?: string,
  ): void {
    // skip if chart is not initialized or destroyed
    if (isNil(this.chart)) {
      return;
    }

    // skip if value is outside of time range
    if (!isNil(this.timeRange) && !this.timeRange.contains(timestamp)) {
      return;
    }

    const dataset = find(this.data, (data) => data.key_id === attributeKeyId);

    if (isNil(dataset)) {
      return;
    }

    if (!isNil(unit) && dataset.unit !== unit) {
      value = convertToUnit(value, unit, dataset.unit);
    }

    // if unit coversion fails null may be returned
    if (isNil(value)) {
      return;
    }

    // find place to insert new value
    let insertIndex: number = null;
    for (let i = dataset.x.length - 1; i >= 0; i--) {
      if (moment(dataset.x[i] as Date).isBefore(timestamp)) {
        insertIndex = i + 1;
        break;
      }
    }

    if (isNil(insertIndex)) {
      return;
    }

    (dataset.x as Date[]).splice(insertIndex, 0, timestamp.toDate());
    (dataset.y as number[]).splice(insertIndex, 0, value);
    this.newDataRevision();

    void this.updateChart();
  }

  updateTrendLine(): Bluebird<void | HTMLElement> {
    if (isNil(this.chart) || !this.dataIsLoaded) {
      return Bluebird.resolve();
    }

    //this.valueTrends = this.buildValueTrends(this.trendData);
    return this.updateChart();
  }

  updateChart(): Bluebird<void | HTMLElement> {
    if (isNil(this.chartElement)) {
      return Bluebird.resolve();
    }

    const layout: Partial<Plotly.Layout> = {
      hoverlabel: DIAGRAM_DEFAULT_HOVER_LABEL_SETTINGS,
      margin: PlotlyLineChart.margins,
      datarevision: this.dataRevision,
      uirevision: this.uiRevision,
      autosize: true,
      showlegend: true,
      // Position the legend outside of the chart centered horizontally
      legend: {
        orientation: "h",
        x: 0.5,
        y: 1.2,
      },
      xaxis: this.getXAxisLayout(),
      font: PlotlyFont,
      //annotations: this.buildAnnotations(this.trendData),
      ...this.getYAxisLayout(),
    };

    return Promise.resolve(
      Plotly.react(
        this.chartElement.get(0),
        [...this.data],
        //, ...this.valueTrends],
        layout,
        {
          responsive: true,
          displaylogo: false,
          modeBarButtons: PlotlyLineChart.modeBarButtons,
          locale: I18n.locale,
        },
      ).then((chart) => {
        if (isNil(this.chart)) {
          // attach listener only once
          chart.on("plotly_relayout", (event) => this.onChartZoom(event));
          this.chart = chart;
        }

        //return this.resize();
      }),
    );
  }

  private getXAxisLayout(): Partial<Plotly.LayoutAxis> {
    const numAxisLeft = Math.floor(this.yAxes.length / 2 + 0.5);
    const numAxisRight = Math.floor(this.yAxes.length / 2);

    return {
      title: I18n.t("widgets.line_diagram_widget.x_axis_label"),
      linewidth: 1,
      spikethickness: 2,
      domain: [
        PlotlyLineChart.axisPadding * numAxisLeft,
        1.0 - PlotlyLineChart.axisPadding * numAxisRight,
      ],
      rangeslider: {
        visible: false,
      },
    };
  }

  private getYAxisLayout(): Partial<Plotly.Layout> {
    const axisLayout: Partial<Plotly.Layout> = {};

    values(this.yAxes).forEach((axis, index) => {
      const key: keyof Plotly.Layout =
        index === 0 ? "yaxis" : (`yaxis${index + 1}` as keyof Plotly.Layout);
      const range = [
        this.beginAtZero ? 0.0 : axis.range[0],
        axis.range[1] * 1.1,
      ];

      (axisLayout[key] as Partial<Plotly.LayoutAxis>) = {
        ...axis,
        range: range,
      };
    });

    return axisLayout;
  }

  private onChartZoom(event: Plotly.PlotRelayoutEvent): void {
    // update zoom range on zoom events or autoscales
    if (!isNil(event["xaxis.range[0]"]) && !isNil(event["xaxis.range[1]"])) {
      this.zoomRange = new DateRange(
        new Date(event["xaxis.range[0]"]),
        new Date(event["xaxis.range[1]"]),
      );
    } else if (event["xaxis.autorange"]) {
      this.zoomRange = this.timeRange;
    }
  }
}

import EmailIcon from "@mui/icons-material/Email";
import EmailOutLinedIcon from "@mui/icons-material/EmailOutlined";
import * as React from "react";

interface EmailNotificationIconProps {
  enabled: boolean;
}

/**
 * An icon that shows if email notifications are enabled or not
 */
export function EmailNotificationIcon(props: EmailNotificationIconProps) {
  if (props.enabled) {
    return <EmailIcon />;
  } else {
    return <EmailOutLinedIcon color="disabled" />;
  }
}

import { RRule } from 'rrule';

const computeMonthlyMode = (data, rruleObj) => {
  if (rruleObj.freq !== RRule.MONTHLY) {
    return data.repeat.monthly.mode;
  }

  if (rruleObj.bymonthday) {
    return 'on';
  }

  return 'on the';
};

export default computeMonthlyMode;

import { Add, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { isNil } from "lodash";
import * as React from "react";
import { new_asset_asset_template_path } from "../../routes";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { AssetTemplateList } from "../../components/asset_templates/asset_template_list";
import { AssetTypesSensorTypesList } from "../../components/asset_types_sensor_types/asset_types_sensor_types_list";
import { FixedBottomArea } from "../../components/common/fixed_bottom_area";
import { FloatingButtons } from "../../components/common/floating_buttons";
import { ProductModelList } from "../../components/product_models";
import { useLoadAssetTypeQuery } from "../../components/asset_type/asset_type_data";
import { AssetTypeTreeSelect } from "../../components/asset_type/asset_type_tree_select";

interface AssetTypeHomeProps {
  assetTypeId: IDType;
  canEdit: boolean;
}

export const AssetTypeHomePage: React.FC<AssetTypeHomeProps> = ({
  assetTypeId: propAssetTypeId,
  canEdit,
}) => {
  const [assetTypeId, setAssetTypeId] = React.useState(propAssetTypeId);
  React.useEffect(() => {
    setAssetTypeId(propAssetTypeId);
  }, [propAssetTypeId]);

  const { isLoading: assetTypeIsLoading, data: assetType } =
    useLoadAssetTypeQuery({
      variables: { id: assetTypeId },
      enabled: !isNil(assetTypeId),
    });

  return (
    <Grid container spacing={4}>
      <Grid item container xs={12} spacing={2}>
        <Grid item minWidth={400}>
          <Paper elevation={3}>
            <Box padding={2}>
              <Typography variant="subtitle2" marginBottom={1}>
                {I18n.t(
                  "frontend.asset_types.home.select_asset_type_from_tree",
                )}
                :
              </Typography>
              <Divider />
              <Box padding={1}>
                <AssetTypeTreeSelect
                  assetTypeId={propAssetTypeId}
                  selectedIds={[assetTypeId]}
                  onSelectionChange={(selectedIds) => {
                    if (selectedIds.length !== 0 && !isNil(selectedIds[0])) {
                      setAssetTypeId(selectedIds[0]);
                    }
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      {assetTypeIsLoading ? (
        <Grid item xs={12}>
          <Skeleton height={500} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h5">{assetType.name}</Typography>
        </Grid>
      )}

      {assetTypeIsLoading ? (
        <Grid item xs={12}>
          <Skeleton height={500} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <ProductModelList
            assetType={assetType}
            useLongTitle={true}
            canEdit={canEdit}
            itemActions={
              canEdit
                ? (productModel) => {
                    return [];
                  }
                : undefined
            }
            tableHeight={500}
          />
        </Grid>
      )}

      {assetTypeIsLoading ? (
        <Grid item xs={12}>
          <Skeleton height={500} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <AssetTemplateList
            assetType={assetType}
            canEdit={canEdit}
            useLongTitle={true}
            tableHeight={500}
            rowActions={
              canEdit
                ? (assetTemplate) => {
                    return [
                      <GridActionsCellItem
                        showInMenu
                        label={`${I18n.t("frontend.create")} ${I18n.t("activerecord.models.asset.one")}`}
                        icon={<Add />}
                        onClick={() => {
                          redirectTo(
                            new_asset_asset_template_path(assetTemplate.id),
                          );
                        }}
                        title="New Asset"
                        key="new_asset_from_asset_template"
                      />,
                    ];
                  }
                : undefined
            }
          />
        </Grid>
      )}

      {assetTypeIsLoading ? (
        <Grid item xs={12}>
          <Skeleton height={800} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                assetType?.name
                  ? I18n.t(
                      "frontend.asset_types_sensor_types_list.heading_for_asset_type",
                      { asset_type_name: assetType.name },
                    )
                  : I18n.t("frontend.asset_types_sensor_types_list.heading")
              }
            />
            <CardContent>
              <AssetTypesSensorTypesList
                assetType={assetType}
                canEdit={canEdit}
                tableHeight={800}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn
          onCancel={() => {
            redirectTo("back");
          }}
          cancelIcon={<KeyboardArrowLeft />}
        />
      </FixedBottomArea>
    </Grid>
  );
};

import { Box, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { find, isNil, reject } from "lodash";
import * as React from "react";

import { AssetType } from "../../models/asset_type";
import { TreeItem } from "../../models/tree_item";
import { asset_tree_items_api_asset_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { ItemTree } from "../common/item_tree";
import { TreeMode } from "../../json_api/tree_items";
interface AssetTreeSelectProps {
  label?: string;
  // The asset id whose tree to load
  assetId?: IDType;
  selectable?: boolean;
  // or the preloaded asset tree
  assetTree?: AssetTreeItem[];
  treeMode?: TreeMode;
  selectedIds?: IDType[];
  maxHeight?: number | string;

  multiselect?: boolean;
  itemContent?: (
    item: AssetTreeItem,
    parent?: AssetTreeItem,
  ) => React.ReactElement;
  onSelectionChange?: (selectedIds: IDType[]) => void;
}

export interface AssetTreeItem extends TreeItem {
  icon_url?: string;

  asset_type?: AssetType;
}
export const AssetTreeSelect: React.FunctionComponent<AssetTreeSelectProps> = ({
  treeMode = "subtree",
  selectedIds = [],
  multiselect = false,
  selectable = true,
  ...props
}) => {
  const [selectedAssetIds, setSelectedAssetIds] = React.useState(
    selectedIds ?? [],
  );

  React.useEffect(() => {
    if (
      !assetTreeQuery.isLoading &&
      selectedIds != selectedAssetIds &&
      !isNil(props.onSelectionChange)
    ) {
      props.onSelectionChange(selectedAssetIds);
    }
  }, [selectedAssetIds]);

  const assetTreeQuery = useQuery({
    queryKey: ["assetTree", { treeMode, assetId: props.assetId }],
    queryFn: () => {
      if (!isNil(props.assetId)) {
        return loadDataFromUrl<AssetTreeItem[]>(
          asset_tree_items_api_asset_path(props.assetId, {
            _options: true,
            tree_mode: treeMode,
          }),
        );
      }
    },
  });

  return assetTreeQuery.isLoading ? (
    <>
      <Skeleton variant="rectangular" height={40} />
      <Skeleton variant="rectangular" height={40} />
    </>
  ) : (
    <Box overflow="auto" maxHeight={props.maxHeight}>
      <ItemTree
        roots={assetTreeQuery.data}
        checkable={selectable}
        expandAll={true}
        checkedItemIds={selectedAssetIds}
        onItemChecked={(item) => {
          if (multiselect) {
            setSelectedAssetIds([...selectedAssetIds, item.id]);
          } else {
            setSelectedAssetIds([item.id]);
          }
        }}
        onItemClicked={(item) => {
          if (find(selectedAssetIds, item)) {
            setSelectedAssetIds(reject(selectedAssetIds, item.id));
          } else {
            setSelectedAssetIds([...selectedAssetIds, item.id]);
          }
        }}
        onItemUnchecked={(item) => {
          setSelectedAssetIds(reject(selectedAssetIds, item.id));
        }}
        itemContent={props.itemContent}
      />
    </Box>
  );
};

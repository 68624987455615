/// <reference types="../../definitions/index" />;
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import {
  ResourcePermissions,
  ResourcePermissionsGroup,
} from "../../models/permission";
import { PermissionForm } from "./permission_form";
import { DefaultActions } from "./permission_structure";
import { getGroupName } from "./resource_translations";

interface PermissionsGroupFormProperties {
  permissionGroup: ResourcePermissionsGroup;
  onChange: (permissionGroup: ResourcePermissionsGroup) => void;
  expanded?: boolean;
  readOnly?: boolean;
}

interface PermissionsGroupFormState {
  expanded: boolean;
  actionSwitchEnabled: { [string: string]: boolean };
}

export class PermissionsGroupForm extends React.Component<
  PermissionsGroupFormProperties,
  PermissionsGroupFormState
> {
  constructor(props: PermissionsGroupFormProperties) {
    super(props);
    const actionSwitchEnabled: Record<string, boolean> = {};
    DefaultActions.forEach((action) => {
      actionSwitchEnabled[action] = false;
    });
    this.state = {
      expanded: isNil(props.expanded) ? true : props.expanded,
      actionSwitchEnabled,
    };
  }

  componentDidUpdate(prevProps: PermissionsGroupFormProperties): void {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        expanded: this.props.expanded,
      });
    }
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} marginY={1}>
          <Accordion
            expanded={this.state.expanded}
            onChange={(event, exp) => this.setState({ expanded: exp })}
          >
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
              <Typography variant="h5">
                {getGroupName(this.props.permissionGroup.groupName)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} rowSpacing={2}>
                {this.props.readOnly ? null : (
                  <>
                    <Grid item xs={12}>
                      <h5>{I18n.t("base.all")}</h5>
                    </Grid>
                    <Grid item xs={12}>
                      {DefaultActions.map((action, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Switch
                              name={`toggle_${action}_all`}
                              size="small"
                              onChange={(event, checked) => {
                                this.setEnabledForAction(action, checked);
                              }}
                            />
                          }
                          label={I18n.t(
                            `frontend.permission.actions.${action}`,
                          )}
                        />
                      ))}
                    </Grid>
                  </>
                )}
                {this.props.permissionGroup.permissions.map(
                  (permission, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <PermissionForm
                          key={index}
                          permission={permission}
                          onChange={(permission) =>
                            this.onChangePermission(index, permission)
                          }
                          readOnly={this.props.readOnly}
                        />
                      </Grid>
                    );
                  },
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  }

  private onToggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  private setEnabledForAction(action: string, enabled: boolean) {
    const permissions: ResourcePermissions[] =
      this.props.permissionGroup.permissions.map((permission) => {
        if (isNil(permission.actions[action])) return permissions;

        const newActions = {
          ...permission.actions,
        };
        newActions[action] = enabled;
        return {
          ...permission,
          actions: newActions,
        } as ResourcePermissions;
      }) as ResourcePermissions[];
    const permissionGroup: ResourcePermissionsGroup = {
      ...this.props.permissionGroup,
      permissions: permissions,
    };

    this.props?.onChange(permissionGroup);
  }

  private setActionForPermissionGroup(
    permissionGroup: ResourcePermissionsGroup,
    action: string,
    enabled: boolean,
  ) {}
  private onChangePermission = (
    index: number,
    permission: ResourcePermissions,
  ) => {
    const permissionGroup = {
      ...this.props.permissionGroup,
      permissions: [...this.props.permissionGroup.permissions],
    };
    permissionGroup.permissions[index] = permission;

    this.props.onChange(permissionGroup);
  };
}

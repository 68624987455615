import { Divider, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { AssetJsonApiAttributes } from "../../json_api/asset";
import { AttributeRow } from "./attribute_row";

export interface AssetInfoProperties {
  asset: AssetJsonApiAttributes;
  hideHeading?: boolean;
}

export const AssetInfo: React.FunctionComponent<AssetInfoProperties> = (
  props,
) => {
  return (
    <Grid container spacing={2}>
      {props.hideHeading === true ? null : (
        <Grid item xs={12}>
          <Typography variant="h5">
            {I18n.t("activerecord.models.asset", { count: 1 })}
          </Typography>
        </Grid>
      )}
      <Divider />
      <AttributeRow
        attributeName={I18n.t("activerecord.attributes.asset.asset_type")}
        value={props.asset.asset_type_name}
      />
      <Divider />
      <AttributeRow
        attributeName={I18n.t("activerecord.attributes.asset.name")}
        value={props.asset.name}
      />
      <Divider />
      {isEmpty(props.asset?.serial) ? null : (
        <AttributeRow
          attributeName={I18n.t("activerecord.attributes.asset.serial")}
          value={props.asset.serial}
        />
      )}
    </Grid>
  );
};

import { Measurement, MeasurementErrorResponse } from "./model";
import MeasurementDispatcher from "./measurement_dispatcher";
import { User } from "../../maintenance_form/data/model";
import { MeasurementValue } from "../../../models/measurement";

export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";
  measurement: Measurement;
  users: User[];
}

export interface UpdateMeasurementAction {
  type: "UPDATE_MEASUREMENT";
  measurement: Partial<Measurement>;
}

export interface UpdateMeasurementValueAction {
  type: "UPDATE_MEASUREMENT_VALUE";
  measurement_value: Partial<MeasurementValue>;
}

export interface SetProcessingAction {
  type: "SET_PROCESSING";
  isProcessing: boolean;
}

export interface SetErrorsAction {
  type: "SET_ERRORS";
  errorResponse: MeasurementErrorResponse;
}

export interface ResetErrorsAction {
  type: "RESET_ERRORS";
}

export type MeasurementAction =
  | ResetStateAction
  | LoadInitialStateAction
  | UpdateMeasurementAction
  | UpdateMeasurementValueAction
  | SetProcessingAction
  | SetErrorsAction
  | ResetErrorsAction;

/**
 * Reset the state of the store to the default state
 */
export function resetState(): void {
  MeasurementDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Initialize state of the measurement store
 * @param measurement The measurement to edit
 */
export function loadInitialState(
  measurement: Measurement,
  users: User[],
): void {
  MeasurementDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    measurement,
    users,
  });
}

export function updateMeasurement(measurement: Partial<Measurement>): void {
  MeasurementDispatcher.dispatch({
    type: "UPDATE_MEASUREMENT",
    measurement,
  });
}

export function updateMeasurementValue(
  measurementValue: Partial<MeasurementValue>,
): void {
  MeasurementDispatcher.dispatch({
    type: "UPDATE_MEASUREMENT_VALUE",
    measurement_value: measurementValue,
  });
}

/**
 * Sets wether the form is processing a request or not
 * @param isProcessing True if form is processing a request
 */
export function setProcessing(isProcessing: boolean): void {
  MeasurementDispatcher.dispatch({
    type: "SET_PROCESSING",
    isProcessing,
  });
}

/**
 * Applies errors from error response to model to show it in the form.
 * @param errorResponse
 */
export function setErrors(errorResponse: MeasurementErrorResponse): void {
  MeasurementDispatcher.dispatch({
    type: "SET_ERRORS",
    errorResponse,
  });
}

/**
 * Resets errors shown in form
 */
export function resetErrors(): void {
  MeasurementDispatcher.dispatch({
    type: "RESET_ERRORS",
  });
}

import {
  Dictionary,
  chain,
  find,
  findKey,
  isEmpty,
  isNil,
  last,
  trim,
  values,
} from "lodash";
import moment from "moment";
import { MeasurementValue } from "../../models/measurement";
import { MeasurementCategory } from "../../models/measurement_category";
import {
  MeasurementValueDefinition,
  mvdRangeString,
} from "../../models/measurement_value_definition";
import { ColorUtils } from "../../utils/colors";
import { unitDisplayString } from "../../utils/unit_conversion";
import {
  createAxisLayout,
  getAxisChartDataNameFromProperty,
  getAxisPropertyName,
} from "../chart_axis_helper";
import { Plotly } from "../plotly_package";

interface PlotDataWithUnit extends Plotly.PlotData {
  unit?: string;
}
type PlotDataById = Dictionary<Partial<PlotDataWithUnit>>;

/**
 * Creates plotly chart data and axis layout from measurements
 */
export class MeasurementChartDataCreator {
  private data: PlotDataById = {};
  private yAxes: { [index: string]: Partial<Plotly.LayoutAxis> } = {};

  /**
   * Creates chart data from measuremetn value definitions and values.
   * @param measurementValueDefinitions The measurement value defintions
   * @param measurementValues The measurement values
   * @param groupByCategory Groups measurement values by measurement category. Defaults to false
   * @param mvdIntervalUnit Inteval unit for the measurement value defintion intervals.
   * @param usePercentage Flag to indicate a percentage unit. if false the measurementValueDefinition unit will be used. Defaults to false.
   */
  createChartData(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    measurementValues: MeasurementValue[],
    groupByCategory = false,
    mvdIntervalUnit: string,
    usePercentage = false,
  ): void {
    this.reset();
    this.createLineDefinitions(
      measurementValueDefinitions,
      measurementCategories,
      groupByCategory,
      usePercentage ? "percentage" : "measurementUnit",
      mvdIntervalUnit,
    );
    this.insertMeasurementValuesIntoLine(
      measurementValueDefinitions,
      measurementValues,
      usePercentage,
      groupByCategory,
    );
  }

  /**
   * Returns chart data. `createChartData()` needs to be called first.
   */
  getChartData(): Partial<Plotly.PlotData>[] {
    return values(this.data);
  }

  /**
   * Returns axis layout. `createChartData()` needs to be called first.
   */
  getAxisLayout(): Partial<Plotly.Layout> {
    return this.yAxes;
  }

  private reset(): void {
    this.data = {};
    this.yAxes = {};
  }

  private createLineDefinitions(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    groupByCategory: boolean,
    valueUnitType: "measurementUnit" | "percentage" = "measurementUnit",
    intervalUnit?: string,
  ): void {
    if (groupByCategory) {
      measurementCategories.forEach((measurementCategory, index) => {
        const measurementValueDefinition = find(
          measurementValueDefinitions,
          (mvd) => mvd.measurement_category_id == measurementCategory.id,
        );
        const unitToUse =
          valueUnitType === "percentage"
            ? "%"
            : unitDisplayString(measurementValueDefinition.unit);
        this.data[measurementCategory.id] = {
          type: "scattergl",
          name: trim(
            getMeasurementCategoryName(measurementCategory, intervalUnit),
          ),
          mode: "lines+markers",
          hoverinfo: "x+y+name+text" as any,
          line: {
            color: getLineColor(index),
            simplify: false,
            width: 1.1,
          },
          marker: {
            color: getFillColor(index),
            size: 3,
          },
          yaxis: this.getOrCreateYAxes(unitToUse, unitToUse),
          x: [],
          y: [],
          text: [],
          unit: unitToUse,
        };
      });
    } else {
      // Create a line per measurement value definition
      measurementValueDefinitions.forEach(
        (measurementValueDefinition, index) => {
          const measurementCategory = find(
            measurementCategories,
            (mc) => mc.id == measurementValueDefinition.measurement_category_id,
          );
          const unitToUse =
            valueUnitType === "percentage"
              ? "%"
              : measurementValueDefinition.unit;
          this.data[measurementValueDefinition.id] = {
            type: "scattergl",
            name: trim(
              getMeasurementValueName(
                measurementValueDefinition,
                measurementCategory,
                intervalUnit,
              ),
            ),
            mode: "lines+markers",
            hoverinfo: "x+y+name+text" as any,

            line: {
              color: getLineColor(index),
              simplify: false,
              width: 1.1,
            },
            marker: {
              color: getFillColor(index),
              size: 3,
            },
            yaxis: this.getOrCreateYAxes(unitToUse, unitToUse),
            x: [],
            y: [],
            text: [],
            unit: unitToUse,
          };
        },
      );
    }
  }

  private insertMeasurementValuesIntoLine(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementValues: MeasurementValue[],
    usePercentage = false,
    groupByCategory = false,
  ): void {
    const valueDefinitionIdToCategoryId = chain(measurementValueDefinitions)
      .keyBy("id")
      .mapValues("measurement_category_id")
      .value();

    const valueDefinitionsById = chain(measurementValueDefinitions)
      .keyBy("id")
      .value();
    if (groupByCategory) {
      measurementValues.forEach((measurementValue) => {
        const categoryId =
          valueDefinitionIdToCategoryId[
            measurementValue.measurement_value_definition_id
          ];
        if (isNil(categoryId)) {
          return;
        }

        const line = this.data[categoryId];
        if (isNil(line)) {
          return;
        }

        const x = line.x as Date[];
        const y = line.y as number[];
        const text = line.text as string[];
        const valueProperty: "percent" | "value" = usePercentage
          ? "percent"
          : "value";
        const timestamp = moment(measurementValue.timestamp).toDate();
        if (last(x)?.getTime() === timestamp.getTime()) {
          // Aggregate existing value
          y[y.length - 1] += measurementValue[valueProperty];
          const prevText = text[text.length - 1];
          if (isNil(prevText) && !isNil(measurementValue.note)) {
            text[text.length - 1] = measurementValue.note;
          } else if (!isNil(prevText) && !isNil(measurementValue.note)) {
            text[text.length - 1] = `${text[text.length - 1]}, ${
              measurementValue.note
            }`;
          } else if (isNil(prevText) && isNil(measurementValue.note)) {
            text[text.length - 1] = undefined;
          }
        } else {
          // Insert new value
          x.push(timestamp);
          y.push(measurementValue[valueProperty]);
          text.push(measurementValue.note);
        }
      });
    } else {
      measurementValues.forEach((measurementValue) => {
        const valueDefinition =
          valueDefinitionsById[
            measurementValue.measurement_value_definition_id
          ];
        const line =
          this.data[measurementValue.measurement_value_definition_id];
        const valueProperty: "percent" | "value" = usePercentage
          ? "percent"
          : "value";
        if (isNil(line)) {
          return;
        }

        const x = line.x as Date[];
        const y = line.y as number[];
        const text = line.text as string[];

        x.push(moment(measurementValue.timestamp).toDate());
        if (usePercentage) {
          y.push(measurementValue.percent);
          text.push(
            `(${measurementValue.value} ${valueDefinition.unit}) ${measurementValue.note}`,
          );
        } else {
          y.push(measurementValue.value);
          text.push(measurementValue.note);
        }
      });
    }
  }

  private getOrCreateYAxes(axisTitle: string, axisUnit: string): string {
    let axisName = findKey(this.yAxes, (axis) => axis.title === axisTitle);
    let axis = this.yAxes[axisName];

    if (isNil(axisName) || isNil(axis)) {
      const axisIndex = values(this.yAxes).length;
      axisName = getAxisPropertyName("y", axisIndex);
      axis = createAxisLayout(axisIndex, axisTitle, axisUnit);

      this.yAxes[axisName] = axis;
    }

    return getAxisChartDataNameFromProperty(axisName);
  }
}

const FillColors = ColorUtils.getColorsRgba();
const LineColors = ColorUtils.getColorsRgba();

function getLineColor(index: number): string {
  return FillColors[index % LineColors.length];
}

function getFillColor(index: number): string {
  return FillColors[index % FillColors.length];
}

function getMeasurementValueName(
  measurementValueDefinition: MeasurementValueDefinition,
  measurementCategory?: MeasurementCategory,
  intervalUnit?: string,
): string {
  const rangeString = mvdRangeString(measurementValueDefinition, intervalUnit);
  if (!isNil(measurementCategory)) {
    return `${measurementValueDefinition.title} (${measurementCategory.title})${
      isEmpty(rangeString) ? "" : "<br />" + rangeString
    }`;
  }

  return (
    measurementValueDefinition.title +
    (isEmpty(rangeString) ? "" : "\n" + rangeString)
  );
}

function getMeasurementCategoryName(
  measurementCategory: MeasurementCategory,
  intervalUnit: string = null,
): string {
  return `${measurementCategory.title} ${mvdRangeString(
    measurementCategory,
    intervalUnit,
  )}`;
}

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { Fab, Grid } from "@mui/material";
import { NumericDictionary, isEmpty, isNil } from "lodash";
import moment from "moment";
import * as React from "react";
import { AssetJsonApiAttributes } from "../../../json_api/asset";
import { MeasurementJSONApiAttributes } from "../../../json_api/measurement";
import { MeasurementPlanJSONApiAttributes } from "../../../json_api/measurement_plan";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { MeasurementValueAttributes } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinitionAttributes } from "../../../models/measurement_value_definition";
import { dialog } from "../../../utils/dialog";
import { sendData } from "../../../utils/jquery_helper";
import { redirectTo } from "../../../utils/redirection";

import { MeasurementTypeAttributes } from "../../../models/measurement_type";
import {
  assetMeasurementPlanMeasurementPath,
  assetMeasurementPlansMeasurementsPath,
  editMeasurementPath,
} from "../../../utils/urls";
import { AssetInfo } from "../../common/asset_info";
import { AttachmentList } from "../../common/attachment_list";
import { AttributeRow } from "../../common/attribute_row";
import { FilePreviewModal } from "../../common/file_preview_modal";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { JSONSchemaValueRow } from "../../common/json_schema_value_row";
import { MeasurementValuesSection } from "./measurement_values_section";

interface MeasurementItemContainerProperties {
  asset?: AssetJsonApiAttributes;
  measurement: MeasurementJSONApiAttributes;
  measurementValues: MeasurementValueAttributes[];
  measurementPlan?: MeasurementPlanJSONApiAttributes;
  measurementType?: MeasurementTypeAttributes;
  measurementValueDefinitionsMap: NumericDictionary<
    MeasurementValueDefinitionAttributes[]
  >;
  orderedMeasurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurementCategoryById: NumericDictionary<MeasurementCategory>;
}
export interface MeasurementItemContainerState {
  fileToPreview?: ActiveStorageStoredFile;
}

export class MeasurementItemContainer extends React.Component<
  MeasurementItemContainerProperties,
  MeasurementItemContainerState
> {
  constructor(props: MeasurementItemContainerProperties) {
    super(props);
    this.state = {
      fileToPreview: null,
    };
  }

  render(): React.ReactNode {
    return (
      <>
        <IBox>
          <IBoxTitle>
            <h4 className="auto-hyphen">
              {I18n.t("frontend.measurement_list_item.heading", {
                date: moment(this.props.measurement.created_at).format("L LT"),
              })}
            </h4>
          </IBoxTitle>
          <IBoxContent>
            <Grid container>
              <Grid item container xs={12} className="mt-3">
                <Grid item xs={12} sm={6}>
                  <AttributeRow
                    attributeName={I18n.t(
                      "activerecord.attributes.measurement.created_by",
                    )}
                    value={this.props.measurement?.created_by_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {isEmpty(this.props.measurement?.note) ? null : (
                    <AttributeRow
                      attributeName={I18n.t(
                        "activerecord.attributes.measurement.note",
                      )}
                      value={this.props.measurement?.note}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className="border-top border-lg-none"
              >
                <Grid item xs={12}>
                  <h3>
                    {I18n.t("frontend.measurement_list_item.meta_info_heading")}
                  </h3>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AssetInfo asset={this.props.asset} hideHeading={true} />
                </Grid>
                {isEmpty(this.props.measurement.meta_info) ? null : (
                  <>
                    <Grid container item xs={12} sm={6} spacing={1}>
                      <JSONSchemaValueRow
                        schema={this.props.measurementPlan.meta_info_schema}
                        jsonValues={this.props.measurement.meta_info}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} className="mt-3">
                <h3>
                  {I18n.t("frontend.measurement_list_item.values_heading")}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <MeasurementValuesSection
                  {...this.props}
                  showPercentage={
                    this.props.measurementPlan.measurement_type_type ===
                    "distribution_measurement_type"
                  }
                  mvdIntervalUnit={this.props.measurementType?.interval_unit}
                />
              </Grid>

              {isEmpty(this.props.measurement.attachments) ? null : (
                <>
                  <Grid item xs={12} className="mt-3">
                    <h3>
                      {I18n.t("frontend.measurement_list_item.files_heading")}
                    </h3>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachmentList
                      attachments={this.props.measurement.attachments}
                      onShow={(file) => this.onShowFilePreview(file)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </IBoxContent>
          <FilePreviewModal
            url={this.state.fileToPreview?.url}
            contentType={this.state.fileToPreview?.content_type}
            isOpen={!isNil(this.state.fileToPreview)}
            onClose={() => this.closeFilePreview()}
          />
        </IBox>
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn={true}
            isProcessing={false}
            submitBtnIcon={<PrintIcon />}
            saveTitle={I18n.t("frontend.print")}
            onSubmit={() => {
              window.print();
            }}
          >
            <Fab
              size="medium"
              aria-label={I18n.t("frontend.edit")}
              title={I18n.t("frontend.edit")}
              color="default"
              href={editMeasurementPath(this.props.measurement.id)}
            >
              <EditIcon />
            </Fab>
            <Fab
              aria-label={I18n.t("frontend.delete")}
              title={I18n.t("frontend.delete")}
              color="default"
              size="medium"
              onClick={() => void this.deleteMeasurement()}
            >
              <DeleteIcon />
            </Fab>
          </FloatingButtons>
        </FixedBottomArea>
      </>
    );
  }

  private deleteMeasurement() {
    const id = this.props?.measurement?.id;
    const assetId = this.props?.measurement?.asset_id;
    if (!isNil(id)) {
      return dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          text: I18n.t("frontend.are_you_sure"),
          title: I18n.t("frontend.delete"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            const url = assetMeasurementPlanMeasurementPath(
              assetId,
              this.props.measurement.measurement_plan_id,
              id,
              "json",
            );
            const method = "DELETE";
            return sendData(url, null, method)
              .then(() => {
                redirectTo(
                  assetMeasurementPlansMeasurementsPath(
                    assetId,
                    this.props.measurement.measurement_plan_id,
                  ),
                );
              })
              .catch((error) => {
                void toasts.error(
                  I18n.t("frontend.measurement_plan_form.error_on_delete"),
                  I18n.t("frontend.measurement_plan_form.error_title"),
                );
              });
          }
        });
    }
  }

  print() {
    window.print();
  }
  closeFilePreview() {
    this.setState({ fileToPreview: null });
  }

  onShowFilePreview(file: ActiveStorageStoredFile) {
    this.setState({ fileToPreview: file });
  }
}

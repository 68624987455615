import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  loadDataFromUrl,
  sendData,
  sendJsonApiData,
} from "../../utils/jquery_helper";
import {
  api_asset_types_sensor_type_path,
  api_asset_types_sensor_types_path,
} from "../../routes";
import { IDType } from "../../utils/urls/url_utils";
import {
  ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
  ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_UPDATABLE_ATTRIBUTES,
  AssetTypesSensorTypeJSONObject,
} from "../../json_api/asset_types_sensor_type";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../../utils/jsonapi_form_tools";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import {
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import { ASSET_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/asset_type";
import { SENSOR_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/sensor_type";
import { createQuery } from "react-query-kit";
import { concat, uniq } from "lodash";

interface LoadAssetTypeSensorTypesRequest {
  assetTypeId: IDType;
  includes?: string[];
  page: number;
  pageSize: number;
}

export const useLoadAssetTypeSensorTypesQuery = createQuery<
  LoadItemsResult<AssetTypesSensorTypeJSONObject>,
  LoadAssetTypeSensorTypesRequest
>({
  queryKey: [ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE],
  fetcher: async (vars) => {
    const options = {
      filter: { asset_type: vars.assetTypeId },

      include: uniq(concat(vars.includes || [], ["sensor_type"])).join(","),
    };
    if (vars.page || vars.pageSize) {
      (options as any).page = {
        number: vars.page || 1,
        size: vars.pageSize || 100,
      };
    }
    const path = api_asset_types_sensor_types_path(options);
    return loadItemResultForJsonApiCollectionResourceDoc<AssetTypesSensorTypeJSONObject>(
      await loadDataFromUrl<
        CollectionResourceDoc<string, AssetTypesSensorTypeJSONObject>
      >(path),
    );
  },
});
export const useDeleteAssetTypeSensorType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(
        api_asset_types_sensor_type_path(id),
        undefined,
        "DELETE",
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};

export const useUpdateAssetTypeSensorType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (attributes: AssetTypesSensorTypeJSONObject) => {
      let sendData = buildJsonApiSubmitData<AssetTypesSensorTypeJSONObject>(
        attributes,
        ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
        ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_UPDATABLE_ATTRIBUTES,
      );

      addHasOneRelationToJsonApiSubmitData(
        sendData.submitData,
        "sensor_type",
        SENSOR_TYPE_JSONAPI_RESOURCE_TYPE,
        attributes.sensor_type_id,
        false,
      );

      return sendJsonApiData<
        SingleResourceDoc<string, Partial<AssetTypesSensorTypeJSONObject>>,
        SingleResourceDoc<string, AssetTypesSensorTypeJSONObject>
      >(
        api_asset_types_sensor_type_path(attributes.id),
        sendData.submitData,
        "PATCH",
      ).then((result) => {
        return jsonApiSingleResourceToFlatObject(result);
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};

export const useCreateAssetTypeSensorType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      attributes: AssetTypesSensorTypeJSONObject;
      assetTypeId: IDType;
    }) => {
      let sendData = buildJsonApiSubmitData<AssetTypesSensorTypeJSONObject>(
        args.attributes,
        ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE,
        ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_UPDATABLE_ATTRIBUTES,
      );
      addHasOneRelationToJsonApiSubmitData(
        sendData.submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        args.assetTypeId,
        false,
      );

      addHasOneRelationToJsonApiSubmitData(
        sendData.submitData,
        "sensor_type",
        SENSOR_TYPE_JSONAPI_RESOURCE_TYPE,
        args.attributes.sensor_type_id,
        false,
      );

      return sendJsonApiData(
        api_asset_types_sensor_types_path(),
        sendData.submitData,
        "POST",
      ).then((result) => {
        return jsonApiSingleResourceToFlatObject(
          result as SingleResourceDoc<string, AssetTypesSensorTypeJSONObject>,
        );
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};

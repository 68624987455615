import geojson from "geojson";
import L from "leaflet";
import { isEmpty, isNil, toInteger } from "lodash";
import { WidgetController } from "../../controller/widget_controller";
import { getAssetMarker } from "../../map/asset";
import { addFeaturePopup } from "../../map/feature_popup";
import { getFeatureIdFromSensorFeature } from "../../map/geojson_tools";
import { addLocationDataFeaturePopup } from "../../map/location_data_popup";
import {
  AssetFeatureType,
  LocationFeatureProperties,
} from "../../map/map.types";
import { ColorUtils } from "../../utils/colors";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { assetTypeLocationsPath, assetsLocationsPath } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { SialogicQueryClient } from "../common/sialogic_query_client";
import { AssetMapWidgetProps } from "./asset_map_widget.types";

export const loadAssetLocations = (
  props: AssetMapWidgetProps,
  assetIdMarkerMap: React.MutableRefObject<Map<number, L.Marker>>,
  assetSensorIdMarkerMap: React.MutableRefObject<Map<number, L.Marker>>,
  assetContextStateMachineIdMarkerMap: React.MutableRefObject<
    Map<number, L.Marker>
  >,
  assetLayer: React.MutableRefObject<L.FeatureGroup>,
  map: React.MutableRefObject<L.Map>,
): Promise<void> => {
  if (isEmpty(props.assetIds)) {
    return;
  }
  let queryKey: [
    "assetTypeLocations" | "assetLocations",
    {
      assetIds?: IDType[];
      assetTypeId?: IDType;
      sensorTypes?: string[];
      assetStates?: boolean;
    },
  ];
  if (!isNil(props.assetTypeId) && !isEmpty(props.assetTypeId)) {
    queryKey = [
      "assetTypeLocations",
      {
        assetTypeId: props.assetTypeId,
        sensorTypes: props.loadSensorWithTypes,
        assetStates: props.loadAssetStates,
      },
    ];
  } else if (!isEmpty(props.assetIds)) {
    queryKey = [
      "assetLocations",
      {
        assetIds: props.assetIds,
        sensorTypes: props.loadSensorWithTypes,
        assetStates: props.loadAssetStates,
      },
    ];
  }
  assetIdMarkerMap.current.clear();
  assetSensorIdMarkerMap.current.forEach((marker, sensorId) => {
    WidgetController.getInstance().sensorDataChannel.removeEventListener(
      this,
      sensorId,
    );
  });

  assetContextStateMachineIdMarkerMap.current.forEach((marker, csmId) => {
    WidgetController.getInstance().contextStateMachineChannel.removeEventListener(
      this,
      csmId,
    );
  });

  assetSensorIdMarkerMap.current.clear();
  assetContextStateMachineIdMarkerMap.current.clear();
  return SialogicQueryClient.fetchQuery({
    queryKey,
    queryFn: ({ queryKey }) => {
      const url =
        queryKey[0] == "assetLocations"
          ? assetsLocationsPath(
              queryKey[1].assetIds,
              queryKey[1].sensorTypes,
              queryKey[1].assetStates,
            )
          : assetTypeLocationsPath(
              queryKey[1].assetTypeId,
              queryKey[1].sensorTypes,
              queryKey[1].assetStates,
            );
      return loadDataFromUrl<
        geojson.FeatureCollection<geojson.Point, LocationFeatureProperties>
      >(url);
    },
  }).then((data) => {
    if (isNil(map.current)) return;

    if (!isNil(assetLayer.current)) {
      assetLayer.current.remove();
    }

    const assetLayerInstance: L.FeatureGroup = props.enableAssetClustering
      ? L.markerClusterGroup()
      : L.featureGroup();
    const assetMarkers = L.geoJSON(data, {
      pointToLayer: (feature) => {
        const marker = getAssetMarker(
          feature,
          props.markerMappingMode,
          props.markerMappingStateContextIdentifier,
          props.markerMappingSensorKey,
          () => handleMarkerClick(marker, feature),
        );
        assetIdMarkerMap.current.set(toInteger(feature.id), marker);
        const sensorId = getSensorIdToUpdateForFeature(feature, props);
        const contextStateMachineId =
          getContextStateMachineIdToUpdateForFeature(feature, props);
        if (contextStateMachineId) {
          assetContextStateMachineIdMarkerMap.current.set(
            contextStateMachineId,
            marker,
          );
        }
        if (sensorId) {
          assetSensorIdMarkerMap.current.set(sensorId, marker);
        }

        return marker;
      },
      onEachFeature: addFeaturePopup,
    });

    assetLayerInstance.addLayer(assetMarkers);
    assetLayer.current = assetLayerInstance;
    assetLayer.current.addTo(map.current);
    assetSensorIdMarkerMap.current.forEach((marker, sensorId) => {
      WidgetController.getInstance().sensorDataChannel.subscribe(sensorId);
      WidgetController.getInstance().sensorDataChannel.addEventListener(
        this,
        sensorId,
      );
    });

    assetContextStateMachineIdMarkerMap.current.forEach((marker, csmId) => {
      WidgetController.getInstance().contextStateMachineChannel.subscribe(
        csmId,
      );
      WidgetController.getInstance().contextStateMachineChannel.addEventListener(
        this,
        csmId,
      );
    });
  });
};

export const loadLocationSensorData = (
  props: AssetMapWidgetProps,
  sensorsLayer: React.MutableRefObject<L.Realtime>,
  map: React.MutableRefObject<L.Map>,
): Promise<any> => {
  if (isEmpty(props.sensorIds)) {
    return;
  }
  const lineColors: string[] = ColorUtils.getColorsRgba(1.0);
  const markerColors: string[] = ColorUtils.getColorsRgba(0.8);

  const sensorIdToColors: {
    [sensorId: string]: { lineColor: string; markerColor: string };
  } = {};
  props.sensorIds.forEach((sensorId, index) => {
    sensorIdToColors[sensorId] = {
      lineColor: lineColors[index % lineColors.length],
      markerColor: markerColors[index % markerColors.length],
    };
  });

  if (sensorsLayer.current) {
    sensorsLayer.current.remove();
  }
  sensorsLayer.current = L.realtime("", {
    start: false,
    getFeatureId: getFeatureIdFromSensorFeature,
    onEachFeature: addLocationDataFeaturePopup,
    style: function (feature: GeoJSON.Feature) {
      return {
        color:
          sensorIdToColors[feature.properties.sensor_id as string].lineColor,
        weight: 2,
      };
    },
    pointToLayer: function (feature: GeoJSON.Feature, latlng) {
      const color =
        sensorIdToColors[feature.properties.sensor_id as string].markerColor;
      if (feature.properties.isLastFeature) {
        return L.marker(latlng);
      } else {
        return L.circleMarker(latlng, {
          radius: 4,
          fillColor: color,
          color: color,
          weight: 1,
          fillOpacity: 0.8,
          opacity: 0.8,
        });
      }
    },
  });
  sensorsLayer.current.addTo(map.current);
};

export const getContextStateMachineIdToUpdateForFeature = (
  feature: AssetFeatureType,
  props: AssetMapWidgetProps,
): number => {
  if (
    props.markerMappingMode === "state" &&
    !isEmpty(props.markerMappingStateContextIdentifier)
  ) {
    const contextStateMachineId =
      feature.properties.states?.[props.markerMappingStateContextIdentifier]
        .csm_id;
    if (!isNil(contextStateMachineId)) {
      return contextStateMachineId;
    }
    return null;
  } else {
    return null;
  }
};

export const getSensorIdToUpdateForFeature = (
  feature: AssetFeatureType,
  props: AssetMapWidgetProps,
): number => {
  if (props.markerMappingMode === "sensor") {
    const sensorId =
      feature.properties?.sensors?.[props.markerMappingSensorKey]?.sensor_id;
    if (!isNil(sensorId)) {
      return sensorId;
    }
    return null;
  } else {
    return null;
  }
};

export const handleMarkerClick = (
  marker: L.Marker,
  feature: AssetFeatureType,
) => {
  void WidgetController.getInstance().handleItemSelection({
    itemType: "Asset",
    itemId: toInteger(feature.id),
    item: feature,
    source: this,
  });
};

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

import MaterialTable from "@material-table/core";
import { Organization } from "../../models/organization";

import { isEmpty, isNil, reject, trim } from "lodash";
import { DeviceUidRegexp } from "../../models/device";
import { sendData } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import * as toast from "../../utils/toasts";
import {
  batchCreateOrganizationDevicesApiPath,
  organizationDevicesPath,
} from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
export interface DeviceBatchCreateFormProps {
  csvData?: string;
  organization: Organization;
  ibox: boolean;
}

function processTextToDeviceIds(text: string) {
  const parsedDeviceIds = text
    .split("\n")
    .map((item) => trim(trim(item, "\"'")));
  const deviceIds = reject<string>(parsedDeviceIds, isEmpty);
  return deviceIds;
}

function validateDeviceId(id: string): boolean {
  return DeviceUidRegexp.test(id);
}

export const DeviceBatchCreateForm: React.FunctionComponent<
  DeviceBatchCreateFormProps
> = (props) => {
  const [deviceIds, setDeviceIds] = React.useState<string[]>([]);
  const [submitEnabled, setSubmitEnabled] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const form = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="caption">
          {I18n.t("frontend.devices.device_batch_create_form.description")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={I18n.t(
              "frontend.devices.device_batch_create_form.input_text",
            )}
            variant="outlined"
            fullWidth
            inputRef={textFieldRef}
            multiline
            onPaste={(event) => {
              const pastedString = event.clipboardData.getData("text");
              const newDeviceIds = processTextToDeviceIds(pastedString);
              setSubmitEnabled(!isEmpty(newDeviceIds));
              setDeviceIds(processTextToDeviceIds(pastedString));
            }}
            minRows={23}
            maxRows={23}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              if (textFieldRef.current) {
                const newDeviceIds = processTextToDeviceIds(
                  textFieldRef.current.value,
                );
                setSubmitEnabled(!isEmpty(newDeviceIds));
                setDeviceIds(
                  processTextToDeviceIds(textFieldRef.current.value),
                );
              }
            }}
          >
            Convert Text
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={6}>
        <MaterialTable
          title={I18n.t(
            "frontend.devices.device_batch_create_form.devices_to_create",
          )}
          options={{
            maxBodyHeight: 400,
            minBodyHeight: 400,
            paging: false,
            headerStyle: { fontSize: 15 },
          }}
          columns={[
            {
              title: I18n.t("activerecord.attributes.device.uid"),
              field: "uid",
              validate(rowData) {
                if (!validateDeviceId(rowData.uid)) {
                  return {
                    isValid: false,
                    helperText: I18n.t(
                      "activerecord.errors.models.device.attributes.uid.may_only_contain_safe_characters",
                    ),
                  };
                } else {
                  return true;
                }
              },
            },
          ]}
          editable={{
            isEditable: () => true,
            onRowUpdate: (newData) => {
              const newDeviceIds = [...deviceIds];
              newDeviceIds[newData.index] = newData.uid;
              return Promise.resolve(setDeviceIds(newDeviceIds));
            },
            onRowAdd: (newData) => {
              const newDeviceIds = [...deviceIds];
              newDeviceIds.push(newData.uid);
              setSubmitEnabled(!isEmpty(newDeviceIds));
              return Promise.resolve(setDeviceIds(newDeviceIds));
            },
            onRowDelete: (oldData) => {
              if (!isNil(oldData.index)) {
                const newDeviceIds = [...deviceIds];
                newDeviceIds.splice(oldData.index);
                setDeviceIds(newDeviceIds);
                setSubmitEnabled(!isEmpty(newDeviceIds));
                return Promise.resolve(newDeviceIds);
              }
              return Promise.resolve(deviceIds);
            },
          }}
          data={deviceIds.map((uid, index) => ({ uid: uid, index }))}
        />
      </Grid>
      <FixedBottomArea id="fixed-bottom-area">
        <FloatingButtons
          isProcessing={processing}
          disableSave={!submitEnabled || processing}
          onSubmit={() => {
            setProcessing(true);
            void submitDevices(deviceIds, props.organization.id)
              .then((res) => {
                void toast.success(I18n.t("frontend.success"), res.message);
              })
              .catch((e) => {
                void toast.error(
                  I18n.t("frontend.error"),
                  I18n.t(
                    "frontend.devices.device_batch_create_form.error_creating_devices",
                  ),
                );
              })
              .finally(() => {
                setProcessing(false);
              });
          }}
          onCancel={() => {
            redirectTo(organizationDevicesPath(props.organization.id));
          }}
        ></FloatingButtons>
      </FixedBottomArea>
    </Grid>
  );

  if (props.ibox) {
    return (
      <Card>
        <CardHeader
          title={I18n.t("frontend.devices.device_batch_create_form.heading")}
        ></CardHeader>
        <CardContent>{form}</CardContent>
      </Card>
    );
  } else {
    return form;
  }
};

async function submitDevices(
  deviceIds: string[],
  organizationId: IDType,
): Promise<{ status: string; message: string }> {
  const response = await sendData<unknown, { status: string; message: string }>(
    batchCreateOrganizationDevicesApiPath(organizationId),
    { device_uids: deviceIds },
    "POST",
  );

  return response;
}

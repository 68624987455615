import * as React from "react";

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isNil } from "lodash";
import { Root, createRoot } from "react-dom/client";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { UserJSONObject } from "../../json_api/user";
import { AppRoot } from "../common/app_root";
import { UsersAutocomplete } from "./users_autocomplete";

const usersAutocompleRoots: Root[] = [];
/**
 * Initialize react component UsersAutocomplete within all elements with data-toggle="users-autocomplete".
 *
 */
export function initializeUsersAutocomplete(): void {
  $('[data-toggle="users-autocomplete"]').each((index, element) => {
    const jqE = $(element);
    //  const organizations = $(element).data('organizations');
    const userJsonApi = jqE.data("user") as SingleResourceDoc<
      string,
      UserJSONObject
    >;

    const user = jsonApiSingleResourceToFlatObject<UserJSONObject>(userJsonApi);
    const assetsJsonApi = jqE.data("users") as CollectionResourceDoc<
      string,
      UserJSONObject
    >;
    const assets = isNil(assetsJsonApi)
      ? null
      : jsonApiResourceCollectionToFlatObjects<UserJSONObject>(assetsJsonApi);
    const url = jqE.data("user-base-url") as string;
    const root = createRoot(element);
    usersAutocompleRoots.push(root);
    root.render(
      <AppRoot>
        <UsersAutocomplete usersBaseUrl={url} users={assets} user={user} />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyUsersAutocomplete(): void {
  usersAutocompleRoots.forEach((root) => {
    root.unmount();
  });
  usersAutocompleRoots.length = 0;
}

import * as React from "react";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import { LinkWidgetDefinition } from "../components/widgets/link_widget";
import { LinkWidgetConfigSerialized } from "./link_widget.types";

/**
 * Javascript implementation of the LinkWidget.
 *
 * @class LinkWidget
 * @extends {Widget}
 */
export default class LinkWidget extends Widget {
  config: LinkWidgetConfigSerialized;

  static getDomClassName(): string {
    return "link-widget";
  }

  static widgetClassName(): string {
    return "LinkWidget";
  }

  constructor(element: JQuery<HTMLElement>) {
    super(element);
    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);

    const props = LinkWidgetDefinition.serializedConfigToProps(this.config);
    this.root.render(
      <AppRoot>
        <LinkWidgetDefinition.Component
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    this.config = element.data("config") as LinkWidgetConfigSerialized;
  }

  cleanup(): void {
    super.cleanup();
  }
}

import { Button, Tooltip } from "@mui/material";
import { defaultTo, isNil, upperCase } from "lodash";
import * as React from "react";
import { ItemAction } from "../../models/item_action";
import { HttpError, RequestMethod, sendData } from "../../utils/jquery_helper";
import { Icon } from "./icon";

export interface ItemActionButtonProps {
  action: ItemAction;
  link: string;
  title: string;

  onComplete?: () => void;
  onError?: (error?: HttpError | string) => void;
}

interface ItemActionRequestResult {
  status: "ok" | "success" | "error" | "confirmation_needed";
  message?: string;
  error?: string;
}

async function handleAction(
  props: ItemActionButtonProps,
  setConfirmParam = false,
) {
  try {
    if (!setConfirmParam && props.action.confirm) {
      if (!confirm(props.action.confirm)) {
        return;
      }
    }

    const token = ($("[name=csrf-token]")[0] as HTMLMetaElement)?.content;

    const result: ItemActionRequestResult = await sendData(
      props.link,
      setConfirmParam ? { confirm: true } : null,
      upperCase(props.action.method) as RequestMethod,
      "application/json",
      { "X-CSRF-TOKEN": token, Accept: "application/json" },
    );
    if (result.status === "ok" || result.status === "success") {
      void toasts.success(
        defaultTo(
          result.message,
          I18n.t("frontend.process_successfully_completed"),
        ),
      );
      if (!isNil(props.onComplete)) {
        props.onComplete();
      } else {
        if (props.action.reload_on_success == true) {
          window.location.reload();
        }
      }
    } else {
      if (result.status === "confirmation_needed") {
        if (confirm(result.message)) {
          return handleAction(props, true);
        }
      } else {
        void toasts.error(
          I18n.t("frontend.error"),
          defaultTo(result.message, result.error),
        );
        if (!isNil(props.onError)) {
          props.onError(result.message);
        }
      }
    }
  } catch (error) {
    if (error instanceof HttpError) {
      void toasts.error(I18n.t("frontend.error"), error.response);
      if (!isNil(props.onError)) {
        props.onError(error);
      }
    }
  }
}

export const ItemActionButton: React.FunctionComponent<
  ItemActionButtonProps
> = (props: ItemActionButtonProps) => {
  if (isNil(props.action)) return null;

  let element;
  if (props.action.api) {
    element = (
      <Button
        color="primary"
        variant="outlined"
        size="small"
        type="submit"
        startIcon={props.action.icon ? <Icon icon={props.action.icon} /> : null}
        onClick={() => {
          void handleAction(props);
        }}
      >
        {defaultTo(props.title, props.action.title)}
      </Button>
    );
  } else {
    element = (
      <form
        key={`action_${props.action.action}`}
        action={props.link}
        method={
          defaultTo(props.action.method, "get") !== "get" ? "post" : "get"
        }
      >
        {props.action.method !== "get" ? (
          <input
            readOnly={true}
            name="_method"
            hidden={true}
            value={props.action.method}
          />
        ) : null}
        <input hidden={true} name="authenticity_token" readOnly={true} />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          type="submit"
          startIcon={
            props.action.icon ? <Icon icon={props.action.icon} /> : null
          }
          onClick={(e) => {
            if (props.action.confirm && !confirm(props.action.confirm)) {
              e.preventDefault();
              return false;
            }
            const token = ($("[name=csrf-token]")[0] as HTMLMetaElement)
              ?.content;
            const input = $(e.currentTarget)
              .parent("form")
              .find('input[name="authenticity_token"]');
            input.val(token);
            return true;
          }}
        >
          {defaultTo(props.title, props.action.title)}
        </Button>
      </form>
    );
  }

  if (props.action.tooltip) {
    return <Tooltip title={props.action.tooltip}>{element}</Tooltip>;
  } else {
    return element;
  }
};

import { computeValuePercent } from "../../../utils/calculate_percentage";
import { AutoDispatchReduceStore } from "../../common/auto_dispatch_reduce_store";
import { User } from "../../maintenance_form/data/model";
import {
  LoadInitialStateAction,
  MeasurementAction,
  ResetErrorsAction,
  ResetStateAction,
  SetErrorsAction,
  SetProcessingAction,
  UpdateMeasurementAction,
  UpdateMeasurementValueAction,
} from "./measurement_actions";
import MeasurementDispatcher from "./measurement_dispatcher";
import { Measurement } from "./model";

export interface MeasurementState {
  isProcessing: boolean;
  measurement: Measurement;

  users: User[];
}

export class MeasurementStore extends AutoDispatchReduceStore<
  MeasurementState,
  MeasurementAction
> {
  getInitialState(): MeasurementState {
    return {
      isProcessing: false,
      measurement: {
        measurement_values_attributes: [],
      },
      users: [],
    };
  }

  RESET_STATE(
    state: MeasurementState,
    action: ResetStateAction,
  ): MeasurementState {
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: MeasurementState,
    action: LoadInitialStateAction,
  ): MeasurementState {
    const { measurement, users } = action;
    measurement.measurement_values_attributes = computeValuePercent(
      measurement.measurement_values_attributes,
    );

    return {
      ...state,
      measurement,
      users,
    };
  }

  UPDATE_MEASUREMENT(
    state: MeasurementState,
    action: UpdateMeasurementAction,
  ): MeasurementState {
    return {
      ...state,
      measurement: {
        ...state.measurement,
        ...action.measurement,
      },
    };
  }

  UPDATE_MEASUREMENT_VALUE(
    state: MeasurementState,
    action: UpdateMeasurementValueAction,
  ): MeasurementState {
    const measurement = {
      ...state.measurement,
      measurement_values_attributes:
        state.measurement.measurement_values_attributes.slice(),
    };

    measurement.measurement_values_attributes.forEach(
      (measurementValue, index) => {
        if (
          measurementValue.measurement_value_definition_id ===
          action.measurement_value.measurement_value_definition_id
        ) {
          measurement.measurement_values_attributes[index] = {
            ...measurementValue,
            ...action.measurement_value,
          };
        }
      },
    );

    measurement.measurement_values_attributes = computeValuePercent(
      measurement.measurement_values_attributes,
    );

    return {
      ...state,
      measurement,
    };
  }

  SET_PROCESSING(
    state: MeasurementState,
    action: SetProcessingAction,
  ): MeasurementState {
    return {
      ...state,
      isProcessing: action.isProcessing,
    };
  }

  SET_ERRORS(
    state: MeasurementState,
    action: SetErrorsAction,
  ): MeasurementState {
    return {
      ...state,
      measurement: {
        ...state.measurement,
        errors: action.errorResponse.errors,
      },
    };
  }

  RESET_ERRORS(
    state: MeasurementState,
    action: ResetErrorsAction,
  ): MeasurementState {
    const measurement = { ...state.measurement };
    delete measurement.errors;

    return {
      ...state,
      measurement,
    };
  }
}

export default new MeasurementStore(MeasurementDispatcher);

import * as React from "react";
import { Grid, Typography } from "@mui/material";

interface WidgetTimestampProps {
  timestamp: string;
}

export const WidgetTimestamp: React.FunctionComponent<WidgetTimestampProps> = ({
  timestamp,
}) => {
  return timestamp ? (
    <Typography variant="caption">{timestamp}</Typography>
  ) : null;
};

interface WidgetTimestampGridProps extends WidgetTimestampProps {
  align?: "center" | "left" | "right";
}

export const WidgetTimestampGridItem = ({
  timestamp,
  align = "center",
}: WidgetTimestampGridProps) => {
  return timestamp ? (
    <Grid item xs={12} textAlign={align} minHeight="1em">
      <WidgetTimestamp timestamp={timestamp} />
    </Grid>
  ) : null;
};

import * as React from "react";

import { isEmpty, noop } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { TimeRanges } from "../../definitions/bootstrap-daterangepicker";
import moment from "../../initializers/moment";
import { getCallback } from "../../utils/html_data_attributes";
import {
  createTimeRanges,
  TranslatedTimeScopeWithReverseMap,
} from "../../utils/time_scopes";
import { AppRoot } from "../common/app_root";
import { Icon } from "../common/icon";
import { Moment } from "moment";

type OnChangeCallback = (
  startTime: Moment,
  endTime: Moment,
  selectedRangeId: string,
) => void;

export interface TimeRangePickerProps {
  startDate?: Moment;
  endDate?: Moment;
  ranges?: TranslatedTimeScopeWithReverseMap;
  opens?: "left" | "right" | "center";
  dropdownParent?: string;
  onChange?: OnChangeCallback;
}

export interface TimeRangePickerState {
  startDate?: Moment;
  endDate?: Moment;
}

export class TimeRangePicker extends React.Component<
  TimeRangePickerProps,
  TimeRangePickerState
> {
  static TimeFormat = "YYYY-MM-DDTHH:mm";

  static defaultProps: TimeRangePickerProps = {
    onChange: noop,
    opens: "left",
    ranges: createTimeRanges(),
  };

  inputRef: React.RefObject<HTMLDivElement>;

  constructor(props: TimeRangePickerProps) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    };
  }

  componentDidMount(): void {
    this.initDateRangePicker();
  }

  componentDidUpdate(
    prevProps: TimeRangePickerProps,
    prevState: TimeRangePickerState,
  ): void {
    // update date state
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }

    // update ranges and opens option
    if (
      prevProps.ranges !== this.props.ranges ||
      prevProps.opens !== this.props.opens
    ) {
      this.initDateRangePicker();
    }

    // update picker after state change
    if (prevState !== this.state) {
      const picker = $(this.inputRef.current).data("daterangepicker");
      picker.startDate = this.state.startDate;
      picker.endDate = this.state.endDate;
      picker.updateView();
    }
  }

  render(): React.ReactNode {
    return (
      <div
        className="daterange form-control input-sm float-right"
        ref={this.inputRef}
      >
        <Icon icon="clock-o" />
        &nbsp;
        <span>{this.getDateLabel()}</span>
        <b className="caret" />
      </div>
    );
  }

  private getDateLabel(): string {
    if (this.state.startDate && this.state.endDate) {
      return `${this.state.startDate.format(
        "L LT",
      )} - ${this.state.endDate.format("L LT")}`;
    } else if (this.state.startDate) {
      return `${this.state.startDate.format("L LT")} - `;
    } else if (this.state.endDate) {
      return ` - ${this.state.endDate.format("L LT")}`;
    } else {
      return "---";
    }
  }

  private initDateRangePicker() {
    const momentLocale = moment.localeData();
    $(this.inputRef.current).daterangepicker(
      {
        timePicker: true,
        timePickerIncrement: 1,
        timePicker24Hour: true,
        showCustomRangeLabel: true,
        locale: {
          format: "L LT",
          customRangeLabel: I18n.t(
            "frontend.time_range_picker.select_custom_range",
          ),
          applyLabel: I18n.t("frontend.apply"),
          cancelLabel: I18n.t("frontend.cancel"),
          monthNames: momentLocale.months(),
          firstDay: momentLocale.firstDayOfWeek(),
          daysOfWeek: momentLocale.weekdaysShort(),
        },
        ranges: this.props.ranges.ranges,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        opens: this.props.opens,
        parentEl: this.props.dropdownParent,
      },
      (startDate, endDate, selectedRange) => {
        this.setState({
          startDate: moment(startDate),
          endDate: moment(endDate),
        });

        this.props.onChange(
          moment(startDate),
          moment(endDate),
          this.props.ranges?.labelToIdMap?.[selectedRange] || "custom",
        );
      },
    );
  }
}

const timeRangePickerRoots: Root[] = [];
/**
 * Initialize a diagram settings menu and load settings from data attributes
 * @param selector jquery selector with diagram settings container
 */
export function initializeTimeRangePicker(selector: JQuery): void {
  if (isEmpty(selector)) return;

  selector.each((index: number, element: HTMLInputElement) => {
    const timeRangePicker = $(element);
    const startDate = moment(timeRangePicker.attr("data-start"));
    const endDate = moment(timeRangePicker.attr("data-end"));
    const opens = timeRangePicker.attr("data-opens");
    const dropdownParent = timeRangePicker.attr("data-parent");
    const chartId = timeRangePicker.attr("data-chart-id");
    const onChange = getCallback(
      chartId,
      timeRangePicker.attr("data-callback"),
    );
    const root = createRoot(element);
    timeRangePickerRoots.push(root);

    root.render(
      <AppRoot>
        <TimeRangePicker
          startDate={startDate}
          endDate={endDate}
          opens={opens as any}
          dropdownParent={dropdownParent}
          ranges={createTimeRanges()}
          onChange={onChange}
        />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy diagram settings in given container
 */
export function destroyTimeRangePicker(): void {
  timeRangePickerRoots.forEach((root) => {
    root.unmount();
  });
  timeRangePickerRoots.length = 0;
}

import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { ExternalReference } from "../models/external_reference";
import { api_asset_external_reference_path } from "../routes";
import { sendJsonApiData } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";
import { AssetJSONObject } from "./asset";
import { OrganizationJSONObject } from "./organization";

export type ExternalReferenceJsonApiAttributes = ExternalReference;

export interface ExternalReferenceJSONObject
  extends ExternalReferenceJsonApiAttributes,
    JSON.Object {
  referencedItem?: AssetJSONObject | OrganizationJSONObject;
}

export type ExtenalReferenceJSONAPIAttributes =
  JSONAPI.AttributesObject<ExternalReferenceJSONObject>;

export async function deleteExternalReference(assetId: IDType, id: IDType) {
  const url = api_asset_external_reference_path(assetId, id, {
    asset_id: assetId,
    id: id,
    format: "json",
    locale: I18n.locale,
    _options: true,
  });
  return sendJsonApiData(url, null, "DELETE");
}

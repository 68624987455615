import { Grid, LinearProgress } from "@mui/material";
import { defaultTo } from "lodash";
import * as React from "react";
import { DropzoneArea, FileObject, PreviewIconProps } from "react-mui-dropzone";

export interface DropzoneProps {
  initialFiles?: Array<File | string>;
  fileSelectionChanged(files: File[]): void;
  onAdd?: (file: File) => void;
  onDelete?: (file: File) => void;
  filesLimit?: number;
  acceptedFileTypes?: string[];
  dropzoneText?: string;
  uploading?: boolean;
  uploadProgressPercent?: number;
  maxFileSize?: number;
}

import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
  ViewInAr,
} from "@mui/icons-material";

const handlePreviewIcon = (
  fileObject: FileObject,
  classes: PreviewIconProps,
) => {
  const { type } = fileObject.file;
  const iconProps = {
    className: classes.classes,
    style: {
      maxWidth: 150,
      maxHeight: 100,
    },
  };

  if (type.startsWith("video/")) return <Theaters {...iconProps} />;
  if (type.startsWith("audio/")) return <Audiotrack {...iconProps} />;
  if (type.startsWith("image/"))
    return <img src={URL.createObjectURL(fileObject.file)} {...iconProps} />;

  if (type.startsWith("model/")) {
    return <ViewInAr {...iconProps} />;
  }
  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description {...iconProps} />;
    case "application/pdf":
      return <PictureAsPdf {...iconProps} />;
    default:
      return <AttachFile {...iconProps} />;
  }
};

export const Dropzone: React.FunctionComponent<DropzoneProps> = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <DropzoneArea
          clearOnUnmount={true}
          filesLimit={props.filesLimit}
          initialFiles={props.initialFiles}
          showFileNamesInPreview={true}
          acceptedFiles={props.acceptedFileTypes}
          onDelete={props.onDelete}
          getFileLimitExceedMessage={(filesLimit) =>
            I18n.t("frontend.common.file_dropzone.file_too_large", {
              limit: filesLimit,
            })
          }
          getFileAddedMessage={(file) =>
            I18n.t("frontend.common.file_dropzone.file_added", {
              file: file,
            })
          }
          getDropRejectMessage={(file) =>
            I18n.t("frontend.common.file_dropzone.reject_message", {
              file: file.name,
            })
          }
          getFileRemovedMessage={(file) =>
            I18n.t("frontend.common.file_dropzone.removed_message", {
              file: file,
            })
          }
          dropzoneText={defaultTo(
            props.dropzoneText,
            I18n.t("frontend.common.file_dropzone.dropzone_text"),
          )}
          onChange={(files) => {
            props.fileSelectionChanged(files);
          }}
          maxFileSize={props.maxFileSize}
          getPreviewIcon={handlePreviewIcon}
        />
      </Grid>
      <Grid item xs={12}>
        {props.uploading ? (
          <LinearProgress value={props.uploadProgressPercent} />
        ) : null}
      </Grid>
    </Grid>
  );
};

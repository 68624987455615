/// <reference types="../../../definitions/index" />;

import { Add } from "@mui/icons-material";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementTypes } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { TypedErrorMap } from "../../../utils/error_map";
import { IBox, IBoxContent } from "../../common/ibox";
import { Icon } from "../../common/icon";
import { MeasurementUnitSelect } from "../../common/measurement_unit_select";

interface MeasurementValueDefinitionListProperties {
  typeOfMeasurement: MeasurementTypes;
  unit?: string;
  measurementValueDefinitions: MeasurementValueDefinition[];
  categorization: MeasurementCategorization;
  interval_unit?: string;

  allowDelete: boolean;
  allowEdit?: boolean;

  errors?: TypedErrorMap<MeasurementValueDefinition>[];
  onMoveMeasurementValueDefinition?: (index: number, direction: -1 | 1) => void;
  onAddMeasurementValueDefinition?: () => void;
  onRemoveMeasurementValueDefinition?: (
    mvd: MeasurementValueDefinition,
    index: number,
  ) => void;

  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void;
}
interface MeasurementValueDefinitionListState {}

export class MeasurementValueDefinitionList extends React.Component<
  MeasurementValueDefinitionListProperties,
  MeasurementValueDefinitionListState
> {
  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        {this.props.allowEdit === false ||
        isNil(this.props.onAddMeasurementValueDefinition) ? null : (
          <Grid item xs={12} key="btn-row">
            <Button
              className="float-right"
              variant="contained"
              onClick={this.props.onAddMeasurementValueDefinition}
              size="small"
              color="primary"
              startIcon={<Add />}
            >
              <span className="d-xs-none d-md-inline">
                {this.props.typeOfMeasurement ==
                "MeasurementTypes::IndependentMeasurementType"
                  ? I18n.t(
                      "frontend.measurement_value_definition_list.add_definition",
                    )
                  : I18n.t(
                      "frontend.measurement_value_definition_list.add_interval_definition",
                    )}
              </span>
            </Button>
            <div className="clearfix"></div>
          </Grid>
        )}
        {map(
          this.props.measurementValueDefinitions,
          (valueDefinition, index) => (
            <Grid item xs={12} key={`value-def-${index}`}>
              <IBox>
                <IBoxContent>
                  <Grid container spacing={2}>
                    <Grid item xs={10} lg={8}>
                      <TextField
                        size="small"
                        fullWidth={true}
                        name="mvd.title"
                        label={I18n.t(
                          "activerecord.attributes.measurement_value_definition.title",
                        )}
                        error={!isNil(this.props.errors?.[index]?.title)}
                        helperText={this.props.errors?.[index]?.title}
                        required={true}
                        inputProps={{
                          readOnly: this.props.allowEdit === false,
                        }}
                        value={toString(valueDefinition?.title)}
                        onChange={(changeEvent) => {
                          if (
                            !isNil(
                              this.props.onUpdateMeasurementValueDefinition,
                            )
                          )
                            this.props.onUpdateMeasurementValueDefinition(
                              {
                                ...valueDefinition,
                                title: changeEvent.target.value,
                              },
                              index,
                            );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} lg={4}>
                      <div
                        className="float-right"
                        hidden={!this.props.allowEdit}
                      >
                        {this.props.measurementValueDefinitions?.length > 1 ? (
                          <>
                            <IconButton
                              size="small"
                              title={I18n.t("frontend.change_order_down")}
                              hidden={
                                index ===
                                  this.props.measurementValueDefinitions
                                    .length -
                                    1 ||
                                isNil(
                                  this.props.onMoveMeasurementValueDefinition,
                                )
                              }
                              onClick={() => {
                                if (this.props.onMoveMeasurementValueDefinition)
                                  this.props.onMoveMeasurementValueDefinition(
                                    index,
                                    1,
                                  );
                              }}
                            >
                              <ArrowDown fontSize="inherit" />
                            </IconButton>

                            <IconButton
                              size="small"
                              title={I18n.t("frontend.change_order_up")}
                              hidden={
                                index === 0 ||
                                isNil(
                                  this.props.onMoveMeasurementValueDefinition,
                                )
                              }
                              onClick={() => {
                                this.props.onMoveMeasurementValueDefinition(
                                  index,
                                  -1,
                                );
                              }}
                            >
                              <ArrowUp fontSize="inherit" />
                            </IconButton>
                          </>
                        ) : null}
                        {isNil(this.props.onRemoveMeasurementValueDefinition) ||
                        !this.props.allowDelete ? null : (
                          <IconButton
                            size="small"
                            title={I18n.t("frontend.delete")}
                            onClick={() => {
                              this.props.onRemoveMeasurementValueDefinition(
                                valueDefinition,
                                index,
                              );
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {this.props.typeOfMeasurement ===
                      "MeasurementTypes::DistributionMeasurementType"
                        ? this.fieldsForDistributionType(
                            valueDefinition,
                            index,
                            this.props.errors?.[index],
                          )
                        : this.fieldsForIndividualMeasurements(
                            valueDefinition,
                            index,
                            this.props.errors?.[index],
                          )}
                    </Grid>
                  </Grid>
                </IBoxContent>
              </IBox>
            </Grid>
          ),
        )}
      </Grid>
    );
  }

  fieldsForDistributionType(
    valueDefinition: MeasurementValueDefinition,
    index: number,
    error: TypedErrorMap<MeasurementValueDefinition>,
  ) {
    return [
      <Grid container key={`${index}-cat`} spacing={1}>
        {isEmpty(this.props?.categorization?.measurement_categories) ? null : (
          <Grid item xs={12}>
            <TextField
              select
              fullWidth={true}
              error={!isEmpty(error?.measurement_category)}
              label={I18n.t(
                "activerecord.attributes.measurement_value_definition.measurement_category",
              )}
              size="small"
              disabled={
                isNil(this.props?.categorization) ||
                isEmpty(this.props?.categorization?.measurement_categories)
              }
              value={toString(valueDefinition.measurement_category_id)}
              contentEditable={this.props.allowEdit === false}
              onChange={(event) => {
                this.handleCategorySelection(
                  valueDefinition,
                  index,
                  event.target.value,
                );
              }}
            >
              {this.props?.categorization?.measurement_categories?.map(
                (cat, index) => (
                  <MenuItem key={index} value={cat.id}>
                    {cat.title}
                  </MenuItem>
                ),
              )}
            </TextField>
          </Grid>
        )}
        <Grid item xs={4}>
          <TextField
            label={I18n.t(
              "activerecord.attributes.measurement_value_definition.min",
            )}
            type="number"
            value={toString(valueDefinition.min)}
            error={!isEmpty(error?.min)}
            helperText={error?.min}
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: this.props.allowEdit === false,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="arrow-circle-o-down" />
                </InputAdornment>
              ),
              endAdornment: isEmpty(this.props.interval_unit) ? null : (
                <InputAdornment position="end">
                  {this.props.interval_unit}
                </InputAdornment>
              ),
            }}
            onChange={(changeEvent) =>
              this.props?.onUpdateMeasurementValueDefinition(
                {
                  ...valueDefinition,
                  min: parseFloat(changeEvent.target.value),
                },
                index,
              )
            }
          />
        </Grid>
        <Grid
          item
          xs={4}
          className="align-self-center text-center"
          style={{ fontSize: "large" }}
        >
          &#8804;&nbsp;
          {I18n.t(
            "activerecord.attributes.measurement_value_definition.value",
          )}{" "}
          &nbsp;&#60;
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={I18n.t(
              "activerecord.attributes.measurement_value_definition.max",
            )}
            type="number"
            value={toString(valueDefinition.max)}
            size="small"
            error={!isEmpty(error?.max)}
            helperText={error?.max}
            fullWidth={true}
            InputProps={{
              readOnly: this.props.allowEdit === false,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="arrow-circle-o-up" />
                </InputAdornment>
              ),
              endAdornment: isEmpty(this.props.interval_unit) ? null : (
                <InputAdornment position="end">
                  {this.props.interval_unit}
                </InputAdornment>
              ),
            }}
            onChange={(changeEvent) => {
              if (!isNil(this.props.onUpdateMeasurementValueDefinition))
                this.props?.onUpdateMeasurementValueDefinition(
                  {
                    ...valueDefinition,
                    max: parseFloat(changeEvent.target.value),
                  },
                  index,
                );
            }}
          />
        </Grid>
      </Grid>,
    ];
  }

  fieldsForIndividualMeasurements(
    valueDefinition: MeasurementValueDefinition,
    index: number,
    error: TypedErrorMap<MeasurementValueDefinition>,
  ) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MeasurementUnitSelect
            error={error?.unit}
            allowEdit={this.props.allowEdit}
            unit={valueDefinition?.unit}
            id={`select-unit-label-${index}`}
            onChange={(newUnit) => {
              if (!isNil(this.props.onUpdateMeasurementValueDefinition)) {
                this.props.onUpdateMeasurementValueDefinition(
                  { ...valueDefinition, unit: newUnit },
                  index,
                );
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  handleCategorySelection(
    valueDefinition: MeasurementValueDefinition,
    index: number,
    categoryId: number | string,
  ) {
    if (isNil(this.props.categorization)) return;

    const category = find(
      this.props.categorization.measurement_categories,
      (cat) => cat.id == categoryId,
    );
    if (
      !isNil(category) &&
      !isNil(this.props.onUpdateMeasurementValueDefinition)
    ) {
      this.props.onUpdateMeasurementValueDefinition(
        {
          ...valueDefinition,
          measurement_category_id: toInteger(category.id),
          measurement_category: category,
        },
        index,
      );
    }
  }
}

/// <reference types="../../definitions/index" />;
import * as React from "react";

import { Grid, TextField, Typography } from "@mui/material";
import { isEmpty, isNil, toNumber } from "lodash";
import moment from "moment";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "./app_root";

interface TimeDurationInputProps {
  name?: string;
  title?: string;
  helperText?: string;
  value?: moment.Duration | string;
  readonly?: boolean;
  onValueUpdate?: (newValue: moment.Duration) => void;
}

interface TimeDurationState {
  value: moment.Duration;
  hours: number;
  minutes: number;
  seconds: number;
}

const timeDurationInputRoots: Root[] = [];
export function initializeTimeDurationInput(selector: JQuery): void {
  if (isEmpty(selector)) return;
  selector.each((index: number, element: HTMLInputElement) => {
    const container = $('<div data-toggle="time-duration"></div>');
    container.attr("data-original", element.outerHTML);
    const root = createRoot(element);
    timeDurationInputRoots.push(root);
    root.render(
      <AppRoot>
        <TimeDurationInput
          value={element.value}
          name={element.name}
        ></TimeDurationInput>
      </AppRoot>,
    );
    $(element).replaceWith(container);
  });
}

export function destroyTimeDurationInput(): void {
  timeDurationInputRoots.forEach((root) => root.unmount());
  timeDurationInputRoots.length = 0;
}

export class TimeDurationInput extends React.Component<
  TimeDurationInputProps,
  TimeDurationState
> {
  static getDurationComponents(duration: moment.Duration) {
    return {
      hours: Math.floor(duration.asHours()),
      minutes: duration.get("minutes"),
      seconds: duration.get("seconds"),
    };
  }

  static getNewState(oldState: TimeDurationState): TimeDurationState {
    let hours = oldState.hours;
    let minutes = oldState.minutes;
    let seconds = oldState.seconds;
    let value = moment
      .duration(hours || 0, "hours")
      .add(moment.duration(minutes || 0, "minutes"))
      .add(moment.duration(seconds || 0, "seconds"));

    // ensure that value is never negative
    if (value.asSeconds() < 0) {
      value = moment.duration(0, "s");
    }

    // keep duration component fields empty if nothing was entered
    if (!isNil(hours) || value.asHours() >= 1) {
      hours = Math.floor(value.asHours());
    }

    if (!isNil(minutes) || value.get("minutes") >= 1) {
      minutes = value.get("minutes");
    }

    if (!isNil(seconds) || value.get("seconds") >= 1) {
      seconds = value.get("seconds");
    }

    return {
      value,
      hours,
      minutes,
      seconds,
    };
  }

  static formatDuration(duration: moment.Duration): string {
    return duration.toISOString();
  }

  static formatComponent(value: number): string {
    if (isNil(value) || isNaN(value)) {
      return "";
    }

    return value.toString();
  }

  constructor(props: TimeDurationInputProps) {
    super(props);

    const value = isEmpty(props.value)
      ? moment.duration(0, "s")
      : moment.duration(props.value);

    this.state = {
      value,
      ...TimeDurationInput.getDurationComponents(value),
    };
  }

  handleInputChange(unit: string, newValue: string | number): void {
    let value = toNumber(newValue as string);
    if (isNaN(value)) {
      value = null;
    }

    this.setState((previousState) => {
      let { hours, minutes, seconds } = previousState;

      switch (unit) {
        case "hours":
          hours = value;
          break;
        case "minutes":
          minutes = value;
          break;
        case "seconds":
          seconds = value;
          break;
      }

      const newState = TimeDurationInput.getNewState({
        value: previousState.value,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      if (this.props.onValueUpdate) {
        this.props.onValueUpdate(newState.value);
      }
      return newState;
    });
  }

  render(): React.ReactNode {
    const { hours, minutes, seconds } = TimeDurationInput.getDurationComponents(
      this.state.value,
    );

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">{this.props.title}</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            InputProps={{
              readOnly: this.props.readonly,
              type: "number",
            }}
            label={I18n.t("base.hour", { count: 2 })}
            value={TimeDurationInput.formatComponent(this.state.hours)}
            placeholder="hh"
            onChange={(event) =>
              this.handleInputChange("hours", event.currentTarget.value)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            InputProps={{
              readOnly: this.props.readonly,
              type: "number",
            }}
            value={TimeDurationInput.formatComponent(this.state.minutes)}
            placeholder="min"
            label={I18n.t("base.minute", { count: 2 })}
            onChange={(event) =>
              this.handleInputChange("minutes", event.currentTarget.value)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            InputProps={{
              readOnly: this.props.readonly,
              type: "number",
            }}
            label={I18n.t("base.second", { count: 2 })}
            value={TimeDurationInput.formatComponent(this.state.seconds)}
            placeholder="ss"
            onChange={(event) =>
              this.handleInputChange("seconds", event.currentTarget.value)
            }
          />
        </Grid>
        {isNil(this.props.name) ? null : (
          <input
            type="hidden"
            name={this.props.name}
            value={TimeDurationInput.formatDuration(this.state.value)}
          />
        )}
      </Grid>
    );
  }
}

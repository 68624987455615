import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import { ReduceStore } from "flux/utils";
import { MaintenancePlan } from "./models";
import {
  MaintenanceDueAction,
  ResetStateAction,
  LoadInitialStateAction,
  AddDueMaintenanceAction,
} from "./maintenance_due_actions";
import MaintenanceDueDispatcher from "./maintenance_due_dispatcher";

export interface MaintenanceDueState {
  dueMaintenances: MaintenancePlan[];
}

type ActionHandler = (
  state: MaintenanceDueState,
  action: MaintenanceDueAction,
) => MaintenanceDueState;

export class MaintenanceDueStore extends ReduceStore<
  MaintenanceDueState,
  MaintenanceDueAction
> {
  constructor() {
    super(MaintenanceDueDispatcher);
  }

  getInitialState(): MaintenanceDueState {
    return {
      dueMaintenances: [],
    };
  }

  reduce(
    state: MaintenanceDueState,
    action: MaintenanceDueAction,
  ): MaintenanceDueState {
    const actionHandler = this[action.type] as ActionHandler;

    if (isNil(actionHandler)) {
      // handle unknown actions
      return state;
    }

    return actionHandler.call(this, state, action);
  }

  RESET_STATE(
    state: MaintenanceDueState,
    action: ResetStateAction,
  ): MaintenanceDueState {
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: MaintenanceDueState,
    action: LoadInitialStateAction,
  ): MaintenanceDueState {
    return {
      dueMaintenances: action.dueMaintenances,
    };
  }

  ADD_DUE_MAINTENANCE(
    state: MaintenanceDueState,
    action: AddDueMaintenanceAction,
  ): MaintenanceDueState {
    // check if maintenance is already in list
    const index = findIndex(
      state.dueMaintenances,
      (maintenancePlan) => maintenancePlan.id === action.maintenancePlan.id,
    );
    if (index !== -1) {
      // update existing entry
      const dueMaintenances = state.dueMaintenances.slice();
      dueMaintenances[index] = action.maintenancePlan;

      return {
        ...state,
        dueMaintenances,
      };
    }

    // insert entry
    return {
      ...state,
      dueMaintenances: [action.maintenancePlan, ...state.dueMaintenances],
    };
  }

  REMOVE_DUE_MAINTENANCE(
    state: MaintenanceDueState,
    action: AddDueMaintenanceAction,
  ): MaintenanceDueState {
    const index = findIndex(
      state.dueMaintenances,
      (maintenancePlan) => maintenancePlan.id === action.maintenancePlan.id,
    );
    if (index !== -1) {
      // remove maintenance plan
      const dueMaintenances = state.dueMaintenances.slice();
      dueMaintenances.splice(index, 1);

      return {
        ...state,
        dueMaintenances,
      };
    }

    // maintenance plan not found
    return state;
  }
}

export default new MaintenanceDueStore();

import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  OffsetGaugeWidget as OffsetGaugeWidgetComponent,
  OffsetGaugeWidgetDefinition,
} from "../components/widgets/offset_gauge_widget";
import { OffsetGaugeWidgetConfigSerialized } from "./offset_gauge_widget.types";
import ValueDifferenceWidget from "./value_difference_widget";

/**
 * Javascript implementation of the OffsetGaugeWidget.
 *
 * @class OffsetGaugeWidget
 * @extends {Widget}
 */
export default class OffsetGaugeWidget extends ValueDifferenceWidget {
  config: OffsetGaugeWidgetConfigSerialized;

  static getDomClassName(): string {
    return "offset-gauge-widget";
  }

  static widgetClassName(): string {
    return "OffsetGaugeWidget";
  }

  protected readMembersFromElement(element: JQuery) {
    super.readMembersFromElement(element);
    this.config = element.data("config") as OffsetGaugeWidgetConfigSerialized;
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element, false);

    const props = OffsetGaugeWidgetDefinition.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <OffsetGaugeWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  cleanup(): void {
    super.cleanup();
  }
}

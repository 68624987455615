import { lowerCase } from "lodash";
import { IDType } from "../utils/urls/url_utils";
import { EventSeverityLevel } from "./event_notification";
import { EventPatternExecutionStates } from "./event_pattern_execution_state";

export type EventPatternComparator =
  | ">"
  | ">="
  | "<="
  | "<"
  | "=="
  | "!="
  | "inside range"
  | "outside range";

export const EVENT_PATTERN_COMPARATOR_REQUIRED_FIELDS: Record<
  EventPatternComparator,
  (keyof EventPatternAttributes)[]
> = {
  ">": ["value"],
  ">=": ["value"],
  "<=": ["value"],
  "<": ["value"],
  "==": ["value"],
  "!=": ["value"],
  "inside range": ["min", "max"],
  "outside range": ["min", "max"],
};
export type EventPatternType =
  | "EventPatterns::SensorEventPattern"
  | "EventPatterns::SensorDataTransmissionEventPattern";

export interface EventPatternAttributes {
  id?: IDType;
  name?: string;
  state_id?: IDType;
  asset_id?: IDType;
  sensor_id?: IDType;
  context_state_machine_id?: IDType;
  comparator: EventPatternComparator;
  min?: number;
  max?: number;
  value?: number;

  cool_down_time?: string;
  time_threshold?: string;
  severity_level?: EventSeverityLevel;
  eval_duration?: string;
  repeated_notification_after?: string;
  enabled_repeated_notification?: boolean;
  asset_event_type_id?: IDType;

  pattern_type?: EventPatternType;
  current_execution_state?: EventPatternExecutionStates;

  current_execution_no_event_at?: string;
  current_execution_state_pending_at?: string;
  current_execution_state_event_occurred_at?: string;
}

export type EventPattern = EventPatternAttributes;

export function eventPatternIntervalDescription(
  eventPattern: EventPatternAttributes,
  unit: string = "",
) {
  if (!eventPattern.comparator) {
    return null;
  }
  switch (lowerCase(eventPattern.comparator)) {
    case "inside range":
      return I18n.t("event_pattern.short_condition_description.inside_range", {
        min: eventPattern.min,
        max: eventPattern.max,
        unit,
      });
    case "outside range":
      return I18n.t("event_pattern.short_condition_description.outside_range", {
        min: eventPattern.min,
        max: eventPattern.max,
        unit: unit,
      });
    default:
      return `${eventPattern.comparator} ${eventPattern.value} ${unit}`;
  }
}

/** @type {*} */
export const EventPatternStateColors: Partial<
  Record<EventPatternExecutionStates, string>
> = {
  event_occurred: "#f8ac59",
};

import { Grid } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { fromPairs, map } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "./app_root";

interface ColData {
  name: string;
  type: "string" | "number" | "date" | "dateTime";
  hidden?: boolean;
  id?: boolean;
}

interface Data {
  headers: ColData[];
  table_data: Array<Array<string | number | Date>>;
}

interface SimpleDataTableProps {
  data: Data;
  height?: number | string;
}

export const SimpleDataTable: React.FunctionComponent<SimpleDataTableProps> = (
  props,
) => {
  const getRowData = React.useCallback(() => {
    return map(props.data.table_data, (d) =>
      fromPairs(
        map(d, (data, index) => {
          const header = props.data.headers[index];
          return [header.id ? "id" : header.name, data];
        }),
      ),
    );
  }, [props.data]);
  const getInitialColumVisibility = React.useCallback(() => {
    const columnVis: GridColumnVisibilityModel = fromPairs(
      map(props.data.headers, (h) => [
        h.name,
        h.hidden === true ? false : true,
      ]),
    );
    return columnVis;
  }, [props.data]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ height: props.height || "70vh" }}>
        <DataGrid
          rows={getRowData()}
          initialState={{
            columns: { columnVisibilityModel: getInitialColumVisibility() },
          }}
          columns={props.data.headers.map(
            (header, index) =>
              ({
                field: header.id ? "id" : header.name,
                headerName: header.name,
                flex: header.id ? null : 0.2,
                width: header.id ? 100 : null,
                type: header.id ? "integer" : header.type,
                valueGetter:
                  header.type == "date" || header.type == "dateTime"
                    ? (value, row) => new Date(row[header.name])
                    : null,
              }) as GridColDef,
          )}
        />
      </Grid>
    </Grid>
  );
};

const simpleDataTableRoots: Root[] = [];
export function initializeSimpleDataTable(): void {
  $('[data-toggle="simple-data-table"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const data = $(element).data("data") as Data;
    const root = createRoot(element);
    simpleDataTableRoots.push(root);
    root.render(
      <AppRoot>
        <SimpleDataTable data={data} />
      </AppRoot>,
    );
  });
}

export function destroySimpleDataTable(): void {
  simpleDataTableRoots.forEach((root) => root.unmount());
  simpleDataTableRoots.length = 0;
}

import { TableChart } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
} from "@mui/material";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { defaultTo, isNil } from "lodash";
import { DateRange } from "moment-range";
import * as React from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { SubscriptionPeriodJSONAPIAttributes } from "../../json_api/subscription_period";
import {
  api_organization_subscription_subscription_periods_path,
  stats_subscription_subscription_period_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingIcon } from "../common/icon";
import { ExtensiblePageSettings } from "../common/page_size";
import { SubscriptionPeriodsActionsDropdown } from "./subscription_periods_actions_dropdown";
import { AppContext } from "../common/app_context/app_context_provider";
interface SubscriptionPeriodsListProps {
  subscriptionId: IDType;
  organizationId: IDType;
  pageSize?: number;
}

export const SubscriptionPeriodList: React.FunctionComponent<
  SubscriptionPeriodsListProps
> = (props) => {
  const context = React.useContext(AppContext);
  const [subscriptionPeriods, setSubscriptionPeriods] =
    React.useState<SubscriptionPeriodJSONAPIAttributes[]>();
  const [selectedPeriods, setSelectedPeriods] =
    React.useState<GridRowSelectionModel>([]);
  const [totalPeriodCount, setTotalPeriodCount] = React.useState<number>(null);
  const [timePeriod, setTimePeriod] = React.useState<DateRange>(null);

  const [pageSettings, setPageSettings] =
    React.useState<ExtensiblePageSettings>({
      number: 1,
      size: defaultTo(props.pageSize, 20),
    });

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const orgId = props.organizationId || context.currentOrganizationId;
    const url = api_organization_subscription_subscription_periods_path(
      orgId,
      props.subscriptionId,
      {
        organization_id: orgId,
        subscription_id: props.subscriptionId,
        locale: I18n.locale,
        format: "json",
        page: pageSettings,
        _options: true,
      },
    );
    void loadDataFromUrl<
      CollectionResourceDoc<string, SubscriptionPeriodJSONAPIAttributes>
    >(url)
      .then((periodsFromJsonApi) => {
        setTotalPeriodCount(periodsFromJsonApi.meta.record_count as number);
        setSubscriptionPeriods(
          jsonApiResourceCollectionToFlatObjects(periodsFromJsonApi),
        );
      })
      .catch((e) => {
        void error(I18n.t("base.error", e.message));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSettings, props.subscriptionId, timePeriod]);

  return (
    <Card>
      <CardHeader
        title={I18n.t("activerecord.models.subscription_period.other")}
      />
      <CardContent>
        {loading ? (
          <Grid container alignContent="center">
            <Grid item xs="auto">
              <LoadingIcon size="4x" />
            </Grid>
          </Grid>
        ) : isNil(subscriptionPeriods) ? null : (
          <Grid container alignContent="center" spacing={1}>
            <Grid item xs={12}>
              <DataGrid
                autoHeight
                rows={subscriptionPeriods}
                rowSelectionModel={selectedPeriods}
                paginationMode="server"
                paginationModel={{
                  page: (pageSettings.number || 0) - 1,
                  pageSize: pageSettings.size,
                }}
                loading={loading}
                pageSizeOptions={[10, 20, 30, 50]}
                rowCount={totalPeriodCount}
                onPaginationModelChange={(paginationModel) => {
                  setPageSettings({
                    size: paginationModel.pageSize,
                    number: paginationModel.page + 1,
                  });
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                getRowId={(row) => row.id}
                checkboxSelection
                onRowSelectionModelChange={(model) => {
                  setSelectedPeriods(model);
                }}
                isRowSelectable={(params) => params.row.state === "closed"}
                columns={[
                  {
                    field: "id",

                    headerName: "#",

                    type: "number",
                    flex: 0.1,
                  },
                  {
                    field: "starts_at",
                    type: "date",

                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.starts_at",
                    ),
                    flex: 0.2,
                    valueGetter: (value, row) => new Date(row.starts_at),
                  },
                  {
                    field: "ends_at",
                    type: "date",

                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.ends_at",
                    ),
                    flex: 0.2,
                    valueGetter: (value, row) => new Date(row.ends_at),
                  },
                  {
                    field: "state",
                    type: "string",
                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.state",
                    ),
                    flex: 0.2,
                    renderCell: (params) => (
                      <Chip
                        size="small"
                        label={I18n.t(
                          `activerecord.attributes.subscription_period.states.${params.row.state}`,
                        )}
                        variant="outlined"
                        color="primary"
                      />
                    ),
                  },
                  {
                    field: "actions",
                    filterable: false,
                    width: 200,

                    headerName: "",

                    renderCell: (params) =>
                      params.row.state != "closed" ? null : (
                        <ButtonGroup size="small">
                          <Button
                            startIcon={<TableChart />}
                            size="small"
                            onClick={(event) => {
                              event.preventDefault();
                              window.open(
                                stats_subscription_subscription_period_path(
                                  props.subscriptionId,
                                  params.row.id,
                                  { locale: I18n.locale, _options: true },
                                ),
                                "_blank",
                              );
                            }}
                          >
                            {I18n.t(
                              "frontend.subscription_periods.list.actions.show_stats",
                            )}
                          </Button>
                        </ButtonGroup>
                      ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <SubscriptionPeriodsActionsDropdown
          selectedPeriodIds={selectedPeriods as IDType[]}
          subscriptionId={props.subscriptionId}
          organizationId={props.organizationId || context.currentOrganizationId}
        />
      </CardActions>
    </Card>
  );
};

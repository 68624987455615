import * as React from "react";
import { Icon, IconProps } from "./icon";
import { Link } from "@mui/material";

export interface IconButtonProps extends IconProps, React.PropsWithChildren {
  title?: string;
  href?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  turbolinks?: boolean;
}

/**
 * A clickable icon
 * @param props
 */
export const IconButton: React.FunctionComponent<IconButtonProps> = function (
  props,
) {
  return (
    <Link
      onClick={props.onClick}
      href={props.href}
      title={props.title}
      className={props.className}
      style={{ cursor: "pointer" }}
      data-turbo={props.turbolinks ? "true" : "false"}
    >
      <Icon icon={props.icon} />
      {props.children}
    </Link>
  );
};

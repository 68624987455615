/********
 * SENSOR_VALUE_RANGES
 *******/

import { defaultTo, isNil } from "lodash";

import { SensorValueRangeJSONAPIAttributes } from "../../json_api/sensor_value_range";

import {
  applyParamsToBaseUrl,
  formatString,
  IDType,
  localizedBasePath,
  RequestFormat,
} from "./url_utils";

export interface SensorValueRangeAttributes {
  id?: number | string;
  sensorId?: number | string;
}

export function sensorValueRangeUrl(
  sensorValueRange: SensorValueRangeAttributes,
  format: RequestFormat = "json",
): string {
  if (isNil(sensorValueRange)) return null;

  const sensorId = defaultTo(
    sensorValueRange?.sensorId,
    (sensorValueRange as SensorValueRangeJSONAPIAttributes)?.sensor_id,
  );
  if (!sensorId) {
    return `${localizedBasePath(format === "json")}/sensor_value_ranges/${
      sensorValueRange.id
    }${formatString(format)}`;
  } else {
    return `${localizedBasePath(
      format === "json",
    )}/sensors/${sensorId}/sensor_value_ranges/${
      sensorValueRange.id
    }${formatString(format)}`;
  }
}

export function jsonApiSensorValueRangesPath(sensorId?: IDType): string {
  if (sensorId) {
    return `${localizedBasePath(true)}/sensors/${sensorId}/sensor_value_ranges`;
  } else {
    return `${localizedBasePath(true)}/sensor_value_ranges`;
  }
}

export function jsonApiSensorValueRangePath(
  opts: {
    assetId?: IDType;
    sensorId?: IDType;
    sensorValueRangeId: IDType;
  },
  includes?: string[],
): string {
  let url = null;
  if (!opts.sensorId && !opts.assetId) {
    url = `${localizedBasePath(true)}/sensor_value_ranges/${
      opts.sensorValueRangeId
    }`;
  } else if (!opts.assetId) {
    url = `${localizedBasePath(true)}/sensors/${
      opts.sensorId
    }/sensor_value_ranges/${opts.sensorValueRangeId}`;
  } else {
    url = `${localizedBasePath(true)}/assets/${opts.assetId}/sensors/${
      opts.sensorId
    }/sensor_value_ranges/${opts.sensorValueRangeId}`;
  }
  return applyParamsToBaseUrl(url, null, null, includes);
}

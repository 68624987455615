import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import { CommentWidget as CommentWidgetComponent } from "../components/widgets/comment_widget";
import { CommentWidgetConfigSerialized } from "./comment_widget.types";
import Widget from "./widget";

export default class CommentWidget extends Widget {
  static getDomClassName(): string {
    return "comment-widget";
  }

  itemId: number;
  itemType: string;
  limit: number;

  tags: string[];
  sort: "asc" | "desc";
  showFilter: boolean;

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as CommentWidgetConfigSerialized;

    if (config) {
      this.itemId = config.item_id;
      this.limit = config.limit;
      this.tags = config.tags;
      this.itemType = config.item_type;
      this.showFilter = defaultTo(config.show_filter, true);
      this.sort = config.sort;
    }

    if (isNil(this.tags)) {
      this.tags = element.data("tags") as string[];
    }
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);

    this.root.render(
      <AppRoot>
        <CommentWidgetComponent
          itemId={this.itemId}
          itemType={this.itemType}
          limit={this.limit}
          tags={this.tags}
          initiallyFormOpen={false}
          sort={this.sort}
          dashboardSettings={this.dashboardSettings}
          widgetId={this.widgetId}
        />
      </AppRoot>,
    );
  }
  getSensorIdsForUpdate(): number[] {
    return [];
  }

  cleanup(): void {
    super.cleanup();
  }
}

import { Grid } from "@mui/material";
import * as React from "react";
import { FunctionComponent } from "react";
import { DashboardWidgetWithConfig } from "../../models/dashboard_widget";
import { DashboardWidget } from "./dashboard_widget";

interface DashboardWidgetRowProps {
  widgets: DashboardWidgetWithConfig[];
}

export const DashboardWidgetRow: FunctionComponent<DashboardWidgetRowProps> = (
  props,
) => {
  return (
    <Grid item xs={12} container spacing={2} alignItems="stretch">
      {props.widgets?.map((w, index) => (
        <DashboardWidget config={w} key={index} />
      ))}
    </Grid>
  );
};

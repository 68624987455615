import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import JQuery from "jquery";
import { isEmpty } from "lodash";
import { AppRoot } from "../common/app_root";
import { ProductModelAssetTemplateSettingList } from "./product_model_asset_template_setting_list";

const productModelAssetTemplateSetttingRoots: Root[] = [];

export function initProductModelAssetTemplateSettingsList(
  itemOrSelector:
    | JQuery
    | string = '[data-toggle="product-model-asset-template-setting-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, formMountItem) => {
    const productModelId = $(formMountItem).data("product-model-id") as number;
    const canEdit = $(formMountItem).data("can-edit") as boolean;
    const root = createRoot(formMountItem);
    productModelAssetTemplateSetttingRoots.push(root);
    root.render(
      <AppRoot>
        <ProductModelAssetTemplateSettingList
          productModelId={productModelId}
          canEdit={canEdit}
        />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Report List in given container
 * @param selector jquery selector to report list container
 */
export function destroyProductModelAssetTemplateSettingsList(
  selector: JQuery,
): void {
  productModelAssetTemplateSetttingRoots.forEach((root) => {
    root.unmount();
  });
  productModelAssetTemplateSetttingRoots.length = 0;
}

import { Action } from "../../actions/actions.types";
import { SensorMappingAttributes } from "../../models/svg_animation_widget_config";
import { logger } from "../../utils/logger";
import { AnimationMapping, AnimationMappingConfig } from "./animation_mapping";
import { ClickableMapping, ClickableMappingConfig } from "./clickable_mapping";
import { ColorMapping } from "./color_mapping";
import { HideMapping } from "./hide_mapping";
import { LabelMapping } from "./label_mapping";
import { BaseMapping } from "./mapping_base";
import { RotateMapping } from "./rotate_mapping";
import { ScaleMapping } from "./scale_mapping";

export function createMappingFromConfig(
  mappingConfig: SensorMappingAttributes,
  onClick?: (
    event: MouseEvent,
    element: SVGElement,
    action: Action,
    config: SensorMappingAttributes,
  ) => void,
): BaseMapping {
  try {
    switch (mappingConfig.mapping_type) {
      case "scale_mapping":
        return new ScaleMapping(mappingConfig, onClick);
      case "label_mapping":
        return new LabelMapping(mappingConfig, onClick);
      case "clickable_mapping":
        return new ClickableMapping(
          mappingConfig as ClickableMappingConfig,
          onClick,
        );
      case "color_mapping":
        return new ColorMapping(mappingConfig, onClick);
      case "rotate_mapping":
        return new RotateMapping(mappingConfig, onClick);
      case "animation_mapping":
        return new AnimationMapping(
          mappingConfig as AnimationMappingConfig,
          onClick,
        );
      case "hide_mapping":
        return new HideMapping(mappingConfig, onClick);

      default:
        return null;
    }
  } catch (e) {
    logger.log(e);
    return null;
  }
}

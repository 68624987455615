import { MenuItem, TextField } from "@mui/material";
import { isEmpty, isNil, isNumber, isString, toString } from "lodash";
import * as React from "react";

import { IDType } from "../../utils/urls/url_utils";
import { ProductModelJSONObject } from "../../json_api/product_model";

interface ProductModelsSelectProps {
  productModels: ProductModelJSONObject[];
  selectedProductModel?: ProductModelJSONObject | IDType;
  onChange: (productModel: ProductModelJSONObject | null) => void;
  fullwidth?: boolean;
}

/** Select component for selecting a product model.
 *
 * @param props Component properties
 * @returns The rendered component
 */
export const ProductModelSelect = (props: ProductModelsSelectProps) => {
  const { productModels, selectedProductModel, onChange } = props;

  const selectedId = React.useMemo(() => {
    if (isNil(selectedProductModel)) {
      return "";
    }
    if (isString(selectedProductModel) || isNumber(selectedProductModel)) {
      return toString(selectedProductModel);
    } else {
      return toString(selectedProductModel.id);
    }
  }, [selectedProductModel]);

  return (
    <TextField
      select
      fullWidth
      value={selectedId}
      label={I18n.t("activerecord.models.product_model.one")}
      onChange={(event) => {
        if (isEmpty(event.target.value)) {
          // No selection
          onChange(null);
          return;
        }
        const selectedProductModelId = parseInt(event.target.value, 10);
        const selectedProductModel = productModels.find(
          (productModel) => productModel.id === selectedProductModelId,
        );
        if (selectedProductModel) {
          onChange(selectedProductModel);
        }
      }}
    >
      <MenuItem key={"none"} value={""}>
        {I18n.t("base.none")}
      </MenuItem>
      {productModels?.map((productModel) => (
        <MenuItem key={productModel.id} value={toString(productModel.id)}>
          {productModel.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

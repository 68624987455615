import { includes, isNil } from "lodash";
import { DateRange } from "moment-range";
import { Plotly } from "../plotly_package";
import { ChartData } from "../plotly_time_series_line_diagram_base.types";

import {
  DIAGRAM_TREND_FILL_COLOR,
  DIAGRAM_TREND_INVERSE_FILL_COLOR,
  DIAGRAM_TREND_INVERSE_TEXT_COLOR,
  DIAGRAM_TREND_LINE_COLOR,
  DIAGRAM_TREND_TEXT_COLOR,
} from "../diagram_constants";

/**
 * Options for minimum maximum and trend annotations
 */
export interface ChartAnnotationOptions {
  /**
   * Show minimum and maximum line annotation
   */
  showMinMax: boolean;
  /**
   * Height of minimum line
   */
  min: number;
  /**
   * Height of maximum line
   */
  max: number;
  value: number;
  annotationMode?:
    | ">"
    | ">="
    | "<="
    | "<"
    | "=="
    | "!="
    | "inside range"
    | "outside range";

  /**
   * Show trend line annotation
   */
  showTrend?: boolean;
  /**
   * Slope of minimum trend line
   */
  minSlope?: number;
  /**
   * Slope of maximum trend line
   */
  maxSlope?: number;
}

export function buildSingleValueLines(
  annotationOptions: Partial<ChartAnnotationOptions>,
  timeRange: DateRange,
): ChartData[] {
  const lines: ChartData[] = [];
  const fillColor = DIAGRAM_TREND_FILL_COLOR;
  const lineSettings: Partial<Plotly.ScatterLine> = {
    dash: "dot",
    width: 2,
    color: DIAGRAM_TREND_TEXT_COLOR,
    shape: "linear",
  };
  lines.push({
    visible: annotationOptions.showMinMax,
    type: "scatter",
    mode: "text+lines",
    showlegend: false,
    hoverinfo: "none",
    line: lineSettings,
    x: [timeRange.start.toDate(), timeRange.end.toDate()],
    y: [annotationOptions.value, annotationOptions.value],

    name: "min",
    text: [
      `${annotationOptions.annotationMode} ${annotationOptions.value}`,
      includes(annotationOptions.annotationMode, ">") ? "↑" : "↓",
    ],
    textposition: `${
      includes(annotationOptions.annotationMode, ">") ? "top" : "bottom"
    } right`,
    textfont: {
      color: DIAGRAM_TREND_INVERSE_TEXT_COLOR,
    },
    fill: "none",
    fillcolor: fillColor,
  } as ChartData);
  return lines;
}

export function buildEqualityLines(
  annotationOptions: Partial<ChartAnnotationOptions>,
  timeRange: DateRange,
): ChartData[] {
  const lines: ChartData[] = [];

  return lines;
}

export function buildRangeLines(
  annotationOptions: Partial<ChartAnnotationOptions>,
  timeRange: DateRange,
): ChartData[] {
  const minMaxLines: ChartData[] = [];
  const fillColor =
    annotationOptions.annotationMode == "inside range"
      ? DIAGRAM_TREND_INVERSE_FILL_COLOR
      : DIAGRAM_TREND_FILL_COLOR;
  const lineSettings: Partial<Plotly.ScatterLine> = {
    dash: "dot",
    width: 1.5,
    color: DIAGRAM_TREND_LINE_COLOR,
    shape: "linear",
  };
  if (!isNil(annotationOptions.min)) {
    minMaxLines.push({
      visible: annotationOptions.showMinMax,
      type: "scatter",
      mode: "text+lines",
      showlegend: false,
      hoverinfo: "none",
      line: lineSettings,
      x: [timeRange.start.toDate(), timeRange.end.toDate()],
      y: [annotationOptions.min, annotationOptions.min],
      name: "min",
      text: [`min (${annotationOptions.min})`, ""],
      textposition: "bottom right",
      textfont: {
        color: DIAGRAM_TREND_TEXT_COLOR,
      },
      fill: "none",
      fillcolor: fillColor,
      yaxis: "y",
    } as ChartData);
  }

  if (!isNil(annotationOptions.max)) {
    minMaxLines.push({
      visible: annotationOptions.showMinMax,
      type: "scatter",
      mode: "text+lines",
      showlegend: false,
      hoverinfo: "none",
      line: lineSettings,
      x: [timeRange.start.toDate(), timeRange.end.toDate()],
      y: [annotationOptions.max, annotationOptions.max],
      name: "max",
      text: [`max (${annotationOptions.max})`, ""],
      textposition: "top right",

      textfont: {
        color: DIAGRAM_TREND_TEXT_COLOR,
      },
      fill: "tonexty",
      fillcolor: fillColor,
      yaxis: "y",
    } as ChartData);
  }
  return minMaxLines;
}

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import types only and wait for async load of three.js
import * as THREE from "three";

global.THREE = THREE;
window.THREE = THREE;

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

/**
 * A simple viewer for GLTF models
 */
export class ModelViewer {
  /**
   * Replace environment map and sets shadow options of all objects in a scene
   * @param scene A 3D scene
   * @param envMap Environment map
   */
  static setLightingOptions(
    scene: THREE.Scene,
    envMap: THREE.CubeTexture,
  ): void {
    scene.traverse((child) => {
      if (child instanceof THREE.Mesh && child.material) {
        child.castShadow = true;
        child.receiveShadow = true;
        const material = child.material as THREE.MeshBasicMaterial;

        material.envMap = envMap;
      }
    });
  }

  private canvas: JQuery<HTMLCanvasElement>;
  private renderer: THREE.Renderer;
  private camera: THREE.PerspectiveCamera;
  private controls: OrbitControls;
  private scene: THREE.Scene;
  private loader: GLTFLoader;
  private envMap: THREE.CubeTexture;
  private textureLoader: THREE.CubeTextureLoader;
  private directionalLight: THREE.DirectionalLight;
  private hemisphereLight: THREE.HemisphereLight;

  /**
   * Create a model viewer for a canvas
   * @param canvas
   */
  constructor(canvas: JQuery<HTMLCanvasElement>) {
    this.canvas = canvas;
    this.init();
  }

  init(): void {
    // create camera
    const width = this.canvas.width();
    const height = this.canvas.height();
    const aspect = width / height;
    this.camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 10000.0);
    this.camera.position.set(0.0, 20.0, 20.0);
    this.camera.lookAt(0, 0, 0);
    this.controls = new OrbitControls(this.camera, this.canvas[0]);
    this.controls.target.set(0, 0.2, 0.2);
    this.controls.update();

    // load environment
    this.textureLoader = new THREE.CubeTextureLoader();
    this.envMap = undefined;
    /*this.textureLoader.load([
      "/images/cube/px.jpg",
      "/images/cube/nx.jpg",
      "/images/cube/py.jpg",
      "/images/cube/ny.jpg",
      "/images/cube/pz.jpg",
      "/images/cube/nz.jpg",
    ]);*/

    // create scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xb5d3e7); // this.envMap;
    this.loader = new GLTFLoader();

    // add lights
    this.directionalLight = new THREE.DirectionalLight(0xffffe4, 0.4);
    this.directionalLight.position.set(0, 1, 2);
    this.directionalLight.castShadow = true;
    this.directionalLight.shadow.bias = -0.0001;
    this.scene.add(this.directionalLight);
    this.hemisphereLight = new THREE.HemisphereLight(0xb5d3e7, 0x444422, 0.45);
    this.hemisphereLight.position.set(0, 1, 0);
    this.scene.add(this.hemisphereLight);

    // create renderer
    const canvas = this.canvas[0];
    /* istanbul ignore if  */
    if (
      canvas.getContext("webgl2") ||
      canvas.getContext("experimental-webgl")
    ) {
      const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
      });
      //renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFShadowMap;
      renderer.setPixelRatio(window.devicePixelRatio);

      this.renderer = renderer;
    } else {
      /*const SoftwareRenderer = (global as any).SoftwareRenderer;

      // fallback for mocha tests
      this.renderer = new SoftwareRenderer({
        canvas: canvas,
      });*/
    }

    $(window).on("resize", () => this.resize());

    setTimeout(() => this.resize(), 500);
  }

  /**
   * Load a gltf scene from a url
   * @param url Url of gtlf scene
   */
  async load(url: string) {
    const response = await this.loadScene(url);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.scene.add(response.scene);
    ModelViewer.setLightingOptions(this.scene, this.envMap);

    // scale and center model in origin
    const bounds = new THREE.Box3();
    bounds.setFromObject(this.scene);
    const sphere = bounds.getBoundingSphere(new THREE.Sphere());
    const center = sphere.center;
    const scaleFactor = 10.0 / sphere.radius;
    this.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
    this.scene.matrixAutoUpdate = true;
    this.scene.position.set(
      -scaleFactor * center.x,
      -scaleFactor * center.y,
      -scaleFactor * center.z,
    );
  }

  /**
   * Render scene
   */
  render(): void {
    this.renderer.render(this.scene, this.camera);
  }

  /**
   * Resize renderer resolution to fit canvas size
   */
  resize(): void {
    const width = this.canvas.parent().width();
    const height = this.canvas.parent().height();

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  /**
   * Delete all renderer resources
   */
  destroy(): void {
    /* istanbul ignore if */
    if (this.renderer instanceof THREE.WebGLRenderer) {
      this.renderer.dispose();
    }
  }

  private async loadScene(url: string): Promise<GLTF> {
    return new Promise((resolve, reject) => {
      this.loader.load(url, resolve, null, (error: Error) => {
        console.log("Modelviewer error:" + error.message);
        reject(new Error(`Loading of '${url}' failed.`));
      });
    });
  }
}

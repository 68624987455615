/********
 * ORGANIZATIONS
 *******/

import { defaultTo, isEmpty, isNil } from "lodash";
import { jsonApiFieldsParamsArray } from "../../json_api/jsonapi_tools";
import { Organization } from "../../models/organization";
import { OrganizationRelationType } from "../../models/organization_relation";

import {
  applyParamsToBaseUrl,
  formatString,
  IDType,
  localizedBasePath,
  ParamsType,
  RequestFormat,
} from "./url_utils";
import { search_api_organizations_path } from "../../routes";

export function organizationsJsonApiPath(
  pageNumber = 1,
  pageSize = 15,
  include: OrganizationIncludes[] = [],
): string {
  const url = `${localizedBasePath(true)}/organizations`;
  return applyParamsToBaseUrl(url, pageNumber, pageSize, include);
}

/** Builds search paths for organizations
 *
 *
 * @export
 * @param {string} searchTerm Term to search for
 * @param {number} [pageNumber=1] Page number.
 * @param {number} [pageSize=5] Page size
 * @param {OrganizationIncludes[]} [include=[]] Relations names to include
 * @param {(keyof Organization)[]} [fields=null] Field restrictions-
 * @return {string}
 */
export function organizationsJsonApiSearchPath(
  searchTerm: string,
  pageNumber = 1,
  pageSize = 15,
  include: OrganizationIncludes[] = [],
  fields: (keyof Organization)[] = null,
): string {
  const url = search_api_organizations_path();

  const searchParam: ParamsType = [["filter[search]", searchTerm]];
  const fieldsParams = jsonApiFieldsParamsArray<Organization>(
    "organizations",
    fields,
  );
  const params = fieldsParams.concat(searchParam);
  return applyParamsToBaseUrl(url, pageNumber, pageSize, include, params);
}

export interface OrganizationSearchOptions {
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  include?: OrganizationIncludes[];
  fields?: (keyof Organization)[];
}
export function relatedOrganizationJsonApiSearchPath(
  relationType: OrganizationRelationType,
  options?: OrganizationSearchOptions,
): string {
  const url = `${localizedBasePath(
    true,
  )}/organizations/related/${relationType}/search`;
  let params: ParamsType = [];
  if (options?.searchTerm) {
    params.push(["search", options.searchTerm]);
  }
  if (options.fields) {
    params = params.concat(
      jsonApiFieldsParamsArray<Organization>("organizations", options.fields),
    );
  }
  return applyParamsToBaseUrl(
    url,
    defaultTo(options?.pageNumber, 1),
    defaultTo(options?.pageSize, 5),
    options?.include,
    params,
  );
}

export function organizationJsonApiPath(
  id: IDType,
  include: OrganizationIncludes[] = [],
): string {
  const url = `${localizedBasePath(true)}/organizations/${id}`;
  return applyParamsToBaseUrl(url, null, null, include);
}

export interface AssignAssetOrganizationBodyParams {
  move?: boolean;
  is_operator?: boolean;
  target_organization_user_group_id?: IDType;
}
/** Url for assigning
 *
 *
 * @export
 * @param {(number | string)} assetId Id of the asset to assign to the organization
 * @param {(number | string)} targetOrganizationId Organization id of the target organization.
 * @param {(number | string)} [sourceOrganizationId=null] Original organization of the asset. may be null
 * @param {OrganizationRelationType} [rel_type=null] type of related target organization, defaults to null
 * @param {boolean} [move=false] Indecates that the asset should be removed from its source organization. Leads to loose of access to the asset for the source organization users.
 * @return {*}  {string}
 */
export function assignAssetOrganizationJsonApiPath(
  assetId: IDType,
  targetOrganizationId: IDType,
  sourceOrganizationId: IDType = null,
  rel_type: OrganizationRelationType = null,
): string {
  let url: string;
  if (isNil(rel_type)) {
    if (isNil(sourceOrganizationId)) {
      url = `${localizedBasePath(
        true,
      )}/organizations/${targetOrganizationId}/assign_asset/${assetId}`;
    } else {
      url = `${localizedBasePath(
        true,
      )}/organizations/${sourceOrganizationId}/assign_asset/${assetId}/to/${targetOrganizationId}`;
    }
  } else {
    if (isNil(sourceOrganizationId)) {
      url = `${localizedBasePath(
        true,
      )}/organizations/${targetOrganizationId}/related/${rel_type}/assign_asset/${assetId}`;
    } else {
      url = `${localizedBasePath(
        true,
      )}/organizations/${sourceOrganizationId}/related/${rel_type}/assign_asset/${assetId}/from/${targetOrganizationId}`;
    }
  }

  return url;
}

export function removeAssetOrganizationJsonApiPath(
  assetId: IDType,
  organizationId: IDType,
): string {
  return `${localizedBasePath(
    true,
  )}/organizations/${organizationId}/remove_asset/${assetId}`;
}

export function organizationsPath(format: RequestFormat = null): string {
  return `${localizedBasePath()}/organizations${formatString(format)}`;
}

export function organizationPath(
  id: IDType,
  format: RequestFormat = "",
): string {
  return `${localizedBasePath()}/organizations/${id}${
    isEmpty(format) ? "" : "." + format
  }`;
}

export function relatedOrganizationsPath(
  parentOrganizationId: IDType,
  relationType: string,
  format: RequestFormat = "",
): string {
  return `${localizedBasePath()}/organizations/${parentOrganizationId}/related/${relationType}${
    isEmpty(format) ? "" : "." + format
  }`;
}

export function organizationIconPath(id: IDType): string {
  return `${localizedBasePath(true)}/organizations/${id}/icon`;
}

export type OrganizationIncludes = "assets";

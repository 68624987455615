import { isEmpty, isNil } from "lodash";
import moment, { Moment } from "moment";
import { DateRange } from "moment-range";
export function getBoolAttribute(
  element: JQuery,
  attributeName: string,
  defaultValue = false,
): boolean {
  const attrValue = element.attr(attributeName);

  // boolean attribute in template rendering
  if (attrValue === "") return true;
  if (attrValue === "1") return true;
  if (attrValue === "true") return true;
  if (attrValue === "false") return false;

  return defaultValue;
}

/**
 * Parse a float attribute from a html data attribute.
 * Return defaultValue if attribute is not set or not a number.
 *
 * @static
 * @param {JQuery} element HTML element
 * @param {string} attributeName Data attribute name
 * @param {number} [defaultValue] The default value to return.
 */
export function getFloatAttribute(
  element: JQuery,
  attributeName: string,
  defaultValue?: number,
): number {
  const attrValue = element.attr(attributeName);

  if (isNil(attrValue)) {
    return defaultValue;
  }

  const numericValue = parseFloat(attrValue);
  if (isNaN(numericValue)) {
    return defaultValue;
  }

  return numericValue;
}

/**
 * Parse an integer attribute from a html data attribute.
 * Return defaultValue if attribute is not set or not a number.
 *
 * @static
 * @param {JQuery} element HTML element
 * @param {string} attributeName Data attribute name
 * @param {number} [defaultValue] The default value to return.
 */
export function getIntegerAttribute(
  element: JQuery,
  attributeName: string,
  defaultValue?: number,
): number {
  const attrValue = element.attr(attributeName);

  if (isNil(attrValue)) {
    return defaultValue;
  }

  const numericValue = parseInt(attrValue);
  if (isNaN(numericValue)) {
    return defaultValue;
  }

  return numericValue;
}

export function getDateAttribute(
  element: JQuery,
  attributeName: string,
  defaultValue?: Moment,
): Moment {
  const attrValue = element.attr(attributeName);

  if (isNil(attrValue)) {
    return defaultValue;
  }

  const date = moment(attrValue).local();
  if (!date.isValid()) {
    return defaultValue;
  }

  return date;
}

export function getDateRangeAttribute(
  element: JQuery,
  startAttributeName: string,
  endAttributeName: string,
  defaultValue: DateRange = new DateRange(),
): DateRange {
  const startDate = getDateAttribute(
    element,
    startAttributeName,
    defaultValue.start,
  );
  const endDate = getDateAttribute(element, endAttributeName, defaultValue.end);

  return new DateRange(startDate, endDate);
}

export function getIdListAttribute(
  element: JQuery,
  attributeName: string,
  filterNaN = false,
): number[] {
  const attributeValue = element.attr(attributeName);

  if (isEmpty(attributeValue)) {
    return [];
  }

  let idValues = attributeValue.split(",").map(parseInt);
  if (filterNaN) {
    idValues = idValues.filter(isFinite);
  }

  return idValues;
}

export function getStringListAttribute(
  element: JQuery,
  attributeName: string,
  defaultValue: string[] = [],
): string[] {
  const rawAttributeValue = element.attr(attributeName);

  if (isNil(rawAttributeValue) || isEmpty(rawAttributeValue)) {
    return defaultValue;
  }

  return rawAttributeValue.split(",");
}
export function getEnumListAttribute<T extends string>(
  element: JQuery,
  attributeName: string,
  defaultValue?: T,
): T | T[] {
  const rawAttributeValue = element.attr(attributeName);

  if (isNil(rawAttributeValue) || isEmpty(rawAttributeValue)) {
    return defaultValue;
  }

  const attributeValue = rawAttributeValue.split(",");

  if (attributeValue.length === 1) {
    return attributeValue[0] as T;
  } else {
    return attributeValue as T[];
  }
}

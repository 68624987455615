import React from "react";
import PropTypes from "prop-types";
import RepeatYearlyOn from "./On";
import RepeatYearlyOnThe from "./OnThe";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import { Grid, TextField } from "@mui/material";

const RepeatYearly = ({
  id,
  yearly: { mode, interval, on, onThe, options },
  handleChange,
  translations,
}) => {
  const isTheOnlyOneMode = (option) => options.modes === option;
  const isOptionAvailable = (option) =>
    !options.modes || isTheOnlyOneMode(option);
  return (
    <Grid item container xs={12} spacing={2} marginTop={2}>
      <Grid item xs={0} sm={2} />
      <Grid item xs={12} sm={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              id={`${id}-interval`}
              label={translateLabel(translations, "repeat.weekly.every")}
              name="repeat.yearly.interval"
              aria-label="Repeat yearly interval"
              value={interval}
              size="small"
              type="number"
              fullWidth
              onChange={numericalFieldHandler(handleChange)}
              InputProps={{
                endAdornment: translateLabel(
                  translations,
                  "repeat.yearly.years",
                ),
              }}
            />
          </Grid>

          {isOptionAvailable("on") && (
            <RepeatYearlyOn
              id={`${id}-on`}
              mode={mode}
              on={on}
              hasMoreModes={!isTheOnlyOneMode("on")}
              handleChange={handleChange}
              translations={translations}
            />
          )}
          {isOptionAvailable("on the") && (
            <RepeatYearlyOnThe
              id={`${id}-onThe`}
              mode={mode}
              onThe={onThe}
              hasMoreModes={!isTheOnlyOneMode("on the")}
              handleChange={handleChange}
              translations={translations}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
RepeatYearly.propTypes = {
  id: PropTypes.string.isRequired,
  yearly: PropTypes.shape({
    mode: PropTypes.oneOf(["on", "on the"]).isRequired,
    interval: PropTypes.number.isRequired,
    on: PropTypes.object.isRequired,
    onThe: PropTypes.object.isRequired,
    options: PropTypes.shape({
      modes: PropTypes.oneOf(["on", "on the"]),
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatYearly;

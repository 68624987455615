import { isNil, sortBy } from "lodash";
import { createQuery } from "react-query-kit";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { CollectionResourceDoc } from "jsonapi-typescript";
import {
  SENSOR_TYPE_JSONAPI_RESOURCE_TYPE,
  SensorTypeJSONAPIAttributes,
} from "../../json_api/sensor_type";
import { IDType } from "../../utils/urls/url_utils";
import { api_sensor_types_path } from "../../routes";
import {
  jsonApiResourceCollectionToFlatObjects,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
import { PageSettings } from "../common/page_size";

interface LoadSensorTypesRequest {
  in_subtree_of_asset?: IDType;
}
type LoadSensorTypesResponse = LoadItemsResult<SensorTypeJSONAPIAttributes>;

export const useLoadSensorTypesQuery = createQuery<
  LoadSensorTypesResponse,
  LoadSensorTypesRequest
>({
  queryKey: [SENSOR_TYPE_JSONAPI_RESOURCE_TYPE],
  fetcher: (variables) => {
    const urlOptions: Record<string, string | boolean | number | PageSettings> =
      {
        page: { size: 100, number: 1 },
        format: "json",
        _options: true,
      };

    if (variables.in_subtree_of_asset) {
      urlOptions["filter[in_subtree_of_asset]"] = variables.in_subtree_of_asset;
    }
    const url = api_sensor_types_path(urlOptions as any);
    return loadDataFromUrl<
      CollectionResourceDoc<string, SensorTypeJSONAPIAttributes>
    >(url).then((sensorTypesResponse) => {
      let sensorTypes =
        jsonApiResourceCollectionToFlatObjects(sensorTypesResponse);

      const resp =
        loadItemResultForJsonApiCollectionResourceDoc<SensorTypeJSONAPIAttributes>(
          sensorTypesResponse,
        );
      resp.items = sortBy(resp.items, (st) =>
        getSensorTypeNameTranslation(st.name),
      );
      return resp;
    });
  },
});

import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { SensorValueRange } from "../models/sensor_value_range";
import { buildJsonApiSubmitData } from "../utils/jsonapi_form_tools";

export interface SensorValueRangeJSONObject
  extends SensorValueRange,
    JSON.Object {}

export type SensorValueRangeJSONAPIAttributes =
  JSONAPI.AttributesObject<SensorValueRangeJSONObject>;

const updatableResourceFields = [
  "min",
  "max",
  "color",
  "icon_name",
  "status",
  "name",
  "description",
];

const creatableFields = [
  "min",
  "max",
  "color",
  "icon_name",
  "name",
  "status",
  "description",
  "sensor",
];

export const SENSOR_VALUE_RANGES_JSONAPI_RESOURCE_TYPE = "sensor_value_ranges";

export function buildSensorValueRangeUpdateRequestPayload(
  sensorValueRangeData: SensorValueRangeJSONObject,
) {
  const payload = buildJsonApiSubmitData(
    sensorValueRangeData,
    SENSOR_VALUE_RANGES_JSONAPI_RESOURCE_TYPE,
    updatableResourceFields,
  );
  return payload;
}

export function buildSensorValueRangeCreateRequestPayload(
  sensorValueRangeData: SensorValueRangeJSONObject,
) {
  const payload = buildJsonApiSubmitData(
    sensorValueRangeData,
    SENSOR_VALUE_RANGES_JSONAPI_RESOURCE_TYPE,
    creatableFields,
  );
  return payload;
}

export function buildSensorValueRangeDeleteRequestPayload(
  sensorValueRangeData: SensorValueRangeJSONObject,
) {
  const payload = buildJsonApiSubmitData(
    sensorValueRangeData,
    SENSOR_VALUE_RANGES_JSONAPI_RESOURCE_TYPE,
  );
  return payload;
}

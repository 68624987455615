/// <reference types="../definitions/index" />;
import { isArray, isEmpty } from "lodash";
import { Frequency, RRule } from "rrule";

/**
 * Creates a human readable description for a rotation configuration
 * @param maintenancePlanType The type of rotation. Either 'planned_after_runtime', 'planned_after_time' or null
 * @param criticalValue The threshold for rotations based on runtime
 * @param rrule The rrule for rotations based on time
 */
export function getRotationDescription(
  maintenancePlanType: string,
  criticalValue: number,
  rrule: string,
): string {
  switch (maintenancePlanType) {
    case "planned_after_runtime":
      return I18n.t("frontend.maintenance_plan_form.runtime_hours", {
        limit: criticalValue,
      });
    case "planned_after_time":
      return rruleToText(rrule);
    case "planned_after_runtime_or_time":
      return I18n.t("base.or_option", {
        option1: rruleToText(rrule),
        option2: I18n.t("frontend.maintenance_plan_form.runtime_hours", {
          limit: criticalValue,
        }),
      });
    default:
      return I18n.t("frontend.maintenance_plan_form.rotation_types.none");
  }
}

/**
 * Creates a localized, human-readable text from an rrule string
 * @param rrule An rrule string
 * @return A human-reable text representing the interval defined in the rrule.
 */
export function rruleToText(rrule: string): string {
  if (isEmpty(rrule)) {
    return "---";
  }

  const parsedRule = RRule.fromString(rrule);
  let text = "";

  switch (parsedRule.origOptions.freq) {
    case Frequency.HOURLY:
      text += hourlyRuleToText(parsedRule);
      break;
    case Frequency.DAILY:
      text += dailyRuleToText(parsedRule);
      break;
    case Frequency.WEEKLY:
      text += weeklyRuleToText(parsedRule);
      break;
    case Frequency.MONTHLY:
      text += monthlyRuleToText(parsedRule);
      break;
    case Frequency.YEARLY:
      text += yearlyRuleToText(parsedRule);
      break;
  }

  return text;
}

function hourlyRuleToText(rule: RRule): string {
  return rule.origOptions.interval > 1
    ? I18n.t("frontend.rrule.hourly.plural", {
        interval: rule.options.interval,
      })
    : I18n.t("frontend.rrule.hourly.singular");
}

function dailyRuleToText(rule: RRule): string {
  return rule.origOptions.interval > 1
    ? I18n.t("frontend.rrule.daily.plural", { interval: rule.options.interval })
    : I18n.t("frontend.rrule.daily.singular");
}

function weeklyRuleToText(rule: RRule): string {
  let text =
    rule.origOptions.interval > 1
      ? I18n.t("frontend.rrule.weekly.plural", {
          interval: rule.options.interval,
        })
      : I18n.t("frontend.rrule.weekly.singular");

  if (rule.origOptions.byweekday) {
    const weekDays = isArray(rule.origOptions.byweekday)
      ? rule.origOptions.byweekday.map((weekday) => weekday.toString())
      : [rule.origOptions.byweekday.toString()];

    if (isEveryDay(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.weekly.every_day")}`;
    } else if (isWeekdays(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.weekly.on_week_days")}`;
    } else if (isWeekends(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.weekly.on_week_ends")}`;
    } else {
      text += ` ${I18n.t("frontend.rrule.weekly.on_days", {
        days: weekDaysToText(weekDays),
      })}`;
    }
  }

  return text;
}

function isEveryDay(byweekday: string[]): boolean {
  if (byweekday.length !== 7) {
    return false;
  }

  return (
    byweekday.indexOf("MO") !== -1 &&
    byweekday.indexOf("TU") !== -1 &&
    byweekday.indexOf("WE") !== -1 &&
    byweekday.indexOf("TH") !== -1 &&
    byweekday.indexOf("FR") !== -1 &&
    byweekday.indexOf("SA") !== -1 &&
    byweekday.indexOf("SU") !== -1
  );
}

function isWeekdays(byweekday: string[]): boolean {
  if (byweekday.length !== 5) {
    return false;
  }

  return (
    byweekday.indexOf("MO") !== -1 &&
    byweekday.indexOf("TU") !== -1 &&
    byweekday.indexOf("WE") !== -1 &&
    byweekday.indexOf("TH") !== -1 &&
    byweekday.indexOf("FR") !== -1 &&
    byweekday.indexOf("SA") === -1 &&
    byweekday.indexOf("SU") === -1
  );
}

function isWeekends(byweekday: string[]): boolean {
  if (byweekday.length !== 2) {
    return false;
  }

  return (
    byweekday.indexOf("MO") === -1 &&
    byweekday.indexOf("TU") === -1 &&
    byweekday.indexOf("WE") === -1 &&
    byweekday.indexOf("TH") === -1 &&
    byweekday.indexOf("FR") === -1 &&
    byweekday.indexOf("SA") !== -1 &&
    byweekday.indexOf("SU") !== -1
  );
}

function weekDaysToText(byweekday: string[]): string {
  return byweekday.map(weekDayToText).join(", ");
}

function weekDayToText(weekday: string): string {
  return I18n.t(`frontend.rrule.weekdays.${weekday.toLowerCase()}`);
}

function monthlyRuleToText(rule: RRule): string {
  let text =
    rule.origOptions.interval > 1
      ? I18n.t("frontend.rrule.monthly.plural", {
          interval: rule.options.interval,
        })
      : I18n.t("frontend.rrule.monthly.singular");

  if (rule.origOptions.bymonthday) {
    // rrule generator only supports one "bymonthday" so we can simplify here
    const monthday = isArray(rule.origOptions.bymonthday)
      ? rule.origOptions.bymonthday[0]
      : rule.origOptions.bymonthday;

    text += ` ${I18n.t("frontend.rrule.monthly.on", { day: monthday })}`;
  } else if (rule.origOptions.byweekday && rule.origOptions.bysetpos) {
    const weekDays = isArray(rule.origOptions.byweekday)
      ? rule.origOptions.byweekday.map((weekday) => weekday.toString())
      : [rule.origOptions.byweekday.toString()];
    const bysetpos = isArray(rule.origOptions.bysetpos)
      ? rule.origOptions.bysetpos[0]
      : rule.origOptions.bysetpos;

    if (isEveryDay(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.monthly.every_day", {
        nth: bySetPosToText(bysetpos),
      })}`;
    } else if (isWeekdays(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.monthly.on_week_day", {
        nth: bySetPosToText(bysetpos),
      })}`;
    } else if (isWeekends(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.monthly.on_week_end", {
        nth: bySetPosToText(bysetpos),
      })}`;
    } else {
      text += ` ${I18n.t("frontend.rrule.monthly.on_day", {
        day: weekDayToText(weekDays[0]),
        nth: bySetPosToText(bysetpos),
      })}`;
    }
  }

  return text;
}

function bySetPosToText(bysetpos: number): string {
  switch (bysetpos) {
    case 1:
      return I18n.t("frontend.rrule.first");
    case 2:
      return I18n.t("frontend.rrule.second");
    case 3:
      return I18n.t("frontend.rrule.third");
    case 4:
      return I18n.t("frontend.rrule.fourth");
    case -1:
      return I18n.t("frontend.rrule.last");
    default:
      return `${bysetpos}.`;
  }
}

function yearlyRuleToText(rule: RRule): string {
  let text =
    rule.origOptions.interval > 1
      ? I18n.t("frontend.rrule.yearly.plural", {
          interval: rule.options.interval,
        })
      : I18n.t("frontend.rrule.yearly.singular");

  if (rule.origOptions.bymonthday && rule.origOptions.bymonth) {
    // rrule generator only supports one "bymonthday" and "bymonth" so we can simplify here
    const monthday = isArray(rule.origOptions.bymonthday)
      ? rule.origOptions.bymonthday[0]
      : rule.origOptions.bymonthday;
    const month = isArray(rule.origOptions.bymonth)
      ? rule.origOptions.bymonth[0]
      : rule.origOptions.bymonth;

    text += ` ${I18n.t("frontend.rrule.yearly.on", {
      day: monthday,
      month: monthToText(month),
    })}`;
  } else if (
    rule.origOptions.byweekday &&
    rule.origOptions.bysetpos &&
    rule.origOptions.bymonth
  ) {
    const weekDays = isArray(rule.origOptions.byweekday)
      ? rule.origOptions.byweekday.map((weekday) => weekday.toString())
      : [rule.origOptions.byweekday.toString()];
    const bysetpos = isArray(rule.origOptions.bysetpos)
      ? rule.origOptions.bysetpos[0]
      : rule.origOptions.bysetpos;
    const month = isArray(rule.origOptions.bymonth)
      ? rule.origOptions.bymonth[0]
      : rule.origOptions.bymonth;

    if (isEveryDay(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.yearly.every_day", {
        nth: bySetPosToText(bysetpos),
        month: monthToText(month),
      })}`;
    } else if (isWeekdays(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.yearly.on_week_day", {
        nth: bySetPosToText(bysetpos),
        month: monthToText(month),
      })}`;
    } else if (isWeekends(weekDays)) {
      text += ` ${I18n.t("frontend.rrule.yearly.on_week_end", {
        nth: bySetPosToText(bysetpos),
        month: monthToText(month),
      })}`;
    } else {
      text += ` ${I18n.t("frontend.rrule.yearly.on_day", {
        day: weekDayToText(weekDays[0]),
        nth: bySetPosToText(bysetpos),
        month: monthToText(month),
      })}`;
    }
  }

  return text;
}

function monthToText(month: number): string {
  return I18n.t(`frontend.rrule.months.${month}`);
}

import { isEmpty, last } from "lodash";

/**
 * Image upload input is added to html element to allow later destruction
 */
export interface HTMLImageInputElement extends HTMLInputElement {
  imageUploadInput?: ImageUploadInput;
}

/**
 * A drag and drop image picker with preview
 */
export class ImageUploadInput {
  element: JQuery<HTMLElement>;
  dropContainer: JQuery<HTMLElement>;
  imagePreview: JQuery<HTMLElement>;
  textField: JQuery<HTMLElement>;
  noImage: JQuery<HTMLElement>;
  fileNameField: JQuery<HTMLElement>;
  removeImageButton: JQuery<HTMLElement>;
  removeImageHiddenInput: JQuery<HTMLElement>;

  static getRemoveFormAttribute(element: JQuery<HTMLElement>): string {
    const formAttribute = element.attr("name");

    if (isEmpty(formAttribute)) {
      return null;
    } else if (formAttribute.indexOf("[") !== -1) {
      const components = formAttribute.split("[");
      let removeAttribute = components[0];
      for (let i = 1; i < components.length - 1; ++i) {
        removeAttribute += `[${components[i]}`;
      }
      removeAttribute += `[remove_${last(components)}`;

      return removeAttribute;
    } else {
      return `remove_${formAttribute}`;
    }
  }

  /**
   * Create a drag and drop image picker from a regular file input.
   * The file input is used to send the selected file as form data.
   * @param elementOrSelector A CSS selector, html node or jquery object with the form element
   */
  constructor(elementOrSelector: string | HTMLElement | JQuery<HTMLElement>) {
    this.element = $(elementOrSelector as HTMLElement);
    let elementContainer: JQuery<HTMLElement>;
    if (this.element.parent().hasClass("custom-file")) {
      elementContainer = this.element.parent();
    } else {
      elementContainer = this.element;
    }

    // show preview image
    this.element.on("change", (event: JQuery.ChangeEvent) =>
      this.handleFileChange(event.originalEvent),
    );

    // create drop container
    this.dropContainer = $('<div class="dropcontainer"></div>');
    this.dropContainer.on("dragover dragenter", (event: JQuery.Event) =>
      this.handleDragOver(event),
    );
    this.dropContainer.on("dragleave dragend", (event: JQuery.Event) =>
      this.handleDragLeave(event),
    );
    this.dropContainer.on("drop", (event: JQuery.TriggeredEvent) =>
      this.handleDrop(event.originalEvent as DragEvent),
    );
    this.dropContainer.click(() => this.handleClick());
    elementContainer.before(this.dropContainer);

    const helpText = this.element.attr("data-help-text") || "";
    this.textField = $(
      `<div class="help-text"><span><strong>${helpText}</strong></span><br><span id="file-name"></span></div>`,
    );
    this.fileNameField = this.textField.find<HTMLElement>("#file-name");
    this.dropContainer.append(this.textField);

    this.imagePreview = $('<img class="dropcontainer-preview"></img>');
    const previewImage = this.element.attr("data-preview");
    if (!isEmpty(previewImage)) {
      this.imagePreview.attr("src", previewImage);
    }
    this.dropContainer.append(this.imagePreview);

    this.noImage = $(
      '<span class="no-preview"><i class="fa fa-file-image-o fa-2x"></i></div>',
    );
    this.dropContainer.append(this.noImage);

    const removeHelpText = this.element.attr("data-remove-help-text") || "";
    this.removeImageButton = $(
      `<i id="remove-image" class="fa fa-trash-o fa-lg" title="${removeHelpText}"></i>`,
    );
    this.removeImageButton.click(() => this.handleImageRemove());
    this.dropContainer.append(this.removeImageButton);

    this.removeImageHiddenInput = $(
      `<input type="hidden" name="${ImageUploadInput.getRemoveFormAttribute(
        this.element,
      )}" value="false"></input>`,
    );
    this.dropContainer.append(this.removeImageHiddenInput);

    // add object to html element
    (this.element[0] as HTMLImageInputElement).imageUploadInput = this;
  }

  /**#
   * Removes all event handler and html(call this before turbo:cache).
   */
  destroy(): void {
    this.element.off("change");
    this.dropContainer.off("dragover dragenter drop dragexit dragend");
    this.dropContainer.remove();
    delete (this.element[0] as HTMLImageInputElement).imageUploadInput;
  }

  private handleFileChange(event: Event): void {
    const fileInput = this.element[0] as HTMLInputElement;
    if (isEmpty(fileInput.files)) {
      this.imagePreview.removeAttr("src");
      this.fileNameField.text("");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview.attr("src", reader.result as any);
    };
    reader.readAsDataURL(fileInput.files[0]);
    this.fileNameField.text(fileInput.files[0].name);
    this.removeImageHiddenInput.val("false");
  }

  private handleImageRemove(): boolean {
    const fileInput = this.element[0] as HTMLInputElement;
    fileInput.files = null;
    this.removeImageHiddenInput.val("true");
    this.imagePreview.removeAttr("src");
    this.fileNameField.text("");
    return false;
  }

  private handleClick(): void {
    this.element.trigger("click");
  }

  private handleDragOver(event: JQuery.Event): void {
    event.preventDefault();
    this.dropContainer.addClass("drop-hover");
  }

  private handleDragLeave(event: JQuery.Event): void {
    event.preventDefault();
    this.dropContainer.removeClass("drop-hover");
  }

  private handleDrop(event: DragEvent): void {
    const fileInput = this.element[0] as HTMLInputElement;
    fileInput.files = event.dataTransfer.files;
    this.handleFileChange(null);
    this.dropContainer.removeClass("drop-hover");
    event.preventDefault();
  }
}

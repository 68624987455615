import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { AssetEventDetails } from "./asset_event_details";
import { AssetEventForm } from "./asset_event_form";
import { AssetEventList } from "./asset_event_list";
import { ResourcePermission } from "../../models/resource_permission";

const roots: Root[] = [];
const listRoots: Root[] = [];
const detailsRoots: Root[] = [];
export function initializeAssetEventForm(
  selector = $('[data-toggle="asset-event-form"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    roots.push(root);
    const jqElem = $(element);
    const assetId = jqElem.data("asset-id");
    const assetEventId = jqElem.data("asset-event-id");
    const permissions = jqElem.data("permissions") as ResourcePermission;
    root.render(
      <AppRoot>
        <AssetEventForm
          assetId={assetId}
          assetEventId={assetEventId}
          permissions={permissions}
          withFloatingButtons
          withCard
        />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventForms() {
  roots.forEach((r) => {
    r.unmount();
  });
  roots.length = 0;
}

export function initializeAssetEventsList(
  selector = $('[data-toggle="asset-event-list"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    listRoots.push(root);
    const jqElem = $(element);
    const assetId = jqElem.data("asset-id");

    root.render(
      <AppRoot>
        <AssetEventList assetId={assetId} />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventsList() {
  listRoots.forEach((r) => {
    r.unmount();
  });
  listRoots.length = 0;
}

export function initializeAssetEventDetails(
  selector = $('[data-toggle="asset-event-details"]'),
): void {
  selector.each((index, element) => {
    const root = createRoot(element);
    detailsRoots.push(root);
    const jqElem = $(element);
    const assetEventId = jqElem.data("asset-event-id");
    root.render(
      <AppRoot>
        <AssetEventDetails
          assetEventId={assetEventId}
          withFabButtons
          wrapInCard
        />
      </AppRoot>,
    );
  });
}

export function destroyAssetEventDetails() {
  detailsRoots.forEach((r) => {
    r.unmount();
  });
  detailsRoots.length = 0;
}

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { HttpError } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { useLoadMqttUser, useUpdateMqttUser } from "./mqtt_users_data";

import { isEmpty, isNil } from "lodash";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { error, success } from "../../utils/toasts";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { logger } from "../../utils/logger";

interface ResetMqttPasswordFormProps {
  mqttUserId: IDType;
  mqttUser?: MqttUserJSONObject;
  onFinish?: () => void;
  showTitle?: boolean;
}

const PASSWORD_MIN_LENGTH = 8;

export const ResetMqttUserPasswordForm: React.FunctionComponent<
  ResetMqttPasswordFormProps
> = ({
  mqttUserId,
  mqttUser: userFromProps,
  onFinish,
  showTitle = true,
}: ResetMqttPasswordFormProps) => {
  const confirm = useConfirm();

  const [mqttUser, setMqttUser] = React.useState<MqttUserJSONObject | null>(
    userFromProps,
  );

  const {
    data: loadedMqttUser,
    isLoading,
    error: mqttUserLoadError,
  } = useLoadMqttUser({
    variables: { id: mqttUserId },
    initialData:
      !isNil(mqttUserId) && mqttUser?.id == mqttUserId ? mqttUser : undefined,
  });

  React.useEffect(() => {
    if (loadedMqttUser || userFromProps) {
      if (loadedMqttUser !== userFromProps) {
        setMqttUser(loadedMqttUser);
      }
    }
  }, [loadedMqttUser, userFromProps]);

  const { mutateAsync: updateMqttUser, error: updateError } =
    useUpdateMqttUser();

  const [password, setPassword] = React.useState<string>(null);
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>(null);

  const [passwordOk, setPasswordOk] = React.useState<boolean>(null);

  const [errorText, setErrorText] = React.useState<string>(null);
  React.useEffect(() => {
    if (
      isEmpty(password) ||
      isEmpty(passwordConfirmation) ||
      password !== passwordConfirmation
    ) {
      setPasswordOk(false);
      setErrorText(I18n.t("frontend.mqtt_users.passwords_do_not_match"));
      return;
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      setPasswordOk(false);
      setErrorText(
        I18n.t("frontend.mqtt_users.password_min_length_not_met", {
          min_length: PASSWORD_MIN_LENGTH,
        }),
      );
      return;
    }

    setPasswordOk(true);
    setErrorText(null);
  }, [password, passwordConfirmation]);

  React.useEffect(() => {
    if (updateError) {
      error(
        I18n.t("frontend.error"),
        I18n.t("frontend.mqtt_users.error_updating_password"),
      );
      logger.logError(updateError);
    }
  }, [updateError]);
  return (
    <Paper>
      <Box padding={2}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={100} />
        ) : mqttUserLoadError ? (
          <>
            <Typography>
              {I18n.t("frontend.mqtt_users.error_loading_user")}
            </Typography>
            <Typography variant="caption">
              {mqttUserLoadError.message}
            </Typography>
          </>
        ) : (
          <Grid container spacing={2}>
            {showTitle && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  {I18n.t("frontend.mqtt_users.reset_password_heading", {
                    username: mqttUser?.username,
                  })}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>
                {I18n.t("frontend.mqtt_users.regenerate_password_warning")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ maxWidth: 300 }}
                fullWidth
                type={"password"}
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoFocus
                InputProps={{
                  autoComplete: "new-password",
                }}
                label={I18n.t("activerecord.attributes.mqtt_user.password")}
                helperText={
                  isEmpty(password)
                    ? I18n.t(
                        "activerecord.attributes.mqtt_user.password_in_clear_text",
                      )
                    : errorText
                }
                error={!isEmpty(password) && !passwordOk}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={I18n.t(
                  "activerecord.attributes.mqtt_user.password_in_clear_text_confirmation",
                )}
                sx={{ maxWidth: 300 }}
                type={"password"}
                value={passwordConfirmation || ""}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                }}
                InputProps={{
                  autoComplete: "new-password",
                }}
                error={!isEmpty(password) && !passwordOk}
                helperText={
                  isEmpty(passwordConfirmation)
                    ? I18n.t(
                        "activerecord.attributes.mqtt_user.password_in_clear_text_confirmation",
                      )
                    : errorText
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!passwordOk}
                onClick={() => {
                  void confirm({
                    description: I18n.t(
                      "frontend.mqtt_users.confirm_regenerate_password",
                    ),
                  }).then(async () => {
                    const res = await updateMqttUser({
                      id: mqttUserId,
                      password_in_clear_text: password,
                    });

                    void success(
                      I18n.t("frontend.success"),
                      I18n.t("frontend.mqtt_users.password_updated"),
                    );
                    if (onFinish) onFinish();
                  });
                }}
                variant="contained"
                color="primary"
              >
                {I18n.t("frontend.mqtt_users.set_password")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};

import * as React from "react";
import { isEmpty } from "lodash";
import JQuery from "jquery";
import { AssetTypeHomePage } from "./home_page";
import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../../components/common/app_root";
import { AssetTypesIndexPage } from "./index_page";
const assetTypeHomeRoots: Root[] = [];

export function initAssetTypeHome(
  itemOrSelector: JQuery | string = '[data-toggle="asset-type-home"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, formMountItem) => {
    const assetTypeId = $(formMountItem).data("asset-type-id") as number;
    const canEdit = $(formMountItem).data("can-edit") as boolean;
    const root = createRoot(formMountItem);
    assetTypeHomeRoots.push(root);
    root.render(
      <AppRoot>
        <AssetTypeHomePage assetTypeId={assetTypeId} canEdit={canEdit} />
      </AppRoot>,
    );
  });
}

const indexRoots: Root[] = [];
export function initAssetTypeIndex(
  itemOrSelector: JQuery | string = '[data-toggle="asset-type-index"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, formMountItem) => {
    const organizationId = $(formMountItem).data("organization-id") as number;
    const parentAssetID = $(formMountItem).data("parent-asset-id") as number;
    const canEdit = $(formMountItem).data("can-edit") as boolean;
    const root = createRoot(formMountItem);
    indexRoots.push(root);
    root.render(
      <AppRoot>
        <AssetTypesIndexPage
          organizationId={organizationId}
          parentAssetID={parentAssetID}
          canEdit={canEdit}
        />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Report List in given container
 */
export function destroyAssetTypesIndex(): void {
  indexRoots.forEach((root) => {
    root.unmount();
  });
  indexRoots.length = 0;
}

/**
 *  Destroy Report List in given container
 */
export function destroyAssetTypesHome(): void {
  assetTypeHomeRoots.forEach((root) => {
    root.unmount();
  });
  assetTypeHomeRoots.length = 0;
}

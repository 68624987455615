import { Grid, IconButton, Link } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { getTimeRangeString } from "../../../utils/time_strings";
import { ReportAttributes } from "../data/models";

import { GetApp, Visibility } from "@mui/icons-material";
import { asset_report_plan_report_path } from "../../../routes";
interface ReportItemProperties {
  report: ReportAttributes;
  onShowReport?: (report: ReportAttributes) => void;
}

function showReportHref(props: ReportItemProperties) {
  return isNil(props.onShowReport)
    ? asset_report_plan_report_path(
        props.report.asset_id,
        props.report.report_plan_id,
        props.report.id,
      )
    : null;
}

function showReportOnClick(props: ReportItemProperties): () => void | null {
  return isNil(props.onShowReport)
    ? null
    : () => props.onShowReport(props.report);
}

export const ReportItem: React.FunctionComponent<ReportItemProperties> = (
  props,
) => {
  return (
    <Grid item container xs={12} alignItems="center" className="border-top">
      <Grid item xs={5}>
        {getTimeRangeString(props.report.period_from, props.report.period_to)}
      </Grid>
      <Grid item xs={3}>
        {I18n.l(
          "time.formats.sialogic_short",
          props.report.created_at.toLocaleString(),
        )}
      </Grid>
      <Grid item container xs={4} justifyContent="space-between">
        <Grid item>
          {isEmpty(props.report.preview_url) ? null : (
            <Link
              href={showReportHref(props)}
              onClick={showReportOnClick(props)}
            >
              <img
                alt="preview"
                src={props.report.preview_url}
                style={{ maxHeight: 100 }}
              />
            </Link>
          )}
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            title={I18n.t("frontend.report_list.show_pdf")}
            size="small"
            href={showReportHref(props)}
            onClick={showReportOnClick(props)}
            disabled={isEmpty(props.report.report_pdf_url)}
          >
            <Visibility />
          </IconButton>
          <IconButton
            className="auto-hyphen"
            color="primary"
            size="small"
            href={props.report.report_pdf_url}
            target="_blank"
            title={I18n.t("frontend.report_list.download_pdf")}
            disabled={isEmpty(props.report.report_pdf_url)}
          >
            <GetApp />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

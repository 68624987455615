import { MenuItem, TextField } from "@mui/material";
import { defaultTo, isEmpty } from "lodash";
import * as React from "react";
import { EventPatternType } from "../../models/event_pattern";
interface EventPatternTypeSelectProps {
  type: EventPatternType;
  readonly?: boolean;
  error?: string;
  onTypeSelect(newType: EventPatternType): void;
}

export const EventPatternTypeSelect: React.FunctionComponent<
  EventPatternTypeSelectProps
> = (props) => {
  return (
    <TextField
      size="small"
      fullWidth
      select
      required
      InputProps={{
        readOnly: props.readonly,
      }}
      value={props.type}
      label={I18n.t(
        "activerecord.attributes.event_patterns/event_pattern.type",
      )}
      error={!isEmpty(props.error)}
      helperText={defaultTo(
        props.error,
        I18n.t("frontend.event_patterns.form.select_pattern_type"),
      )}
      onChange={(e) => {
        props.onTypeSelect(e.target.value as EventPatternType);
      }}
    >
      <MenuItem key={"sep"} value={"EventPatterns::SensorEventPattern"}>
        {I18n.t("activerecord.models.event_patterns/sensor_event_pattern", {
          count: 1,
        })}
      </MenuItem>
      <MenuItem
        key={"sdtep"}
        value={"EventPatterns::SensorDataTransmissionEventPattern"}
      >
        {I18n.t(
          "activerecord.models.event_patterns/sensor_data_transmission_event_pattern",
          {
            count: 1,
          },
        )}
      </MenuItem>
    </TextField>
  );
};

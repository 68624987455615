import { isEmpty, isString } from "lodash";
import * as React from "react";

import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { User } from "../data/model";
interface UserGuestUserAutocompleteProps {
  label?: string;
  users?: User[];
  user?: User;
  userErrors?: string;
  disabled?: boolean;
  placeholder?: string;

  onUserSelect: (name: string, user: User) => void;
}

type NameUserAutocompleteValue = User & { acceptSelection?: string };

function getOptionLabel(option: NameUserAutocompleteValue | string) {
  if (typeof option == "string") return option;

  if (option?.acceptSelection) {
    return option.acceptSelection;
  }
  return option.name;
}

function getOptionKey(option: NameUserAutocompleteValue | string) {
  if (typeof option == "string") return option;
  return `${option.type}${option.id}${option.name}`;
}

const filter = createFilterOptions<NameUserAutocompleteValue>();

export const UserGuestUserAutocomplete: React.FunctionComponent<
  UserGuestUserAutocompleteProps
> = (props) => {
  return (
    <Autocomplete<NameUserAutocompleteValue, false, false, true>
      fullWidth={true}
      size="small"
      renderInput={(textFieldProps) => (
        <TextField
          {...textFieldProps}
          variant="outlined"
          placeholder={props.placeholder}
          label={props.label}
          error={!isEmpty(props.userErrors)}
          helperText={props.userErrors}
        />
      )}
      value={props.user || null}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => {
        if (typeof option == "string" && typeof value == "string") {
          return option == value;
        } else if (typeof option == "string" && typeof value != "string") {
          return value.name == option;
        } else if (typeof option != "string" && typeof value == "string") {
          return option.name == value;
        } else {
          if (option.type == "GuestUser" && value.type == "GuestUser") {
            // compare GuestUsers by name
            return option.name == value.name;
          } else if (option.type == "User" && value.type == "User") {
            return option.id == value.id;
          } else {
            return false;
          }
        }
      }}
      renderOption={(props, value, state, ownerState) => {
        let type = "";
        if (isString(value)) {
          type = I18n.t("base.new");
        } else if (value?.type == "GuestUser") {
          type = "ext.";
        } else if (value?.type == "User") {
          type = "int.";
        }

        return (
          <Box component="li" {...props} key={getOptionKey(value)}>
            <Grid container>
              <Grid item xs={8}>
                {getOptionLabel(value)}
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="caption" color="textSecondary">
                  {type}
                </Typography>
              </Grid>
              {value.acceptSelection ? (
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    {value.acceptSelection}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (!isEmpty(params.inputValue)) {
          filtered.unshift({
            id: null,
            name: params.inputValue,
            type: "GuestUser",
            acceptSelection: I18n.t(
              "frontend.maintenance_plan_name_input.add",
              {
                name: params.inputValue,
              },
            ),
          });
        }

        return filtered;
      }}
      options={props.users}
      disabled={props.disabled}
      freeSolo
      onChange={(event, selectedElement) => {
        if (isString(selectedElement)) {
          props.onUserSelect(selectedElement, null);
          return;
        }

        props.onUserSelect(
          selectedElement?.name || null,
          selectedElement?.acceptSelection
            ? // remove the acceptSelection property for final selection
              {
                id: null,
                type: "GuestUser",
                name: selectedElement.name,
              }
            : selectedElement,
        );
      }}
    />
  );
};

import { JSONSchema4 } from "json-schema";
import * as JSON from "json-typescript";
import { defaultTo, isEmpty, isNil, map, toString } from "lodash";
import * as React from "react";
import { AttributeRow } from "./attribute_row";
import { Grid } from "@mui/material";

export interface JSONSchemaValueRowProperties {
  jsonValues: JSON.Object;
  schema: JSONSchema4;
}
export const JSONSchemaValueRow: React.FunctionComponent<
  JSONSchemaValueRowProperties
> = (props) => {
  if (isNil(props.jsonValues) || isEmpty(props.jsonValues)) return null;

  return map(props.jsonValues, (val, key) => {
    const metaInfo = props.schema;
    const descr = descriptionFromSchema(key, metaInfo);
    return (
      <AttributeRow
        key={key}
        attributeName={descr.title}
        value={toString(val)}
        title={descr.description}
      />
    );
  });
};

function descriptionFromSchema(
  key: string,
  schema: JSONSchema4,
): {
  title?: string;
  description?: string;
} {
  const metaInfo = schema?.properties[key] ?? { description: "" };
  metaInfo.title = defaultTo(metaInfo.title, key);
  return metaInfo;
}

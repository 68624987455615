import { defaultTo, isNil, pick, last, get, find } from "lodash";
import * as L from "leaflet";
import geojson from "geojson";
import { Moment } from "moment";
import { getMarkerIconForFeature } from "../../map/feature_icons";
import { getFeaturePopupContent } from "../../map/feature_popup";
import { AssetFeatureType, SensorPointFeatureType } from "../../map/map.types";
import { getValueRangeForValue } from "../../utils/status_helper";

export function initMap(
  node: HTMLDivElement,
  {
    tileZoom,
    zoom,
    startPosition,
    startZoom,
    mapUrl,
    attribution,
  }: {
    tileZoom?: { max: number; min: number };
    zoom?: { max: number; min: number };
    startPosition?: L.LatLngTuple | null;
    startZoom?: number | null;
    mapUrl: string;
    attribution: string;
  },
) {
  const assetIdMarkerMap = new Map<number, L.Marker>();
  const assetSensorIdMarkerMap = new Map<number, L.Marker>();
  const assetContextStateMachineIdMarkerMap = new Map<number, L.Marker>();

  const newMap = L.map(node, {
    maxZoom: defaultTo(zoom.max, 19),
    minZoom: defaultTo(zoom.min, 4),
    preferCanvas: true,
  });

  const resizeObserver = new ResizeObserver(() => {
    try {
      newMap.invalidateSize();
    } catch (e) {
      // swallow errors
    }
  });
  resizeObserver.observe(node);

  const newMapLayer = L.tileLayer(mapUrl, {
    maxZoom: defaultTo(tileZoom.max, 19),
    minZoom: defaultTo(tileZoom.min, 0),
    attribution: attribution,
  });
  newMapLayer.addTo(newMap);

  if (!isNil(startPosition)) {
    newMap.setView(
      [startPosition[0], startPosition[1]],
      startZoom ?? startPosition[2],
    );
  }

  return {
    assetIdMarkerMap,
    assetSensorIdMarkerMap,
    assetContextStateMachineIdMarkerMap,
    map: newMap,
  };
}
export function handleNonLocationSensorValueUpdate(
  assetSensorIdMarkerMap: Map<number, L.Marker>,
  sensorId: number,
  value: number | string,
  time: Moment,
  props: any,
) {
  const marker = assetSensorIdMarkerMap.get(sensorId);
  if (!isNil(marker)) {
    const feature = marker.feature as AssetFeatureType;
    const sensorInfo = find(
      feature.properties.sensors,
      (sensorData, keyId) => sensorData.sensor_id === sensorId,
    );
    if (!isNil(sensorInfo)) {
      sensorInfo.value = value;
      sensorInfo.timestamp = time.toISOString();
      if (sensorInfo.value_ranges) {
        sensorInfo.range = getValueRangeForValue(
          value as number,
          sensorInfo.value_ranges,
        );
      }

      marker.setPopupContent(getFeaturePopupContent(feature));
      marker.setIcon(
        getMarkerIconForFeature(
          feature,
          props.markerMappingMode,
          props.markerMappingStateContextIdentifier,
          props.markerMappingSensorKey,
        ),
      );
    }
  }
}

export function handleLocationUpdate(
  pointFeatures: any,
  lineFeatures: any,
  sensorsLayer: L.Realtime,
  sensorId: number,
  value: any,
  time: Moment,
  attributeKeyId: number,
) {
  const prevPoints = get(pointFeatures, [
    sensorId,
  ]) as geojson.FeatureCollection;
  const line = get(lineFeatures, [sensorId, "features", 0]) as geojson.Feature;

  if (isNil(line) || isNil(prevPoints)) {
    return;
  }

  const pointFeature: SensorPointFeatureType = {
    type: "Feature",
    properties: {
      isLastFeature: true,
      sensor_id: sensorId,
      attribute_key_id: attributeKeyId,
      timestamp: time.valueOf(),
      ...pick(line.properties, [
        "sensor_name",
        "asset_name",
        "asset_id",
        "feature_id",
      ]),
    },
    geometry: {
      type: "Point",
      coordinates: [value.x, value.y, value.z],
    },
  };

  const lastPoint: geojson.Feature = last(prevPoints.features);
  lastPoint.properties.isLastFeature = false;
  prevPoints.features.push(pointFeature);

  if (line.geometry.type === "LineString") {
    (line.properties.timestamps as number[]).push(time.valueOf());
    line.geometry.coordinates.push([value.x, value.y, value.z]);
  }

  sensorsLayer.remove(lastPoint);
  sensorsLayer.update([pointFeature, lastPoint]);
  sensorsLayer.update(line);
}

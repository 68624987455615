import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";

import { Grid } from "@mui/material";
import { FunctionComponent } from "react";
import { CONSTANTS } from "../../../utils/constants";
import { getRotationDescription } from "../../../utils/rotation_description";
import { MaintenanceJob, MaintenancePlanWithLastJob } from "../data/model";

interface MaintenanceStatusFieldProps {
  maintenanceJob: MaintenanceJob;
}

/**
 * Field to display status for maintenance jobs(next runtime, rotation etc.)
 */
export const MaintenanceStatusField: FunctionComponent<
  MaintenanceStatusFieldProps
> = (props: MaintenanceStatusFieldProps) => {
  const maintenancePlan = props.maintenanceJob.maintenance_plan;
  return (
    <Grid item container xs={12} className="p-2">
      <Grid
        item
        container
        xs={12}
        className="attribute-row small border-bottom"
      >
        <Grid item xs={6}>
          <b className="auto-hyphen">
            {I18n.t("frontend.maintenance_form.last_maintenance_job_at")}:
          </b>
        </Grid>
        <Grid item xs={6}>
          <div className="float-right text-right">
            {maintenancePlan.last_maintenance_job_at
              ? I18n.l(
                  "time.formats.sialogic_short",
                  maintenancePlan.last_maintenance_job_at,
                )
              : "---"}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className="attribute-row small border-bottom"
      >
        <Grid item xs={6}>
          <b className="auto-hyphen">
            {maintenancePlan.maintenance_plan_type === "planned_after_runtime"
              ? I18n.t(
                  "frontend.maintenance_form.runtime_since_last_maintenance_job",
                )
              : I18n.t(
                  "frontend.maintenance_form.time_since_last_maintenance_job",
                )}
            :
          </b>
        </Grid>
        <Grid item xs={6}>
          <div className="float-right text-right">
            {getRuntimeSinceLastMaintenance(maintenancePlan)}
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className="attribute-row small border-bottom"
      >
        <Grid item xs={6}>
          <b className="auto-hyphen">
            {I18n.t("frontend.maintenance_form.runtime_until_next_maintenance")}
            :
          </b>
        </Grid>
        <Grid item xs={6}>
          <div className="float-right text-right">
            {getNextRuntimeLabel(maintenancePlan)}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className="attribute-row small border-bottom"
      >
        <Grid item xs={6}>
          <b className="auto-hyphen">
            {I18n.t("frontend.maintenance_form.next_runtime_at")}:
          </b>
        </Grid>
        <Grid item xs={6}>
          <div className="float-right text-right">
            {maintenancePlan.next_runtime_at
              ? I18n.l(
                  "time.formats.sialogic_short",
                  maintenancePlan.next_runtime_at,
                )
              : "---"}
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={12} className="attribute-row small border-top">
        <Grid item xs={6}>
          <b className="auto-hyphen">
            {I18n.t("frontend.maintenance_form.rotation")}:
          </b>
        </Grid>
        <Grid item xs={6}>
          <div className="float-right text-right">
            {getRotationDescription(
              maintenancePlan.maintenance_plan_type,
              maintenancePlan.critical_value,
              maintenancePlan.rrule,
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

function getRuntimeSinceLastMaintenance(
  maintenancePlan: MaintenancePlanWithLastJob,
): string {
  if (isNil(maintenancePlan.runtime_since_last_maintenance_job)) {
    return CONSTANTS.EMPTY_VALUE_PLACEHOLDER;
  }

  switch (maintenancePlan.maintenance_plan_type) {
    case "planned_after_runtime":
    case "unplanned":
      return I18n.t("frontend.maintenance_plan_form.runtime_hours", {
        limit:
          maintenancePlan.runtime_since_last_maintenance_job.toLocaleString(
            I18n.locale,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ),
      });
    case "planned_after_time":
      return I18n.t("frontend.maintenance_plan_form.hours", {
        limit:
          maintenancePlan.runtime_since_last_maintenance_job.toLocaleString(
            I18n.locale,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ),
      });
    case "planned_after_runtime_or_time":
      return I18n.t("frontend.maintenance_plan_form.hours", {
        limit:
          maintenancePlan.runtime_since_last_maintenance_job.toLocaleString(
            I18n.locale,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ),
      });
  }

  return CONSTANTS.EMPTY_VALUE_PLACEHOLDER;
}

function getNextRuntimeLabel(
  maintenancePlan: MaintenancePlanWithLastJob,
): string {
  if (isNil(maintenancePlan.runtime_until_next_maintenance_job)) {
    return CONSTANTS.EMPTY_VALUE_PLACEHOLDER;
  }

  switch (maintenancePlan.maintenance_plan_type) {
    case "planned_after_runtime":
      return I18n.t("frontend.maintenance_plan_form.runtime_hours", {
        limit:
          maintenancePlan.runtime_until_next_maintenance_job.toLocaleString(
            I18n.locale,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ),
      });
    case "planned_after_time":
      return I18n.t("frontend.maintenance_plan_form.hours", {
        limit:
          maintenancePlan.runtime_until_next_maintenance_job.toLocaleString(
            I18n.locale,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ),
      });
  }

  return CONSTANTS.EMPTY_VALUE_PLACEHOLDER;
}

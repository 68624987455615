import { Grid, Link } from "@mui/material";
import * as React from "react";
import { MaintenanceJob, MaintenanceJobGroup, User } from "../data/model";
import { MaintenanceJobList } from "./maintenance_job_list";

interface MaintenanceJobGroupListProps {
  maintenanceJobGroup: MaintenanceJobGroup;
  id?: string;
  users: User[];
  onUpdateMaintenanceJob: (
    assetIndex: number,
    maintenanceIndex: number,
    maintenanceJob: MaintenanceJob,
  ) => void;

  onGuestUserAdded: (name: string, newUser: User) => void;

  onToggle: (assetIndex: number, expanded: boolean) => void;
}

export class MaintenanceJobGroupList extends React.Component<MaintenanceJobGroupListProps> {
  shouldComponentUpdate(nextProps: MaintenanceJobGroupListProps): boolean {
    return (
      this.props.maintenanceJobGroup !== nextProps.maintenanceJobGroup ||
      this.props.users !== nextProps.users
    );
  }

  render(): React.ReactNode {
    return (
      <Grid container id={this.props.id} spacing={2}>
        <Grid item xs={12}>
          <div className="page-header">
            <h3>
              <Link href={this.props.maintenanceJobGroup.title_link}>
                {this.props.maintenanceJobGroup.title}
              </Link>
            </h3>
          </div>
        </Grid>

        {this.props.maintenanceJobGroup.assets.map((asset, assetIndex) => {
          return (
            <Grid item xs={12} key={assetIndex}>
              <MaintenanceJobList
                asset={asset}
                users={this.props.users}
                onUpdateMaintenanceJob={(maintenanceIndex, mainteanceJob) =>
                  this.props.onUpdateMaintenanceJob(
                    assetIndex,
                    maintenanceIndex,
                    mainteanceJob,
                  )
                }
                onGuestUserAdded={(name: string, newUser: User) =>
                  this.props.onGuestUserAdded(name, newUser)
                }
                onToggle={(expanded) =>
                  this.props.onToggle(assetIndex, expanded)
                }
              />
            </Grid>
          );
        })}
        <hr />
      </Grid>
    );
  }
}

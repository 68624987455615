/// <reference lib="dom" />
import { Grid } from "@mui/material";
import { isEmpty, isEqual, isNil, merge } from "lodash";
import * as React from "react";
import { ModelViewer } from "../../model_viewer/model_viewer";
import { RenderManager, Renderable } from "../../model_viewer/render_manager";
import { logger } from "../../utils/logger";
import { ModelViewerWidgetConfigSerialized } from "../../widgets/model_viewer_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";

import {
  ModelViewerWidgetProps,
  ModelViewerWidgetState,
} from "./model_viewer_widget.types";

import { WidgetBox } from "./widget_box";
export class ModelViewerWidget extends React.Component<
  ModelViewerWidgetProps,
  ModelViewerWidgetState
> {
  static defaultProps: Partial<ModelViewerWidgetProps> = {
    allowFullscreen: true,
  };

  static serializedConfigToProps(
    config: ModelViewerWidgetConfigSerialized,
  ): ModelViewerWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      modelUrl: config.model_url,
    } as ModelViewerWidgetProps);
  }

  canvas: HTMLCanvasElement;
  modelViewer: ModelViewer;

  constructor(props: ModelViewerWidgetProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    this.initModelViewer();
  }

  componentWillUnmount(): void {
    if (!isNil(this.modelViewer)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      RenderManager.removeRenderer(this.modelViewer);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.modelViewer.destroy();
      this.modelViewer = null;
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ModelViewerWidgetProps>,
    prevState: Readonly<ModelViewerWidgetState>,
    snapshot?: any,
  ): void {
    if (
      !isNil(prevProps?.modelUrl) &&
      !isEqual(prevProps.modelUrl, this.props.modelUrl)
    ) {
      this.initModelViewer();
    }
  }

  render() {
    return (
      <WidgetBox {...this.props} onFullscreen={() => {}} allowFullscreen>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <canvas
              ref={(canvas) => {
                this.canvas = canvas;
                this.initModelViewer();
              }}
            />
          </Grid>
        </Grid>
      </WidgetBox>
    );
  }

  private initModelViewer() {
    const momentLocale = moment.localeData();

    if (!isNil(this.modelViewer)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      RenderManager.removeRenderer(this.modelViewer);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.modelViewer.destroy();
      this.modelViewer = null;
    }

    if (isNil(this.canvas)) {
      return;
    }
    this.modelViewer = new ModelViewer($(this.canvas));
    RenderManager.addRenderer(this.modelViewer as Renderable);
    if (!isEmpty(this.props.modelUrl)) {
      this.modelViewer.load(this.props.modelUrl).catch((e) => {
        void toasts.error("Could not load model.", "Model Loading Error");
        logger.logError(e as Error);
      });
    }
  }
}

import { isEmpty } from "lodash";

export function widgetMinHeight(
  height: string,
  defaultHeight: string | number = null,
): number | string {
  if (isEmpty(height)) return defaultHeight;

  switch (height) {
    case "xsmall_height":
      return 100;
    case "small_height":
      return 150;
    case "medium_height":
      return 300;
    case "large_height":
      return 500;
    case "xlarge_height":
      return 700;
    case "xxlarge_height":
      return 900;
    default:
      return defaultHeight;
  }
}

import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { defaultTo, isNil, omit, pick, toString } from "lodash";
import { IDType } from "./urls/url_utils";

export type JSONApiFormRequestMode = "update" | "create";

export interface JSONAPISubmitData<ResourceAttributes extends JSON.Object> {
  submitData:
    | JSONAPI.SingleResourceDoc<string, Partial<ResourceAttributes>>
    | undefined;
  mode: JSONApiFormRequestMode;
}
export function buildJsonApiSubmitData<ResourceAttributes extends JSON.Object>(
  data: ResourceAttributes,
  jsonApiResourceType: string,
  restrictToKeys: Array<keyof ResourceAttributes> = null,
): JSONAPISubmitData<ResourceAttributes> {
  let mode: JSONApiFormRequestMode;
  // do not send id properties as these do not belong into the attributes section
  let attributes: Partial<ResourceAttributes> = omit<ResourceAttributes>(
    data,
    "id",
  );
  if (!isNil(restrictToKeys)) {
    attributes = pick(attributes, restrictToKeys);
  }
  const submitData: JSONAPI.SingleResourceDoc<
    string,
    Partial<ResourceAttributes>
  > = {
    data: {
      type: jsonApiResourceType,

      attributes: attributes,
    },
  };

  if (!isNil(data.id)) {
    submitData.data.id = toString(data.id);
    mode = "update";
  } else {
    mode = "create";
  }
  return { submitData, mode };
}

/** Adds a jsonapi relationship to the resource document. Mutates the resourceDoc
 *
 *
 * @export
 * @template ResourceAttributes Attributes of the given Resource
 * @param {JSONAPISubmitData<ResourceAttributes>} resourceDoc
 * @param {string} relationName Name of the jsonapi resource relationship. e.g, subtree or asset
 * @param {string} resourceType
 * @param {IDType} id
 * @param {boolean} setNullIfNotDefined
 * @return {*}
 */
export function addHasOneRelationToJsonApiSubmitData<
  ResourceAttributes extends JSON.Object,
>(
  resourceDoc: JSONAPI.SingleResourceDoc<string, Partial<ResourceAttributes>>,
  relationName: string,
  resourceType: string,
  id: IDType,
  setNullIfNotDefined: boolean,
) {
  const relationShips = defaultTo(resourceDoc.data.relationships, {});
  if (!isNil(id)) {
    relationShips[relationName] = jsonApiRelationship(id, resourceType);
  } else if (setNullIfNotDefined) {
    // this will cause the relation to be removed from the relation
    relationShips[relationName] = null;
  }
  resourceDoc.data.relationships = relationShips;
}

function jsonApiRelationship(
  id: IDType,
  resourceType: string,
): JSONAPI.RelationshipObject {
  if (isNil(id)) return null;

  return {
    data: {
      type: resourceType,
      id: toString(id),
    },
  };
}

import { createMutation, createQuery } from "react-query-kit";
import { IDType } from "../../utils/urls/url_utils";
import {
  buildEventPatternUpdateSubmitData,
  EVENT_PATTERN_JSONAPI_RESOURCE_TYPE,
  EventPatternJSONAPIAttributes,
  EventPatternJsonApiIncludes,
  EventPatternJSONObject,
} from "../../json_api/event_pattern";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiFilterParamsArray,
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import { loadDataFromUrl, sendJsonApiData } from "../../utils/jquery_helper";
import {
  api_event_pattern_path,
  api_event_patterns_path,
  api_sensor_data_transmission_event_pattern_path,
  api_sensor_data_transmission_event_patterns_path,
  api_sensor_event_pattern_path,
  api_sensor_event_patterns_path,
} from "../../routes";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil, toInteger } from "lodash";
import { EventPatternType } from "../../models/event_pattern";

interface EventPatternFilter {
  asset_id?: IDType;
  sensor_id?: IDType;
  condition?: string;
  current_execution_state?: string;
}

interface LoadEventPatternsQuery {
  page: number;
  pageSize: number;
  filter: EventPatternFilter;
  includes?: EventPatternJsonApiIncludes[];
}

type LoadEventPatternsResult = LoadItemsResult<EventPatternJSONObject>;

export const useEventPatternsQuery = createQuery<
  LoadEventPatternsResult,
  LoadEventPatternsQuery
>({
  queryKey: [EVENT_PATTERN_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ page, pageSize, filter, includes }) => {
    const doc = await loadDataFromUrl<
      CollectionResourceDoc<string, EventPatternJSONAPIAttributes>
    >(
      api_event_patterns_path({
        page: {
          number: (page || 0) + 1,
          size: pageSize,
        },
        filter: filter as unknown as Record<string, string>,
        include: includes?.join(","),
      }),
    );
    const objects = jsonApiResourceCollectionToFlatObjects(doc);
    return {
      items: objects,
      totalItems: toInteger(doc?.meta?.record_count),
      totalPages: toInteger(doc?.meta?.page_count),
    };
  },
  placeholderData: { items: [], totalItems: 0, totalPages: 0 },
});

export const useLoadEventPattern = createQuery<
  EventPatternJSONObject,
  { id: IDType; includes?: EventPatternJsonApiIncludes[] }
>({
  queryKey: [EVENT_PATTERN_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ id, includes }) => {
    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, EventPatternJSONAPIAttributes>
    >(api_event_pattern_path(id, { include: includes?.join(",") }));
    return jsonApiSingleResourceToFlatObject(doc);
  },
});

export const useDeleteEventPattern = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (id: IDType) => {
        return await sendJsonApiData<unknown, EventPatternJSONAPIAttributes>(
          api_event_pattern_path(id),
          null,
          "DELETE",
        );
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [EVENT_PATTERN_JSONAPI_RESOURCE_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};

export const createEventPatternQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (eventPattern: EventPatternJSONObject) => {
        const mode = isNil(eventPattern.id) ? "create" : "update";
        const submitData = buildEventPatternUpdateSubmitData(eventPattern);
        const url = submitUrl(mode, eventPattern.pattern_type, eventPattern.id);
        const response = await sendJsonApiData<
          unknown,
          SingleResourceDoc<string, EventPatternJSONObject>
        >(url, submitData.submitData, "POST");
        return jsonApiSingleResourceToFlatObject(response);
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [EVENT_PATTERN_JSONAPI_RESOURCE_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};

export const updateEventPatternQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (eventPattern: EventPatternJSONObject) => {
        const mode = "update";
        const submitData = buildEventPatternUpdateSubmitData(eventPattern);
        const url = submitUrl(mode, eventPattern.pattern_type, eventPattern.id);
        const response = await sendJsonApiData<
          unknown,
          SingleResourceDoc<string, EventPatternJSONObject>
        >(url, submitData.submitData, "PATCH");
        return jsonApiSingleResourceToFlatObject(response);
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [EVENT_PATTERN_JSONAPI_RESOURCE_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};

function submitUrl(
  mode: "create" | "update",
  patternType: EventPatternType,
  id: IDType,
): string {
  let url: string;
  if (mode == "create") {
    if (patternType == "EventPatterns::SensorEventPattern") {
      url = api_sensor_event_patterns_path({ format: "json" });
    } else if (
      patternType == "EventPatterns::SensorDataTransmissionEventPattern"
    ) {
      url = api_sensor_data_transmission_event_patterns_path({
        format: "json",
        _options: true,
      });
    }
  } else {
    if (patternType == "EventPatterns::SensorEventPattern") {
      url = api_sensor_event_pattern_path(id, { format: "json" });
    } else if (
      patternType == "EventPatterns::SensorDataTransmissionEventPattern"
    ) {
      url = api_sensor_data_transmission_event_pattern_path(id as string, {
        format: "json",
      });
    }
  }
  return url;
}

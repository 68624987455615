import * as React from "react";

import { noop } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { ExternalReferenceItemType } from "../../models/external_reference";
import { AppRoot } from "../common/app_root";
import { ExternalReferenceList } from "./external_references_list";

const externalReferecesListRoots: Root[] = [];
export function initializeExternalReferenceList(
  selector = $('[data-toggle="external-references-list"]'),
): void {
  $(selector).each((index, element) => {
    const referencedItemId = $(element).data("referenced-item-id") as string;
    const referencedItemType = $(element).data(
      "referenced-item-type",
    ) as ExternalReferenceItemType;
    const root = createRoot(element);
    externalReferecesListRoots.push(root);
    root.render(
      <AppRoot>
        <ExternalReferenceList
          referencedItemId={referencedItemId}
          referencedItemType={referencedItemType}
          onSelect={noop}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyExternalReferenceList(
  selector = $('[data-toggle="external-references-list"]'),
): void {
  externalReferecesListRoots.forEach((root) => {
    root.unmount();
  });
  externalReferecesListRoots.length = 0;
}

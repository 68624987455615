/// <reference types="../../definitions/bootstrap-daterangepicker" />;
import { TextField } from "@mui/material";
import { defaultTo, isEmpty, isEqual, isNil, isString, noop } from "lodash";
import moment from "../../initializers/moment";
import { Moment } from "moment";
import * as React from "react";
import {
  DateLike,
  DateRangePickerOptions,
} from "../../definitions/bootstrap-daterangepicker";

export type DatePickerHTMLElement = HTMLInputElement & {
  date: Moment;
};

export interface DatePickerProps {
  type?: "date" | "datetime";
  name?: string;
  autoApply?: boolean;
  label?: string;
  "aria-label"?: string;
  value?: Moment | string;
  dateFormat?: string;

  error?: string;
  maxDate?: Moment;
  minDate?: Moment;
  onChange?: (date: Moment) => void;
  required?: boolean;
  disabled?: boolean;

  pickSeconds?: boolean;

  noValueLabel?: string;
}

interface DatePickerState {
  initialValue: Moment;
  value: Moment;
}

/* interface DatePickerComponent extends React.Component<DatePickerProps, DatePickerState> {
  inputRef?: React.RefObject<HTMLInputElement>;
  componentDidMount(this: DatePickerComponent): void;
  componentDidUpdate(this: DatePickerComponent, prevProps: DatePickerProps, prevState: DatePickerState);
  onInputChange(this: DatePickerComponent, event: React.ChangeEvent<HTMLInputElement>);
} */

export interface MaterialUiDatePickerProps extends DatePickerProps {
  size: "small" | "medium";
  helperText?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}
/**
 * React wrapper for the boostrap-daterangepicker using material UI design
 */
export class MaterialUiDatePicker extends React.Component<
  MaterialUiDatePickerProps,
  DatePickerState
> {
  static defaultProps: MaterialUiDatePickerProps = {
    size: "small",
    dateFormat: "L",
    autoApply: false,

    required: false,
    noValueLabel: "",
    onChange: noop,
    pickSeconds: false,
  };

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: MaterialUiDatePickerProps) {
    super(props);
    this.inputRef = React.createRef();
    const v = moment.isMoment(props.value)
      ? props.value
      : moment(props.value, this.props.dateFormat);
    this.state = {
      initialValue: isNil(props.value) ? null : v,
      value: isNil(props.value) ? null : v,
    };
  }
  componentDidMount() {
    const momentLocale = moment.localeData();
    const datePickerOptions: DateRangePickerOptions = {
      singleDatePicker: true,
      timePicker: this.props.type === "datetime",
      showDropdown: true,
      timePicker24Hour: true,

      timePickerSeconds: this.props.pickSeconds,

      locale: {
        fromLabel: I18n.t("frontend.from"),
        toLabel: I18n.t("frontend.to"),
        applyLabel: I18n.t("frontend.apply"),
        cancelLabel: I18n.t("frontend.close"),
        format: this.props.dateFormat,
        monthNames: momentLocale.months(),
        daysOfWeek: momentLocale.weekdaysShort(),
        firstDay: momentLocale.firstDayOfWeek(),
      },
      autoApply: this.props.autoApply,
      autoUpdateInput: false,
    };

    // asure a valid start date as the time picker otherwise displays a NaN date.
    // This is only the value shown not the value selected until a apply was pressed
    if (moment.isMoment(this.state.value) && this.state.value.isValid()) {
      datePickerOptions.startDate = this.state.value;
    } else {
      datePickerOptions.startDate = moment();
    }

    if (moment.isMoment(this.props.maxDate) && this.props.maxDate.isValid()) {
      datePickerOptions.maxDate = defaultTo(this.props.maxDate, false);
    }
    if (moment.isMoment(this.props.minDate) && this.props.minDate.isValid()) {
      datePickerOptions.minDate = defaultTo(this.props.minDate, false);
    }

    $(this.inputRef.current).daterangepicker(
      datePickerOptions,
      (date: DateLike, endDate: DateLike, label: string) => {
        const d = moment(date);
        if (d.isValid()) {
          this.props.onChange(d);
        } else {
          this.props.onChange(null);
        }
      },
    );
  }
  componentDidUpdate(prevProps: DatePickerProps, prevState: DatePickerState) {
    let changed = false;

    const datePicker = $(this.inputRef.current).data("daterangepicker");
    const value = !moment.isMoment(this.props.value)
      ? moment(this.props.value, this.props.dateFormat)
      : this.props.value;
    if (
      !isEqual(prevState.initialValue, this.props.value) &&
      !prevState.initialValue?.isSame(value)
    ) {
      if (isNil(this.props.value)) {
        this.setState({ initialValue: null, value: null });
        return;
      }

      this.setState({
        initialValue: value,
        value,
      });

      if (value?.isValid()) {
        changed = true;
        datePicker.setStartDate(value);
      }
    }

    if (
      this.props.minDate != prevProps.minDate &&
      (isNil(this.props.minDate) ||
        (moment.isMoment(this.props.minDate) && this.props.minDate.isValid()))
    ) {
      changed = true;
      datePicker.minDate = defaultTo(this.props.minDate, false);
    }

    if (
      this.props.maxDate != prevProps.maxDate &&
      (isNil(this.props.minDate) ||
        (moment.isMoment(this.props.maxDate) && this.props.maxDate.isValid()))
    ) {
      changed = true;
      datePicker.maxDate = defaultTo(this.props.maxDate, false);
    }

    if (changed) datePicker.updateView();
  }
  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();
    const date = moment(event.target.value, this.props.dateFormat, true);

    // Only trigger change event if the entered date is valid
    if (date.isValid()) {
      this.props.onChange(date);

      // Always store changed value in state
      this.setState({
        ...this.state,
        value: date,
      });
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <TextField
          type="text"
          disabled={this.props.disabled}
          inputRef={this.inputRef}
          autoComplete="off"
          value={
            this.state.value
              ?.locale(I18n.locale)
              ?.format(this.props.dateFormat) ?? this.props.noValueLabel
          }
          label={this.props.label}
          size={defaultTo(this.props.size, "small")}
          //onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          //  this.onInputChange(event)
          //}
          name={this.props.name}
          error={!isEmpty(this.props.error)}
          helperText={
            isEmpty(this.props.error) ? this.props.helperText : this.props.error
          }
          required={this.props.required}
          fullWidth
          InputProps={{
            startAdornment: this.props.startAdornment,
            endAdornment: this.props.endAdornment,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </>
    );
  }
}

import { LocationCity } from "@mui/icons-material";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import React, { useEffect } from "react";

export interface TimezoneSelectProps
  extends Pick<
    TextFieldProps,
    "disabled" | "label" | "required" | "helperText" | "error" | "fullWidth"
  > {
  readOnly?: boolean;
  label?: string;
  selectedTimezoneKey?: string | null;
  timezoneFilterRegExp?: string | null;
  onChange?: (timezone: string) => void;
}

type CustomTimezone = {
  key: string;
  title: string;
};

export const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  selectedTimezoneKey: selectedTimezoneFromProps = "None",
  readOnly = false,
  timezoneFilterRegExp = "Europe",
  label = "Time Zone",
  ...props
}) => {
  //regionFilterExpression
  const allTimezones = Intl.supportedValuesOf("timeZone");
  const filteredTimezones = allTimezones.filter((entry: string) => {
    if (timezoneFilterRegExp) {
      return new RegExp(timezoneFilterRegExp).test(entry);
    } else {
      return entry;
    }
  });

  const [timezones, setTimezones] = React.useState<CustomTimezone[]>(
    filteredTimezones.map((fullEntry: string) => {
      // fullEntry is, e.g., "America/North_Dakota/Beulah" of "Europe/Berlin"
      const parts = fullEntry.split("/");
      const region = parts.length > 2 ? `${parts[0]}/${parts[1]}` : parts[0];
      const city = parts.length > 2 ? parts[2] : parts[1];
      return { key: city, title: fullEntry };
    }),
  );
  const [selectedTimezoneKey, setSelectedTimezoneKey] = React.useState<
    string | null
  >(selectedTimezoneFromProps);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedTimezoneKey || "None");
    }
  }, [selectedTimezoneKey, timezones]);

  return (
    <TextField
      inputProps={{ readOnly }}
      select
      label={label}
      value={selectedTimezoneKey || "None"}
      fullWidth={props.fullWidth}
      onChange={(e) => {
        setSelectedTimezoneKey(e.target.value);
      }}
    >
      <MenuItem value={"None"}>
        <em style={{ color: "gray" }}>
          {I18n.t("frontend.timezone_select.select_timezone")}
        </em>
      </MenuItem>
      {timezones.map((timezone) => (
        <MenuItem key={timezone.key} value={timezone.key}>
          {timezone.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

import { defaultTo, isNil } from "lodash";
import * as React from "react";

import { Chip } from "@mui/material";
import { EventSeverityLevel } from "../../models/event_notification";
import { Icon } from "./icon";

export interface SeverityLevelChipProps {
  severityLevel: EventSeverityLevel;
  icon?: boolean;
}

export const SeverityLevelChip: React.FunctionComponent<
  SeverityLevelChipProps
> = (props) => {
  let color: "info" | "warning" | "error" | "primary" = "primary";
  if (isNil(props.severityLevel)) return null;

  const lvl = defaultTo(props.severityLevel, "info");
  const title = I18n.t(
    `activerecord.attributes.notification_setting.severity_levels.${props.severityLevel}`,
  );
  switch (lvl) {
    case "alarm": {
      color = "error";
      break;
    }
    case "warn": {
      color = "warning";
      break;
    }
    case "info": {
      color = "info";
      break;
    }
    default: {
      color = "info";
    }
  }

  return (
    <Chip
      color={color}
      variant="outlined"
      size="small"
      label={title}
      icon={
        props.icon ? (
          <SeverityLevelIcon severityLevel={props.severityLevel} />
        ) : null
      }
    />
  );
};

export const SeverityLevelIcon: React.FunctionComponent<
  SeverityLevelChipProps
> = (props) => {
  const lvl = defaultTo(props.severityLevel, "info");
  const title = I18n.t(
    `activerecord.attributes.notification_setting.severity_levels.${lvl}`,
  );
  switch (lvl) {
    case "alarm": {
      return (
        <Icon
          icon="exclamation-triangle"
          className="text-danger"
          title={title}
        />
      );
    }
    case "warn": {
      return (
        <Icon
          icon="exclamation-triangle"
          className="text-warning"
          title={title}
        />
      );
    }
    case "info": {
      return <Icon icon="info-circle" className="text-info" title={title} />;
    }
    default: {
      return <Icon icon="info-circle" className="text-info" title={title} />;
    }
  }
};

export { ProductModelForm } from "./product_model_form";
export { ProductModelList } from "./product_model_list";
import * as JSONAPI from "jsonapi-typescript";
import { isNil, toNumber } from "lodash";
import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { ProductModelModelFor } from "../../models/product_model";
import { AppRoot } from "../common/app_root";
import { ProductModelForm } from "./product_model_form";
import { ProductModelList } from "./product_model_list";
const productModelListRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeProductModelList(
  selector: JQuery = $('[data-toggle="product-model-list"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);
    const assetTypeDoc = jqElement.data(
      "asset-type",
    ) as JSONAPI.SingleResourceDoc<string, AssetTypeJSONObject>;
    const canEdit = jqElement.data("can-edit") as boolean;
    const assetType = jsonApiSingleResourceToFlatObject(assetTypeDoc);
    const modelFor = jqElement.data("model-for") as ProductModelModelFor;
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <ProductModelList
          assetType={assetType}
          assetTypeId={assetType?.id}
          modelFor={modelFor}
          canEdit={canEdit}
        />
      </AppRoot>,
    );
    productModelListRoots.push(root);
  });
}

export function destroyProductModelList(): void {
  productModelListRoots.forEach((root) => {
    root.unmount();
  });
  productModelListRoots.length = 0;
}

const productModelFormRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeProductModelForm(
  selector: JQuery = $('[data-toggle="product-model-form"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);
    const productModelId = jqElement.data("product-model-id") as string;
    const readOnly = jqElement.data("read-only") as boolean;
    const canEdit = jqElement.data("can-edit") as boolean;
    const assetTypeDoc = jqElement.data(
      "asset-type",
    ) as JSONAPI.SingleResourceDoc<string, AssetTypeJSONObject>;

    const assetType = jsonApiSingleResourceToFlatObject(assetTypeDoc);
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <ProductModelForm
          productModelId={
            isNil(productModelId) ? null : toNumber(productModelId)
          }
          readOnly={readOnly}
          canEdit={canEdit}
          assetTypeId={assetType?.id}
          assetType={assetType}
        />
      </AppRoot>,
    );
    productModelFormRoots.push(root);
  });
}

export function destroyProductModelForm(): void {
  productModelFormRoots.forEach((root) => {
    root.unmount();
  });
  productModelFormRoots.length = 0;
}

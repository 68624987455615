import { forEach } from "lodash";
import {
  AssetTemplateTree,
  AssetTemplateWithSensorProps,
} from "../models/models";

export function forEachAssetTemplate(
  tree: AssetTemplateTree,
  cb: (config: AssetTemplateWithSensorProps) => void,
) {
  cb(tree);
  forEach(tree.children, (childTree) => {
    forEachAssetTemplate(childTree, cb);
  });
}

import { IDType } from "../utils/urls/url_utils";
import { NamedItem } from "./base";

export const ASSET_SHORT_NAME_MAX_LENGTH = 20;

export interface Asset extends NamedItem {
  id?: IDType;
  key?: string;
  name_de?: string;
  name_en?: string;
  name?: string;
  root_id?: IDType;
  serial?: string;
  asset_type_id?: number;
  asset_type_name?: string;
  operator_id?: number;
  timezone?: string;
  created_at?: string;
  updated_at?: string;
}

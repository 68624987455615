import {
  api_asset_file_attachments_path,
  api_file_attachment_path,
  api_file_attachments_path,
  api_product_model_file_attachments_path,
} from "../../routes";
import { IDType } from "./url_utils";

export function apiFileAttachmentsPath(
  itemId: IDType = null,
  itemType: string = null,
): string {
  let url = null;
  if (itemType == "Asset") {
    url = api_asset_file_attachments_path(itemId);
  } else if (itemType == "ProductModel") {
    url = api_product_model_file_attachments_path(itemId);
  } else {
    url = api_file_attachments_path();
  }

  return url;
}

export function apiFileAttachmentPath(id: IDType): string {
  return api_file_attachment_path(id);
}

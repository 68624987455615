import swal from "sweetalert2";
const swal2 = require("sweetalert2");
export const dialog: typeof swal = swal2.mixin({
  allowOutsideClick: true,
  showClass: {
    popup: "animated fadeIn faster",
  },
  hideClass: {
    popup: "animated fadeOut faster",
  },
});

import JSON from "json-typescript";

import { MqttUser } from "../models/mqtt_user";

export const MQTT_USER_JSON_API_TYPE = "mqtt_users";
interface MqttUserJsonApiAttributes extends Partial<MqttUser> {}

export const MQTT_USER_JSON_API_CREATE_ATTRIBUTES: (
  | keyof MqttUser
  | "password_in_clear_text"
)[] = ["username", "enabled", "superuser", "password_in_clear_text"];

export const MQTT_USER_JSON_API_UPDATE_ATTRIBUTES =
  MQTT_USER_JSON_API_CREATE_ATTRIBUTES;

export type MqttUserIncludes = "device" | "asset" | "user";
export interface MqttUserJSONObject
  extends MqttUserJsonApiAttributes,
    JSON.Object {
  mqtt_acls?: { topic: string; access: string }[];
}

import FileIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { SvgIconProps } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
export function iconForContentType(
  contentType: string,
  addProps: SvgIconProps = {},
): React.ReactElement {
  if (isNil(contentType)) {
    return <FileIcon {...addProps} />;
  } else if (contentType === "application/pdf") {
    return <PDFIcon {...addProps} />;
  } else if (contentType.startsWith("image/")) {
    return <ImageIcon {...addProps} />;
  } else {
    return <FileIcon {...addProps} />;
  }
}

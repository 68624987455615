import {
  defaultTo,
  isEmpty,
  isNil,
  isNumber,
  isPlainObject,
  isString,
} from "lodash";
import * as React from "react";

import { getValueString } from "../../utils/value_format";
import { FaSizeType } from "./icon";
import { getIconForName } from "./icon_for_name";

import { Box, Chip, Grid, Typography, useTheme } from "@mui/material";
import { unitDisplayString } from "../../utils/unit_conversion";
import { Sensors } from "@mui/icons-material";
import { WidgetTimestampGridItem } from "../widgets/widget_timestamp";

export interface ValueDisplayProps {
  value?: string | number | { x: number; y: number; z?: number };
  hideValue?: boolean;
  vertical?: boolean;
  timestamp?: string;
  precision?: number;
  unit?: string;
  sensorType?: string;
  measurementType?: string;
  iconName?: string;
  iconSize?: FaSizeType;
  color?: string;
  status?: string;
  mode?: "inline" | "rows";
  shadowText?: boolean;
}

function getValueClassName(props: ValueDisplayProps): string {
  if (isNumber(props.value)) {
    return "value number";
  } else if (isString(props.value)) {
    return "value string";
  } else if (isPlainObject(props.value)) {
    return "value location";
  } else {
    //may be nil or undefined
    return "value";
  }
}

export const ValueDisplay: React.FunctionComponent<ValueDisplayProps> = (
  props: ValueDisplayProps,
) => {
  return props.mode === "inline"
    ? InlineValueDisplay(props)
    : BlockValueDisplay(props);
};

export const InlineValueDisplay: React.FunctionComponent<ValueDisplayProps> = (
  props: ValueDisplayProps,
) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <span className={getValueClassName(props)}>
          {getValueString(props.value, props.precision)}
        </span>
        <span className="unit ml-1">{unitDisplayString(props.unit)}</span>
      </Grid>
      {isNil(props.timestamp) ? null : (
        <Grid item xs={12} textAlign="center">
          <Typography variant="caption">{props.timestamp}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const BlockValueDisplay: React.FunctionComponent<ValueDisplayProps> = (
  props: ValueDisplayProps,
) => {
  const theme = useTheme();
  return (
    <Grid
      container
      className={props.shadowText ? "text-shadow" : null}
      justifyContent={props.vertical ? "center" : "space-between"}
      direction="row"
    >
      <Grid
        item
        xs={props.vertical == true ? 12 : "auto"}
        m={1}
        textAlign={props.vertical ? "center" : "right"}
        color={props.color ?? theme.palette.primary.main}
      >
        {isNil(props.iconName) ? (
          props.hideValue ? (
            <Sensors fontSize="large" />
          ) : null
        ) : (
          getIconForName(props.iconName, defaultTo(props.iconSize, "2x"))
        )}
      </Grid>

      {props.hideValue ? null : (
        <Grid
          item
          container
          color={props.color}
          xs={props.vertical == true ? 12 : "auto"}
          className={props.vertical == true ? "" : "my-auto"}
          justifyContent={props.vertical == true ? "center" : "flex-end"}
          minHeight="3em"
        >
          <Typography
            variant="h5"
            textAlign={props.vertical ? "center" : "right"}
            className="value"
          >
            <Box component="span" className={getValueClassName(props)}>
              {getValueString(props.value, props.precision)}
            </Box>
            <Box component="span" marginLeft={1} className="unit">
              {unitDisplayString(props.unit)}
            </Box>
          </Typography>
        </Grid>
      )}
      {isNil(props.timestamp) && false ? null : (
        <WidgetTimestampGridItem
          timestamp={props.timestamp}
          align={props.vertical == true ? "center" : "right"}
        />
      )}

      {isNil(props.status) ? null : (
        <Grid
          item
          xs={12}
          className="mb-3 mt-1"
          color={props.color}
          justifyContent="center"
          textAlign={props.vertical ? "center" : "right"}
        >
          <Chip
            icon={
              isEmpty(props.iconName)
                ? null
                : getIconForName(props.iconName, "1x")
            }
            variant="outlined"
            label={props.status}
          />
        </Grid>
      )}
    </Grid>
  );
};

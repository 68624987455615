/********
 * ASSETS
 *******/

import { isEmpty } from "lodash";
import { AssetIncludes } from "../../json_api/asset";
import {
  IDType,
  ParamsType,
  RequestFormat,
  applyParamsToBaseUrl,
  formatString,
  localizedBasePath,
} from "./url_utils";

export function assetPath(
  assetId?: IDType,
  format: RequestFormat = "",
): string {
  return `${localizedBasePath(
    format === "json",
  )}/assets/${assetId}${formatString(format)}`;
}

/*******************
 * JSON API
 */

export function assetsJsonApiPath(
  pageNumber: number,
  pageSize: number,
  include: AssetIncludes[] = [],
  params: ParamsType = [],
): string {
  const url = `${localizedBasePath(true)}/assets`;
  return applyParamsToBaseUrl(url, pageNumber, pageSize, include, params);
}

export function assetsJsonApiSearchPath(
  searchTerm: string,
  pageNumber: number,
  pageSize: number,
  include: AssetIncludes[] = [],

  params: ParamsType = [],
): string {
  const url = `${localizedBasePath(true)}/assets/search`;
  return applyParamsToBaseUrl(
    url,
    pageNumber,
    pageSize,
    include,
    params.concat([["search", searchTerm]] as ParamsType),
  );
}

export function assetTypeAssetsApiPath(
  assetTypeId: IDType,
  pageNumber: number,
  pageSize: number,
  include: AssetIncludes[] = [],
  params?: ParamsType,
): string {
  if (isEmpty(assetTypeId)) throw "Asset Type Id not provided";

  const url = `${localizedBasePath(true)}/asset_types/${assetTypeId}/assets`;

  return applyParamsToBaseUrl(url, pageNumber, pageSize, include, params);
}

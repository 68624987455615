import Widget from "./widget";
import { WidgetConfigSerialized } from "./widget.types";

interface ErrorWidgetConfigSerialized extends WidgetConfigSerialized {
  errors?: Record<string, string>;
}
/**
 * Javascript implementation of the ErrorWidget.
 *
 * @class ErrorWidget
 * @extends {Widget}
 */
export default class ErrorWidget extends Widget {
  static getDomClassName(): string {
    return "error-widget";
  }

  static widgetClassName(): string {
    return "ErrorWidget";
  }

  errors: Record<string, string>;

  readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as ErrorWidgetConfigSerialized;
    this.errors = config.errors;
  }

  cleanup(): void {
    super.cleanup();
  }
}

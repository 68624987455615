import { isEmpty, isNil } from "lodash";
import * as React from "react";

import { MeasurementPlanContainer } from "./containers/measurement_plan_container";
import {
  FormMode,
  loadInitialState,
  resetState,
} from "./data/measurement_plan_actions";

import { Root, createRoot } from "react-dom/client";
import { MeasurementCategorization } from "../../models/measurement_categorization";
import {
  MeasurementPlan,
  MeasurementPlanWithErrors,
} from "../../models/measurement_plan";
import { MeasurementType } from "../../models/measurement_type";
import { AppRoot } from "../common/app_root";

const measurementPlanFormContainerRoots: Root[] = [];
/**
 * Initialize react component MeasurementPlanContainer within all elements with data-toggle="measurement-plan-form".
 * Initial state is loaded fron "data-assets" and "data-measurement-types".
 * State is expected to be in JSON format.
 */
export function initializeMeasurementPlanFormContainer(selector: JQuery): void {
  const theSelector = !isEmpty(selector)
    ? selector
    : $('[data-toggle="measurement-plan-form"]');
  theSelector.each((index, element) => {
    const root = createRoot(element);
    measurementPlanFormContainerRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementPlanContainer />
      </AppRoot>,
    );

    const assetId = $(element).data("asset-id") as number;
    const measurementPlan: MeasurementPlanWithErrors = ($(element).data(
      "measurement-plan",
    ) as MeasurementPlan) ?? { errors: {}, measurement_type: undefined };
    const measurementTypes: MeasurementType[] =
      ($(element).data("measurement-types") as MeasurementType[]) ?? [];
    const measurementCategorizations: MeasurementCategorization[] =
      ($(element).data(
        "measurement-categorizations",
      ) as MeasurementCategorization[]) ?? [];
    const submitUrl: string = $(element).data("submit-url") as string;
    const submitMethod: "POST" | "PATCH" = $(element).data("submit-method") as
      | "POST"
      | "PATCH";
    const editable: boolean = $(element).attr("data-editable") !== "false";

    let mode: FormMode = "create";
    if (!editable) {
      mode = "show";
    } else if (!isNil(measurementPlan?.id)) {
      mode = "edit";
    }

    loadInitialState(
      assetId,
      measurementPlan,
      mode,
      measurementTypes,
      measurementCategorizations,
      submitUrl,
      submitMethod,
    );
  });
}

/**
 * Remove react component from all elements with data-toggle="measurement-plan-form"
 */
export function destroyMeasurementPlanFormContainer(): void {
  measurementPlanFormContainerRoots.forEach((root) => {
    root.unmount();
  });
  measurementPlanFormContainerRoots.length = 0;
  resetState();
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridDensity,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import {
  compact,
  defaultTo,
  filter,
  isEmpty,
  isNil,
  map,
  max,
  min,
  uniq,
} from "lodash";
import * as React from "react";

import {
  Add,
  AddCircleOutline,
  Check,
  Close,
  Computer,
  Edit,
  KeyboardArrowLeft,
  LineAxis,
  MonitorHeart,
  Notifications,
  NotificationsOff,
  Print,
  RestoreFromTrash,
  ShowChart,
  Speed,
  SpeedSharp,
  Translate,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Fab,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AssetJSONObject, loadAsset } from "../../json_api/asset";
import {
  SensorFilter as SensorFilterInterface,
  SensorJSONAPIAttributes,
} from "../../json_api/sensor";

import {
  admin_translate_item_form_path,
  asset_admin_manage_sensor_data_path,
  asset_path,
  asset_sensor_path,
  asset_sensor_sensor_event_patterns_path,
  edit_asset_sensor_path,
  multi_sensor_asset_path,
  new_asset_sensor_path,
  new_asset_sensor_sensor_event_pattern_path,
  sensor_admin_sensor_attributes_path,
} from "../../routes";
import { getTranslatedProp } from "../../utils/globalize";

import { getIconForSensorTypeOrMeasurementType } from "../../utils/sensor_icons";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingWrapper } from "../common/loading_wrapper";
import { SensorValueWidget } from "../widgets/sensor_value_widget";
import { SensorDisplay } from "./sensor_display";

import { useQuery } from "@tanstack/react-query";
import { EventPatternStateColors } from "../../models/event_pattern";
import { ResourcePermission } from "../../models/resource_permission";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";
import { redirectTo } from "../../utils/redirection";
import { AppContext } from "../common/app_context/app_context_provider";
import { CopyValueIcon } from "../common/copy_value";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { PageSettings } from "../common/page_size";
import { SialogicDialog } from "../common/sialogic_dialog";
import { useSeverityStyles } from "../styling/theme";
import { ProcessedSensorsAttributes, useLoadSensors } from "./sensor_data";
import { SensorFilter } from "./sensor_filter";
import { useDebounce } from "@uidotdev/usehooks";
import { DateRange } from "moment-range";

export interface SensorListProps {
  // add more properties here
  dataUpdateEnabled?: boolean;
  enableSearch?: boolean;
  pageSize?: number;
  permissions?: ResourcePermission;
  assetPermissions?: ResourcePermission;
  assetId?: IDType;
  sensors?: SensorJSONAPIAttributes[];
  density?: GridDensity;
  fabButtons?: boolean;
  onSensorSelect?: (sensor?: SensorJSONAPIAttributes) => void;
  onAssetSelect?: (asset?: AssetJSONObject) => void;
}

const defaultWidth = 160;
const smallWidth = 50;
const largeWidth = 200;

function getGridColDef(
  setShowSensors: (sensors: Array<SensorJSONAPIAttributes>) => void,
  admin?: boolean,
): GridColDef<ProcessedSensorsAttributes>[] {
  const colDefs = [
    {
      field: "id",
      headerName: "ID",
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: I18n.t("activerecord.attributes.sensor.name"),
      width: largeWidth,
      flex: 0.6,

      valueGetter: (value, row) => getTranslatedProp(row, "name"),
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "short_name",
      headerName: I18n.t("activerecord.attributes.sensor.short_name"),
      width: largeWidth,
      flex: 0.4,
      valueGetter: (value, row) => getTranslatedProp(row, "short_name"),
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "asset",
      headerName: I18n.t("activerecord.attributes.sensor.asset"),
      flex: 0.5,
      align: "center",
      minWidth: defaultWidth,
      valueGetter: (value, row) => getTranslatedProp(row.asset, "name"),
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          <Box
          // necessary to prevent the link from being as wide as the cell
          >
            <Tooltip title={params.formattedValue}>
              <Link href={asset_path(params.row.asset_id)} variant="body2">
                {params.formattedValue}
              </Link>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
    {
      field: "sensor_type",
      headerName: I18n.t("activerecord.attributes.sensor.sensor_type"),
      width: defaultWidth,
      align: "center",
      headerAlign: "center",
      type: "string",
      flex: 0.4,
      valueGetter: (value, row) => {
        const typeName = row.sensor_type_name;
        if (isEmpty(typeName)) {
          return "---";
        } else {
          return getSensorTypeNameTranslation(typeName);
        }
      },
      renderCell: (params) => {
        const typeName = params.row.sensor_type_name;
        const icon = getIconForSensorTypeOrMeasurementType(
          params.row.sensor_type_name,
          params.row.measurement_type,
        );
        return (
          <Box
            display={"flex"}
            justifyContent="center"
            flexDirection="column"
            height="100%"
          >
            {icon ? <Box>{icon}</Box> : null}
            <Typography textOverflow={"ellipsis"} variant="caption">
              {params.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "measurement_type",
      headerName: I18n.t("activerecord.attributes.sensor.measurement_type"),
      minWidth: smallWidth,
      align: "center",
      headerAlign: "center",
      flex: 0.4,
      valueGetter: (value, row) => {
        const typeName = row.measurement_type;
        if (isEmpty(typeName)) return "---";

        return I18n.t(
          `activerecord.attributes.sensor_type.measurement_types.${typeName}`,
          {
            defaultValue: typeName,
          },
        );
      },
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },

    {
      field: "current_value",
      headerAlign: "center",
      headerName: I18n.t("activerecord.attributes.sensor.current_value"),
      align: "center",
      minWidth: defaultWidth,
      flex: 0.5,
      type: "number",
      valueGetter: (value, row) => row.last_value?.value,
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          <SensorValueWidget
            sensor={params.row}
            mode="inline"
            updateEnabled
            sensorId={""}
          />
        </Box>
      ),
    },
    {
      field: "key",
      headerAlign: "center",
      headerName: I18n.t("activerecord.attributes.attribute_key.key"),
      align: "center",
      flex: 0.5,

      renderCell: ({ value }) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          <Box>
            {value}
            {<CopyValueIcon sx={{ marginLeft: 1 }} value={value} />}
          </Box>
        </Box>
      ),
    },
    {
      field: "sensor_context",
      flex: 0.5,
      headerAlign: "center",
      headerName: I18n.t("activerecord.attributes.sensor.context"),
      align: "center",
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {isEmpty(params.formattedValue) ? (
            "---"
          ) : (
            <Box>
              {params.formattedValue}
              {
                <CopyValueIcon
                  sx={{ marginLeft: 1 }}
                  value={params.formattedValue}
                />
              }
            </Box>
          )}
        </Box>
      ),
    },
    {
      field: "sensor_context2",
      flex: 0.5,

      headerAlign: "center",
      headerName: I18n.t("activerecord.attributes.sensor.context2"),
      align: "center",

      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {isEmpty(params.formattedValue) ? (
            "---"
          ) : (
            <Box>
              {params.formattedValue}
              {
                <CopyValueIcon
                  sx={{ marginLeft: 1 }}
                  value={params.formattedValue}
                />
              }
            </Box>
          )}
        </Box>
      ),
    },
    {
      field: "enabled",
      align: "center",
      headerName: I18n.t("activerecord.attributes.sensor.enabled"),
      flex: 0.2,
      type: "boolean",
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {isNil(params.row.enabled) || params.row.enabled ? (
            <Tooltip title={I18n.t("activerecord.attributes.sensor.enabled")}>
              <Check fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title={I18n.t("activerecord.attributes.sensor.disabled")}>
              <Close fontSize="small" />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: "derived",
      align: "center",
      headerName: I18n.t("activerecord.attributes.sensor.measured"),
      flex: 0.2,
      type: "boolean",
      renderCell(params) {
        return (
          <Box
            display={"flex"}
            justifyContent="center"
            flexDirection="column"
            height="100%"
          >
            {isNil(params.row.derived) || params.row.derived ? (
              <Tooltip
                title={I18n.t("activerecord.attributes.sensor.computed")}
              >
                <Computer fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip
                title={I18n.t("activerecord.attributes.sensor.measured")}
              >
                <Speed fontSize="small" />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "event_patterns",
      headerName: I18n.t(
        "activerecord.models.event_patterns/event_pattern.other",
      ),

      width: smallWidth,
      align: "center",
      flex: 0.25,
      type: "number",
      valueGetter: (value, row) => row.eventPatternsEventOccurredCount ?? 0,
      renderCell: ({ row: sensor }) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {sensor.eventPatternsCount != 0 &&
          !isNil(sensor.eventPatternsCount) ? (
            <Tooltip
              title={`${sensor.eventPatternsCount} ${I18n.t(
                "activerecord.models.event_patterns/event_pattern.other",
              )} (${I18n.t("frontend.sensors.sensor_list.triggered_patterns", { count: sensor.eventPatternsEventOccurredCount })})`}
            >
              <Link
                fontSize="small"
                underline="hover"
                href={asset_sensor_sensor_event_patterns_path(
                  sensor.asset_id,
                  sensor.id,
                )}
              >
                <Chip
                  variant="outlined"
                  size="small"
                  icon={
                    sensor.eventPatternsEventOccurredCount != 0 ? (
                      <Notifications />
                    ) : (
                      <NotificationsOff />
                    )
                  }
                  style={{
                    backgroundColor:
                      sensor.eventPatternsEventOccurredCount != 0
                        ? EventPatternStateColors.event_occurred
                        : null,
                  }}
                  label={`${sensor.eventPatternsEventOccurredCount} / ${sensor.eventPatternsCount}`}
                />
              </Link>
            </Tooltip>
          ) : (
            <Tooltip
              title={I18n.t("frontend.sensors.sensor_list.add_monitoring")}
            >
              <Link
                fontSize="small"
                underline="hover"
                onClick={(e) => {
                  e.stopPropagation();
                  redirectTo(
                    new_asset_sensor_sensor_event_pattern_path(
                      sensor.asset_id,
                      sensor.id,
                    ),
                  );
                }}
                onAuxClick={(e) => {
                  e.stopPropagation();
                  redirectTo(
                    new_asset_sensor_sensor_event_pattern_path(
                      sensor.asset_id,
                      sensor.id,
                    ),
                    "_blank",
                  );
                }}
              >
                <AddCircleOutline fontSize="small" />
              </Link>
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: "description",
      headerName: I18n.t("activerecord.attributes.sensor.description"),
      width: defaultWidth,
      flex: 0.5,
      type: "string",
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "serial",
      headerName: I18n.t("activerecord.attributes.sensor.serial"),
      flex: 0.5,
      width: defaultWidth,
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "created_at",
      headerName: I18n.t("activerecord.attributes.base.created_at"),
      minWidth: defaultWidth,
      flex: 0.5,
      type: "dateTime",

      valueGetter: (value, row) => new Date(row.created_at),
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "updated_at",
      headerName: I18n.t("activerecord.attributes.base.updated_at"),
      minWidth: defaultWidth,
      flex: 0.5,
      type: "dateTime",

      valueGetter: (value, row) => new Date(row.updated_at),
      renderCell: (params) => (
        <Box
          display={"flex"}
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          {params.formattedValue}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: I18n.t("frontend.actions"),
      filterable: false,
      align: "right",
      type: "actions",
      flex: 0.3,

      getActions: (params) => {
        const actions = [
          <Tooltip
            title={I18n.t("frontend.sensors.sensor_list.show_chart")}
            key="show_sensors"
          >
            <GridActionsCellItem
              color="primary"
              key="show_chart"
              icon={<ShowChart />}
              label={I18n.t("frontend.sensors.sensor_list.show_chart")}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (e.ctrlKey || e.metaKey) {
                  redirectTo(
                    multi_sensor_asset_path(params.row.asset_id, params.row.id),
                    "_blank",
                  );
                } else {
                  setShowSensors([params.row]);
                }
              }}
              onAuxClick={(e: React.MouseEvent) => {
                e.preventDefault();
                redirectTo(
                  multi_sensor_asset_path(params.row.asset_id, params.row.id),
                  "_blank",
                );
              }}
            />
          </Tooltip>,
          <GridActionsCellItem
            color="primary"
            showInMenu
            key="show"
            icon={<Visibility />}
            component={Link}
            label={I18n.t("frontend.sensors.sensor_list.sensor_home")}
            onClick={(event) => {
              const link = asset_sensor_path(
                params.row.asset_id,
                params.row.id,
              );
              if (event.ctrlKey || event.metaKey) {
                redirectTo(link, "_blank");
              } else {
                redirectTo(link);
              }
            }}
            onAuxClick={() => {
              redirectTo(
                asset_sensor_path(params.row.asset_id, params.row.id),
                "_blank",
              );
            }}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<Add />}
            key="add_monitoring"
            label={I18n.t("frontend.sensors.sensor_list.add_monitoring")}
            onClick={(event) => {
              const link = new_asset_sensor_sensor_event_pattern_path(
                params.row.asset_id,
                params.row.id,
              );
              if (event.ctrlKey || event.metaKey) {
                redirectTo(link, "_blank");
              } else {
                redirectTo(link);
              }
            }}
            onAuxClick={(e) => {
              redirectTo(
                new_asset_sensor_sensor_event_pattern_path(
                  params.row.asset_id,
                  params.row.id,
                ),
                "_blank",
              );
            }}
            showInMenu
          />,
          <GridActionsCellItem
            color="primary"
            showInMenu
            key="show_monitoring"
            icon={<MonitorHeart />}
            label={I18n.t(
              "activerecord.models.event_patterns/sensor_event_pattern.other",
            )}
            onClick={(event) => {
              const link = asset_sensor_sensor_event_patterns_path(
                params.row.asset_id,
                params.row.id,
              );
              if (event.ctrlKey || event.metaKey) {
                redirectTo(link, "_blank");
              } else {
                redirectTo(link);
              }
            }}
            onAuxClick={() => {
              redirectTo(
                asset_sensor_sensor_event_patterns_path(
                  params.row.asset_id,
                  params.row.id,
                ),
                "_blank",
              );
            }}
          />,
          <GridActionsCellItem
            color="primary"
            showInMenu
            key="edit"
            icon={<Edit />}
            label={I18n.t("frontend.sensors.sensor_list.edit_sensor")}
            onClick={(event) => {
              const link = edit_asset_sensor_path(
                params.row.asset_id,
                params.row.id,
              );
              if (event.ctrlKey || event.metaKey) {
                redirectTo(link, "_blank");
              } else {
                redirectTo(link);
              }
            }}
            onAuxClick={() => {
              redirectTo(
                edit_asset_sensor_path(params.row.asset_id, params.row.id),
                "_blank",
              );
            }}
          />,
        ];
        if (admin) {
          actions.push(
            <GridActionsCellItem
              color="secondary"
              showInMenu
              key="sensor_values"
              icon={<SpeedSharp />}
              label={I18n.t("frontend.sensors.sensor_list.sensor_values")}
              onClick={(event) => {
                const link = sensor_admin_sensor_attributes_path(params.row.id);
                if (event.ctrlKey || event.metaKey) {
                  redirectTo(link, "_blank");
                } else {
                  redirectTo(link);
                }
              }}
              onAuxClick={() => {
                redirectTo(
                  sensor_admin_sensor_attributes_path(params.row.id),
                  "_blank",
                );
              }}
            />,
          );
          actions.push(
            <GridActionsCellItem
              color="secondary"
              showInMenu
              key="delete_restore_sensor_data"
              icon={<RestoreFromTrash />}
              label={I18n.t(
                "frontend.sensors.sensor_list.delete_restore_sensor_data",
              )}
              onClick={(event) => {
                const link = asset_admin_manage_sensor_data_path(
                  params.row.asset_id,
                );
                if (event.ctrlKey || event.metaKey) {
                  redirectTo(link, "_blank");
                } else {
                  redirectTo(link);
                }
              }}
              onAuxClick={() => {
                redirectTo(
                  asset_admin_manage_sensor_data_path(params.row.asset_id),
                  "_blank",
                );
              }}
            />,
          );
          actions.push(
            <GridActionsCellItem
              key={"translate_sensor"}
              color="secondary"
              showInMenu
              icon={<Translate />}
              label={I18n.t("frontend.sensors.sensor_list.translate_sensor")}
              onClick={(event) => {
                const link = admin_translate_item_form_path(
                  "Sensor",
                  params.row.id,
                );
                if (event.ctrlKey || event.metaKey) {
                  redirectTo(link, "_blank");
                } else {
                  redirectTo(link);
                }
              }}
              onAuxClick={() => {
                redirectTo(
                  admin_translate_item_form_path("Sensor", params.row.id),
                  "_blank",
                );
              }}
            />,
          );
        }
        return actions;
      },
    },
  ] as GridColDef<ProcessedSensorsAttributes>[];

  return colDefs;
}

export const SensorList: React.FunctionComponent<SensorListProps> = ({
  pageSize = 50,
  fabButtons = false,
  ...props
}) => {
  const [sensorFilter, setSensorFilter] = React.useState<SensorFilterInterface>(
    {
      asset: null,
    },
  );

  const [pageSettings, setPageSettings] = React.useState<PageSettings>({
    number: 1,
    size: defaultTo(pageSize, 30),
  });

  const [selectableAssets, setSelectableAssets] =
    React.useState<Array<AssetJSONObject | null>>(null);

  const [showSensorsTimeRange, setShowSensorsTimeRange] =
    React.useState<DateRange>();

  const [showSensors, setShowSensors] =
    React.useState<SensorJSONAPIAttributes[]>();

  const [showDialogProps, setShowDialogProps] = React.useState({
    open: false,
    fullscreen: false,
  });

  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const assetQuery = useQuery({
    queryKey: ["assetSubtree", props.assetId],
    queryFn: () => loadAsset(props.assetId, ["subtree"]),
  });

  // set the time range for the sensors to the last month if no time range is set
  React.useEffect(() => {
    // if there are sensors to show and no time range is set yet
    if (showSensors && !showSensorsTimeRange) {
      const lastValueTimes = compact(
        map(showSensors, (s) =>
          s.last_value?.timestamp
            ? new Date(s.last_value?.timestamp).valueOf()
            : null,
        ),
      );
      const minTime = min(lastValueTimes);
      const maxTime = max(lastValueTimes);
      if (minTime && maxTime) {
        const newDateRange = new DateRange(
          (minTime ? moment(minTime) : moment()).startOf("day"),
          (maxTime ? moment(maxTime) : moment()).endOf("day"),
        );
        if (newDateRange.duration("months") > 1) {
          // limit to one month if the range is too large
          newDateRange.start = moment().startOf("month");
        }
        setShowSensorsTimeRange(newDateRange);
      }
    }
  }, [showSensors]);

  React.useEffect(() => {
    if (assetQuery.data?.subtree) {
      const asset = assetQuery.data;
      setSensorFilter({ ...sensorFilter, asset: asset.subtree_ids });

      // check if a search parameter is present in the URL
      const searchFromUrl = new URLSearchParams(window.location.search).get(
        "search",
      );
      if (searchFromUrl) {
        const sanitizedSearch = searchFromUrl.replace(
          /[^a-zA-Zäöü0-9_\- ]/g,
          "",
        );
        setSensorFilter({ ...sensorFilter, search: sanitizedSearch });
      }
      // we have to create new object since there are circular dependencies in the result object from json api

      const theSelectableAssets = asset.subtree.map((a) => ({
        id: a.id,
        root: {
          id: asset.id,
          root_id: asset.id,
          name: asset?.name,
          asset_type_name: asset.asset_type_name,
          asset_type_id: asset.asset_type_id,
        },
        name: a.name,
        asset_type_name: a.asset_type_name,
      }));
      setSelectableAssets(theSelectableAssets);
    }
  }, [assetQuery.data]);

  const sensorsQuery = useLoadSensors({
    variables: {
      assetId: props.assetId,
      page: pageSettings.number,
      pageSize: pageSettings.size,
      filter: sensorFilter,
      includes: ["asset", "sensor_type", "event_patterns"],
    },
    placeholderData: {
      sensors: props.sensors,
      recordCount: props.sensors?.length ?? -1,

      pageCount: -1,
    },
  });

  const severityStyle = useSeverityStyles();

  const appContext = React.useContext(AppContext);
  const columns = React.useMemo(
    () =>
      getGridColDef((sensors) => {
        const mostRecentTime = max(
          compact(
            sensors.map((s) =>
              s.last_value?.timestamp
                ? new Date(s.last_value.timestamp).getTime()
                : null,
            ),
          ),
        );
        setShowSensors(sensors);
        setShowDialogProps({ open: true, fullscreen: false });
      }, appContext.user?.isAdmin),
    [setShowSensors, setShowDialogProps, appContext.user?.isAdmin],
  );

  const getEstimatedRowHeight = React.useCallback(() => 60, []);
  const getRowHeight = React.useCallback(() => "auto", []);
  const pageSizeOptions = React.useMemo(() => {
    const defaultSizes = [10, 20, 30, 50, 100];
    defaultSizes.push(pageSize);
    return uniq(compact(defaultSizes).sort((a, b) => a - b));
  }, []);

  return (
    <Card>
      <CardHeader></CardHeader>
      <CardContent>
        <LoadingWrapper
          loading={sensorsQuery.isLoading}
          loadingElements={<Skeleton variant="rectangular" height={500} />}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SensorFilter
                baseAssetId={assetQuery.data?.id ?? props.assetId}
                selectableAssets={selectableAssets}
                filter={sensorFilter}
                onFilterChange={(nf) => setSensorFilter(nf)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <DataGrid
                  checkboxSelection
                  paginationMode="server"
                  pagination
                  getRowHeight={getRowHeight}
                  getEstimatedRowHeight={getEstimatedRowHeight}
                  initialState={{
                    density: defaultTo(props.density, "standard"),

                    columns: {
                      columnVisibilityModel: {
                        updated_at: false,
                        created_at: false,
                        description: false,
                        measurement_type: false,
                        serial: false,
                        key: false,
                        sensor_context: false,
                        sensor_context2: false,
                        short_name: false,
                        id: false,
                        actions: true,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: pageSettings.size,
                        page: 1,
                      },

                      rowCount: sensorsQuery.data?.recordCount ?? -1,
                    },
                  }}
                  paginationModel={{
                    pageSize: pageSettings.size,
                    page: (pageSettings.number || 1) - 1,
                  }}
                  density="standard"
                  pageSizeOptions={pageSizeOptions}
                  rowCount={sensorsQuery.data?.recordCount}
                  rows={sensorsQuery.data?.sensors || []}
                  columns={columns}
                  loading={sensorsQuery.isLoading}
                  rowSelectionModel={selectionModel}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "4px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "9px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "17px",
                      },
                    "--DataGrid-overlayHeight": "300px",
                  }}
                  onRowSelectionModelChange={(newSelectionModel) =>
                    setSelectionModel(newSelectionModel)
                  }
                  onRowDoubleClick={(params, event) => {
                    setShowSensors([params.row as SensorJSONAPIAttributes]);
                    setShowDialogProps({ ...showDialogProps, open: true });
                    event.stopPropagation();
                  }}
                  onPaginationModelChange={(pageinationModel) => {
                    setPageSettings({
                      size: pageinationModel.pageSize,
                      number: pageinationModel.page + 1,
                    });
                  }}
                />
              </Box>
            </Grid>
            {isEmpty(selectionModel) ? null : (
              <Grid item xs={12}>
                <Button
                  startIcon={<LineAxis />}
                  size="small"
                  onClick={() => {
                    const sensorsToShow = filter(
                      sensorsQuery.data?.sensors,
                      (s) => selectionModel.includes(s.id),
                    );
                    setShowSensors(sensorsToShow);
                    setShowDialogProps({ ...showDialogProps, open: true });
                  }}
                >
                  {I18n.t("frontend.sensors.sensor_list.show_selected")}
                </Button>
              </Grid>
            )}
          </Grid>
        </LoadingWrapper>
        {fabButtons ? (
          <FixedBottomArea>
            <FloatingButtons
              showScrollToTopBtn
              onCancel={() => redirectTo("back")}
              cancelIcon={<KeyboardArrowLeft />}
              cancelTitle={I18n.t("frontend.back")}
              saveTitle={I18n.t("frontend.sensors.sensor_list.show_selected")}
              disableSave={isEmpty(selectionModel)}
              submitBtnIcon={<LineAxis />}
              onSubmit={() => {
                const sensorsToShow = filter(sensorsQuery.data?.sensors, (s) =>
                  selectionModel.includes(s.id),
                );
                setShowSensors(sensorsToShow);
                setShowDialogProps({ ...showDialogProps, open: true });
              }}
            >
              {props.permissions?.create ? (
                <Tooltip
                  title={I18n.t(
                    "frontend.sensors.sensor_list.create_new_sensor",
                  )}
                >
                  <Fab
                    color="secondary"
                    size="medium"
                    onClick={() =>
                      redirectTo(
                        new_asset_sensor_path(
                          assetQuery.data?.id ?? props.assetId,
                        ),
                      )
                    }
                  >
                    <Add />
                  </Fab>
                </Tooltip>
              ) : null}
            </FloatingButtons>
          </FixedBottomArea>
        ) : null}
        {!showDialogProps.open ||
        isEmpty(showSensors) ||
        isNil(showSensorsTimeRange) ? null : (
          <SialogicDialog
            fullScreen={showDialogProps.fullscreen}
            fullWidth
            maxWidth={"xl"}
            onClose={() => {
              setShowDialogProps({ ...showDialogProps, open: false });
              setShowSensors(null);
            }}
            open={showDialogProps.open}
            title={showSensors
              ?.map((s) => getTranslatedProp(s, "name"))
              .join(", ")}
            additionTitleActions={
              <IconButton
                aria-label="print"
                onClick={() => {
                  window.print();
                }}
                size="medium"
              >
                <Print />
              </IconButton>
            }
          >
            <SensorDisplay
              sensors={showSensors}
              onChangeTimeRange={(tr) => {
                console.log("time range set", tr);
                setShowSensorsTimeRange(tr);
              }}
              fullHeight={showDialogProps.fullscreen}
              timeRange={showSensorsTimeRange}
            />
          </SialogicDialog>
        )}
      </CardContent>
    </Card>
  );
};

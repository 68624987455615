import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { ManufacturerAttributes } from "../models/manufacturer";

export const MANUFACTURER_JSONAPI_RESOURCE_TYPE = "manufacturers";

export interface ManufacturerJSONObject
  extends ManufacturerAttributes,
    JSON.Object {}

export type ManufacturerJSONAPIAttributes =
  JSONAPI.AttributesObject<ManufacturerJSONObject>;

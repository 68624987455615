/// <reference types="./../definitions/index" />;

import { Subscription } from "@rails/actioncable";
import { each, isEmpty, isNil, noop } from "lodash";
export type CommentMessageType =
  | "new_comment"
  | "comment_deleted"
  | "comment_updated";

export interface CommentCableMessage {
  action: CommentMessageType;
  id: number;
  parent_id: number;
}

export interface CommentEventSubscriber {
  handleCommentMessage(message: CommentCableMessage): void;
}

export class CommentsChannel {
  subscriptionsByItem: { [itemIdentifier: string]: Subscription };
  subscriber: { [itemIdentifier: string]: CommentEventSubscriber[] };

  constructor() {
    this.subscriptionsByItem = {};
    this.subscriber = {};
  }

  getItemIdentifier(itemType: string, itemId: number): string {
    return `${itemType}_${itemId}`;
  }

  subscribe(
    itemType: string,
    itemId: number,
    subscriber: CommentEventSubscriber,
  ): void {
    if (
      isNil(this.subscriptionsByItem[this.getItemIdentifier(itemType, itemId)])
    ) {
      const channel = App.cable.subscriptions.create(
        {
          channel: "CommentsChannel",
          item_type: itemType,
          item_id: itemId,
        },
        {
          connected: noop,
          disconnected: noop,
          received: (data: CommentCableMessage) =>
            this.notifyListener(itemType, itemId, data),
        },
      );

      this.subscriptionsByItem[this.getItemIdentifier(itemType, itemId)] =
        channel;
    }
    this.addListener(itemType, itemId, subscriber);
  }

  unsubscribe(
    itemType: string,
    itemId: number,
    subscriber: CommentEventSubscriber,
  ): void {
    this.removeListenter(itemType, itemId, subscriber);

    if (isEmpty(this.subscriber[this.getItemIdentifier(itemType, itemId)])) {
      const channel =
        this.subscriptionsByItem[this.getItemIdentifier(itemType, itemId)];
      if (!isNil(channel)) {
        channel.unsubscribe();
        delete this.subscriptionsByItem[
          this.getItemIdentifier(itemType, itemId)
        ];
      }
    }
  }

  protected addListener(
    itemType: string,
    itemId: number,
    subscriber: CommentEventSubscriber,
  ): void {
    let subscribers = this.subscriber[this.getItemIdentifier(itemType, itemId)];
    if (isNil(subscribers)) {
      subscribers = [];
      this.subscriber[this.getItemIdentifier(itemType, itemId)] = subscribers;
    }

    subscribers.push(subscriber);
  }

  protected removeListenter(
    itemType: string,
    itemId: number,
    subscriber: CommentEventSubscriber,
  ): void {
    const subscribers =
      this.subscriber[this.getItemIdentifier(itemType, itemId)];
    if (isNil(subscribers)) {
      return;
    }

    const index = subscribers.indexOf(subscriber);
    if (index !== -1) {
      subscribers.splice(index, 1);
    }
  }

  protected notifyListener(
    itemType: string,
    itemId: number,
    data: CommentCableMessage,
  ): void {
    if (this.subscriber[this.getItemIdentifier(itemType, itemId)]) {
      each(
        this.subscriber[this.getItemIdentifier(itemType, itemId)],
        (subscriber) => {
          subscriber.handleCommentMessage(data);
        },
      );
    }
  }
}

import { deleteExternalReference } from "../../json_api/external_reference";
import { dialog } from "../../utils/dialog";
import { error, success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";

export async function confirmAndDeleteExternalReference(
  assetId: IDType,
  externalReferenceId: IDType,
  setLoading: (loading: boolean) => void,
) {
  return dialog
    .fire({
      title: I18n.t("frontend.external_references.delete.delete_reference"),
      text: I18n.t("frontend.external_references.delete.delete_dialog_text"),
      showConfirmButton: true,
      showCancelButton: true,
      icon: "question",
      cancelButtonText: I18n.t("frontend.cancel"),
      confirmButtonText: I18n.t("frontend.delete"),
      confirmButtonColor: "#ff0000",
    })
    .then((result) => {
      if (result.isDismissed) {
        return;
      }

      return deleteExternalReference(assetId, externalReferenceId).then(() => {
        void success(
          I18n.t(
            "frontend.external_references.delete.deleted_external_reference",
          ),
        );
      });
    })
    .catch(() => {
      void error(
        I18n.t("frontend.external_references.delete.error_title"),
        I18n.t("frontend.external_references.delete.error_on_delete"),
        true,
      );
    })
    .finally(() => {
      setLoading(false);
    });
}

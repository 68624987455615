import { Grid, Typography } from "@mui/material";
import { find, isNil, snakeCase } from "lodash";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";

import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import { StateJSONObject } from "../../json_api/state";

import { logger } from "../../utils/logger";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingIcon } from "../common/icon";
import { StateDisplay } from "./state_display";

export interface AssetStateSelectionDisplayProps {
  title?: string;
  showTitle?: boolean;
  noneLabel?: string;
  selectedContextStateMachineId: IDType;
  selectedContextStateMachine?: ContextStateMachineJSONObject;
  selectedStateId: IDType;
}

export const AssetStateSelectionDisplay: React.FunctionComponent<
  AssetStateSelectionDisplayProps
> = ({ showTitle = true, ...props }) => {
  const [csm, setCSM] = React.useState<
    ContextStateMachineJSONObject<AssetJSONObject>
  >(props.selectedContextStateMachine);

  const [loading, setLoading] = React.useState(
    isNil(props.selectedContextStateMachine),
  );
  React.useEffect(() => {
    // effect for inital load with no stateful item id selected because it is initially not kown
    if (!isNil(props.selectedContextStateMachineId) && isNil(csm)) {
      setLoading(true);
      loadContextStateMachine(props.selectedContextStateMachineId, [
        "stateful_item",
        "possible_states",
        "state_context",
      ])
        .then((csm) => {
          setCSM(csm);
        })
        .catch((err) => {
          void error(I18n.t("base.error"));
          logger.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!isNil(props.selectedContextStateMachine)) {
      setCSM(props.selectedContextStateMachine);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.selectedContextStateMachineId, props.selectedContextStateMachine]);

  if (loading) {
    return (
      <Grid item>
        <LoadingIcon size="2x" />
      </Grid>
    );
  }

  const selectedState: StateJSONObject = find(
    csm?.possible_states,
    (s) => s.id == props.selectedStateId,
  );

  if (isNil(csm) && !isNil(props.selectedContextStateMachineId)) {
    return null;
  }
  return (
    <Grid item container xs={12}>
      {showTitle ? (
        <Grid item xs={12} className="mb-1">
          <Typography variant="h5">
            {props.title
              ? props.title
              : I18n.t(
                  "frontend.states.asset_state_selection_display.selected_state_heading",
                )}
          </Typography>
        </Grid>
      ) : null}
      {isNil(csm) && isNil(props.selectedContextStateMachineId) ? (
        <Grid item>
          {props.noneLabel ||
            I18n.t(
              "frontend.states.asset_state_selection_display.none_selected",
            )}
        </Grid>
      ) : (
        <>
          <Grid item xs={4} lg={4}>
            <Typography variant="caption">
              {I18n.t(
                `activerecord.models.${snakeCase(csm?.stateful_item_type)}`,
                { count: 1 },
              )}
            </Typography>

            <Typography variant="body1">
              {csm?.stateful_item?.name} #{csm?.stateful_item_id}
            </Typography>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Typography variant="caption">
              {I18n.t(`activerecord.models.state_context`, { count: 1 })}
            </Typography>
            <br />
            <Typography variant="body1">{csm.state_context.name}</Typography>
          </Grid>

          <Grid item xs={4} lg={4}>
            {isNil(selectedState) ? (
              "---"
            ) : (
              <>
                <Typography variant="caption">
                  {I18n.t(`activerecord.models.state`, { count: 1 })}
                </Typography>
                <br />
                <StateDisplay
                  stateName={selectedState.name}
                  imageUrl={selectedState.image_url}
                  stateIcon={selectedState.icon}
                  stateDescription={selectedState.description}
                  stateColor={selectedState.color}
                  criticality={selectedState.criticality}
                  inline
                />
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

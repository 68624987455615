import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { HttpError, sendData } from "../../utils/jquery_helper";
import { useLoadMqttUser } from "./mqtt_users_data";
import { IDType } from "../../utils/urls/url_utils";

import { ContentCopy } from "@mui/icons-material";
import { error, success } from "../../utils/toasts";
import { copy } from "clipboard";
import {
  api_mqtt_users_path,
  generate_and_update_password_api_mqtt_user_path,
} from "../../routes";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { isNil } from "lodash";

interface RegeneratePasswordFormProps {
  mqttUserId: IDType;
  mqttUser?: MqttUserJSONObject;
  showTitle?: boolean;
}

export const RegeneratePasswordForm: React.FunctionComponent<
  RegeneratePasswordFormProps
> = ({
  mqttUserId,
  mqttUser: userFromProps,
  showTitle = true,
}: RegeneratePasswordFormProps) => {
  const confirm = useConfirm();

  const [mqttUser, setMqttUser] = React.useState<MqttUserJSONObject | null>(
    userFromProps,
  );

  const {
    data: loadedMqttUser,
    isLoading,
    error: mqttUserLoadError,
  } = useLoadMqttUser({
    variables: { id: mqttUserId },
    initialData:
      !isNil(mqttUserId) && mqttUser?.id == mqttUserId ? mqttUser : undefined,
  });

  React.useEffect(() => {
    if (loadedMqttUser || userFromProps) {
      if (loadedMqttUser !== userFromProps) {
        setMqttUser(loadedMqttUser);
      }
    }
  }, [loadedMqttUser, userFromProps]);

  const [shownPassword, setShownPassword] = React.useState<string>(null);
  return (
    <Paper>
      <Box padding={2}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={100} />
        ) : mqttUserLoadError ? (
          <>
            <Typography variant="h6">
              {I18n.t("frontend.mqtt_users.regenerate_password_heading")}
            </Typography>
            <Typography>
              {I18n.t("frontend.mqtt_users.error_loading_user")}
            </Typography>
            <Typography variant="caption">
              {mqttUserLoadError.message}
            </Typography>
          </>
        ) : (
          <Grid container spacing={2}>
            {showTitle && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  {I18n.t("frontend.mqtt_users.regenerate_password_heading")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>
                {I18n.t("frontend.mqtt_users.regenerate_password_warning")}
              </Typography>
            </Grid>
            {shownPassword && (
              <Grid item xs={12}>
                <Box padding={4} textAlign={"center"}>
                  <Typography component="span" variant="caption" mr={1}>
                    {I18n.t("frontend.mqtt_users.new_password")}
                  </Typography>
                  <Typography
                    component="span"
                    border={1}
                    padding={2}
                    borderRadius={5}
                  >
                    {shownPassword}{" "}
                    <IconButton
                      onClick={() => {
                        copy(shownPassword);
                        void success(
                          I18n.t("frontend.mqtt_users.password_copied"),
                        );
                      }}
                      size="small"
                    >
                      <ContentCopy />
                    </IconButton>
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} textAlign={"center"}>
              <Button
                onClick={() => {
                  void confirm({
                    description: I18n.t(
                      "frontend.mqtt_users.confirm_regenerate_password",
                      { username: mqttUser.username },
                    ),
                  })
                    .then(async () => {
                      const { success, password } = await sendData<
                        unknown,
                        {
                          success: boolean;
                          password: string;
                        }
                      >(
                        generate_and_update_password_api_mqtt_user_path(
                          mqttUserId,
                          {
                            format: "json",
                            _options: true,
                          },
                        ),
                        "",
                        "PATCH",
                      );
                      // Regenerate password
                      if (success) {
                        setShownPassword(password);
                      } else {
                        error(
                          I18n.t(
                            "frontend.mqtt_users.error_regenerating_password",
                          ),
                        );
                      }
                    })
                    .catch((err) => {
                      if (err instanceof HttpError) {
                        const rsp = JSON.parse(err.response);
                        error(
                          rsp.error ||
                            rsp.message ||
                            I18n.t("frontend.error  "),
                        );
                      }
                    });
                }}
                variant="contained"
                color="primary"
              >
                {I18n.t("frontend.mqtt_users.regenerate_password")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};

import { Grid } from "@mui/material";
import { NumericDictionary, defaultTo, find, isEmpty, map } from "lodash";
import * as React from "react";
import {
  MeasurementValue,
  MeasurementValueAttributes,
} from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import {
  MeasurementValueDefinition,
  MeasurementValueDefinitionAttributes,
  mvdRangeString,
} from "../../../models/measurement_value_definition";
import { unitDisplayString } from "../../../utils/unit_conversion";
import { getValueString } from "../../../utils/value_format";
export interface MeasurementValuesSectionProperties {
  orderedMeasurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurementValues: MeasurementValue[];
  mvdIntervalUnit: string;
  measurementCategoryById: NumericDictionary<MeasurementCategory>;

  showPercentage?: boolean;

  hideHeaderRow?: boolean;
}

export const MeasurementValuesSection: React.FunctionComponent<
  MeasurementValuesSectionProperties
> = (props) => {
  return (
    <Grid container>
      <Grid item container xs={12} className="text-center border-bottom">
        {headerForMeasurementType(props.showPercentage)}
      </Grid>
      <Grid item container xs={12} className="text-center" rowSpacing={2}>
        {map(props.orderedMeasurementValueDefinitions, (mvd, index) => {
          const vals = props.measurementValues;
          const value =
            find(
              vals,
              (value) => value.measurement_value_definition_id == mvd.id,
            ) ??
            ({
              title: "---",
              unit: "",
              note: "---",
            } as any as MeasurementValueAttributes);

          return (
            <Grid container item xs={12} key={index}>
              {valueLine(
                mvd,
                value,
                props.measurementCategoryById,
                props.showPercentage,
                props.mvdIntervalUnit,
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

function valueLine(
  mvd: MeasurementValueDefinition,
  value: MeasurementValue,
  measurementCategoriesById: NumericDictionary<MeasurementCategory>,
  includePercentage: boolean,
  intervalUnit: string,
) {
  const valueRangeString = mvdRangeString(mvd, intervalUnit);
  if (includePercentage === true) {
    return (
      <>
        <Grid item xs={3} className="text-left">
          <span>{mvd.title}</span>
          {isEmpty(valueRangeString) ? null : (
            <>
              <br className="d-inline d-md-none" />
              <small className="ml-md-1">({valueRangeString})</small>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <strong>
            {getValueString(value.value)} {unitDisplayString(mvd.unit)}
          </strong>
        </Grid>
        <Grid item xs="auto">
          {getValueString(value.percent)} %
        </Grid>
        <Grid item xs={2}>
          <span>
            {defaultTo(measurementCategoriesById, {})[
              mvd.measurement_category_id
            ]?.title ?? "---"}
          </span>
        </Grid>
        <Grid item xs={3}>
          <span>{isEmpty(value.note) ? "---" : value.note}</span>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item xs={3} className="text-left">
          <span>{mvd.title}</span>
          {isEmpty(valueRangeString) ? null : (
            <>
              <br className="d-inline d-md-none" />
              <small className="ml-md-1">({valueRangeString})</small>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <span>
            {getValueString(value.value)} {unitDisplayString(mvd.unit)}
          </span>
        </Grid>
        <Grid item xs={3}>
          <span>
            {defaultTo(measurementCategoriesById, {})[
              mvd.measurement_category_id
            ]?.title ?? "---"}
          </span>
        </Grid>
        <Grid item xs={4}>
          <span>{isEmpty(value.note) ? "---" : value.note}</span>
        </Grid>
      </>
    );
  }
}

function headerForMeasurementType(includePercentage: boolean) {
  if (includePercentage === true) {
    return (
      <>
        <Grid item xs={3} className="text-left">
          <strong>
            {I18n.t(
              "activerecord.attributes.measurement_value_definition.title",
            )}
          </strong>
        </Grid>
        <Grid item xs={2}>
          <strong>
            {I18n.t("activemodel.attributes.measurement_value.value")}
          </strong>
        </Grid>
        <Grid item xs={2}>
          <strong>
            {I18n.t("activemodel.attributes.measurement_value.percent")}
          </strong>
        </Grid>
        <Grid item xs={2}>
          <strong>
            {I18n.t(
              "activerecord.attributes.measurement_value_definition.measurement_category",
            )}
          </strong>
        </Grid>
        <Grid item xs={3}>
          <strong>
            {I18n.t("activemodel.attributes.measurement_value.note")}
          </strong>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item xs={3} className="text-left">
          <strong>
            {I18n.t(
              "activerecord.attributes.measurement_value_definition.title",
            )}
          </strong>
        </Grid>
        <Grid item xs={2}>
          <strong>
            {I18n.t("activemodel.attributes.measurement_value.value")}
          </strong>
        </Grid>
        <Grid item xs={3}>
          <strong>
            {I18n.t(
              "activerecord.attributes.measurement_value_definition.measurement_category",
            )}
          </strong>
        </Grid>
        <Grid item xs={4}>
          <strong>
            {I18n.t("activemodel.attributes.measurement_value.note")}
          </strong>
        </Grid>
      </>
    );
  }
}

import * as React from "react";

import JQuery from "jquery";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { MeasurementPlanJSONAPIAttributesObject } from "../../json_api/measurement_plan";
import { MeasurementPlan } from "../../models/measurement_plan";
import { AppRoot } from "../common/app_root";
import { MeasurementPlanList } from "./views/measurement_plan_list";

const measurementPlanListRoots: Root[] = [];
export function initializeMeasurementPlanList(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-plan-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);
    const measurementPlansJSON = el.data(
      "items",
    ) as JSONAPI.CollectionResourceDoc<
      string,
      MeasurementPlanJSONAPIAttributesObject
    >;
    let measurementPlans: MeasurementPlan[] = undefined;
    if (!isEmpty(measurementPlansJSON?.data)) {
      measurementPlans = measurementPlansJSON.data.map(
        (measurementPlan, index) => {
          return { ...measurementPlan.attributes, id: measurementPlan.id };
        },
      );
    }

    const measurementPlansUrl: string = el.data("items-url") as string;
    const assetId: string = el.data("asset-id") as string;
    let currentPage = parseInt(el.data("current-page") as string) || 1;
    currentPage = isNaN(currentPage) ? 1 : currentPage;
    const totalPages = parseInt(el.data("total-pages") as string);
    const pageSize = parseInt(el.data("page-size") as string);
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <MeasurementPlanList
          measurementPlans={measurementPlans}
          measurementPlansUrl={measurementPlansUrl}
          pageSize={pageSize}
          assetId={assetId}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </AppRoot>,
    );
    measurementPlanListRoots.push(root);
  });
}

export function destroyMeasurementPlanList() {
  measurementPlanListRoots.forEach((r) => r.unmount());
  measurementPlanListRoots.length = 0;
}

import { createQuery } from "react-query-kit";
import {
  EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE,
  ExternalReferenceTargetJSONObject,
} from "../../json_api/external_reference_target";
import { api_organization_external_reference_targets_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import * as JSONAPI from "jsonapi-typescript";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
interface LoadExternalReferenceTargetsRequest {
  referencedItemType: string;
  organizationId: IDType;
}

type LoadExternalReferenceTargetsResult = ExternalReferenceTargetJSONObject[];

export const loadExternalReferenceTargets = createQuery<
  LoadExternalReferenceTargetsResult,
  LoadExternalReferenceTargetsRequest
>({
  queryKey: [EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE],
  fetcher: async (vars) => {
    const document = await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, ExternalReferenceTargetJSONObject>
    >(
      api_organization_external_reference_targets_path(vars.organizationId, {
        organization_id: vars.organizationId,
        filter: { item_type: vars.referencedItemType },
      }),
    );
    return jsonApiResourceCollectionToFlatObjects<ExternalReferenceTargetJSONObject>(
      document,
    );
  },
});

import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Bluebird, { CancellationError } from "bluebird";
import {
  defaultTo,
  each,
  forEach,
  has,
  isEmpty,
  isNil,
  merge,
  orderBy,
  sortedUniq,
  toInteger,
  uniq,
  values,
} from "lodash";
import moment from "moment";
import { DateRange } from "moment-range";
import React, { useEffect, useRef, useState } from "react";
import { ChartDataLoader } from "../../charting/chart_data/chart_data_loader";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { Sensor } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { SensorValueTableWidgetConfigSerialized } from "../../widgets/sensor_value_table_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  groupAndMergeDataFromBinaryData,
  sensorValueTableBuildBinaryDataUrls,
  sensorValueTableGridColDef,
} from "./sensor_value_table.utils";
import {
  SensorsByAssetsHash,
  SensorValueTableWidgetProps,
} from "./sensor_value_table_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { use } from "chai";
import { CancelledError } from "@tanstack/react-query";

const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

export const SensorValueTableWidget: React.FunctionComponent<
  SensorValueTableWidgetProps
> = ({
  encloseInWidgetBox = true,
  updateEnabled = true,
  allowFullscreen = true,
  pageSize = 20,
  maxEntries = 500,
  tableHeight = 200,
  timeRange = new DateRange(moment().startOf("day"), moment().endOf("day")),
  groupByAsset = false,
  assetSearchMode = "subtree",
  sensorIds = [],
  assetInfo = [],
  ...props
}: SensorValueTableWidgetProps) => {
  const [title, setTitle] = useState(props.title);
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSizes, setPageSizes] = useState(
    sortedUniq(
      [pageSize, ...DEFAULT_PAGE_SIZES]
        .sort((a, b) => a - b)
        .filter((value) => value < maxEntries),
    ),
  );
  const [sensors, setSensors] = useState(null);
  const [columns, setColumns] = useState(null);

  const [sensorsLoadingPromise, setSensorsLoadingPromise] = useState(null);
  const [binaryDataLoadingPromise, setBinaryDataLoadingPromise] =
    useState(null);
  const [pageModel, setPageModel] = useState({ pageSize: pageSize, page: 0 });
  const [sensorIdsByAssetId, setSensorIdsByAssetId] = useState({});
  const [assetNameByAssetId, setAssetNameByAssetId] = useState({});
  const [dataUpdateEnabled, setDataUpdateEnabled] = React.useState(
    updateEnabled && timeRange.contains(moment()),
  );

  const dataLoader = useRef(new ChartDataLoader());

  //Update title when sensor name or title changes
  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  // Update sensor data when sensor link url changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  // Update data update enabled state when updateEnabled or timeRange changes
  useEffect(() => {
    setDataUpdateEnabled(
      updateEnabled && (!timeRange || timeRange?.contains(moment())),
    );
  }, [updateEnabled, timeRange?.start, timeRange?.end]);

  // Update asset name by asset id when assetInfo changes
  useEffect(() => {
    each(assetInfo, (info) => {
      if (!has(assetNameByAssetId, info.id)) {
        setAssetNameByAssetId((prev) => ({ ...prev, [info.id]: info.name }));
      }
    });
  }, [assetInfo]);

  useEffect(() => {}, [dataUpdateEnabled]);

  // Fetch and prepare data when timeRange or sensorIds changes
  useEffect(() => {
    fetchAndPrepareData();

    return () => {
      const instance = WidgetController.getInstance();
      if (!isNil(sensorsLoadingPromise)) {
        sensorsLoadingPromise.cancel();
        setSensorsLoadingPromise(null);
      }
      if (!isNil(binaryDataLoadingPromise)) {
        binaryDataLoadingPromise.cancel();
        setBinaryDataLoadingPromise(null);
      }
    };
  }, [timeRange, sensorIds]);

  // Function to fetch and prepare data
  const fetchAndPrepareData = () => {
    setLoading(true);
    try {
      const sensorsPromise = loadSensors();
      setSensorsLoadingPromise(sensorsPromise);

      sensorsPromise
        .then((sensors) => {
          const binaryDataUrls = sensorValueTableBuildBinaryDataUrls(
            sensors,
            timeRange,
            maxEntries,
          );
          const binaryDataPromise = dataLoader.current?.loadBinaryChartData(
            binaryDataUrls,
            null,
          );
          setBinaryDataLoadingPromise(binaryDataPromise);

          return Bluebird.all([binaryDataPromise, sensors]);
        })
        .then(([binaryChartData, sensors]) => {
          const groupedDataHash = groupAndMergeDataFromBinaryData(
            binaryChartData,
            sensorIdsByAssetId,
            groupByAsset,
          );

          let tableData = values(groupedDataHash);
          tableData = orderBy(tableData, ["timestamp"], ["desc"]);

          setColumns(
            sensorValueTableGridColDef(
              sensors,
              assetNameByAssetId,
              props.sensorColumnSorting,
              sensorIdsByAssetId,
              groupByAsset,
            ),
          );
          setSensors(sensors);
          setTableData(tableData);
        })
        .catch(CancellationError, (e) => {
          logger.info("Sensor Fetching Cancelled");
        })
        .catch(CancelledError, (e) => {
          logger.info("Sensor Fetching Cancelled");
        })
        // ignore the cancellation error as we dont't care
        .catch((error) => {
          logger.error("Error fetching data: ", error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      logger.error("Error fetching data: ", error);
    }
  };

  /**Loads sensor information from API. Stores the result in this.sensorIdsByAssetId.
   *
   *
   * @return {*}  {Bluebird<Sensor[]>}
   */
  const loadSensors = (): Bluebird<Sensor[]> => {
    const sensor_ids = defaultTo(sensorIds, []);

    return SensorLoader.getInstance()
      .getSensors(sensor_ids)
      .then((sensors) => {
        const sensorIdsByAssetId = {} as SensorsByAssetsHash;
        forEach(sensors, (sensor: Sensor) => {
          if (!isNil(sensor)) {
            const assetIdString = `${sensor.asset_id}`;
            const sensorIdString = `${sensor.id}`;
            if (isNil(sensorIdsByAssetId[assetIdString])) {
              sensorIdsByAssetId[assetIdString] = {};
            }
            sensorIdsByAssetId[assetIdString][sensorIdString] = sensor;
          }
        });
        setSensorIdsByAssetId(sensorIdsByAssetId);
        return sensors;
      })
      .catch(CancellationError, (e) => {
        logger.info("Sensor loading cancelled");
        return [] as Sensor[];
      })
      .catch(CancelledError, (e) => {
        logger.info("Sensor Fetching Cancelled");
        return [] as Sensor[];
      })
      .catch((e) => {
        logger.error(`Error: ${(e as Error).toString()}`);
        return [] as Sensor[];
      });
  };

  const tableContent = () => {
    let showPagination = true;
    const rowCount = defaultTo(tableData?.length, null);
    if (!isNil(pageModel.pageSize) && !isNil(rowCount)) {
      showPagination = pageModel.pageSize <= rowCount;
    }
    if (isEmpty(sensors) || isEmpty(columns))
      return (
        <Typography>
          {I18n.t(
            "frontend.widgets.sensor_value_table_widget.no_sensors_to_display",
          )}
        </Typography>
      );
    return (
      <Box height={tableHeight} width="100%">
        <DataGrid
          autoPageSize={false}
          initialState={{
            density: defaultTo(props.density, "compact"),
          }}
          pagination
          paginationMode={"client"}
          paginationModel={pageModel}
          hideFooterPagination={!showPagination}
          pageSizeOptions={pageSizes}
          rows={defaultTo(tableData, [])}
          columns={columns}
          loading={loading}
          onPaginationModelChange={(newPaginationModel) => {
            setPageModel(newPaginationModel);
          }}
        />
      </Box>
    );
  };

  const content = loading ? (
    <Skeleton variant="rounded" height={300} />
  ) : (
    tableContent()
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          {...props}
          title={title}
          titleLinkUrl={titleLinkUrl}
          contentLinkUrl={contentLinkUrl}
        >
          {content}
        </WidgetBox>
      )}
    </>
  );
};
function serializedConfigToProps(
  config: SensorValueTableWidgetConfigSerialized,
): SensorValueTableWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    pageSize: config?.page_size,
    maxEntries: config?.max_entries,
    tableHeight: config.table_height,
    assetSearchMode: config.asset_search_mode,
    groupByAsset: config.group_by_asset,
    sensorIds: uniq(config.sensor_ids),
    assetInfo: config.asset_info,
    sensorColumnSorting: config.sensor_sort,
    dataUpdateEnabled: !config.disable_update,
  } as SensorValueTableWidgetProps);
}

export const SensorValueTableWidgetDefinition: SialogicWidgetDefinition<
  typeof SensorValueTableWidget,
  typeof serializedConfigToProps
> = {
  Component: SensorValueTableWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import { isNil } from "lodash";

export let UPDATE_INTERVALL_MS = 5000;
export const DEFAULT_UPDATE_INTERVALL = 5000;
export function setUpdateIntervall(intervallMs: number) {
  UPDATE_INTERVALL_MS = intervallMs;
  if (isUpdating) {
    stopUpdates();
    startUpdates();
  }
}
/**
 * An updatable component
 */
export interface Updatable {
  update(): void;
}

const UpdatableObjects: Updatable[] = [];

/**
 * Register a component for time based updates.
 * Automatically registers an interval if no updates are started.
 * @param object An updatable object.
 */
export function registerUpdate(object: Updatable): void {
  const index = UpdatableObjects.indexOf(object);
  if (index > 0) {
    return;
  }

  UpdatableObjects.push(object);
  startUpdates();
}

/**
 * Unregister a component from time based updates.
 * Automatically stops update interval if there are no more components to update.
 * @param object An updatable object.
 */
export function unregisterUpdate(object: Updatable): void {
  const index = UpdatableObjects.indexOf(object);
  if (index < 0) {
    return;
  }

  UpdatableObjects.splice(index, 1);

  if (UpdatableObjects.length == 0) {
    stopUpdates();
  }
}

/**
 * Clears all component updates and stops timer
 */
export function clearUpdate(): void {
  UpdatableObjects.splice(0, UpdatableObjects.length);
  stopUpdates();
}

let interval: NodeJS.Timeout = null;
function startUpdates(): void {
  if (interval) {
    return;
  }

  interval = setInterval(() => {
    UpdatableObjects.forEach((object) => object.update());
  }, UPDATE_INTERVALL_MS);
}

function isUpdating() {
  return isNil(interval);
}

function stopUpdates(): void {
  clearInterval(interval);
  interval = null;
}

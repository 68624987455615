import { TreeItem } from "../../../models/tree_item";
import MaintenancePlanDispatcher from "./maintenance_plan_dispatcher";
import {
  AssetGroups,
  MaintenancePlan,
  MaintenancePlanErrorResponse,
  MaintenanceType,
} from "./models";

export interface AssetIdentifier {
  assetGroupIndex: number;
  assetIndex: number;
  id?: string | number;
}

export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";

  assetTree: TreeItem;
  rootAssetId: string | number;
  assetGroups: AssetGroups;
  maintenanceTypes: MaintenanceType[];
}

export interface AddMaintenancePlanAction {
  type: "ADD_MAINTENANCE_PLAN";
  assetGroupIndex: number;
  assetIndex: number;
}

export interface AddInspectionPlanAction {
  type: "ADD_INSPECTION_PLAN";
  assetGroupIndex: number;
  assetIndex: number;
}

export interface RemoveMaintenancePlanAction {
  type: "REMOVE_MAINTENANCE_PLAN";
  assetGroupIndex: number;
  assetIndex: number;
  maintenancePlanIndex: number;
}

export interface UpdateMaintenancePlanAction {
  type: "UPDATE_MAINTENANCE_PLAN";
  assetGroupIndex: number;
  assetIndex: number;
  maintenancePlanIndex: number;
  maintenancePlan: MaintenancePlan;
}

export interface ChangeMaintenancePlanOrderAction {
  type: "CHANGE_MAINTENANCE_PLAN_ORDER";
  assetGroupIndex: number;
  assetIndex: number;
  maintenancePlanIndex: number;
  direction: number;
}

export interface ToggleMaintenancePlanListAction {
  type: "TOGGLE_MAINTENANCE_PLAN_LIST";
  assetGroupIndex: number;
  assetIndex: number;
  open?: boolean;
}

export interface ToggleAllMaintenancePlanListsAction {
  type: "TOGGLE_ALL_MAINTENANCE_PLAN_LISTS";
  open?: boolean;
}

export interface CopyMaintenancePlanAction {
  type: "COPY_MAINTENANCE_PLAN";
  assetIndices: AssetIdentifier[];
  maintenancePlan: MaintenancePlan;
}

export interface MoveMaintenancePlanAction {
  type: "MOVE_MAINTENANCE_PLAN";
  assetGroupIndex: number;
  assetIndex: number;
  maintenancePlanIndex: number;
  destAsset: AssetIdentifier;
}

export interface SetProcessingAction {
  type: "SET_PROCESSING";
  isProcessing: boolean;
}

export interface SetErrorsAction {
  type: "SET_ERRORS";
  errorResponse: MaintenancePlanErrorResponse;
}

export interface ResetErrorsAction {
  type: "RESET_ERRORS";
}

export type MaintenancePlanAction =
  | ResetStateAction
  | LoadInitialStateAction
  | AddMaintenancePlanAction
  | AddInspectionPlanAction
  | RemoveMaintenancePlanAction
  | UpdateMaintenancePlanAction
  | ChangeMaintenancePlanOrderAction
  | ToggleMaintenancePlanListAction
  | ToggleAllMaintenancePlanListsAction
  | CopyMaintenancePlanAction
  | MoveMaintenancePlanAction
  | SetProcessingAction
  | SetErrorsAction
  | ResetErrorsAction;

/**
 * Reset the state of the store to the default state
 */
export function resetState(): void {
  MaintenancePlanDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Initialize state of the maintenance plan store
 * @param rootAssetId Id of root asset
 * @param assetGroups Asset groups with maintenance plans
 * @param maintenanceTypes Maintenance types for autocomplete
 */
export function loadInitialState(
  rootAssetId: string | number,
  assetGroups: AssetGroups,
  maintenanceTypes: MaintenanceType[],
  assetTree: TreeItem,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    rootAssetId,
    assetGroups,
    maintenanceTypes,
    assetTree,
  });
}

/**
 * Add a new maintenace plan to an asset
 * @param assetIndex Index in the asset list
 */
export function addMaintenancePlan(
  assetGroupIndex: number,
  assetIndex: number,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "ADD_MAINTENANCE_PLAN",
    assetGroupIndex,
    assetIndex,
  });
}

/**
 * Add a new maintenace plan to an asset
 * @param assetIndex Index in the asset list
 */
export function addInspectionPlan(
  assetGroupIndex: number,
  assetIndex: number,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "ADD_INSPECTION_PLAN",
    assetGroupIndex,
    assetIndex,
  });
}

/**
 * Remove a maintenance plan
 * @param assetIndex Index of the asset in the asset list
 * @param maintenancePlanIndex Index of the maintenace plan within the asset
 */
export function removeMaintenancePlan(
  assetGroupIndex: number,
  assetIndex: number,
  maintenancePlanIndex: number,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "REMOVE_MAINTENANCE_PLAN",
    assetGroupIndex,
    assetIndex,
    maintenancePlanIndex,
  });
}

/**
 * Update an existing maintenance plan
 * @param assetIndex Index of the asset in the asset list
 * @param maintenancePlanIndex Index of the maintenace plan within the asset
 * @param maintenancePlan The new maintenance plan
 */
export function updateMaintenacePlan(
  assetGroupIndex: number,
  assetIndex: number,
  maintenancePlanIndex: number,
  maintenancePlan: MaintenancePlan,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "UPDATE_MAINTENANCE_PLAN",
    assetGroupIndex,
    assetIndex,
    maintenancePlanIndex,
    maintenancePlan,
  });
}

/**
 * Change the position of a maintenance plan within an asset.
 * @param assetIndex Index of the asset in the asset list
 * @param maintenancePlanIndex Index of the maintenace plan within the asset
 * @param direction Direction of the change: -1 for up and 1 for down. The new index will be maintenancePlanIndex + direction.
 */
export function changeMaintenancePlanOrder(
  assetGroupIndex: number,
  assetIndex: number,
  maintenancePlanIndex: number,
  direction: number,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "CHANGE_MAINTENANCE_PLAN_ORDER",
    assetGroupIndex,
    assetIndex,
    maintenancePlanIndex,
    direction,
  });
}

export function toggleMaintenancePlanList(
  assetGroupIndex: number,
  assetIndex: number,
  open?: boolean,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "TOGGLE_MAINTENANCE_PLAN_LIST",
    assetGroupIndex: assetGroupIndex,
    assetIndex: assetIndex,
    open: open,
  });
}

export function toggleAllMaintenancePlanLists(open?: boolean): void {
  MaintenancePlanDispatcher.dispatch({
    type: "TOGGLE_ALL_MAINTENANCE_PLAN_LISTS",
    open: open,
  });
}

/**
 * Copy an maintenance plan to other assets.
 * @param assetIndices A set of asset indices within the asset list.
 * @param maintenancePlan The maintenance plan to copy.
 */
export function copyMaintenancePlan(
  assetIndices: AssetIdentifier[],
  maintenancePlan: MaintenancePlan,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "COPY_MAINTENANCE_PLAN",
    assetIndices: assetIndices,
    maintenancePlan: maintenancePlan,
  });
}

export function moveMaintenancePlan(
  assetGroupIndex: number,
  assetIndex: number,
  maintenancePlanIndex: number,
  destAsset: AssetIdentifier,
): void {
  MaintenancePlanDispatcher.dispatch({
    type: "MOVE_MAINTENANCE_PLAN",
    assetGroupIndex,
    assetIndex,
    maintenancePlanIndex,
    destAsset,
  });
}

/**
 * Sets wether the form is processing a request or not
 * @param isProcessing True if form is processing a request
 */
export function setProcessing(isProcessing: boolean): void {
  MaintenancePlanDispatcher.dispatch({
    type: "SET_PROCESSING",
    isProcessing,
  });
}

/**
 * Applies errors from error response to model to show it in the form.
 * @param errorResponse
 */
export function setErrors(errorResponse: MaintenancePlanErrorResponse): void {
  MaintenancePlanDispatcher.dispatch({
    type: "SET_ERRORS",
    errorResponse,
  });
}

/**
 * Resets errors shown in form
 */
export function resetErrors(): void {
  MaintenancePlanDispatcher.dispatch({
    type: "RESET_ERRORS",
  });
}

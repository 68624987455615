import { AccessTime, Cancel, Edit } from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Skeleton } from "@mui/material";
import { isNil, map } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";
import { MaterialUiDateRangePicker } from "../common/data_range_picker";
import { Dashboard } from "./dashboard";
import { DashboardContainerProps } from "./dashboard_container.types";
import { DashboardContext } from "./dashboard_context/dashboard_context";

export const DashboardContainer: FunctionComponent<DashboardContainerProps> = (
  props,
) => {
  const ctx = React.useContext(DashboardContext);

  return (
    <Grid container spacing={2}>
      {isNil(ctx.timeRange?.start) ? null : (
        <Grid item xs={12}>
          <Grid container columnSpacing={2} justifyContent="flex-end">
            <Grid item container xs={12} sm="auto">
              {ctx.loading ? (
                <Grid item>
                  <CircularProgress size="small" />
                </Grid>
              ) : null}
              <Grid item>
                <MaterialUiDateRangePicker
                  ranges={ctx.availableTimeScopes}
                  type="datetime"
                  dateFormat="L LT"
                  endAdornment={<>{ctx.timeScope ?? <AccessTime />}</>}
                  helperText={I18n.t(
                    "frontend.dashboard.selected_time_range_helper_text",
                  )}
                  label={I18n.t("frontend.dashboard.select_time_range")}
                  value={
                    !isNil(ctx.timeRange?.start)
                      ? [ctx.timeRange.start, ctx.timeRange.end]
                      : null
                  }
                  opens="left"
                  onChange={({ dateRange, timeRangeId: label }) => {
                    ctx?.onChangeTimeScope(dateRange, label);
                  }}
                />
              </Grid>
              {ctx.canEdit ? (
                <Grid item className="backend-link toggleable">
                  <IconButton
                    onClick={() => {
                      ctx.onChangeEditMode?.(!ctx.editMode);
                    }}
                  >
                    {ctx.editMode ? <Cancel /> : <Edit />}
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
      {ctx.loading ? (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      ) : (
        map(ctx.dashboardConfigs, (dashboardConfig, index) => (
          <Grid item xs={12} key={index}>
            <Dashboard dashboardConfig={dashboardConfig} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import { StateWidgetDefinition } from "../components/widgets/state_widget";
import { ContextStateMachine } from "../models/context_state_machine";

import { StateWidgetConfigSerialized } from "./state_widget.types";
import Widget from "./widget";

export function contextStateMachineFromSerializedConfig(
  config: StateWidgetConfigSerialized,
) {
  return {
    id: config.context_state_machine_id as number,
    current_state_since: config.current_state_since,
    state_context: {
      id: config.context_id as number,
      name: config.context_name,
      states: config.states,
    },
    stateful_item_id: config.stateful_item_id as number,
    stateful_item_type: config.stateful_item_type,

    current_state: config.current_state,
  };
}
/*
 * Javascript implementation of the StateWidget.
 *
 * @class StateWidget
 * @extends {Widget}
 */
export class StateWidget extends Widget<StateWidgetConfigSerialized> {
  static getDomClassName(): string {
    return "state-widget";
  }

  static widgetClassName(): string {
    return "StateWidget";
  }

  protected contextStateMachine: ContextStateMachine;
  //protected enableStateEdit: boolean;
  //protected states: StateJSONObject[];

  protected constructor(element: JQuery) {
    super(element);
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as StateWidgetConfigSerialized;
  }

  getContextStateMachineIdsForUpdate(): number[] {
    return [this.contextStateMachine?.id];
  }

  protected initComponent(element: JQuery): void {
    super.initComponent(element);
    const props = StateWidgetDefinition.serializedConfigToProps(this.config);
    this.root.render(
      <AppRoot>
        <StateWidgetDefinition.Component
          {...props}
          encloseInIBox={true}
          title={this.widgetName}
          dashboardSettings={this.dashboardSettings}
          widgetId={this.widgetId as number}
        />
      </AppRoot>,
    );
  }

  cleanup(): void {
    super.cleanup();
  }
}

export default StateWidget;

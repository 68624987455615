import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { copy } from "clipboard";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { success } from "../../utils/toasts";
import {
  useCreateMqttUser,
  useLoadMqttUser,
  useUpdateMqttUser,
} from "./mqtt_users_data";
import { IDType } from "../../utils/urls/url_utils";
import { Save } from "@mui/icons-material";
import { defaultTo } from "lodash";
import { rails_admin_edit_path } from "../../routes";
import { AppContext } from "../common/app_context/app_context_provider";

interface MqttUserFormProps {
  mqttUserId: IDType;
  mqttUser?: MqttUserJSONObject;
  editMode: "show" | "edit" | "new";
  onSaved: (mqttUser: MqttUserJSONObject) => void;
  onCancel?: () => void;
  relations?: {
    deviceId: IDType;
    assetId: IDType;
    userId: IDType;
  };
}

export const MqttUserForm: React.FunctionComponent<MqttUserFormProps> = ({
  mqttUserId,
  editMode,
  mqttUser: mqttUserFromProps,
  onSaved,
  onCancel,
  relations,
}: MqttUserFormProps) => {
  const confirm = useConfirm();

  const { data: loadedMqttUser, isLoading: loading } = useLoadMqttUser({
    variables: { id: mqttUserId },
    enabled: !!mqttUserId,
    initialData: mqttUserFromProps,
    placeholderData: {},
  });

  const [mqttUser, setMqttUser] = React.useState<MqttUserJSONObject | null>(
    editMode !== "new" ? null : {},
  );

  React.useEffect(() => {
    if (loadedMqttUser) {
      setMqttUser(loadedMqttUser);
    }
  }, [loadedMqttUser]);

  const { mutateAsync: updateMqttUser, isPending: updatePending } =
    useUpdateMqttUser();

  const { mutateAsync: createMqttUser, isPending: createPending } =
    useCreateMqttUser();

  const acls = React.useMemo(() => {
    const sortedAcls = mqttUser?.mqtt_acls?.sort((a, b) => {
      return a.topic.localeCompare(b.topic);
    });
    return sortedAcls?.map((acl, index) => (
      <ListItem
        key={index}
        onClick={() => {
          copy(acl.topic);
          void success(
            I18n.t("frontend.copied_to_clipboard", { text: acl.topic }),
          );
        }}
      >
        <Typography>
          {acl.topic}
          {` (${acl.access})`}
        </Typography>
      </ListItem>
    ));
  }, [mqttUser?.mqtt_acls]);
  const appCtx = React.useContext(AppContext);
  return (
    <Paper>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Regenerate Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ readOnly: editMode === "show" }}
            fullWidth
            label={I18n.t("activerecord.attributes.mqtt_user.username")}
            value={mqttUser?.username || ""}
            onChange={(e) => {
              setMqttUser({ ...mqttUser, username: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} px={2}>
            <Grid item xs>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly={editMode === "show"}
                      checked={defaultTo(mqttUser?.enabled, true)}
                      onChange={(e) =>
                        setMqttUser((u) => ({
                          ...u,
                          enabled: e.target.checked,
                        }))
                      }
                    />
                  }
                  label={I18n.t("activerecord.attributes.mqtt_user.enabled")}
                />
              </FormControl>
            </Grid>
            {appCtx.user?.isAdmin && (
              <Grid item xs>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        readOnly={editMode === "show"}
                        checked={defaultTo(mqttUser?.superuser, false)}
                        onChange={(e) =>
                          setMqttUser((u) => ({
                            ...u,
                            superuser: e.target.checked,
                          }))
                        }
                      />
                    }
                    label={I18n.t(
                      "activerecord.attributes.mqtt_user.superuser",
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        {acls && (
          <Grid item xs={12}>
            {acls}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} p={2}>
        <ButtonGroup>
          {editMode !== "show" ? (
            <Button
              color="primary"
              startIcon={<Save />}
              onClick={() => {
                if (editMode == "new") {
                  void createMqttUser({ mqttUser, rels: relations }).then(
                    (mqttUser) => {
                      onSaved(mqttUser);
                    },
                  );
                }
                if (editMode == "edit") {
                  void updateMqttUser(mqttUser).then((mqttUser) => {
                    onSaved(mqttUser);
                  });
                }
              }}
            >
              {I18n.t("base.save")}
            </Button>
          ) : null}
          <Button onClick={onCancel} color="secondary">
            {I18n.t("base.cancel")}
          </Button>
        </ButtonGroup>
      </Grid>
    </Paper>
  );
};

import { isEmpty, reject } from "lodash";
import { IDType, localizedBasePath } from "./url_utils";
import { asset_location_path, organization_location_path } from "../../routes";
export function assetLocationPath(assetId: IDType): string {
  return localizableItemLocationPath(assetId, "asset");
}

export function localizableItemLocationPath(
  itemId: IDType,
  itemType: "asset" | "organization",
): string {
  if (itemType === "asset") {
    return asset_location_path(itemId);
  } else if (itemType === "organization") {
    return organization_location_path(itemId);
  }
}

export function editAssetLocationPath(assetId: IDType): string {
  return `${assetLocationPath(assetId)}/edit`;
}

export function assetsLocationsPath(
  assetIds: IDType[],
  sensorTypesToInclude: string[] = [],
  loadAssetStates = false,
): string {
  let url = `${localizedBasePath()}/assets/locations/${reject(
    assetIds,
    isNaN,
  ).join(",")}`;
  const params = assetLocationsParams(sensorTypesToInclude, loadAssetStates);
  if (!isEmpty(params)) {
    url = `${url}?${params.join("&")}`;
  }

  return url;
}

export function geocodePath(searchTerm: string) {
  const url = new URL(
    `${localizedBasePath(true)}/utils/geocode`,
    window.location.origin,
  );
  url.searchParams.append("q", searchTerm);

  return url.pathname + url.search;
}

function assetLocationsParams(
  sensorTypesToInclude: string[],
  loadAssetStates = false,
): string[] {
  const params = [];
  if (loadAssetStates) {
    params.push("include_states=true");
  }

  if (!isEmpty(sensorTypesToInclude)) {
    sensorTypesToInclude?.forEach((type) => {
      params.push(`sensor_types[]=${encodeURIComponent(type)}`);
    });
  }

  return params;
}

export function assetTypeLocationsPath(
  assetTypeIdOrSlug: IDType,
  sensorTypesToInclude: string[] = [],
  loadAssetStates = false,
): string {
  let url = `${localizedBasePath()}/asset_types/${assetTypeIdOrSlug}/locations`;
  const params = assetLocationsParams(sensorTypesToInclude, loadAssetStates);
  if (!isEmpty(params)) {
    url = `${url}?${params.join("&")}`;
  }
  return url;
}

/// <reference types="../../definitions/index" />;
import * as React from "react";

import { Grid, MenuItem, TextField } from "@mui/material";
import { defaultTo, isNil, map, noop } from "lodash";
import {
  SamplingRate,
  SensorDataSamplingMode,
  samplingRateFromString,
} from "../../models/sensor";

const SamplingRates = [
  "30 seconds",
  "1 minutes",
  "2 minutes",
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "30 minutes",
  "1 hours",
  "6 hours",
  "12 hours",
  "1 days",
  "2 days",
  "1 weeks",
  "2 weeks",
  "1 months",
  "3 months",
  "6 months",
  "1 years",
];

function getSamplingRateLabel(samplingRate: SamplingRate): string {
  if (isNil(samplingRate?.value) || isNil(samplingRate?.unit)) {
    return I18n.t("frontend.sampling_rate_select.all_values");
  }

  return `${samplingRate.value} ${I18n.t(
    `frontend.sampling_rate_select.units.${samplingRate.unit}`,
    { count: samplingRate.value },
  )}`;
}

export interface SamplingRateSelectProps {
  samplingRate?: string;
  samplingMode?: SensorDataSamplingMode;
  helperText?: string;
  size?: "small" | "medium";

  onChange?: (samplingRate: string) => void;

  selectAggregateFunction?: boolean;

  onSelectAggregateFunction?: (mode: SensorDataSamplingMode) => void;
}

export const SAMPLING_RATE_SELECT_NO_SAMPLING_VALUE = "---";

export const SamplingRateSelect: React.FunctionComponent<
  SamplingRateSelectProps
> = ({
  onChange = noop,
  size = "small",
  samplingMode = "avg",
  ...props
}: SamplingRateSelectProps) => {
  const [samplingModeState, setSamplingModeState] =
    React.useState<SensorDataSamplingMode>(samplingMode);

  React.useEffect(() => {
    if (props.selectAggregateFunction) {
      props.onSelectAggregateFunction(samplingModeState);
    }
  }, [samplingModeState]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md="auto" minWidth={100}>
        <TextField
          select
          name="sampling_rate"
          size={size}
          fullWidth
          label={I18n.t("frontend.time_range_picker.sampling_rate")}
          value={defaultTo(
            props.samplingRate,
            SAMPLING_RATE_SELECT_NO_SAMPLING_VALUE,
          )}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          helperText={props.helperText}
        >
          <MenuItem
            key={"noval"}
            value={SAMPLING_RATE_SELECT_NO_SAMPLING_VALUE}
          >
            {getSamplingRateLabel(null)}
          </MenuItem>
          {map(SamplingRates, (samplingRate, index) => {
            return (
              <MenuItem key={index} value={samplingRate}>
                {getSamplingRateLabel(samplingRateFromString(samplingRate))}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      {!props.selectAggregateFunction ? null : (
        <Grid item xs={6} md="auto" minWidth={100}>
          <TextField
            size={size}
            label={I18n.t("frontend.diagram_settings.sampling_mode")}
            select
            fullWidth
            value={samplingModeState}
            onChange={(e) =>
              setSamplingModeState(e.target.value as SensorDataSamplingMode)
            }
            name="sampling_rate_agg_fun"
          >
            <MenuItem value={"avg"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.avg")}
            </MenuItem>
            <MenuItem value={"min"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.min")}
            </MenuItem>
            <MenuItem value={"max"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.max")}
            </MenuItem>
            <MenuItem value={"median"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.median")}
            </MenuItem>
            <MenuItem value={"sum"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.sum")}
            </MenuItem>
            <MenuItem value={"count"}>
              {I18n.t("frontend.diagram_settings.sampling_modes.count")}
            </MenuItem>
          </TextField>
        </Grid>
      )}
    </Grid>
  );
};

/* eslint no-console:0 */
/* eslint @typescript-eslint/no-unsafe-assignment: "off" */
/* eslint @typescript-eslint/no-unsafe-member-access: "off" */
// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
if (!window._rails_loaded) {
  Rails.start();
}

import "regenerator-runtime/runtime";
/// <reference types="../definitions/index" />;
import $ from "../initializers/init_jquery";
import { registerGlobalErrorHandler } from "../utils/airbrake_error_handler";
registerGlobalErrorHandler();

import { each, filter, isNil, merge } from "lodash";

import { initializeActionCable } from "../initializers/actioncable";
import { initAppGlobals } from "../initializers/init_app_globals";
initializeActionCable();
initAppGlobals();

import ChartPluginInitializer from "../charting/chart_plugin_initializer";
import { AssetFormController } from "../controller/asset_form_controller";
import { NotificationSettingsController } from "../controller/notification_settings_controller";
import {
  WidgetController,
  injectSensorValue,
  injectSensorValueInTimeInterval,
} from "../controller/widget_controller";
import * as DashboardTimeRangePicker from "../utils/dashboard_time_range_picker";
import * as TimeRangePicker from "../utils/time_range_picker";
import * as toasts from "../utils/toasts";

import {
  destroyTimeDurationInput,
  initializeTimeDurationInput,
} from "../components/common/time_duration_input";
import {
  destroyDiagramSettings,
  initializeDiagramSettings,
} from "../components/diagram_settings";
import {
  destroyTimeRangePicker,
  initializeTimeRangePicker,
} from "../components/diagram_settings/time_range_picker";
import {
  destroyManageSensorDataForm,
  initializeManageSensorDataForm,
} from "../components/manage_sensor_data_form";
import "../initializers/leaflet_resources";

// load inspinia
import { destroyInspinia, initInspinia } from "../initializers/inspinia";
import { logger } from "../utils/logger";

window.SIALOGIC = merge(window.SIALOGIC, {
  WidgetController: WidgetController,
  ChartPluginInitializer: ChartPluginInitializer,
  injectSensorValue,
  injectSensorValueInTimeInterval,

  sendData: sendData,
});

import {
  destroyMeasurementForms,
  initializeMeasurementForms,
} from "../components/common/measurement_form";
import * as MaintenanceDueDropdown from "../components/maintenance_due_dropdown";
import {
  destroyMaintenancePlanContainer,
  initializeMaintenancePlanContainer,
} from "../components/maintenance_plan_form";
import * as NotificationsDropdown from "../components/notifications_dropdown";
import {
  bindUnloadCallbacks,
  showBeforeUnloadDialog,
} from "../utils/before_unload_dialog";
import {
  destroyClipboard,
  initializeClipboard,
} from "../utils/clipboard_input";
import {
  HTMLImageInputElement,
  ImageUploadInput,
} from "../utils/image_upload_input";

import {
  destroyAssetTemplateForm,
  initializeAssetTemplateForm,
} from "../components/asset_template_form";
import {
  destroyAssetForm,
  destroyAssetHome,
  destroyAssetsAutocomplete,
  initializeAssetForm,
  initializeAssetHome,
  initializeAssetsAutocomplete,
} from "../components/assets";
import {
  destroyMaintenanceButtonToolbar,
  initializeMaintenanceButtonToolbar,
} from "../components/common/maintenance/maintenance_button_toolbar";
import {
  destroyRatingInputs,
  initializeRatingInputs,
} from "../components/common/rating_input";
import {
  destroyDeviceAssignAssetForm,
  destroyDeviceBatchCreateForm,
  destroyDevicePairingForm,
  initializeDeviceAssignAssetForm,
  initializeDeviceBatchCreateForm,
  initializeDevicePairingForm,
} from "../components/devices";
import {
  destroyLocationEditor,
  initializeLocationEditor,
} from "../components/locations/index";
import {
  destroyMaintenanceContainer,
  initializeMaintenanceContainer,
} from "../components/maintenance_form";
import {
  destroyMeasurementContainer,
  initializeMeasurementContainer,
} from "../components/measurement_form";
import {
  destroyMeasurementItems,
  destroyMeasurementList,
  initializeMeasurementItem,
  initializeMeasurementList,
} from "../components/measurement_list";
import {
  destroyMeasurementPlanFormContainer,
  initializeMeasurementPlanFormContainer,
} from "../components/measurement_plan_form";
import {
  destroyMeasurementPlanList,
  initializeMeasurementPlanList,
} from "../components/measurement_plan_list";
import {
  destroyOrganizationAssignAssetForm,
  destroyOrganizationsAutocomplete,
  initializeOrganizationAssignAssetFrom,
  initializeOrganizationsAutocomplete,
} from "../components/organizations";
import {
  destroyOrganizationForm,
  initializeOrganizationForm,
} from "../components/organizations/organization_form";
import {
  destroyReportList,
  initializeReportList,
} from "../components/report_list";
import {
  destroyReportPlanList,
  initializeReportPlanList,
} from "../components/report_plan_list";
import {
  destroyRoleDefinitionForm,
  initializeRoleDefinitionForm,
} from "../components/role_definition_form";
import {
  destroySensorValueRangesForm,
  initializeSensorValueRangesForm,
} from "../components/sensor_value_ranges";
import {
  destroySensorAssignAssetFrom,
  destroySensorForm,
  destroySensorsAutocomplete,
  destroySensorsList,
  initializeSensorAssignAssetFrom,
  initializeSensorForm,
  initializeSensorList,
  initializeSensorsAutocomplete,
} from "../components/sensors";
import {
  destroyUserRoleForm,
  initializeUserRoleForm,
} from "../components/user_role_form";
import {
  destroyWidgetEditorForm,
  initializeWidgetEditorForm,
} from "../components/widgets/widget_editor";
import { hideShowScrollUpButtons as hideShowScrollUpButton } from "../initializers/scroll_down_show";
import { WIDGET_CLASSES } from "../widgets";

import {
  destroyDeviceLogTable,
  initializeDeviceLogTable,
} from "../components/device_logs";

import {
  destroyAssetEventTypeDetails,
  destroyAssetEventTypeForms,
  destroyAssetEventTypesList,
  initializeAssetEventTypeDetails,
  initializeAssetEventTypeForm,
  initializeAssetEventTypesList,
} from "../components/asset_event_types";
import {
  destroyAssetEventDetails,
  destroyAssetEventForms,
  destroyAssetEventsList,
  initializeAssetEventDetails,
  initializeAssetEventForm,
  initializeAssetEventsList,
} from "../components/asset_events";
import {
  destroyAssetTypesHome,
  destroyAssetTypesIndex,
  initAssetTypeHome,
  initAssetTypeIndex,
} from "../pages/asset_types";
import {
  destroyAssetTypesSensorTypesList,
  initAssetTypesSensorTypesList,
} from "../components/asset_types_sensor_types";
import { destroyCommentForm, initCommentForm } from "../components/comments";
import {
  destroySimpleDataTable,
  initializeSimpleDataTable,
} from "../components/common/simple_data_table";
import {
  destroyContextStateChangeList,
  initContextStateChangeList,
} from "../components/context_state_changes";
import { destroyDashboards, initDashboard } from "../components/dashboard";
import {
  destroyEventPatternForm,
  initializeEventPatternForm,
} from "../components/event_patterns";
import {
  destroyExternalReferenceList,
  initializeExternalReferenceList,
} from "../components/external_references";
import {
  destroyFileAttachmentList,
  initFileAttachmentList,
} from "../components/file_attachments/file_attachment_list";
import {
  destroyProductModelAssetTemplateSettingsList,
  initProductModelAssetTemplateSettingsList,
} from "../components/product_model_asset_template_settings";
import {
  destroyProductModelForm,
  destroyProductModelList,
  initializeProductModelForm,
  initializeProductModelList,
} from "../components/product_models";
import {
  destroyReportSpecEditorForm,
  initializeReportSpecEditorForm,
} from "../components/report_specs";
import { initializeSubscriptionPeriodList } from "../components/subscription_periods";
import {
  destroySubscriptionCards,
  initializeSubscriptionCards,
} from "../components/subscriptions";
import {
  destroyUserGroupAddUserForm,
  initializeUserGroupAddUserFrom,
} from "../components/user_groups";
import {
  destroyUsersAutocomplete,
  initializeUsersAutocomplete,
} from "../components/users";
import { ComponentCallback, ComponentInitializer } from "../definitions";
import { showCookiesConsent } from "../initializers/cookies_directive";
import {
  destroyEventPatternIndexPage,
  initializeEventPatternIndexPage,
} from "../pages/event_patterns";
import { configure } from "../routes";
import { sendData } from "../utils/jquery_helper";
import {
  destroyMqttUserEditPage,
  destroyMqttUserIndexPage,
  initializeMqttUsersEditPage,
  initializeMqttUsersIndexPage,
} from "../pages/mqtt_users";

// bind unload dialog
bindUnloadCallbacks();

const initializers: Array<ComponentInitializer> = [
  {
    component: "NotificationsDropdown",
    fn: NotificationsDropdown.initializeNotificationDropdown,
    destroyFn: NotificationsDropdown.destroy,
    elementSelector: "[data-toggle=notifications]",
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MaintenanceDueDropdown",
    fn: MaintenanceDueDropdown.initializeMaintenanceDropdown,
    destroyFn: MaintenanceDueDropdown.destroy,
    elementSelector: "[data-toggle=maintenance-due]",
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "TimeDurationInput",
    fn: initializeTimeDurationInput,
    destroyFn: destroyTimeDurationInput,
    elementSelector: '[data-toggle="time-duration"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "TimeRangePicker",
    fn: initializeTimeRangePicker,
    destroyFn: destroyTimeRangePicker,
    elementSelector: '[data-toggle="timerangepicker"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "DiagramSettings",
    fn: initializeDiagramSettings,
    destroyFn: destroyDiagramSettings,
    elementSelector: '[data-toggle="diagram-settings"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "Dashboard",
    fn: initDashboard,
    destroyFn: destroyDashboards,
    elementSelector: '[data-toggle="dashboard-container"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "ReportList",
    fn: initializeReportList,
    destroyFn: destroyReportList,
    elementSelector: '[data-toggle="report-list"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "ReportPlanList",
    fn: initializeReportPlanList,
    destroyFn: destroyReportPlanList,
    elementSelector: '[data-toggle="report-plan-list"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MeasurementPlanList",
    fn: initializeMeasurementPlanList,
    destroyFn: destroyMeasurementPlanList,
    elementSelector: '[data-toggle="measurement-plan-list"]',
    runOnLoad: true,
    runOnUnload: false,
  },
  {
    component: "MeasurementContainer",
    fn: initializeMeasurementContainer,
    destroyFn: destroyMeasurementContainer,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MeasurementForm",
    fn: initializeMeasurementForms,
    destroyFn: destroyMeasurementForms,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MeasurementPlanFormContainer",
    fn: initializeMeasurementPlanFormContainer,
    destroyFn: destroyMeasurementPlanFormContainer,
    elementSelector: '[data-toggle="measurement-plan-form"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MeasurementList",
    fn: initializeMeasurementList,
    destroyFn: destroyMeasurementList,
    elementSelector: '[data-toggle="measurement-list"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MeasurementItem",
    fn: initializeMeasurementItem,
    destroyFn: destroyMeasurementItems,
    elementSelector: '[data-toggle="measurement-item-view"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "RatingInput",
    fn: initializeRatingInputs,
    destroyFn: destroyRatingInputs,
    elementSelector: '[data-toggle="rating-input"]',
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MaintenancePlanContainer",
    fn: initializeMaintenancePlanContainer,
    destroyFn: destroyMaintenancePlanContainer,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "Clipboard",
    fn: initializeClipboard,
    destroyFn: destroyClipboard,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MaintenanceContainer",
    fn: initializeMaintenanceContainer,
    destroyFn: destroyMaintenanceContainer,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "MaintenanceButtonToolbar",
    fn: initializeMaintenanceButtonToolbar,
    destroyFn: destroyMaintenanceButtonToolbar,
    elementSelector: "#maintenance-button-toolbar",
    runOnLoad: true,
    runOnUnload: true,
  },

  {
    component: "SensorDataForm",
    fn: initializeManageSensorDataForm,
    destroyFn: destroyManageSensorDataForm,
    runOnLoad: true,
    runOnUnload: true,
  },

  {
    component: "RoleDefinitionForm",
    fn: initializeRoleDefinitionForm,
    destroyFn: destroyRoleDefinitionForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "UserRoleForm",
    fn: initializeUserRoleForm,
    destroyFn: destroyUserRoleForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "WidgetFormEditor",
    fn: initializeWidgetEditorForm,
    destroyFn: destroyWidgetEditorForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "ReportSpecEditorForm",
    fn: initializeReportSpecEditorForm,
    destroyFn: destroyReportSpecEditorForm,
    runOnLoad: false,
    runOnUnload: false,
  },
  {
    component: "LocationEditor",
    fn: initializeLocationEditor,
    destroyFn: destroyLocationEditor,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "OrganizationForm",
    fn: initializeOrganizationForm,
    destroyFn: destroyOrganizationForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "AssetTemplateForm",
    fn: initializeAssetTemplateForm,
    destroyFn: destroyAssetTemplateForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "OrganizationAutocomplete",
    fn: initializeOrganizationsAutocomplete,
    destroyFn: destroyOrganizationsAutocomplete,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "AssetsAutocomplete",
    fn: initializeAssetsAutocomplete,
    destroyFn: destroyAssetsAutocomplete,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "UsersAutocomplete",
    fn: initializeUsersAutocomplete,
    destroyFn: destroyUsersAutocomplete,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "SensorsAutocomplete",
    fn: initializeSensorsAutocomplete,
    destroyFn: destroySensorsAutocomplete,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "OrganizationAssignAssetForm",
    fn: initializeOrganizationAssignAssetFrom,

    destroyFn: destroyOrganizationAssignAssetForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "SensorForm",
    fn: initializeSensorForm,
    destroyFn: destroySensorForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "SensorValueRangesForm",
    fn: initializeSensorValueRangesForm,
    destroyFn: destroySensorValueRangesForm,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "DeviceAssignAssetForm",
    fn: initializeDeviceAssignAssetForm,
    destroyFn: destroyDeviceAssignAssetForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "DeviceBatchCreateForm",
    fn: initializeDeviceBatchCreateForm,
    destroyFn: destroyDeviceBatchCreateForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "DevicePairing",
    fn: initializeDevicePairingForm,
    runOnLoad: true,
    destroyFn: destroyDevicePairingForm,
    runOnUnload: true,
  },
  {
    component: "SensorAssignAssetForm",
    fn: initializeSensorAssignAssetFrom,
    destroyFn: destroySensorAssignAssetFrom,
    runOnLoad: true,
    runOnUnload: true,
  },

  {
    component: "ContextStateChangesList",
    fn: initContextStateChangeList,
    destroyFn: destroyContextStateChangeList,
    elementSelector: '[data-toggle="context-state-change-list"]',

    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "DeviceLogTable",
    fn: initializeDeviceLogTable,
    destroyFn: destroyDeviceLogTable,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "FileAttachmentList",
    fn: initFileAttachmentList,
    destroyFn: destroyFileAttachmentList,
    elementSelector: '[data-toggle="file-attachment-list"]',
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "ProductModelForm",
    fn: initializeProductModelForm,
    destroyFn: destroyProductModelForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "ProductModelList",
    fn: initializeProductModelList,
    destroyFn: destroyProductModelList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "UserGroupAddUserForm",
    fn: initializeUserGroupAddUserFrom,
    destroyFn: destroyUserGroupAddUserForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "ExternalReferenceList",
    fn: initializeExternalReferenceList,
    destroyFn: destroyExternalReferenceList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "EventPatternForm",
    fn: initializeEventPatternForm,
    destroyFn: destroyEventPatternForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "SensorList",
    fn: initializeSensorList,
    destroyFn: destroySensorsList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetHome",
    fn: initializeAssetHome,
    destroyFn: destroyAssetHome,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetForm",
    fn: initializeAssetForm,
    destroyFn: destroyAssetForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "CommentForm",
    fn: initCommentForm,
    destroyFn: destroyCommentForm,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "SubscriptionCards",
    fn: initializeSubscriptionCards,
    destroyFn: destroySubscriptionCards,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "SubscriptionPeriodList",
    fn: initializeSubscriptionPeriodList,
    destroyFn: initializeSubscriptionCards,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "SimpleDataTable",
    fn: initializeSimpleDataTable,
    destroyFn: destroySimpleDataTable,
    runOnLoad: true,
    runOnUnload: true,
  },
  {
    component: "AssetEventForm",
    fn: initializeAssetEventForm,
    destroyFn: destroyAssetEventForms,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetEventList",
    fn: initializeAssetEventsList,
    destroyFn: destroyAssetEventsList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetEventDetails",
    fn: initializeAssetEventDetails,
    destroyFn: destroyAssetEventDetails,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetEventTypeDetails",
    fn: initializeAssetEventTypeDetails,
    destroyFn: destroyAssetEventTypeDetails,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetEventTypeList",
    fn: initializeAssetEventTypesList,
    destroyFn: destroyAssetEventTypesList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetEventTypeForm",
    fn: initializeAssetEventTypeForm,
    destroyFn: destroyAssetEventTypeForms,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "ProductModelAssetTemplateSettingList",
    fn: initProductModelAssetTemplateSettingsList,
    destroyFn: destroyProductModelAssetTemplateSettingsList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetTypeHome",

    fn: initAssetTypeHome,
    destroyFn: destroyAssetTypesHome,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetTypeIndex",

    fn: initAssetTypeIndex,
    destroyFn: destroyAssetTypesIndex,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "AssetTypesSensorTypesList",
    fn: initAssetTypesSensorTypesList,
    destroyFn: destroyAssetTypesSensorTypesList,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "EventPatternIndexPage",
    fn: initializeEventPatternIndexPage,
    destroyFn: destroyEventPatternIndexPage,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "MqttUsersIndexPage",
    fn: initializeMqttUsersIndexPage,
    destroyFn: destroyMqttUserIndexPage,
    runOnLoad: false,
    runOnUnload: true,
  },
  {
    component: "MqttUsersEditPage",
    fn: initializeMqttUsersEditPage,
    destroyFn: destroyMqttUserEditPage,
    runOnLoad: false,
    runOnUnload: true,
  },
];

const ComponentInitializers: Record<string, ComponentInitializer> = {};
// register initializers to be accessible globally in single html views
each(initializers, (initializer) => {
  if (!isNil(initializer.component)) {
    ComponentInitializers[initializer.component] = initializer;
  }
});

window.SIALOGIC.ComponentInitializers = ComponentInitializers;
window.SIALOGIC.registerInitComponent = (
  componentName: string,
  once = true,
) => {
  SIALOGIC.oneTimeInit(() => {
    SIALOGIC.initComponent(componentName);
  });
};

window.SIALOGIC.initComponent = (componentName: string) => {
  SIALOGIC.ComponentInitializers[componentName]?.fn();
};
window.SIALOGIC.oneTimeInit = (fun: () => void) => {
  SIALOGIC.oneTimeEvent(fun, "turbo:load");
};

window.SIALOGIC.oneTimeEvent = (
  fun: () => void,
  eventName: string = "turbo:load",
  once = true,
) => {
  document.addEventListener(
    eventName,
    () => {
      fun();
    },
    { once },
  );
};
(global as any).SIALOGIC = window.SIALOGIC;

let assetFormController: AssetFormController;

SIALOGIC.initApp = function initializeApp() {
  // set locales for moment and i18n

  window.I18n.locale = gon.locale;
  window.moment.locale(gon.locale);
  initInspinia();

  configure({ default_url_options: { locale: gon.locale } });

  WidgetController.runOnLoad(WIDGET_CLASSES as any[]);
  try {
    TimeRangePicker.initAllDateRangePicker();

    AssetFormController.initAssetForm();

    TimeRangePicker.initAllSamplingRatePicker();
    TimeRangePicker.initAllBeginAtZeroSelector();
  } catch (error) {
    logger.logError(error as Error);
  }

  try {
    $("[data-toggle=dashboard-time-range-picker]").each((index, element) => {
      DashboardTimeRangePicker.initialize($(element));
    });
  } catch (error) {
    logger.logError(error as Error);
  }
  showCookiesConsent();
  try {
    $("[data-toggle=notification-settings]").each((index, element) => {
      new NotificationSettingsController($(element));
    });
  } catch (error) {
    logger.logError(error as Error);
  }

  try {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
      container: "body",
      placement: "auto",
    });
  } catch (err) {
    logger.logError(err as Error);
  }

  $(".navbar-nav a").tooltip({
    trigger: "hover",
    container: "body",
  });

  try {
    $('[data-toggle="image-upload"]').each(function (index, element) {
      new ImageUploadInput(element);
    });
  } catch (err) {
    logger.logError(err as Error);
  }

  execInitializersProtected(filter(initializers, (init) => init.runOnLoad));

  // handle flash messages
  toasts.initializeFlashMessages();
  hideShowScrollUpButton();
};

function execInitializersProtected(
  initializers: Array<ComponentCallback>,
  destroy = false,
) {
  each(initializers, (options) => {
    const fun = destroy ? options.destroyFn : options.fn;
    if (isNil(fun)) {
      return;
    }
    try {
      if (!isNil(options.elementSelector)) {
        void fun($(options.elementSelector));
      } else {
        void fun();
      }
    } catch (error) {
      logger.logError(error as Error);
    }
  });
}

$(document).on("turbo:before-cache", function () {
  try {
    showBeforeUnloadDialog(false);

    if (!isNil(assetFormController)) {
      assetFormController.destroy();
      assetFormController = null;
    }
    $("[data-toggle=tooltip]").tooltip("hide");
    $('[data-toggle="image-upload"]').each(function (
      index,
      element: HTMLImageInputElement,
    ) {
      if (!isNil(element.imageUploadInput)) {
        element.imageUploadInput.destroy();
      }
    });
  } catch (err) {
    logger.logError(err as Error);
  }
});

$(document).on("turbo:before-render", function () {
  try {
    WidgetController.runOnUnload();
  } catch (e) {
    logger.error(e);
  }

  try {
    execInitializersProtected(
      filter(ComponentInitializers, (destr) => destr.runOnUnload),
      true,
    );
  } catch (e) {
    logger.error(e);
  }

  destroyInspinia();
});

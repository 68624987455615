/// <reference types="../../../definitions/index" />;
import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { MeasurementType } from "../../../models/measurement_type";

export interface MeasurementValueHeaderProps {
  measurementType?: MeasurementType;
}

export function MeasurementValueHeader(
  props: MeasurementValueHeaderProps,
): React.ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body2">
          {I18n.t("activerecord.attributes.measurement_value_definition.title")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.value")}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.note")}
        </Typography>
      </Grid>
    </Grid>
  );
}

import { cloneDeep, isNil } from "lodash";
import { Asset } from "./asset";
import {
  MeasurementType,
  MeasurementTypeSubmit,
  MeasurementTypesUnderscore,
} from "./measurement_type";
import { MeasurementValueDefinitionAttributes } from "./measurement_value_definition";

export type NotificationSetting =
  | null
  | "none"
  | "same_day"
  | "one_day_ahead"
  | "two_days_ahead"
  | "one_week_ahead";

export interface MeasurementPlanAttributes {
  id?: string | number;
  measurement_type_id?: number;
  measurement_type_type?: MeasurementTypesUnderscore;
  asset_id?: string | number;
  notification_setting?: NotificationSetting;
  position?: number;
  rrule?: string;
}
export interface MeasurementPlan extends MeasurementPlanAttributes {
  asset?: Asset;
  measurement_type?: MeasurementType;
}

export interface MeasurementPlanWithErrors extends MeasurementPlan {
  errors?: Partial<Record<keyof MeasurementPlan, string>>;
}
export interface MeasurementPlanSubmit extends MeasurementPlan {
  measurement_type_attributes: MeasurementTypeSubmit;
  errors?: string[];
}

export function toSubmitJson(
  measurementPlan: MeasurementPlanSubmit,
): MeasurementPlanSubmit {
  const measurementPlanClone = cloneDeep(measurementPlan);
  const measurementPlanToSubmit: MeasurementPlanSubmit = measurementPlanClone;

  delete measurementPlanClone.errors;
  delete measurementPlanClone.asset;

  const measurementType = measurementPlanClone.measurement_type;
  if (measurementType.type === "MeasurementTypes::IndependentMeasurementType") {
    // no categorizations for non distributions
    delete measurementType.measurement_categorization_id;
  }

  const measurementTypeSubmit: MeasurementTypeSubmit = measurementType;

  const mvds = measurementType.measurement_value_definitions?.map((mvd) => {
    const measurementCategory = mvd.measurement_category;
    return {
      id: mvd.id,
      is_interval: mvd.is_interval,
      max: mvd.max,
      min: mvd.min,
      position: mvd.position,
      title: mvd.title,
      unit: mvd.unit,
      value: mvd.value,
      value_type: mvd.value_type,
      measurement_category_id: measurementCategory?.id
        ? measurementCategory.id
        : mvd.measurement_category_id,
    } as MeasurementValueDefinitionAttributes;
  });

  measurementTypeSubmit.measurement_value_definitions_attributes = mvds;
  delete measurementType.measurement_value_definitions;

  if (!isNil(measurementType.measurement_categorization)) {
    measurementTypeSubmit.measurement_categorization_id =
      measurementType.measurement_categorization.id;
    delete measurementType.measurement_categorization;
  }

  delete measurementPlanClone.measurement_type;
  measurementPlanToSubmit.measurement_type_attributes = measurementType;

  return measurementPlanToSubmit;
}

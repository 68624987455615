/// <reference types="../../definitions/index" />;
"use client";

import { Close, Fullscreen } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Skeleton,
} from "@mui/material";
import { isEmpty, isNil, toString } from "lodash";
import * as React from "react";
import { IBoxFooter } from "../common/ibox";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";
import { WidgetAdminDropdown } from "./widget_admin_dropdown";
import { WidgetBoxFullscreenProps, WidgetBoxProps } from "./widget_box.types";
import { SialogicDialog } from "../common/sialogic_dialog";
import { logger } from "../../utils/logger";

const WidgetBoxFullscreen: React.FunctionComponent<
  React.PropsWithChildren<WidgetBoxFullscreenProps>
> = (props) => {
  return (
    <SialogicDialog
      fullScreen={true}
      open={true}
      onClose={() => {
        props.endFullscreen();
      }}
      title={props.title}
      buttons={
        <Button
          onClick={() => {
            props.endFullscreen();
          }}
        >
          {I18n.t("frontend.close")}
        </Button>
      }
      style={{ maxHeight: "100vh - 100px" }}
    >
      {props.children}
    </SialogicDialog>
  );
};
export const WidgetBox: React.FunctionComponent<
  React.PropsWithChildren<WidgetBoxProps>
> = ({
  noLinks = false,
  ...props
}: React.PropsWithChildren<WidgetBoxProps>) => {
  const [fullscreen, setFullscreen] = React.useState(false);
  const showTitle = !isEmpty(props.title);

  let content = props.loading ? (
    <Skeleton variant="rectangular" height={80} />
  ) : (
    props.children
  );

  // propagate fullscreen information to parent
  React.useEffect(() => {
    props.onFullscreen?.(fullscreen);
  }, [fullscreen]);

  const dashboardContext = React.useContext(DashboardContext);

  if (props.allowFullscreen && fullscreen) {
    return (
      <WidgetBoxFullscreen
        {...props}
        endFullscreen={() => setFullscreen(false)}
      />
    );
  }

  content = <CardContent>{content}</CardContent>;

  if (!isEmpty(props.contentLinkUrl)) {
    const linkProps = {
      href: props.contentLinkUrl,
      title: props.contentTitle,
      target: props.linkTarget,
    };

    content = props.encloseInIBox ? (
      <CardActionArea {...linkProps}>{content}</CardActionArea>
    ) : (
      <Link {...linkProps} underline="none">
        {content}
      </Link>
    );
  }

  const footer = isNil(props.footer) ? null : (
    <IBoxFooter className="py-1">{props.footer}</IBoxFooter>
  );

  const cardActions =
    !isEmpty(props.tools) || dashboardContext?.canEdit ? (
      <>
        {props.tools}
        {props.allowFullscreen ? (
          <IconButton
            key="fullscreen"
            size="small"
            onClick={() => {
              const newFullscreen = !fullscreen;
              setFullscreen(newFullscreen);
            }}
          >
            {fullscreen ? <Close /> : <Fullscreen />}
          </IconButton>
        ) : null}
        {!dashboardContext.canEdit ? null : (
          <WidgetAdminDropdown
            key="admin"
            widgetId={props.widgetId}
            dashboardSettings={props.dashboardSettings}
            requestDebug={
              props.onRequestDebug
                ? props.onRequestDebug
                : () => {
                    logger.log(
                      `Widget ${props.widgetId} ${props.className}:`,
                      props,
                    );
                  }
            }
          />
        )}
      </>
    ) : null;

  const titleVal = showTitle ? (
    !isNil(props.titleLinkUrl) ? (
      <Link
        underline="none"
        href={props.titleLinkUrl}
        target={props.linkTarget}
      >
        {props.title}
      </Link>
    ) : (
      props.title
    )
  ) : null;

  const title =
    props.cardAvatar || showTitle || cardActions ? (
      <CardHeader
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 8,
          paddingTop: 15,
        }}
        avatar={props.cardAvatar}
        title={titleVal}
        subheader={props.subtitle}
        className={props.vertical ? "text-center" : null}
        action={cardActions}
      />
    ) : null;
  if (props.encloseInIBox) {
    content = (
      <Card
        sx={{ height: "100%" }}
        className={`${toString(props.className)}`}
        onClick={props.onClick}
        style={isNil(props.minWidthPx) ? null : { minWidth: props.minWidthPx }}
      >
        {title}
        {content}
        {footer}
      </Card>
    );
  } else {
    content = (
      <div onClick={props.onClick}>
        {title}
        {content}
        {footer}
      </div>
    );
  }

  return content;
};

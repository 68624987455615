import React from "react";
import PropTypes from "prop-types";
import EndAfter from "./After";
import EndOnDate from "./OnDate";

import translateLabel from "../../utils/translateLabel";
import { MenuItem } from "@mui/material";

const End = ({
  id,
  end: { mode, after, onDate, options },
  handleChange,
  translations,
}) => {
  const isOptionAvailable = (option) =>
    !options.modes || options.modes.indexOf(option) !== -1;
  const isOptionSelected = (option) => mode === option;

  return (
    <Box px={3}>
      <Grid container>
        <Grid item sm={3}>
          <TextField
            select
            label={translateLabel(translations, "end.label")}
            name="end.mode"
            id={id}
            value={mode}
            size="small"
            onChange={handleChange}
          >
            {isOptionAvailable("Never") && (
              <MenuItem value="Never">
                {translateLabel(translations, "end.never")}
              </MenuItem>
            )}
            {isOptionAvailable("After") && (
              <MenuItem value="After">
                {translateLabel(translations, "end.after")}
              </MenuItem>
            )}
            {isOptionAvailable("On date") && (
              <MenuItem value="On date">
                {translateLabel(translations, "end.on_date")}
              </MenuItem>
            )}
          </TextField>
        </Grid>

        {isOptionSelected("After") && (
          <EndAfter
            id={`${id}-after`}
            after={after}
            handleChange={handleChange}
            translations={translations}
          />
        )}
        {isOptionSelected("On date") && (
          <EndOnDate
            id={`${id}-onDate`}
            onDate={onDate}
            handleChange={handleChange}
            translations={translations}
          />
        )}
      </Grid>
    </Box>
  );
};

End.propTypes = {
  id: PropTypes.string.isRequired,
  end: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    after: PropTypes.number.isRequired,
    onDate: PropTypes.object.isRequired,
    options: PropTypes.shape({
      modes: PropTypes.arrayOf(PropTypes.oneOf(["Never", "After", "On date"])),
      weekStartsOnSunday: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default End;

import { defaultTo, isNil, toNumber } from "lodash";
import {
  MappedSensor,
  SensorMappingAttributes,
  SvgSensorMapping,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";
import { Action } from "../../actions/actions.types";

export interface AnimationMappingConfig extends SensorMappingAttributes {
  on_threshold?: number;
}

export class AnimationMapping extends BaseMapping<SVGAnimateElement> {
  threshold?: number;
  invert?: boolean;
  constructor(
    config: AnimationMappingConfig,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config);

    this.threshold = config.on_threshold;
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);
    if (isNil(this.element)) return;
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    if (isNil(this.element)) {
      return;
    }
    const value = toNumber(defaultTo(sensorConfig?.value, 0.0));

    // Apply svg transform

    let threshold = this.threshold;
    if (isNil(threshold)) {
      if (
        !isNil(sensorConfig?.range?.max) &&
        !isNil(sensorConfig?.range?.min)
      ) {
        threshold =
          sensorConfig.range.min +
          (sensorConfig.range.max - sensorConfig.range.min) / 2.0;
      } else {
        return;
      }
    }

    if (value >= threshold) {
      if (this.invert) {
        this.element.endElement();
      } else {
        this.element.beginElement();
      }
    } else {
      if (this.invert) {
        this.element.beginElement();
      } else {
        this.element.endElement();
      }
    }
  }
}

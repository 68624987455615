/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AccountTree,
  Add,
  Clear,
  Delete,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridDensity,
  GridRowParams,
} from "@mui/x-data-grid";
import { SingleResourceDoc } from "jsonapi-typescript";
import { defaultTo, isNil, sortedUniq, toInteger, toString } from "lodash";
import * as React from "react";
import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJSONObject,
} from "../../json_api/asset_type";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { ProductModelJSONObject } from "../../json_api/product_model";

import {
  api_asset_type_path,
  asset_type_product_model_path,
  asset_type_product_model_product_model_asset_template_settings_path,
  device_model_path,
  edit_asset_type_product_model_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";

import { useQuery } from "@tanstack/react-query";
import { ManufacturerJSONObject } from "../../json_api/manufacturer";
import {
  PRODUCT_MODEL_ATTACHMENT_GROUPS,
  ProductModelModelFor,
} from "../../models/product_model";
import { dialog } from "../../utils/dialog";
import { redirectTo } from "../../utils/redirection";
import { success } from "../../utils/toasts";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { SialogicDialog } from "../common/sialogic_dialog";
import { FileAttachmentList } from "../file_attachments/file_attachment_list";
import {
  useLoadProductModelsQuery,
  useDeleteProductModel,
} from "./product_model_data";
import { ProductModelForm } from "./product_model_form";
import { ProductModelAssetTemplateSettingList } from "../product_model_asset_template_settings/product_model_asset_template_setting_list";
import { useLoadAssetTypeQuery } from "../asset_type/asset_type_data";
import { i } from "mathjs";

export interface ProductModelListProps {
  canEdit?: boolean;
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  assetTypeId?: IDType;
  assetType?: AssetTypeJSONObject;
  tableHeight?: number;
  maxTableHeight?: string;
  density?: GridDensity;
  enableSearch?: boolean;
  modelFor?: ProductModelModelFor;
  useLongTitle?: boolean;

  onSelect?: (prodModel: ProductModelJSONObject) => void;
  itemActions?: (prodModel: ProductModelJSONObject) => React.ReactElement[];
}

const largeWidth = 250;
const gridColDef = (
  props: ProductModelListProps,
  setShowItemDialog: (id: IDType, mode: "edit" | "show" | "new") => void,
  appContext: SialogicContext,
  deleteProductModel: (id: IDType) => Promise<unknown>,
  showProductModelAssetTemplatesDialog: (id: IDType) => void,
) => {
  return [
    { field: "id", headerName: "#" },

    {
      field: "name",
      headerName: I18n.t("activerecord.attributes.product_model.name"),
      width: largeWidth,
      renderCell: ({ row: productModel }) => (
        <Link
          href={
            productModel.model_for == "device"
              ? device_model_path(toInteger(productModel.id))
              : asset_type_product_model_path(
                  productModel.asset_type_id,
                  toInteger(productModel.id),
                )
          }
        >
          {productModel.name}
        </Link>
      ),
    },
    {
      field: "manufacturer",
      headerName: I18n.t("activerecord.attributes.product_model.manufacturer"),
      width: largeWidth,
      renderCell: ({ row: productModel }) =>
        isNil(productModel.manufacturer) ? null : (
          <Link
            href={
              productModel.model_for == "device"
                ? device_model_path(toInteger(productModel.id))
                : asset_type_product_model_path(
                    productModel.asset_type_id,
                    toInteger(productModel.id),
                  )
            }
          >
            {(productModel.manufacturer as ManufacturerJSONObject)?.name}
          </Link>
        ),
    },
    {
      field: "identifier",
      headerName: I18n.t("activerecord.attributes.product_model.identifier"),
      width: largeWidth,
      renderCell: ({ row: productModel }) =>
        isNil(productModel.identifier) ? null : (
          <Link
            href={
              productModel.model_for == "device"
                ? device_model_path(toInteger(productModel.id))
                : asset_type_product_model_path(
                    productModel.asset_type_id,
                    toInteger(productModel.id),
                  )
            }
          >
            {productModel.identifier}
          </Link>
        ),
    },
    {
      field: "description",
      headerName: I18n.t("activerecord.attributes.product_model.description"),
      width: largeWidth,
    },
    {
      field: "Actions",
      headerName: I18n.t("frontend.actions"),
      type: "actions",
      width: largeWidth,
      getActions: ({ row }) => {
        const actions = props.canEdit
          ? [
              <GridActionsCellItem
                showInMenu
                label={I18n.t(
                  "frontend.product_model.list.product_model_asset_templates_parameters",
                )}
                icon={
                  <Tooltip
                    title={I18n.t(
                      "frontend.product_model.list.product_model_asset_templates_parameters",
                    )}
                  >
                    <AccountTree />
                  </Tooltip>
                }
                onClick={() => {
                  showProductModelAssetTemplatesDialog(row.id);
                }}
                onAuxClick={() => {
                  redirectTo(
                    asset_type_product_model_product_model_asset_template_settings_path(
                      row.asset_type_id,
                      row.id,
                    ),
                  );
                }}
                key="product_model_asset_templates_parameters"
              />,
              <GridActionsCellItem
                label={I18n.t("frontend.edit")}
                icon={
                  <Tooltip title={I18n.t("frontend.edit")}>
                    <Edit />
                  </Tooltip>
                }
                onAuxClick={() => {
                  redirectTo(
                    edit_asset_type_product_model_path(
                      props.assetType?.id || props.assetTypeId,
                      row.id,
                    ),
                    "_blank",
                  );
                }}
                onClick={() => {
                  setShowItemDialog(row.id, "edit");
                }}
                key="edit"
              />,
              <GridActionsCellItem
                label={I18n.t("frontend.show")}
                icon={
                  <Tooltip title={I18n.t("frontend.show")}>
                    <Visibility />
                  </Tooltip>
                }
                onAuxClick={() => {
                  redirectTo(
                    asset_type_product_model_path(
                      props.assetType?.id || props.assetTypeId,
                      row.id,
                    ),
                    "_blank",
                  );
                }}
                onClick={() => {
                  setShowItemDialog(row.id, "show");
                }}
                key="show"
              />,
              <GridActionsCellItem
                showInMenu
                label={I18n.t("frontend.delete")}
                icon={
                  <Tooltip title={I18n.t("frontend.delete")}>
                    <Delete />
                  </Tooltip>
                }
                onClick={() => {
                  dialog
                    .fire({
                      title: I18n.t("frontend.delete_confirm"),
                      text: I18n.t(
                        "frontend.product_model.list.confirm_delete_text",
                      ),
                      icon: "warning",
                      confirmButtonText: I18n.t("frontend.delete"),
                      showCloseButton: true,
                      showCancelButton: true,
                      cancelButtonText: I18n.t("frontend.cancel"),
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        void deleteProductModel(row.id).then(() => {
                          success(
                            I18n.t("frontend.deleted"),
                            I18n.t("frontend.successfully_deleted"),
                          );
                        });
                      }
                    });
                }}
                key="delete"
              />,
            ]
          : [];
        return [...actions, ...(props.itemActions?.(row) || [])];
      },
    },
  ] as GridColDef<ProductModelJSONObject>[];
};

export const ProductModelList: React.FunctionComponent<
  ProductModelListProps
> = ({
  tableHeight = 700,
  pageNumber = 1,
  pageSize = 20,
  maxTableHeight = "75vh",
  enableSearch = true,
  useLongTitle = false,
  ...props
}) => {
  const appContext = React.useContext(AppContext);
  const [pageSizes, setPageSizes] = React.useState(() =>
    sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
  );

  React.useEffect(() => {
    setPageSizes(() =>
      sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
    );
  }, [pageSize]);

  const [searchTermEdit, setSearchTermEdit] = React.useState<string>(null);
  // search term used for actual searching
  const [searchTerm, setSearchTerm] = React.useState<string>(null);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [dialogMode, setDialogMode] = React.useState<"show" | "edit" | "new">();
  const [currentTab, setCurrentTab] = React.useState<string>(null);
  const [selectedProductModelId, setSelectedProductModelId] =
    React.useState<IDType>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize,
    page: pageNumber - 1,
  });
  const [totalItems, setTotalItems] = React.useState<number>(null);
  const [, setTotalPages] = React.useState<number>(null);

  const assetTypeID = props.assetType?.id || props.assetTypeId;

  const { isLoading: assetTypeIsLoading, data: assetType } =
    useLoadAssetTypeQuery({
      variables: { id: assetTypeID },
      enabled: !isNil(assetTypeID),
      initialData: defaultTo(props.assetType, undefined),
    });

  const {
    isLoading: productModelsLoading,
    data: {
      items: productModels,
      totalItems: fetchedTotalItems,
      totalPages: fetchedTotalPages,
    },
  } = useLoadProductModelsQuery({
    variables: {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      modelFor: props.modelFor,
      searchTerm,
      assetTypeId: assetTypeID,
    },
    enabled: !assetTypeIsLoading && !isNil(assetTypeID),
  });

  const [showProductModelAssetTemplates, setShowProductModelAssetTemplates] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setTotalItems(fetchedTotalItems);
  }, [fetchedTotalItems]);
  React.useEffect(() => {
    setTotalPages(fetchedTotalPages);
  }, [fetchedTotalPages]);

  const { mutateAsync: deleteProductModel } = useDeleteProductModel();

  const showModelForm = React.useCallback(
    (id: IDType, mode: "edit" | "show" | "new") => {
      setSelectedProductModelId(id);
      setDialogMode(mode);
      setShowDialog(true);
    },
    [props.canEdit],
  );

  const showProductModelAssetTemplatesDialog = React.useCallback(
    (id: IDType) => {
      setShowProductModelAssetTemplates(true);
      setSelectedProductModelId(id);
    },
    [props.canEdit],
  );

  const gridColDefs = React.useMemo(
    () =>
      gridColDef(
        props,
        showModelForm,
        appContext,
        deleteProductModel,
        showProductModelAssetTemplatesDialog,
      ),
    [
      props,
      setSelectedProductModelId,
      appContext,
      deleteProductModel,
      showProductModelAssetTemplatesDialog,
    ],
  );

  const hideModelForm = React.useCallback(() => {
    setSelectedProductModelId(null);
    setShowDialog(false);
  }, [props.canEdit]);

  return (
    <Card>
      <CardHeader
        title={
          props.assetType?.name && useLongTitle
            ? I18n.t("frontend.product_model.list.heading_for_asset_type", {
                asset_type_name: props.assetType?.name,
              })
            : I18n.t("frontend.product_model.list.heading")
        }
      ></CardHeader>
      <CardContent>
        <Grid container>
          {enableSearch ? (
            <Grid item xs={12} className="mb-1">
              <TextField
                size="small"
                className="float-right"
                value={toString(toString(searchTermEdit))}
                label={I18n.t("frontend.search")}
                onChange={(event) => {
                  setSearchTermEdit(event.currentTarget.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setSearchTerm(searchTermEdit);
                        }}
                      >
                        <Search />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="default"
                        onClick={() => {
                          setSearchTermEdit(null);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(event) => {
                  if (event.key == "Enter") {
                    setSearchTerm(searchTermEdit);
                    event.stopPropagation();
                  }
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
              <DataGrid
                paginationMode="server"
                pagination={isNil(productModels) ? undefined : true}
                paginationModel={paginationModel}
                initialState={{
                  density: defaultTo(props.density, "standard"),
                  columns: { columnVisibilityModel: { id: false } },
                }}
                rowCount={defaultTo(totalItems, 0)}
                pageSizeOptions={pageSizes}
                rows={defaultTo(productModels, [] as ProductModelJSONObject[])}
                columns={gridColDefs}
                loading={productModelsLoading}
                onRowDoubleClick={(
                  params: GridRowParams<ProductModelJSONObject>,
                ) => {
                  if (!isNil(props.onSelect)) {
                    props.onSelect(params.row);
                  } else {
                    showModelForm(
                      params.row.id,
                      props.canEdit ? "edit" : "show",
                    );
                  }
                }}
                onPaginationModelChange={(paginationModel) =>
                  setPaginationModel(paginationModel)
                }
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {props.canEdit ? (
        <CardActions>
          <Button
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              showModelForm(null, "new");
            }}
          >
            {I18n.t("frontend.add")}
          </Button>
        </CardActions>
      ) : null}

      {showDialog ? (
        <SialogicDialog open={showDialog} maxWidth="lg" onClose={hideModelForm}>
          <Tabs
            value={currentTab || "modelForm"}
            onChange={(e, newValue) => {
              setCurrentTab(newValue as string);
            }}
            aria-label="Product Model Tabs"
          >
            <Tab
              label={I18n.t("frontend.product_model.form.form")}
              value="modelForm"
            />
            {!isNil(selectedProductModelId) ? (
              <Tab
                label={I18n.t("frontend.product_model.form.attachments")}
                value="attachmentList"
              />
            ) : null}
          </Tabs>
          {!currentTab || currentTab == "modelForm" ? (
            <ProductModelForm
              buttonMode="card"
              assetType={assetType}
              assetTypeId={assetType?.id}
              productModelId={selectedProductModelId}
              readOnly={!props.canEdit || dialogMode == "show"}
              mode={dialogMode}
              modelFor={props.modelFor}
              onCancel={hideModelForm}
              onSuccess={hideModelForm}
            />
          ) : null}
          {!isNil(selectedProductModelId) && currentTab == "attachmentList" ? (
            <Box minHeight={500}>
              <FileAttachmentList
                itemType="ProductModel"
                itemId={selectedProductModelId}
                allowDelete={props.canEdit}
                allowEdit={props.canEdit}
                allowUpload={props.canEdit}
                groups={PRODUCT_MODEL_ATTACHMENT_GROUPS}
              />
            </Box>
          ) : null}
        </SialogicDialog>
      ) : null}

      {showProductModelAssetTemplates ? (
        <SialogicDialog
          open={showProductModelAssetTemplates}
          maxWidth="lg"
          onClose={() => setShowProductModelAssetTemplates(false)}
        >
          <ProductModelAssetTemplateSettingList
            productModelId={selectedProductModelId}
            canEdit={props.canEdit}
          />
        </SialogicDialog>
      ) : null}
    </Card>
  );
};

import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { AssetTypeJSONObject } from "./asset_type";
import { SensorTypeJSONObject } from "./sensor_type";
import { AssetTypesSensorType } from "../models/asset_types_sensor_type";

export const ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_TYPE =
  "asset_types_sensor_types";
export interface AssetTypesSensorTypeJSONObject
  extends AssetTypesSensorType,
    JSON.Object {
  asset_type?: AssetTypeJSONObject;
  sensor_type?: SensorTypeJSONObject;
}

export type AssetTypesSensorTypeJSONAPIAttributes =
  JSONAPI.AttributesObject<AssetTypesSensorTypeJSONObject>;

export const ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_UPDATABLE_ATTRIBUTES = [
  "default_attribute_key_type",
  "creation_scope",
  "default_derived",
  "default_sampling_rate_unit",
  "default_context",
  "default_context2",
  "default_import_formula",
  "default_import_formula_target_key",
  "default_name",
  "default_short_name",
  "default_description",
  "default_unit",
  "default_display_unit",
  "default_attribute_key",
  "default_min",
  "default_max",
  "default_precision",
  "default_sampling_rate_value",
  "initial_value",
  "default_exp_data_period",
  "position",
];

export const ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_CREATABLE_ATTRIBUTES =
  ASSET_TYPES_SENSOR_TYPES_JSONAPI_RESOURCE_UPDATABLE_ATTRIBUTES;

import { FastForward } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";

import {
  each,
  flatten,
  flattenDeep,
  get,
  isEmpty,
  isNil,
  map,
  reject,
  some,
  sum,
} from "lodash";
import moment from "../../../initializers/moment";
import { Moment } from "moment";

import * as React from "react";

import { TreeItem } from "../../../models/tree_item";
import { ErrorMap } from "../../../utils/error_map";

import { MaterialUiDatePicker } from "../../common/date_picker";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent } from "../../common/ibox";
import { MaintenanceButtonToolbar } from "../../common/maintenance/maintenance_button_toolbar";
import { MaintenancePlanFilterSelection } from "../../maintenance_plan_form/data/models";
import { MaintenanceJob, MaintenanceJobGroup, User } from "../data/model";
import { MaintenanceJobGroupList } from "./maintenance_job_group_list";
import { UserGuestUserAutocomplete } from "./user_guest_user_autocomplete";

interface MaintenanceFormProps {
  doneAt?: Moment;
  note?: string;
  defaultUser?: User;
  maintenanceJobGroups: MaintenanceJobGroup[];
  assetTree: TreeItem;
  users: User[];
  isProcessing?: boolean;
  errors?: ErrorMap;
  filter?: MaintenancePlanFilterSelection;
  onSubmit: () => void;
  onCancel: () => void;
  onUpdateMaintenanceJob: (
    maintenanceJobGroupIndex: number,
    assetIndex: number,
    maintenanceIndex: number,
    maintenanceJob: MaintenanceJob,
  ) => void;
  onUpdateDoneAt: (doneAt: Moment) => void;
  onUpdateNote: (note: string) => void;
  onGuestUserAdded: (name: string, newUser: User) => void;
  onSetDefaultUser: (user: User) => void;
  onApplyDefaultUser: () => void;
  onToggleAll: (expanded: boolean) => void;
  onToggle: (
    maintenanceJobGroupIndex: number,
    assetIndex: number,
    expanded: boolean,
  ) => void;
  onFilterChanged: (filter: MaintenancePlanFilterSelection) => void;
}

/**
 * Returns first possible date that can be selected for a maintenance.
 * It determines the start of the first maintenance period of all maintenance jobs.
 * @param jobGroups
 */
function minimumPeriodStartDate(jobGroups: MaintenanceJobGroup[]): Moment {
  const dates = reject(
    flattenDeep(
      jobGroups.map((jobGroup) =>
        jobGroup.assets.map((assetWithJobs) =>
          assetWithJobs.maintenance_jobs.map((job) =>
            job.maintenance_period_is_initial_period
              ? null
              : job.maintenance_period_starts_at,
          ),
        ),
      ),
    ),
    isNil,
  );
  if (isEmpty(dates)) {
    return null;
  } else {
    return moment.min(dates);
  }
}

/**
 * Returns wether the form contains a maintenance job with changes(job executed, status value changed or status note entered)
 * @param props Props of maintenance form
 */
export function hasModifications(
  maintenanceJobGroups: MaintenanceJobGroup[],
): boolean {
  return some(maintenanceJobGroups, (jobGroup) =>
    some(jobGroup.assets, (assetWithJob) =>
      some(assetWithJob.maintenance_jobs, (job) => job.executed),
    ),
  );
}

export function inspectionCount(
  maintenanceJobGroups: MaintenanceJobGroup[],
): number {
  return sum(
    maintenanceJobGroups.map((jobGroup) => {
      let count = 0;
      each(jobGroup.assets, (assetWithJob) => {
        each(assetWithJob.maintenance_jobs, (job) => {
          if (job.executed && job.type === "InspectionJob") count++;
        });
      });
      return count;
    }),
  );
}

export function maintenanceJobCount(
  maintenanceJobGroups: MaintenanceJobGroup[],
): number {
  return sum(
    maintenanceJobGroups.map((jobGroup) => {
      let count = 0;
      each(jobGroup.assets, (assetWithJob) => {
        each(assetWithJob.maintenance_jobs, (job) => {
          if (job.executed && job.type === "MaintenanceJob") count++;
        });
      });

      return count;
    }),
  );
}

function maintenanceJobGroupId(
  maintenanceJobGroup: MaintenanceJobGroup,
  index: number,
) {
  return `maintenance-job-group-${index}`;
}

export function MaintenanceForm({
  note = "",
  isProcessing = false,
  ...props
}: MaintenanceFormProps): JSX.Element {
  const disableSave = !hasModifications(props.maintenanceJobGroups);
  const assets = flatten(
    props.maintenanceJobGroups.map((mjg) =>
      mjg.assets.map(
        (assetWithMaintenanceJob) => assetWithMaintenanceJob.asset,
      ),
    ),
  );
  let filter = props.filter;
  if (isNil(filter)) {
    filter = {
      maintenancePlanTypes: ["InspectionPlan", "MaintenancePlan"],
      assetIds: assets.map((a) => parseInt(a.id as string)),
    };
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaintenanceButtonToolbar
          filter={props.filter}
          jumpToTitlesIds={map(props.maintenanceJobGroups, (jg, index) => [
            jg.title,
            maintenanceJobGroupId(jg, index),
          ])}
          assets={assets}
          onFilterChanged={props.onFilterChanged}
          onToggleAll={props.onToggleAll}
        />
      </Grid>
      <div className="clearfix" />

      {isNil(get(props.errors, "base")) ? null : (
        <Grid item xs={12}>
          <div className="error-report">
            <h5>{I18n.t("frontend.maintenance_form.base_errors_heading")}</h5>
            <ul>
              <li>{get(props.errors, "base") as string}</li>
            </ul>
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        <IBox>
          <IBoxContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl>
                  <MaterialUiDatePicker
                    label={I18n.t(
                      "activerecord.attributes.maintenance.done_at",
                    )}
                    autoApply
                    type="datetime"
                    name="doneAt"
                    size="small"
                    dateFormat="L LT"
                    minDate={minimumPeriodStartDate(props.maintenanceJobGroups)}
                    maxDate={moment().endOf("day")}
                    error={
                      (get(props.errors, "done_at") as string) ??
                      (get(props.errors, "maintenance_jobs.done_at") as string)
                    }
                    value={
                      props.doneAt && moment.isMoment(props.doneAt)
                        ? props.doneAt
                        : moment(props.doneAt)
                    }
                    onChange={(date) => props.onUpdateDoneAt(date)}
                  />
                  <FormHelperText>
                    {(get(props.errors, "done_at") as string) ||
                      (get(props.errors, "maintenance_jobs.done_at") as string)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={11}>
                    <UserGuestUserAutocomplete
                      label={I18n.t("frontend.maintenance_form.executed_by")}
                      user={props.defaultUser}
                      users={props.users}
                      onUserSelect={(userName, selectedUser) => {
                        if (!selectedUser.id) {
                          // add the user to the list of users to make it available for selection
                          props.onGuestUserAdded(userName, selectedUser);
                        }

                        // we do not want to reset all possibly entered users to null. So do not apply null values
                        if (!isNil(selectedUser)) {
                          props.onSetDefaultUser(selectedUser);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip
                      title={I18n.t(
                        "frontend.maintenance_form.apply_executed_by",
                      )}
                    >
                      <IconButton
                        onClick={props.onApplyDefaultUser}
                        color="primary"
                      >
                        <FastForward
                          fontSize="small"
                          className="apply-executed-by"
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  size="small"
                  name="note"
                  label={I18n.t("activerecord.attributes.maintenance.note")}
                  value={note || ""}
                  onChange={(event) => props.onUpdateNote(event.target.value)}
                />
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </Grid>

      <Grid item xs={12} className="mt-2">
        <div id="accordion">
          {props.maintenanceJobGroups.map(
            (maintenanceJobGroup, maintenanceJobGroupIndex) => {
              return (
                <MaintenanceJobGroupList
                  id={maintenanceJobGroupId(
                    maintenanceJobGroup,
                    maintenanceJobGroupIndex,
                  )}
                  key={maintenanceJobGroupIndex}
                  maintenanceJobGroup={maintenanceJobGroup}
                  users={props.users}
                  onUpdateMaintenanceJob={(
                    assetIndex: number,
                    maintenanceJobIndex: number,
                    maintenanceJob: MaintenanceJob,
                  ) =>
                    props.onUpdateMaintenanceJob(
                      maintenanceJobGroupIndex,
                      assetIndex,
                      maintenanceJobIndex,
                      maintenanceJob,
                    )
                  }
                  onGuestUserAdded={(name: string, newUser: User) =>
                    props.onGuestUserAdded(name, newUser)
                  }
                  onToggle={(assetIndex: number, expanded: boolean) =>
                    props.onToggle(
                      maintenanceJobGroupIndex,
                      assetIndex,
                      expanded,
                    )
                  }
                />
              );
            },
          )}
        </div>
      </Grid>

      <FixedBottomArea>
        <FloatingButtons
          isProcessing={isProcessing}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
          disableSave={disableSave}
          showScrollToTopBtn={true}
          saveTitle={I18n.t("frontend.maintenance_form.submit_title", {
            maintenance_count: maintenanceJobCount(props.maintenanceJobGroups),
            inspection_count: inspectionCount(props.maintenanceJobGroups),
          })}
        />
      </FixedBottomArea>
    </Grid>
  );
}

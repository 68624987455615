import { isEmpty, remove } from "lodash";

/**
 * A rendererbale objecet
 */
export interface Renderable {
  /**
   * Render a single frame
   */
  render(): void;
}

/**
 * The render manager centralizes all render update loops.
 */
export class RenderManager {
  static renderingRunning = false;
  static rendererList: Renderable[] = [];

  /**
   * Add a renderable object to the list of active renderer
   * and automatically start rendering.
   * @param renderer A renderable object
   */
  static addRenderer(renderer: Renderable): void {
    RenderManager.rendererList.push(renderer);

    if (!isEmpty(RenderManager.rendererList)) {
      RenderManager.startRendering();
    }
  }

  /**
   * Remove a renderable object from the list of active renderer
   * and stop rendering if there are no more renderer.
   * @param renderer A renderable object
   */
  static removeRenderer(renderer: Renderable): void {
    remove(RenderManager.rendererList, (i) => i === renderer);

    if (isEmpty(RenderManager.rendererList)) {
      RenderManager.stopRendering();
    }
  }

  /**
   * Removes all renderable objects from the list and stops rendering
   */
  static removeAllRenderer(): void {
    RenderManager.rendererList.splice(0, RenderManager.rendererList.length);
    RenderManager.stopRendering();
  }

  /**
   * Returns wether rendering is running
   */
  static isRenderingRunning(): boolean {
    return RenderManager.renderingRunning;
  }

  /**
   * Start the rendering. Nothing happens if the render loop is already running.
   */
  static startRendering() {
    if (RenderManager.renderingRunning) {
      return;
    }

    RenderManager.renderingRunning = true;
    RenderManager.render();
  }

  /**
   * Stop the render loop. The render loop is not stopped immediatly,
   * but on the next animation frame.
   */
  static stopRendering() {
    RenderManager.renderingRunning = false;
  }

  static render(): React.ReactNode {
    if (!RenderManager.renderingRunning) {
      return;
    }

    // request next animation frame
    requestAnimationFrame(RenderManager.render);

    // render each object
    RenderManager.rendererList.forEach((renderer) => renderer.render());
  }
}

import { IDType } from "../utils/urls/url_utils";
import { NamedItem } from "./base";

export interface Device extends NamedItem {
  id?: number;
  name?: string;
  uid?: string;
  description?: string;
  asset_id?: number;
  organization_id?: number;

  pairing_start: string | Date;
  pairing_end: string | Date;
  enabled?: boolean;
  hw_ver?: string;
  sw_ver?: string;
  os_ver?: string;
  middleware_ver?: string;
  product_model_id?: IDType;
}

export interface DeviceWithPairingInfo extends Device {
  paring_start: Date;
  paring_end: Date;

  pairing_initiated_by_id?: number;
}

export const DeviceUidRegexp = /^[A-z|a-z_\-.0-9@]+$/;

import { CircularProgress, Grid, MenuItem, TextField } from "@mui/material";
import JSONSchemaValidator from "@rjsf/validator-ajv8";
import { JSONSchema4 } from "json-schema";
import * as JSONType from "json-typescript";
import {
  cloneDeep,
  defaultTo,
  isEmpty,
  isNil,
  map,
  toInteger,
  toString,
} from "lodash";
import * as React from "react";

import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { Form as MuiForm } from "../common/json_schema_form/form";

import { ReportSpecJSONAPIAttributes } from "../../json_api/report_spec";
import { api_report_spec_path, api_report_specs_path } from "../../routes";
import { sendJsonApiData } from "../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../utils/jsonapi_form_tools";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";

export interface ReportSpecIdentifiableItem {
  title: string;
  identifier: string;
  id?: number;
}

export interface WidgetTypeInfo {
  name: string;
  identifier: string;
  description?: string;
}

export interface ReportSpecEditFormProps {
  organizationId: number;
  schema?: JSONType.Object;
  spec?: ReportSpecJSONAPIAttributes;
  referer?: string;
  sensorTypes?: ReportSpecIdentifiableItem[];
  assetTypes?: ReportSpecIdentifiableItem[];
  sensorContexts?: string[];
}

export interface ReportSpecEditorState {
  dataLoaded: boolean;
  schema?: JSONType.Object;
  config?: JSONType.Object;
  loading?: boolean;
}

function processSchema(
  sourceSchema: JSONSchema4,
  props: ReportSpecEditFormProps,
): JSONSchema4 {
  if (isNil(sourceSchema)) {
    return null;
  }
  const newSchema = cloneDeep(sourceSchema);
  const definitions = (newSchema?.definitions ??
    newSchema.$defs) as JSONType.Object;
  // populate "sensor_type" list
  if (
    !isEmpty(props.sensorTypes) &&
    definitions &&
    definitions?.["sensor_type"]
  ) {
    const sensorTypeDefinition = definitions["sensor_type"] as JSONSchema4;
    if (
      sensorTypeDefinition.type === "string" &&
      isEmpty(sensorTypeDefinition.enum)
    ) {
      sensorTypeDefinition.oneOf = map(props.sensorTypes, (st) => ({
        const: st.identifier,
        title: st.title,
      }));
    }
  }

  // populate "sensor_type" list
  if (definitions && definitions?.["sensor_context"]) {
    const contextDefinition = definitions?.["sensor_context"] as JSONSchema4;

    if (
      contextDefinition.type === "string" &&
      isEmpty(contextDefinition.enum)
    ) {
      contextDefinition.enum = props.sensorContexts;
    }
  }

  return newSchema;
}
export const ReportSpecEditForm: React.FunctionComponent<
  ReportSpecEditFormProps
> = (props) => {
  const [spec, setSpec] = React.useState(defaultTo(props.spec, {}));
  const [schema, setSchema] = React.useState<JSONType.Object>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSchema(processSchema(props.schema, props));
  }, [props.schema]);

  const submit = React.useCallback(
    async (spec: ReportSpecJSONAPIAttributes) => {
      const { submitData, mode } = buildJsonApiSubmitData(
        spec,
        "report_specs",
        ["spec", "name"],
      );
      submitData.data.relationships = {
        asset_type: {
          data: { type: "asset_types", id: toString(spec.asset_type_id) },
        },
      };
      const url =
        mode == "create"
          ? api_report_specs_path()
          : api_report_spec_path(spec.id);
      const result = await sendJsonApiData(
        url,
        submitData,
        mode == "create" ? "POST" : "PATCH",
      );
    },
    [],
  );

  if (loading) {
    return (
      <IBox>
        <IBoxTitle>Loading ...</IBoxTitle>
        <IBoxContent>
          <CircularProgress className="d-print-none" size={50} />
        </IBoxContent>
      </IBox>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label={I18n.t("activerecord.attributes.report_spec.name")}
          value={spec.name}
          fullWidth
          onChange={(e) => setSpec({ ...spec, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          value={toString(spec.asset_type_id)}
          label={I18n.t("activerecord.attributes.report_spec.asset_type")}
          onChange={(e) =>
            setSpec({ ...spec, asset_type_id: toInteger(e.target.value) })
          }
        >
          {props.assetTypes?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {isEmpty(schema) ? null : (
        <Grid item xs={12}>
          <IBox>
            <IBoxTitle>
              {I18n.t(
                "frontend.report_specs.report_spec_editor.report_spec_heading",
              )}
            </IBoxTitle>
            <IBoxContent>
              <MuiForm
                validator={JSONSchemaValidator}
                schema={schema}
                formData={spec?.spec || {}}
                disabled={loading}
                onChange={(event) =>
                  setSpec({
                    ...spec,
                    spec: event.formData as JSONType.Object,
                  })
                }
                showErrorList={"top"}

                //disabled={!this.props.editable  }
              >
                <div />
              </MuiForm>
            </IBoxContent>
          </IBox>
        </Grid>
      )}

      <FixedBottomArea id="fixed-bottom-area">
        <FloatingButtons
          isProcessing={loading}
          onSubmit={() => {
            submit(spec)
              .then(() => {
                void success(
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.success_title",
                  ),
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.success_message",
                  ),
                );
                redirectTo(defaultTo(props.referer, "back"));
              })
              .catch((err: Error) => {
                logger.error(err);
                void error(
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.error_title",
                  ),
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.error_message",
                    { error: err.message },
                  ),
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          onCancel={() => {
            setLoading(false);
          }}
          disableSave={loading}
          showScrollToTopBtn={true}
          saveTitle={I18n.t("base.save")}
        />
      </FixedBottomArea>
    </Grid>
  );
};

import { TextField } from "@mui/material";
import { defaultTo, first, isEmpty, isNil } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { DateRangePickerOptions } from "../../definitions/bootstrap-daterangepicker";
import moment from "../../initializers/moment";
import {
  createTimeRanges,
  TranslatedTimeScopeWithReverseMap,
} from "../../utils/time_scopes";

export type DatePickerHTMLElement = HTMLInputElement & {
  dateRange: [Moment, Moment];
};

export interface DateRangePickerProps {
  type?: "date" | "datetime";
  name?: string;
  opens?: "left" | "right" | "center";
  autoApply?: boolean;
  label?: string;
  "aria-label"?: string;
  /** The selected date range
   *
   *
   * @type {[Moment, Moment]}
   * @memberof DateRangePickerProps
   */
  value?: [Moment, Moment];
  dateFormat?: string;
  pickSeconds?: boolean;
  ranges?: TranslatedTimeScopeWithReverseMap;
  locale?: string;
  error?: string;
  /** Maximum selectable date
   *
   *
   * @type {Moment}
   * @memberof DateRangePickerProps
   */
  maxDate?: Moment;
  /** Minimum selectable date
   *
   *
   * @type {Moment}
   * @memberof DateRangePickerProps
   */
  minDate?: Moment;
  /** Called whenever a new time range isSelected, timeRangeId  */
  onChange?: (newValue: {
    dateRange: [Moment, Moment];
    stringValue: string;
    timeRangeId: string;
  }) => void;
  required?: boolean;
}

function formatDateRange(
  startDate: Date | Moment,
  endDate: Date | Moment,
  dateFormat: string,
): string {
  return `${moment(startDate)
    .locale(I18n.locale)
    .format(dateFormat)} - ${moment(endDate)
    .locale(I18n.locale)
    .format(dateFormat)}`;
}

function mountDatePicker(
  props: DateRangePickerProps,
  input: HTMLInputElement,
): void {
  if (!input) {
    return;
  }
  const momentLocale = moment.localeData();
  const pickerOptions: DateRangePickerOptions = {
    singleDatePicker: false,
    timePicker: props.type === "datetime",
    showDropdown: true,
    timePicker24Hour: true,
    timePickerSeconds: defaultTo(props.pickSeconds, false),
    locale: {
      format: defaultTo(props.dateFormat, "L LT"),
      customRangeLabel: I18n.t(
        "frontend.time_range_picker.select_custom_range",
      ),

      fromLabel: I18n.t("frontend.from"),
      toLabel: I18n.t("frontend.to"),
      applyLabel: I18n.t("frontend.apply"),
      cancelLabel: I18n.t("frontend.close"),
      monthNames: momentLocale.months(),
      daysOfWeek: momentLocale.weekdaysShort(),
      firstDay: momentLocale.firstDayOfWeek(),
    },
    ranges: props.ranges?.ranges,
    opens: props.opens,
    autoApply: props.autoApply,
    autoUpdateInput: false,

    startDate: first(props.value),
    endDate: props.value?.[1],
    maxDate: defaultTo(props.maxDate?.toDate(), new Date(2100, 0)),
    minDate: defaultTo(props.minDate?.toDate(), new Date(2000, 0)),
  };
  $(input).daterangepicker(
    pickerOptions,
    (startDate: Date, endDate: Date, label: string) => {
      if (isNil(props.onChange)) {
        return;
      }

      props.onChange({
        stringValue: formatDateRange(startDate, endDate, props.dateFormat),
        dateRange: [moment(startDate), moment(endDate)],
        timeRangeId: props.ranges?.labelToIdMap?.[label] || "custom",
      });
    },
  );
}

function updateComponent(
  props: DateRangePickerProps,
  input: HTMLInputElement,
): void {
  if (!input) {
    return;
  }
  const dateRangePicker = $(input).data("daterangepicker");

  dateRangePicker.minDate = defaultTo(props.minDate, false);

  dateRangePicker.maxDate = defaultTo(props.maxDate, false);

  dateRangePicker.updateView();
}

/**
 * React wrapper for the boostrap-daterangepicker
 */
export class BootstrapDateRangePicker extends React.Component<DateRangePickerProps> {
  static defaultProps: DateRangePickerProps = {
    dateFormat: "L",
    autoApply: false,
    value: [null, null],
  };

  inputElem: HTMLInputElement;

  constructor(props: DateRangePickerProps) {
    super(props);
  }

  componentDidMount(): void {}

  componentDidUpdate() {
    updateComponent(this.props, this.inputElem);
  }

  componentWillUnmount(): void {
    if (this.inputElem) {
      $(this.inputElem).data("daterangepicker")?.remove();
    }
  }
  render(): React.ReactNode {
    return (
      <input
        className={
          !isEmpty(this.props.error)
            ? "form-control is-invalid"
            : "form-control"
        }
        type="text"
        ref={(input: HTMLInputElement) => {
          if (this.inputElem && this.inputElem !== input) {
            $(this.inputElem).data("daterangepicker")?.remove();
          }
          this.inputElem = input;
          mountDatePicker(this.props, input);
        }}
        value={formatDateRange(
          this.props.value[0],
          this.props.value[1],
          this.props.dateFormat,
        )}
        title={this.props["aria-label"]}
        //onChange={this.props.onChange}
        name={this.props.name}
      />
    );
  }
}

export interface MaterialUiDateRangePickerProps extends DateRangePickerProps {
  size?: "medium" | "small";
  helperText?: string;
  endAdornment?: React.ReactElement;
  startAdornment?: React.ReactElement;
}
/**
 * React wrapper for the boostrap-daterangepicker using material UI design
 */
export class MaterialUiDateRangePicker extends React.Component<MaterialUiDateRangePickerProps> {
  static defaultProps: MaterialUiDateRangePickerProps = {
    dateFormat: "L",
    size: "small",
    autoApply: false,
    required: false,
    value: [null, null],
    minDate: moment(new Date(2000, 0, 0)),
    maxDate: moment(new Date(2100, 0, 0)),
  };

  inputElem: HTMLInputElement;

  constructor(props: MaterialUiDateRangePickerProps) {
    super(props);
  }

  componentDidMount(): void {}

  componentDidUpdate(prevProps: DateRangePickerProps) {
    updateComponent(this.props, this.inputElem);
  }

  componentWillUnmount(): void {
    if (this.inputElem) {
      $(this.inputElem).data("daterangepicker")?.remove();
    }
  }

  render(): React.ReactNode {
    return (
      <TextField
        size="small"
        type="text"
        inputRef={(input: HTMLInputElement) => {
          if (this.inputElem && this.inputElem !== input) {
            $(this.inputElem).data("daterangepicker")?.remove();
          }
          this.inputElem = input;
          mountDatePicker(
            // create a default set of ranges if none are provided, cannot be a default property as it
            { ...this.props, ranges: this.props.ranges || createTimeRanges() },
            this.inputElem,
          );
        }}
        value={
          isNil(this.props.value?.[0]) || isNil(this.props.value?.[1])
            ? ""
            : formatDateRange(
                this.props.value[0],
                this.props.value[1],
                this.props.dateFormat,
              )
        }
        label={this.props.label}
        name={this.props.name}
        error={!isEmpty(this.props.error)}
        helperText={this.props.error ?? this.props.helperText}
        required={this.props.required}
        fullWidth
        InputProps={{
          endAdornment: this.props.endAdornment,
          startAdornment: this.props.startAdornment,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

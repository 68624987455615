import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { multi_stats_organization_subscription_subscription_periods_path } from "../../routes";
import { IDType } from "../../utils/urls/url_utils";
import { Icon } from "../common/icon";
interface SubscriptionPeriodsActionsDropdownProps {
  selectedPeriodIds: IDType[];
  subscriptionId: IDType;
  organizationId: IDType;
}

export const SubscriptionPeriodsActionsDropdown: React.FunctionComponent<
  SubscriptionPeriodsActionsDropdownProps
> = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const open = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      <Button
        id="export-menu-button"
        aria-controls={open ? "export-menu-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleMenuClick}
        disabled={isEmpty(props.selectedPeriodIds)}
        color="primary"
        size="small"
      >
        {I18n.t("frontend.subscription_periods.actions_dropdown.evaluations")}
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "export-menu-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            const url =
              multi_stats_organization_subscription_subscription_periods_path(
                props.organizationId,
                props.subscriptionId,
                {
                  format: "xlsx",
                  locale: I18n.locale,
                  subscription_period_ids: props.selectedPeriodIds,
                  _options: true,
                },
              );
            window.open(url);
          }}
        >
          <ListItemIcon>
            <Icon icon="file-excel" size="2x" />
          </ListItemIcon>
          <ListItemText>
            {I18n.t(
              "frontend.subscription_periods.actions_dropdown.actions.download_asset_evaluation_excel",
            )}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

import { isNil, toString } from "lodash";
import {
  IDType,
  RequestFormat,
  formatString,
  localizedBasePath,
} from "./url_utils";

export function createOrUpdateMaintenancePlanPath(
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/maintenance_plans/create_or_update${formatString(
    format,
  )}`;
}

export function assetMaintenancesPath(
  assetId: IDType,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/assets/${assetId}/maintenances${formatString(
    format,
  )}`;
}

export function assetMaintenancePlansPath(
  assetId: IDType,
  maintenancePlanId: IDType = null,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/assets/${assetId}/maintenance_plans${formatString(
    format,
  )}${
    isNil(maintenancePlanId)
      ? ""
      : "#maintenance-plan-item-" + toString(maintenancePlanId)
  }`;
}

export function assetMaintenancePlansDueSoonPath(
  assetId: IDType,
  format: RequestFormat = "json",
): string {
  if (isNil(assetId)) {
    return `${localizedBasePath()}/maintenance_plans/due_soon${formatString(
      format,
    )}`;
  }

  return `${localizedBasePath()}/assets/${assetId}/maintenance_plans/due_soon${formatString(
    format,
  )}`;
}

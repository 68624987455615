import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import {
  defaultTo,
  find,
  first,
  get,
  isEmpty,
  isNil,
  map,
  toArray,
  toString,
} from "lodash";
import * as React from "react";
import {
  AssetIncludes,
  AssetJSONAPIAttributesObject,
  loadAsset,
} from "../../json_api/asset";

import {
  ContextStateMachineJSONObject,
  loadContextStateMachinesForAsset,
} from "../../json_api/context_state_machines";
import { StateJSONObject } from "../../json_api/state";
import { StateContextJSONObject } from "../../json_api/state_contexts";
import { LoadingWrapper } from "../common/loading_wrapper";

import { logger } from "../../utils/logger";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
interface StateSelectProps {
  // Asset ID for searching available state contexts and corresponding states
  baseAssetId: IDType;
  // current asset selection for state
  selectedStatefulItemId?: IDType;
  // currently selected state
  selectedStateId?: IDType;
  // currently selected context state machine
  selectedContextStateMachineId?: IDType;
  // Which assets should be selectable
  assetSelectionMode?: "asset" | "root" | "subtree" | "root-subtree";
  // heading to display - A standard heading will be used if omited
  heading?: string;

  // Callback for state selected
  onStateSelected(
    statefulItemId: IDType,
    state: StateJSONObject,
    stateContext: StateContextJSONObject,
    csm: ContextStateMachineJSONObject,
  ): void;
}

export const AssetStateSelect: React.FunctionComponent<StateSelectProps> = ({
  assetSelectionMode = "root-subtree",
  ...props
}) => {
  const [loadCount, setLoadCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [availableAssets, setAvailableAssets] =
    React.useState<AssetJSONAPIAttributesObject[]>(null);
  const [selectedAsset, setSelectedAsset] =
    React.useState<AssetJSONAPIAttributesObject>(null);
  const [selectedContextStateMachine, setSelectedContextStateMachine] =
    React.useState<ContextStateMachineJSONObject>(null);
  const [selectedState, setSelectedState] = React.useState<StateJSONObject>();
  const [
    availableContextStateMachinesForAsset,
    setAvailableContextStateMachinesForAsset,
  ] = React.useState<ContextStateMachineJSONObject[]>(null);

  // set loading state on load count change
  React.useEffect(() => {
    if (loadCount <= 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadCount]);

  // set initial values from props
  React.useEffect(() => {
    if (
      isNil(props.selectedStateId) ||
      isNil(props.selectedContextStateMachineId) ||
      isEmpty(availableContextStateMachinesForAsset) ||
      isEmpty(availableAssets)
    ) {
      return;
    }
    const csm = find(
      availableContextStateMachinesForAsset,
      (csm) => csm.id == props.selectedContextStateMachineId,
    );

    const selectedAssetId = defaultTo(
      csm?.stateful_item_id,
      props.selectedStatefulItemId,
    );

    const asset = find(availableAssets, (a) => a.id == selectedAssetId);
    setSelectedAsset(asset);

    const state = find(
      csm?.possible_states,
      (s) => s.id == props.selectedStateId,
    );
    setSelectedState(state);
  }, [
    props.selectedStateId,
    props.selectedContextStateMachineId,
    props.selectedStatefulItemId,
  ]);

  React.useEffect(() => {
    if (!isEmpty(availableAssets) || isNil(props.baseAssetId)) {
      return;
    }
    const assetIncludes: AssetIncludes[] = [];
    let assetSubtreeInclude: AssetIncludes;
    if (!isNil(assetSelectionMode)) {
      switch (assetSelectionMode) {
        case "root-subtree":
          // the API seems not to return included items if the first nsteing level resolves to the resource itself.
          // This appears if root assets are loaded
          // we bypass that problem for
          assetIncludes.push("subtree");
          assetIncludes.push("root.subtree");
          break;
        case "subtree":
          assetIncludes.push("subtree");
          break;
        case "root":
          assetIncludes.push("root");
          break;
      }
    }

    void loadAsset(props.baseAssetId, assetIncludes)
      .then((asset) => {
        let assets: AssetJSONAPIAttributesObject[] = null;
        if (!isNil(assetSubtreeInclude)) {
          assets = toArray(
            get(asset, assetSubtreeInclude),
          ) as AssetJSONAPIAttributesObject[];
        } else {
          assets = [asset];
        }
        setAvailableAssets(assets);
        let theSelectedAsset = first(assets);
        if (
          (!isNil(selectedContextStateMachine) &&
            selectedContextStateMachine?.stateful_item_type == "Asset") ||
          !isNil(props.selectedStatefulItemId)
        ) {
          const itemId = defaultTo(
            selectedContextStateMachine?.stateful_item_id,
            props.selectedStatefulItemId,
          );
          theSelectedAsset = find(assets, (a) => a.id == itemId);
        }
        if (theSelectedAsset) {
          setSelectedAsset(theSelectedAsset);
        }
        return null as AssetJSONAPIAttributesObject[];
      })
      .finally(() => {
        setLoadCount(loadCount - 1);
      })
      .catch((e) => {
        logger.error(e);
        void error(
          I18n.t("base.error"),
          I18n.t("frontend.states.state_selection.could_not_load_assets"),
        );
        return null as AssetJSONAPIAttributesObject[];
      });
  }, [props.baseAssetId, props.selectedStatefulItemId]);

  React.useEffect(() => {
    if (isNil(selectedAsset)) {
      setSelectedContextStateMachine(null);
      setSelectedState(null);
    } else {
      setLoadCount(loadCount + 1);
      void loadContextStateMachinesForAsset(selectedAsset.id, [
        "possible_states",
        "state_context",
      ])
        .then((stateMachines) => {
          setAvailableContextStateMachinesForAsset(stateMachines);
        })
        .catch((e) => {
          logger.error(e);
          void error(
            I18n.t("base.error"),
            I18n.t("frontend.states.state_selection.error_loading_csms"),
          );
        })
        .finally(() => {
          setLoadCount(loadCount - 1);
        });
    }
  }, [selectedAsset, props.selectedContextStateMachineId]);

  React.useEffect(() => {
    if (isEmpty(availableContextStateMachinesForAsset)) {
      setSelectedState(null);
    } else {
      const stateId = defaultTo(selectedState?.id, props.selectedStateId);
      if (stateId) {
        // find the context that includes the currently selected state
        const csmWithSelectedState = find(
          availableContextStateMachinesForAsset,
          (csm) => !isNil(find(csm.possible_states, (s) => s.id == stateId)),
        );

        if (!isNil(csmWithSelectedState)) {
          setSelectedContextStateMachine(csmWithSelectedState);
        } else {
          setSelectedContextStateMachine(null);
        }
      } else {
        setSelectedContextStateMachine(null);
      }
    }
  }, [availableContextStateMachinesForAsset]);

  React.useEffect(() => {
    if (isNil(selectedContextStateMachine)) {
      setSelectedState(null);
    } else {
      const stateId = defaultTo(selectedState?.id, props.selectedStateId);
      const stateInCSM = isNil(stateId)
        ? null
        : find(
            selectedContextStateMachine.possible_states,
            (s) => s.id == stateId,
          );
      if (isNil(stateInCSM)) {
        setSelectedState(null);
      } else {
        setSelectedState(stateInCSM);
      }
    }
  }, [selectedContextStateMachine]);
  React.useEffect(() => {
    // for initial loads with preselected CSM the seletectedStatefulItem must be fetched
    // initially and set outside of the compontent.So when the prop changes after first init
    // we select the corersponding asset
    if (
      !isNil(availableAssets) &&
      selectedAsset?.id != props.selectedStatefulItemId
    ) {
      const asset = find(
        availableAssets,
        (a) => a.id == props.selectedStatefulItemId,
      );
      setSelectedAsset(asset);
    }
  }, [props.selectedStatefulItemId, availableAssets]);
  return (
    <Grid container spacing={2}>
      {loading ? (
        <LoadingWrapper size="4x" loading={loading} />
      ) : (
        <>
          {isEmpty(availableAssets) ? null : (
            <Grid item xs={12} lg={4}>
              <TextField
                size="small"
                fullWidth
                select
                title={selectedAsset?.name}
                label={I18n.t("activerecord.models.asset", { count: 1 })}
                onChange={(e) => {
                  const theSelectedAsset = find(
                    availableAssets,
                    (a) => toString(a.id) == e.target.value,
                  );

                  setSelectedAsset(theSelectedAsset);
                }}
                value={toString(selectedAsset?.id)}
              >
                <MenuItem key={"none"} value={""}>
                  ---
                </MenuItem>

                {map(availableAssets, (a) => (
                  <MenuItem key={a.id} value={toString(a.id)}>
                    {a.name} (#{a.id})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} lg={4}>
            {isEmpty(availableContextStateMachinesForAsset) ? (
              <Typography variant="body1">
                {I18n.t(
                  "frontend.states.asset_state_selection.no_state_context_for_asset",
                )}
              </Typography>
            ) : (
              <TextField
                size="small"
                fullWidth
                select
                title={selectedContextStateMachine?.state_context?.name}
                label={I18n.t("activerecord.models.state_context", {
                  count: 1,
                })}
                value={toString(selectedContextStateMachine?.id)}
                onChange={(e) => {
                  const csm = find(
                    availableContextStateMachinesForAsset,
                    (csm) => toString(csm.id) == e.target.value,
                  );

                  setSelectedContextStateMachine(csm);
                }}
              >
                <MenuItem key={"none"} value={""}>
                  ---
                </MenuItem>

                {map(availableContextStateMachinesForAsset, (csm) => (
                  <MenuItem key={csm.id} value={toString(csm.id)}>
                    {csm.state_context?.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {isNil(selectedContextStateMachine) ? null : (
              <TextField
                size="small"
                fullWidth
                select
                title={selectedState?.name}
                label={I18n.t("activerecord.models.state", { count: 1 })}
                value={toString(selectedState?.id)}
                onChange={(e) => {
                  const theSelectedState = find(
                    selectedContextStateMachine.possible_states,
                    (s) => toString(s.id) == e.target.value,
                  );
                  if (!isNil(theSelectedState)) {
                    setSelectedState(theSelectedState);
                    props.onStateSelected(
                      // there should only be csm for assets
                      selectedContextStateMachine.stateful_item_id,
                      theSelectedState,
                      selectedContextStateMachine.state_context,
                      selectedContextStateMachine,
                    );
                  }
                }}
              >
                <MenuItem key={"none"} value={""}>
                  ---
                </MenuItem>

                {map(selectedContextStateMachine.possible_states, (s) => (
                  <MenuItem key={s.id} value={toString(s.id)}>
                    {s.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

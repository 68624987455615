/// <reference types="../../../definitions/index" />;
import { Grid, InputAdornment, TextField } from "@mui/material";
import { isNil, sumBy, toString } from "lodash";
import * as React from "react";
import { MeasurementValue } from "../../../models/measurement";
import { MeasurementType } from "../../../models/measurement_type";
import { unitDisplayString } from "../../../utils/unit_conversion";

interface MeasurementValueAggregateProps {
  measurementType?: MeasurementType;
  measurementValues: MeasurementValue[];
  aggregateUnit?: string;
}

export function MeasurementValuesAggregateRow(
  props: MeasurementValueAggregateProps,
): React.ReactElement {
  if (
    props.measurementType?.type ===
    "MeasurementTypes::DistributionMeasurementType"
  ) {
    const sum = sumBy(props.measurementValues, (item) => item?.value);
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          {I18n.t("frontend.measurement_form.sum")}
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            contentEditable={false}
            name="measurementValue.value"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {toString(unitDisplayString(props.aggregateUnit))}
                </InputAdornment>
              ),
            }}
            size="small"
            value={isNil(sum) ? "---" : sum}
          />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

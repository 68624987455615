import { Dashboard } from "./dashboard";
import { isString } from "lodash";
import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { IDType } from "../../utils/urls/url_utils";
import { DashboardContainer } from "./dashboard_container";
import { DashboardRoot } from "./dashboard_root";
import { TTimescopeNames } from "../../utils/time_scopes";
import { DateRange } from "moment-range";

const dashboardRoots: Root[] = [];

interface DashboardInitData {
  id: IDType;
  type: "dashboard" | "asset_dashboard" | "asset_type_dashboard";
  base_url?: string;
  asset: { id: IDType; name: string };
  asset_type?: { id: IDType; name: string };
  time_scope: TTimescopeNames;
  time_range: {
    end: string;
    start: string;
  };
}
export function initDashboard(
  selector:
    | JQuery<HTMLElement>
    | string = '[data-toggle="dashboard-container"]',
) {
  (isString(selector) ? $(selector) : selector).each((index, element) => {
    const root = createRoot(element);
    const data = $(element).data("dashboard") as DashboardInitData;
    let timeRange: DateRange = null;
    if (data.time_range) {
      timeRange = new DateRange(
        moment(data.time_range.start),
        moment(data.time_range.end),
      );
    }
    dashboardRoots.push(root);
    root.render(
      <AppRoot queryDevTools={true}>
        <DashboardRoot
          assetId={data.asset.id}
          dashboardId={data.id}
          assetTypeId={data.asset_type?.id}
          baseUrl={data.base_url}
          dashboardType={data.type}
          initialTimeRange={timeRange}
          initialTimeScopeName={data.time_scope}
        />
      </AppRoot>,
    );
  });
}

export function destroyDashboards() {
  dashboardRoots.forEach((r) => r.unmount());
  dashboardRoots.length = 0;
}

/// <reference types="../../../definitions/index" />;
import { Grid, InputAdornment, TextField } from "@mui/material";
import { camelCase, defaultTo, isEmpty, isNil } from "lodash";
import * as React from "react";
import { MeasurementValue } from "../../../models/measurement";
import { MeasurementType } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { unitDisplayString } from "../../../utils/unit_conversion";
import { getValueString } from "../../../utils/value_format";

interface MeasurementValueWithErrors extends MeasurementValue {
  errors?: Record<keyof MeasurementValue, string>;
}
export interface MeasurementDistributionValueInputProps {
  measurementValue: MeasurementValueWithErrors;
  measurementValueDefinition: MeasurementValueDefinition;
  measurementType?: MeasurementType;
  error?: string;
  editable?: boolean;
  onUpdateMeasurementValue: (measurementValue: MeasurementValue) => void;
}

/**
 * Input to edit measurement values
 * @param props
 */
export const MeasurementDistributionValueInput: React.FunctionComponent<
  MeasurementDistributionValueInputProps
> = ({
  editable = true,
  error = null,

  ...props
}: MeasurementDistributionValueInputProps) => {
  const { measurementValue, measurementValueDefinition } = props;
  const key = camelCase(measurementValueDefinition.title.replace(" ", "_"));
  const onChangeValue = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      props.onUpdateMeasurementValue({
        ...measurementValue,
        value: isNaN(value) ? null : value,
      });
    },
    [],
  );
  const onChangeNote = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onUpdateMeasurementValue({
        ...measurementValue,
        note: event.target.value.toString(),
      });
    },
    [],
  );

  if (
    props?.measurementType?.type !==
    "MeasurementTypes::DistributionMeasurementType"
  ) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {measurementValueDefinition.title}
        <br className="d-inline d-lg-none" />
        <small className="ml-lg-1">
          {measurementValueDefinition.min} - {measurementValueDefinition.max}{" "}
          {unitDisplayString(props.measurementType.interval_unit)}
        </small>
      </Grid>
      <Grid item xs={2} xl={1}>
        <TextField
          fullWidth
          type="number"
          name="measurementValue.value"
          error={!isEmpty(error)}
          helperText={error}
          InputProps={{
            readOnly: !editable,
            endAdornment: (
              <InputAdornment position="end">
                {unitDisplayString(measurementValueDefinition?.unit)}
              </InputAdornment>
            ),
          }}
          size="small"
          value={defaultTo(measurementValue.value, "")}
          onChange={onChangeValue}
        />
      </Grid>
      <Grid item xs={2} xl={1} className="text-center">
        {getValueString(measurementValue.percent)} %
      </Grid>
      <Grid item xs={2} xl={2}>
        {measurementValueDefinition?.measurement_category?.title}
        {isNil(measurementValueDefinition?.measurement_category?.min) &&
        isNil(measurementValueDefinition?.measurement_category?.max) ? null : (
          <>
            <br />

            <small>
              {measurementValueDefinition?.measurement_category?.min} -{" "}
              {measurementValueDefinition?.measurement_category?.max}{" "}
              {defaultTo(
                unitDisplayString(props.measurementType?.interval_unit),
                "",
              )}
            </small>
          </>
        )}
      </Grid>
      <Grid item xs={3} xl={5}>
        <TextField
          fullWidth
          name="measurementValue.note"
          placeholder={I18n.t("activemodel.attributes.measurement_value.note")}
          size="small"
          InputProps={{ readOnly: !editable }}
          value={defaultTo(measurementValue.note, "")}
          onChange={onChangeNote}
        />
      </Grid>
    </Grid>
  );
};

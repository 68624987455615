import * as React from "react";

import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import { Root, createRoot } from "react-dom/client";
import { TreeItem } from "../../models/tree_item";
import { AppRoot } from "../common/app_root";
import { MaintenanceFormContainer } from "./containers/maintenance_form_container";
import { loadInitialState, resetState } from "./data/maintenance_actions";
import { MaintenanceJobGroup, User } from "./data/model";

const maintenanceContainerRoots: Root[] = [];
/**
 * Initialize react component MaintenanceContainer within all elements with data-toggle="maintenance-form".
 * Initial state is loaded fron "data-maintenance-job-groups".
 * State is expected to be in JSON format.
 */
export function initializeMaintenanceContainer(): void {
  $('[data-toggle="maintenance-form"]').each((index, element) => {
    const root = createRoot(element);
    maintenanceContainerRoots.push(root);
    root.render(
      <AppRoot>
        <MaintenanceFormContainer />
      </AppRoot>,
    );

    const rootAssetId = $(element).attr("data-root-asset-id");
    let maintenanceJobGroups: MaintenanceJobGroup[] = $(element).data(
      "maintenance-job-groups",
    ) as MaintenanceJobGroup[];
    maintenanceJobGroups = isNil(maintenanceJobGroups)
      ? []
      : maintenanceJobGroups;

    let users: User[] = [];
    if ($(element).attr("data-users")) {
      users = JSON.parse($(element).attr("data-users")) as User[];
    }
    let assetTree: TreeItem = null;
    if ($(element).attr("data-asset-item-tree")) {
      assetTree = JSON.parse(
        $(element).attr("data-asset-item-tree"),
      ) as TreeItem;
    }

    loadInitialState(rootAssetId, maintenanceJobGroups, users, assetTree);
  });
}

/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyMaintenanceContainer(): void {
  maintenanceContainerRoots.forEach((root) => {
    root.unmount();
  });
  maintenanceContainerRoots.length = 0;
  resetState();
}

/// <reference types="../../definitions/index" />;
import { Cancel, Check, Close } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { NotificationSetting } from "../../models/measurement_plan";
import { SialogicDialog } from "./sialogic_dialog";

interface NotificationSettingsInputProps {
  value?: NotificationSetting;
  options?: NotificationSetting[];
  error?: string;
  allowEdit?: boolean;
  onChange: (value: NotificationSetting) => void;
}

interface NotificationSettingsInputState {
  value: NotificationSetting;
  isEditing: boolean;
}

/**
 * A selection input for notification settings.
 */
export class NotificationSettingsInput extends React.Component<
  NotificationSettingsInputProps,
  NotificationSettingsInputState
> {
  static AvailableNotificationSettings: NotificationSetting[] = [
    "none",
    "same_day",
    "one_day_ahead",
    "two_days_ahead",
    "one_week_ahead",
  ];

  static defaultProps: Partial<NotificationSettingsInputProps> = {
    value: "none",
    options: NotificationSettingsInput.AvailableNotificationSettings,
    allowEdit: true,
  };

  constructor(props: NotificationSettingsInputProps) {
    super(props);

    this.state = {
      value: this.props.value,
      isEditing: false,
    };
  }

  componentDidUpdate(prevProps: NotificationSettingsInputProps): void {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <TextField
          key="notificationSetting"
          size="small"
          fullWidth
          label={I18n.t("frontend.common.notification_settings_input.reminder")}
          title={I18n.t("frontend.click_to_edit")}
          style={{ cursor: "pointer" }}
          error={!isEmpty(this.props.error)}
          helperText={this.props.error}
          contentEditable={false}
          value={this.getNotificationSettingLabel(this.state.value)}
          onClick={
            this.props.allowEdit ? () => this.onChangeEditMode(true) : null
          }
          InputProps={{
            style: { caretColor: "transparent" },

            startAdornment: (
              <InputAdornment position="start">
                <NotificationsActiveIcon fontSize="inherit" />
              </InputAdornment>
            ),
            endAdornment: this.props.allowEdit && (
              <InputAdornment position="end">
                <IconButton
                  color="default"
                  size="small"
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onChangeEditMode(true);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {this.props.allowEdit == false ? null : (
          <SialogicDialog
            open={this.state.isEditing}
            onClose={() => this.onChangeEditMode(false)}
            title={I18n.t(
              "frontend.common.notification_settings_input.reminder",
            )}
            buttons={
              <>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ isEditing: false });
                    this.onApplyChange();
                  }}
                  className="apply-notification-setting"
                  startIcon={<Check />}
                >
                  {I18n.t("frontend.submit")}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ isEditing: false });
                    this.onCancelChange();
                  }}
                  startIcon={<Cancel />}
                >
                  {I18n.t("frontend.cancel")}
                </Button>
              </>
            }
          >
            <Box paddingTop={2}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    value={this.state.value || ""}
                    label={I18n.t(
                      "frontend.common.notification_settings_input.reminder",
                    )}
                    name="notification"
                    onChange={(event) =>
                      this.onChangeNotificationSetting(
                        event as React.ChangeEvent<HTMLInputElement>,
                      )
                    }
                  >
                    {this.props.options.map((value) => (
                      <MenuItem key={value} value={value}>
                        {this.getNotificationSettingLabel(value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </SialogicDialog>
        )}
      </React.Fragment>
    );
  }

  private onChangeEditMode(active: boolean): void {
    this.setState({
      isEditing: active,
    });
  }

  private onChangeNotificationSetting(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const value = event.target.value as NotificationSetting;

    this.setState({
      value: value,
    });
  }

  private onApplyChange(): void {
    this.props.onChange(this.state.value);

    this.setState({
      isEditing: false,
    });
  }

  private onCancelChange(): void {
    this.setState({
      value: this.props.value || "none",
      isEditing: false,
    });
  }

  private getNotificationSettingLabel(value: NotificationSetting): string {
    if (isNil(value)) {
      return I18n.t(
        "frontend.maintenance_plan_form.notification_settings.none",
      );
    }

    return I18n.t(
      `frontend.maintenance_plan_form.notification_settings.${value}`,
    );
  }
}

import * as React from "react";
import { EventListEntry } from "./event_list_entry";

import { Divider, ListItemText, MenuItem, Typography } from "@mui/material";
import { EventNotification } from "../../../models/event_notification";
import { AppContext } from "../../common/app_context/app_context_provider";
import { isString } from "lodash";
import { getTranslatedProp } from "../../../utils/globalize";

interface EventListProps {
  baseKey: string;
  /**
   * Heading of the event list
   */
  heading: string;
  /**
   * Events that should be shown in the list
   */
  events: EventNotification[];
}

/**
 * A list of events with a heading
 * @param props Properties for list
 */
export function EventList(props: EventListProps): React.ReactElement[] {
  const context = React.useContext(AppContext);
  const elems = React.useMemo(() => {
    const listElements: React.ReactElement[] = [
      <MenuItem key={`${props.baseKey}-heading`}>
        <ListItemText>
          <Typography variant="subtitle2">{props.heading}</Typography>
        </ListItemText>
      </MenuItem>,
    ];

    props.events.forEach((event, index) => {
      let name = event.root_asset
        ? getTranslatedProp(event.root_asset, "name")
        : getTranslatedProp(event.asset, "name");

      listElements.push(
        <EventListEntry
          active={!event.read_by_user}
          assetName={name}
          name={getTranslatedProp(event, "name")}
          description={getTranslatedProp(event, "description")}
          url={event.url}
          icon={event.icon}
          timestamp={event.timestamp}
          key={`${props.baseKey}_event_${event.event_id}`}
        />,
      );
      listElements.push(<Divider key={`event_divider_${index}`} />);
    });

    return listElements;
  }, [props.events, props.heading, context.locale, props.baseKey]);

  return elems;
}

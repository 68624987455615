import * as React from "react";
import { IDType } from "../../utils/urls/url_utils";
import { SubscriptionPeriodList } from "./subscription_period_list";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
const roots: Root[] = [];
export function initializeSubscriptionPeriodList(): void {
  $('[data-toggle="subscription-period-list"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const subscriptionId = $(element).data("subscription-id") as IDType;
    const organizationId = $(element).data("organization-id") as IDType;

    const root = createRoot(element);
    roots.push(root);
    root.render(
      <AppRoot>
        <SubscriptionPeriodList
          subscriptionId={subscriptionId}
          organizationId={organizationId}
        />
      </AppRoot>,
    );
  });
}

export function destroySubscriptionPeriodList(): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}

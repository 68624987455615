import { Promise } from "bluebird";
import { Container } from "flux/utils";
import { get, has, isEmpty, isNil } from "lodash";
import * as React from "react";
import "../../../utils/flux_container_adapter";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import * as Actions from "../data/maintenance_actions";
import MaintenanceStore, { MaintenanceState } from "../data/maintenance_store";
import { MaintenanceForm } from "../views/maintenance_form";

import { SweetAlertResult } from "sweetalert2";
import {
  setConfirmLeaveMessage,
  showBeforeUnloadDialog,
} from "../../../utils/before_unload_dialog";
import { dialog } from "../../../utils/dialog";
import { sendData } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";
import {
  CreateMaintenanceErrorResponse,
  Maintenance,
  MaintenanceJob,
  StatusMeasurement,
  getMaintanceJobType,
} from "../data/model";

interface MaintenanceContainerBaseProps {}
/**
 * Base class of maintenance container.
 * This is never used directly but extended with the Flux.Container mixin.
 */
class MaintenanceFormContainerBase extends React.Component<
  MaintenanceContainerBaseProps,
  MaintenanceState
> {
  static getStores() {
    return [MaintenanceStore];
  }

  static calculateState(prevState: MaintenanceState) {
    return MaintenanceStore.getState();
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  constructor(props: MaintenanceContainerBaseProps) {
    super(props);
    this.state = MaintenanceStore.getState();
  }
  render(): React.ReactNode {
    const state = this.state;

    if (state.hasChanges) {
      // enable before unload dialog if changes are applied
      showBeforeUnloadDialog(true);
      setConfirmLeaveMessage(
        I18n.t("frontend.maintenance_form.cancel_confirm_heading"),
        I18n.t("frontend.maintenance_form.cancel_confirm_message"),
      );
    }

    return (
      <MaintenanceForm
        doneAt={state.done_at}
        note={state.note}
        maintenanceJobGroups={state.maintenanceJobGroups}
        users={state.users}
        assetTree={state.assetTree}
        filter={state.filter}
        defaultUser={state.defaultUser}
        isProcessing={state.isProcessing}
        errors={state.errors}
        onSubmit={() => void this.onSaveMaintenance()}
        onCancel={() => void this.onCancel()}
        onGuestUserAdded={Actions.addUser}
        onUpdateDoneAt={Actions.updateDoneAt}
        onUpdateNote={Actions.updateNote}
        onUpdateMaintenanceJob={Actions.updateMaintenanceJob}
        onSetDefaultUser={Actions.setDefaultUser}
        onApplyDefaultUser={Actions.applyDefaultUser}
        onToggleAll={Actions.toggleAllMaintenanceJobGroups}
        onToggle={Actions.toggleMaintanceJobGroup}
        onFilterChanged={Actions.applyFilter}
      />
    );
  }

  private async onSaveMaintenance(): Promise<void> {
    Actions.setProcessing(true);
    Actions.resetErrors();

    const state = this.state;
    const maintenanceJobs: MaintenanceJob[] = [];

    state.maintenanceJobGroups.forEach((maintenanceJobGroup) => {
      maintenanceJobGroup.assets.forEach((asset) => {
        asset.maintenance_jobs.forEach((maintenanceJob) => {
          if (maintenanceJob.executed) {
            maintenanceJobs.push({
              type: getMaintanceJobType(maintenanceJob.maintenance_plan),
              maintenance_plan_id: maintenanceJob.maintenance_plan.id,
              maintenance_period_id: maintenanceJob.maintenance_period_id,
              asset_id: asset.asset.id,
              description: maintenanceJob.description,
              user_id: maintenanceJob.user_id,
              user_type: maintenanceJob.user_type,
              user_attributes: maintenanceJob.user_attributes,
              status_measurement: get(
                maintenanceJob,
                "status_measurement",
              ) as StatusMeasurement,
            } as MaintenanceJob);
          }
        });
      });
    });

    const maintenance: Maintenance = {
      done_at: state.done_at,
      note: state.note,
      maintenance_jobs_attributes: maintenanceJobs,
    };

    try {
      await sendData(
        url_helper.assetMaintenancesPath(state.rootAssetId, "json"),
        {
          maintenance,
        },
      );

      showBeforeUnloadDialog(false);

      void toast.success(
        I18n.t("frontend.saved_successfully"),
        I18n.t("frontend.maintenance_form.maintenance_saved"),
      );
      this.redirect();
    } catch (error) {
      // load errors from response if present
      if (has(error, "request.responseJSON")) {
        Actions.setErrors(
          get(error, "request.responseJSON") as CreateMaintenanceErrorResponse,
        );
      }
      logger.error(error);

      void toast.error(
        I18n.t("frontend.maintenance_form.error"),
        I18n.t("frontend.maintenance_form.error_title"),
        true,
      );
    } finally {
      Actions.setProcessing(false);
    }
  }

  private onCancel(): Promise<any> | void {
    return Promise.bind(this).then(() => {
      Actions.setProcessing(false);
      const closeAndRedirect = (result: SweetAlertResult<boolean>) => {
        if (result.value) {
          showBeforeUnloadDialog(false);
          this.redirect();
        }
      };

      if (!this.state.hasChanges) {
        closeAndRedirect({
          value: true,
          isDismissed: true,
          isConfirmed: true,
          isDenied: false,
        });
        return;
      }

      return dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          icon: "question",
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          title: I18n.t("frontend.maintenance_form.cancel_confirm_heading"),
          html: `<p>${I18n.t(
            "frontend.maintenance_form.cancel_confirm_message",
          )}</p>`,
        })
        .then(closeAndRedirect);
    });
  }

  private redirect(url: string = null): void {
    const state = this.state;

    if (!isEmpty(url)) {
      redirectTo(url);
      return;
    }
    if (!isNil(state.rootAssetId) && !isEmpty(state.rootAssetId.toString())) {
      // redirect to maintenance plans overview
      this.redirect(
        url_helper.assetMaintenancePlansPath(state.rootAssetId, null, "html"),
      );
    } else if (
      !isNil(this.context.referrer) &&
      !isEmpty(this.context.referrer)
    ) {
      // redirect to referrer
      this.redirect(this.context.referrer);
    }
  }
}

/**
 * A Flux container component that connects the maintenance form to the store and actions.
 */
export const MaintenanceFormContainer =
  Container.create<MaintenanceContainerBaseProps>(
    MaintenanceFormContainerBase as any,
  ) as React.ComponentType<MaintenanceContainerBaseProps>;

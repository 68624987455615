import { difference, isEmpty, isNil, join, map, noop } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { Compress, Expand } from "@mui/icons-material";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { Root, createRoot } from "react-dom/client";
import { TreeItem } from "../../../models/tree_item";
import {
  AllMaintenancePlanTypes,
  Asset,
  MaintenancePlanFilterSelection,
} from "../../maintenance_plan_form/data/models";
import { AppRoot } from "../app_root";
import { JumpToElementDropdown } from "../jump_to_element_dropdown";
import { MaintenancePlanTypeFilter } from "./maintenance_plan_type_filter";

export interface MaintenanceButtonToolbarProps {
  filter?: MaintenancePlanFilterSelection;
  jumpToTitlesIds: [string, string][];
  assets: Asset[];
  onFilterChanged: (filter: MaintenancePlanFilterSelection) => void;
  onToggleAll?: (state: boolean) => void;
}

export const MaintenanceButtonToolbar: FunctionComponent<
  MaintenanceButtonToolbarProps
> = ({
  filter = null,
  onToggleAll = null,
  onFilterChanged = noop,
  assets = [],
  jumpToTitlesIds = [],
  ...props
}: MaintenanceButtonToolbarProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item minWidth={200}>
        <JumpToElementDropdown
          titlesWithIds={jumpToTitlesIds}
          buttonTitle={I18n.t("frontend.jump_to_asset")}
        />
      </Grid>
      <Grid item>
        <MaintenancePlanTypeFilter
          filter={filter}
          onFilterChanged={onFilterChanged}
        />
      </Grid>

      {isNil(onToggleAll) ? null : (
        <Grid item>
          <ButtonGroup className="mb-2" size="small">
            <Button
              variant="text"
              id="expandall"
              size="small"
              onClick={() => onToggleAll(true)}
              startIcon={<Expand />}
            >
              {" "}
              <span className="d-none d-md-inline">
                {I18n.t("frontend.expand_all")}
              </span>
            </Button>
            <Button
              variant="text"
              id="collapseall"
              size="small"
              onClick={() => onToggleAll(false)}
              startIcon={<Compress />}
            >
              <span className="d-none d-md-inline">
                {I18n.t("frontend.collapse_all")}
              </span>
            </Button>
          </ButtonGroup>
        </Grid>
      )}
    </Grid>
  );
};

/** Shows or hides maintenance asset groups.
 *
 * applies opening / closing to .ibox.asset-maintenance-plan-container classed elements for collapse links
 *
 * @param {boolean} open transition iboxes to the given state.
 */
function toggleAssets(open: boolean) {
  const containers = $(".collapse-link").closest(
    "div.ibox.asset-maintenance-plan-container",
  );
  const content = containers.children(".ibox-content");
  const buttons = $(".collapse-link").find("i");
  if (open) {
    content.slideDown();
  } else {
    content.slideUp();
  }
  buttons.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
  containers.toggleClass("").toggleClass("border-bottom");
}

/** Creates a filter function that rerenders the Maintenance Button Toolbar using the props given.
 *  It filters / hides the iboxes with the data-maintenance-plan-type attributes not matching the selected maintenance plan types.
 *
 * @param {MaintenanceButtonToolbarProps} props Propierties for toolbar rendering
 * @param {Element} parentElement Parent element to render the component
 * @returns
 */
function createFilterFunction(
  props: MaintenanceButtonToolbarProps,
  parentElement: Element,
  renderFun: (props: MaintenanceButtonToolbarProps) => void,
) {
  return function (filter: MaintenancePlanFilterSelection) {
    const notSelectedTypes = difference(
      AllMaintenancePlanTypes,
      filter.maintenancePlanTypes,
    );
    let cssSelector = join(
      map(
        notSelectedTypes,
        (unselectedType) =>
          `.ibox[data-maintenance-plan-type="${unselectedType}"]`,
      ),
      ",",
    );
    if (!isEmpty(cssSelector)) {
      $(cssSelector).fadeOut();
    }

    cssSelector = join(
      map(
        filter.maintenancePlanTypes,
        (selectedType) => `.ibox[data-maintenance-plan-type="${selectedType}"]`,
      ),
      ",",
    );

    if (!isEmpty(cssSelector)) {
      $(cssSelector).fadeIn();
    }
    props.filter = filter;
    renderFun(props);
  };
}

const maintenanceButtonToolbarRoots: Root[] = [];
/** Initializes the maintenance button toolbar component for non React Component views
 * Requires
 *  # a Parent element
 *
 * @param elements parent element to render the button bar. The element should have a property data-asset-item-tree that holds a json representation of the current asset item tree.
 * @export
 */
export function initializeMaintenanceButtonToolbar(
  elements?: JQuery<HTMLElement>,
) {
  if (elements.length === 0) return;

  elements.each((index, element) => {
    const jqElement = $(element);
    const assetTree: TreeItem[] = jqElement.data("asset-item-tree");
    const jumpToAssets = jqElement.data("jump-assets");
    const jumpToSuffix = jqElement.data("jump-to-id-suffix");
    let assets = assetTree[0].children;
    if (jumpToAssets) {
      assets = jumpToAssets;
    }

    //
    const filter: MaintenancePlanFilterSelection = {
      maintenancePlanTypes: AllMaintenancePlanTypes,
      assetIds: null,
    };

    const props: MaintenanceButtonToolbarProps = {
      assets: assets as any as Asset[],
      jumpToTitlesIds: map(assets, (a, index) => [
        a.title,
        maintenanceJobGroupId(a, index, jumpToSuffix),
      ]),
      onToggleAll: toggleAssets,
      filter: filter,
      onFilterChanged: null,
    };

    const root = createRoot(element);
    const renderFunction = function (props: MaintenanceButtonToolbarProps) {
      root.render(
        <AppRoot>
          <MaintenanceButtonToolbar {...props} />
        </AppRoot>,
      );
    };
    props.onFilterChanged = createFilterFunction(
      props,
      jqElement[0],
      renderFunction,
    );
    maintenanceButtonToolbarRoots.push(root);
    renderFunction(props);
  });
}

export function destroyMaintenanceButtonToolbar() {
  maintenanceButtonToolbarRoots.forEach((root) => root.unmount());
  maintenanceButtonToolbarRoots.length = 0;
}
function maintenanceJobGroupId(
  jobGroup: any,
  index: number,
  idSuffix = "asset-maintenance-plans-",
) {
  return `${idSuffix}${index}`;
}

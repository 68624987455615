import { IDType } from "../utils/urls/url_utils";
import { EventSeverityLevel } from "./event_notification";

export type AssetEventTypeCategory =
  | "monitoring"
  | "maintenance"
  | "user"
  | "asset"
  | "measurement"
  | "report";

export const AssetEventTypeCategories: AssetEventTypeCategory[] = [
  "user",
  "asset",
  "measurement",
  "maintenance",
  "report",
  "monitoring",
];

export const ASSET_EVENT_TYPE_SLUG_REGEXP = /^[A-Za-z0-9\-]+$/;

export interface AssetEventTypeAttributes {
  id?: IDType;
  color?: string;
  icon?: string;
  slug?: string;
  category?: AssetEventTypeCategory;
  default_severity_level?: EventSeverityLevel;
  code?: string;
  name?: string;
  description?: string;
  action?: string;
  message?: string;
}

export type AssetEventType = AssetEventTypeAttributes;

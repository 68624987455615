import {
  asset_measurement_plan_path,
  measurement_plan_path,
} from "../../routes";
import {
  formatString,
  IDType,
  localizedBasePath,
  RequestFormat,
} from "./url_utils";

export function measurementValuesUrl(
  measurementId: IDType,
  format: RequestFormat = "json",
): string {
  return `${localizedBasePath()}/measurements/${measurementId}/measurement_values${formatString(
    format,
  )}`;
}

export function measurementValueUrl(
  measurementId: IDType,
  timestamp: Date,
  format: RequestFormat = "json",
): string {
  const timestampNs = `${timestamp.getTime()}000000000`;
  return `${localizedBasePath()}/measurements/${measurementId}/measurement_values/${timestampNs}${formatString(
    format,
  )}`;
}

export function assetMeasurementPlansPath(
  assetId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans${formatString(
    format,
  )}`;
}
export function newAssetMeasurementPlanPath(assetId: IDType): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/new`;
}
export function assetMeasurementPlanPath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  if (assetId) {
    return measurement_plan_path(measurementPlanId, {
      _options: true,
      locale: I18n.locale,
      format,
    });
  } else {
    return asset_measurement_plan_path(assetId, measurementPlanId, {
      _options: true,
      locale: I18n.locale,
      format,
    });
  }
}

export function editAssetMeasurementPlanPath(
  assetId: IDType,
  measurementPlanId: IDType,
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/edit`;
}

export function assetMeasurementPlansMeasurementsPath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/measurements${formatString(
    format,
  )}`;
}

export function assetMeasurementPlanMeasurementPath(
  assetId: IDType,
  measurementPlanId: IDType,
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/measurements/${measurementId}${formatString(
    format,
  )}`;
}

export function assetMeasurementPlanSubscribePath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/subscribe${formatString(
    format,
  )}`;
}

export function assetMeasurementPlanUnsubscribePath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/unsubscribe${formatString(
    format,
  )}`;
}

export function measurementPlanPath(
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/measurement_plans/${measurementPlanId}${formatString(
    format,
  )}`;
}

export function createMeasurementPath(
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/measurement_plans/${measurementPlanId}/measurements${formatString(
    format,
  )}`;
}

export function measurementPath(
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/measurements/${measurementId}${formatString(
    format,
  )}`;
}

export function editMeasurementPath(measurementId: IDType): string {
  return `${localizedBasePath()}/measurements/${measurementId}/edit`;
}
export function newAssetMeasurementPath(
  assetId: IDType,
  measurementPlanId: IDType,
): string {
  return `${localizedBasePath()}/assets/${assetId}/measurement_plans/${measurementPlanId}/measurements/new`;
}

export function updateMeasurementPath(
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath()}/measurements/${measurementId}${formatString(
    format,
  )}`;
}

import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { User } from "../maintenance_form/data/model";
import { MeasurementContainer } from "./containers/measurement_container";
import { loadInitialState } from "./data/measurement_actions";
import { Measurement } from "./data/model";

const measurementContainerRoots: Root[] = [];
/**
 * Initialize react component MeasurementContainer within all elements with data-toggle="planned-measurement-form".
 * Initial state is loaded fron "data-measurement" and "data-users".
 * State is expected to be in JSON format.
 */
export function initializeMeasurementContainer(): void {
  $('[data-toggle="planned-measurement-form"]').each((index, element) => {
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <MeasurementContainer />
      </AppRoot>,
    );
    measurementContainerRoots.push(root);

    const measurement = $(element).data("measurement") as Measurement;
    const users = ($(element).data("users") || []) as User[];

    loadInitialState(measurement, users);
  });
}

/**
 * Remove react component from all elements with data-toggle="planned-measurement-form"
 */
export function destroyMeasurementContainer(): void {
  measurementContainerRoots.forEach((root) => {
    root.unmount();
  });
  measurementContainerRoots.length = 0;
}

import { createContext } from "react";
import { DashboardActionContextType } from "./dashboard_action_context.types";

export const DashboardActionContext = createContext<DashboardActionContextType>(
  {
    performAction: () => {},
    addSensorToDisplay: () => {},
    resetSensorsToDisplay: () => {},
  },
);

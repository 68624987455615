/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty, isNil } from "lodash";
import * as React from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { samplingRateToString } from "../../models/sensor";
import { getTranslatedProp } from "../../utils/globalize";
import { AttributeRow } from "../common/attribute_row";
import { SensorValueRangesForm } from "../sensor_value_ranges/sensor_value_ranges_form";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";

interface SensorInfoProps {
  sensor: SensorJSONAPIAttributes;
  collapsible?: boolean;
  showValueRanges?: boolean;
}

export const SensorInfo: React.FunctionComponent<SensorInfoProps> = ({
  collapsible = false,
  showValueRanges = false,
  ...props
}) => {
  const infoContent = (
    <Grid container>
      <Grid item container xs={12}>
        <AttributeRow
          attributeName={I18n.t("activerecord.attributes.sensor.name")}
          value={getTranslatedProp(props.sensor, "name")}
        />
        <AttributeRow
          attributeName={I18n.t("activerecord.attributes.sensor.short_name")}
          value={getTranslatedProp(props.sensor, "short_name")}
        />
        {props.sensor.asset ? (
          <AttributeRow
            attributeName={I18n.t("activerecord.attributes.sensor.asset")}
            value={getTranslatedProp(props.sensor.asset, "name")}
          />
        ) : null}
        {props.sensor.sensor_type_name ? (
          <AttributeRow
            attributeName={I18n.t("activerecord.attributes.sensor.sensor_type")}
            value={getSensorTypeNameTranslation(props.sensor.sensor_type_name)}
            copyValue={props.sensor.sensor_type_name}
          />
        ) : null}

        <AttributeRow
          value={props.sensor.key}
          copyable
          attributeName={I18n.t("activerecord.attributes.attribute_key.key")}
        />

        {props.sensor.measurement_type ? (
          <AttributeRow
            attributeName={I18n.t(
              "activerecord.attributes.sensor_type.measurement_type",
            )}
            copyValue={props.sensor.measurement_type}
            value={I18n.t(
              `activerecord.attributes.sensor_type.measurement_types.${props.sensor.measurement_type}`,
              { defaultValue: props.sensor.measurement_type },
            )}
          />
        ) : null}
        {isEmpty(props.sensor.sensor_context) ? null : (
          <AttributeRow
            value={props.sensor.sensor_context}
            copyable
            attributeName={I18n.t("activerecord.attributes.sensor.context")}
          />
        )}
        {isEmpty(props.sensor.sensor_context2) ? null : (
          <AttributeRow
            value={props.sensor.sensor_context2}
            copyable
            attributeName={I18n.t("activerecord.attributes.sensor.context2")}
          />
        )}
        {isNil(props.sensor.derived) ? null : (
          <AttributeRow
            value={
              props.sensor.derived
                ? I18n.t("activerecord.attributes.sensor.computed")
                : I18n.t("activerecord.attributes.sensor.measured")
            }
            attributeName={I18n.t("activerecord.attributes.sensor.derived")}
          />
        )}
        {isNil(props.sensor.sampling_rate_unit) ||
        isNil(isNil(props.sensor.sampling_rate_value)) ? null : (
          <AttributeRow
            value={
              samplingRateToString({
                unit: props.sensor.sampling_rate_unit,
                value: props.sensor.sampling_rate_value,
              }) ?? "---"
            }
            copyable
            attributeName={I18n.t(
              "activerecord.attributes.sensor.sampling_rate",
            )}
          />
        )}
      </Grid>
      {isEmpty(props.sensor.value_ranges) ? null : (
        <Grid item xs={12} marginTop={3}>
          <Typography variant="caption">
            {I18n.t("activerecord.models.sensor_value_range.other")}
          </Typography>
          <SensorValueRangesForm
            ibox={false}
            readonly
            sensor={props.sensor}
            sensorValueRanges={props.sensor.value_ranges}
          />
        </Grid>
      )}
    </Grid>
  );
  if (collapsible) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1-content"
          id={`${props.sensor.id}`}
        >
          <Grid container>
            <Grid item xs={12}>
              {I18n.t("frontend.sensors.sensor_info.details")}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{infoContent}</AccordionDetails>
      </Accordion>
    );
  } else {
    return infoContent;
  }
};

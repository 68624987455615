import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { isEqual, isNil, toInteger } from "lodash";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import { AssetTypesSensorTypeJSONObject } from "../../json_api/asset_types_sensor_type";
import { AttributeKeyDataTypes } from "../../models/attribute_key";
import { HttpError } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import * as toasts from "../../utils/toasts";
import { useLoadSensorTypesQuery } from "../sensor_types/sensor_types_data";
import {
  useCreateAssetTypeSensorType,
  useDeleteAssetTypeSensorType,
  useLoadAssetTypeSensorTypesQuery,
  useUpdateAssetTypeSensorType,
} from "./asset_types_sensor_types_data";
import { getSensorTypeNameTranslation } from "../../models/sensor_type";

interface AssetTypesSensorTypesListProps {
  assetType?: AssetTypeJSONObject;
  assetTypeId?: number;
  canEdit: boolean;
  tableHeight?: number;
  maxTableHeight?: number | string;
}
const NEW_ROW_ID = -1;
interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type AssetTypeSensorTypeRow = AssetTypesSensorTypeJSONObject & {
  isNew?: boolean;
};

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    setRows: (
      newRows: (
        oldRows: GridRowsProp<AssetTypeSensorTypeRow>,
      ) => GridRowsProp<AssetTypeSensorTypeRow>,
    ) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }
}
function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = NEW_ROW_ID;
    setRows((oldRows) => [
      ...oldRows,
      { id, name: "", age: "", role: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleClick}>
        {I18n.t("frontend.add")}
      </Button>
    </GridToolbarContainer>
  );
}

export const AssetTypesSensorTypesList: React.FC<
  AssetTypesSensorTypesListProps
> = ({
  assetType,
  assetTypeId,
  tableHeight = 600,
  maxTableHeight = "75vh",
  canEdit = false,
}) => {
  const [pageSettings, setPageSettings] = React.useState({
    pageSize: 20,
    page: 0,
  });

  const theAssetTypeID = assetType ? assetType.id : assetTypeId;
  const sensorTypesQuery = useLoadSensorTypesQuery({ variables: {} });

  const assetTypesSensorTypesQuery = useLoadAssetTypeSensorTypesQuery({
    variables: {
      assetTypeId: theAssetTypeID,
      pageSize: pageSettings.pageSize,
      page: pageSettings.page,
    },
    enabled: !isNil(theAssetTypeID),
    placeholderData: {
      items: [],
      totalItems: -1,
      totalPages: -1,
    },
  });

  const [rows, setRows] =
    React.useState<GridRowModel<AssetTypeSensorTypeRow>[]>(null);

  React.useEffect(() => {
    if (assetTypesSensorTypesQuery.isSuccess) {
      setRows([...(assetTypesSensorTypesQuery.data.items || [])]);
    }
  }, [assetTypesSensorTypesQuery.isSuccess, assetTypesSensorTypesQuery.data]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = React.useCallback(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    [],
  );

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const { mutateAsync: deleteAssertTypeSensorType } =
    useDeleteAssetTypeSensorType();

  const confirm = useConfirm();
  const handleDeleteClick = (id: GridRowId) => () => {
    void confirm({
      title: I18n.t(
        "frontend.asset_types_sensor_types_list.confirm_delete_text",
      ),
    })
      .then(() => {
        deleteAssertTypeSensorType(id)
          .then(() => {
            void toasts.success("Sensor template deleted", "Success");
          })
          .catch((error) => {
            logger.error(error);

            void toasts.error(
              I18n.t(
                "frontend.asset_types_sensor_types_list.error_delete_title",
              ),
              I18n.t(
                "frontend.asset_types_sensor_types_list.error_delete_text",
              ),
            );
          });
      })
      .catch(() => {});
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const { mutateAsync: createAssetTypeSensorType } =
    useCreateAssetTypeSensorType();
  const { mutateAsync: updateAssetTypeSensorType } =
    useUpdateAssetTypeSensorType();

  /**
   * Processes the update of a row in the grid.
   *
   * @param newRow - The new row data after the update.
   * @param oldRow - The old row data before the update.
   * @returns A promise that resolves to the result of the update or creation operation, or undefined if no update is necessary.
   *
   * This function checks if the new row data is different from the old row data. If they are the same, it does nothing.
   * If the row is not new, it updates the existing asset type sensor type.
   * If the row is new and has a temporary ID, it removes the ID and creates a new asset type sensor type with the remaining data.
   */
  const processRowUpdate = (
    newRow: GridRowModel<AssetTypesSensorTypeJSONObject>,
    oldRow: GridRowModel<AssetTypesSensorTypeJSONObject>,
  ) => {
    if (isEqual(newRow, oldRow)) {
      return;
    }

    if (!newRow.isNew) {
      return updateAssetTypeSensorType(newRow).then((res) => {
        void toasts.success("Sensor template updated", "Success");
        return res;
      });
    } else {
      if (newRow.id === NEW_ROW_ID) {
        delete newRow.id;
      }
      const { id, ...data } = newRow;
      return createAssetTypeSensorType({
        attributes: data,
        assetTypeId: theAssetTypeID,
      }).then((res) => {
        void toasts.success("Sensor template created", "Success");
        return res;
      });
    }
  };

  const gridColDef: readonly GridColDef<AssetTypesSensorTypeJSONObject>[] =
    React.useMemo(
      () => [
        {
          field: "id",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.id",
          ),
          editable: false,
          valueGetter: (value) =>
            value == null || value == undefined || value == NEW_ROW_ID
              ? ""
              : value,
        },
        {
          field: "default_name",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_name",
          ),
          editable: true,
          flex: 1,
        },
        {
          field: "default_short_name",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_short_name",
          ),
          editable: true,
          flex: 1,
        },
        {
          field: "default_attribute_key",
          editable: true,
          flex: 0.8,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_attribute_key",
          ),
        },
        {
          field: "default_attribute_key_type",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_attribute_key_type",
          ),
          type: "singleSelect",
          editable: true,

          valueGetter: (value) =>
            value == null || value == undefined ? "" : value,
          valueSetter: (value, row) => {
            return {
              ...row,
              default_attribute_key_type:
                value == "" ? null : (value as string),
            };
          },
          valueOptions: () => {
            const data = AttributeKeyDataTypes.map((type) => ({
              value: type as string,
              label: type as string,
            }));
            data.push({ value: "", label: "None" });
            return data;
          },
        },
        {
          field: "default_unit",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_unit",
          ),
          flex: 0.5,
        },
        {
          field: "default_display_unit",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_display_unit",
          ),
          flex: 0.5,
        },
        {
          field: "default_min",
          type: "number",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_min",
          ),
          flex: 0.5,
        },
        {
          field: "default_max",
          type: "number",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_max",
          ),
          flex: 0.5,
        },
        {
          field: "default_precision",
          type: "number",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_precision",
          ),
          flex: 0.5,
        },
        {
          field: "sensor_type_id",
          type: "singleSelect",
          editable: true,
          headerName: I18n.t("activerecord.models.sensor_type.one"),

          valueOptions: () => {
            return (
              sensorTypesQuery.data?.items?.map((sensorType) => ({
                value: toInteger(sensorType.id),
                label: `${getSensorTypeNameTranslation(sensorType.name)} (${sensorType.name} - ${sensorType.measurement_type})`,
              })) || []
            );
          },
        },
        {
          field: "default_description",
          type: "string",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_description",
          ),
          flex: 0.8,
        },
        {
          field: "creation_scope",
          type: "singleSelect",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.creation_scope",
          ),
          editable: true,
          valueGetter: (v) => (v == null || v == undefined ? "" : v),
          valueSetter: (v, row) => ({
            ...row,
            creation_scope: v == "" ? null : (v as "optional" | "autocreate"),
          }),

          valueOptions: [
            { value: "autocreate", label: "autocreate" },
            { label: "optional", value: "optional" },
            { label: "None", value: "" },
          ],
          flex: 0.5,
        },
        {
          field: "default_import_formula",
          type: "string",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_import_formula",
          ),
          flex: 0.5,
        },
        {
          field: "default_import_formula_target_key",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_import_formula_target_key",
          ),
          type: "string",
          editable: true,
          flex: 0.5,
        },
        {
          field: "default_sampling_rate_value",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_sampling_rate_value",
          ),
          type: "number",
          editable: true,
          flex: 0.5,
        },
        {
          field: "default_sampling_rate_unit",
          type: "singleSelect",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_sampling_rate_unit",
          ),
          editable: true,
          valueGetter: (v) => (v == null || v == undefined ? "" : v),
          valueSetter: (v, row) => ({
            ...row,
            default_sampling_rate_unit: v == "" ? null : (v as string),
          }),
          valueOptions: [
            { label: "minutes", value: "Minutes" },
            { value: "seconds", label: "Seconds" },
            { value: "hours", label: "Hours" },
            { value: "days", label: "Days" },
            { value: "weeks", label: "Weeks" },
            { label: "None", value: "" },
          ],
          flex: 0.5,
        },
        {
          field: "initial_value",
          type: "number",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.initial_value",
          ),
          flex: 0.5,
        },
        {
          field: "default_exp_data_period",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_exp_data_period",
          ),
          type: "number",
          editable: true,
          flex: 0.5,
        },
        {
          field: "default_derived",
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_derived",
          ),
          type: "boolean",
          editable: true,
          flex: 0.5,
        },
        {
          field: "default_context",
          type: "string",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_context",
          ),
          flex: 0.5,
        },
        {
          field: "default_context2",
          type: "string",
          editable: true,
          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.default_context2",
          ),
          flex: 0.5,
        },
        {
          field: "position",
          type: "number",
          editable: true,

          headerName: I18n.t(
            "activerecord.attributes.asset_types_sensor_type.position",
          ),
          flex: 0.5,
        },
        {
          field: "actions",
          type: "actions",
          headerName: I18n.t("frontend.actions"),
          width: 100,
          cellClassName: "actions",
          getActions: ({ id }) => {
            if (!canEdit) {
              return null;
            }
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<Save />}
                  label="Save"
                  key="save"
                  sx={{
                    color: "primary.main",
                  }}
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<Cancel />}
                  label="Cancel"
                  key="cancel"
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }

            return [
              <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                key="edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                key="delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
        },
      ],
      [rowModesModel, sensorTypesQuery.data],
    );

  return (
    <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
      <DataGrid
        columns={gridColDef}
        rows={rows}
        density="comfortable"
        onPaginationModelChange={(newModel) => {
          setPageSettings({ ...newModel });
        }}
        rowModesModel={rowModesModel}
        editMode="row"
        paginationMode="server"
        onRowModesModelChange={handleRowModesModelChange}
        slots={{
          toolbar: EditToolbar,
        }}
        rowCount={assetTypesSensorTypesQuery.data?.totalItems}
        slotProps={{
          toolbar: { setRows: setRows as any, setRowModesModel },
        }}
        onProcessRowUpdateError={(er) => {
          if (er instanceof HttpError) {
            void toasts.error(I18n.t("frontend.error"), er.response);
          } else {
            void toasts.error(
              I18n.t("frontend.error"),
              (er as Error).message ||
                I18n.t(
                  "frontend.asset_types_sensor_types_list.error_updating_sensor_template",
                ),
            );
          }
          logger.info(er);
        }}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};

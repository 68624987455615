import { cloneDeep, isEmpty, isNil } from "lodash";
import { User } from "../data/model";
import { MaintenanceJobListEntryProps } from "./maintenance_job_list_entry";

export function onMaintenanceUserUpdated(
  userName: string,
  selectedUser: User,
  props: MaintenanceJobListEntryProps,
): void {
  const newMaintenanceJob = cloneDeep(props.maintenanceJob);

  if (isNil(selectedUser) && isEmpty(userName)) {
    newMaintenanceJob.user_attributes = null;
    newMaintenanceJob.user_id = null;
    newMaintenanceJob.user_type = null;
  } else if (isNil(selectedUser)) {
    if (isEmpty(userName)) return; // do not allow empty names to be applied
    const newUser = {
      id: null,
      type: "GuestUser",
      // remove leading/trailing whitespaces for entered user names
      name: userName.trim(),
    } as User;
    newMaintenanceJob.user_attributes = newUser;
    newMaintenanceJob.user_id = null;
    newMaintenanceJob.user_type = "GuestUser";
    props.onGuestUserAdded(userName, newUser);
  } else {
    if (
      isNil(selectedUser.id) &&
      isNil(
        props.users.find(
          (user) => user.type == "GuestUser" && user.name === selectedUser.name,
        ),
      )
    ) {
      // a new guest user was selected
      props.onGuestUserAdded(userName, selectedUser);
    }
    newMaintenanceJob.user_attributes = selectedUser;
    newMaintenanceJob.user_id = selectedUser.id;
    newMaintenanceJob.user_type = selectedUser.type;
  }

  props.onUpdateMaintenanceJob(newMaintenanceJob);
}

import {
  SimpleTreeView,
  TreeItem as TreeItemComponent,
} from "@mui/x-tree-view";
import * as React from "react";

import {
  castArray,
  each,
  includes,
  isEmpty,
  isNil,
  map,
  noop,
  toString,
} from "lodash";
import { Component } from "react";

import { Checkbox, FormControlLabel, FormGroup, Radio } from "@mui/material";
import { TreeItem } from "../../models/tree_item";
import { extractIdsFromTree } from "../../utils/tree_utils";
import { IDType } from "../../utils/urls/url_utils";

export interface TreeViewProps {
  roots: TreeItem | TreeItem[];
  checkable: boolean;
  multiselect?: boolean;
  onItemClicked: (item: TreeItem) => void;
  onItemChecked: (item: TreeItem) => void;
  onItemUnchecked: (item: TreeItem) => void;

  isItemChecked: (item: TreeItem) => boolean;
  checkedItemIds: IDType[];

  itemContent(item: TreeItem, parent?: TreeItem): JSX.Element;
  expandAll: boolean;
  icon?: string;
}

interface TreeViewState {
  expandedIds: string[];
}

export class ItemTree extends Component<TreeViewProps, TreeViewState> {
  static defaultProps: TreeViewProps = {
    onItemClicked: noop,
    onItemChecked: noop,
    onItemUnchecked: noop,
    multiselect: true,
    isItemChecked: null,
    roots: [],
    checkable: false,
    itemContent: null,
    checkedItemIds: null,
    expandAll: true,
  };

  constructor(props: TreeViewProps) {
    super(props);

    //this.state = { expandedIds:  };
  }
  render(): React.ReactNode {
    if (isNil(this.props.roots)) return null;
    return (
      <SimpleTreeView
        defaultExpandedItems={this.expandedIdFromProps()}
        disableSelection={!this.props.checkable}
      >
        {this.renderChildren(castArray(this.props.roots))}
      </SimpleTreeView>
    );
  }

  renderChildren(items: TreeItem[], parent?: TreeItem) {
    if (isNil(items) || isEmpty(items)) return null;
    return map(items, (item, index) => {
      if (isNil(item)) return null;

      return (
        <TreeItemComponent
          key={index}
          itemId={toString(item.id)}
          label={this.itemContent(item, parent)}
        >
          {this.renderChildren(item.children, item)}
        </TreeItemComponent>
      );
    });
  }

  itemContent(item: TreeItem, parent?: TreeItem): string | JSX.Element {
    const content = this.props.itemContent
      ? this.props.itemContent(item, parent)
      : item.title;
    if (this.props.checkable) {
      return (
        <FormGroup key={`filter-asset-${item.id}`}>
          <FormControlLabel
            label={content}
            control={
              this.props.multiselect ? (
                <Checkbox
                  size="small"
                  value={item.id}
                  checked={this.isItemChecked(item)}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.props.onItemChecked(item);
                    } else {
                      this.props.onItemUnchecked(item);
                    }
                  }}
                />
              ) : (
                <Radio
                  size="small"
                  value={item.id}
                  checked={this.isItemChecked(item)}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.props.onItemChecked(item);
                    } else {
                      this.props.onItemUnchecked(item);
                    }
                  }}
                />
              )
            }
          />
        </FormGroup>
      );
    } else {
      if (this.props.itemContent) return this.props.itemContent(item, parent);

      return (
        <span
          onClick={(event) => {
            event.stopPropagation();
            this.props.onItemClicked(item);
          }}
        >
          {item.title};
        </span>
      );
    }
  }

  private isItemChecked(item: TreeItem): boolean {
    if (this.props.isItemChecked) {
      return this.props.isItemChecked(item);
    } else if (!isNil(this.props.checkedItemIds)) {
      return includes(this.props.checkedItemIds, item.id);
    } else {
      return false;
    }
  }

  private expandedIdFromProps(): string[] {
    const expandedIds: string[] = [];
    if (this.props.expandAll) {
      each(this.props.roots, (root) => {
        extractIdsFromTree(root, expandedIds);
      });
    }
    return expandedIds;
  }
}

import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryFull,
  CropSquare,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SvgIconComponent,
} from "@mui/icons-material";
import { defaultTo, get, isNil, startsWith, toString } from "lodash";
import * as React from "react";
import { FaSizeType, Icon, IconStack } from "./icon";

const MATERIAL_UI_ICONS_BY_NAME = {
  "mi-signal-cellular-4": SignalCellular4Bar,
  "mi-signal-cellular-3": SignalCellular3Bar,
  "mi-signal-cellular-2": SignalCellular2Bar,
  "mi-signal-cellular-1": SignalCellular1Bar,
  "mi-signal-cellular-0": SignalCellular0Bar,
  "mi-battery-alert": BatteryAlert,
  "mi-battery-20": Battery20,
  "mi-battery-30": Battery30,
  "mi-battery-50": Battery50,
  "mi-battery-60": Battery60,
  "mi-battery-80": Battery80,
  "mi-battery-90": Battery90,
  "mi-battery-full": BatteryFull,
};

/** Creates a react component for the given icon name.
 * Supports stacked icons by supplying
 *
 * @export
 * @param {(string | keyof typeof MATERIAL_UI_ICONS_BY_NAME)} iconName
 * @param {FaSizeType} [size="4x"]
 * @return {*}  {React.ReactElement}
 */
export function getIconForName(
  iconName: string | keyof typeof MATERIAL_UI_ICONS_BY_NAME,
  size: FaSizeType = "4x",
  className?: string,
  onClick?: () => void,
): React.ReactElement {
  const icons = defaultTo(iconName, "circle").split(" ");
  if (icons.length == 1) {
    if (startsWith(icons[0], "mi-")) {
      const sizeFactor = size?.charAt(0);
      let MuiIcon = get(
        MATERIAL_UI_ICONS_BY_NAME,
        icons[0],
      ) as SvgIconComponent;
      if (isNil(MuiIcon)) {
        MuiIcon = CropSquare;
      }
      return (
        <span
          style={{ fontSize: `${sizeFactor}em` }}
          className={className}
          onClick={onClick}
        >
          <MuiIcon fontSize="inherit" />
        </span>
      );
    }
    return <Icon icon={icons[0]} size={size} fixedWidth onClick={onClick} />;
  } else if (icons.length > 1) {
    return (
      <IconStack
        icons={icons}
        size={size}
        className={"fa-fw " + toString(className)}
        onClick={onClick}
      />
    );
  } else {
    return (
      <Icon
        icon={"circle"}
        size={size}
        fixedWidth
        className={className}
        onClick={onClick}
      />
    );
  }
}

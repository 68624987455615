import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";

import { Role } from "./role_list_item";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { User, UserRoleForm } from "./user_role_form";

const roots: Root[] = [];
/**
 * Initialize react component UserRoleForm within all elements with data-toggle="measurement-plan-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeUserRoleForm(
  selector: JQuery = $('[data-toggle="user-role-form"]'),
): void {
  selector.each((_i, element) => {
    const user = $(element).data("user") as User;
    const availableRoles = $(element).data("available-roles") as Role[];
    const readOnly = $(element).data("read-only") as boolean;

    if (isNil(user)) {
      throw new Error("Could not initialize UserRoleForm: user missing.");
    }

    if (isNil(availableRoles)) {
      throw new Error(
        "Could not initialize UserRoleForm: availableRoles missing.",
      );
    }

    const root = createRoot(element);
    roots.push(root);
    root.render(
      <AppRoot>
        <UserRoleForm
          user={user}
          availableRoles={availableRoles}
          readOnly={readOnly}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all ellements
 */
export function destroyUserRoleForm(): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}

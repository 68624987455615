import { Settings } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import Bluebird from "bluebird";
import {
  compact,
  defaultTo,
  each,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isString,
  map,
  merge,
  set,
  toInteger,
  uniq,
} from "lodash";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  LineChartStatistics,
  LineConfig,
  PlotlyLineChart,
} from "../../charting/plotly_line_chart";

import { WidgetController } from "../../controller/widget_controller";
import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import {
  SamplingRate,
  SensorValueType,
  samplingRateFromString,
  samplingRateToString,
} from "../../models/sensor";
import { time_series_api_sensor_path } from "../../routes";
import {
  addTimeScopeToAnchor,
  loadPropsFromAnchor,
  loadTimeScopeFromAnchor,
  updatePropInAnchor,
} from "../../utils/anchor_prop_store";
import { createTimeRanges } from "../../utils/time_scopes";
import {
  buildLineDiagramAnnotationUrls,
  buildLineDiagramDataUrls,
} from "../../widgets/line_diagram_widget";
import { LineDiagramWidgetConfigSerialized } from "../../widgets/line_diagram_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { LoadingIcon } from "../common/icon";
import { DiagramSettings } from "../diagram_settings";
import { LineDiagramWidgetProps } from "./line_diagram_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { ChartStatisticsDisplay } from "../../charting/react/chart_statistics_display";
import { useDebounce } from "@uidotdev/usehooks";

export const LineDiagramWidget: React.FunctionComponent<
  LineDiagramWidgetProps
> = ({
  allowFullscreen = true,
  dataUrls = [],
  activeContextStateMachineId: activeContextStateMachineIdFromProps = null,
  samplingRate: samplingRateFromProps = null,
  samplingMode: samplingModeFromProps = "avg",
  sensorIds = [],
  hideSettings = false,
  disableSettings = false,
  storeSettingsInAnchor = true,
  diagramHeight = 350,
  lineOptions = {
    lineMode: "lines+markers",
    lineShape: "linear",
  },
  ...props
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [showSettings, setShowSettings] = useState(!hideSettings);
  const [showSettingsFullscreen, setShowSettingsFullscreen] = useState(false);

  const [timeRange, setTimeRange] = useState<DateRange>(
    props.timeRange ||
      new DateRange(moment().startOf("day"), moment().endOf("day")),
  );

  const [samplingRate, setSamplingRate] = useState<SamplingRate | null>(
    samplingRateFromProps,
  );
  const [samplingMode, setSamplingMode] = useState(samplingModeFromProps);
  const [contextStateMachines, setContextStateMachines] = useState<
    ContextStateMachineJSONObject[]
  >([]);
  const [activeContextStateMachineId, setActiveContextStateMachineId] =
    useState<string | null>(
      activeContextStateMachineIdFromProps?.toString() || null,
    );

  const [statistics, setStatistics] = useState<{
    s: LineChartStatistics;
    colorFun: (index: number) => string;
  }>();
  const debouncedStatistics = useDebounce(statistics, 1000);
  const diagramContainer = useRef<HTMLDivElement>();
  const statisticsContainer = useRef<HTMLDivElement | null>(null);

  const lineDiagramRef = useRef<PlotlyLineChart>();

  const [dataUpdateEnabled, setDataUpdateEnabled] = useState(
    isNil(timeRange) || timeRange.contains(moment()),
  );

  useEffect(() => {
    setDataUpdateEnabled(isNil(timeRange) || timeRange.contains(moment()));
  }, [timeRange?.end]);

  useEffect(() => {
    if (storeSettingsInAnchor) {
      const timeRanges = createTimeRanges();
      const anchorTimeRange = loadTimeScopeFromAnchor(timeRanges.ranges);
      if (!isNil(anchorTimeRange)) {
        setTimeRange(new DateRange(anchorTimeRange[0], anchorTimeRange[1]));
      }

      const anchorProps = loadPropsFromAnchor();
      setActiveContextStateMachineId(
        defaultTo(
          anchorProps["active-context-state-machine-id"],
          activeContextStateMachineId,
        ) as string,
      );

      setSamplingRate(
        defaultTo(
          samplingRateFromString(anchorProps["sampling-rate"] as string),
          samplingRate,
        ),
      );
    }

    if (isNil(samplingRate?.unit) || isNil(samplingRate?.value)) {
      setSamplingRate(null);
    }

    if (isNil(timeRange?.start) && isNil(timeRange?.end)) {
      // should not happen as time range is always initialized, but just in case set the last day as default
      setTimeRange(
        new DateRange(moment().startOf("day"), moment().endOf("day")),
      );
    }

    loadStateMachines();
  }, []);

  useEffect(() => {
    lineDiagramRef.current?.setActiveContextStateMachineId(
      activeContextStateMachineId,
    );
  }, [lineDiagramRef.current, activeContextStateMachineId]);

  // Handle sensor value updates
  const handleSensorValueUpdate = React.useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      if (!isNumber(value)) {
        return;
      }
      lineDiagramRef.current?.addValue(
        attributeKeyId,
        sensorId,
        time,
        value,
        unit,
      );
    },
    [lineDiagramRef.current],
  );

  useEffect(() => {
    if (lineDiagramRef.current) {
      // only if lineDiagramRef is already initialized
      if (dataUpdateEnabled) {
        // and the data update is enabled
        const sensorEventSubscriber = {
          handleSensorValueUpdate,
        };
        const subscriptionIds = map(sensorIds, (id) =>
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            sensorEventSubscriber,
            toInteger(id),
          ),
        );
        return () => {
          each(subscriptionIds, (s) => {
            WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
              s,
            );
          });
        };
      }
    }
  }, [handleSensorValueUpdate, dataUpdateEnabled]);

  useEffect(() => {
    if (props.onUpdateStatistics && statistics) {
      props.onUpdateStatistics(statistics.s, statistics.colorFun);
    }
  }, [statistics]);
  // Update time range if it changes
  useEffect(() => {
    if (props.timeRange && !props.timeRange.isSame(timeRange)) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange?.start, props.timeRange?.end]);

  // Update fullscreen state
  useEffect(() => {
    setFullscreen(props.fullscreen);
  }, [props.fullscreen]);

  // resize on fullscreen change
  useEffect(() => {
    lineDiagramRef.current?.resize();
  }, [fullscreen]);

  const lineConfigs = React.useMemo<LineConfig[]>(() => {
    let configs: LineConfig[];
    if (isNil(dataUrls)) {
      configs = map(sensorIds, (sid) => {
        const cfg: LineConfig = {
          baseUrl: time_series_api_sensor_path(sid, {
            format: "bin",
            _options: true,
          }),
        };
        return cfg;
      });
    } else {
      configs = dataUrls.map((url) => ({
        baseUrl: url,
      }));
    }
    return configs;
  }, [sensorIds, dataUrls]);

  // Update line diagram when line configs change
  useEffect(
    () => {
      if (!isNil(diagramContainer.current)) {
        if (isNil(lineDiagramRef.current)) {
          const ld = new PlotlyLineChart(
            {
              baseElementIdOrElement: diagramContainer.current,
              statisticsElementOrId: statisticsContainer.current,
            },
            {
              lineConfigs: lineConfigs,
              trendURLs: props.trendLineUrls,
              annotationURLs: props.annotationUrls,
            },
            {
              activeContextStateMachineId: activeContextStateMachineId,
              contextStateMachineIds: props.contextStateMachineIds,
            },
            {
              yAxesPerUnit: props.oneYAxisPerUnitOnly,
              offsetYAxis: props.offsetYAxis,
              zoomMode: props.zoomMode,
            },
            (stats, colorFun) => {
              setStatistics({ s: stats, colorFun });
            },
          );

          ld.lineMode = lineOptions?.lineMode;
          ld.lineShape = lineOptions?.lineShape;
          ld.setSamplingRate(samplingRate, "avg", false);

          ld.setChartAnnotationOptions(props.chartAnnotationOptions, false);
          ld.setTimeScope(timeRange);
          lineDiagramRef.current = ld;
        } else {
          lineDiagramRef.current.setChartAnnotationOptions(
            props.chartAnnotationOptions,
            false,
          );

          if (!isEqual(lineConfigs, lineDiagramRef.current.getLineConfigs())) {
            lineDiagramRef.current.setLineConfigs(lineConfigs);
          } else {
            lineDiagramRef.current
              .initElements(
                {
                  baseElementIdOrElement: diagramContainer.current,
                  statisticsElementOrId: statisticsContainer.current,
                },
                false,
              )
              .then(() => {
                lineDiagramRef.current?.updateChart();
              });
          }
        }
      }
    },
    // if line config changes or diagram container changes, e.g., on switching full screen
    [
      lineConfigs,
      diagramContainer.current,
      statisticsContainer.current,
      lineDiagramRef.current,
    ],
  );

  // Load context state machines
  const loadStateMachines = async () => {
    const csms = await Bluebird.map(
      uniq(compact(props.contextStateMachineIds)),
      (id) => loadContextStateMachine(id, ["stateful_item"]),
    );
    setContextStateMachines(csms);
  };

  useEffect(() => {
    if (storeSettingsInAnchor)
      addTimeScopeToAnchor(timeRange.start, timeRange.end);
    if (props.onChangeTimeRange) {
      props.onChangeTimeRange(timeRange);
    }
    if (timeRange) {
      lineDiagramRef.current?.setTimeScope(timeRange);
    }
  }, [timeRange?.start?.valueOf(), timeRange?.end?.valueOf()]);

  useEffect(() => {
    if (isNil(samplingRate?.value) || isNil(samplingRate?.unit)) {
      if (storeSettingsInAnchor) updatePropInAnchor("sampling-rate", null);
    } else {
      if (storeSettingsInAnchor)
        updatePropInAnchor("sampling-rate", samplingRateToString(samplingRate));
    }
    if (storeSettingsInAnchor)
      updatePropInAnchor("sampling-mode", samplingMode);
    lineDiagramRef.current?.setSamplingRate(samplingRate, samplingMode);
  }, [samplingMode, samplingRate]);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      if (lineDiagramRef.current) {
        lineDiagramRef.current.destroyChart();
      }
    };
  }, []);

  const content = (
    <Grid container spacing={2}>
      {!disableSettings && (
        <Grid item xs={12}>
          <DiagramSettings
            key={"settings"}
            visible={showSettings}
            samplingRate={samplingRate}
            startDate={timeRange?.start}
            endDate={timeRange?.end}
            samplingMode={samplingMode}
            contextStateMachines={contextStateMachines}
            selectedContextStateMachineId={activeContextStateMachineId}
            storeSettingsInAnchor={storeSettingsInAnchor}
            showCsmSelection={props.showStateSelection}
            mobileOpen={showSettingsFullscreen}
            onSelectContextStateMachine={(
              csm: ContextStateMachineJSONObject,
            ) => {
              setActiveContextStateMachineId(csm?.id.toString());
            }}
            onChangeBeginAtZero={(beginAtZero) => {
              if (storeSettingsInAnchor)
                updatePropInAnchor("begin-at-zero", beginAtZero);
              lineDiagramRef.current?.setBeginAtZero(beginAtZero);
            }}
            onChangeSamplingRate={(samplingRate, samplingMode) => {
              setSamplingRate(samplingRate);
              setSamplingMode(samplingMode);
            }}
            onChangeTimeRange={(startTime, endTime, label) => {
              setTimeRange(new DateRange(startTime, endTime));
            }}
            onRequestHide={() => {
              setShowSettings(false);
              setShowSettingsFullscreen(false);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          className={`sensor-diagram-container`}
          height={
            fullscreen ? "calc(100vh - 250px)" : defaultTo(diagramHeight, 300)
          }
          minHeight={150}
          ref={diagramContainer}
          key={"diagram-container"}
        >
          <Box className="sensor-diagram-no-data" display="none" height="100%">
            <Box m={"auto"}>
              <Typography variant="h5">{I18n.t("frontend.no_data")}</Typography>
            </Box>
          </Box>
          <Box className="sensor-diagram-error" display="none">
            <Box m={"auto"}>
              <Typography variant="h5">Error while loading data.</Typography>
              <Box className="sensor-diagram-error-message" display="none" />
            </Box>
          </Box>
          <Grid
            container
            justifyContent="center"
            className="loading-overlay text-center"
            alignItems={"center"}
            display="none"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <LoadingIcon size="6x" />
            </Box>
          </Grid>
          <Box className="sensor-diagram"></Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {props.onUpdateStatistics ? null : debouncedStatistics?.s
            ?.chartStatistics ? (
          <ChartStatisticsDisplay
            chartStatistics={debouncedStatistics?.s?.chartStatistics}
            color={statistics?.colorFun}
          />
        ) : null}
      </Grid>
    </Grid>
  );

  const boxedContent = props.encloseInIBox ? (
    <WidgetBox
      {...props}
      allowFullscreen={true}
      minWidthPx={250}
      onFullscreen={(fullscreen) => {
        props.onFullscreen?.(fullscreen);
        setFullscreen(fullscreen);
      }}
      tools={[
        !disableSettings && (
          <IconButton
            key={"diagram-settings"}
            size="small"
            onClick={() => {
              setShowSettings(!showSettings);
              setShowSettingsFullscreen(!showSettingsFullscreen);
            }}
          >
            <Settings />
          </IconButton>
        ),
      ]}
    >
      {content}
    </WidgetBox>
  ) : (
    content
  );

  return boxedContent;
};

function serializedConfigToProps(
  config: LineDiagramWidgetConfigSerialized,
): LineDiagramWidgetProps {
  let sensorIds = config.sensor_ids;
  if (isNumber(sensorIds) || isString(sensorIds)) {
    sensorIds = [sensorIds as number];
  }

  let samplingRate: SamplingRate;
  if (!isNil(config.sampling_rate?.value)) {
    samplingRate = config.sampling_rate;
  }

  const showStateSelection = defaultTo(
    config.show_state_selection,
    !isEmpty(config.context_state_machine_ids),
  );

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    sensorIds,
    diagramHeight: config.diagram_height,
    contentTitle: config.title,
    dataUrls: buildLineDiagramDataUrls(
      sensorIds,
      config.asset_ids,
      config.kpi_ids,
    ),
    oneYAxisPerUnitOnly: config.unify_unit_axes,
    contextStateMachineIds: config.context_state_machine_ids,
    activeContextStateMachineId: config.active_context_state_machine_id,
    annotationUrls: config.show_annotations
      ? buildLineDiagramAnnotationUrls(sensorIds, config.asset_ids)
      : [],
    samplingRate,
    title: config.widget_name,
    zoomMode: config.zoom_mode,
    showStatistics: config.show_statistics,
    showStateSelection,
    hideSettings: config.hide_settings,
    disableSettings: config.disable_settings,
    offsetYAxis: config.offset_y_axis,
    lineOptions: {
      lineMode: config.line_mode || "lines+markers",
      lineShape: config.line_shape || "linear",
    },
    storeSettingsInAnchor: config.store_settings_in_anchor,
  } as LineDiagramWidgetProps);
}

export const LineDiagramWidgetDefinition: SialogicWidgetDefinition<
  typeof LineDiagramWidget,
  typeof serializedConfigToProps
> = {
  Component: LineDiagramWidget,
  serializedConfigToProps: serializedConfigToProps,
};

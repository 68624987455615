import { Grid } from "@mui/material";
import { defaultTo, each, isEmpty, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { PercentageWidgetConfigSerialized } from "../../widgets/percentage_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PercentageBar } from "../common/percentage_bar";
import { SensorPercentageValueCalculator } from "./algorithm/sensor_percentage_value_calculator";
import { loadPercentageWidgetSensorData } from "./percentage_widget.data";
import { PercentageWidgetProps } from "./percentage_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { WidgetTimestampGridItem } from "./widget_timestamp";

export const PercentageWidget: React.FunctionComponent<
  PercentageWidgetProps
> = ({
  mode = "bar",
  fallbackToLastValue = false,
  ignoreTimeScope = false,
  ...props
}: PercentageWidgetProps) => {
  const [numeratorValue, setNumeratorValue] = useState(props.numeratorValue);
  const [numeratorTimestamp, setNumeratorTimestamp] = useState(
    defaultTo(props.numeratorTimestamp, props.timestamp),
  );
  const [denominatorValue, setDenominatorValue] = useState(
    props.denominatorValue,
  );
  const [denominatorTimestamp, setDenominatorTimestamp] = useState(
    defaultTo(props.denominatorTimestamp, props.timestamp),
  );

  // Create an instance of SensorPercentageValueCalculator
  const valueCalculator = React.useRef(
    new SensorPercentageValueCalculator(
      props.numeratorBaseValue,
      props.denominatorBaseValue,
      props.limitToRange ? props.range : undefined,
    ),
  );

  // update the base values when they change
  useEffect(() => {
    valueCalculator.current?.setNumeratorBaseValue(props.numeratorBaseValue);
    valueCalculator.current?.setDenominatorBaseValue(
      props.denominatorBaseValue,
    );
  }, [props.numeratorBaseValue, props.denominatorBaseValue]);

  // update the base values when they change
  useEffect(() => {
    valueCalculator.current.setValueRange(
      props.limitToRange ? props.range : undefined,
    );
  }, [props.limitToRange, props.range]);

  const [value, setValue] = useState(
    props.value || valueCalculator.current.calculateValue(),
  );
  const [timestampLabel, setTimestampLabel] = useState(
    getTimeString(props.timeScopeName, props.timestamp),
  );
  const [fullscreen, setFullscreen] = useState(false);

  // Callback to calculate the percentage value
  const calculateValue = useCallback(
    (
      numerator: number,
      numeratorTimestamp: Moment,
      denominator: number,
      denominatorTimestamp: Moment,
    ): number => {
      valueCalculator.current.setNumeratorValue(numerator, numeratorTimestamp);
      valueCalculator.current.setDenominatorValue(
        denominator,
        denominatorTimestamp,
      );
      return valueCalculator.current.calculateValue();
    },
    [valueCalculator],
  );

  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [timeRange, setTimeRange] = useState(props.timeRange);

  // Callback to handle sensor value updates
  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ): void => {
      if (
        !isNumber(value) ||
        (!isNil(timeRange) && !timeRange.contains(time))
      ) {
        return;
      }

      let theValue = value;
      if (sensorId == props.numeratorSensorId) {
        if (!isNil(unit) && !isNil(props.numeratorUnit)) {
          theValue = convertToUnit(value, unit, props.denominatorUnit);
        }

        setNumeratorValue(theValue);
        setNumeratorTimestamp(time);
      } else if (sensorId == props.denominatorSensorId) {
        if (!isNil(unit) && !isNil(props.denominatorUnit)) {
          theValue = convertToUnit(value, unit, props.denominatorUnit);
        }
        setDenominatorValue(theValue);
        setDenominatorTimestamp(time);
      }
    },
    [
      props.denominatorSensorId,
      props.denominatorUnit,
      props.numeratorUnit,
      props.numeratorSensorId,
      timeRange,
    ],
  );

  useEffect(() => {
    if (props.timeRange) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange]);

  // select the timestamp to show
  useEffect(() => {
    if (isNil(numeratorTimestamp)) {
      if (!isNil(denominatorTimestamp)) {
        setTimestamp(denominatorTimestamp);
      } else {
        setTimestamp(null);
      }
    } else {
      if (isNil(denominatorTimestamp)) {
        // no timestamp for denominator and numerator
        setTimestamp(numeratorTimestamp);
      } else {
        // set newest timestamp
        numeratorTimestamp.isAfter(denominatorTimestamp)
          ? setTimestamp(numeratorTimestamp)
          : setTimestamp(denominatorTimestamp);
      }
    }
  }, [numeratorTimestamp, denominatorTimestamp]);

  // select the timestamp label to show
  useEffect(() => {
    setTimestampLabel(getTimeString(props.timeScopeName, timestamp));
  }, [props.timeScopeName, timestamp]);

  // calculate the value on mount and when the numerator or denominator value changes
  useEffect(() => {
    const theValue = calculateValue(
      numeratorValue,
      numeratorTimestamp,
      denominatorValue,
      denominatorTimestamp,
    );
    setValue(theValue);
  }, [
    denominatorValue,
    numeratorValue,
    valueCalculator.current?.denominatorBaseValue,
    valueCalculator.current?.numeratorBaseValue,
  ]);

  // manage sensor data subscriptions
  useEffect(() => {
    // Subscriber object to handle sensor value updates
    const subscriber = { handleSensorValueUpdate };
    const subscriptions: number[] = [];
    if (WidgetController.getInstance()) {
      if (
        props.updateEnabled &&
        (isNil(timeRange) || timeRange.contains(moment()))
      ) {
        subscriptions.push(
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            subscriber,
            props.numeratorSensorId,
          ),
        );
        subscriptions.push(
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            subscriber,
            props.denominatorSensorId,
          ),
        );
      }
    }
    return () => {
      if (WidgetController.getInstance()) {
        each(subscriptions, (subscriptionId) => {
          WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
            subscriptionId,
          );
        });
      }
    };
  }, [
    props.denominatorSensorId,
    props.numeratorSensorId,
    props.updateEnabled,
    timeRange?.start,
    timeRange?.end,
  ]);

  // Effect to load sensor data on mount and when sensor IDs change
  useEffect(() => {
    if (props.numeratorSensorId && props.denominatorSensorId) {
      void loadPercentageWidgetSensorData(
        props.numeratorSensorId,
        props.denominatorSensorId,
        props.numeratorUnit,
        props.denominatorUnit,
        timeRange,
        fallbackToLastValue,
      ).then((v) => {
        if (v) {
          // when data could be loaded
          const { nomValue, nomTime, denomValue, denomTime } = v;
          setNumeratorValue(nomValue);
          setNumeratorTimestamp(nomTime);
          setDenominatorValue(denomValue);
          setDenominatorTimestamp(denomTime);
        }
      });
    }
  }, [
    props.denominatorSensorId,
    props.numeratorSensorId,
    timeRange,
    props.numeratorUnit,
    props.denominatorUnit,
    fallbackToLastValue,
  ]);

  return (
    <WidgetBox
      {...props}
      onFullscreen={(fullscreen) => {
        setFullscreen(fullscreen);
      }}
      onRequestDebug={() => {
        console.log(
          `Widget ${props.widgetId} ${props.className} Props:`,
          props,
          "State: ",
          {
            fallbackToLastValue,
            numeratorValue,
            numeratorTimestamp,
            denominatorValue,
            denominatorTimestamp,
            value,
            timestampLabel,
          },
        );
      }}
    >
      {mode == "gauge" ? (
        <Grid container direction="column" justifyContent="center">
          <Grid item xs={12}>
            <PlotlyGaugeChart
              divId={`widget-${props.widgetId}-diagram-container`}
              value={value}
              height={fullscreen ? 600 : props.gaugeHeight}
              maxWidth={fullscreen ? "80%" : null}
              unit="%"
            />
          </Grid>

          {isNil(timestampLabel) ? null : (
            <WidgetTimestampGridItem
              timestamp={timestampLabel}
              align="center"
            />
          )}
        </Grid>
      ) : (
        <Grid container direction="column" justifyContent="space-between">
          <Grid item xs={12}>
            <PercentageBar value={value} max={100} timestamp={timestampLabel} />
          </Grid>
        </Grid>
      )}
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: PercentageWidgetConfigSerialized,
): PercentageWidgetProps {
  let range;
  let limitToRange;
  if (config.limit_to_range === true) {
    limitToRange = true;
    range = { min: 0, max: 100 };
  } else if (!isEmpty(config.limit_to_range)) {
    limitToRange = true;
    const limits = range as { min: number; max: number };
    range = limits;
  } else {
    limitToRange = false;
  }
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    updateEnabled: !config.disable_update,
    value: config?.value as number,
    range,
    limitToRange,
    numeratorValue: config.numerator?.value,
    numeratorTimestamp: config.numerator
      ? moment(config.numerator.timestamp)
      : null,
    numeratorSensorId: config.numerator?.sensor_id,
    numeratorBaseValue: config.numerator?.base_value,
    denominatorSensorId: config.denominator?.sensor_id,
    denominatorValue: config.denominator?.value,
    denominatorTimestamp: config.denominator
      ? moment(config.denominator.timestamp)
      : null,
    denominatorBaseValue: config.denominator?.base_value,
    timestamp: config?.timestamp ? moment(config.timestamp) : null,
    mode: defaultTo(config.display_mode, "bar"),
    timeScopeName: config.timescope,
    sensorType: config.sensor_type,
    measurementType: config.measurement_type,
    iconName: config?.icon_name,
    iconSize: config?.icon_size,
    precision: config?.precision,
    ignoreTimeScope: config.ignore_time_scope,
    fallbackToLastValue: config.fallback_to_last_value,
    gaugeHeight: config.gauge_height,
  } as PercentageWidgetProps);
}

export const PercentageWidgetDefinition: SialogicWidgetDefinition<
  typeof PercentageWidget,
  typeof serializedConfigToProps
> = {
  Component: PercentageWidget,
  serializedConfigToProps: serializedConfigToProps,
};

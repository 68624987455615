import {
  each,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isString,
  reject,
  split,
} from "lodash";
import moment, { Moment } from "moment";

import {
  jsonApiIncludeParamsArray,
  jsonApiPagingParamsArray,
} from "../../json_api/jsonapi_tools";

export type IDType = number | string;

export type ParamValueType = null | Moment | boolean | string | number | Date;

export type RequestFormat = "json" | "html" | "" | "bin" | null;
export type ParamsType = ParamType[];
export type ParamType = [string, string | number | Array<string | number>];
export const API_PREFIX = "api";

export function formatString(format: RequestFormat): string {
  return isEmpty(format) ? "" : "." + format;
}
export function localizedBasePath(api = false) {
  if (api) {
    return `/${I18n.locale}/${API_PREFIX}`;
  } else {
    return `/${I18n.locale}`;
  }
}

export function objectToParams(object: Record<string, ParamValueType>): string {
  const params: string[] = [];

  for (const key in object) {
    let value: ParamValueType = object[key];
    if (isNil(value)) {
      continue;
    }
    if (moment.isMoment(value) || value instanceof Date) {
      value = value.toISOString();
    }
    if (isBoolean(value)) {
      value = value ? "true" : "false";
    }

    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  if (isEmpty(params)) {
    return "";
  }

  return `?${params.join("&")}`;
}

export function applyParamsToBaseUrl(
  baseUrl: string,
  pageNumber?: number,
  pageSize?: number,
  includes: string[] = [],
  params: ParamsType = [],
): string {
  let url: URL;
  let isRelative = false;
  if (baseUrl.startsWith("/")) {
    isRelative = true;
    url = new URL(baseUrl, window.location.origin);
  } else {
    url = new URL(baseUrl);
  }

  const addedParams = jsonApiIncludeParamsArray(includes).concat(
    jsonApiPagingParamsArray(pageNumber, pageSize),
  );

  url = applyParamsDataToBaseUrl(url, addedParams);
  url = applyParamsDataToBaseUrl(url, params);

  if (isRelative) {
    return url.pathname + url.search;
  } else {
    return url.toString();
  }
}

export function applyParamsDataToBaseUrl(
  url: string | URL,
  params: ParamsType = [],
): URL {
  let theUrl: URL = url as URL;

  if (isString(url)) {
    if (url.startsWith("/")) {
      theUrl = new URL(url, window.location.origin);
    } else {
      theUrl = new URL(url);
    }
  }

  if (!isEmpty(params)) {
    each(reject(params, isEmpty), (param: string | [string, string]) => {
      let name: string, value: string;
      if (isArray(param)) {
        [name, value] = param;
      } else {
        [name, value] = split(param, "=");
      }
      theUrl.searchParams.append(name, value);
    });
  }

  return theUrl;
}

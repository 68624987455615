import MeasurementPlanDispatcher from "./measurement_plan_dispatcher";
import { MeasurementPlanErrorResponse, Asset } from "./models";
import {
  MeasurementType,
  MeasurementTypes,
} from "../../../models/measurement_type";
import { MeasurementPlanCreationMode } from "../views/measurement_plan_form_step_1";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementPlan } from "../../../models/measurement_plan";

export interface ResetStateAction {
  type: "RESET_STATE";
}

export type FormMode = "create" | "edit" | "show";
export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";
  assetId?: string | number;
  measurementPlan: MeasurementPlan;
  mode: FormMode;
  templateTypes: MeasurementType[];
  availableMeasurementCategorizations: MeasurementCategorization[];
  submitUrl: string;
  submitMethod: "POST" | "PATCH";
}

export interface SelectMeasurementTypeTemplateAction {
  type: "SELECT_MEASUREMENT_TYPE_TEMPLATE";
  template: MeasurementType;
}
export interface SelectTypeOfMeasurementAction {
  type: "SELECT_TYPE_OF_MEASUREMENT";
  typeOfMeasurement: MeasurementTypes;
}

export interface SelectCreationModeAction {
  type: "SELECT_CREATION_MODE";
  createBy: MeasurementPlanCreationMode;
}

export interface UpdateMeasurementPlanAction {
  type: "UPDATE_MEASUREMENT_PLAN";
  measurementPlan: MeasurementPlan;
}

export interface SelectMeasurementUnitAction {
  type: "SELECT_MEASUREMENT_UNIT";
  unit: string;
}

export interface FinishStep1Action {
  type: "FINISH_STEP_1";
}
export interface BackAction {
  type: "BACK";
}

export interface AddMeasurementValueDefinitionAction {
  type: "ADD_MEASUREMENT_VALUE_DEFINITION";
}

export interface UpdateMeasurementValueDefinitionAction {
  type: "UPDATE_MEASUREMENT_VALUE_DEFINITION";
  mvd: MeasurementValueDefinition;
  index: number;
}

export interface RemoveMeasurementValueDefinitionAction {
  type: "REMOVE_MEASUREMENT_VALUE_DEFINITION";
  mvd: MeasurementValueDefinition;
  index: number;
}

export interface MoveMeasurementValueDefinitionAction {
  type: "MOVE_MEASUREMENT_VALUE_DEFINITION";
  index: number;
  direction: -1 | 1;
}

export interface SelecteMeasurementCategorizationAction {
  type: "SELECT_MEASUREMENT_CATEGORIZATION";
  mCat: MeasurementCategorization;
}

export interface SetProcessingAction {
  type: "SET_PROCESSING";
  isProcessing: boolean;
}

export interface SetErrorsAction {
  type: "SET_ERRORS";
  errorResponse: MeasurementPlanErrorResponse;
}

export interface ResetErrorsAction {
  type: "RESET_ERRORS";
}

export interface EnableEditAction {
  type: "ENABLE_EDIT";
  allowEdit: boolean;
}

export type MeasurementPlanAction =
  | ResetStateAction
  | BackAction
  | LoadInitialStateAction
  | SelectMeasurementUnitAction
  | SelectMeasurementTypeTemplateAction
  | SelectTypeOfMeasurementAction
  | SelectCreationModeAction
  | UpdateMeasurementPlanAction
  | SetProcessingAction
  | SetErrorsAction
  | ResetErrorsAction
  | FinishStep1Action
  | AddMeasurementValueDefinitionAction
  | MoveMeasurementValueDefinitionAction
  | UpdateMeasurementValueDefinitionAction
  | RemoveMeasurementValueDefinitionAction
  | SelecteMeasurementCategorizationAction
  | EnableEditAction;

/**
 * Reset the state of the store to the default state
 */
export function resetState(): void {
  MeasurementPlanDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Loads initial state of form
 * @export
 * @param assetId Asset id to assign the measurement
 * @param measurementPlan Measurement plan to show or edit. May be blank if mode is 'create'
 * @param mode Mode for measurement plan form (either for creating, editing, or just showing the plan
 * @param templateTypes Template types for measurement that can be instanciated to create a new one as copy of these objects.
 * @param availableMeasurementCategorizations List of categorizations for measurement values (Measurement Value Definitions) These can only be assigned to distribution measurements.
 * @param submitUrl The url to submit form data to.
 * @param submitMehtod The http method for form data submit.
 */
export function loadInitialState(
  assetId: number,
  measurementPlan: MeasurementPlan,
  mode: FormMode,
  templateTypes: MeasurementType[],
  availableMeasurementCategorizations: MeasurementCategorization[],
  submitUrl: string,
  submitMethod: "POST" | "PATCH",
): void {
  MeasurementPlanDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    assetId,
    measurementPlan,
    mode,
    templateTypes,
    availableMeasurementCategorizations,
    submitUrl,
    submitMethod,
  });
}

/************************************
 * Form Steps
 ***********************************/

/**
 * Remove a measurement plan
 * @param measurementPlanIndex Index of the maintenace plan within the asset
 */
export function finishStep1(): void {
  MeasurementPlanDispatcher.dispatch({ type: "FINISH_STEP_1" });
}

export function back() {
  MeasurementPlanDispatcher.dispatch({ type: "BACK" });
}

/**
 * Measurement Type Templates
 */

export function selectMeasurementTypeTemplate(template: MeasurementType) {
  MeasurementPlanDispatcher.dispatch({
    type: "SELECT_MEASUREMENT_TYPE_TEMPLATE",
    template,
  });
}

/**************************************
 * Measurement Categorization Selection
 *************************************/

export function selectMeasurementCategorization(
  mCat: MeasurementCategorization,
) {
  MeasurementPlanDispatcher.dispatch({
    type: "SELECT_MEASUREMENT_CATEGORIZATION",
    mCat,
  });
}

export function selectCreationMode(createBy: MeasurementPlanCreationMode) {
  MeasurementPlanDispatcher.dispatch({
    type: "SELECT_CREATION_MODE",
    createBy,
  });
}

export function selectTypeOfMeasurement(typeOfMeasurement: MeasurementTypes) {
  MeasurementPlanDispatcher.dispatch({
    type: "SELECT_TYPE_OF_MEASUREMENT",
    typeOfMeasurement,
  });
}

/************************************
 * Measurement Value Definitions
 ***********************************/

export function addMeasurementValueDefinition() {
  MeasurementPlanDispatcher.dispatch({
    type: "ADD_MEASUREMENT_VALUE_DEFINITION",
  });
}

export function updateMeasurementValueDefinition(
  updatedDefinition: MeasurementValueDefinition,
  index: number,
) {
  MeasurementPlanDispatcher.dispatch({
    type: "UPDATE_MEASUREMENT_VALUE_DEFINITION",
    index,
    mvd: updatedDefinition,
  });
}

export function removeMeasurementValueDefinition(
  mvd: MeasurementValueDefinition,
  index: number,
) {
  MeasurementPlanDispatcher.dispatch({
    type: "REMOVE_MEASUREMENT_VALUE_DEFINITION",
    index,
    mvd,
  });
}
export function moveMeasurementValueDefinition(
  index: number,
  direction: -1 | 1,
) {
  MeasurementPlanDispatcher.dispatch({
    type: "MOVE_MEASUREMENT_VALUE_DEFINITION",
    index,
    direction,
  });
}

/**
 * Update an existing measurement plan
 * @param measurementPlanIndex Index of the maintenace plan
 * @param measurementPlan The new measurement plan
 */
export function updateMeasurementPlan(measurementPlan: MeasurementPlan): void {
  MeasurementPlanDispatcher.dispatch({
    type: "UPDATE_MEASUREMENT_PLAN",
    measurementPlan,
  });
}

/************************************
 * Utility Actions
 ***********************************/

export function selectMeasurementUnit(unit: string): void {
  MeasurementPlanDispatcher.dispatch({
    type: "SELECT_MEASUREMENT_UNIT",
    unit,
  });
}
/**
 * Sets wether the form is processing a request or not
 * @param isProcessing True if form is processing a request
 */
export function setProcessing(isProcessing: boolean): void {
  MeasurementPlanDispatcher.dispatch({
    type: "SET_PROCESSING",
    isProcessing,
  });
}

/**
 * Applies errors from error response to model to show it in the form.
 * @param errorResponse
 */
export function setErrors(errorResponse: MeasurementPlanErrorResponse): void {
  MeasurementPlanDispatcher.dispatch({
    type: "SET_ERRORS",
    errorResponse,
  });
}

/**
 * Resets errors shown in form
 */
export function resetErrors(): void {
  MeasurementPlanDispatcher.dispatch({
    type: "RESET_ERRORS",
  });
}

export function enableEdit(allowEdit: boolean): void {
  MeasurementPlanDispatcher.dispatch({
    type: "ENABLE_EDIT",
    allowEdit,
  });
}

import { Grid, Skeleton, Typography } from "@mui/material";
import { defaultTo, isEmpty, isNil, map, merge, set } from "lodash";
import { SensorGroupWidgetConfigSerialized } from "../../widgets/sensor_group_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { SensorGroupWidgetProps } from "./sensor_group_widget.types";
import { SensorValueWidget } from "./sensor_value_widget";
import { WidgetBox } from "./widget_box";

import { loadSensors, SensorJSONAPIAttributes } from "../../json_api/sensor";
import { SialogicQueryClient } from "../common/sialogic_query_client";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";

function processLoadedSensors(sensors: SensorJSONAPIAttributes[]) {
  return sensors.sort((a, b) => {
    if (isNil(a.name)) {
      return -1;
    }
    return a.name?.localeCompare(b.name) ?? -1;
  });
}

export const SensorGroupWidget: React.FC<SensorGroupWidgetProps> = (props) => {
  const [sensorIds, setSensorIds] = useState(
    props.sensorIds ? props.sensorIds : null,
  );
  const [sensors, setSensors] = useState<SensorJSONAPIAttributes[] | null>(
    null,
  );
  const [displaySensors, setDisplaySensors] = useState<
    SensorJSONAPIAttributes[] | null
  >(null);
  const [loading, setLoading] = useState(
    props.sensorIds ? false : !isNil(props.assetId),
  );

  useEffect(() => {
    if (sensorIds === null && isNil(sensors)) {
      if (props.assetId) {
        SialogicQueryClient.fetchQuery({
          queryKey: [
            "sensors",
            {
              asset_id: props.assetId,
              include: ["sensor_type"],
              page: { size: 100 },
            },
          ],
          queryFn: async ({ queryKey }) => {
            setLoading(true);
            return loadSensors(null, [props.assetId], ["sensor_type"]);
          },
        })
          .then((sensors) => {
            setSensors(sensors);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [sensorIds, sensors, props.assetId, processLoadedSensors]);

  // adapt the displaySensors whenever the sensors change
  useEffect(() => {
    if (!sensors) {
      setDisplaySensors(null);
      return;
    }
    setDisplaySensors(processLoadedSensors(sensors));
    setSensorIds(sensors.map((sensor) => sensor.id));
  }, [sensors]);

  // adjust sensorIds if the props change
  useEffect(() => {
    if (props.sensorIds !== sensorIds) {
      setSensorIds(props.sensorIds);
    }
  }, [props.sensorIds]);

  return (
    <WidgetBox {...props}>
      <Grid container spacing={4} alignItems="stretch">
        {loading ? (
          <Grid item xs={12}>
            <Skeleton height={300} />
          </Grid>
        ) : displaySensors ? (
          map(displaySensors, (sensor, index) => (
            <Grid item key={index} xs={12} sm>
              <SensorValueWidget
                {...props}
                widgetId={null}
                className={null}
                sensorId={sensor.id}
                useValueRange
                title={null}
                encloseInIBox
                minWidthPx={200}
                sensor={sensor}
                assetId={props.assetId}
                dashboardSettings={{
                  ...props.dashboardSettings,
                  widget_id: null,
                  dashboards_widget_id: null,
                }}
              />
            </Grid>
          ))
        ) : (
          map(sensorIds, (sensorId, index) => (
            <Grid item key={index} xs={12} sm>
              <SensorValueWidget
                {...props}
                widgetId={null}
                className={null}
                title={null}
                encloseInIBox
                minWidthPx={150}
                sensorId={sensorId as number}
                assetId={props.assetId}
                dashboardSettings={{
                  ...props.dashboardSettings,
                  widget_id: null,
                  dashboards_widget_id: null,
                }}
              />
            </Grid>
          ))
        )}
        {isEmpty(sensorIds) && isEmpty(sensors) ? (
          <Grid item xs={12} padding={2} textAlign="center">
            <Typography variant="body1">
              {I18n.t("frontend.widgets.sensor_group_widget.no_sensors")}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: SensorGroupWidgetConfigSerialized,
): SensorGroupWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    sensorIds: config.sensor_ids,
    hideValue: defaultTo(config.hide_value, false),
    vertical: defaultTo(config.vertical, false),
    updateEnabled: !defaultTo(config.disable_update, false),
    useValueRange: defaultTo(config.use_value_range, false),
  } as SensorGroupWidgetProps);
}

export const SensorGroupWidgetDefinition: SialogicWidgetDefinition<
  typeof SensorGroupWidget,
  typeof serializedConfigToProps
> = {
  Component: SensorGroupWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import { includes, isNil, noop, orderBy } from "lodash";
import moment from "moment";
import * as React from "react";
import { EventNotification } from "../../../models/event_notification";
import "../../../utils/flux_container_adapter";
import { loadDataFromUrl, sendData } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import {
  createDesktopNotification,
  requestDesktopNotifications,
} from "../desktop_notifications";
import { NotificationDropdown } from "../views/notification_dropdown";

import { notifyAirbrake } from "../../../utils/airbrake_error_handler";
import { AppContext } from "../../common/app_context/app_context_provider";

import { CancelledError, useQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "../../common/error_boundary";
import { SialogicQueryClient } from "../../common/sialogic_query_client";
import { api_asset_events_confirm_read_path } from "../../../routes";

/**
 * Add number of unnoticed events to document title
 */
function updateDocumentTitle(
  notificationCount: number,
  originalTitle: string,
): void {
  if (isNil(notificationCount) || notificationCount == 0) {
    if (!isNil(originalTitle)) {
      document.title = originalTitle;
    }
  } else if (notificationCount < 100) {
    document.title = `(${notificationCount}) ${originalTitle}`;
  } else {
    document.title = `(99+) ${originalTitle}`;
  }
}

interface NotificationDropdownContainer {
  loadNotifications?: boolean;
}

interface NotificationsResponse {
  newNotifications: EventNotification[];
  previousNotifications: EventNotification[];
}
export const NotificationsDropdownContainer: React.FC<
  NotificationDropdownContainer
> = ({ loadNotifications = true }) => {
  const [newNotifications, setNewNotifications] = React.useState<
    EventNotification[]
  >([]);
  const [previousNotifications, setPreviousNotifications] = React.useState<
    EventNotification[]
  >([]);
  const [originalTitle, setOriginalTitle] = React.useState<string>(
    () => document.title,
  );

  const context = React.useContext(AppContext);

  const [notificationSound, setNotificationSound] =
    React.useState<HTMLAudioElement>();
  React.useEffect(() => {
    void requestDesktopNotifications().catch((error) => {
      logger.warn("This browser does not support desktop notification");
    });
    setNotificationSound(new Audio(context.notificationSound));
  }, []);

  React.useEffect(() => {
    const channel = App.cable.subscriptions.create(
      {
        channel: "UserNotificationChannel",
      },
      {
        connected: noop,
        disconnected: noop,
        received: (notification: EventNotification) => {
          try {
            onAddNewNotification(notification);
          } catch (e) {
            void notifyAirbrake(e as Error);
          }
        },
      },
    );
    return () => {
      channel.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    updateDocumentTitle(newNotifications?.length, originalTitle);
  }, [newNotifications]);

  const { data: loadedNotifications, isLoading: notificationsLoading } =
    useQuery({
      queryKey: ["assetEventNotifications", context.currentAssetId],
      enabled: loadNotifications,
      queryFn: () =>
        loadDataFromUrl<NotificationsResponse>(
          url_helper.assetEventsNotificationsPath(context.currentAssetId),
        ).catch((error) => {
          if (error instanceof CancelledError) {
            // do nothing
            return;
          }
          logger.logError(error as Error);
        }),
      staleTime: 30000,
    });

  React.useEffect(() => {
    return () => {
      if (notificationsLoading) {
        void SialogicQueryClient.cancelQueries({
          queryKey: ["assetEventNotifications", context.currentAssetId],
          exact: true,
        });
      }
    };

    return () => {
      document.title = originalTitle;
    };
  }, []);
  React.useEffect(() => {
    if (loadedNotifications) {
      setNewNotifications(loadedNotifications.newNotifications);
      setPreviousNotifications(loadedNotifications.previousNotifications);
    }
  }, [loadedNotifications]);
  React.useEffect(() => {
    updateDocumentTitle(newNotifications?.length, originalTitle);
  }, [newNotifications]);
  /**
   * Add a new notification to the list, send desktop notification and play sound
   * @param notification
   */
  const onAddNewNotification = React.useCallback(
    (notification: EventNotification) => {
      try {
        if (
          context?.user?.notificationSoundEnabled &&
          !isNil(notificationSound)
        ) {
          void notificationSound.play();
        }
        createDesktopNotification(notification);
        const newNewNotifications = orderBy(
          [notification, ...newNotifications],
          ["from"],
          ["desc"],
        );

        setNewNotifications(newNewNotifications);
      } catch (e) {
        // we do not want that to throw an error
        void notifyAirbrake(e as Error);
      }
    },
    [newNotifications, notificationSound],
  );

  /**
   * Send mark as noticed request to server and dispatch mark as noticed action if request succeeds
   * @param eventIds The event notifications to mark as noticed
   */
  const onMarkNotificationsAsNoticed = React.useCallback(
    (eventIds: number[]) => {
      void sendData(api_asset_events_confirm_read_path(), {
        asset_event_ids: eventIds,
        noticed_at: moment().toISOString(),
      })
        .then(() => {
          const newPreviousNotifications: EventNotification[] = [];
          const notifications: EventNotification[] = [];

          newNotifications.forEach((notification) => {
            if (includes(eventIds, notification.event_id)) {
              notification.read_by_user = true;
              // move noticed event notifications to the list of previous notifications
              newPreviousNotifications.push(notification);
            } else {
              // event is unnoticed keep it in new notification list
              notifications.push(notification);
            }
          });

          // keep events that were already in previous events list
          newPreviousNotifications.forEach((notification) =>
            notifications.push(notification),
          );

          setNewNotifications(orderBy(notifications, ["from"], ["desc"]));
          setPreviousNotifications(
            orderBy(newPreviousNotifications, ["from"], ["desc"]),
          );
        })
        .catch((error) => {
          logger.logError(error as Error);
          void toast.error(
            I18n.t("frontend.event_dropdown.could_not_mark_as_noticed"),
          );
        });
    },
    [newNotifications, originalTitle],
  );

  return (
    <ErrorBoundary>
      <NotificationDropdown
        newNotifications={newNotifications}
        previousNotifications={previousNotifications}
        onMarkNotificationsAsNoticed={onMarkNotificationsAsNoticed}
      />
    </ErrorBoundary>
  );
};

import {
  MaintenanceJob,
  MaintenanceJobGroup,
  User,
  CreateMaintenanceErrorResponse,
} from "./model";
import MaintenanceDispatcher from "./maintenance_dispatcher";
import { MaintenancePlanFilterSelection } from "../../maintenance_plan_form/data/models";
import moment, { Moment } from "moment";
import { TreeItem } from "../../../models/tree_item";

export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";
  rootAssetId: string | number;

  assetTree: TreeItem;
  maintenanceJobGroups: MaintenanceJobGroup[];
  users: User[];
}

export interface ApplyFilterStateAction {
  type: "APPLY_FILTER";
  filter: MaintenancePlanFilterSelection;
}
export interface UpdateDoneAtAction {
  type: "UPDATE_DONE_AT";
  doneAt: Moment;
}

export interface UpdateNoteAction {
  type: "UPDATE_NOTE";
  note: string;
}

export interface UpdateMaintenanceJobAction {
  type: "UPDATE_MAINTENANCE_JOB";
  maintenanceJobGroupIndex: number;
  assetIndex: number;
  maintenanceIndex: number;
  maintenanceJob: MaintenanceJob;
}

export interface SetProcessingAction {
  type: "SET_PROCESSING";
  isProcessing: boolean;
}

export interface SetDefaultUserAction {
  type: "SET_DEFAULT_USER";
  user: User;
}

export interface ApplyDefaultUserAction {
  type: "APPLY_DEFAULT_USER";
}

export interface SetErrorsAction {
  type: "SET_ERRORS";
  errorResponse: CreateMaintenanceErrorResponse;
}

export interface ResetErrorsAction {
  type: "RESET_ERRORS";
}

export interface ToggleAllMaintenanceJobGroupsAction {
  type: "TOGGLE_ALL_MAINTENANCE_JOB_GROUPS";
  expanded: boolean;
}

export interface ToggleMaintenanceJobGroupAction {
  type: "TOGGLE_MAINTENANCE_JOB_GROUP";
  maintenanceJobGroupIndex: number;
  assetIndex: number;
  expanded: boolean;
}

export interface AddUserAction {
  type: "ADD_USER";
  name: string;
  user: User;
}

export type MaintenanceAction =
  | ResetStateAction
  | LoadInitialStateAction
  | ApplyFilterStateAction
  | UpdateDoneAtAction
  | UpdateNoteAction
  | UpdateMaintenanceJobAction
  | SetProcessingAction
  | SetDefaultUserAction
  | ApplyDefaultUserAction
  | ResetErrorsAction
  | SetErrorsAction
  | ToggleAllMaintenanceJobGroupsAction
  | ToggleMaintenanceJobGroupAction
  | AddUserAction;

/**
 * Reset the state of the store to the default state
 */
export function resetState(): void {
  MaintenanceDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Initialize state of the maintenance store
 * @param rootAssetId The id of the root asset
 * @param maintenanceJobGroups Maintenance jobs
 * @param users Users
 */
export function loadInitialState(
  rootAssetId: string | number,
  maintenanceJobGroups: MaintenanceJobGroup[],
  users: User[],
  assetTree: TreeItem,
): void {
  MaintenanceDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    rootAssetId,
    maintenanceJobGroups,
    users,
    assetTree,
  });
}

/**
 * Updates done at timestamp for maintenance
 * @param doneAt The date the maintenance was executed
 */
export function updateDoneAt(doneAt: Moment): void {
  MaintenanceDispatcher.dispatch({
    type: "UPDATE_DONE_AT",
    doneAt,
  });
}

/**
 * Updates note for maintenance
 * @param note A note for the maintenance
 */
export function updateNote(note: string): void {
  MaintenanceDispatcher.dispatch({
    type: "UPDATE_NOTE",
    note,
  });
}

/**
 * Updates a maintenance job
 * @param maintenanceJobGroupIndex Index of maintenance job group
 * @param maintenanceIndex Index of maintenance job with group
 * @param maintenanceJob The updated maintenance job
 */
export function updateMaintenanceJob(
  maintenanceJobGroupIndex: number,
  assetIndex: number,
  maintenanceIndex: number,
  maintenanceJob: MaintenanceJob,
): void {
  MaintenanceDispatcher.dispatch({
    type: "UPDATE_MAINTENANCE_JOB",
    maintenanceJobGroupIndex,
    assetIndex,
    maintenanceIndex,
    maintenanceJob,
  });
}

/**
 * Sets wether the form is processing a request or not
 * @param isProcessing True if form is processing a request
 */
export function setProcessing(isProcessing: boolean): void {
  MaintenanceDispatcher.dispatch({
    type: "SET_PROCESSING",
    isProcessing,
  });
}

/**
 * Sets the default user for maintenance jobs
 * @param user The user to set as default
 */
export function setDefaultUser(user: User): void {
  MaintenanceDispatcher.dispatch({
    type: "SET_DEFAULT_USER",
    user,
  });
}

/**
 * Applies the default user to all maintenance jobs were no user is configured
 */
export function applyDefaultUser(): void {
  MaintenanceDispatcher.dispatch({
    type: "APPLY_DEFAULT_USER",
  });
}

/**
 * Applies errors from error response to model to show it in the form.
 * @param errorResponse
 */
export function setErrors(errorResponse: CreateMaintenanceErrorResponse): void {
  MaintenanceDispatcher.dispatch({
    type: "SET_ERRORS",
    errorResponse,
  });
}

/**
 * Resets errors shown in form
 */
export function resetErrors(): void {
  MaintenanceDispatcher.dispatch({
    type: "RESET_ERRORS",
  });
}

/**
 * Collapses or expands all maintenance job groups
 * @param expanded Wether the forms should be expanded or collapsed
 */
export function toggleAllMaintenanceJobGroups(expanded: boolean): void {
  MaintenanceDispatcher.dispatch({
    type: "TOGGLE_ALL_MAINTENANCE_JOB_GROUPS",
    expanded,
  });
}

/**
 * Collapse a single maintenance job group
 * @param maintenanceJobGroupIndex Index of the maintenance job group
 * @param expanded Wether the form should be expanded or collapsed
 */
export function toggleMaintanceJobGroup(
  maintenanceJobGroupIndex: number,
  assetIndex: number,
  expanded: boolean,
): void {
  MaintenanceDispatcher.dispatch({
    type: "TOGGLE_MAINTENANCE_JOB_GROUP",
    maintenanceJobGroupIndex,
    assetIndex,
    expanded,
  });
}

/**
 * Collapse a single maintenance job group
 * @param maintenanceJobGroupIndex Index of the maintenance job group
 * @param expanded Wether the form should be expanded or collapsed
 */
export function addUser(name: string, user: User): void {
  MaintenanceDispatcher.dispatch({
    type: "ADD_USER",
    name,
    user,
  });
}

/**
 * Apply the given filter to the maintenance form to show / hide specific items
 * @param filter Description of filter
 */
export function applyFilter(filter: MaintenancePlanFilterSelection): void {
  MaintenanceDispatcher.dispatch({
    type: "APPLY_FILTER",
    filter,
  });
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/// <reference types="../definitions/index" />;

import { defaultTo, isNil, isObject, toString } from "lodash";
import { TranslatedStringObject } from "../models/event_notification";

export type TranslatedModel = Partial<Record<string, string>>;

// Be sure not to build infinite loops of fallbacks, it should end with a defined language
const TranslationFallback: Record<string, string> = {
  de: "en",
  en: "de",
  pl: "en",
};

/** Queries a translated property from given model.
 * Able to handle localized properties. E.g. title_de, title_en, title_fr and property translation object {de: 'Titel, en: 'Title', fr: 'Titre'}
 * Tries first the <prop>_<language_code> property. Falls back to default locale property <prop>_<default_locale>. Falls back to non localized property value.
 *
 * @export
 * @param {any} model
 * @param {string} prop
 * @param {string} [locale=I18n.locale]
 * @returns {string}
 */
export function getTranslatedProp<
  M = Record<string, string | TranslatedStringObject>,
>(model: M, prop: keyof M, locale: string = I18n.locale): string {
  if (isNil(model)) return null;
  // double check if locale is set
  locale ||= I18n.locale;

  const propValue = model[prop];
  const triedLocales: string[] = [];
  // handle translation object
  if (isObject(propValue)) {
    let translation;
    let queryLocale = locale;
    while (!isNil(queryLocale) && !triedLocales.includes(queryLocale)) {
      translation = (propValue as TranslatedStringObject)[queryLocale];
      triedLocales.push(queryLocale);
      // found a translation
      if (!isNil(translation)) return translation;

      // try fallback language
      queryLocale = TranslationFallback[queryLocale];
    }
    return translation ?? "";
  }

  // handle string property translation with fallback

  let translation;

  const stringProp = toString(prop);
  translation = (model as Record<string, string>)[
    localizedPropName(stringProp, locale)
  ];

  // try fallback language
  translation = defaultTo(
    translation,
    (model as Record<string, string>)[
      localizedPropName(stringProp, TranslationFallback[locale])
    ],
  );
  return defaultTo(translation, propValue) as string;
}

function localizedPropName(propName: string, locale: string): string {
  return `${toString(propName)}_${locale}`;
}

import { isNil } from "lodash";

import * as React from "react";
import {
  SvgAnimationWidget as SvgAnimationWidgetComponent,
  SvgAnimationWidgetDefinition,
} from "../components/widgets/svg_animation_widget";
import { jsonApiSingleResourceToFlatObject } from "../json_api/jsonapi_tools";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import {
  MappedSensor,
  SensorMapping,
} from "../models/svg_animation_widget_config";
import { SvgAnimationWidgetConfigSerialized } from "./svg_animation_widget.types";

/**
 * Javascript implementation of the SvgAnimationWidget.
 *
 * @class SvgAnimationWidget
 * @extends {Widget}
 */
export default class SvgAnimationWidget extends Widget<SvgAnimationWidgetConfigSerialized> {
  contentLink?: string;
  contentLinkTarget?: string;

  svgCode: string;
  svgUrl?: string;
  maxSvgElementWidth?: string | number;
  maxSvgElementHeight?: string | number;
  imageUrl?: string;
  imagePlaceholderSelector?: string;

  sensorsToMap?: MappedSensor[];
  sensorMappings?: SensorMapping[][];

  static getDomClassName(): string {
    return "svg-animation-widget";
  }

  static widgetClassName(): string {
    return "SvgAnimationWidget";
  }

  protected constructor(element: JQuery<HTMLElement>) {
    super(element);
    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = SvgAnimationWidgetDefinition.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <SvgAnimationWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    const config = element.data("config") as SvgAnimationWidgetConfigSerialized;
    this.config = config;
  }

  cleanup(): void {
    super.cleanup();
  }
}

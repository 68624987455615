/// <reference types="../definitions/index"/>
/* eslint @typescript-eslint/no-unsafe-assignment: "off" */
/* eslint @typescript-eslint/no-unsafe-member-access: "off" */
/* eslint @typescript-eslint/no-unsafe-call: "off" */
/* eslint @typescript-eslint/no-var-requires: "off" */

// emulate global once provided by Vite
window.global ||= window as any;

import "../utils/polyfills";
import "./init_jquery";
import "./init_jquery_plugins";

import { each, isNil } from "lodash";
import moment from "./moment";
import Popper from "popper.js";
import { SensorLoader } from "../json_api/sensor_loader";
import * as toasts from "../utils/toasts";
import "./leaflet";
import { bindScrollFunction } from "./scroll_down_show";

// load and initialize turbo
import "@hotwired/turbo-rails";

export function initAppGlobals() {
  try {
    const windowAny = window as any;
    const globalAny = global as any;

    window.moment = moment;

    if (!isNil(window.gon.locale)) {
      //init local very early
      moment.locale(window.gon.locale);
    }

    const properties = {
      moment: moment,

      toasts: toasts,
      Popper: Popper,

      SIALOGIC: {},
      SensorLoader: SensorLoader,
      // we have to provide options about not tracking websocket requests since it ruins action cable otherwise
      paceOptions: {
        ajax: {
          trackWebSockets: false,
        },
      },
    };
    each(properties, (value, key) => {
      windowAny[key] = value;
      if (windowAny !== globalAny) {
        globalAny[key] = value;
      }
    });

    bindScrollFunction();

    return properties;
  } catch (e) {
    console.log(e);
    return null;
  }
}

import {
  Box,
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Grid,
} from "@mui/material";
import * as React from "react";

type IBoxProps = React.HTMLAttributes<HTMLDivElement>;

export const IBox: React.FunctionComponent<CardProps> = (props) => {
  return (
    <Card {...props} sx={{ marginY: 1, paddingX: 2, paddingY: 2 }}>
      {props.children}
    </Card>
  );
};

type IBoxTitleProps = CardHeaderProps & {
  tools?: React.ReactNode;
};
export const IBoxTitle: React.FunctionComponent<IBoxTitleProps> = ({
  tools,
  ...props
}: IBoxTitleProps) => {
  return (
    <CardHeader
      {...props}
      title={
        <Grid container justifyContent="space-between">
          <Grid item xs>
            {props.children}
          </Grid>
          {tools ? (
            <Grid item xs="auto">
              {tools}
            </Grid>
          ) : null}
        </Grid>
      }
    />
  );
};

export const IBoxContent: React.FunctionComponent<CardContentProps> = (
  props: CardContentProps,
) => {
  return <CardContent {...props}>{props.children}</CardContent>;
};

export const IBoxFooter: React.FunctionComponent<IBoxProps> = ({
  className = "",
  ...props
}: IBoxProps) => {
  return (
    <Box {...props} className={`ibox-footer ${className}`}>
      {props.children}
    </Box>
  );
};

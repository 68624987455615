import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { defaultTo, isEmpty, isNil } from "lodash";
import { Duration } from "moment";
import * as React from "react";
import { CastedContextStateChange } from "../../models/context_state_machine";
import { State } from "../../models/state";
import { getStateColor } from "../../utils/colors";
import { getIconForName } from "../common/icon_for_name";

interface ContextStateChangeTimelineItemProps {
  stateChange: CastedContextStateChange;
  timeSinceLast?: Duration;
  state: State;
}

export const ContextStateChangeTimelineItem: React.FunctionComponent<
  ContextStateChangeTimelineItemProps
> = (props) => {
  const color = getStateColor(props.state);
  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ margin: "auto 0" }}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            {props.stateChange.time?.format("L LTS") || "---"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            {props.timeSinceLast
              ? `${I18n.t("frontend.duration")}: ${props.timeSinceLast.humanize()}`
              : null}
          </Typography>
        </Box>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          style={{ width: "4em", height: "4em", borderColor: color }}
        >
          <div
            className="m-auto text-center"
            style={{
              width: "100%",

              color: color,
            }}
          >
            {isNil(props.state?.image_url) ? (
              <Avatar
                style={{
                  width: "2em",
                  height: "2em",
                  background: "transparent",
                  color: color,
                }}
                className="m-auto"
              >
                {getIconForName(props.state.icon, "1x")}
              </Avatar>
            ) : (
              <Avatar src={props.state?.image_url} />
            )}
          </div>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Grid container spacing={2} className="my-auto">
          <Grid item xs={12}>
            <Tooltip
              title={
                <>
                  <Box>
                    {defaultTo(props.state.description, props.state.name)}
                  </Box>
                  <Box>
                    {props.timeSinceLast
                      ? `${I18n.t("frontend.duration")}: ${props.timeSinceLast.humanize()}`
                      : null}
                  </Box>
                </>
              }
            >
              <Typography variant="body2" component="span">
                {props.state.name}
              </Typography>
            </Tooltip>
          </Grid>

          {isEmpty(props.stateChange.code) ? null : (
            <Grid item xs>
              <Typography variant="caption">
                Code: {props.stateChange.code}
              </Typography>
            </Grid>
          )}
          {isEmpty(props.stateChange.reason) ? null : (
            <Grid item xs>
              <Typography variant="caption">
                Reason: {props.stateChange.reason}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

import { ExternalReferenceTargetModel } from "../models/external_reference_target";
import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";

export const EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE =
  "external_reference_targets";

export type ExternalReferenceTargetJsonApiAttributes =
  ExternalReferenceTargetModel;

export interface ExternalReferenceTargetJSONObject
  extends ExternalReferenceTargetModel,
    JSON.Object {}

export type ExternalReferenceTargetJSONAPIAttributes =
  JSONAPI.AttributesObject<ExternalReferenceTargetJSONObject>;

import { each, isEmpty, isNil, noop } from "lodash";
import moment, { Moment } from "moment";
import { State } from "../models/state";
import { StateContext } from "../models/state_context";
import ActionCable from "@rails/actioncable";

import ModelDataChannel, { EventSubscription } from "./model_data_channel";

export interface ContextStateMachineCableValue {
  action: string;
  context_state_machine_id: number;
  message: ContextStateMachineChangeMessage;
}

export interface ContextStateMachineChangeMessage {
  new_state_id: number;
  new_state_name: string;
  new_state_criticality: number;
  new_state_description: string;
  new_state_identifier: string;
  new_state_color?: string;
  new_state_icon?: string;
  state_context_id: number;
  state_context_identifier: string;
  state_context_name: string;
  stateful_item_id: number;
  stateful_item_type: string;
  time: string;
}

export interface ContextStateMachineEventSubscriber {
  handleContextStateMachineUpdate(
    contextStateMachineId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
  ): void;
}

interface ChannelSubscription extends ActionCable.ChannelNameWithParams {
  identifier?: string;
}

const WILDCARD_ATTRIBUTE_ID = -1;
export class ContextStateMachineChannel extends ModelDataChannel<
  ContextStateMachineEventSubscriber,
  ContextStateMachineCableValue
> {
  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "ContextStateMachineChannel",
      context_state_machine_id: modelId.toString(),
    };
  }

  protected handleDataMessage(
    data: ContextStateMachineCableValue,
    listeners: EventSubscription<ContextStateMachineEventSubscriber>[],
  ): void {
    if (isNil(listeners)) {
      return;
    }
    each(listeners, (listener) => {
      listener.f.handleContextStateMachineUpdate(
        data.context_state_machine_id,
        {
          id: data.message.state_context_id,
          name: data.message.state_context_name,
          identifier: data.message.state_context_identifier,
        },
        {
          id: data.message.new_state_id,
          name: data.message.new_state_name,
          criticality: data.message.new_state_criticality,
          description: data.message.new_state_description,
          color: data.message.new_state_color,
          icon: data.message.new_state_icon,
          identifier: data.message.new_state_identifier,
        },
        moment(data.message.time),
        data.message.stateful_item_id,
        data.message.stateful_item_type,
      );
    });
  }
}

export default ContextStateMachineChannel;

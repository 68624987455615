import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { SingleResourceDoc } from "jsonapi-typescript";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { translatedModelName } from "../../i18n/translation_helper";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { Asset } from "../../models/asset";
import { Sensor } from "../../models/sensor";
import {
  HttpError,
  loadDataFromUrl,
  sendData,
} from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { info } from "../../utils/toasts";
import {
  assetSensorsUrl,
  assignAssetSensorJsonApiPath,
  jsonApiSensorPath,
} from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { AssetsAutocomplete } from "../assets/assets_autocomplete";
import { AssignmentForm } from "../common/assignment_form";
import {
  AssetJSONAPIAttributesObject,
  AssetJsonApiAttributes,
} from "../../json_api/asset";

export interface SensorAssignAssetFormProps {
  ibox?: boolean;
  assets: AssetJSONAPIAttributesObject[];
  sensor?: Sensor;
  sensorId?: number;
  asset?: AssetJSONAPIAttributesObject;
  assetId?: number;
  // use only the tree of the current asset for searching assets
  assetTreeOnly?: boolean;

  onSubmitPressed?: (asset: Asset) => void;
  onAssignFinished?: (asset: Asset) => void;
  onAssignFailed?: (org: Asset, error?: Error) => void;
  onCancelPressed?: () => void;
}

interface SensorAssignAssetFormState {
  loading: boolean;
  sensor?: Sensor;
  selectedAsset: AssetJSONAPIAttributesObject;
  rootOnly: boolean;
  assetTreeOnly: boolean;
  contextAssetId: IDType;
}

export class SensorAssignAssetForm extends React.Component<
  SensorAssignAssetFormProps,
  SensorAssignAssetFormState
> {
  static defaultProps = {
    loading: false,
  };
  constructor(props: SensorAssignAssetFormProps) {
    super(props);
    this.state = {
      loading: false,
      sensor: props.sensor,
      selectedAsset: this.props.asset,
      rootOnly: true,
      assetTreeOnly: false,
      contextAssetId: defaultTo(this.props.asset?.id, this.props.assetId),
    };
  }

  componentDidMount(): void {
    if (isNil(this.state.sensor)) {
      this.setState({ loading: true });
      void loadDataFromUrl<SingleResourceDoc<string, SensorJSONAPIAttributes>>(
        jsonApiSensorPath({ sensorId: this.props.sensorId }, ["asset"]),
      )
        .then((sensorResponseDoc) => {
          const sensor = jsonApiSingleResourceToFlatObject(sensorResponseDoc);
          this.setState({ sensor: sensor });
        })
        .catch((e) => {
          void toasts.error(
            I18n.t(
              "frontend.sensors.sensor_assign_asset_form.error_loading_sensor",
            ),
            (e as Error).message,
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleSubmit(): void {
    if (this.props.onSubmitPressed) {
      this.props.onSubmitPressed(this.state.selectedAsset);
      return;
    }

    this.setState({ loading: true }, () => {
      void this.requestAssign();
    });
  }

  async requestAssign(): Promise<void> {
    try {
      const result = await sendData<any, { message: string }>(
        assignAssetSensorJsonApiPath(
          this.state.sensor.id,
          this.state.selectedAsset.id as number,
        ),
        {},
        "POST",
      );
      const res = await info(I18n.t("frontend.success"), result.message);
      redirectTo(assetSensorsUrl(this.state.selectedAsset.id as number));
    } catch (error) {
      logger.error(error);
      if (error instanceof HttpError) {
        void toasts.error(
          I18n.t(
            "frontend.sensors.sensor_assign_asset_form.error_assigning_asset",
          ),
          error.response,
        );
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCancel(): void {
    if (this.props.onCancelPressed) {
      this.props.onCancelPressed();
      return;
    }

    redirectTo("back");
  }

  render(): React.ReactNode {
    return (
      <AssignmentForm
        ibox={this.props.ibox}
        loading={this.state.loading}
        submitEnabled={!this.state.loading && !isNil(this.state.selectedAsset)}
        onSubmitClick={() => {
          this.handleSubmit();
        }}
        onCancelClick={() => {
          this.handleCancel();
        }}
        iboxTitle={I18n.t(
          "frontend.sensors.sensor_assign_asset_form.ibox_heading",
        )}
        heading={translatedModelName("sensor") + ": " + this.state.sensor?.name}
      >
        <AssetsAutocomplete
          variant="outlined"
          label={I18n.t(
            "frontend.sensors.sensor_assign_asset_form.select_asset",
          )}
          width={"100%"}
          assets={this.props.assets}
          contextAssetId={this.state.contextAssetId}
          assetTreeOnly={this.state.assetTreeOnly}
          asset={this.state.selectedAsset}
          rootsOnly={this.state.rootOnly}
          onSelect={(a) => this.setState({ selectedAsset: a })}
          inputId="assign-asset-autocomplete"
        />
        <FormGroup>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.rootOnly}
                  onChange={(e) => {
                    this.setState({
                      rootOnly: e.currentTarget.checked,
                      assetTreeOnly: false,
                    });
                  }}
                />
              }
              label={I18n.t(
                "frontend.sensors.sensor_assign_asset_form.root_only",
              )}
            />
            <FormHelperText>
              {I18n.t(
                "frontend.sensors.sensor_assign_asset_form.root_only_help",
              )}
            </FormHelperText>
          </FormControl>
        </FormGroup>
        {isNil(this.state.contextAssetId) ? null : (
          <FormGroup>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.assetTreeOnly}
                    onChange={(e) => {
                      this.setState({
                        assetTreeOnly: e.currentTarget.checked,
                        rootOnly: !e.currentTarget.checked,
                      });
                    }}
                  />
                }
                label={I18n.t(
                  "frontend.sensors.sensor_assign_asset_form.asset_tree_only",
                )}
              />
              <FormHelperText>
                {I18n.t(
                  "frontend.sensors.sensor_assign_asset_form.asset_tree_only_help",
                )}
              </FormHelperText>
            </FormControl>
          </FormGroup>
        )}
      </AssignmentForm>
    );
  }
}

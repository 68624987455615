import { Box, MenuItem, TextField } from "@mui/material";
import { isEmpty, map } from "lodash";
import * as React from "react";
export interface PageSizeSelectProperties {
  pageSize: number;

  availablePageSizes?: number[];
  onSelectPageSize: (newSize: number) => void;
}

export const DEFAULT_PAGE_SIZES = [5, 10, 20, 30, 40, 50];

export const PageSizeSelect: React.FunctionComponent<
  PageSizeSelectProperties
> = (props) => {
  const pageSizes = isEmpty(props.availablePageSizes)
    ? DEFAULT_PAGE_SIZES
    : props.availablePageSizes;
  return (
    <Box displayPrint="none">
      <TextField
        select
        size="small"
        value={props.pageSize}
        onChange={(event) => {
          props.onSelectPageSize(parseInt(event.target.value));
        }}
      >
        {map(pageSizes, (pageSize, index) => (
          <MenuItem key={index} value={pageSize}>
            {pageSize}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

import moment, { Moment } from "moment";
import { EventSubscription, ModelDataChannel } from "./model_data_channel";
import { SensorValueType, LocationValue } from "../models/sensor";
import { EventNotification } from "../models/event_notification";
import { each, isNil } from "lodash";
import { IDType } from "../utils/urls/url_utils";

export interface EventDataCableValue {
  action: string;
  asset_id: IDType;
  event_id: IDType;
  message: EventNotification;
}
export interface AssetEventSubscriber {
  handleNewEvent(
    event: EventNotification,
    time: Moment,
    assetId: IDType,
    eventId: IDType,
  ): void;
}

const WILDCARD_ATTRIBUTE_ID = -1;

export class AssetNotificationChannel extends ModelDataChannel<
  AssetEventSubscriber,
  EventDataCableValue
> {
  protected includeSubtreeInSubscription: boolean;
  constructor(includeSubtree = true) {
    super();
    this.includeSubtreeInSubscription = includeSubtree;
  }
  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "AssetNotificationChannel",
      asset_id: modelId,
      include_subtree: this.includeSubtreeInSubscription,
    };
  }

  public subscribe(modelId: number) {
    super.subscribe(modelId);
  }
  protected handleDataMessage(
    data: EventDataCableValue,
    listeners: EventSubscription<AssetEventSubscriber>[],
  ): void {
    if (isNil(listeners)) return;

    const time = moment(data.message.from);
    each(listeners, (listener) => {
      listener.f.handleNewEvent(
        data.message,
        time,
        data.asset_id,
        data.event_id || data.message.event_id,
      );
    });
  }
}

export default AssetNotificationChannel;

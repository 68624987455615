/// <reference types="../../../definitions/index" />;
import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import * as React from "react";
import { formatTimestampDuration } from "../../../utils/format_timestamp_duration";
import { redirectTo } from "../../../utils/redirection";
import { Icon } from "../../common/icon";
import * as component_updater from "../component_updater";

interface EventListEntryProps {
  /**
   * Name of the asset
   */
  assetName?: string;
  /**
   * Name of the event
   */
  name?: string;

  /**
   * Description of the event
   */
  description?: string;

  /**
   * Icon for the event
   */
  icon?: string;
  /**
   * Url to the event page
   */
  url?: string;
  /**
   * A timestamp in ISO format
   */
  timestamp?: string;
  /**
   * Wether the event should be highlighted as active
   */
  active?: boolean;
}

interface EventListEntryState {
  timestamp: string;
}

/** A timestamp possibly needs an update if it shows a time range that is less than a day. In this case the time in words needs to be updated as time passes.
 *
 *
 * @param {string} timestamp
 * @returns {boolean}
 */
function timestampNeedsUpdate(timestamp: string): boolean {
  const duration = moment.duration(
    moment().diff(timestamp, "seconds"),
    "second",
  );

  return duration.asDays() < 1;
}

/**
 * A list entry for an event
 */
export class EventListEntry extends React.Component<
  EventListEntryProps,
  EventListEntryState
> {
  static defaultProps: EventListEntryProps = {
    icon: "calendar",
  };

  constructor(props: EventListEntryProps) {
    super(props);

    this.state = {
      timestamp: formatTimestampDuration(this.props.timestamp),
    };
  }

  componentDidUpdate(prevProps: EventListEntryProps): void {
    if (prevProps.timestamp !== this.props.timestamp) {
      this.setState({
        timestamp: formatTimestampDuration(this.props.timestamp),
      });
    }
  }

  componentDidMount(): void {
    if (!timestampNeedsUpdate(this.props.timestamp)) {
      return;
    }
    component_updater.registerUpdate(this);
  }

  componentWillUnmount() {
    component_updater.unregisterUpdate(this);
  }

  handleClick(event: React.MouseEvent<HTMLDivElement>) {
    if (isEmpty(this.props.url)) {
      return;
    }
    event.preventDefault();
    redirectTo(this.props.url);
  }

  update() {
    this.setState({
      timestamp: formatTimestampDuration(this.props.timestamp),
    });

    // clear interval if timestamp does not need an update
    if (!timestampNeedsUpdate(this.props.timestamp)) {
      component_updater.unregisterUpdate(this);
    }
  }

  render(): React.ReactNode {
    return (
      <MenuItem
        className={this.props.active ? "active" : ""}
        title={this.props.description}
        href={this.props.url}
      >
        <ListItemAvatar>
          <Avatar color="primary">
            <Icon
              size="1x"
              icon={isEmpty(this.props.icon) ? "calendar" : this.props.icon}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          onClick={(event) => this.handleClick(event)}
          sx={{ lineHeight: "1em" }}
        >
          <strong className="event-list-entry-heading">
            {this.props.name}
          </strong>
          <br />
          <span className="event-list-entry-description">
            {this.props.assetName}
          </span>
          <br />
          <small className="text-muted">{this.state.timestamp}</small>
        </ListItemText>
      </MenuItem>
    );
  }
}

/**
 * A divider element(horizontal line) for a dropdown list.
 */
export function EventListDivider(): JSX.Element {
  return <Divider />;
}

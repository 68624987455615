/// <reference types="../../definitions/index" />;
import { Checkbox, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";

export interface Role {
  name: string;
  description?: string;
  organization_id: number;
  organization_name?: string;
  owner_organization_name?: string;
  owner_organization_id?: number;
  checked?: boolean;
  _destroy?: boolean;
}

interface RoleListItemProps {
  role: Role;
  onChange(checked: boolean): void;
}

export function RoleListItem(props: RoleListItemProps): React.ReactElement {
  const labelId = `role-${props.role.name}-${props.role.organization_id}`;

  return (
    <ListItem
      role="listitem"
      button
      onClick={() => props.onChange(!props.role.checked)}
    >
      <ListItemIcon>
        <Checkbox
          checked={props.role.checked ?? false}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={props.role.name}
        secondary={
          isEmpty(props.role.owner_organization_name) ||
          props.role.owner_organization_id === props.role.organization_id
            ? props.role.organization_name
            : `${props.role.organization_name} (${props.role.owner_organization_name})`
        }
        title={props.role.description}
      />
    </ListItem>
  );
}

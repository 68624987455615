import { AttachFile, Cancel, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty, isNil, toString } from "lodash";
import * as React from "react";
import {
  FileAttachmentJSONObject,
  FileAttachmentUpdatableAttributes as FileAttachmentUpdatableAttributes,
} from "../../json_api/file_attachment";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { notifyAirbrake } from "../../utils/airbrake_error_handler";
import { ASFileUpload, UploadDelegate } from "../../utils/file_uploader";
import { sendData } from "../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../utils/jsonapi_form_tools";
import {
  apiFileAttachmentPath,
  apiFileAttachmentsPath,
} from "../../utils/urls/file_attachment_urls";
import { IDType } from "../../utils/urls/url_utils";
import { Dropzone } from "../common/file_dropzone/dropzone";
import { LoadingWrapper } from "../common/loading_wrapper";

interface FileAttachmentUploadProps {
  itemType: string;
  itemId: IDType;
  group?: string;

  fileAttachment?: FileAttachmentJSONObject;

  onUploadFileAttachmentCreated(file: FileAttachmentJSONObject): void;
  onUploadFileAttachmentUpdated?(file: FileAttachmentJSONObject): void;
  requestCancel?: () => void;
}

export const FileAttachmentUpload: React.FunctionComponent<
  FileAttachmentUploadProps
> = (props) => {
  const [uploadProgressPercent, setUploadProgressPercent] =
    React.useState<number>(null);
  const [fileAttachment, setFileAttachment] = React.useState<
    FileAttachmentJSONObject & { file: string }
  >(() =>
    props.fileAttachment
      ? { ...props.fileAttachment, file: null }
      : {
          title: "",
          description: "",
          key: null,
          file: null,
          group: props.group,
          item_id: props.itemId,
          item_type: props.itemType,
        },
  );
  const [lastUploadedFile, setLastUploadedFile] = React.useState<File>(null);
  const [fileAttachmentLoading, setFileAttachmentLoading] =
    React.useState(false);

  const commitFileAttachment = React.useCallback(async () => {
    try {
      setFileAttachmentLoading(true);
      const { submitData, mode } = buildJsonApiSubmitData<
        FileAttachmentJSONObject & { file: string }
      >(fileAttachment, "file_attachments", FileAttachmentUpdatableAttributes);
      let fileAttachmentResponse;
      if (mode === "update") {
        fileAttachmentResponse = await sendData<
          JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>,
          JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>
        >(
          apiFileAttachmentPath(fileAttachment.id),
          submitData,
          "PATCH",
          "application/vnd.api+json",
        );
      } else if (mode === "create") {
        fileAttachmentResponse = await sendData<
          JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>,
          JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>
        >(
          apiFileAttachmentsPath(),
          submitData,
          "POST",
          "application/vnd.api+json",
        );
      }

      const createdFileAttachmentObject = jsonApiSingleResourceToFlatObject(
        fileAttachmentResponse,
      );

      if (mode === "create") {
        props.onUploadFileAttachmentCreated?.(createdFileAttachmentObject);
      } else {
        props.onUploadFileAttachmentUpdated?.(createdFileAttachmentObject);
      }
    } catch (error) {
      void notifyAirbrake(error as Error);

      void toasts.error(I18n.t("frontend.error"));
    } finally {
      setFileAttachmentLoading(false);
    }
  }, [fileAttachment]);

  const handleFileUpload = React.useCallback(
    async (file: File, oldFile: File) => {
      if (isNil(file)) {
        return;
      }

      if (oldFile?.name === file.name) {
        return;
      }

      try {
        const uploadDelegate: UploadDelegate = {
          onStart: (upload) => {
            setUploadProgressPercent(0);
          },
          onProgress: (progress, upload) => {
            setUploadProgressPercent(progress * 100);
          },
          onComplete: (upload) => {
            setUploadProgressPercent(null);
          },
        };
        const upload = new ASFileUpload(file, uploadDelegate);
        // set the currently running upload
        setLastUploadedFile(file);

        const blob = await upload.upload();
        const newFa = { ...fileAttachment, file: blob.signed_id };

        if (isEmpty(newFa.title)) {
          newFa.title = blob.filename;
        }
        setFileAttachment(newFa);
      } catch (error) {
        void notifyAirbrake(error as Error);

        setLastUploadedFile(oldFile);
        void toasts.error(I18n.t("frontend.error_uploading_file"));
      } finally {
        setUploadProgressPercent(null);
      }
    },
    [fileAttachment],
  );

  return (
    <Grid container>
      <Card>
        <CardHeader
          avatar={<AttachFile color="primary" />}
          title={I18n.t(
            "frontend.file_attachments.file_attachment_upload.heading",
          )}
        />
        <CardContent>
          <LoadingWrapper loading={fileAttachmentLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={fileAttachment.title}
                  label={I18n.t(
                    "activerecord.attributes.file_attachment.title",
                  )}
                  onChange={(event) =>
                    setFileAttachment({
                      ...fileAttachment,
                      title: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  minRows={3}
                  fullWidth
                  value={fileAttachment.description}
                  label={I18n.t(
                    "activerecord.attributes.file_attachment.description",
                  )}
                  onChange={(event) =>
                    setFileAttachment({
                      ...fileAttachment,
                      description: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={toString(fileAttachment.key)}
                  label={I18n.t("activerecord.attributes.file_attachment.key")}
                  onChange={(event) =>
                    setFileAttachment({
                      ...fileAttachment,
                      key: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Dropzone
                  fileSelectionChanged={(files) =>
                    void handleFileUpload(files[0], lastUploadedFile)
                  }
                  initialFiles={
                    isNil(fileAttachment.url) ? [] : [fileAttachment.url]
                  }
                  acceptedFileTypes={[
                    "image/png",
                    "image/jpeg",
                    "image/svg+xml",
                    "application/pdf",
                  ]}
                  filesLimit={1}
                  dropzoneText={I18n.t(
                    "frontend.file_attachments.file_attachment_upload.click_or_drag_to_upload",
                  )}
                  uploading={uploadProgressPercent !== null}
                  uploadProgressPercent={uploadProgressPercent}
                />
              </Grid>
            </Grid>
          </LoadingWrapper>
        </CardContent>
        <CardActions>
          {isNil(props.requestCancel) ? null : (
            <Button
              size="small"
              startIcon={<Cancel />}
              onClick={() => props?.requestCancel()}
            >
              {I18n.t("frontend.cancel")}
            </Button>
          )}
          <Button
            size="small"
            color="primary"
            startIcon={<Save />}
            disabled={isNil(fileAttachment.file)}
            onClick={() => void commitFileAttachment()}
          >
            {I18n.t("frontend.save")}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { getIntegerAttribute } from "../../utils/data_attributes";
import { AppRoot } from "../common/app_root";

import { LocationForm } from "./location_form";
import { Box, Paper } from "@mui/material";

const locationEditorRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeLocationEditor(
  selector: JQuery = $('[data-toggle="location-editor"]'),
): void {
  selector.each((_i, element) => {
    const jqElem = $(element);
    const localizableItemId = getIntegerAttribute(
      jqElem,
      "data-localizable-item-id",
    );
    const localizableItemType = jqElem.data("localizable-item-type") as string;

    const locationId = getIntegerAttribute(jqElem, "data-location-id");
    const enableLocationUpdate =
      jqElem.data("enable-location-update") !== "false";

    const root = createRoot(element);
    locationEditorRoots.push(root);
    root.render(
      <AppRoot>
        <Paper>
          <Box p={2}>
            <LocationForm
              allowSave
              locationId={locationId}
              localizableId={localizableItemId}
              localizableType={localizableItemType as "asset" | "organization"}
            />
          </Box>
        </Paper>
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all ellements
 */
export function destroyLocationEditor(): void {
  locationEditorRoots.forEach((root) => {
    root.unmount();
  });

  locationEditorRoots.length = 0;
}

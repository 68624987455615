import { Close, Fullscreen, FullscreenExit } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogProps,
} from "@mui/material";
import { add } from "lodash";
import * as React from "react";

interface SialogicDialogProps
  extends Pick<
      DialogProps,
      | "fullScreen"
      | "maxWidth"
      | "open"
      | "disableEscapeKeyDown"
      | "scroll"
      | "fullWidth"
      | "style"
      | "className"
    >,
    React.PropsWithChildren {
  title?: string | React.ReactNode;
  onClose: () => void;
  allowFullScreen?: boolean;
  buttons?: React.ReactNode | React.ReactNode[];
  additionTitleActions?: React.ReactNode | React.ReactNode[];
}

export const SialogicDialog: React.FC<SialogicDialogProps> = ({
  title,
  open,
  onClose,
  disableEscapeKeyDown = false,
  children,
  scroll,
  allowFullScreen = true,
  fullScreen: propFullscreen = false,
  fullWidth,
  buttons,
  style,
  maxWidth = "lg",
  additionTitleActions,
  className,
}) => {
  const [fullScreen, setFullScreen] = React.useState(propFullscreen);

  React.useEffect(() => {
    setFullScreen(propFullscreen);
  }, [propFullscreen]);

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      scroll={scroll}
      onClose={onClose}
      fullWidth={fullWidth}
      style={style}
      className={className}
    >
      <DialogTitle>
        <Box marginRight={allowFullScreen ? "80px" : "40px"} minHeight="1em">
          {title}
        </Box>

        <Box displayPrint="none" position="absolute" right={8} top={8}>
          {additionTitleActions}
          {allowFullScreen ? (
            <IconButton
              aria-label="fullscreen"
              onClick={() => {
                setFullScreen(!fullScreen);
              }}
            >
              {fullScreen ? (
                <FullscreenExit fontSize="inherit" />
              ) : (
                <Fullscreen fontSize="inherit" />
              )}
            </IconButton>
          ) : null}
          <IconButton aria-label="close" onClick={() => onClose()}>
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box padding={2}>{children}</Box>
      </DialogContent>
      {buttons ? <DialogActions>{buttons}</DialogActions> : null}
    </Dialog>
  );
};

import { isNil, toInteger } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { ResourcePermission } from "../../models/resource_permission";
import { AppRoot } from "../common/app_root";
import { EventPatternForm } from "./event_pattern_form";

const eventPatternFormRoots: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeEventPatternForm(): void {
  $('[data-toggle="event-pattern-form"]').each((index, element) => {
    const eventPatternId = $(element).data("event-pattern-id") as string;
    const sensorId = $(element).data("sensor-id") as string;
    const assetId = $(element).data("asset-id") as string;
    const readonly = $(element).data("readonly") as boolean;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    eventPatternFormRoots.push(root);
    root.render(
      <AppRoot>
        <EventPatternForm
          eventPatternId={
            isNil(eventPatternId) ? null : toInteger(eventPatternId)
          }
          permissions={permissions}
          readonly={readonly}
          sensorId={toInteger(sensorId)}
          assetId={toInteger(assetId)}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyEventPatternForm(): void {
  eventPatternFormRoots.forEach((root) => {
    root.unmount();
  });
  eventPatternFormRoots.length = 0;
}

import { defaultTo, find, isEmpty, isNil } from "lodash";

import {
  SensorValueRange,
  ValueRangeStatus,
} from "../models/sensor_value_range";

export function getStatusLabel(statusName: string, fallback?: string): string {
  return defaultTo(statusName, fallback);
}

export function getStatusClassName(statusName: string): string {
  return "";
}

/** Finds the first matching value range for the given value.
 *
 *
 * @export
 * @param {number} value Value for which the value range should be determined
 * @param {SensorValueRange[]} valueRanges Array of value ranges. Order is maintained.
 * @return {*}  {SensorValueRange} First value range of the valueRanges that matches the given value. If no value range matches, undefined is returned.
 */
export function getValueRangeForValue(
  value: number,
  valueRanges: SensorValueRange[],
): SensorValueRange {
  if (isNil(value) || isNaN(value) || isEmpty(valueRanges)) return null;

  return find(valueRanges, (range) => {
    // normal closed ranges
    if (
      !isNil(range.min) &&
      !isNil(range.max) &&
      value >= range.min &&
      value < range.max
    ) {
      return true;
    }
    // lower open ranges
    if (isNil(range.min) && value < range.max) {
      return true;
    }

    // upper open ranges
    if (isNil(range.max) && value >= range.min) {
      return true;
    }

    return false;
  });
}

const statusMap: Record<ValueRangeStatus, string> = {
  critical: "danger",
  fatal: "danger",
  normal: "success",
  optimal: "info",
};
export function getStatusClassForValueRange(status: ValueRangeStatus): string {
  return statusMap[status];
}

import { Box, Fab, Paper, Typography } from "@mui/material";
import * as React from "react";
import { EventPatternJSONObject } from "../../json_api/event_pattern";
import { IDType } from "../../utils/urls/url_utils";

import { Add, Delete, KeyboardArrowLeft } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { AppContext } from "../../components/common/app_context/app_context_provider";
import { FixedBottomArea } from "../../components/common/fixed_bottom_area";
import { FloatingButtons } from "../../components/common/floating_buttons";
import { useDeleteEventPattern } from "../../components/event_patterns/event_pattern_data";
import { MqttUserList } from "../../components/mqtt_users/mqtt_user_table";
import { ResourcePermission } from "../../models/resource_permission";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { SialogicDialog } from "../../components/common/sialogic_dialog";
import { MqttUserForm } from "../../components/mqtt_users/mqtt_user_form";
import { HttpError } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";

interface MqttUsersEditPageProps {
  id: IDType;
  permissions?: ResourcePermission;
}

export const MqttUsersEditPage: React.FunctionComponent<
  MqttUsersEditPageProps
> = ({ id, permissions }) => {
  const [editMqttUser, setMqttUser] = React.useState<MqttUserJSONObject>();

  const confirm = useConfirm();
  const appCtx = React.useContext(AppContext);

  const {
    mutateAsync: deleteEventPattern,
    isPending: deletePending,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteEventPattern();

  React.useEffect(() => {
    if (deleteSuccess) {
      void success(
        I18n.t("frontend.event_patterns.index.delete_success"),
        I18n.t("frontend.event_patterns.index.delete_success"),
      );
    }
  }, [deleteSuccess, deleteError]);

  React.useEffect(() => {
    if (deleteError) {
      void error(
        I18n.t("frontend.event_patterns.index.delete_error"),
        deleteError.message,
      );
    }
  }, [deleteError]);

  return (
    <Paper>
      <Box padding={2}>
        <MqttUserForm
          mqttUserId={id}
          editMode={permissions.update ? "edit" : "show"}
          onSaved={() => {}}
        />
      </Box>

      <FixedBottomArea>
        {permissions?.create && (
          <FloatingButtons
            showScrollToTopBtn
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              redirectTo("back");
            }}
            submitBtnIcon={<Add />}
            submitBtnColor="primary"
            onSubmit={
              permissions?.create
                ? () => {
                    setMqttUser(null);
                  }
                : null
            }
            saveTitle={I18n.t("frontend.mqtt_users.add_new_mqtt_user")}
          >
            {permissions.destroy && (
              <Fab
                color="error"
                onClick={() => {
                  void confirm({
                    description: I18n.t(
                      "frontend.event_patterns.index.delete_event_pattern_confirm",
                    ),
                  }).then(() =>
                    deleteEventPattern(id)
                      .then(() => {
                        success(I18n.t("frontend.mqtt_users.delete_success"));
                        redirectTo("back");
                      })
                      .catch((err) => {
                        if (err instanceof HttpError) {
                          const rsp = JSON.parse(err.response);
                          error(
                            rsp.error ||
                              rsp.message ||
                              I18n.t("frontend.error  "),
                          );
                        }
                        logger.error(err);
                      }),
                  );
                }}
              >
                <Delete />
              </Fab>
            )}
          </FloatingButtons>
        )}
      </FixedBottomArea>
    </Paper>
  );
};

import { Organization } from "./organization";

export const OrganizationRelationTypeCustomerValue = "customer";
export const OrganizationRelationTypeContractorValue = "contractor";
export type OrganizationRelationTypeCustomer = "customer";
export type OrganizationRelationTypeContractor = "contractor";
export type OrganizationRelationType =
  | OrganizationRelationTypeCustomer
  | OrganizationRelationTypeContractor;
export const OrganizationRelationTypes = [
  OrganizationRelationTypeCustomerValue,
  OrganizationRelationTypeContractorValue,
];
export interface OrganizationRelation {
  id: number;

  ext_ref?: string;
  ext_ref_type?: string;
  parent_id?: number;
  parent?: Organization;
  child_id?: number;
  child?: Organization;
  rel_type: OrganizationRelationType;
}

/// <reference types="./../definitions/index" />;
/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.8
 *
 */

// global inspinia dependencies

import "./init_jquery";
import "./init_jquery_plugins";
import { notifyAirbrake } from "../utils/airbrake_error_handler";
import { debounce } from "lodash";

/**
 * Check if browser support HTML5 local storage
 */
function localStorageSupport(): boolean {
  return "localStorage" in window && window["localStorage"] !== null;
}

// For demo purpose - animation css script
function animationHover(
  element: HTMLHtmlElement | JQuery,
  animation: string,
): void {
  const jqElement = $(element);
  jqElement.hover(
    function () {
      jqElement.addClass("animated " + animation);
    },
    function () {
      //wait for animation to finish before removing classes
      window.setTimeout(function () {
        jqElement.removeClass("animated " + animation);
      }, 2000);
    },
  );
}

function SmoothlyMenu() {
  if (!$("body").hasClass("mini-navbar") || $("body").hasClass("body-small")) {
    // Hide menu in order to smoothly turn on when maximize menu
    $("#side-menu").hide();
    // For smoothly turn on menu
    setTimeout(function () {
      $("#side-menu").fadeIn(400);
    }, 200);
  } else if ($("body").hasClass("fixed-sidebar")) {
    $("#side-menu").hide();
    setTimeout(function () {
      $("#side-menu").fadeIn(400);
    }, 100);
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    $("#side-menu").removeAttr("style");
  }
}

// // Dragable panels
// export function WinMove() {
//     var element = "[class*=col]";
//     var handle = ".ibox-title";
//     var connect = "[class*=col]";
//     $(element).sortable({
//         handle: handle,
//         connectWith: connect,
//         tolerance: 'pointer',
//         forcePlaceholderSize: true,
//         opacity: 0.8
//     }).disableSelection();
// }

/**
 * Initializes inspinia elements
 */
export function initInspinia(): void {
  // bind UI elements on page load

  try {
    // Add body-small class if window less than 768px
    if ($(window).width() < 769) {
      $("body").addClass("body-small");
    } else {
      $("body").removeClass("body-small");
    }

    // MetisMenu
    $("#side-menu").metisMenu();

    // Collapse ibox function
    $(".collapse-link").on("click", function () {
      const ibox = $(this).closest("div.ibox");
      const button = $(this).find("i");
      const content = ibox.children(".ibox-content");
      content.slideToggle(200);
      button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
      ibox.toggleClass("").toggleClass("border-bottom");
      setTimeout(function () {
        ibox.resize();
        ibox.find("[id^=map-]").resize();
      }, 50);
    });

    // Close ibox function
    $(".close-link").on("click", function (e: JQuery.Event) {
      e.preventDefault();
      const content = $(this).closest("div.ibox");
      content.remove();
    });

    // Fullscreen ibox function
    $(".fullscreen-link").on("click", function (e: JQuery.Event) {
      e.preventDefault();
      const ibox = $(this).closest("div.ibox");
      const button = $(this).find("i");
      $("body").toggleClass("fullscreen-ibox-mode");
      button.toggleClass("fa-expand").toggleClass("fa-compress");
      ibox.toggleClass("fullscreen");
      setTimeout(function () {
        $(window).trigger("resize");
      }, 100);
    });

    // Close menu in canvas mode
    $(".close-canvas-menu").on("click", function (e: JQuery.Event) {
      e.preventDefault();
      $("body").toggleClass("mini-navbar");
      SmoothlyMenu();
    });

    // Run menu of canvas
    $("body.canvas-menu .sidebar-collapse").slimScroll({
      height: "100%",
      railOpacity: 0.9,
    });

    // Open close right sidebar
    $(".right-sidebar-toggle").on("click", function (e: JQuery.Event) {
      e.preventDefault();
      $("#right-sidebar").toggleClass("sidebar-open");
    });

    // Initialize slimscroll for right sidebar
    $(".sidebar-container").slimScroll({
      height: "100%",
      railOpacity: 0.4,
      wheelStep: 10,
    });

    // Fixed Sidebar
    if ($("body").hasClass("fixed-sidebar")) {
      $(".sidebar-collapse").slimScroll({
        height: "100%",
        railOpacity: 0.9,
      });
    }

    // Open close small chat
    $(".open-small-chat").on("click", function (e: JQuery.Event) {
      e.preventDefault();
      $(this).children().toggleClass("fa-comments").toggleClass("fa-remove");
      $(".small-chat-box").toggleClass("active");
    });

    // Initialize slimscroll for small chat
    $(".small-chat-box .content").slimScroll({
      height: "234px",
      railOpacity: 0.4,
    });

    // Small todo handler
    $(".check-link").on("click", function () {
      const button = $(this).find("i");
      const label = $(this).next("span");
      button.toggleClass("fa-check-square").toggleClass("fa-square-o");
      label.toggleClass("todo-completed");
      return false;
    });

    // Minimalize menu
    $(".navbar-minimalize").on("click", function (event: JQuery.Event) {
      event.preventDefault();
      $("body").toggleClass("mini-navbar");
      SmoothlyMenu();
    });

    // Tooltips demo
    $(".tooltip-demo").tooltip({
      selector: "[data-toggle=tooltip]",
      container: "body",
    });

    // Full height of sidebar
    function fix_height() {
      const heightWithoutNavbar = $("body > #wrapper").height() - 61;
      $(".sidebar-panel").css("min-height", heightWithoutNavbar + "px");

      const navbarHeigh = $("nav.navbar-default").height();
      const wrapperHeigh = $("#page-wrapper").height();

      if (navbarHeigh > wrapperHeigh) {
        $("#page-wrapper").css("min-height", navbarHeigh + "px");
      }

      if (navbarHeigh < wrapperHeigh) {
        $("#page-wrapper").css("min-height", $(window).height() + "px");
      }

      if ($("body").hasClass("fixed-nav")) {
        if (navbarHeigh > wrapperHeigh) {
          $("#page-wrapper").css("min-height", navbarHeigh - 60 + "px");
        } else {
          $("#page-wrapper").css("min-height", $(window).height() - 60 + "px");
        }
      }
    }

    fix_height();

    // Move right sidebar top after scroll
    $(window).scroll(function () {
      if ($(window).scrollTop() > 0 && !$("body").hasClass("fixed-nav")) {
        $("#right-sidebar").addClass("sidebar-top");
      } else {
        $("#right-sidebar").removeClass("sidebar-top");
      }
    });

    const handleLoadScrollResize = function () {
      // Full height of sidebar
      setTimeout(function () {
        if (!$("body").hasClass("body-small")) {
          fix_height();
        }
      });
    };

    $(window).on("load resize scroll", handleLoadScrollResize);

    $("[data-toggle=popover]").popover();

    // Add slimscroll to element
    $(".full-height-scroll").slimScroll({
      height: "100%",
    });

    const handleResize = debounce(function () {
      if ($(window).width() < 769) {
        $("body").addClass("body-small");
      } else {
        $("body").removeClass("body-small");
      }
    }, 100);
    window.removeEventListener("resize", handleResize);
    // Minimalize menu when screen is less than 768px
    window.addEventListener("resize", handleResize);

    handleResize();

    if (localStorageSupport()) {
      const collapse = localStorage.getItem("collapse_menu");
      const fixedsidebar = localStorage.getItem("fixedsidebar");
      const fixednavbar = localStorage.getItem("fixednavbar");
      const boxedlayout = localStorage.getItem("boxedlayout");
      const fixedfooter = localStorage.getItem("fixedfooter");

      const body = $("body");

      if (fixedsidebar == "on") {
        body.addClass("fixed-sidebar");
        $(".sidebar-collapse").slimScroll({
          height: "100%",
          railOpacity: 0.9,
        });
      }

      if (collapse == "on") {
        if (body.hasClass("fixed-sidebar")) {
          if (!body.hasClass("body-small")) {
            body.addClass("mini-navbar");
          }
        } else {
          if (!body.hasClass("body-small")) {
            body.addClass("mini-navbar");
          }
        }
      }

      if (fixednavbar == "on") {
        $(".navbar-static-top")
          .removeClass("navbar-static-top")
          .addClass("navbar-fixed-top");
        body.addClass("fixed-nav");
      }

      if (boxedlayout == "on") {
        body.addClass("boxed-layout");
      }

      if (fixedfooter == "on") {
        $(".footer").addClass("fixed");
      }
    }
  } catch (error) {
    notifyAirbrake(error);
  }
}

export function destroyInspinia() {
  // destroy slimscrolls before turbolinks caching

  $(".sidebar-collapse").slimScroll({ destroy: true });

  // Local Storage functions
  // Set proper body class and plugins based on user configuration
}

import { defaultTo, isNil, toString } from "lodash";

export const ASSET_TEMPLATE_REPLACEMENT_VARIABLES = new Set([
  "%ASSET_NAME%",
  "%ASSET_NAME_DE%",
  "%ASSET_NAME_EN%",
  "%ASSET_SHORT_NAME_DE%",
  "%ASSET_SHORT_NAME_EN%",
  "%ASSET_SHORT_NAME%",
  "%ASSET_INDEX%",
  "%ASSET_NUMBER%",
  "%ASSET_NUMBER_INC%",
  "%ASSET_FOR_TEMPLATE_INDEX%",
  "%ASSET_FOR_TEMPLATE_NUMBER%",
  "%ASSET_FOR_TEMPLATE_NUMBER_INC%",
  "%ASSET_KEY%",
  "%PARENT_ASSET_KEY%",
  "%PARENT_ASSET_NAME%",
  "%PARENT_ASSET_SHORT_NAME%",
]);

export function replacementData(
  assetName: string,
  assetShortName: string,
  assetKey: string,
  index: any,
  assetIndexByTemplate: any,
  parentAssetKey?: string,
  parentAssetName?: string,
  parentAssetShortName?: string,
) {
  return {
    "%ASSET_NAME%": assetName,
    "%ASSET_NAME_DE%": assetName,
    "%ASSET_NAME_EN%": assetName,
    "%ASSET_SHORT_NAME_DE%": assetShortName,
    "%ASSET_SHORT_NAME_EN%": assetShortName,
    "%ASSET_SHORT_NAME%": assetShortName,
    "%ASSET_INDEX%": toString(defaultTo(index, "")),
    "%ASSET_NUMBER%": isNil(index) ? "" : toString((index || 0) + 1),
    "%ASSET_NUMBER_INC%": isNil(index) ? "" : toString((index || 0) + 2),
    // as the template items are instanciated by template
    "%ASSET_FOR_TEMPLATE_INDEX%": toString(defaultTo(assetIndexByTemplate, "")),
    "%ASSET_FOR_TEMPLATE_NUMBER%": isNil(assetIndexByTemplate)
      ? ""
      : toString((assetIndexByTemplate || 0) + 1),
    "%ASSET_FOR_TEMPLATE_NUMBER_INC%": isNil(assetIndexByTemplate)
      ? ""
      : toString((assetIndexByTemplate || 0) + 2),
    "%ASSET_KEY%": assetKey,
    "%PARENT_ASSET_KEY%": toString(parentAssetKey),
    "%PARENT_ASSET_NAME%": toString(parentAssetName),
    "%PARENT_ASSET_SHORT_NAME%": toString(parentAssetShortName),
  };
}

import React from "react";
import PropTypes from "prop-types";
import StartOnDate from "./OnDate";

import translateLabel from "../../utils/translateLabel";
import { Grid } from "@mui/material";

const Start = ({ id, start: { onDate }, handleChange, translations }) => (
  <StartOnDate
    id={id}
    onDate={onDate}
    handleChange={handleChange}
    translations={translations}
    label={translateLabel(translations, "start.label")}
  />
);

Start.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.shape({
    onDate: PropTypes.object.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default Start;

import { first, isNil, isNumber, merge } from "lodash";
import * as React from "react";

import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorValueType } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { AssetMaintenanceInfoWidgetConfigSerialized } from "../../widgets/asset_maintenance_info_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PercentageBar } from "../common/percentage_bar";
import {
  AssetMaintenanceInfoWidgetProps,
  AssetMaintenanceInfoWidgetState,
} from "./asset_maintenance_info_widget.types";
import { WidgetBox } from "./widget_box";
import { Chip, Grid, Typography } from "@mui/material";
import { Moment } from "moment";
import { preventExtensions } from "core-js/core/object";

/** Widget encapsulating a sensor value display that can be updated programmatically
 * requires a set of spans as child elements to set the values
 * <span class="value"></span><span class="unit"></span>
 * ans <small|span class="timestamp">
 *
 * reads initial values from data attributes if provided:
 * data-sensor-id: sensor id
 * data-attribute-key-id: attribute key id to subscribe
 * data-unit: unit string
 * data-value: value
 * data-time: timestamp in ISO 8601 format
 * data-disable-update: disables the data update
 * @class SensorValuePanel
 * @extends {Widget}
 */
export default class AssetMaintenanceInfoWidget
  extends React.Component<
    AssetMaintenanceInfoWidgetProps,
    AssetMaintenanceInfoWidgetState
  >
  implements SensorEventSubscriber
{
  barRef: React.Ref<HTMLDivElement>;

  static serializedConfigToProps(
    config: AssetMaintenanceInfoWidgetConfigSerialized,
  ): AssetMaintenanceInfoWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      assetId: config.asset_id,
      assetName: config.asset_name,
      sensorValue: config.sensor_value,
      sensorValueId: config.sensor_id,
      sensorValueUnit: config.sensor_value_unit,
      sensorValueMax: config.sensor_value_max,
      sensorValueMin: config.sensor_value_min,
      status: config.status,
      maintenancePlanName: config.maintenance_plan_name,
      sensorStatus: config.sensor_status,
      currentMaintenanceUrgencyLevel: config.current_maintenance_urgency_level,
    } as AssetMaintenanceInfoWidgetProps);
  }
  constructor(props: AssetMaintenanceInfoWidgetProps) {
    super(props);
    this.barRef = React.createRef();
    this.state = {
      sensorStatus: props.sensorStatus,
      sensorValue: props.sensorValue,
      status: props.status ? [...props.status] : [],
    };
  }

  render() {
    return (
      <WidgetBox
        {...this.props}
        cardAvatar={
          this.props.currentMaintenanceUrgencyLevel ? (
            <Chip
              label={this.props.currentMaintenanceUrgencyLevel}
              size="small"
            />
          ) : null
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>{this.state.sensor?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{this.props.maintenancePlanName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <PercentageBar
              value={this.state.sensorValue}
              max={this.props.sensorValueMax}
              min={this.props.sensorValueMin}
              unit={this.props.sensorValueUnit}
              timestamp={getTimeString(
                this.props.timeScopeName,
                this.state.timestamp,
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {this.props.status?.map((status, index) => (
              <PercentageBar
                key={index}
                value={status.current_value}
                min={status.start_value}
                max={status.critical_value}
                status={
                  status.urgency_level == "maintenance_due"
                    ? "critical"
                    : "normal"
                }
                unit={status.unit}
              />
            ))}
          </Grid>
        </Grid>
      </WidgetBox>
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {}

  componentDidMount(): void {
    SensorLoader.getInstance()
      .getSensors([this.props.sensorValueId])
      .then((s) => {
        this.setState({ sensor: first(s) });
      })
      .catch((e) => {
        logger.error(e);
      });
    WidgetController.getInstance().sensorDataChannel.addEventListener(
      this,
      this.props.sensorValueId,
    );
  }

  componentWillUnmount(): void {
    WidgetController.getInstance().sensorDataChannel.removeEventListener(
      this,
      this.props.sensorValueId,
    );
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    if (!isNumber(value) && !isNil(value)) {
      return;
    }

    this.updateValue(attributeKeyId, sensorId, value, unit, time);
  }

  updateValue(
    attributeKeyId: number,
    sensorId: number,
    value: number,
    unit: string,
    time: Moment,
  ): void {
    if (!this.props.updateEnabled) {
      return;
    }
    if (sensorId === this.props.sensorValueId) {
      if (isNil(value)) {
        this.setSensorValue(0);
      } else {
        this.setSensorValue(value);
      }
    }

    const statusValueIndex = this.props.status?.findIndex(
      (s) => s.sensor_id == sensorId,
    );
    if (!isNil(statusValueIndex) && statusValueIndex != -1) {
      if (!isNil(value)) {
        this.setState((prev) => {
          const newStatus = [...prev.status];
          newStatus[statusValueIndex].current_value = value;
          return { ...prev, status: newStatus };
        });
      }
    }
  }

  setSensorValue(value: number) {
    this.setState({ sensorValue: value });
  }
}

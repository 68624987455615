import * as React from "react";
import { WidgetBox } from "../widgets/widget_box";
import { Typography } from "@mui/material";
import { map } from "lodash";
import { WidgetConfigSerialized } from "../../widgets/widget.types";
import { DashboardWidget } from "../../models/dashboard_widget";

interface DashboardWidgetWithErrorsProps {
  errors: string[];
  widgetProps: any;

  config: DashboardWidget;
}
export const DashboardWidgetWithErrors: React.FunctionComponent<
  DashboardWidgetWithErrorsProps
> = (props) => {
  return (
    <WidgetBox {...props.widgetProps}>
      <Typography variant="h5">
        {I18n.t("frontend.dashboard_widget.heading_error_preparing")}
      </Typography>
      {map(props.config.errors, (e, index) => (
        <Typography p={2} variant="body1" key={index}>
          {e}
        </Typography>
      ))}
    </WidgetBox>
  );
};

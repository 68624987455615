import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { AssetTemplate } from "../models/asset_template";
import { AssetTypeJSONObject } from "./asset_type";

export const ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE = "asset_templates";
export interface AssetTemplateJSONObject extends AssetTemplate, JSON.Object {
  asset_type?: AssetTypeJSONObject;
  template_descendants?: AssetTemplateJSONObject[];
  subtree?: AssetTemplateJSONObject[];
  parent?: AssetTemplateJSONObject;
  children?: AssetTemplateJSONObject[];
}

export type AssetTemplateJsonApiIncludes =
  | "asset_type"
  | "parent"
  | "subtree"
  | "children"
  | "template_descendants";

export type AssetTemplateJSONAPIAttributes =
  JSONAPI.AttributesObject<AssetTemplateJSONObject>;

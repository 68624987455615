import { defaultTo, each, isEmpty, isNil, isNumber, map } from "lodash";
import { convertToUnit, unitDisplayString } from "../utils/unit_conversion";
import { getValueString } from "../utils/value_format";
import { AssetFeatureType } from "./map.types";

export function getFeaturePopupContent(feature: AssetFeatureType): string {
  let popup = "";
  const htmlComponents = [];
  if (feature.properties) {
    htmlComponents.push(
      `<div class="row"><div class="col"><h4><a href="${feature.properties.link}">${feature.properties.name}</a><br><small>${feature.properties.asset_type}</small></h4></div></div>`,
    );

    if (feature.properties.description) {
      htmlComponents.push(
        `<div class="row"><div class="col"><p>${feature.properties.description}</p></div></div>`,
      );
    }

    if (feature.properties.address) {
      const address = feature.properties.address;
      htmlComponents.push(
        `<div class="row"><div class="col"><p>${address.street}<br>${address.city}</p></div></div>`,
      );
    }

    if (!isEmpty(feature.properties.sensors)) {
      htmlComponents.push(
        `<div class="row border-top"><div class="col"><h5>${I18n.t(
          "frontend.widgets.asset_map_widget.popup.sensor_values",
        )}</h5></div></div>`,
      );
      each(feature.properties.sensors, (value, attributeKey) => {
        const valueString: string = isNumber(value.value)
          ? getValueString(
              convertToUnit(value.value, value.unit, value.display_unit),
            )
          : defaultTo(value.value as string, "---");
        htmlComponents.push(
          `<div class="row border-bottom"><div class="col-6">${
            value.sensor_name
          }</div><div class="col-6 text-right">${valueString}<span class="unit ml-1">${unitDisplayString(
            defaultTo(value.display_unit, value.unit),
          )}</span><br/><small class="timestamp">${
            isNil(value.timestamp) ? "" : moment(value.timestamp).format("L LT")
          }</small></div></div>`,
        );
      });
    }

    if (!isEmpty(feature.properties.states)) {
      htmlComponents.push(
        `<div class="row"><div class="col"><h5>${I18n.t(
          "frontend.widgets.asset_map_widget.popup.states",
        )}</h5></div></div>`,
      );
      htmlComponents.push(
        map(
          feature.properties.states,
          (value, stateIdentifier) =>
            `<div class="row"><div class="col-6">${value.context_name}</div><div class="col-6">${value.name}</div></div>`,
        ).join(""),
      );
    }
  }
  popup = htmlComponents.join("");
  return popup;
}

export function addFeaturePopup(
  feature: AssetFeatureType,
  layer: L.Layer,
): void {
  layer.bindPopup(getFeaturePopupContent(feature), {
    minWidth: 200,
  });
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import * as React from "react";

import {
  EventPatternErrors,
  EventPatternJSONAPIAttributes,
  EventPatternJSONObject,
} from "../../json_api/event_pattern";

import { isNil } from "lodash";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { error, success } from "../../utils/toasts";
import { LoadingWrapper } from "../common/loading_wrapper";
import { EventPatternTypeSelect } from "./event_pattern_type_select";
import { SensorEventPatternForm } from "./sensor_event_pattern_form";

import { Cancel, Check, Delete, KeyboardArrowLeft } from "@mui/icons-material";
import { DocWithErrors } from "jsonapi-typescript";
import { useConfirm } from "material-ui-confirm";
import {
  extractErrorsFromJsonApi,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import { EventPatternType } from "../../models/event_pattern";
import { ResourcePermission } from "../../models/resource_permission";
import { HttpError } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { useLoadSensor } from "../sensors/sensor_data";
import {
  createEventPatternQuery,
  updateEventPatternQuery,
  useDeleteEventPattern,
  useLoadEventPattern,
} from "./event_pattern_data";
import { SensorDataTransmissionEventPatternForm } from "./sensor_data_transmission_event_pattern_form";

interface EventPatternFormProps {
  eventPatternId: IDType;
  eventPatternType?: EventPatternType;
  permissions?: ResourcePermission;
  sensorId: IDType;
  assetId: IDType;
  readonly?: boolean;
  buttonMode?: "card" | "bottom";
  onChangeEventPattern?: (pattern: EventPatternJSONObject) => void;
  onSave?: (pattern: EventPatternJSONObject) => void;
  onCancel?: () => void;
  onDelete?: (deletedEventPattern: EventPatternJSONObject) => void;
}

export const EventPatternForm: React.FunctionComponent<
  EventPatternFormProps
> = ({ buttonMode = "bottom", ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(props.readonly);
  const [sensor, setSensor] = React.useState<SensorJSONAPIAttributes>(null);
  const [errorString, setErrorString] = React.useState<string>(null);
  const [eventPatternErrors, setEventPatternErrors] =
    React.useState<EventPatternErrors>({});
  const [eventPattern, setEventPattern] =
    React.useState<EventPatternJSONAPIAttributes>(null);

  const confirm = useConfirm();

  const {
    data: loadedPattern,
    isLoading: patternLoading,
    error: loadingError,
  } = useLoadEventPattern({
    variables: {
      id: props.eventPatternId,
      includes: [
        "asset",
        "sensor",
        "asset_event_type",
        "state",
        "context_state_machine",
      ],
    },

    enabled: !isNil(props.eventPatternId),
  });

  const {
    data: loadedSensor,
    isLoading: sensorIsLoading,
    error: sensorLoadError,
  } = useLoadSensor({
    variables: { id: props.sensorId },
    enabled: isNil(props.eventPatternId) && !isNil(props.sensorId),
  });
  React.useEffect(() => {
    if (loadedPattern) {
      setEventPattern(loadedPattern);
    }
  }, [loadedPattern]);

  React.useEffect(() => {
    if (loadingError) {
      void error(
        I18n.t("base.error"),
        I18n.t("frontend.event_patterns.form.error_loading_event_pattern"),
      );
      setErrorString(
        I18n.t("frontend.event_patterns.form.error_loading_event_pattern"),
      );
    }
  }, [loadingError]);

  React.useEffect(() => {
    if (!isNil(loadedSensor)) {
      setSensor(loadedSensor);
      // set default values for event pattern
      setEventPattern({
        pattern_type: "EventPatterns::SensorEventPattern",
        comparator: "inside range",
        asset_id: loadedSensor.asset_id,
        sensor_id: loadedSensor.id as number,
        sensor,
      });
    }
  }, [loadedSensor]);

  React.useEffect(() => {
    if (sensorLoadError) {
      void error(
        I18n.t("base.error"),
        I18n.t("frontend.event_patterns.form.error_loading_sensor"),
      );
    }
  }, [sensorLoadError]);

  const {
    mutateAsync: createEventPattern,
    isPending: createPending,
    error: createError,
  } = createEventPatternQuery();

  const { mutateAsync: deleteEventPattern, isPending: deletePending } =
    useDeleteEventPattern();
  const {
    mutateAsync: updateEventPattern,
    isPending: updatePending,
    error: updateError,
  } = updateEventPatternQuery();

  const submitEventPattern = React.useCallback(
    (eventPattern: EventPatternJSONObject) => {
      setLoading(true);
      const mode = isNil(eventPattern.id) ? "create" : "update";
      (mode == "create"
        ? createEventPattern(eventPattern)
        : updateEventPattern(eventPattern)
      ).then((eventPattern) => {
        void success(
          mode == "create"
            ? I18n.t("base.successfully_created")
            : I18n.t("base.successfully_updated"),
        );
        if (props.onSave) {
          props.onSave(eventPattern);
        } else {
          redirectTo();
        }
      });
    },
    [props.onSave],
  );

  React.useEffect(() => {
    if (createError) {
      toasts.error(createError.message);
      setEventPatternErrors(
        extractErrorsFromJsonApi(
          (createError as HttpError).request.responseJSON as DocWithErrors,
        ),
      );
    }
  }, [createError]);

  React.useEffect(() => {
    if (updateError) {
      toasts.error(updateError.message);
      setEventPatternErrors(
        extractErrorsFromJsonApi(
          (updateError as HttpError).request.responseJSON as DocWithErrors,
        ),
      );
    }
  }, [updateError]);

  React.useEffect(() => {
    if (props.onChangeEventPattern) {
      props.onChangeEventPattern(eventPattern);
    }
  }, [eventPattern]);

  React.useEffect(() => {
    setLoading(patternLoading || sensorIsLoading);
  }, [patternLoading, sensorIsLoading, updatePending, createPending]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("frontend.event_patterns.form.header", {
              sensor: sensor?.name,
            })}
          />

          <CardContent>
            <LoadingWrapper loading={loading}>
              {isNil(eventPattern) ? (
                loadingError ? (
                  <Typography>{loadingError.message}</Typography>
                ) : null
              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} container>
                    <EventPatternTypeSelect
                      readonly={readOnly || !isNil(eventPattern.id)}
                      type={eventPattern.pattern_type}
                      error={modelPropertyError(
                        eventPatternErrors,
                        "pattern_type",
                      )}
                      onTypeSelect={(t) => {
                        const ep = { ...eventPattern, pattern_type: t };

                        setEventPattern(ep);
                      }}
                    />
                  </Grid>

                  {eventPattern.pattern_type ==
                  "EventPatterns::SensorEventPattern" ? (
                    <SensorEventPatternForm
                      readonly={readOnly}
                      eventPattern={eventPattern}
                      errors={eventPatternErrors}
                      onPatternUpdate={(e) => setEventPattern(e)}
                    />
                  ) : null}
                  {eventPattern.pattern_type ==
                  "EventPatterns::SensorDataTransmissionEventPattern" ? (
                    <SensorDataTransmissionEventPatternForm
                      readonly={readOnly}
                      errors={eventPatternErrors}
                      eventPattern={eventPattern}
                      onPatternUpdate={(e) => setEventPattern(e)}
                    />
                  ) : null}
                </Grid>
              )}
            </LoadingWrapper>
          </CardContent>
          {buttonMode != "card" ? null : (
            <CardActions>
              {readOnly ? null : (
                <Button
                  startIcon={<Check />}
                  onClick={
                    readOnly ? null : () => submitEventPattern(eventPattern)
                  }
                ></Button>
              )}

              <Button
                startIcon={<Cancel />}
                onClick={
                  props.onCancel
                    ? props.onCancel
                    : () => {
                        redirectTo();
                      }
                }
              ></Button>
            </CardActions>
          )}
        </Card>
      </Grid>
      {buttonMode != "bottom" ? null : (
        <FixedBottomArea id="fixed-bottom-area">
          <FloatingButtons
            showScrollToTopBtn
            disableCancel={loading}
            disableSave={loading}
            cancelIcon={readOnly ? <KeyboardArrowLeft /> : <Cancel />}
            onSubmit={readOnly ? null : () => submitEventPattern(eventPattern)}
            onCancel={
              props.onCancel
                ? props.onCancel
                : () => {
                    redirectTo();
                  }
            }
          >
            {!isNil(eventPattern?.id) && props.permissions?.destroy && (
              <Fab
                size="medium"
                color="secondary"
                disabled={loading}
                onClick={() => {
                  void confirm()
                    .then(() => {
                      deleteEventPattern(eventPattern.id)
                        .then(() => {
                          void success(I18n.t("base.successfully_destroyed"));
                          if (props.onDelete) {
                            props.onDelete(eventPattern);
                          } else {
                            redirectTo();
                          }
                        })
                        .catch((e) => {
                          void error(I18n.t("base.error_destroying"));
                          logger.error(e);
                        });
                    })
                    .catch(() => {
                      //
                    });
                }}
              >
                <Delete />
              </Fab>
            )}
          </FloatingButtons>
        </FixedBottomArea>
      )}
    </Grid>
  );
};

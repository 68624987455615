import { IDType } from "../utils/urls/url_utils";
export type ProductModelModelFor = "asset" | "device";
export interface ProductModelAttributes {
  id?: IDType;

  name?: string;
  description?: string;
  model_for?: ProductModelModelFor;
  identifier?: string;
  manufacturer_id?: IDType;
  asset_type_id?: IDType;
}

export type ProductModel = ProductModelAttributes;
export const PRODUCT_MODEL_ATTACHMENT_GROUPS = [
  "general",
  "image",
  "manuals",
  "tech",
];

import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";

import { UserGroup } from "../models/user_group";

export interface UserGroupJSONObject extends UserGroup, JSON.Object {}

export type UserGroupJSONAPIAttributes =
  JSONAPI.AttributesObject<UserGroupJSONObject>;

export const USER_GROUP_JSONAPI_RESOURCE_TYPE = "user_groups";

import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { defaultTo, isEmpty } from "lodash";
import { Moment } from "moment";
import moment from "../../initializers/moment";
import * as React from "react";
import { MaterialUiDatePicker } from "../common/date_picker";

export interface CommentFilter {
  from?: Moment;
  to?: Moment;
  tags?: string[];
}
interface CommentFilterProps {
  availableTags: string[];
  filter: CommentFilter;
  onFilterChanged: (filter: CommentFilter) => void;
}

export const CommentFilterItems: React.FunctionComponent<CommentFilterProps> = (
  props,
) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md="auto">
        <MaterialUiDatePicker
          value={props.filter?.from ? moment(props.filter.from) : null}
          type="datetime"
          dateFormat="L LT"
          noValueLabel={"---"}
          label={I18n.t("frontend.from")}
          maxDate={props.filter.to ?? null}
          onChange={(date) => {
            const newFilter = {
              ...props.filter,
              from: date,
            };
            if (
              moment.isMoment(newFilter.to) &&
              newFilter.to.isValid() &&
              moment.isMoment(newFilter.from) &&
              newFilter.from.isValid() &&
              newFilter.to.isBefore(newFilter.from)
            ) {
              newFilter.to = newFilter.from;
            }
            props.onFilterChanged(newFilter);
          }}
        />
      </Grid>
      <Grid item xs={12} md="auto">
        <MaterialUiDatePicker
          value={props.filter?.to ? moment(props.filter.to) : null}
          type="datetime"
          dateFormat="L LT"
          label={I18n.t("frontend.to")}
          noValueLabel={"---"}
          minDate={props.filter.from ?? null}
          onChange={(date) => {
            const newFilter = {
              ...props.filter,
              to: date,
            };
            if (
              moment.isMoment(newFilter.from) &&
              newFilter.from.isValid() &&
              newFilter.from.isAfter(newFilter.to)
            ) {
              newFilter.from = newFilter.to;
            }
            props.onFilterChanged(newFilter);
          }}
        />
      </Grid>
      {isEmpty(props.availableTags) ? null : (
        <Grid item xs={12} md="auto">
          <Autocomplete
            fullWidth
            size="small"
            multiple
            noOptionsText={I18n.t("frontend.none")}
            options={props.availableTags}
            value={defaultTo(props.filter.tags, [])}
            onChange={(v, values) => {
              props.onFilterChanged({ ...props.filter, tags: values });
            }}
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  key={index}
                  size="small"
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(props) => (
              <TextField
                {...props}
                sx={{ minWidth: 300 }}
                {...props}
                label={I18n.t("activerecord.attributes.comment.tags")}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

/// <reference types="../../definitions/index" />;
import { isEmpty, isNil, isString, range, toNumber, toString } from "lodash";
import * as React from "react";

import { Check } from "@mui/icons-material";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import moment, { Moment } from "moment";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "./app_root";
interface RatingCheckboxProps {
  name: string;
  rating: number;
  selected?: boolean;
  editEnabled?: boolean;

  isInitialStatusRating?: boolean;
  indicateCurrent?: boolean;
}

function ratingText(props: RatingCheckboxProps) {
  if (props.isInitialStatusRating) {
    return props.rating.toString() + "*";
  } else {
    return props.rating.toString();
  }
}

function labelClassName(props: RatingCheckboxProps): string {
  const className: string = props.selected ? "rating selected" : "rating";
  if (props.isInitialStatusRating && props.indicateCurrent) {
    return className.concat(" initial-status-label");
  } else {
    return className;
  }
}

function RatingCheckbox(props: RatingCheckboxProps) {
  return (
    <Grid item xs={2} textAlign="center">
      {props.editEnabled ? (
        <FormControlLabel
          style={{ margin: 0 }}
          labelPlacement="end"
          control={
            <Radio
              checkedIcon={<Check fontSize="inherit" />}
              style={{ padding: 0, fontSize: 12 }}
              size="small"
              name={props.name}
              value={props.rating}
            />
          }
          label={
            <span className={`${labelClassName(props)} m-0 `}>
              {ratingText(props)}
            </span>
          }
        />
      ) : (
        <span className={`${labelClassName(props)} m-0 w-100`}>
          {ratingText(props)}
        </span>
      )}
    </Grid>
  );
}

const ratingInputRoots: Root[] = [];
export function initializeRatingInputs(
  elements: JQuery | string = $('[data-toggle="rating-input"]'),
) {
  if (isString(elements)) {
    elements = $(elements);
  }

  const components: React.Component[] = [];
  $(elements).each((index, element) => {
    const jElem = $(element);
    const ratingValue = parseInt(jElem.attr("data-rating-value"));
    const ratingDisabled = !isNil(jElem.attr("data-rating-disabled"));
    const ratingName = jElem.attr("data-rating-name");
    let ratingScaleMin = parseInt(jElem.attr("data-rating-scale-min"));
    let ratingScaleMax = parseInt(jElem.attr("data-rating-scale-max"));
    const ratingStep = parseInt(jElem.attr("data-rating-step"));
    const ratingLabel = jElem.attr("data-rating-label");
    const ratingNote = jElem.attr("data-rating-note");
    const ratingNoteDateString = jElem.attr("data-rating-note-date");
    let ratingNoteDate;
    if (!isNil(ratingNoteDateString)) {
      ratingNoteDate = moment(ratingNoteDateString);
    }

    if (isNaN(ratingScaleMin) || isNaN(ratingScaleMax)) {
      ratingScaleMin = 1;
      ratingScaleMax = 6;
    }

    const ratingProps: RatingInputProps = {
      name: ratingName,
      scaleRange: [ratingScaleMin, ratingScaleMax],
      value: ratingValue,
      initialStatusValue: ratingValue,
      step: isNaN(ratingStep) ? 1 : ratingStep,
      disabled: ratingDisabled,
      label: ratingLabel,
      note: ratingNote,
      noteDate: ratingNoteDate,
    };

    const root = createRoot(element);
    ratingInputRoots.push(root);
    root.render(
      <AppRoot>
        <RatingInput {...ratingProps}></RatingInput>
      </AppRoot>,
    );
  });

  return components;
}

/**
 * Destroy rating inputs
 */
export function destroyRatingInputs(): void {
  ratingInputRoots.forEach((root) => {
    root.unmount();
  });
  ratingInputRoots.length = 0;
}

export interface RatingInputProps {
  name?: string;
  label?: string;
  note?: string;
  noteDate?: Moment;
  scaleRange?: [number, number];
  step?: number;
  value?: number;
  inverted?: boolean;
  disabled?: boolean;

  onChange?: (value: number) => void;
  /* Indicates if the rating was set through user interaction  */
  initialStatusValue?: number;
  /**
   * Show initial value in input with a "*" and display help text
   */
  indicateCurrent?: boolean;
}

/**
 * An input for ratings on a configurable scale
 */
export class RatingInput extends React.Component<RatingInputProps> {
  static defaultProps: RatingInputProps = {
    scaleRange: [1, 6],
    step: 1,
    inverted: false,
    disabled: false,
    indicateCurrent: false,
  };

  render(): React.ReactNode {
    return (
      <Grid container>
        <Grid
          item
          container
          xs={12}
          className="rating-input"
          title={this.getNoteText()}
        >
          <Grid item container xs={12}>
            <RadioGroup
              style={{ width: "100%" }}
              row
              value={this.props.value || null}
              onChange={
                this.props.disabled
                  ? null
                  : (e) => this.props.onChange?.(toNumber(e.target.value))
              }
            >
              {range(
                this.props.scaleRange[0],
                this.props.scaleRange[1] + 1,
                this.props.step,
              ).map((rating) => {
                return (
                  <RatingCheckbox
                    key={rating}
                    name={isNil(this.props.name) ? "rating" : this.props.name}
                    rating={rating}
                    selected={this.props.value === rating}
                    indicateCurrent={this.props.indicateCurrent}
                    editEnabled={this.props.disabled !== true}
                    isInitialStatusRating={
                      isNil(this.props.value)
                        ? false
                        : rating === this.props.initialStatusValue
                    }
                  />
                );
              })}
            </RadioGroup>
          </Grid>
          <Grid
            item
            xs={12}
            className={getColorScaleClass(this.props.inverted)}
          ></Grid>
          <Grid
            item
            xs={12}
            className="color-scale-overlay"
            style={{
              background: getOverlayBackground(
                this.props.value,
                this.props.scaleRange,
              ),
            }}
          ></Grid>
          {this.props.indicateCurrent && !isNil(this.props.value) ? (
            <small className="form-text text-muted mt-0">
              {I18n.t("frontend.rating_input.rating_help_text")}
            </small>
          ) : null}
        </Grid>
      </Grid>
    );
  }
  getNoteText(): string {
    if (!isEmpty(this.props.note)) {
      return `${
        isNil(this.props.noteDate)
          ? ""
          : toString(this.props.noteDate.format("L LT")) + ": "
      } ${this.props.note}`;
    } else {
      return null;
    }
  }
}

function getInputWrapperClass(disabled: boolean): string {
  return disabled ? "rating-input-wrapper disabled" : "rating-input-wrapper";
}

function getColorScaleClass(inverted: boolean): string {
  return inverted ? "color-scale inverted" : "color-scale";
}

function getOverlayBackground(
  value: number,
  scaleRange: [number, number],
): string {
  if (isNil(value) || isNaN(value)) {
    return "rgba(255, 255, 255, 0.8)";
  }

  const percent = (value / scaleRange[1]) * 100;
  return `linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) ${percent}%, rgba(255, 255, 255, 0.8) ${percent}%, rgba(255, 255, 255, 0.8) 100%)`;
}

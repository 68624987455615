import { isEmpty, map, values } from "lodash";
import { getStateColor } from "../../utils/colors";
import { StateData } from "../chart_data/chart_data_loader.types";

export function buildStateLegendHtml(stateData: StateData[]): string {
  if (isEmpty(stateData)) return "";

  return map(
    stateData,
    (priticularStateData) =>
      `<div class="row mx-2 mt-1">
          <div class="col-12">
            <h6>${priticularStateData.state_context.name}</h6>
          </div>
      ${map(
        values(priticularStateData.possible_states),
        (state, index) =>
          `<div class="col">
          <i class="fa fa-square ml-1" style="color: ${getStateColor(
            state,
            index,
          )};" ></i>
          <small>
          ${state.name}
          </small>
      </div>`,
      ).join("")}
      </div>`,
  ).join("");
}

/// <reference types="../../../definitions/index" />;
import { isEmpty, isNil, noop } from "lodash";
import * as React from "react";

import {
  Divider,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { EventNotification } from "../../../models/event_notification";
import * as url_helper from "../../../utils/urls";
import { DropdownButton } from "./dropdown_button";
import { EventList } from "./event_list";

import { Event, NotificationsOutlined, Settings } from "@mui/icons-material";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";

interface NotificationDropdownProps {
  error?: string;
  /**
   * An list of new(unnoticed) event notifications
   */
  newNotifications?: EventNotification[];

  /**
   * An list of old event notifications
   */
  previousNotifications?: EventNotification[];

  /**
   * Delay till new notifications are marked as noticed
   */
  markAsNoticedAfterMs?: number;

  /**
   * Callback to mark notifications as noticed
   */
  onMarkNotificationsAsNoticed?: (eventIds: number[]) => void;
}

/**
 * A dropdown menu to display event notifications.
 * The events are marked as noticed if the dropdown is opened for at least 2 seconds.
 */
export const NotificationDropdown: React.FunctionComponent<
  NotificationDropdownProps
> = ({
  newNotifications = [],
  previousNotifications = [],
  markAsNoticedAfterMs = 2000,
  onMarkNotificationsAsNoticed = noop,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [markNoticedDone, setMarkNoticedDone] = React.useState<boolean | null>(
    null,
  );

  const handleShow = React.useCallback(() => {
    if (isEmpty(newNotifications)) {
      return;
    }
    if (markNoticedDone !== true) {
      setMarkNoticedDone(false);
      setTimeout(() => {
        if (!isNil(markNoticedDone)) {
          onMarkNotificationsAsNoticed(getUnnoticedEventIds(newNotifications));
          setMarkNoticedDone(true);
        }
      }, markAsNoticedAfterMs);
    }
  }, [
    newNotifications,
    markAsNoticedAfterMs,
    onMarkNotificationsAsNoticed,
    markNoticedDone,
  ]);

  const context = React.useContext<SialogicContext>(AppContext);
  return (
    <div>
      <DropdownButton
        title={I18n.t("frontend.event_dropdown.heading")}
        unnoticedEvents={newNotifications.length}
        id="notification-dropdown-btn"
        icon={<NotificationsOutlined />}
        aria-haspopup="true"
        aria-controls={open ? "notification-dropdown" : undefined}
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          handleClick(e);
          handleShow();
        }}
      />
      <Menu
        id="notification-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "notification-dropdown-btn",
        }}
        slotProps={{ paper: { style: { width: "350px" } } }}
      >
        <MenuItem>
          <ListItemText>
            <Typography variant="h5">
              {I18n.t("frontend.event_dropdown.heading")}
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <Link href={url_helper.notificationSettingsUrl()}>
              <Settings fontSize="small" />
            </Link>
          </ListItemSecondaryAction>
        </MenuItem>
        <Divider />

        {!isNil(props.error) ? (
          <MenuItem>{props.error}</MenuItem>
        ) : isEmpty(newNotifications) && isEmpty(previousNotifications) ? (
          <MenuItem>
            <Typography variant="caption">
              {I18n.t("frontend.event_dropdown.no_notifications")}
            </Typography>
          </MenuItem>
        ) : (
          [
            isEmpty(newNotifications) ? null : (
              <EventList
                key="currentEvents"
                baseKey="current"
                events={newNotifications}
                heading={I18n.t("frontend.event_dropdown.new")}
              />
            ),
            isEmpty(previousNotifications) ? null : (
              <EventList
                key="previousEvents"
                baseKey={"previous"}
                events={previousNotifications}
                heading={I18n.t("frontend.event_dropdown.old")}
              />
            ),
          ]
        )}

        <Divider />
        <MenuItem href={url_helper.assetEventsPath(context.currentAssetId)}>
          <ListItemIcon>
            <Event fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">
              {I18n.t("frontend.event_dropdown.all_events")}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

function getUnnoticedEventIds(newNotifications: EventNotification[]): number[] {
  return newNotifications.map((event) => event.event_id as number);
}

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isNil, sortBy } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AssetJSONObject } from "../../json_api/asset";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { SensorJSONObject } from "../../json_api/sensor";
import { SensorTypeJSONObject } from "../../json_api/sensor_type";
import { ResourcePermission } from "../../models/resource_permission";
import { IDType } from "../../utils/urls/url_utils";
import { AppRoot } from "../common/app_root";
import { SensorAssignAssetForm } from "./sensor_assign_asset_form";
import { SensorForm, SensorFormMode } from "./sensor_form";
import { SensorList } from "./sensor_list";
import { SensorsAutocomplete } from "./sensors_autocomplete";

const sensorsAutocompleteRoots: Root[] = [];
/**
 * Initialize react component AssetsAutocomplete within all elements with data-toggle="assets-autocomplete".
 *
 */
export function initializeSensorsAutocomplete(): void {
  $('[data-toggle="sensors-autocomplete"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const sensorJsonApi = $(element).data("sensor") as SingleResourceDoc<
      string,
      SensorJSONObject
    >;
    const sensor =
      jsonApiSingleResourceToFlatObject<SensorJSONObject>(sensorJsonApi);
    const sensorsJsonApi = $(element).data("sensors") as CollectionResourceDoc<
      string,
      SensorJSONObject
    >;
    const sensors = isNil(sensorsJsonApi)
      ? null
      : jsonApiResourceCollectionToFlatObjects<SensorJSONObject>(
          sensorsJsonApi,
        );
    const url = $(element).data("sensors-base-url") as string;
    const root = createRoot(element);

    root.render(
      <AppRoot>
        <SensorsAutocomplete
          sensorsBaseUrl={url}
          sensors={sensors}
          sensor={sensor}
        />
      </AppRoot>,
    );
    sensorsAutocompleteRoots.push(root);
  });
}

/**
 * Remove react component from all elements
 */
export function destroySensorsAutocomplete(): void {
  sensorsAutocompleteRoots.forEach((root) => {
    root.unmount();
  });
  sensorsAutocompleteRoots.length = 0;
}

const sensorListRoots: Root[] = [];

/****************** Sensor List */

export function initializeSensorList(): void {
  $('[data-toggle="sensor-list"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const assetId = $(element).data("asset-id") as IDType;
    const permissions = $(element).data(
      "sensor-permissions",
    ) as ResourcePermission;
    const assetPermissions = $(element).data(
      "asset-permissions",
    ) as ResourcePermission;
    const root = createRoot(element);
    sensorListRoots.push(root);
    root.render(
      <AppRoot>
        <SensorList
          assetId={assetId}
          fabButtons
          permissions={permissions}
          assetPermissions={assetPermissions}
        />
      </AppRoot>,
    );
  });
}

export function destroySensorsList(): void {
  sensorListRoots.forEach((root) => {
    root.unmount();
  });
}

const sensorFormRoots: Root[] = [];

/****************** Sensor Form */

export function initializeSensorForm(): void {
  $('[data-toggle="sensor-form"]').each((index, element) => {
    const jqElem = $(element);
    //  const organizations = $(element).data('organizations');
    const sensorJsonApi = jqElem.data("sensor") as SingleResourceDoc<
      string,
      SensorJSONObject
    >;
    const sensor =
      jsonApiSingleResourceToFlatObject<SensorJSONObject>(sensorJsonApi);

    const assetTypesSensorTypes = jqElem.data(
      "asset-types-sensor-types",
    ) as Record<string, string>[];

    const sensorTypesJsonApi = jqElem.data(
      "sensor-types",
    ) as CollectionResourceDoc<string, SensorTypeJSONObject>;
    const sensorTypes = isNil(sensorTypesJsonApi)
      ? null
      : jsonApiResourceCollectionToFlatObjects<SensorTypeJSONObject>(
          sensorTypesJsonApi,
        );
    const url = jqElem.data("sensors-base-url") as string;

    const mode = jqElem.data("mode") as SensorFormMode;
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <SensorForm
          sensor={sensor}
          sensorTypes={sortBy(sensorTypes, (st) => st.name)}
          mode={mode}
          buttonPosition={"bottom"}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroySensorForm(): void {
  sensorFormRoots.forEach((root) => {
    root.unmount();
  });
  sensorFormRoots.length = 0;
}

const sensorAssignAssetFormRoots: Root[] = [];

export function initializeSensorAssignAssetFrom(): void {
  $('[data-toggle="sensor-assign-asset-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');
    const assetsJsonApiDoc = theElem.data("assets") as CollectionResourceDoc<
      string,
      AssetJSONObject
    >;
    const assets =
      jsonApiResourceCollectionToFlatObjects<AssetJSONObject>(assetsJsonApiDoc);
    const currentAssetJsonApi = theElem.data("asset") as SingleResourceDoc<
      string,
      AssetJSONObject
    >;
    const currentAsset = jsonApiSingleResourceToFlatObject(currentAssetJsonApi);
    const currentAssetId = theElem.data("asset-id") as number;
    const sensorJsonApiDoc = theElem.data("sensor") as SingleResourceDoc<
      string,
      SensorJSONObject
    >;
    const sensor =
      jsonApiSingleResourceToFlatObject<SensorJSONObject>(sensorJsonApiDoc);
    const root = createRoot(element);
    sensorAssignAssetFormRoots.push(root);
    root.render(
      <AppRoot>
        <SensorAssignAssetForm
          ibox={true}
          asset={currentAsset}
          assetId={currentAssetId}
          assets={assets}
          sensor={sensor}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroySensorAssignAssetFrom() {
  sensorAssignAssetFormRoots.forEach((root) => {
    root.unmount();
  });
}

import { AccessTime, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Skeleton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, isNil, map, toString } from "lodash";
import { DateRange } from "moment-range";
import * as React from "react";
import { AssetIncludes } from "../../json_api/asset";
import { used_tags_asset_logbook_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { CSMIncludes } from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { MaterialUiDateRangePicker } from "../common/data_range_picker";
import { ErrorBoundary } from "../common/error_boundary";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { loadStateMachinesForItem } from "../context_state_machines/context_state_machine_data";
import { AssetEventsWidget } from "../widgets/asset_events_widget";
import { AssetInfoWidget } from "../widgets/asset_info_widget";
import { AssetMapWidget } from "../widgets/asset_map_widget";
import { AssetPartsWidget } from "../widgets/asset_parts_widget";
import { CommentWidget } from "../widgets/comment_widget";
import { SensorGroupWidget } from "../widgets/sensor_group_widget";
import { StateWidget } from "../widgets/state_widget";
import { useLoadAssetQuery } from "./asset_data";
import { AssetHomeProps } from "./asset_home.types";
const CSM_INCLUDES: CSMIncludes[] = [
  "state_context",
  "possible_states",
  "current_state",
];
const ASSET_INCLUDES: AssetIncludes[] = ["asset_type", "location"];
/**
 *
 *
 * @param {*} assetState
 */
export const AssetHome: React.FunctionComponent<AssetHomeProps> = ({
  floatingButtons = true,
  ...props
}) => {
  const assetQuery = useLoadAssetQuery({
    variables: { id: props.assetId, includes: ASSET_INCLUDES },
  });

  const stateMachinesQuery = loadStateMachinesForItem({
    variables: {
      itemId: props.assetId,
      itemType: "Asset",
      include: CSM_INCLUDES,
    },
  });

  const logbookTagsQuery = useQuery({
    queryKey: ["logbook_tags", { logbook_id: assetQuery.data?.logbook_id }],
    enabled: !!assetQuery.data?.logbook_id,
    queryFn: (data) => {
      return loadDataFromUrl<string[]>(
        used_tags_asset_logbook_path(props.assetId, {
          format: "json",
        }),
      );
    },
  });

  const appContext = React.useContext(AppContext);

  const [timeRange, setTimeRange] = React.useState<DateRange>(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(
      assetQuery.isLoading ||
        logbookTagsQuery.isLoading ||
        stateMachinesQuery.isLoading,
    );
  }, [
    assetQuery.isLoading,
    logbookTagsQuery.isLoading,
    stateMachinesQuery.isLoading,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} justifyContent="flex-end">
          <Grid item container xs={12} sm="auto">
            {loading ? (
              <Grid item>
                <CircularProgress size="small" />
              </Grid>
            ) : null}
            <Grid item>
              <MaterialUiDateRangePicker
                type="datetime"
                dateFormat="L LT"
                endAdornment={<AccessTime />}
                helperText={I18n.t(
                  "frontend.dashboard.selected_time_range_helper_text",
                )}
                label={I18n.t("frontend.dashboard.select_time_range")}
                value={
                  !isNil(timeRange?.start)
                    ? [timeRange.start, timeRange.end]
                    : null
                }
                opens="left"
                onChange={({ dateRange, timeRangeId: label }) => {
                  setTimeRange(new DateRange(dateRange[0], dateRange[1]));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorBoundary>
              {assetQuery.isLoading ? (
                <Skeleton variant="rectangular" height={200} />
              ) : (
                <AssetEventsWidget
                  timeRange={timeRange}
                  title={I18n.t("activerecord.models.asset_event.other")}
                  encloseInIBox={true}
                  assetId={props.assetId}
                  assetIds={assetQuery?.data.subtree_ids}
                  displayMode="detailed"
                  eventListSize={5}
                />
              )}
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SensorGroupWidget
              title={I18n.t("activerecord.models.sensor.other")}
              timeRange={timeRange}
              fallbackToLastValue
              encloseInIBox
              assetId={props.assetId}
              vertical
              updateEnabled
            />
          </Grid>

          {!stateMachinesQuery.isLoading &&
          isEmpty(stateMachinesQuery.data) ? null : (
            <Grid item xs={12}>
              <ErrorBoundary>
                <Card>
                  <CardHeader
                    title={I18n.t("activerecord.models.state.other")}
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      {assetQuery.isLoading || stateMachinesQuery.isLoading ? (
                        <Skeleton variant="rectangular" height={200} />
                      ) : assetQuery.data && stateMachinesQuery.data ? (
                        map(stateMachinesQuery.data, (csm) => {
                          const identifier = csm.state_context?.identifier;
                          const stateInfo =
                            assetQuery.data.asset_states[identifier];
                          return (
                            <Grid
                              item
                              xs={12}
                              sm
                              key={toString(stateInfo?.csm_id || identifier)}
                            >
                              <StateWidget
                                enableStateUpdate={appContext.user.isAdmin}
                                enableEditState={appContext.user.isAdmin}
                                encloseInIBox={true}
                                timeRange={timeRange}
                                contextStateMachine={csm}
                                states={csm.possible_states}
                                assetId={props.assetId}
                              />
                            </Grid>
                          );
                        })
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </ErrorBoundary>
            </Grid>
          )}
          {appContext.subscribedModules.logbook &&
          assetQuery.data?.logbook_id ? (
            <Grid item xs={12}>
              <ErrorBoundary>
                <CommentWidget
                  timeRange={timeRange}
                  title={I18n.t("activerecord.models.logbook.one")}
                  itemId={assetQuery.data.logbook_id}
                  tags={logbookTagsQuery.data}
                  itemType="Logbook"
                  assetId={props.assetId}
                  encloseInIBox
                  limit={5}
                />
              </ErrorBoundary>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorBoundary>
              <AssetInfoWidget
                assetId={props.assetId}
                encloseInIBox={true}
                timeRange={timeRange}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item xs={12}>
            <ErrorBoundary>
              <AssetPartsWidget
                assetId={props.assetId}
                encloseInIBox={true}
                timeRange={timeRange}
              />
            </ErrorBoundary>
          </Grid>
          {props.map.url && assetQuery.data?.location?.lat ? (
            <Grid item xs={12}>
              <ErrorBoundary>
                <AssetMapWidget
                  timeRange={timeRange}
                  assetIds={[props.assetId]}
                  mapUrl={props.map.url}
                  attribution={props.map.attribution}
                  encloseInIBox={true}
                />
              </ErrorBoundary>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {floatingButtons ? (
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn
            onCancel={() => redirectTo("back")}
            cancelIcon={<KeyboardArrowLeft />}
          />
        </FixedBottomArea>
      ) : null}
    </Grid>
  );
};

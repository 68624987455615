import "jquery";
import { isEmpty, isNil, isNumber, isString } from "lodash";
import { WidgetController } from "../controller/widget_controller";
import { convertToUnit, unitDisplayString } from "../utils/unit_conversion";
import LineDiagramWidget from "../widgets/line_diagram_widget";

export default class ChartPluginInitializer {
  private typeSelect: JQuery;
  private invertInput: JQuery;
  private minInput: JQuery;
  private maxInput: JQuery;
  private inputUnitField: JQuery;
  private unit: string;
  private displayUnit: string;

  static initialize(
    typeSelectId: string,
    invertInputId: string,
    minInputId: string,
    maxInputId: string,
    unit: string,
    displayUnit: string,
  ): void {
    new ChartPluginInitializer(
      typeSelectId,
      invertInputId,
      minInputId,
      maxInputId,
      unit,
      displayUnit,
    );
  }

  constructor(
    typeSelectId: string,
    invertInputId: string,
    minInputId: string,
    maxInputId: string,
    unit: string,
    displayUnit: string,
  ) {
    this.typeSelect = $(typeSelectId);
    this.invertInput = $(invertInputId);
    this.minInput = $(minInputId);
    this.maxInput = $(maxInputId);
    this.inputUnitField = $(".unit-addon");
    this.unit = unit;
    if (isNil(displayUnit) || isEmpty(displayUnit)) {
      this.displayUnit = unit;
    } else {
      this.displayUnit = displayUnit;
    }

    // initial update
    this.updatePlugins();

    // register events
    this.typeSelect.on("change", () => {
      this.updatePlugins();
    });
    this.invertInput.on("change", () => {
      const invert = this.getBooleanFromInput(this.invertInput);
      void WidgetController.getInstance().forEachWidget((widget) => {
        if (!(widget instanceof LineDiagramWidget)) {
          return;
        }

        return widget.setChartAnnotationOptions({
          annotationMode: invert ? "inside range" : "outside range",
        });
      });
    });

    this.minInput.on("keyup", (e: JQuery.KeyUpEvent) => {
      if (e.key == "Enter") {
        this.checkMinLessThanMax("min");
        this.updatePlugins();
        e.stopPropagation();
        //do not submit the form on enter
        return false;
      }
    });

    this.maxInput.on("keyup", (e: JQuery.KeyUpEvent) => {
      if (e.key == "Enter") {
        this.checkMinLessThanMax("max");
        this.updatePlugins();
        e.stopPropagation();
        //do not submit the form on enter
        return false;
      }
    });

    this.minInput.on("change", (event) => {
      this.checkMinLessThanMax("min");
      this.updatePlugins();
    });

    this.maxInput.on("change", (event) => {
      this.checkMinLessThanMax("max");
      this.updatePlugins();
    });
  }

  checkMinLessThanMax(key: string): void {
    let minValue = parseFloat(this.minInput.val() as string);
    let maxValue = parseFloat(this.maxInput.val() as string);

    // clamp min max so that min <= max
    if (!isNaN(minValue) && !isNaN(maxValue) && minValue > maxValue) {
      if (key === "min") {
        minValue = maxValue;
        this.minInput.val(minValue);
      } else {
        maxValue = minValue;
        this.maxInput.val(maxValue);
      }
    }
  }

  updatePlugins(): void {
    const type = this.typeSelect.val();
    const min = this.getNumberFromInput(this.minInput);
    const max = this.getNumberFromInput(this.maxInput);
    const invert = this.getBooleanFromInput(this.invertInput);

    switch (type) {
      case "EventPatterns::SensorEventPattern":
        // setup min max annotations
        void WidgetController.getInstance().forEachWidget((widget) => {
          if (!(widget instanceof LineDiagramWidget)) {
            return;
          }

          return widget.setChartAnnotationOptions({
            showMinMax: true,
            showTrend: false,
            annotationMode: invert ? "inside range" : "outside range",
            min,
            max,
          });
        });

        this.updateUnitField(this.unit);
        break;
      case "EventPatterns::ValueTrendEventPattern":
        // setup trend annotations
        void WidgetController.getInstance().forEachWidget((widget) => {
          if (!(widget instanceof LineDiagramWidget)) {
            return;
          }

          return widget.setChartAnnotationOptions({
            showMinMax: false,
            showTrend: true,
            annotationMode: invert ? "inside range" : "outside range",
            minSlope: min,
            maxSlope: max,
          });
        });

        this.updateUnitField(`\u0394${this.unit} / h`);
        break;
    }
  }

  private updateUnitField(unit: string): void {
    this.inputUnitField.each((index, element) => {
      $(element).text(unitDisplayString(unit));
    });
  }

  private getNumberFromInput(input: JQuery): number {
    const value = input.val();

    if (isEmpty(value)) {
      return null;
    } else if (isString(value)) {
      return convertToUnit(parseFloat(value), this.unit, this.displayUnit);
    } else if (isNumber(value)) {
      return convertToUnit(value, this.unit, this.displayUnit);
    } else {
      return null;
    }
  }

  private getBooleanFromInput(input: JQuery): boolean {
    switch (input.attr("type")) {
      case "checkbox":
        return input.prop("checked") as boolean;
      default:
        return input.val() === "true";
    }
  }
}

import { isArray, isEmpty, isNil, isString } from "lodash";

import JSON from "json5";
import { Moment } from "moment";
import moment from "../initializers/moment";
import { TimeRanges } from "../definitions/bootstrap-daterangepicker";

export function addTimeScopeToAnchor(
  start: Moment,
  end: Moment,
  label: string = null,
  propHolderId?: string,
): void {
  let propValue;

  if (
    isNil(label) ||
    (label == I18n.t("frontend.time_range_picker.select_custom_range") &&
      !isNil(start) &&
      !isNil(end))
  ) {
    propValue = [start.toISOString(), end.toISOString()];
  } else {
    propValue = label;
  }
  updatePropInAnchor("time-range", propValue, propHolderId);
}
export function loadTimeScopeFromAnchor(
  timeRanges: TimeRanges,
  propHolderId?: string,
): [Moment, Moment] {
  const props = loadPropsFromAnchor(propHolderId) as { "time-range": string[] };
  const timeRange = props["time-range"];

  if (isNil(timeRange)) return null;

  if (isString(timeRange) && !isEmpty(timeRange)) {
    return timeRanges[timeRange] as [Moment, Moment];
  } else if (isArray(timeRange) && timeRange.length === 2) {
    const start = moment(timeRange[0]);
    const end = moment(timeRange[1]);

    if (!start.isValid() || !end.isValid()) return null;

    return [start, end];
  }

  return null;
}

export function serializePropsToAnchor(
  props: Record<string, string[] | string | boolean | number>,
  propHolderId?: string,
): void {
  if (isEmpty(props)) return;

  if (isEmpty(propHolderId)) {
    window.location.hash = "#" + JSON.stringify(props);
  } else {
    const allProps = loadPropsFromAnchor();
    (allProps as Record<string, any>)[propHolderId] = props;
    window.location.hash = "#" + JSON.stringify(props);
  }
}

export function loadPropsFromAnchor(
  propHolderId?: string,
): Record<string, string | string[] | boolean | number> {
  const json_str_escaped = window.location.hash.slice(1);
  // unescape
  const json_str = decodeURIComponent(json_str_escaped);
  const props = isEmpty(json_str)
    ? {}
    : (JSON.parse(json_str) as Record<
        string,
        string | string[] | Record<string, string | string[]>
      >);
  if (!isEmpty(propHolderId)) {
    return props[propHolderId] as Record<string, string | string[]>;
  }

  return props as Record<string, string | boolean | number>;
}

export function updatePropInAnchor(
  prop: string,
  value: string | string[] | boolean | number,
  propHolderId?: string,
) {
  const anchorProps = loadPropsFromAnchor();
  if (isNil(value)) {
    delete anchorProps[prop];
  } else {
    anchorProps[prop] = value;
  }
  serializePropsToAnchor(anchorProps, propHolderId);
}

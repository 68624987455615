import { IDType } from "../utils/urls/url_utils";

export interface AttributeKey {
  id?: IDType;
  key?: string;
  data_type?: AttributeKeyDataType;
}

export type AttributeKeyDataType =
  | "integer"
  | "real"
  | "text"
  | "timestamp"
  | "virtual"
  | "influx"
  | "location"
  | "enum_attribute";

export const AttributeKeyDataTypes: readonly AttributeKeyDataType[] = [
  "integer",
  "real",
  "text",
  "timestamp",
  "virtual",
  "influx",
  "location",
  "enum_attribute",
];

import { isNil } from "lodash";
import {
  IDType,
  RequestFormat,
  formatString,
  localizedBasePath,
} from "./url_utils";

export function roleDefinitionsPath(
  format: RequestFormat = "html",
  organizationId: IDType = null,
): string {
  if (!isNil(organizationId)) {
    return `${localizedBasePath()}/organizations/${organizationId}/role_definitions${formatString(
      format,
    )}`;
  } else {
    return `${localizedBasePath()}/role_definitions${formatString(format)}`;
  }
}

export function roleDefinitionPath(
  id: IDType,
  format: RequestFormat = "html",
  organizationId: IDType = null,
): string {
  if (!isNil(organizationId)) {
    return `${localizedBasePath()}/organizations/${organizationId}/role_definitions/${id}${formatString(
      format,
    )}`;
  } else {
    return `${localizedBasePath()}/role_definitions/${id}${formatString(
      format,
    )}`;
  }
}

export function assignRolesUserPath(
  id: IDType,
  format: RequestFormat = "html",
): string {
  return `${localizedBasePath(true)}/users/${id}/assign_roles${formatString(
    format,
  )}`;
}

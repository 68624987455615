import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Bluebird from "bluebird";
import { defaultTo, isEmpty, isNil, map, pick } from "lodash";
import * as React from "react";
import { Comment, CommentWithChildren } from "../../models/comment";
import { IDType } from "../../utils/urls/url_utils";

import { Cancel, Check, Delete, Edit } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { dialog } from "../../utils/dialog";
import { loadDataFromUrl, sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { error, success } from "../../utils/toasts";
import { commentUrl, commentsUrl } from "../../utils/urls";
import { LoadingIcon } from "../common/icon";
import { UserCard } from "../users/user_card";
import { AppContext } from "../common/app_context/app_context_provider";
import { useConfirm } from "material-ui-confirm";
interface CommentFormProps {
  parentId?: IDType;
  readonly?: boolean;
  // data necessary to load a comment form API
  commentLoadData?: { id: IDType; itemId: IDType; itemType: string };
  // Complete comment data object to display / edit
  comment: CommentWithChildren;

  heading?: string;
  onCommentAdded?: (comment: Comment) => void;
  onCommentSaved?: (comment: Comment) => void;
  onCommentDeleted?: (comment: Comment) => void;
}
interface ProcessResult {
  action: "create" | "update" | "delete";
  comment: Comment;
}
function createComment(comment: Comment): Bluebird<ProcessResult> {
  return sendData(
    commentsUrl({ itemId: comment.itemId, itemType: comment.itemType }),
    { comment: pick(comment, ["title", "message", "parentId", "tags"]) },
    "POST",
    "application/json",
  ).then((response: Comment & { errors: any }) => {
    // Insert new comment
    return { action: "create", comment: response };
  });
}

function updateComment(comment: Comment): Bluebird<ProcessResult> {
  return sendData(
    commentUrl(comment),
    { comment: pick(comment, ["title", "message", "parentId", "tags"]) },
    "PATCH",
    "application/json",
  ).then((response: Comment & { errors: any }) => {
    // Insert new comment
    return { action: "update", comment: response };
  });
}

function deleteComment(comment: Comment): Bluebird<boolean> {
  return sendData(commentUrl(comment), null, "DELETE")
    .catch((error: Error) => {
      return false;
    })
    .then(() => {
      return true;
    });
}

function processStore(comment: Comment) {
  return isNil(comment.id) ? createComment(comment) : updateComment(comment);
}

function validateComment(c: Comment) {
  return !(isEmpty(c?.title) || isEmpty(c.message));
}

export const CommentForm: React.FunctionComponent<CommentFormProps> = (
  props,
) => {
  const context = React.useContext(AppContext);
  const [comment, setComment] = React.useState(defaultTo(props.comment, {}));
  const [isValid, setIsValid] = React.useState(validateComment(comment));
  const [orginalComment, setOriginalComment] = React.useState(
    defaultTo(props.comment, {}),
  );
  const [loading, setLoading] = React.useState(false);
  const [editEnabled, setEditEnabled] = React.useState(
    isNil(props.readonly) ? false : !props.readonly,
  );

  const [newComment, setNewComment] = React.useState(isNil(comment?.id));

  React.useEffect(() => {
    setIsValid(validateComment(comment));
    setNewComment(isNil(comment?.id));
  }, [comment]);

  React.useEffect(() => {
    setComment(props.comment);
    setOriginalComment(props.comment);
  }, [props.comment]);

  if (isNil(props.comment) && !isNil(props.commentLoadData)) {
    React.useEffect(() => {
      const url = commentUrl(props.commentLoadData);
      setLoading(true);
      void loadDataFromUrl(url)
        .then((loadedComment: CommentWithChildren) => {
          setComment(loadedComment);
          setOriginalComment(loadedComment);
        })
        .catch((theError: Error) => {
          logger.error(theError);
          void error(
            I18n.t("base.error"),
            I18n.t("frontend.comments.comment_form.comment_could_not_be_saved"),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, [props.commentLoadData]);
  }

  const confirm = useConfirm();
  return (
    <Card variant="outlined">
      <CardHeader
        style={{ paddingBottom: 5 }}
        title={props.heading || comment?.title}
        action={
          <>
            {context.user?.id != comment?.user?.id || newComment ? null : (
              <IconButton
                onClick={() => {
                  void confirm({
                    allowClose: true,
                    description: I18n.t(
                      "frontend.comments.comment_form.delete_confirmation",
                    ),
                  }).then(() => {
                    return deleteComment(comment).then((deleted) => {
                      if (deleted) props.onCommentDeleted?.(comment);
                    });
                  });
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            )}
            {newComment ? null : (
              <IconButton
                size="small"
                aria-label="edit"
                onClick={() => {
                  setEditEnabled(!editEnabled);
                  setComment(orginalComment);
                }}
              >
                {editEnabled ? <Cancel /> : <Edit />}
              </IconButton>
            )}
          </>
        }
      />
      <CardContent style={{ paddingTop: 0, paddingBottom: 10 }}>
        {loading ? (
          <LoadingIcon size="4x" />
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={3}
              style={{ textAlign: "center" }}
              spacing={2}
            >
              <Grid item xs={12}>
                <UserCard user={comment?.user} />
              </Grid>
              {isEmpty(comment.created_at) ? null : (
                <Grid item xs={12}>
                  <Typography variant="caption" style={{ fontSize: 10 }}>
                    {moment(comment.created_at).format("L LT")}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={9} spacing={2}>
              {!editEnabled ? (
                isNil(props.heading) ? null : (
                  <Grid item xs={12}>
                    <Typography variant="h5">{comment.title}</Typography>
                  </Grid>
                )
              ) : (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={I18n.t(
                      "activerecord.attributes.comment.title",
                    )}
                    value={defaultTo(comment.title, "")}
                    label={I18n.t("activerecord.attributes.comment.title")}
                    onChange={(event) => {
                      setComment({ ...comment, title: event.target.value });
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                {!editEnabled ? (
                  <Typography variant="body1">{comment.message}</Typography>
                ) : (
                  <TextField
                    size="small"
                    fullWidth
                    placeholder={I18n.t(
                      "activerecord.attributes.comment.message",
                    )}
                    label={I18n.t("activerecord.attributes.comment.message")}
                    value={defaultTo(comment.message, "")}
                    multiline
                    onChange={(event) => {
                      setComment({ ...comment, message: event.target.value });
                    }}
                  ></TextField>
                )}
              </Grid>
              <Grid item xs={12}>
                {!editEnabled ? (
                  map(comment.tags, (t, i) => (
                    <Chip
                      color="primary"
                      label={t}
                      key={i}
                      variant="outlined"
                      size="small"
                    />
                  ))
                ) : (
                  <Autocomplete
                    size="small"
                    multiple
                    id="tags-filled"
                    options={defaultTo(comment.tags, [])}
                    defaultValue={[]}
                    freeSolo
                    value={defaultTo(comment.tags, [])}
                    onChange={(e, value) => {
                      setComment({ ...comment, tags: value });
                    }}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="primary"
                          key={index}
                          size="small"
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={I18n.t("activerecord.attributes.comment.tags")}
                        placeholder="Tags"
                        helperText={I18n.t(
                          "frontend.comments.comment_form.tags_help_text",
                        )}
                      />
                    )}
                  />
                )}
              </Grid>

              {isEmpty(comment?.children) ? null : (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item container xs={10}>
                    {map(comment.children, (childComment) => (
                      <Grid item xs={12}>
                        <CommentForm
                          comment={childComment}
                          readonly={props.readonly}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
      {!editEnabled ? null : (
        <CardActions>
          <ButtonGroup size="small">
            <Button
              disabled={!isValid}
              color="primary"
              startIcon={<Check />}
              onClick={() => {
                setLoading(true);
                void processStore(comment)
                  .then((processResult) => {
                    setOriginalComment(processResult.comment);
                    if (processResult.action == "create") {
                      void success(
                        I18n.t("base.ok"),
                        I18n.t("base.successfully_created"),
                      );
                      props.onCommentAdded?.(processResult.comment);
                    } else if (processResult.action == "update") {
                      void success(
                        I18n.t("base.ok"),
                        I18n.t("base.successfully_updated"),
                      );
                      setEditEnabled(false);

                      props.onCommentSaved?.(processResult.comment);
                    }
                  })
                  .error((theError: Error) => {
                    logger.warn(theError);
                    void error(
                      I18n.t("base.error"),
                      I18n.t(
                        "frontend.comments.comment_form.comment_could_not_be_saved",
                      ),
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              {I18n.t("base.save")}
            </Button>

            <Button
              startIcon={<Cancel />}
              onClick={() => {
                if (newComment) {
                  setComment(orginalComment);
                } else {
                  setEditEnabled(false);
                  setComment(orginalComment);
                }
              }}
            >
              {I18n.t("base.cancel")}
            </Button>
          </ButtonGroup>
        </CardActions>
      )}
    </Card>
  );
};

import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import {
  compact,
  defaultTo,
  each,
  fromPairs,
  isArray,
  isDate,
  isEmpty,
  isNil,
  isString,
  join,
  last,
  map,
  toInteger,
  toNumber,
  toString,
} from "lodash";
import { IDType, ParamType, ParamsType } from "../utils/urls/url_utils";
import { Moment } from "moment";
import moment from "../initializers/moment";

interface PlainObject {
  [member: string]: PlainObjectValue;
}

type PlainObjectValue = JSON.Primitive | PlainObject | PlainObjectValue[];

type RelationNameMappingFunction<Attributes> = (
  relationName: string,
  parentObject: Partial<Attributes>,
  relatedObject: PlainObject,
) => string;

/**
 * Represents the result of loading a collection of items from a JSON API.
 *
 * @template Attributes - The type of the attributes for each item.
 *
 * @property {Attributes[]} items - The array of items loaded from the API.
 * @property {number} totalItems - The total number of items available in the API.
 * @property {number} totalPages - The total number of pages available in the API.
 */
export interface LoadItemsResult<Attributes> {
  items: Attributes[];
  totalItems: number;
  totalPages: number;
}

/**
 * Processes a JSON:API collection resource document and returns a `LoadItemsResult` object.
 *
 * @template Attributes - The type of the attributes in the JSON:API resource.
 * @param jsonApiResponse - The JSON:API collection resource document to process.
 * @returns An object containing the flattened items, total item count, and total page count.
 */
export function loadItemResultForJsonApiCollectionResourceDoc<
  Attributes extends JSON.Object,
>(
  jsonApiResponse: JSONAPI.CollectionResourceDoc<string, Attributes>,
): LoadItemsResult<Attributes> {
  return {
    items: jsonApiResourceCollectionToFlatObjects(jsonApiResponse),
    totalItems: toInteger(jsonApiResponse?.meta?.record_count),
    totalPages: toInteger(jsonApiResponse?.meta?.page_count),
  };
}

/** Builds a plain object out of a JSONAPI response. Also sets included relations as distinct properties.
 *
 *
 * @export
 * @template Attributes Model attributes expected to be contained in JSONAPI Response Root
 * @param {JSONAPI.SingleResourceDoc<string, Attributes>} jsonAPIObject JSON API Response containing a single resource
 * @returns {Attributes}
 */
export function jsonApiSingleResourceToFlatObject<
  Attributes extends PlainObject,
>(
  jsonAPIObject: JSONAPI.SingleResourceDoc<string, Attributes>,
  callback?: (
    plainObject: Attributes,
    apiObject: JSONAPI.SingleResourceDoc<string, Attributes>,
    includesByItemType: Record<string, JSON.Object>,
  ) => void,
): Attributes {
  {
    const includesByItemType: Record<string, JSON.Object> = {};
    if (isNil(jsonAPIObject) || isEmpty(jsonAPIObject.data)) return null;
    const stringIdOfObject = jsonAPIObject.data.id;
    const typeOfObject = jsonAPIObject.data.type;
    const plainObject: Attributes = {
      id: toNumber(stringIdOfObject),
      ...jsonAPIObject.data.attributes,
    };

    each(jsonAPIObject.included, (includedItem) => {
      const typeMap = defaultTo(
        includesByItemType[includedItem.type],
        {},
      ) as Record<string, JSON.Object>;
      typeMap[includedItem.id] = {
        ...includedItem.attributes,
        id: toInteger(includedItem.id),
      } as JSON.Object;
      includesByItemType[includedItem.type] = typeMap;
    });

    each(jsonAPIObject.data.relationships, (relationshipObject, relName) => {
      addRelatedObjectsToSingleObject(
        plainObject,
        stringIdOfObject,
        typeOfObject,
        relName,
        relationshipObject as JSONAPI.RelationshipsWithData,
        includesByItemType,
      );
    });

    if (callback) {
      callback(plainObject, jsonAPIObject, includesByItemType);
    }

    return plainObject;
  }
}

/** Adds resolved related objects to the plain object from relationships map.
 *
 *
 * @export
 * @template Attributes
 * @param {Attributes} plainObject
 * @param {string} plainObjectId
 * @param {string} plainObjectType
 * @param {string} relName
 * @param {JSONAPI.RelationshipsWithData} jsonApiRelationshipObject
 * @param {Record<string, JSON.Object>} includedItemsByType
 * @return {*}
 */
export function addRelatedObjectsToSingleObject<Attributes extends PlainObject>(
  plainObject: Attributes,
  plainObjectId: string,
  plainObjectType: string,
  relName: string,
  jsonApiRelationshipObject: JSONAPI.RelationshipsWithData,
  includedItemsByType: Record<string, JSON.Object>,
) {
  let relValue: JSON.Value = null;
  if (isNil(jsonApiRelationshipObject?.data)) return;

  if (isArray(jsonApiRelationshipObject.data)) {
    relValue = map(jsonApiRelationshipObject.data, (relationData) =>
      getIncludedOrRelation(
        relationData,
        plainObjectId,
        plainObjectType,
        plainObject,
        includedItemsByType,
      ),
    );
  } else {
    relValue = getIncludedOrRelation(
      jsonApiRelationshipObject.data,
      plainObjectId,
      plainObjectType,
      plainObject,
      includedItemsByType,
    );
  }
  if (relValue) {
    (plainObject as Record<string, JSON.Value>)[relName] = relValue;
  }
}

function getIncludedOrRelation<Attributes extends PlainObject>(
  relationData: JSONAPI.ResourceIdentifierObject,
  plainObjectId: string,
  plainObjectType: string,
  plainObject: Attributes,
  includedItemsByType: Record<string, JSON.Object>,
): JSON.Value {
  if (
    relationData.id == plainObjectId &&
    relationData.type == plainObjectType
  ) {
    // return the data of item itself and not the object to avoid circular dependencies when referenced in association, e.g. in subtree
    return { ...plainObject };
  } else {
    return includedItemsByType?.[relationData.type]?.[relationData.id];
  }
}

interface ResourceCollectionMetaInfo {
  page_count: number;
  record_count: number;
}
export function metaDataFromJsonApiCollectionResourceDoc(
  doc: JSONAPI.CollectionResourceDoc,
): ResourceCollectionMetaInfo {
  return doc.meta as any as ResourceCollectionMetaInfo;
}
/** Flattens a JSONApi Collection Response Document and returns a plain object that has the associated objects assigned as properties named like the relations.
 *
 *  Does not assign deep includes such as sensors.attributeKeys
 *
 * @export
 * @template ModelInterface
 * @param {JSONAPI.CollectionResourceDoc<string>} resourceCollection
 * @returns {ModelInterface[]} An array of objects that were contained in
 */
export function jsonApiResourceCollectionToFlatObjects<
  ModelInterface extends Record<string, JSON.Value>,
>(
  resourceCollection: JSONAPI.CollectionResourceDoc<string, ModelInterface>,
): ModelInterface[] {
  if (isNil(resourceCollection) || isEmpty(resourceCollection.data)) return [];

  const relationMap: Record<string, JSON.Object> = {};

  const includedItemsWithRelationships = [] as {
    item: JSON.Object;
    type: string;
    relationships: JSONAPI.RelationshipsObject;
  }[];
  each(resourceCollection.included, (includedItem) => {
    const typeMap = defaultTo(relationMap[includedItem.type], {}) as Record<
      string,
      JSON.Object
    >;
    const item = {
      ...includedItem.attributes,
      id: toInteger(includedItem.id),
    } as JSON.Object;

    typeMap[includedItem.id] = item;
    if (!isEmpty(includedItem.relationships)) {
      includedItemsWithRelationships.push({
        item,
        type: includedItem.type,
        relationships: includedItem.relationships,
      });
    }
    relationMap[includedItem.type] = typeMap;
  });

  // after having built the relation map, we can assign the relations of the included items to the objects
  each(includedItemsWithRelationships, (includedItem) => {
    each(includedItem.relationships, (relationshipObject, relName) => {
      addRelatedObjectsToSingleObject(
        includedItem.item,
        includedItem.item.id.toString(),
        includedItem.type,
        relName,
        relationshipObject as JSONAPI.RelationshipsWithData,
        relationMap,
      );
    });
  });

  const plainObjects: Record<string, unknown>[] = map(
    resourceCollection.data,
    (resourceObject) => {
      const plainObj: Record<string, JSON.Value> = {
        ...resourceObject.attributes,
        id: toInteger(resourceObject.id),
      };

      each(resourceObject.relationships, (relationshipObject, relName) => {
        const dataRelationshipObject =
          relationshipObject as JSONAPI.RelationshipsWithData;
        addRelatedObjectsToSingleObject(
          plainObj,
          resourceObject.id,
          resourceObject.type,
          relName,
          dataRelationshipObject,
          relationMap,
        );
      });
      return plainObj;
    },
  );

  return plainObjects as ModelInterface[];
}

/**
 * Generates an array of JSON:API paging parameters.
 *
 * @param pageNumber - The current page number, can be a number or a string. Starts from 1
 * @param pagesize - The size of the page, can be a number or a string.
 * @returns An array of parameters for JSON:API pagination.
 */
export function jsonApiPagingParamsArray(
  pageNumber: number | string,
  pagesize: number | string,
): ParamType[] {
  const params: ParamsType = [];
  if (!isNil(pagesize)) {
    params.push(["page[size]", toString(pagesize)]);
  }

  if (!isNil(pageNumber)) {
    params.push(["page[number]", toString(pageNumber)]);
  }
  return params;
}

export function jsonApiIncludeParamsArray(includes: string[]): ParamType[] {
  if (isEmpty(includes)) return [];
  return [["include", includes.join(",")]];
}

/**
 * Generates an array of parameters for JSONAPI field selection.
 *
 * @template ObjectAttributes - The type representing the attributes of the resource.
 * @param {string} resourceName - The name of the resource for which the fields are being specified.
 * @param {(keyof ObjectAttributes)[]} fields - An array of keys representing the fields to be included in the response.
 * @returns {ParamType[]} An array containing a single parameter tuple for JSON:API field selection.
 */
export function jsonApiFieldsParamsArray<ObjectAttributes>(
  resourceName: string,
  fields: (keyof ObjectAttributes)[],
): ParamType[] {
  if (isNil(fields) || isEmpty(fields)) return [];

  return [[`fields[${resourceName}]`, fields.join(",")]];
}

type SingleFilterValueType = IDType | string | number | boolean | Date | Moment;

type FilterValueType =
  | SingleFilterValueType
  | Array<Exclude<FilterValueType, boolean>>;

export function jsonApiFilterParamsArray(
  attribute: string,
  value: FilterValueType,
): ParamType[] {
  return [[`filter[${attribute}]`, filterValueToJsonApiFilterValue(value)]];
}

export type JsonApiFilterParamName<T> = `filter[${keyof T & string}]`;

/**
 * Converts a filter object into an array of JSONAPI filter parameters.
 *
 * @template T - A type that extends a partial record of keys of type `T` and values of type `FilterValueType`.
 * @param {T} filterObject - The filter object to convert.
 * @returns {Array<[JsonApiFilterParamName<T>, string]>} An array of tuples where the first element is the JSON:API filter parameter name and the second element is the filter value as a string.
 */
export function jsonApiFilterParamsFromFilterObject<
  T extends Partial<Record<keyof T, FilterValueType>>,
>(filterObject: T): Array<[JsonApiFilterParamName<T>, string]> {
  const values = map(
    filterObject,
    (value: FilterValueType, key: keyof T & string) => {
      const v = filterValueToJsonApiFilterValue(value);
      if (isNil(v)) return null as [JsonApiFilterParamName<T>, string];
      const paramName: JsonApiFilterParamName<T> = `filter[${key}]`;
      return [paramName, v] as [JsonApiFilterParamName<T>, string];
    },
  );
  return compact(values);
}

/**
 * Converts a filter value to a JSONAPI filter value string.
 *
 * @param value - The filter value to convert. It can be of various types such as string, array, date, etc.
 * @param allowNil - A boolean flag indicating whether `null` values are allowed. Defaults to `false`.
 * @returns The JSON:API filter value as a string. If `value` is `null` and `allowNil` is `false`, returns `null`.
 */
export function filterValueToJsonApiFilterValue(
  value: FilterValueType,
  allowNil = false,
): string {
  let theString = value;
  if (isNil(value) && !allowNil) return null;

  if (isArray(value)) {
    if (isEmpty(value)) return null;
    theString = value
      .map((v) => filterValueToJsonApiFilterValue(v, true))
      .join(",");
  } else if (isDate(value) || moment.isMoment(value)) {
    theString = value.toISOString();
  } else if (!isString(value)) {
    theString = toString(value);
  }

  return theString as string;
}

/**
 * Converts a filter object into a JSONAPI filter parameters object.
 *
 * This function takes a filter object where the keys are the filter names and the values
 * are the filter values, and converts it into a JSON:API filter parameters object.
 * It removes all Boolean(value) == false values from the object, i.e. null, undefined, 0, 0n, "".
 *
 *
 * @template T - A type that extends a partial record of keys of type `T` and values of type `FilterValueType`.
 * @param {T} filterObject - The filter object to convert.
 * @returns {Partial<Record<JsonApiFilterParamName<T>, string>>} - A partial record where the keys are JSON:API filter parameter names and the values are strings.
 */
export function jsonApiFilterParamsArgumentsFromFilterObject<
  T extends Partial<Record<keyof T, FilterValueType>>,
>(filterObject: T): Partial<Record<JsonApiFilterParamName<T>, string>> {
  const pairs = jsonApiFilterParamsFromFilterObject(filterObject);

  return fromPairs(compact(pairs)) as Partial<
    Record<JsonApiFilterParamName<T>, string>
  >;
}

export type ModelErrors<Attributes> = Partial<
  Record<keyof Attributes | "base", string[]>
>;

/**
 * Retrieves the error message(s) associated with a specific model attribute.
 *
 * @template Attributes - The type representing the model's attributes.
 * @param {ModelErrors<Attributes>} errors - The object containing error messages for the model's attributes.
 * @param {keyof Attributes} attribute - The specific attribute for which to retrieve error messages.
 * @param {string} [fallback=null] - The fallback string to return if no errors are found.
 * @returns {string} - A string containing the error messages for the specified attribute, or the fallback string if no errors are found.
 */
export function modelPropertyError<Attributes>(
  errors: ModelErrors<Attributes>,
  attribute: keyof Attributes,
  fallback: string = null,
): string {
  if (isNil(errors)) return fallback;
  const theErrors = errors[attribute];
  if (isNil(theErrors)) return fallback;
  return join(theErrors, ", ");
}

/**
 * Extracts errors from a JSON:API document and maps them to a model's attributes.
 *
 * @template Attributes - The type of the model's attributes.
 * @param {JSONAPI.DocWithErrors} e - The JSON:API document containing errors.
 * @returns {ModelErrors<Attributes>} - An object where keys are attribute names and values are arrays of error messages.
 */
export function extractErrorsFromJsonApi<Attributes>(
  e: JSONAPI.DocWithErrors,
): ModelErrors<Attributes> {
  const errors = {} as ModelErrors<Attributes>;
  each(e.errors, (e) => {
    const attributePointer = e.source?.pointer as string;
    let attributeName: keyof ModelErrors<Attributes>;
    if (!isNil(attributePointer)) {
      attributeName = last(attributePointer.split("/")) as keyof Attributes;
    } else {
      attributeName = "base";
    }
    const detail = e.title;
    let errs = errors[attributeName];
    if (isNil(errs)) {
      errs = [];
      errors[attributeName] = errs;
    }
    errs.push(detail);
  });
  return errors;
}

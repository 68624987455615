/// <reference types="../../definitions/index" />;

import { snakeCase } from "lodash";

function getResorceTranslationName(resource: string): string {
  return resource
    .split("::")
    .map((s) => snakeCase(s))
    .join("/");
}

/**
 * Return a human readable name for a group
 * @param groupName Name of a group
 */
export function getGroupName(groupName: string): string {
  return I18n.t(`frontend.role_definition_form.group_names.${groupName}`);
}

/**
 * Return a human readable name for a resource
 * @param resource Name of the resource class, e.g., Asset, Sensor, User
 */
export function getResourceName(resource: string): string {
  return I18n.t(
    `activerecord.models.${getResorceTranslationName(resource)}.one`,
  );
}

/**
 * Return a human readable description for a resource
 * @param resource Name of the resource class, e.g., Asset, Sensor, User
 */
export function getResourceDescription(resource: string): string {
  return I18n.t(
    `activerecord.descriptions.${getResorceTranslationName(resource)}`,
    { defaultValue: "" },
  );
}

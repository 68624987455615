import React from "react";
import PropTypes from "prop-types";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import { Grid, TextField } from "@mui/material";

const RepeatDaily = ({
  id,
  daily: { interval },
  handleChange,
  translations,
}) => (
  <Grid item xs={12}>
    <TextField
      label={translateLabel(translations, "repeat.daily.every")}
      id={`${id}-interval`}
      type="number"
      name="repeat.daily.interval"
      aria-label="Repeat daily interval"
      value={interval}
      size="small"
      onChange={numericalFieldHandler(handleChange)}
      InputProps={{
        min: 1,
        max: 365,
        endAdornment: translateLabel(translations, "repeat.daily.days"),
      }}
    />
  </Grid>
);
RepeatDaily.propTypes = {
  id: PropTypes.string.isRequired,
  daily: PropTypes.shape({
    interval: PropTypes.number.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatDaily;

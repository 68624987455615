import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { InfluxJournal, SensorGroup } from "./data/model";
import { ManageSensorDataForm } from "./views/manage_sensor_data_form";

const manageSensorDataRoots: Root[] = [];
export function initializeManageSensorDataForm(): void {
  $('[data-toggle="manage-sensor-data-form"]').each((index, element) => {
    const assetId = $(element).data("asset-id") as string;
    const sensorGroups = $(element).data("sensor-groups") as SensorGroup[];
    const influxJournals = $(element).data(
      "influx-journals",
    ) as InfluxJournal[];
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <ManageSensorDataForm
          assetId={assetId}
          sensorGroups={sensorGroups}
          influxJournals={influxJournals}
        />
      </AppRoot>,
    );
    manageSensorDataRoots.push(root);
  });
}

export function destroyManageSensorDataForm(): void {
  manageSensorDataRoots.forEach((root) => {
    root.unmount();
  });

  manageSensorDataRoots.length = 0;
}

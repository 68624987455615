import { defaultTo, isEmpty, isNaN, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { WidgetController } from "../../controller/widget_controller";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { SensorValueType } from "../../models/sensor";
import { SensorValueRange } from "../../models/sensor_value_range";
import { logger } from "../../utils/logger";
import {
  getStatusLabel,
  getValueRangeForValue,
} from "../../utils/status_helper";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { ValueDisplay } from "../common/value_display";
import { SensorValueWidgetProps } from "./sensor_value_widget.types";
import { WidgetBox } from "./widget_box";

import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  getIconNameForMeasurementType,
  getIconNameForSensorType,
} from "../../utils/sensor_icons";
import {
  registerSensorUpdates,
  unregisterSensorUpdatesForSubscriberIds,
} from "../../utils/sensor_updates";
import {
  SensorValueDisplayMode,
  SensorValueWidgetConfigSerialized,
} from "../../widgets/sensor_value_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { loadSensorData } from "./utils/load_sensor_data";

export const defaultPrecision = 2;

/** builds the sensor link URL for the given sensor
 *
 *
 * @param {(SensorJSONAPIAttributes | null)} [sensor=null]
 * @param {number} [assetID]
 * @return {*}  {(string | null)}
 */
const getSensorLink = (
  sensor: SensorJSONAPIAttributes | null = null,
  assetID?: number,
): string | null => {
  if (isNil(sensor)) return null;
  const theAssetId = assetID ?? sensor?.asset_id;
  if (isNil(theAssetId)) return null;
  const link = sensorUrl(
    {
      id: sensor.id,
      assetId: assetID,
    },
    "html",
  );

  return link;
};

export const SensorValueWidget: React.FunctionComponent<
  SensorValueWidgetProps
> = ({
  mode = "rows",
  useValueRange = false,
  ...props
}: SensorValueWidgetProps) => {
  const [sensor, setSensor] = useState(props.sensor);
  const [title, setTitle] = useState(props.sensor?.name || props.title);
  const [value, setValue] = useState<SensorValueType>(
    props.value || props.sensor?.last_value?.value,
  );
  const [unit, setUnit] = useState(
    props.unit ||
      props.sensor?.display_unit ||
      props.sensor?.attribute_key_unit,
  );
  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [timeScopeName, setTimeScopeName] = useState(props.timeScopeName);
  const [status, setStatus] = useState(props.status);
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);
  const [loading, setLoading] = useState(false);
  const [iconName, setIconName] = useState(props.iconName);
  const [valueRanges, setValueRanges] = useState(
    props.sensor?.value_ranges || props.valueRanges,
  );
  const [currentValueRange, setCurrentValueRange] =
    useState<SensorValueRange | null>(
      getValueRangeForValue(value as number, valueRanges),
    );

  const getSensorId = (): number => {
    return (props.sensor?.id as number) || (props.sensorId as number);
  };

  // Update title when sensor name or title changes
  useEffect(() => {
    setTitle(props.title || sensor?.name);
  }, [sensor?.name, props.title]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    const newValue = props.value || sensor?.last_value?.value;
    if (newValue !== value) {
      setValue(newValue);
    }
  }, [props.value, sensor?.last_value?.value]);

  // Update sensor data when sensor unit changes
  useEffect(() => {
    setUnit(props.unit || sensor?.display_unit || sensor?.attribute_key_unit);
  }, [props.unit, sensor?.display_unit, sensor?.attribute_key_unit]);

  // Update sensor data when sensor timestamp changes
  useEffect(() => {
    if (props.timestamp !== timestamp) {
      setTimestamp(props.timestamp);
    }
  }, [props.timestamp]);

  // Update sensor data when sensor status changes
  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  // Update iconName when iconName prop changes
  useEffect(() => {
    if (props.iconName !== iconName) {
      setIconName(props.iconName);
    }
  }, [props.iconName]);

  // Update sensor data when sensor link URL changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  useEffect(() => {
    const sensorId = props.sensorId;
    const timeRange = props.timeRange;
    const fallbackToLastValue = props.fallbackToLastValue || false;

    if (!sensorId || isNil(sensorId) || isNaN(sensorId)) {
      return;
    }

    setLoading(true);

    loadSensorData(sensorId, timeRange, fallbackToLastValue)
      .then((result) => {
        if (result) {
          setSensor(result.sensor);
          setValue(result.value);
          setStatus(result.status);
          setTimestamp(result.timestamp);
        }
      })
      .catch((e) => {
        logger.error("Error loading sensor data", e);
      })
      .finally(() => setLoading(false));
  }, [props.sensorId, props.timeRange, props.fallbackToLastValue]);

  useEffect(() => {
    const value = isNil(sensor?.last_value) ? null : sensor?.last_value.value;

    const link =
      props.noLinks || mode == "inline"
        ? null
        : getSensorLink(
            sensor,
            defaultTo(props.assetId, sensor?.asset_id) as number,
          );

    setValue(value);
    setTimestamp(
      isNil(sensor?.last_value) ? null : moment(sensor.last_value.timestamp),
    );
    setTitle(isEmpty(props.title) ? sensor?.name : (props.title as string));
    setUnit(
      isNil(sensor?.display_unit)
        ? sensor?.attribute_key_unit
        : sensor?.display_unit,
    );

    setTitleLinkUrl(link);
    setContentLinkUrl(link);
    setValueRanges(sensor?.value_ranges);
  }, [sensor, props.title, props.iconName, props.assetId]);

  // find out the current value range for the value if neccessary
  useEffect(() => {
    if (isNil(value) || isEmpty(valueRanges) || !useValueRange) {
      if (!isNil(currentValueRange)) {
        // no value no range
        setCurrentValueRange(null);
      }

      return;
    }
    const newCurrentValueRange = getValueRangeForValue(
      value as number,
      valueRanges,
    );
    setCurrentValueRange(newCurrentValueRange);
  }, [valueRanges, value, useValueRange]);

  const [noValueRangeIconName, setNoValueRangeIconName] = useState<
    string | null
  >(() => {
    let icon = props.iconName;
    if (!isEmpty(icon)) {
      return icon;
    }
    if (sensor?.icon) {
      return sensor.icon;
    } else if (sensor?.sensor_type_name) {
      icon = getIconNameForSensorType(sensor?.sensor_type_name);
    }
    if (!icon) {
      icon = getIconNameForMeasurementType(sensor?.measurement_type);
    }
    return icon;
  });

  useEffect(() => {
    let icon = props.iconName;
    if (!isEmpty(icon)) {
      setNoValueRangeIconName(icon);
      return;
    }

    if (sensor?.icon) {
      setNoValueRangeIconName(sensor.icon);
      return;
    }

    if (sensor?.sensor_type_name) {
      icon = getIconNameForSensorType(sensor?.sensor_type_name);
    }
    if (!icon) {
      icon = getIconNameForMeasurementType(sensor?.measurement_type);
    }
    setNoValueRangeIconName(icon);
  }, [
    sensor?.icon,
    sensor?.sensor_type_name,
    sensor?.measurement_type,
    props.iconName,
  ]);

  // apply value range settings to the value if neccessary
  useEffect(() => {
    if (isNil(currentValueRange) || !useValueRange) {
      if (iconName !== noValueRangeIconName) {
        setIconName(noValueRangeIconName);
      }
      return;
    }

    setStatus(currentValueRange.status);
    let newIconName = props.iconName;
    if (!isNil(currentValueRange?.icon_name)) {
      newIconName = currentValueRange.icon_name;
    }
    setIconName(currentValueRange.icon_name);
  }, [currentValueRange, noValueRangeIconName]);
  // handle subscriptions for sensor updates after sensor has been loaded
  useEffect(() => {
    if (!sensor?.id) {
      return;
    }
    if (WidgetController.getInstance()) {
      const sensorId: number = sensor.id as number;
      let subscriberIds: number[];
      if (
        props.updateEnabled &&
        // consider time range if provided. Subscribe if current time is within the time range
        (!props.timeRange || props.timeRange?.contains(moment()))
      ) {
        subscriberIds = registerSensorUpdates({ handleSensorValueUpdate }, [
          sensorId,
        ]);
      }
      // Unregister sensor updates when the component is unmounted or when the data update is disabled and was previously enabled
      return () => {
        if (subscriberIds) {
          unregisterSensorUpdatesForSubscriberIds(subscriberIds, [sensorId]);
        }
      };
    }
  }, [props.updateEnabled, sensor?.id]);

  // callback for handling sensor value updates
  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      if (isNumber(value) && !isNil(unit) && !isNil(props.unit)) {
        value = convertToUnit(value, unit, props.unit);
      }
      setValue(value);
      setTimestamp(time);
    },
    [valueRanges, props.unit],
  );

  const valRange: SensorValueRange | null = useValueRange
    ? currentValueRange
    : null;
  const valueDisplay = (
    <>
      <ValueDisplay
        mode={mode}
        value={value}
        unit={unit}
        sensorType={props.sensorType ?? sensor?.sensor_type_name}
        measurementType={props.measurementType ?? sensor?.measurement_type}
        iconName={defaultTo(valRange?.icon_name, iconName)}
        iconSize={props.iconSize}
        hideValue={props.hideValue}
        precision={props.precision ?? sensor?.precision}
        timestamp={getTimeString(timeScopeName, timestamp)}
        shadowText={props.textShadow}
        color={valRange?.color}
        status={getStatusLabel(status, useValueRange ? valRange?.name : null)}
        vertical={props.vertical}
      />
    </>
  );

  if (mode === "inline") {
    return <Box className="sensor-value-sm">{valueDisplay}</Box>;
  } else {
    return (
      <WidgetBox
        {...props}
        loading={loading}
        title={title}
        titleLinkUrl={titleLinkUrl}
        contentLinkUrl={contentLinkUrl}
      >
        {valueDisplay}
      </WidgetBox>
    );
  }
};

function serializedConfigToProps(
  config: SensorValueWidgetConfigSerialized,
): SensorValueWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    sensorId: config.sensor_id,
    assetId: config.asset_id,
    sensorType: config.sensor_type,
    measurementType: config.measurement_type,
    iconName: config.icon_name,
    iconSize: config.icon_size,
    status: config.value_status,
    value: config.value as number,
    ignoreTimeScope: config.ignore_time_scope,
    fallbackToLastValue: defaultTo(config.fallback_to_last_value, true),
    hideValue: defaultTo(config.hide_value, false),
    unit: defaultTo(config.display_unit, config.unit),
    precision: defaultTo(config.precision, defaultPrecision),
    timestamp: isNil(config.timestamp)
      ? null
      : moment(config.timestamp).local(),
    timeScopeName: config.timescope,
    mode: defaultTo<SensorValueDisplayMode>(config.mode, "inline"),
    vertical: defaultTo(config.vertical, false),
    textShadow: defaultTo(config.text_shadow, false),
    updateEnabled: !defaultTo(config.disable_update, false),
    valueRanges: config.value_ranges,
    useValueRange: defaultTo(config.use_value_range, false),
  } as SensorValueWidgetProps);
}

export const SensorValueWidgetDefinition: SialogicWidgetDefinition<
  typeof SensorValueWidget,
  typeof serializedConfigToProps
> = {
  Component: SensorValueWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import { Add, Close, Folder, NavigateBefore } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import {
  clone,
  defaultTo,
  each,
  filter,
  groupBy,
  isEmpty,
  isNil,
  keys,
  map,
  union,
  uniq,
  upperCase,
} from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { FileAttachmentJSONObject } from "../../json_api/file_attachment";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { notifyAirbrake } from "../../utils/airbrake_error_handler";
import { loadDataFromUrl, sendData } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { success } from "../../utils/toasts";
import {
  apiFileAttachmentPath,
  apiFileAttachmentsPath,
} from "../../utils/urls/file_attachment_urls";
import { IDType } from "../../utils/urls/url_utils";
import { AppRoot } from "../common/app_root";
import { AttachmentList } from "../common/attachment_list";
import { FilePreviewModal } from "../common/file_preview_modal";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { LoadingWrapper } from "../common/loading_wrapper";
import { FileAttachmentUpload } from "./file_attachment_upload";
import { useQuery } from "@tanstack/react-query";
import { DnDItemTypes } from "../common/drag_and_drop";
import { useDrop } from "react-dnd";
import { buildJsonApiSubmitData } from "../../utils/jsonapi_form_tools";
import { SialogicDialog } from "../common/sialogic_dialog";

interface FileAttachmentListProps {
  itemId: IDType;
  itemType: string;
  groups?: string[];
  allowUpload?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  // hides the BottomButtonArea
  hideButtons?: boolean;
}

const UNGROUPED_GROUP_NAME = "other";

async function updateFileAttachmentGroup(
  fileAttachment: FileAttachmentJSONObject,
  newGroup: string,
) {
  const newFa = { ...fileAttachment, group: newGroup };
  const { submitData, mode } = buildJsonApiSubmitData<FileAttachmentJSONObject>(
    newFa,
    "file_attachments",
    ["group"],
  );
  let fileAttachmentResponse;

  fileAttachmentResponse = await sendData<
    JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>,
    JSONAPI.SingleResourceDoc<string, FileAttachmentJSONObject>
  >(
    apiFileAttachmentPath(fileAttachment.id),
    submitData,
    "PATCH",
    "application/vnd.api+json",
  );

  return newFa;
}

export const FileAttachmentList: React.FunctionComponent<
  FileAttachmentListProps
> = (props) => {
  const [uploadedFileNumber, setUploadedFileNumber] = React.useState(0);
  const [previewFile, setPreviewFile] =
    React.useState<FileAttachmentJSONObject>(null);
  const [editFile, setEditFile] =
    React.useState<FileAttachmentJSONObject>(null);
  const [fileAttachments, setFileAttachments] = React.useState<
    FileAttachmentJSONObject[]
  >([]);

  const [groupedFileAttachments, setGroupedFileAttachments] = React.useState<
    [string, FileAttachmentJSONObject[]][]
  >([]);

  const [uploadDialogOpen, setUploadDialogOpen] =
    React.useState<boolean>(false);
  const [uploadDialogGroup, setUploadDialogGroup] =
    React.useState<string>(null);

  const updateFileAttachments = React.useCallback(
    (fileAttachmentObjects: FileAttachmentJSONObject[]) => {
      setFileAttachments(fileAttachmentObjects);
      const ungrouped = filter(fileAttachmentObjects, (fa) => isNil(fa.group));
      const withGroups = filter(
        fileAttachmentObjects,
        (fa) => !isNil(fa.group),
      );

      const existingGroupNames = map(withGroups, (fa) => fa.group);

      const grouped = groupBy(
        fileAttachmentObjects,
        (fileAttachmentObject) =>
          fileAttachmentObject.group || UNGROUPED_GROUP_NAME,
      );
      const newGroupedFileAttachments = [] as [
        string,
        FileAttachmentJSONObject[],
      ][];

      const groupNames = uniq(union(keys(grouped), props.groups)).sort();
      each(groupNames, (groupName) => {
        newGroupedFileAttachments.push([groupName, grouped[groupName]]);
      });

      setGroupedFileAttachments(newGroupedFileAttachments);
    },
    [],
  );

  const fileAttachmentsQuery = useQuery({
    queryKey: ["fileAttachments", props.itemId, props.itemType],
    queryFn: async () => {
      const fileAttachments = await loadDataFromUrl<
        JSONAPI.CollectionResourceDoc<string, FileAttachmentJSONObject>
      >(apiFileAttachmentsPath(props.itemId, props.itemType));
      const fileAttachmentObjects =
        jsonApiResourceCollectionToFlatObjects<FileAttachmentJSONObject>(
          fileAttachments,
        );
      return fileAttachmentObjects;
    },
    enabled: !isNil(props.itemId) && !isNil(props.itemType),
  });

  React.useEffect(() => {
    if (fileAttachmentsQuery.isSuccess) {
      const fileAttachments = fileAttachmentsQuery.data;
      updateFileAttachments(fileAttachments);
    }
  }, [fileAttachmentsQuery.data]);

  const handleFileDelete = React.useCallback(
    async (file: FileAttachmentJSONObject) => {
      if (isNil(file)) {
        return;
      }

      try {
        if (
          confirm(
            I18n.t(
              "frontend.file_attachments.file_attachment_list.confirm_delete",
            ),
          )
        ) {
          const result = await sendData<string, string>(
            apiFileAttachmentPath(file.id),
            null,
            "DELETE",
            "application/vnd.api+json",
          );

          const newFileAttachments = filter(
            fileAttachments,
            (fa) => fa.id != file.id,
          );
          updateFileAttachments(newFileAttachments);
        }
      } catch (error) {
        // something went wrong, report error
        void notifyAirbrake(error as Error);
        void toasts.error(I18n.t("frontend.error_deleting_file"));
      }
    },
    [fileAttachments],
  );

  return (
    <Grid container spacing={3}>
      <LoadingWrapper
        loading={fileAttachmentsQuery.isLoading}
        loadingElements={map(props.groups, (g, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardHeader
                title={I18n.t(
                  `activerecord.attributes.file_attachment.groups.${defaultTo(
                    g,
                    "general",
                  )}`,
                )}
              />
              <CardContent>
                <Skeleton height={300} width="100%" />
              </CardContent>
            </Card>
          </Grid>
        ))}
        renderContent={() =>
          map(groupedFileAttachments, ([group, attachments]) => (
            <FileAttachmentGroup
              group={group}
              key={group}
              attachments={attachments}
              onShow={(file) => {
                setPreviewFile(file);
              }}
              allowUpload={props.allowUpload}
              onEdit={
                props.allowEdit
                  ? (file) => {
                      setEditFile(file);
                      setUploadDialogGroup(file.group);
                      setUploadDialogOpen(true);
                    }
                  : null
              }
              onDelete={
                props.allowDelete ? (file) => void handleFileDelete(file) : null
              }
              onGroupChange={
                props.allowEdit
                  ? (file, newGroup) => {
                      void updateFileAttachmentGroup(file, newGroup)
                        .then((newFile) => {
                          const newFileAttachments = clone(fileAttachments);
                          const index = newFileAttachments.findIndex(
                            (fa) => fa.id === newFile.id,
                          );

                          if (index >= 0) {
                            newFileAttachments[index] = newFile;
                            updateFileAttachments(newFileAttachments);
                          }
                        })
                        .catch((error) => {
                          void notifyAirbrake(error as Error);
                          void success(
                            I18n.t("frontend.error"),
                            I18n.t(
                              "frontend.file_attachments.file_attachment_list.error_saving",
                            ),
                          );
                        });
                    }
                  : null
              }
              onRequestNewUpload={(groups) => {
                setUploadDialogGroup(group);
                setUploadDialogOpen(true);
              }}
            />
          ))
        }
      >
        <SialogicDialog
          open={uploadDialogOpen}
          onClose={() => {
            setUploadDialogOpen(false);
          }}
        >
          <FileAttachmentUpload
            itemId={props.itemId}
            key={uploadedFileNumber}
            itemType={props.itemType}
            group={uploadDialogGroup}
            fileAttachment={editFile}
            onUploadFileAttachmentCreated={(uploadedAttachment) => {
              setUploadDialogOpen(false);
              const att = isNil(fileAttachments) ? [] : clone(fileAttachments);
              att.push(uploadedAttachment);
              updateFileAttachments(att);

              setUploadedFileNumber(uploadedFileNumber + 1);
              void success(
                I18n.t(
                  "frontend.file_attachments.file_attachment_list.file_added",
                ),
                I18n.t(
                  "frontend.file_attachments.file_attachment_list.file_added_long",
                  { filename: uploadedAttachment.title },
                ),
              );
            }}
            onUploadFileAttachmentUpdated={(updatedAttachment) => {
              const index = fileAttachments?.findIndex(
                (fa) => fa.id === updatedAttachment.id,
              );

              if (!isNil(index) && index >= 0) {
                const att = clone(fileAttachments);
                att[index] = updatedAttachment;
                updateFileAttachments(att);
              }
              setEditFile(null);
              setUploadDialogOpen(false);
            }}
            requestCancel={() => {
              setEditFile(null);

              setUploadDialogOpen(false);
            }}
          />
        </SialogicDialog>
        <FilePreviewModal
          isOpen={!isNil(previewFile)}
          contentType={previewFile?.content_type}
          onClose={() => {
            setPreviewFile(null);
          }}
          additionalInfo={[
            {
              title: I18n.t("activerecord.attributes.file_attachment.key"),
              value: previewFile?.key || "---",
            },
          ]}
          title={previewFile?.title}
          url={previewFile?.url}
          fileName={defaultTo(previewFile?.title, previewFile?.filename)}
        />
        {!props.hideButtons ? (
          <FixedBottomArea id="fixed-bottom-area">
            <FloatingButtons
              cancelIcon={<NavigateBefore />}
              onCancel={() => {
                redirectTo("back");
              }}
              showScrollToTopBtn={true}
            />
          </FixedBottomArea>
        ) : null}
      </LoadingWrapper>
    </Grid>
  );
};

const fileAttachmentListRoots: Root[] = [];
export function initFileAttachmentList(
  selector: JQuery = $('[data-toggle="file-attachment-list"]'),
) {
  selector.each((index, e) => {
    const jqE = $(e);
    const itemType = jqE.data("item-type") as string;
    const itemID = jqE.data("item-id") as string;
    const allowDelete = jqE.data("allow-delete") as boolean;
    const allowEdit = jqE.data("allow-edit") as boolean;
    const groups = jqE.data("groups") as string[];
    const allowUpload = jqE.data("allow-upload") as boolean;
    const hideButtons = jqE.data("hide-buttons") as boolean;
    const root = createRoot(e);
    fileAttachmentListRoots.push(root);
    root.render(
      <AppRoot>
        <FileAttachmentList
          itemId={itemID}
          itemType={itemType}
          groups={groups}
          hideButtons={hideButtons}
          allowUpload={allowUpload}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </AppRoot>,
    );
  });
}

export function destroyFileAttachmentList(
  selector: JQuery = $('[data-toggle="file-attachment-list"]'),
) {
  fileAttachmentListRoots.forEach((root) => {
    root.unmount();
  });
  fileAttachmentListRoots.length = 0;
}

interface FileAttachmentGroupProps {
  group: string;
  attachments: FileAttachmentJSONObject[];
  allowUpload?: boolean;
  onShow?(file: FileAttachmentJSONObject): void;
  onDelete?(file: FileAttachmentJSONObject): void;
  onDownload?(file: FileAttachmentJSONObject): void;
  onEdit?(file: FileAttachmentJSONObject): void;

  onRequestNewUpload?(group: string): void;
  onGroupChange?(attachment: FileAttachmentJSONObject, newGroup: string): void;
}

const FileAttachmentGroup: React.FunctionComponent<
  FileAttachmentGroupProps
> = ({
  group,
  attachments,
  allowUpload,
  onShow,
  onEdit,
  onDelete,
  onDownload,
  onRequestNewUpload: onRequestUpload,
  onGroupChange,
}) => {
  const [{ canDrop }, drop] = useDrop<
    FileAttachmentJSONObject,
    { type: string; group: string },
    { isOver: boolean; canDrop: boolean }
  >(
    () => ({
      accept: DnDItemTypes.FILE_ATTACHMENT,
      drop(attachment, monitor) {
        if (attachment && attachment.group !== group && onGroupChange) {
          onGroupChange(attachment, group);
        }

        return {
          type: "FileAttachmentGroup",
          group,
        };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [group],
  );
  return (
    <Grid item xs={12} key={group}>
      <Card
        ref={onGroupChange ? drop : null}
        style={{ backgroundColor: canDrop ? "#dddddd" : null }}
      >
        <CardHeader
          title={
            <>
              {upperCase(
                I18n.t(
                  `activerecord.attributes.file_attachment.groups.${defaultTo(
                    group,
                    "general",
                  )}`,
                  { defaultValue: group },
                ),
              )}
            </>
          }
          avatar={<Folder color="primary" />}
        />
        <CardContent>
          {isEmpty(attachments) ? (
            <Typography variant="body2" align="center">
              {I18n.t(
                "frontend.file_attachments.file_attachment_list.no_attachments",
              )}
            </Typography>
          ) : (
            <AttachmentList
              enableAttachmentDrag={onGroupChange ? true : false}
              attachments={attachments}
              onShow={onShow}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </CardContent>
        <CardActions>
          {allowUpload !== true ? null : (
            <>
              <Grid
                className="mt-3 mb-3"
                spacing={3}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => onRequestUpload(group)}
                >
                  {I18n.t(
                    "frontend.file_attachments.file_attachment_list.add_file",
                  )}
                </Button>
              </Grid>
            </>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

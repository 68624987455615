import { defaultTo, isNil, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { SensorEventSubscriber } from "../../channels/sensor_data_channel";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { WeatherWidgetConfigSerialized } from "../../widgets/weather_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { WeatherDisplay } from "../weather/weather_display";
import { WeatherWidgetProps, WeatherWidgetState } from "./weather_widget.types";
import { WidgetBox } from "./widget_box";

export class WeatherWidget
  extends React.Component<WeatherWidgetProps, WeatherWidgetState>
  implements SensorEventSubscriber
{
  static defaultProps: Partial<WeatherWidgetProps> = {
    dataUpdateEnabled: true,
    encloseInWidgetBox: true,
  };

  // TODO implement
  static serializedConfigToProps(
    config: WeatherWidgetConfigSerialized,
  ): WeatherWidgetProps {
    return merge(widgetBoxPropsFromSerializedConfig(config), {
      lat: config.lat,
      lon: config.lon,
      apiKey: config.api_key,
    } as WeatherWidgetProps);
  }

  constructor(props: WeatherWidgetProps) {
    super(props);
    this.state = {
      dataUpdateEnabled: props.dataUpdateEnabled,
      title: props.title,
      titleLinkUrl: props.titleLinkUrl,
      contentLinkUrl: props.contentLinkUrl,
    };
  }

  componentDidMount(): void {
    // initialize component, e.g. some loading from API
  }

  componentWillUnmount() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      // register listeners to widget controller
      // for sensors :
      /// WidgetController.getInstance().sensorDataChannel.removeEventListener(this, this.props.sensorId);
    }
  }

  componentDidUpdate(oldProps: WeatherWidgetProps): void {
    if (this.props.dataUpdateEnabled !== oldProps.dataUpdateEnabled) {
      //this.toggleSensorUpdates(this.props);
    }
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void {
    /** do something widget specific with the new sensor data. Remove method if not required */
  }

  render(): React.ReactNode {
    // implement display content here ...
    const content = (
      <WeatherDisplay
        lat={this.props.lat}
        lon={this.props.lon}
        apiKey={this.props.apiKey}
      ></WeatherDisplay>
    );

    return (
      <>
        {!this.props.encloseInWidgetBox ? (
          <>{content}</>
        ) : (
          <WidgetBox
            {...this.props}
            title={defaultTo(
              this.state.title,
              I18n.t("frontend.widgets.weather_widget.heading"),
            )}
            titleLinkUrl={this.state.titleLinkUrl}
            contentLinkUrl={this.state.contentLinkUrl}
          >
            {content}
          </WidgetBox>
        )}
      </>
    );
  }
}

import { each, isEmpty, replace } from "lodash";
import { ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP } from "../components/asset_template_form/models/models";
import { re } from "mathjs";

const MAX_REPLACEMENTS = 100;
export function replaceTemplate(
  string: string,
  replacements: Record<string, string>,
): string {
  if (isEmpty(string) || isEmpty(replacements)) {
    return string;
  }
  let replacementCount = 0;

  const notFoundReplacements = [];
  // there are still problems with infinite loops if there is no replacement
  while (
    !isEmpty(string.match(ASSET_TEMPLATE_REPLACEMENT_VARIABLE_REGEXP)) &&
    !(replacementCount > MAX_REPLACEMENTS)
  ) {
    each(replacements, (replacement, template) => {
      const newString = replace(string, template, replacement);
      if (newString !== string) {
        string = newString;
      } else {
        notFoundReplacements.push(template);
        console.log("No replacement found");
      }
    });
    replacementCount += 1;
  }
  console.log(replacementCount);
  return string;
}

import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../../components/common/app_root";
import { ResourcePermission } from "../../models/resource_permission";
import { MqttUsersIndexPage } from "./index_page";
import { MqttUsersEditPage } from "./edit_page";

const mqttUsersIndexRoots: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeMqttUsersIndexPage(): void {
  $('[data-toggle="mqtt-users-index"]').each((index, element) => {
    const userId = $(element).data("user-id") as string;
    const assetId = $(element).data("asset-id") as string;
    const deviceId = $(element).data("device-id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    mqttUsersIndexRoots.push(root);
    root.render(
      <AppRoot>
        <MqttUsersIndexPage
          userId={userId}
          assetId={assetId}
          deviceId={deviceId}
          permissions={permissions}
        />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyMqttUserIndexPage(): void {
  mqttUsersIndexRoots.forEach((root) => {
    root.unmount();
  });
  mqttUsersIndexRoots.length = 0;
}

const mqttUsersEditRoots: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeMqttUsersEditPage(): void {
  $('[data-toggle="mqtt-users-edit"]').each((index, element) => {
    const id = $(element).data("id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    mqttUsersIndexRoots.push(root);
    root.render(
      <AppRoot>
        <MqttUsersEditPage id={id} permissions={permissions} />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyMqttUserEditPage(): void {
  mqttUsersEditRoots.forEach((root) => {
    root.unmount();
  });
  mqttUsersEditRoots.length = 0;
}

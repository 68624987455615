import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { defaultTo } from "lodash";
import * as React from "react";
import { IBox, IBoxContent, IBoxTitle } from "./ibox";
import { LoadingWrapper } from "./loading_wrapper";

export interface AssignmentFormProps {
  ibox: boolean;
  loading: boolean;
  submitEnabled: boolean;
  onSubmitClick: () => void;
  onCancelClick: () => void;
  heading: string;
  iboxTitle?: string;
}
export const AssignmentForm: React.FunctionComponent<
  React.PropsWithChildren<AssignmentFormProps>
> = (props: React.PropsWithChildren<AssignmentFormProps>) => {
  const content = (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <LoadingWrapper size={"3x"} loading={props.loading}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h6">{props.heading}</Typography>
            </Grid>

            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </LoadingWrapper>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonGroup>
              <Button
                onClick={() => props.onCancelClick()}
                variant="outlined"
                color="secondary"
              >
                {I18n.t("frontend.cancel")}
              </Button>
              <Button
                onClick={() => props.onSubmitClick()}
                disabled={!props.submitEnabled}
                variant="outlined"
                color="primary"
              >
                {I18n.t("frontend.ok")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (props.ibox) {
    return (
      <IBox>
        <IBoxTitle>
          <Typography variant="h5">
            {defaultTo(
              props.iboxTitle,
              I18n.t("frontend.common.assignment_form.ibox_title"),
            )}
          </Typography>
        </IBoxTitle>
        <IBoxContent>{content}</IBoxContent>
      </IBox>
    );
  } else {
    return content;
  }
};

/// <reference types="../../../definitions/index" />;

import { Grid, Switch, TextField, Typography } from "@mui/material";
import { isEmpty, isNil, map, toInteger } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import {
  AssetTemplateConfig,
  AssetTemplateConfigsByAssetTemplate,
} from "../models/models";
import { AssetTemplateItem } from "./asset_template_item";

interface AssetTemplateItemGroupProperties {
  enabled?: boolean;
  readonly?: boolean;

  // one config Array by AssetTemplate - first array level
  // and asset template config instances - child assets for one type to create
  assetTemplateConfigs: AssetTemplateConfigsByAssetTemplate;
  parentTemplate?: AssetTemplateConfig;

  assetTemplateConfigPath: Array<number | string>;

  onChangeQuantity(path: Array<number | string>, quantity: number): void;
  onChangeEnabled(path: Array<number | string>, enabled: boolean): void;
  onChangeAssetTemplateConfig(
    path: Array<number | string>,
    atc: AssetTemplateConfig,
  ): void;
  onDeleteAssetTemplateConfig?(
    path: Array<number | string>,
    atc: AssetTemplateConfig,
  ): void;
}

export const AssetTemplateItemGroup: FunctionComponent<
  AssetTemplateItemGroupProperties
> = (props) => {
  const path = props.assetTemplateConfigPath;
  return (
    <Grid container spacing={2}>
      {map(
        props.assetTemplateConfigs,
        (assetTemplateConfigsForTemplate, assetTemplateIndex) => {
          return (
            <Grid item xs={12} key={`${assetTemplateIndex}`}>
              <IBox>
                <IBoxTitle
                  tools={
                    assetTemplateConfigsForTemplate.maxCount < 2 ? null : (
                      <TextField
                        //InputProps={{ endAdornment: <InputAdornment position="end">{props.assetTemplate.asset_type.name}</InputAdornment> }}
                        inputProps={{
                          type: "number",
                          max: assetTemplateConfigsForTemplate.maxCount,
                          min: assetTemplateConfigsForTemplate.minCount,
                        }}
                        disabled={!props.enabled || props.readonly}
                        value={assetTemplateConfigsForTemplate.configs.length}
                        label={I18n.t(
                          "frontend.asset_template_item_group.item_count",
                        )}
                        size="small"
                        onChange={(ele) => {
                          ele.stopPropagation();
                          props.onChangeQuantity(
                            props.assetTemplateConfigPath.concat([
                              assetTemplateIndex,
                            ]),
                            toInteger(ele.target.value),
                          );
                        }}
                      />
                    )
                  }
                >
                  {assetTemplateConfigsForTemplate.minCount !== 0 ? null : (
                    <Switch
                      title={I18n.t(
                        "frontend.asset_template_item_group.enabled",
                      )}
                      checked={assetTemplateConfigsForTemplate.enabled}
                      size="small"
                      disabled={!props.enabled || props.readonly}
                      onChange={(el) => {
                        props.onChangeEnabled(
                          props.assetTemplateConfigPath.concat([
                            assetTemplateIndex,
                          ]),
                          el.target.checked,
                        );
                      }}
                      color="primary"
                    />
                  )}
                  <Typography variant="h5">
                    {
                      assetTemplateConfigsForTemplate.assetTemplate.asset_type
                        ?.name
                    }
                  </Typography>
                </IBoxTitle>
                <IBoxContent>
                  <Grid container spacing={2}>
                    {map(
                      assetTemplateConfigsForTemplate.configs,
                      (atc, assetTemplateConfigIndex) => (
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={2}
                          key={`atc${assetTemplateConfigIndex}`}
                          className={`${
                            assetTemplateConfigIndex !=
                            assetTemplateConfigsForTemplate.configs.length - 1
                              ? "border-bottom"
                              : ""
                          } mt-1`}
                        >
                          <Grid item xs={1} className="text-center">
                            {assetTemplateConfigsForTemplate.minCount === 1 &&
                            assetTemplateConfigsForTemplate.maxCount === 1
                              ? null
                              : assetTemplateConfigIndex + 1}
                          </Grid>
                          <Grid item xs={11} className="mb-2">
                            <AssetTemplateItem
                              assetIndex={assetTemplateConfigIndex}
                              index={assetTemplateConfigIndex}
                              readonly={props.readonly}
                              assetTemplateConfig={atc}
                              parentTemplate={props.parentTemplate}
                              counters={atc.counters}
                              onDelete={
                                isNil(props.onDeleteAssetTemplateConfig)
                                  ? null
                                  : (atcToDelete) =>
                                      props.onDeleteAssetTemplateConfig(
                                        path.concat([
                                          assetTemplateIndex,
                                          "configs",
                                          assetTemplateConfigIndex,
                                        ]),
                                        atcToDelete,
                                      )
                              }
                              onChange={(updatedAtc) =>
                                props.onChangeAssetTemplateConfig(
                                  path.concat([
                                    assetTemplateIndex,
                                    "configs",
                                    assetTemplateConfigIndex,
                                  ]),
                                  updatedAtc,
                                )
                              }
                            />
                          </Grid>

                          {isNil(atc.children) ||
                          isEmpty(atc.children) ? null : (
                            <Grid item xs={12} className="mt-3 ml-3">
                              <AssetTemplateItemGroup
                                enabled={atc.enabled}
                                parentTemplate={atc}
                                readonly={props.readonly}
                                assetTemplateConfigs={atc.children}
                                assetTemplateConfigPath={path.concat([
                                  assetTemplateIndex,
                                  "configs",
                                  assetTemplateConfigIndex,
                                  "children",
                                ])}
                                onChangeEnabled={(path, enabled) =>
                                  props.onChangeEnabled(path, enabled)
                                }
                                onChangeQuantity={(path, qty) =>
                                  props.onChangeQuantity(path, qty)
                                }
                                onDeleteAssetTemplateConfig={(path, atc) => {
                                  props.onDeleteAssetTemplateConfig(path, atc);
                                }}
                                onChangeAssetTemplateConfig={(path, atc) => {
                                  props.onChangeAssetTemplateConfig(path, atc);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      ),
                    )}
                  </Grid>
                </IBoxContent>
              </IBox>
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

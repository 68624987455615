import Clipboard from "clipboard";
import { truncate } from "lodash";
import { success } from "./toasts";

export function initializeClipboard(): void {
  $('[data-toggle="clipboard"]').each((index, element) => {
    const buttonElement = $(element);
    const clipboardButton = new Clipboard(element);
    buttonElement.data("clipboard", clipboardButton);

    clipboardButton.on("success", (event) => {
      const showFlash = buttonElement.data("confirmbytoast") as boolean;
      if (showFlash) {
        void success(
          I18n.t("frontend.copied"),
          I18n.t("frontend.copied_to_clipboard", {
            text: truncate(event.text, { length: 50 }),
          }),
        );
      } else {
        const clipboardTarget = buttonElement.attr("data-clipboard-target")
          ? $(buttonElement.attr("data-clipboard-target"))
          : $();
        clipboardTarget.parent().addClass("has-success");

        const clipboardFlash = buttonElement.attr("data-clipboard-flash")
          ? $(buttonElement.attr("data-clipboard-flash"))
          : $();

        clipboardFlash.fadeIn();
        setTimeout(() => clipboardFlash.fadeOut(), 2000);
      }
    });
  });
}

export function destroyClipboard(): void {
  $('[data-toggle="clipboard"]').each((index, element) => {
    const buttonElement = $(element);
    const clipboardButton = buttonElement.data("clipboard") as Clipboard;

    if (clipboardButton) {
      clipboardButton.destroy();
      buttonElement.removeData("clipboard");
    }
  });
}

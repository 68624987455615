import L from "leaflet";

import "leaflet-realtime";
import "leaflet.icon.glyph";
import "leaflet.markercluster";
import "./leaflet_resources";

if (typeof globalThis != "undefined") {
  globalThis.L ||= L;
}

window.L ||= L;
export default window.L;

import { isNil, isString } from "lodash";
import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { CommentForm } from "./comment_form";

const commentFormRoots: Root[] = [];
export function initCommentForm(
  selector: JQuery<HTMLElement> | string = '[data-toggle="comment-form"]',
) {
  (isString(selector) ? $(selector) : selector).each((index, element) => {
    const commentId = $(element).data("id");
    const itemId = $(element).data("item-id");
    const itemType = $(element).data("item-type") as string;
    const readonly = $(element).data("readonly") as boolean;
    const commentLoadData = isNil(commentId)
      ? null
      : { id: commentId, itemId, itemType };
    const root = createRoot(element);
    commentFormRoots.push(root);
    const comment = isNil(commentId) ? { itemId, itemType } : null;
    root.render(
      <AppRoot>
        <CommentForm
          commentLoadData={commentLoadData}
          comment={comment}
          readonly={readonly}
        />
      </AppRoot>,
    );
  });
}

export function destroyCommentForm() {
  commentFormRoots.forEach((root) => root.unmount());
  commentFormRoots.length = 0;
}

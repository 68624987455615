import { Root, createRoot } from "react-dom/client";
import { UserGroupsAddUserForm } from "./user_group_add_user_form";

import * as React from "react";
import { AppRoot } from "../common/app_root";
const roots: Root[] = [];
/**
 * Initialize react component Organizations Assign Asset Form element within all elements with data-toggle="organization-assign-asset-form".
 *

 */
export function initializeUserGroupAddUserFrom(): void {
  $('[data-toggle="user-group-add-user-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');

    const userGroupId = theElem.data("user-group-id") as number;

    const root = createRoot(element);
    roots.push(root);
    root.render(
      <AppRoot>
        <UserGroupsAddUserForm ibox={true} userGroupId={userGroupId} />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroyUserGroupAddUserForm(): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}

import React from "react";
import PropTypes from "prop-types";

import { MONTHS, DAYS } from "../../../constants/index";
import translateLabel from "../../../utils/translateLabel";
import { Grid, MenuItem, Radio, TextField } from "@mui/material";

const RepeatYearlyOnThe = ({
  id,
  mode,
  onThe,
  hasMoreModes,
  handleChange,
  translations,
}) => {
  const isActive = mode === "on the";

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item sm={2} display={{ xs: "none" }} />
      <Grid item xs="auto">
        {hasMoreModes && (
          <Radio
            id={id}
            aria-label="Repeat yearly on the"
            name="repeat.yearly.mode"
            checked={isActive}
            value="on the"
            onChange={handleChange}
          />
        )}
      </Grid>

      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-which`}
          name="repeat.yearly.onThe.which"
          aria-label="Repeat yearly on the which"
          label={translateLabel(translations, "repeat.yearly.on_the")}
          value={onThe.which}
          disabled={!isActive}
          size="small"
          onChange={handleChange}
        >
          <MenuItem value="First">
            {translateLabel(translations, "numerals.first")}
          </MenuItem>
          <MenuItem value="Second">
            {translateLabel(translations, "numerals.second")}
          </MenuItem>
          <MenuItem value="Third">
            {translateLabel(translations, "numerals.third")}
          </MenuItem>
          <MenuItem value="Fourth">
            {translateLabel(translations, "numerals.fourth")}
          </MenuItem>
          <MenuItem value="Last">
            {translateLabel(translations, "numerals.last")}
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-day`}
          name="repeat.yearly.onThe.day"
          aria-label="Repeat yearly on the day"
          size="small"
          value={onThe.day}
          disabled={!isActive}
          onChange={handleChange}
        >
          {DAYS.map((day) => (
            <MenuItem key={day} value={day}>
              {translateLabel(translations, `days.${day.toLowerCase()}`)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-month`}
          label={translateLabel(translations, "repeat.yearly.of")}
          name="repeat.yearly.onThe.month"
          aria-label="Repeat yearly on the month"
          size="small"
          value={onThe.month}
          disabled={!isActive}
          onChange={handleChange}
        >
          {MONTHS.map((month) => (
            <MenuItem key={month} value={month}>
              {translateLabel(translations, `months.${month.toLowerCase()}`)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};
RepeatYearlyOnThe.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  onThe: PropTypes.shape({
    which: PropTypes.oneOf(["First", "Second", "Third", "Fourth", "Last"])
      .isRequired,
    month: PropTypes.oneOf(MONTHS).isRequired,
    day: PropTypes.oneOf(DAYS).isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatYearlyOnThe;

import { Cancel, Check, Edit, NavigateBefore } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import {
  defaultTo,
  find,
  isEmpty,
  isNil,
  isNumber,
  map,
  toNumber,
  toString,
} from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { translatedAttributeName } from "../../i18n/translation_helper";
import { AssetJSONObject } from "../../json_api/asset";
import { ExternalReferenceJSONObject } from "../../json_api/external_reference";
import {
  EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE,
  ExternalReferenceTargetJSONObject,
} from "../../json_api/external_reference_target";
import {
  extractErrorsFromJsonApi,
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import {
  ExternalReference,
  ExternalReferenceItemType,
} from "../../models/external_reference";
import {
  api_asset_external_reference_path,
  api_asset_external_references_path,
  api_organization_external_reference_path,
  api_organization_external_reference_targets_path,
  api_organization_external_references_path,
  asset_external_references_path,
  organization_external_references_path,
} from "../../routes";
import {
  HttpError,
  RequestMethod,
  loadDataFromUrl,
  sendJsonApiData,
} from "../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../utils/jsonapi_form_tools";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { AppRoot } from "../common/app_root";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { LoadingWrapper } from "../common/loading_wrapper";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { useQuery } from "@tanstack/react-query";
import { loadExternalReferenceTargets } from "../external_reference_targets/external_reference_targets_data";

type OperationMode = "show" | "edit" | "new";
type RequestMode = "create" | "update";
export interface ExternalRefereceFormProps {
  externalReferenceId?: IDType;
  referencedItemId?: IDType;
  referencedItemType?: ExternalReferenceItemType;
  mode?: OperationMode;
  canEdit?: boolean;
  readOnly?: boolean;

  buttonMode?: "global" | "card";
  onCancel?: () => void;
  onSuccess?: (externalReference: ExternalReference) => void;
}

interface LoadedExternalReference extends ExternalReferenceJSONObject {
  external_reference_target?: ExternalReferenceTargetJSONObject;
}

type ExternalReferenceFormErrors = Partial<
  Record<keyof LoadedExternalReference, string[]>
>;

function validate(
  externalReference: LoadedExternalReference,
  oldErrors: ExternalReferenceFormErrors = {},
): ExternalReferenceFormErrors {
  if (isNil(externalReference)) return oldErrors;

  const err =
    isNil(externalReference?.external_reference_target_id) &&
    isEmpty(externalReference?.external_reference_target)
      ? [I18n.t("errors.messages.blank")]
      : null;

  if (err === null) {
    delete oldErrors.external_reference_target;
  } else {
    oldErrors.external_reference_target = err;
  }

  (["external_id"] as (keyof ExternalReference)[]).forEach((key) => {
    const err =
      !isNumber(externalReference[key]) && isEmpty(externalReference[key])
        ? [I18n.t("errors.messages.blank")]
        : null;

    if (err === null) {
      delete oldErrors[key];
    } else {
      oldErrors[key] = err;
    }
  });

  return oldErrors;
}

export const ExternalReferenceForm: React.FunctionComponent<
  ExternalRefereceFormProps
> = (props) => {
  const context = React.useContext(AppContext);
  const [operationMode, setOperationMode] = React.useState(
    props.readOnly == true
      ? "show"
      : isNil(props.externalReferenceId)
        ? "new"
        : "edit",
  );
  const [externalReference, setExternalReference] =
    React.useState<LoadedExternalReference>(operationMode == "new" ? {} : null);
  const [readonly, setReadOnly] = React.useState(operationMode == "show");
  const [externalReferenceModelErrors, setExternalReferenceModelErrors] =
    React.useState<ExternalReferenceFormErrors>({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    setExternalReferenceModelErrors(validate(externalReference));
  }, [externalReference]);
  React.useEffect(() => {
    setIsProcessing(true);
    if (operationMode == "new") {
      setExternalReference({ id: null });
      setIsProcessing(false);
    } else {
      if (isNil(props.externalReferenceId)) return;

      const pathFun: (
        id1: IDType,
        id2: IDType,
        options: Record<string, any>,
      ) => string =
        props.referencedItemType == "Asset"
          ? api_asset_external_reference_path
          : api_organization_external_reference_path;
      const urlOptions: Record<string, any> = {
        id: props.externalReferenceId,
        locale: I18n.locale,
        format: "json",
        include: "external_reference_target",
        _options: true,
      };

      urlOptions[
        props.referencedItemType == "Organization"
          ? "organization_id"
          : "asset_id"
      ] = props.referencedItemId;
      const url = pathFun(
        props.referencedItemId,
        props.externalReferenceId,
        urlOptions,
      );
      void loadDataFromUrl<
        JSONAPI.SingleResourceDoc<string, ExternalReferenceJSONObject>
      >(url)
        .then((document) => {
          const externalReference =
            jsonApiSingleResourceToFlatObject<LoadedExternalReference>(
              document,
            );
          setExternalReference(externalReference);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  }, [props.externalReferenceId]);

  const externalTargetSystemsQuery = loadExternalReferenceTargets({
    variables: {
      organizationId: context.currentOrganizationId,
      referencedItemType: props.referencedItemType,
    },
  });

  const submit = React.useCallback(() => {
    void onSubmit(
      externalReference,
      props.referencedItemId,
      props.referencedItemType,
      setIsProcessing,
      setExternalReference,
      setExternalReferenceModelErrors,
      props.onSuccess,
      context,
    );
  }, [externalReference]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("frontend.external_references.form.heading", {
              reference: isNil(externalReference?.id)
                ? I18n.t(
                    "frontend.external_references.form.new_external_reference",
                  )
                : externalReference?.external_id,
            } as Record<string, string>)}
          />

          <CardContent>
            <LoadingWrapper loading={isProcessing}>
              {isProcessing ? null : isEmpty(
                  externalTargetSystemsQuery.data,
                ) ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>
                      {I18n.t(
                        "frontend.external_references.form.no_reference_targets",
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "external_reference",
                          "external_reference_target_id",
                        )}
                        label={translatedAttributeName(
                          "external_reference",
                          "external_reference_target_id",
                        )}
                        error={
                          isEmpty(
                            externalReferenceModelErrors.external_reference_target,
                          ) ||
                          isNil(
                            externalReferenceModelErrors.external_reference_target_id,
                          )
                        }
                        helperText={modelPropertyError(
                          externalReferenceModelErrors,
                          externalReferenceModelErrors.external_reference_target
                            ? "external_reference_target_id"
                            : "external_reference_target",
                        )}
                        required
                        value={toString(
                          externalReference?.external_reference_target_id,
                        )}
                        placeholder={
                          isEmpty(externalTargetSystemsQuery.data)
                            ? I18n.t("base.none")
                            : null
                        }
                        disabled={
                          externalTargetSystemsQuery.isLoading ||
                          isProcessing ||
                          isEmpty(externalTargetSystemsQuery.data)
                        }
                        onChange={(event) => {
                          const extRef = isNil(externalReference)
                            ? {}
                            : externalReference;
                          const selectedTargetSystem = find(
                            externalTargetSystemsQuery.data,
                            (m) => m.id == event.target.value,
                          );
                          setExternalReference({
                            ...extRef,
                            external_reference_target: selectedTargetSystem,
                            external_reference_target_id:
                              selectedTargetSystem?.id,
                          });
                        }}
                      >
                        {map(
                          defaultTo(externalTargetSystemsQuery.data, [
                            externalReference?.external_reference_target,
                          ]),
                          (m) =>
                            isNil(m) ? null : (
                              <MenuItem key={m.id} value={toString(m.id)}>
                                {defaultTo(m.name, m.url_template)} -{" "}
                                {m.item_type}
                              </MenuItem>
                            ),
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "external_reference",
                          "external_id",
                        )}
                        label={translatedAttributeName(
                          "external_reference",
                          "external_id",
                        )}
                        value={toString(externalReference?.external_id)}
                        error={
                          isEmpty(externalReference?.external_id) ||
                          !isEmpty(externalReferenceModelErrors.external_id)
                        }
                        disabled={isProcessing}
                        helperText={modelPropertyError(
                          externalReferenceModelErrors,
                          "external_id",
                        )}
                        onChange={(event) => {
                          const extRef = isNil(externalReference)
                            ? {}
                            : externalReference;
                          setExternalReference({
                            ...extRef,
                            external_id: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        minRows={2}
                        fullWidth
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "external_reference",
                          "note",
                        )}
                        label={translatedAttributeName(
                          "external_reference",
                          "note",
                        )}
                        value={toString(externalReference?.note)}
                        disabled={isProcessing}
                        onChange={(event) => {
                          const extRef = isNil(externalReference)
                            ? {}
                            : externalReference;
                          setExternalReference({
                            ...extRef,
                            note: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  {props.buttonMode == "card" ? null : (
                    <FixedBottomArea id="fixed-bottom-area">
                      {readonly ? (
                        <FloatingButtons isProcessing={isProcessing}>
                          <Tooltip title={I18n.t("frontend.back")}>
                            <Fab
                              size="medium"
                              onClick={() => {
                                redirectTo("back");
                              }}
                            >
                              <NavigateBefore />
                            </Fab>
                          </Tooltip>
                          {!props.canEdit ? null : (
                            <Tooltip title={I18n.t("frontend.edit")}>
                              <Fab
                                className="submit"
                                onClick={() => {
                                  setOperationMode("edit");
                                }}
                                color={"primary"}
                              >
                                <Edit />
                              </Fab>
                            </Tooltip>
                          )}
                        </FloatingButtons>
                      ) : null}
                      {!readonly && (
                        <FloatingButtons
                          isProcessing={isProcessing}
                          onSubmit={() => {
                            void submit();
                          }}
                          onCancel={() => {
                            if (props.onCancel) {
                              props.onCancel();
                            } else {
                              redirectTo("back");
                            }
                          }}
                          disableSave={
                            isProcessing ||
                            !isEmpty(externalReferenceModelErrors)
                          }
                          showScrollToTopBtn={true}
                          saveTitle={I18n.t(
                            "frontend.organizations.form.submit_title",
                          )}
                        />
                      )}
                    </FixedBottomArea>
                  )}
                </>
              )}
            </LoadingWrapper>
          </CardContent>
          {props.buttonMode != "card" ? null : (
            <CardActions>
              <Button
                color="primary"
                disabled={
                  isProcessing || !isEmpty(externalReferenceModelErrors)
                }
                startIcon={<Check />}
                onClick={() => {
                  void submit();
                }}
              >
                {I18n.t("frontend.save")}
              </Button>
              <Button
                disabled={isProcessing}
                startIcon={<Cancel />}
                onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  } else {
                    redirectTo("back");
                  }
                }}
              >
                {I18n.t("frontend.cancel")}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

async function onSubmit(
  externalReference: ExternalReferenceJSONObject,
  referencedItemId: IDType,
  referencedItemType: ExternalReferenceItemType,
  setIsProcessing: (isProcesing: boolean) => void,
  setExternalReference: (externalReference: LoadedExternalReference) => void,
  setExternalReferenceModelErrors: (
    errors: ExternalReferenceFormErrors,
  ) => void,
  onSuccess: (productModel: LoadedExternalReference) => void,
  context: SialogicContext,
): Promise<void> {
  const { submitData, mode } = buildSubmitData(
    externalReference,
    referencedItemId,
    referencedItemType,
  );

  let url: string;
  let method: RequestMethod = "POST";
  if (isNil(externalReference?.id)) {
    const pathFun =
      referencedItemType == "Organization"
        ? api_organization_external_references_path
        : api_asset_external_references_path;

    url = pathFun(referencedItemId, {
      locale: I18n.locale,
      format: "json",
    });
  } else {
    const pathFun =
      referencedItemType == "Organization"
        ? api_organization_external_reference_path
        : api_asset_external_reference_path;
    const pathOptions: Record<string, any> = {
      id: externalReference.id,
      locale: I18n.locale,
      format: "json",
      _options: true,
    };
    pathOptions[
      referencedItemType == "Organization" ? "organization_id" : "asset_id"
    ] = referencedItemId;
    url = pathFun(referencedItemId, externalReference.id, pathOptions);
    method = "PATCH";
  }

  try {
    setIsProcessing(true);
    const result = await sendJsonApiData<
      JSONAPI.SingleResourceDoc<string, ExternalReferenceJSONObject>,
      JSONAPI.SingleResourceDoc<string, ExternalReferenceJSONObject>
    >(url, submitData, method);

    handleRequestSuccess(
      mode,
      result,
      referencedItemId,
      referencedItemType,
      setExternalReference,
      onSuccess,
      context,
    );
  } catch (err) {
    handleRequestError(mode, err as HttpError, setExternalReferenceModelErrors);
  } finally {
    setIsProcessing(false);
  }
}

function handleRequestSuccess(
  mode: RequestMode,
  resultData: JSONAPI.SingleResourceDoc<string, ExternalReferenceJSONObject>,
  referencedItemId: IDType,
  referencedItemType: ExternalReferenceItemType,
  setExternalReference: (
    externalReference: ExternalReferenceJSONObject,
  ) => void,
  onSuccess: (externalReference: ExternalReferenceJSONObject) => void,
  context: SialogicContext,
) {
  if (mode === "create") {
    void toasts.success(I18n.t("base.successfully_created"));
  } else {
    void toasts.success(I18n.t("base.successfully_updated"));
  }

  const externalReference = jsonApiSingleResourceToFlatObject(resultData);
  setExternalReference(externalReference);

  if (!isNil(onSuccess)) {
    onSuccess(externalReference);
  } else {
    redirectTo(
      defaultTo(
        context.referrer,
        referencedItemType == "Organization"
          ? organization_external_references_path(referencedItemId)
          : asset_external_references_path(referencedItemId),
      ),
    );
  }
}
function handleRequestError(
  mode: string,
  err: HttpError,
  setExternalReferenceModelErrors: (
    errors: ExternalReferenceFormErrors,
  ) => void,
) {
  const errors: ExternalReferenceFormErrors =
    extractErrorsFromJsonApi<LoadedExternalReference>(
      err.request?.responseJSON as JSONAPI.DocWithErrors,
    );

  if (mode === "create") {
    void toasts.error(I18n.t("base.error"), I18n.t("base.error_creating"));
  } else {
    void toasts.error(I18n.t("base.error"), I18n.t("base.error_updating"));
  }

  setExternalReferenceModelErrors(errors);
}

function buildSubmitData(
  externalReference: LoadedExternalReference,
  referencedItemId: IDType,
  referencedItemType: ExternalReferenceItemType,
): {
  mode: RequestMode;
  submitData?: JSONAPI.SingleResourceDoc<string, ExternalReferenceJSONObject>;
} {
  const { submitData, mode } =
    buildJsonApiSubmitData<ExternalReferenceJSONObject>(
      externalReference,
      "external_references",
      ["external_id", "note"],
    );

  const relationships: JSONAPI.RelationshipsObject = {};
  if (mode == "create") {
    relationships["referenced_item"] = {
      data: {
        type: referencedItemType == "Organization" ? "organizations" : "assets",
        id: toString(referencedItemId),
      },
    };
  }

  if (!isNil(externalReference?.external_reference_target)) {
    relationships["external_reference_target"] = {
      data: {
        type: "external_reference_targets",
        id: toString(externalReference?.external_reference_target?.id),
      },
    };
  }

  submitData.data.relationships = relationships;

  return { submitData, mode };
}

const externalReferenceFormRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeExternalReferenceForm(
  selector: JQuery = $('[data-toggle="external-reference-form"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);
    const externalReferenceId = jqElement.data(
      "external-reference-id",
    ) as string;
    const readOnly = jqElement.data("read-only") as boolean;
    const canEdit = jqElement.data("can-edit") as boolean;
    const referencedItemDoc = jqElement.data(
      "referenced_item",
    ) as JSONAPI.SingleResourceDoc<string, AssetJSONObject>;

    const referencedItem = jsonApiSingleResourceToFlatObject(referencedItemDoc);
    const root = createRoot(element);
    externalReferenceFormRoots.push(root);
    root.render(
      <AppRoot>
        <ExternalReferenceForm
          externalReferenceId={
            isNil(externalReferenceId) ? null : toNumber(externalReferenceId)
          }
          readOnly={readOnly}
          canEdit={canEdit}
          referencedItemId={referencedItem?.id}
          referencedItemType={
            referencedItemDoc.data.type == "organizations"
              ? "Organization"
              : "Asset"
          }
        />
      </AppRoot>,
    );
  });
}

export function destroyExternalReferenceForm(): void {
  externalReferenceFormRoots.forEach((root) => {
    root.unmount();
  });
  externalReferenceFormRoots.length = 0;
}

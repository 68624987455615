import JQuery from "jquery";
import * as JSONAPI from "jsonapi-typescript";
import { toNumber } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { MeasurementJSONAPIAttributesObject } from "../../json_api/measurement";
import { AppRoot } from "../common/app_root";
import { handleJsonApiMeasurements } from "./utils/handle_json_api_measurements";
import { MeasurementItemContainer } from "./views/measurement_item_container";
import { MeasurementList } from "./views/measurement_list";

const measurementListRoots: Root[] = [];
export function initializeMeasurementList(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-list"]',
): void {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);

    const measurementsJSON = el.data("items") as JSONAPI.CollectionResourceDoc<
      string,
      MeasurementJSONAPIAttributesObject
    >;
    const measurementData = handleJsonApiMeasurements(measurementsJSON);

    const measurementsUrl = el.data("items-url") as string;
    const assetId = toNumber(el.data("asset-id") as string);
    let currentPage = parseInt(el.data("current-page") as string) || 1;
    currentPage = isNaN(currentPage) ? 1 : currentPage;
    const totalPages = parseInt(el.data("total-pages") as string);
    const totalItems = parseInt(el.data("total-items") as string);
    const pageSize = parseInt(el.data("page-size") as string);
    const root = createRoot(element);
    measurementListRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementList
          measurements={measurementData.measurements}
          measurementCategoriesById={measurementData.measurementCategoryById}
          measurementValueDefinitionsByMeasurementId={
            measurementData.measurementValueDefinitionsByMeasurementId
          }
          measurementValueByMeasurementId={
            measurementData.measurementValueByMeasurementId
          }
          measurementTypeById={measurementData.measurementTypeById}
          measurementsUrl={measurementsUrl}
          measurementPlan={measurementData.measurementPlan}
          pageSize={pageSize}
          assetId={assetId}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </AppRoot>,
    );
  });
}

export function destroyMeasurementList() {
  measurementListRoots.forEach((r) => r.unmount());
  measurementListRoots.length = 0;
}

const measurementItemRoots: Root[] = [];
export function initializeMeasurementItem(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-item-view"]',
): void {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);

    const measurementsJSON = el.data(
      "measurement",
    ) as JSONAPI.SingleResourceDoc<string, MeasurementJSONAPIAttributesObject>;
    const measurementData = handleJsonApiMeasurements(measurementsJSON);
    const measurement = measurementData.measurements[0];
    const root = createRoot(element);
    measurementItemRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementItemContainer
          asset={measurementData.assets[0]}
          measurement={measurement}
          measurementValueDefinitionsMap={
            measurementData.measurementValueDefinitionsByMeasurementId
          }
          measurementPlan={measurementData.measurementPlan}
          measurementValues={
            measurementData.measurementValueByMeasurementId[
              measurement.id as number
            ]
          }
          measurementType={
            measurementData.measurementTypeById[
              measurementData?.measurementPlan?.measurement_type_id
            ]
          }
          orderedMeasurementValueDefinitions={
            measurementData.measurementValueDefinitionsByMeasurementId[
              measurement.id as number
            ]
          }
          measurementCategoryById={measurementData.measurementCategoryById}
        />
      </AppRoot>,
    );
  });
}

export function destroyMeasurementItems() {
  measurementItemRoots.forEach((r) => r.unmount());
  measurementItemRoots.length = 0;
}

import {
  defaultTo,
  each,
  fill,
  first,
  isEmpty,
  isNil,
  last,
  toString,
  values,
} from "lodash";
import { DateRange } from "moment-range";
import { getStateColor } from "../utils/colors";
import { StateData } from "./chart_data/chart_data_loader.types";
import { Plotly } from "./plotly_package";

import { Moment } from "moment";
import { State } from "../models/state";
import { StateContext } from "../models/state_context";

function buildStateShape(
  startTime: Date,
  endTime: Date,
  stateYaxis: Plotly.YAxisName,
  color: string,
  name: string,
  shapeCenterY: number,
  shapeHeightY: number,
): Partial<Plotly.Shape> {
  return {
    type: "rect",
    xref: "x",
    yref: stateYaxis,
    x0: startTime.getTime() < endTime.getTime() ? startTime : endTime,
    y0: shapeCenterY - shapeHeightY * 0.5,
    x1: startTime.getTime() < endTime.getTime() ? endTime : startTime,
    y1: shapeCenterY + shapeHeightY * 0.5,
    fillcolor: color,
    opacity: 0.2,

    name: name,
    line: {
      width: 1,
    },
    /* label: {
      text: name,
      font: { size: 5 },
      textposition: "middle center",
    },*/
  };
}

export interface StatesAndTrances {
  stateShapes: Partial<Plotly.Shape>[];
  shapeTrace: Partial<Plotly.ScatterData>;
}
export function buildStateShapesAndTraces(
  stateChangeData: StateData,
  timeRange: DateRange,
  stateYaxis: Plotly.YAxisName,
  shapeHeight = 0.4,
  shapeCenter = 0.5,
): StatesAndTrances {
  if (isEmpty(stateChangeData?.stateChanges)) return null;

  const stateShapes: Partial<Plotly.Shape>[] = [];

  const stateColors: Record<string, string> = {};

  each(values(stateChangeData.possible_states), (state, index) => {
    stateColors[toString(state.id)] = getStateColor(state, index);
  });

  const traceDates: Date[] = [];
  const traceTexts: string[] = [];
  const firstStateChange = first(stateChangeData.stateChanges);
  let previousState =
    stateChangeData.possible_states[firstStateChange.previous_state_id];
  const firstStateChangedAt = firstStateChange?.time;

  if (timeRange.start.isBefore(firstStateChangedAt) && !isNil(previousState)) {
    // there was a state before the time range
    addStateTransition(
      timeRange.start,
      firstStateChangedAt,
      previousState,
      stateChangeData.state_context,
      stateShapes,
      stateYaxis,
      stateColors[toString(previousState.id)],
      shapeCenter,
      shapeHeight,
      traceTexts,
      traceDates,
    );
  }

  each(stateChangeData.stateChanges, (stateChange, index) => {
    // start with the second item
    if (index == 0) return;

    const prevStateChange = stateChangeData.stateChanges[index - 1];
    previousState =
      stateChangeData.possible_states[stateChange.previous_state_id];
    let stateColor: string;
    if (isNil(previousState)) {
      // this is can be the case if this was the first state, a state has been deleted
      stateColor = getStateColor(null, null);
    } else {
      stateColor = stateColors[toString(previousState.id)];
    }

    addStateTransition(
      prevStateChange.time,
      stateChange.time,
      previousState,
      stateChangeData.state_context,
      stateShapes,
      stateYaxis,
      stateColor,
      shapeCenter,
      shapeHeight,
      traceTexts,
      traceDates,
    );
  });

  const lastStateChange = last(stateChangeData.stateChanges);
  const lastState = stateChangeData.possible_states[lastStateChange?.state_id];
  if (timeRange.end.isAfter(lastStateChange.time)) {
    addStateTransition(
      lastStateChange.time,
      timeRange.end,
      lastState,
      stateChangeData.state_context,
      stateShapes,
      stateYaxis,
      stateColors[toString(lastState.id)],
      shapeCenter,
      shapeHeight,
      traceTexts,
      traceDates,
    );
  }

  const trace = {
    x: traceDates,
    y: fill(new Array(traceDates.length), shapeCenter),
    text: traceTexts,
    xaxis: "x",
    yaxis: stateYaxis,
    showlegend: false,

    opacity: 0,

    hoverinfo: "text",
    type: "scattergl" as Plotly.PlotType,
    mode: "markers",
    name: "State Context",
  } as Plotly.PlotData;

  return { stateShapes, shapeTrace: trace };
}

function addStateTransition(
  stateFrom: Moment,
  stateTo: Moment,
  state: State,
  stateContext: StateContext,
  shapes: Partial<Plotly.Shape>[],
  stateYaxis: Plotly.YAxisName,
  stateColor: string,
  shapeCenter: number,
  shapeHeight: number,
  traceTexts: string[],
  traceDates: Date[],
) {
  // put a shape from last state change to current state change

  const stateFromDate = stateFrom.toDate();
  const stateToDate = stateTo.toDate();
  traceDates.push(stateFromDate);
  traceTexts.push(stateText(state, stateContext, stateFrom));
  shapes.push(
    buildStateShape(
      stateFromDate,
      stateToDate,
      stateYaxis,
      stateColor,
      defaultTo(state?.name, ""),
      shapeCenter,
      shapeHeight,
    ),
  );
  traceDates.push(new Date(stateToDate.getTime() - 50));
  traceTexts.push(stateText(state, stateContext, stateTo));
}

function stateText(state: State, context: StateContext, time: Moment) {
  if (isNil(state)) return "";

  return `${context?.name}: ${state.name} - ${time.format("L LT")}`;
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
  AssetTemplateJSONObject,
} from "../../json_api/asset_template";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
  JSONApiFormRequestMode,
} from "../../utils/jsonapi_form_tools";

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { concat, isEmpty, toInteger, uniq } from "lodash";
import { ASSET_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/asset_type";
import {
  jsonApiResourceCollectionToFlatObjects,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import { MANUFACTURER_JSONAPI_RESOURCE_TYPE } from "../../json_api/manufacturer";
import {
  api_asset_template_path,
  api_asset_templates_path,
} from "../../routes";
import { loadDataFromUrl, sendJsonApiData } from "../../utils/jquery_helper";
import { apiAssetTemplatesUrl } from "../../utils/urls/asset_template_urls";
import { IDType, ParamsType } from "../../utils/urls/url_utils";
import { createQuery, RouterQueryOptions } from "react-query-kit";

function buildSubmitData(assetTemplate: AssetTemplateJSONObject): {
  mode: JSONApiFormRequestMode;
  submitData?: SingleResourceDoc<string, AssetTemplateJSONObject>;
} {
  const submitData = buildJsonApiSubmitData<AssetTemplateJSONObject>(
    assetTemplate,
    ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
    [
      "name",
      "short_name",
      "key",
      "min_count",
      "max_count",
      "description",
      "title",
      "timezone",
    ],
  );
  const { submitData: submitDataDoc, mode } = submitData;

  if (mode == "create") {
    addHasOneRelationToJsonApiSubmitData(
      submitDataDoc,
      "asset_type",
      ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
      assetTemplate.asset_type_id!,
      false,
    );
  }

  addHasOneRelationToJsonApiSubmitData(
    submitDataDoc,
    "manufacturer",
    MANUFACTURER_JSONAPI_RESOURCE_TYPE,
    assetTemplate.manufacturer_id!,
    true,
  );

  return { submitData: submitDataDoc, mode };
}

function getUrl(pageSize: number, pageNumber: number, assetTypeId?: IDType) {
  const params: ParamsType = [];

  return apiAssetTemplatesUrl(
    assetTypeId,
    pageNumber + 1,
    pageSize,
    ["asset_type"],
    params,
  );
}

interface LoadAssetTemplatesVariables {
  assetTypeId: IDType;
  pageSettings: { pageSize: number; page: number };
  include?: string[];
}

export const useLoadAssetTemplates = createQuery<
  LoadItemsResult<AssetTemplateJSONObject>,
  LoadAssetTemplatesVariables
>({
  queryKey: [ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ assetTypeId, pageSettings, include }) => {
    const options = {
      filter: { asset_type: assetTypeId },
      _options: true,
    };

    const includes = uniq(concat(include || [], ["parent"])).join(",");

    if (!isEmpty(includes)) {
      (options as any)["include"] = includes;
    }

    if (pageSettings?.page || pageSettings?.pageSize) {
      (options as any).page = {
        number: pageSettings.page || 1,
        size: pageSettings.pageSize || 100,
      };
    }

    const jsonApiResponse = await loadDataFromUrl<
      CollectionResourceDoc<string, AssetTemplateJSONObject>
    >(api_asset_templates_path(options));

    return loadItemResultForJsonApiCollectionResourceDoc<AssetTemplateJSONObject>(
      jsonApiResponse,
    );
  },
});

// initialData: { assetTemplates: [], totalItems: 0, totalPages: 0 },

interface CreateAssetTemplateOptions {
  assetTemplate: AssetTemplateJSONObject;
  assetTypeId?: IDType;
}

export const useCreateAssetTemplate = (
  onError?: (eerData: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ assetTemplate }: CreateAssetTemplateOptions) => {
      const jsonAPISubmitData = buildSubmitData(assetTemplate);
      const { submitData, mode } = jsonAPISubmitData;

      if (mode !== "create") {
        throw new Error("Invalid mode - expected create");
      }

      addHasOneRelationToJsonApiSubmitData(
        submitData,
        "asset_type",
        ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
        assetTemplate.asset_type_id,
        false,
      );

      addHasOneRelationToJsonApiSubmitData(
        submitData,
        "manufacturer",
        MANUFACTURER_JSONAPI_RESOURCE_TYPE,
        assetTemplate.manufacturer_id,
        false,
      );

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, AssetTemplateJSONObject>
      >(api_asset_templates_path(), submitData, "POST");
    },
    onError,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useUpdateAssetTemplate = (
  onError?: (eerData: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ assetTemplate }: CreateAssetTemplateOptions) => {
      const { submitData, mode } = buildSubmitData(assetTemplate);

      if (mode !== "update") {
        throw new Error("Invalid mode - expected update");
      }

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, AssetTemplateJSONObject>
      >(api_asset_template_path(assetTemplate.id), submitData, "PATCH");
    },
    onError,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useDeleteAssetTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(api_asset_template_path(id), null, "DELETE");
    },
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: [ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE],
      });
      return data;
    },
  });
};

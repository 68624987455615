import { Grid } from "@mui/material";
import * as React from "react";
import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementPlan } from "../../../models/measurement_plan";
import {
  MeasurementType,
  MeasurementTypes,
} from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { TypedErrorMap } from "../../../utils/error_map";
import { FormMode } from "../data/measurement_plan_actions";
import { MeasurementPlanDetailsForm } from "./measurement_plan_details_form";
import {
  MeasurementPlanCreationMode,
  MeasurementPlanFormStep1,
} from "./measurement_plan_form_step_1";

export type MeasurementPlanFormStepNumbers = 1 | 2;

interface MeasurementPlanFormProps {
  assetId: number | string;
  measurementPlan: MeasurementPlan;
  availableMeasurementCategorizations: MeasurementCategorization[];
  templateMeasurementTypes: MeasurementType[];
  templateType: MeasurementType;
  typeOfMeasurement: MeasurementTypes;

  unit: string;
  mode: FormMode;
  createBy?: MeasurementPlanCreationMode;
  isProcessing: boolean;
  onSubmit?: () => void;
  onCancel: () => void;
  errors: TypedErrorMap<MeasurementPlan>;

  step: MeasurementPlanFormStepNumbers;

  onFinishStep1?: () => void;
  onBack?: () => void;
  onMoveMeasurementValueDefinition?: (index: number, direction: -1 | 1) => void;
  onAddMeasurementValueDefinition?: () => void;
  onRemoveMeasurementValueDefinition?: (
    mvd: MeasurementValueDefinition,
    index: number,
  ) => void;
  onSelectCreationMode?: (createBy: MeasurementPlanCreationMode) => void;
  onSelectTypeOfMeasurement?: (type: MeasurementTypes) => void;
  onSelectTemplate?: (template: MeasurementType) => void;

  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void;
  onCategorizationSelect?: (mCat: MeasurementCategorization) => void;
  onUpdateMeasurementPlan?: (mPlan: MeasurementPlan) => void;
  onUnitSelect?: (unit: string) => void;
  onToggleEdit?: (editable: boolean) => void;
  onDelete?: () => void;
}

interface MeasurementPlanFormState {}

/**
 * A form to configure measurement plans
 */
export class MeasurementPlanForm extends React.Component<
  MeasurementPlanFormProps,
  MeasurementPlanFormState
> {
  constructor(props: MeasurementPlanFormProps) {
    super(props);
    this.state = {};
  }

  static defaultProps() {
    return {
      availableMeasurementCategorizations: [] as MeasurementCategorization[],
    };
  }

  render(): React.ReactNode {
    return (
      <form noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            {this.formStep()}
          </Grid>
        </Grid>
      </form>
    );
  }
  formStep() {
    if (this.props.step === 1) {
      return (
        <MeasurementPlanFormStep1
          errors={this.props.errors}
          onSelectTemplate={this.props.onSelectTemplate}
          createBy={this.props.createBy}
          measurementPlan={this.props.measurementPlan}
          templates={this.props.templateMeasurementTypes}
          templateType={this.props.templateType}
          typeOfMeasurement={this.props.typeOfMeasurement}
          onFinish={this.props.onFinishStep1}
          onSelectCreationMode={this.props.onSelectCreationMode}
          onSelectTypeOfMeasurement={this.props.onSelectTypeOfMeasurement}
          onUpdateMeasurementPlan={this.props.onUpdateMeasurementPlan}
          onCancel={() => {
            this.props.onCancel();
          }}
        />
      );
    } else if (this.props.step === 2) {
      return (
        <MeasurementPlanDetailsForm
          errors={this.props.errors}
          allowEdit={this.props.mode !== "show"}
          allowMvdDelete={this.props.mode === "create"}
          availableMeasurementCategorizations={
            this.props.availableMeasurementCategorizations
          }
          measurementPlan={this.props.measurementPlan}
          createBy={this.props.createBy}
          unit={this.props.unit}
          mode={this.props.mode}
          typeOfMeasurement={this.props.typeOfMeasurement}
          onSubmit={this.props.onSubmit}
          onBack={this.props.mode !== "create" ? null : this.props.onBack}
          onCancel={this.props.onCancel}
          onMoveMeasurementValueDefinition={
            this.props.onMoveMeasurementValueDefinition
          }
          onAddMeasurementValueDefinition={
            this.props.onAddMeasurementValueDefinition
          }
          onCategorizationSelect={this.props.onCategorizationSelect}
          onUpdateMeasurementPlan={this.props.onUpdateMeasurementPlan}
          onUnitSelect={this.props.onUnitSelect}
          onRemoveMeasurementValueDefinition={
            this.props.onRemoveMeasurementValueDefinition
          }
          processing={this.props.isProcessing}
          onUpdateMeasurementValueDefinition={
            this.props.onUpdateMeasurementValueDefinition
          }
          onToggleEdit={this.props.onToggleEdit}
          onDelete={this.props.onDelete}
        />
      );
    }
  }
}

/// <reference types="../../definitions/index" />;
import * as React from "react";

import { defaultTo, isEmpty, map, noop } from "lodash";
import moment from "../../initializers/moment";

import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { TimeRanges } from "../../definitions/bootstrap-daterangepicker";
import { MaterialUiDatePicker } from "../common/date_picker";
import { Moment } from "moment";

export interface MobileTimeRangePickerProps {
  startDate?: Moment;
  endDate?: Moment;
  selectedRange?: string;
  ranges?: TimeRanges;
  onChange?: (
    startTime: Moment,
    endTime: Moment,
    selectedRange: string,
  ) => void;
  onHideParent?: () => void;
}

export interface MobileTimeRangePickerState {
  startDate?: Moment;
  endDate?: Moment;
  selectedRange?: string;
}

export class MobileTimeRangePicker extends React.Component<
  MobileTimeRangePickerProps,
  MobileTimeRangePickerState
> {
  static TimeFormat = "YYYY-MM-DDTHH:mm";

  static defaultProps: MobileTimeRangePickerProps = {
    onChange: noop,
    onHideParent: noop,
  };

  constructor(props: MobileTimeRangePickerProps) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      selectedRange: props.selectedRange,
    };
  }

  componentDidUpdate(prevProps: MobileTimeRangePickerProps): void {
    if (prevProps !== this.props) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        selectedRange: this.props.selectedRange,
      });
    }
  }

  render() {
    const enableDatePicker =
      isEmpty(this.props.ranges) ||
      this.state.selectedRange ==
        I18n.t("frontend.time_range_picker.select_custom_range");

    return (
      <Grid container spacing={4}>
        {isEmpty(this.props.ranges) ? null : (
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="medium"
              label={I18n.t("frontend.time_range_picker.time_range")}
              name="time_range"
              select
              value={defaultTo(
                this.state.selectedRange,
                I18n.t("frontend.time_range_picker.select_custom_range"),
              )}
              onChange={(event) => this.onChangeTimeRange(event.target.value)}
            >
              {this.renderRangeOptions()}
              <MenuItem
                key={"custom_range"}
                value={I18n.t("frontend.time_range_picker.select_custom_range")}
              >
                {I18n.t("frontend.time_range_picker.select_custom_range")}
              </MenuItem>
            </TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <MaterialUiDatePicker
              size="medium"
              type="datetime"
              name="start_date"
              disabled={!enableDatePicker}
              label={I18n.t("frontend.time_range_picker.start_date")}
              value={this.state.startDate}
              dateFormat={MobileTimeRangePicker.TimeFormat}
              onChange={(date) => this.onChangeDate("startDate", date)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <MaterialUiDatePicker
              size="medium"
              type={"datetime"}
              label={I18n.t("frontend.time_range_picker.end_date")}
              name="end_date"
              disabled={!enableDatePicker}
              value={this.state.endDate}
              dateFormat={MobileTimeRangePicker.TimeFormat}
              onChange={(event) => this.onChangeDate("endDate", event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} alignContent="end">
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => {
                this.onApply();
              }}
            >
              {I18n.t("frontend.apply")}
            </Button>
            <Button
              onClick={() => {
                this.onCancel();
              }}
            >
              {I18n.t("frontend.cancel")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }

  renderRangeOptions() {
    return map(this.props.ranges, (range, name) => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));
  }

  private onChangeTimeRange(value: string): void {
    if (value == I18n.t("frontend.time_range_picker.select_custom_range")) {
      // custom time range selected
      this.setState({
        selectedRange: value,
      });
    } else {
      // one of the predefined time ranges selected
      const timeRange = this.props.ranges[value];
      this.setState({
        selectedRange: value,
        startDate: moment(timeRange[0]),
        endDate: moment(timeRange[1]),
      });
    }
  }

  private onChangeDate(property: "startDate" | "endDate", date: Moment): void {
    const value = date;

    if (!value.isValid()) {
      return;
    }

    const state: MobileTimeRangePickerState = {};
    state[property] = value;
    this.setState(state);
  }

  private onApply(): void {
    this.props.onChange(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRange,
    );
    this.props.onHideParent();
  }

  private onCancel(): void {
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      selectedRange: this.props.selectedRange,
    });
    this.props.onHideParent();
  }
}

// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "line_diagram_string_data.proto" (package "DiagramData", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message DiagramData.LineDiagramStringData
 */
export interface LineDiagramStringData {
    /**
     * Attribute key id
     *
     * @generated from protobuf field: sint64 key_id = 1;
     */
    keyId: bigint;
    /**
     * Overall series name
     *
     * @generated from protobuf field: string series_name = 2;
     */
    seriesName: string;
    /**
     * Series unit
     *
     * @generated from protobuf field: string unit = 3;
     */
    unit: string;
    /**
     * minimum fetched time in seconds since epcoh
     *
     * @generated from protobuf field: double mintime = 4;
     */
    mintime: number;
    /**
     * maximum fetched time in seconds since epcoh
     *
     * @generated from protobuf field: double maxtime = 5;
     */
    maxtime: number;
    /**
     * minimum fetched value
     *
     * @generated from protobuf field: string minvalue = 6;
     */
    minvalue: string;
    /**
     * maximum fetched value
     *
     * @generated from protobuf field: string maxvalue = 7;
     */
    maxvalue: string;
    /**
     * Time array in seconds since epcoh
     *
     * @generated from protobuf field: repeated double x = 8;
     */
    x: number[];
    /**
     * value array
     *
     * @generated from protobuf field: repeated string y = 9;
     */
    y: string[];
    /**
     * @generated from protobuf field: repeated string open = 10;
     */
    open: string[];
    /**
     * @generated from protobuf field: repeated string close = 11;
     */
    close: string[];
    /**
     * @generated from protobuf field: repeated string low = 12;
     */
    low: string[];
    /**
     * @generated from protobuf field: repeated string high = 13;
     */
    high: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class LineDiagramStringData$Type extends MessageType<LineDiagramStringData> {
    constructor() {
        super("DiagramData.LineDiagramStringData", [
            { no: 1, name: "key_id", kind: "scalar", T: 18 /*ScalarType.SINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "series_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mintime", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "maxtime", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "minvalue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "maxvalue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "x", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "y", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "open", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "close", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "low", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "high", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LineDiagramStringData>): LineDiagramStringData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.keyId = 0n;
        message.seriesName = "";
        message.unit = "";
        message.mintime = 0;
        message.maxtime = 0;
        message.minvalue = "";
        message.maxvalue = "";
        message.x = [];
        message.y = [];
        message.open = [];
        message.close = [];
        message.low = [];
        message.high = [];
        if (value !== undefined)
            reflectionMergePartial<LineDiagramStringData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LineDiagramStringData): LineDiagramStringData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint64 key_id */ 1:
                    message.keyId = reader.sint64().toBigInt();
                    break;
                case /* string series_name */ 2:
                    message.seriesName = reader.string();
                    break;
                case /* string unit */ 3:
                    message.unit = reader.string();
                    break;
                case /* double mintime */ 4:
                    message.mintime = reader.double();
                    break;
                case /* double maxtime */ 5:
                    message.maxtime = reader.double();
                    break;
                case /* string minvalue */ 6:
                    message.minvalue = reader.string();
                    break;
                case /* string maxvalue */ 7:
                    message.maxvalue = reader.string();
                    break;
                case /* repeated double x */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.x.push(reader.double());
                    else
                        message.x.push(reader.double());
                    break;
                case /* repeated string y */ 9:
                    message.y.push(reader.string());
                    break;
                case /* repeated string open */ 10:
                    message.open.push(reader.string());
                    break;
                case /* repeated string close */ 11:
                    message.close.push(reader.string());
                    break;
                case /* repeated string low */ 12:
                    message.low.push(reader.string());
                    break;
                case /* repeated string high */ 13:
                    message.high.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LineDiagramStringData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint64 key_id = 1; */
        if (message.keyId !== 0n)
            writer.tag(1, WireType.Varint).sint64(message.keyId);
        /* string series_name = 2; */
        if (message.seriesName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.seriesName);
        /* string unit = 3; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        /* double mintime = 4; */
        if (message.mintime !== 0)
            writer.tag(4, WireType.Bit64).double(message.mintime);
        /* double maxtime = 5; */
        if (message.maxtime !== 0)
            writer.tag(5, WireType.Bit64).double(message.maxtime);
        /* string minvalue = 6; */
        if (message.minvalue !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.minvalue);
        /* string maxvalue = 7; */
        if (message.maxvalue !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.maxvalue);
        /* repeated double x = 8; */
        if (message.x.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.x.length; i++)
                writer.double(message.x[i]);
            writer.join();
        }
        /* repeated string y = 9; */
        for (let i = 0; i < message.y.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.y[i]);
        /* repeated string open = 10; */
        for (let i = 0; i < message.open.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.open[i]);
        /* repeated string close = 11; */
        for (let i = 0; i < message.close.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.close[i]);
        /* repeated string low = 12; */
        for (let i = 0; i < message.low.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.low[i]);
        /* repeated string high = 13; */
        for (let i = 0; i < message.high.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.high[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DiagramData.LineDiagramStringData
 */
export const LineDiagramStringData = new LineDiagramStringData$Type();

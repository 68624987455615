import React from "react";
import PropTypes from "prop-types";
import { toPairs } from "lodash";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";

const RepeatWeekly = ({
  id,
  weekly: { interval, days, options },
  handleChange,
  translations,
}) => {
  let daysArray = toPairs(days);
  if (options.weekStartsOnSunday) {
    daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  }

  return (
    <Grid item container xs={12} spacing={2} marginTop={2}>
      <Grid item xs={0} sm={2} />
      <Grid item xs={12} sm={10}>
        <Grid container>
          <Grid item xs={12} sm="auto" px={2}>
            <TextField
              id={`${id}-interval`}
              name="repeat.weekly.interval"
              aria-label="Repeat weekly interval"
              value={interval}
              size="small"
              type="number"
              label={translateLabel(translations, "repeat.weekly.every")}
              onChange={numericalFieldHandler(handleChange)}
              InputProps={{
                endAdornment: translateLabel(
                  translations,
                  "repeat.weekly.weeks",
                ),
              }}
            />
          </Grid>

          <Grid item container xs={12} sm="auto">
            <FormGroup>
              <Grid container>
                {daysArray.map(([dayName, isDayActive]) => (
                  <Grid item xs="auto" key={dayName}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            const editedEvent = {
                              ...event,
                              target: {
                                ...event.target,
                                value: !isDayActive,
                                name: event.target.name,
                              },
                            };

                            handleChange(editedEvent);
                          }}
                          name={`repeat.weekly.days[${dayName}]`}
                          checked={isDayActive}
                        />
                      }
                      label={translateLabel(
                        translations,
                        `days_short.${dayName.toLowerCase()}`,
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RepeatWeekly.propTypes = {
  id: PropTypes.string.isRequired,
  weekly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
    days: PropTypes.shape({
      mon: PropTypes.bool.isRequired,
      tue: PropTypes.bool.isRequired,
      wed: PropTypes.bool.isRequired,
      thu: PropTypes.bool.isRequired,
      fri: PropTypes.bool.isRequired,
      sat: PropTypes.bool.isRequired,
      sun: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.shape({
      weekStartsOnSunday: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatWeekly;

import React from "react";
import PropTypes from "prop-types";
import RepeatYearly from "./Yearly/index";
import RepeatMonthly from "./Monthly/index";
import RepeatWeekly from "./Weekly/index";
import RepeatDaily from "./Daily/index";
import RepeatHourly from "./Hourly/index";
import translateLabel from "../../utils/translateLabel";
import { MenuItem, TextField, Box, Grid } from "@mui/material";

const Repeat = ({
  id,
  repeat: { frequency, yearly, monthly, weekly, daily, hourly, options },
  handleChange,
  translations,
}) => {
  const isOptionAvailable = (option) =>
    !options.frequency || options.frequency.indexOf(option) !== -1;
  const isOptionSelected = (option) => frequency === option;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          select
          label={translateLabel(translations, "repeat.label")}
          name="repeat.frequency"
          id={`${id}-frequency`}
          size="small"
          fullWidth
          value={frequency}
          onChange={handleChange}
        >
          {isOptionAvailable("Yearly") && (
            <MenuItem value="Yearly">
              {translateLabel(translations, "repeat.yearly.label")}
            </MenuItem>
          )}
          {isOptionAvailable("Monthly") && (
            <MenuItem value="Monthly">
              {translateLabel(translations, "repeat.monthly.label")}
            </MenuItem>
          )}
          {isOptionAvailable("Weekly") && (
            <MenuItem value="Weekly">
              {translateLabel(translations, "repeat.weekly.label")}
            </MenuItem>
          )}
          {isOptionAvailable("Daily") && (
            <MenuItem value="Daily">
              {translateLabel(translations, "repeat.daily.label")}
            </MenuItem>
          )}
          {isOptionAvailable("Hourly") && (
            <MenuItem value="Hourly">
              {translateLabel(translations, "repeat.hourly.label")}
            </MenuItem>
          )}
        </TextField>
      </Grid>

      {isOptionSelected("Yearly") && (
        <RepeatYearly
          id={`${id}-yearly`}
          yearly={yearly}
          handleChange={handleChange}
          translations={translations}
        />
      )}
      {isOptionSelected("Monthly") && (
        <RepeatMonthly
          id={`${id}-monthly`}
          monthly={monthly}
          handleChange={handleChange}
          translations={translations}
        />
      )}
      {isOptionSelected("Weekly") && (
        <RepeatWeekly
          id={`${id}-weekly`}
          weekly={weekly}
          handleChange={handleChange}
          translations={translations}
        />
      )}
      {isOptionSelected("Daily") && (
        <RepeatDaily
          id={`${id}-daily`}
          daily={daily}
          handleChange={handleChange}
          translations={translations}
        />
      )}
      {isOptionSelected("Hourly") && (
        <RepeatHourly
          id={`${id}-hourly`}
          hourly={hourly}
          handleChange={handleChange}
          translations={translations}
        />
      )}
    </Grid>
  );
};

Repeat.propTypes = {
  id: PropTypes.string.isRequired,
  repeat: PropTypes.shape({
    frequency: PropTypes.oneOf([
      "Yearly",
      "Monthly",
      "Weekly",
      "Daily",
      "Hourly",
    ]).isRequired,
    yearly: PropTypes.object.isRequired,
    monthly: PropTypes.object.isRequired,
    weekly: PropTypes.object.isRequired,
    daily: PropTypes.object.isRequired,
    hourly: PropTypes.object.isRequired,
    options: PropTypes.shape({
      frequency: PropTypes.arrayOf(
        PropTypes.oneOf(["Yearly", "Monthly", "Weekly", "Daily", "Hourly"]),
      ),
      yearly: PropTypes.oneOf(["on", "on the"]),
      monthly: PropTypes.oneOf(["on", "on the"]),
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default Repeat;

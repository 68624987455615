import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import { ModelViewerWidget as ModelViewerWidgetComponent } from "../components/widgets/model_viewer_widget";
import { ModelViewer } from "../model_viewer/model_viewer";
import { ModelViewerWidgetConfigSerialized } from "./model_viewer_widget.types";
import Widget from "./widget";

export default class ModelViewerWidget extends Widget {
  loaded: Promise<void>;
  private canvas: JQuery<HTMLCanvasElement>;
  private modelUrl: string;
  private modelViewer: ModelViewer;

  static getDomClassName(): string {
    return "model-viewer-widget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as ModelViewerWidgetConfigSerialized;
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    const props = ModelViewerWidgetComponent.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <ModelViewerWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  getSensorIdsForUpdate(): number[] {
    return [];
  }
}

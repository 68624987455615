import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isNil, sortBy } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { SensorJSONObject } from "../../json_api/sensor";
import { SensorValueRangeJSONObject } from "../../json_api/sensor_value_range";
import { AppRoot } from "../common/app_root";
import { SensorValueRangesForm } from "./sensor_value_ranges_form";

const roots: Root[] = [];
/****************** Sensor Form */

export function initializeSensorValueRangesForm(): void {
  $('[data-toggle="sensor-value-ranges-form"]').each((index, element) => {
    //  const organizations = $(element).data('organizations');
    const sensorJsonApi = $(element).data("sensor") as SingleResourceDoc<
      string,
      SensorJSONObject
    >;
    const sensor =
      jsonApiSingleResourceToFlatObject<SensorJSONObject>(sensorJsonApi);

    const sensorValueRangesJsonApi = $(element).data(
      "sensor-value-ranges",
    ) as CollectionResourceDoc<string, SensorValueRangeJSONObject>;
    const sensorValueRanges = isNil(sensorValueRangesJsonApi)
      ? null
      : jsonApiResourceCollectionToFlatObjects<SensorValueRangeJSONObject>(
          sensorValueRangesJsonApi,
        );

    //const mode = $(element).data("mode") as FormMode;

    const root = createRoot(element);
    roots.push(root);
    root.render(
      <AppRoot>
        <SensorValueRangesForm
          sensor={sensor}
          sensorValueRanges={sortBy(sensorValueRanges, (vr) => vr.min)}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all elements
 */
export function destroySensorValueRangesForm(): void {
  roots.forEach((root) => root.unmount());
  roots.length = 0;
}

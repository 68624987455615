import { has, isEmpty, isNil, keys } from "lodash";
import moment from "../initializers/moment";
import { TimeRanges } from "../definitions/bootstrap-daterangepicker";
import { BusinessHours } from "./business_hours";
import { Moment } from "moment";

export type TimeScopeFunction = (
  businessHours?: BusinessHours,
) => [Moment, Moment];

export enum TimeScopesEnum {
  last_hour = "last_hours",
  last_two_hours = "last_two_hours",
  today = "today",
  yesterday = "yesterday",
  current_week = "current_week",
  last_week = "last_week",
  last_two_weeks = "last_two_weeks",
  current_month = "current_month",
  last_month = "last_month",
  current_quarter = "current_quarter",
  last_quarter = "last_quarter",
  current_year = "current_year",
  last_year = "last_year",
}

export type TTimescopeNames = keyof typeof TimeScopesEnum;
export interface TimeScopeMap {
  [timeScope: string]: TimeScopeFunction;
}

export const TimeScopes: TimeScopeMap = {
  last_hour: () => [moment().subtract(1, "hours"), moment().endOf("hour")],
  last_two_hours: () => [moment().subtract(2, "hours"), moment().endOf("hour")],
  today: () => [moment().startOf("day"), moment().endOf("day")],
  yesterday: () => [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
  ],
  current_week: () => [moment().startOf("week"), moment().endOf("day")],
  last_week: () => [
    moment().subtract(1, "weeks").startOf("week"),
    moment().subtract(1, "week").endOf("week"),
  ],
  last_two_weeks: () => [
    moment().subtract(2, "weeks").startOf("week"),
    moment().endOf("day"),
  ],
  current_month: () => [moment().startOf("month"), moment().endOf("month")],
  last_month: () => [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  current_quarter: () => [
    moment().startOf("quarter"),
    moment().endOf("quarter"),
  ],
  last_quarter: () => [
    moment().subtract(1, "quarter").startOf("quarter"),
    moment().subtract(1, "quarter").endOf("quarter"),
  ],
  current_year: () => [moment().startOf("year"), moment().endOf("year")],
  last_year: () => [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
};

function timeScopeToBusinessHoursTimeScope(
  businessHours: BusinessHours,
  timescopeName: keyof typeof TimeScopesEnum,
): [Moment, Moment] {
  return [
    businessHours.startOfBusinessDay(TimeScopes[timescopeName]()[0]),
    businessHours.endOfBusinessDay(TimeScopes[timescopeName]()[1]),
  ];
}

export const TimeScopesWithBusinessHours: TimeScopeMap = {
  last_hour: () => [moment().subtract(1, "hours"), moment().endOf("hour")],
  last_two_hours: () => [moment().subtract(2, "hours"), moment().endOf("hour")],
  today: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "today"),
  yesterday: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "yesterday"),
  current_week: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "current_week"),
  last_week: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "last_week"),
  last_two_weeks: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "last_two_weeks"),
  current_month: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "current_month"),
  last_month: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "last_month"),
  current_quarter: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "current_quarter"),
  last_quarter: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "last_quarter"),
  current_year: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "current_year"),
  last_year: (businessHours: BusinessHours) =>
    timeScopeToBusinessHoursTimeScope(businessHours, "last_year"),
};

export const TimeScopeNames = keys(TimeScopes);

export interface TranslatedTimeScopeWithReverseMap {
  ranges: TimeRanges;
  labelToIdMap: Record<string, string>;
  idToLabelMap?: Record<string, string>;
}

/**
 * Creates a time scope configuration for daterange picker
 * @param availableTimeScopes Filter list of timescopes. Default: all timescopes
 */
export function createTimeRanges(
  availableTimeScopes?: string[],
): TranslatedTimeScopeWithReverseMap {
  if (isEmpty(availableTimeScopes)) {
    availableTimeScopes = TimeScopeNames;
  }

  const ranges: TimeRanges = {};
  const labelToIdMap: Record<string, string> = {};
  const idToLabelMap: Record<string, string> = {};
  if (isNil(gon.business_hours)) {
    availableTimeScopes.forEach((timeScope) => {
      ranges[I18n.t(`frontend.time_range_picker.${timeScope}`)] =
        TimeScopes[timeScope]();
    });
  } else {
    const businessHours = new BusinessHours({
      startOfBusinessDay: gon.business_hours.start_time,
      endOfBusinessDay: gon.business_hours.end_time,
      timezone: gon.business_hours.time_zone,
    });

    availableTimeScopes.forEach((timeScope) => {
      const displayName = I18n.t(`frontend.time_range_picker.${timeScope}`);
      ranges[displayName] =
        TimeScopesWithBusinessHours[timeScope](businessHours);
      labelToIdMap[displayName] = timeScope;
      idToLabelMap[timeScope] = displayName;
    });
  }

  return { ranges, labelToIdMap, idToLabelMap };
}

/**
 * Return the time range for a time scope
 * @param timeScope Name of the time scope
 */
export function getTimeRange(timeScope: string): [Moment, Moment] {
  if (!has(TimeScopes, timeScope)) {
    return [null, null];
  }

  if (isNil(gon.business_hours)) {
    return TimeScopes[timeScope]();
  } else {
    const businessHours = new BusinessHours({
      startOfBusinessDay: gon.business_hours.start_time,
      endOfBusinessDay: gon.business_hours.end_time,
      timezone: gon.business_hours.time_zone,
    });

    return TimeScopesWithBusinessHours[timeScope](businessHours);
  }
}

/**
 * Create a map from localized timescope label to timescope name
 */
export function createTimeScopeTranslationMap(): { [name: string]: string } {
  const timeScopeMap: Record<string, string> = {};
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_hour")}`] =
    "last_hour";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_two_hours")}`] =
    "last_two_hours";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.today")}`] = "today";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.yesterday")}`] =
    "yesterday";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.current_week")}`] =
    "current_week";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_week")}`] =
    "last_week";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_two_weeks")}`] =
    "last_two_weeks";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.current_month")}`] =
    "current_month";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_month")}`] =
    "last_month";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.current_quarter")}`] =
    "current_quarter";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_quarter")}`] =
    "last_quarter";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.current_year")}`] =
    "current_year";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.last_year")}`] =
    "last_year";
  timeScopeMap[`${I18n.t("frontend.time_range_picker.select_custom_range")}`] =
    "custom";

  return timeScopeMap;
}

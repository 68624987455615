import { isNil, noop } from "lodash";
import * as React from "react";

import { AssetJSONObject } from "../../json_api/asset";
import { jsonApiFieldsParamsArray } from "../../json_api/jsonapi_tools";
import { SensorJSONObject } from "../../json_api/sensor";
import { sensorsJsonApiSearchPath } from "../../utils/urls";
import { applyParamsToBaseUrl } from "../../utils/urls/url_utils";
import { ItemSearchAutocomplete } from "../common/item_autocomplete";

export interface SensorsAutocompleteProps {
  maxItems: number;
  assetId?: number;
  sensor?: SensorJSONObject;
  label?: string;
  variant?: "standard" | "outlined" | "filled";

  loadSearchResults?: boolean;
  width: number | string;
  sensors?: SensorJSONObject[];

  /** Base url for retrieving assets. Exected to accept 'search' parameter
   *
   *
   * @type {string}
   * @memberof SensorsAutocomplete
   */
  sensorsBaseUrl?: string;

  onSelect?: (asset: SensorJSONObject) => void;
}

export class SensorsAutocomplete extends React.Component<SensorsAutocompleteProps> {
  static defaultProps: Partial<SensorsAutocompleteProps> = {
    onSelect: noop,
    width: 300,
    loadSearchResults: true,

    maxItems: 4,
  };

  constructor(props: SensorsAutocompleteProps) {
    super(props);
  }
  render(): React.ReactNode {
    return (
      <ItemSearchAutocomplete<SensorJSONObject>
        selectedItem={this.props.sensor}
        items={this.props.sensors}
        maxItems={this.props.maxItems}
        itemType="sensors"
        label={this.props.label}
        width={this.props.width}
        variant={this.props.variant}
        onSelect={(sensor) => {
          this.props.onSelect(sensor);
        }}
        urlForRequest={(searchTerm) =>
          isNil(this.props.sensorsBaseUrl)
            ? sensorsJsonApiSearchPath(
                searchTerm,
                this.props.assetId,
                1,
                this.props.maxItems,
                ["asset", "sensor_type"],

                jsonApiFieldsParamsArray<SensorJSONObject>("sensors", [
                  "id",
                  "name",
                  "asset",
                  "sensor_type",
                ]).concat(
                  jsonApiFieldsParamsArray<AssetJSONObject>("assets", [
                    "id",
                    "name",
                  ]).concat(
                    jsonApiFieldsParamsArray("sensor_types", ["id", "name"]),
                  ),
                ),
              )
            : applyParamsToBaseUrl(
                this.props.sensorsBaseUrl,
                1,
                this.props.maxItems,
                [],

                jsonApiFieldsParamsArray<SensorJSONObject>("organizations", [
                  "id",
                  "name",
                ]),
              )
        }
      />
    );
  }
}

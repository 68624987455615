import { applyParamsToBaseUrl, IDType, localizedBasePath } from "./url_utils";

export function apiManufacturersUrl(
  page: number = null,
  pageSize: number = null,
) {
  const url = `${localizedBasePath(true)}/manufacturers`;

  return applyParamsToBaseUrl(url, page, pageSize);
}

export function apiManufacturerUrl(id: IDType) {
  return `${localizedBasePath(true)}/manufacturer/${id}`;
}

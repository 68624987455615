import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { ResourcePermission } from "../../models/resource_permission";
import { EventPatternIndexPage } from "./event_pattern_index_page";
import { AppRoot } from "../../components/common/app_root";
import { toInteger } from "lodash";

const eventPatternIndexRoot: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeEventPatternIndexPage(): void {
  $('[data-toggle="event-pattern-index"]').each((index, element) => {
    const sensorId = $(element).data("sensor-id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    eventPatternIndexRoot.push(root);
    root.render(
      <AppRoot>
        <EventPatternIndexPage
          sensorId={toInteger(sensorId)}
          permissions={permissions}
        />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyEventPatternIndexPage(): void {
  eventPatternIndexRoot.forEach((root) => {
    root.unmount();
  });
  eventPatternIndexRoot.length = 0;
}

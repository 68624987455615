import {
  Button,
  Fade,
  Grid,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { SingleResourceDoc } from "jsonapi-typescript";
import {
  AssetEventJSONAPIAttributes,
  AssetEventJSONObject,
} from "../../json_api/asset_event";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { api_asset_event_path, asset_asset_event_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { assetPath } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { Icon } from "../common/icon";
import { SeverityLevelChip } from "../common/severity_level";
import { getTranslatedProp } from "../../utils/globalize";

export interface AssetEventItemProps {
  event?: AssetEventJSONObject;
  eventId?: IDType;
  descriptionLen?: number;

  onShowDetails?: (event: AssetEventJSONObject) => void;
}

export const AssetEventItem: FunctionComponent<AssetEventItemProps> = ({
  descriptionLen = 100,
  ...props
}) => {
  const [event, setEvent] = React.useState<AssetEventJSONAPIAttributes>(
    props.event,
  );

  const [loading, setLoading] = React.useState(isNil(event));
  React.useEffect(() => {
    if (props.event) {
      setEvent(props.event);
    } else if (!isNil(props.eventId) && props.eventId != props.event?.id) {
      setLoading(true);
      loadDataFromUrl<SingleResourceDoc<string, AssetEventJSONAPIAttributes>>(
        api_asset_event_path(props.eventId),
      )
        .then((resp) => {
          setEvent(jsonApiSingleResourceToFlatObject(resp));
        })
        .catch((e) => {
          void error(I18n.t("frontend.error"), e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.eventId, props.event]);

  let descr = isNil(event.description) ? null : event.description;
  let titleDescription = isNil(descr) || isEmpty(descr) ? "" : `: ${descr}`;
  if (descr && descriptionLen) {
    descr =
      descr.length > descriptionLen
        ? descr.substring(0, descriptionLen) + " ..."
        : descr;
  }

  if (!isNil(event?.action)) {
    titleDescription = `${titleDescription}\n\n${I18n.t(
      "activerecord.attributes.asset_event.action",
    )}: ${event.action}`;
  } else if (!isEmpty(event?.event_type?.action)) {
    titleDescription = `${titleDescription}\n\n${I18n.t(
      "activerecord.attributes.asset_event.action",
    )}: ${event.event_type.action}`;
  }
  return loading || isNil(event) ? (
    <Skeleton variant="rectangular" height={40} />
  ) : (
    <Tooltip title={`${event.name}${titleDescription}`}>
      <Fade in>
        <Grid item container xs={12} className="border-bottom" p={2}>
          <Grid item xs={3} sm={3} className="text-center">
            <small className="date">
              {event.from != event.to &&
              !isEmpty(event.to) &&
              isEmpty(!event.from)
                ? `${I18n.l(
                    "time.formats.sialogic_short_no_year",
                    new Date(event.from),
                  )} - ${I18n.l(
                    "time.formats.sialogic_short_no_year",
                    new Date(event.to),
                  )} `
                : I18n.l(
                    "time.formats.sialogic_short_no_year",
                    new Date(event.from),
                  )}
            </small>
            <br />
            <SeverityLevelChip severityLevel={event.severity_level} />
          </Grid>
          <Grid item container xs={9} justifyContent="space-between">
            <Grid item>
              <Icon
                icon={
                  isEmpty(event.icon) || isNil(event.icon)
                    ? "calendar"
                    : (event.icon as string)
                }
                color={event.event_type?.color}
              />
              <Typography variant="body2" component="span" marginLeft={1}>
                {event.name ?? event.event_type?.name}
              </Typography>
              <br />
              <Typography variant="caption">
                <Link href={assetPath(event.root_asset?.id || event.asset.id)}>
                  {getTranslatedProp(
                    event.asset ?? event.root_asset,
                    "name",
                    I18n.locale,
                  )}
                </Link>{" "}
                {!isNil(event.root_asset?.id) &&
                event.asset?.id !== event.root_asset?.id ? (
                  <>
                    {" - "}
                    <Link href={assetPath(event.asset_id)}>
                      {getTranslatedProp(event.root_asset, "name", I18n.locale)}
                    </Link>
                  </>
                ) : null}
              </Typography>

              <p className="mb-1">{descr}</p>
            </Grid>
            <Grid item>
              {isNil(props.onShowDetails) ? (
                <Button
                  size="small"
                  //variant="body2"
                  href={asset_asset_event_path(event.asset_id, event.id, {
                    asset_id: event.asset_id,
                    id: event.id,
                    _options: true,
                  })}
                >
                  {I18n.t("base.more")}...
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={(uiEvent) => {
                    props.onShowDetails(event);
                  }}
                >
                  {I18n.t("base.more")}...
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Tooltip>
  );
};

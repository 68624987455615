import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import * as React from "react";

import { each } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { AssetJSONObject } from "../../json_api/asset";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { OrganizationJSONObject } from "../../json_api/organization";
import { OrganizationRelationType } from "../../models/organization_relation";
import { getBoolAttribute } from "../../utils/data_attributes";
import { AppRoot } from "../common/app_root";
import { OrganizationAssignAssetForm } from "./organization_assign_asset_form";
import { OrganizationsAutocomplete } from "./organizations_autocomplete";

const organizationAutocompletes: Root[] = [];
/**
 * Initialize react component MaintenanceContainer within all elements with data-toggle="organizations-autocomplete".
 *
 *
 */
export function initializeOrganizationsAutocomplete(): void {
  $('[data-toggle="organizations-autocomplete"]').each((index, element) => {
    //const organizations = $(element).data('organizations');
    const root = createRoot(element);
    const organizationJsonApi = $(element).data(
      "organization",
    ) as SingleResourceDoc<string, OrganizationJSONObject>;
    const org =
      jsonApiSingleResourceToFlatObject<OrganizationJSONObject>(
        organizationJsonApi,
      );
    const url: string = $(element).data("organization-base-url") as string;
    //const assetTemplates = jsonApiSingleResourceToFlatObject(assetTemplateJsonApi) as any;
    root.render(
      <AppRoot>
        <OrganizationsAutocomplete organization={org} />
      </AppRoot>,
    );
    organizationAutocompletes.push(root);
  });
}

/**
 * Remove react component from all elements
 */
export function destroyOrganizationsAutocomplete(): void {
  each(organizationAutocompletes, (root) => root.unmount());
  organizationAutocompletes.length = 0;
}

const organizationAssignAssetForms: Root[] = [];
/**
 * Initialize react component Organizations Assign Asset Form element within all elements with data-toggle="organization-assign-asset-form".
 *

 */
export function initializeOrganizationAssignAssetFrom(): void {
  $('[data-toggle="organization-assign-asset-form"]').each((index, element) => {
    const theElem = $(element);
    //const organizations = $(element).data('organizations');
    const organizationJsonApi = theElem.data(
      "organizations",
    ) as CollectionResourceDoc<string, OrganizationJSONObject>;
    const orgs =
      jsonApiResourceCollectionToFlatObjects<OrganizationJSONObject>(
        organizationJsonApi,
      );
    const currentAssetJsonApi = theElem.data("asset") as SingleResourceDoc<
      string,
      AssetJSONObject
    >;
    const currentAsset = jsonApiSingleResourceToFlatObject(currentAssetJsonApi);
    const currentAssetId = theElem.data("asset-id") as number;
    const relationType = theElem.data("rel-type") as OrganizationRelationType;
    const moveAsset = getBoolAttribute(theElem, "data-move", true);
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <OrganizationAssignAssetForm
          ibox={true}
          asset={currentAsset}
          assetId={currentAssetId}
          organizations={orgs}
          relationType={relationType}
          moveAsset={moveAsset}
        />
      </AppRoot>,
    );
    organizationAssignAssetForms.push(root);
  });
}

/**
 * Remove react component from all elements
 */
export function destroyOrganizationAssignAssetForm(): void {
  each(organizationAssignAssetForms, (r) => r.unmount());
  organizationAssignAssetForms.length = 0;
}

import { defaultTo } from "lodash";

import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  LevelIndicatorWidget as LevelIndicatorWidgetComponent,
  LevelIndicatorWidgetDefinition,
} from "../components/widgets/level_indicator_widget";
import { LevelIndicatorWidgetDisplayMode } from "../components/widgets/level_indicator_widget.types";
import { LevelIndicatorWidgetConfigSerialized } from "./level_indicator_widget.types";
import { SensorValueWidget } from "./sensor_value_widget";

/** Widget encapsulating a sensor value display that can be updated programmatically
 * requires a set of spans as child elements to set the values
 * <span class="value"></span><span class="unit"></span>
 * ans <small|span class="timestamp">
 *
 * reads initial values from data attributes if provided:
 * data-sensor-id: sensor id
 * data-attribute-key-id: attribute key id to subscribe
 * data-unit: unit string
 * data-value: value
 * data-time: timestamp in ISO 8601 format
 * data-disable-update: disables the data update
 * @class SensorValuePanel
 * @extends {Widget}
 */
export default class LevelIndicatorWidget extends SensorValueWidget {
  noStepIntervals: boolean;

  displayMode: LevelIndicatorWidgetDisplayMode;

  static getDomClassName(): string {
    return "level-indicator-widget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data(
      "config",
    ) as LevelIndicatorWidgetConfigSerialized;

    this.noStepIntervals = defaultTo(config.no_step_intervals, null);
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element, false);
    const props = LevelIndicatorWidgetDefinition.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <LevelIndicatorWidgetComponent {...props} />
      </AppRoot>,
    );
  }
  cleanup(): void {
    super.cleanup();
  }
}

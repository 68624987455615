import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  ImageWidgetDefinition,
  ImageWidget as ImageWidgetComponent,
} from "../components/widgets/image_widget";
import { ImageWidgetConfigSerialized } from "./image_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the ImageWidget.
 *
 * @class ImageWidget
 * @extends {Widget}
 */
export default class ImageWidget extends Widget {
  url: string;
  alt: string;
  maxWidth: string;
  minWidth: string;

  static getDomClassName(): string {
    return "image-widget";
  }

  static widgetClassName(): string {
    return "ImageWidget";
  }

  constructor(element: JQuery<HTMLElement>) {
    super(element);
    // init the members in the context of the current this, should only be called in leaf classes
    // otherwise the properties will not be attached to the correct 'this', pass "false" to super call if this is not a leaf class
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = ImageWidgetDefinition.serializedConfigToProps(this.config);
    this.root.render(
      <AppRoot>
        <ImageWidgetComponent
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as ImageWidgetConfigSerialized;
  }

  cleanup(): void {
    super.cleanup();
  }
}

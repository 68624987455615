import { Avatar, Grid } from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
} from "lodash";
import * as React from "react";
import { getStateColor } from "../../utils/colors";
import { FaSizeType } from "../common/icon";
import { getIconForName } from "../common/icon_for_name";
export interface StateDisplayProps {
  inline?: boolean;
  stateName?: string;
  criticality?: number;
  stateColor?: string;
  stateIcon?: string;
  iconSize?: FaSizeType;
  imageUrl?: string;
  stateDescription?: string;
}

function getInlineContent(
  props: StateDisplayProps,
  styles: React.CSSProperties,
) {
  const displayName = defaultTo(props.stateName, "---");
  return (
    <Grid container style={styles} title={displayName}>
      <Grid item>
        {isNil(props.imageUrl) ? (
          getIconForName(
            props.stateIcon,
            defaultTo(props.iconSize, "1x" as FaSizeType),
          )
        ) : (
          <Avatar src={props.imageUrl} />
        )}
      </Grid>
      <Grid item className="my-auto">
        <span className="state-title ml-2">
          {defaultTo(props.stateName, "---")}
        </span>
      </Grid>
    </Grid>
  );
}

function getRowContent(props: StateDisplayProps, styles: React.CSSProperties) {
  const displayName = defaultTo(props.stateName, "---");
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="text-center"
        style={styles}
        title={props.stateDescription}
      >
        {isNil(props.imageUrl) ? (
          <Avatar
            style={{
              width: "4em",
              height: "4em",
              background: "transparent",
              color: styles.color,
            }}
            className="m-auto"
          >
            {getIconForName(props.stateIcon, defaultTo(props.iconSize, "2x"))}
          </Avatar>
        ) : (
          <Avatar
            style={{
              height: "4em",
              width: "4em",
              borderColor: styles.color,
              borderWidth: 2,
            }}
            src={props.imageUrl}
            className="m-auto"
          />
        )}
      </Grid>

      <Grid item container className="mt-2" xs={12}>
        <Grid
          item
          xs={12}
          className="text-center"
          title={props.stateDescription}
        >
          <span className="state-title" title={displayName} style={styles}>
            {displayName}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
}
export function StateDisplay(props: StateDisplayProps) {
  const styles: React.CSSProperties = {};
  const color = getStateColor({
    criticality: props.criticality,
    color: props.stateColor,
  });
  if (!isNil(color)) {
    styles.color = color;
  }

  if (props.inline) {
    return getInlineContent(props, styles);
  } else {
    return getRowContent(props, styles);
  }
}

import { clamp, defaultTo, isNil, toNumber } from "lodash";
import {
  MappedSensor,
  SensorMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import * as colors from "../../utils/colors";
import { getValueRangeForValue } from "../../utils/status_helper";
import { BaseMapping } from "./mapping_base";
import { percentageValue } from "./utils";
import { Action } from "../../actions/actions.types";

export class ColorMapping extends BaseMapping<SVGElement> {
  color_scheme?: string;
  use_color_from_value_ranges?: boolean;
  invert?: boolean;

  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, onClick);

    this.color_scheme = defaultTo(config?.color_scheme, "interpolateRdYlGn");
    this.use_color_from_value_ranges = defaultTo(
      config?.use_value_ranges,
      false,
    );
    this.invert = defaultTo(config.invert, false);
  }

  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    super.setElementInfoFromSvg(svgElement);
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    if (isNil(this.element) || isNil(sensorConfig?.value)) return;

    const value = toNumber(defaultTo(sensorConfig?.value, 0.0));
    let color = "#ffffff";
    if (this.use_color_from_value_ranges) {
      const range = defaultTo(
        getValueRangeForValue(value, sensorConfig?.sensor?.value_ranges),
        null,
      );
      color = defaultTo(range?.color, color);
    } else {
      const min = defaultTo(sensorConfig?.range?.min, 0.0);
      const max = defaultTo(sensorConfig?.range?.max, 100.0);
      color = this.getColorForValue(value, min, max);
    }
    // Apply svg transform
    this.element.setAttribute("fill", color);
  }

  protected getColorForValue(value: number, min: number, max: number): string {
    let normalizedValue: number = clamp(
      percentageValue(value, min, max) / 100.0,
      0,
      1,
    );
    if (this.invert) {
      normalizedValue = 100.0 - normalizedValue;
    }
    const col = colors.ColorUtils.getColorFor(
      normalizedValue,
      this.color_scheme,
    );
    return col;
  }
}

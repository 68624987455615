import { Clear, Search } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridDensity,
  GridRowModel,
} from "@mui/x-data-grid";
import * as JSONAPI from "jsonapi-typescript";
import {
  defaultTo,
  first,
  isEmpty,
  isNaN,
  isNil,
  map,
  sortedUniq,
  toInteger,
  toNumber,
  toString,
} from "lodash";
import * as React from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { OrganizationJSONAPIAttributes } from "../../json_api/organization";
import {
  ItemAction,
  generateLinkForItemActionRoute,
} from "../../models/item_action";
import { Organization } from "../../models/organization";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import {
  OrganizationIncludes,
  organizationPath,
  organizationsJsonApiPath,
  organizationsJsonApiSearchPath,
  removeAssetOrganizationJsonApiPath,
} from "../../utils/urls";
import { ParamsType, applyParamsToBaseUrl } from "../../utils/urls/url_utils";
import { ItemActionButton } from "../common/item_action_button";
import { OrganizationAvatar } from "../common/organization_icon";

export interface OrganizationsListProps {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  organizations?: Organization[];
  assetId?: number;
  tableHeight?: number;
  maxTableHeight?: string;
  organizationsBaseUrl?: string;
  organizationActions?: ItemAction[];
  density: GridDensity;
  enableSearch?: boolean;

  onSelect: (org: Organization) => void;
}
export interface OrganizationsListState {
  currentPage?: number;
  pageNumber?: number;
  loadingPage?: number;
  pageSize?: number;
  pageSizes: number[];

  totalPages?: number;
  totalItems?: number;
  organizations?: Organization[];
  searchTerm: string;
  loading: boolean;
}

export class OrganizationsList extends React.Component<
  OrganizationsListProps,
  OrganizationsListState
> {
  static defaultProps: Partial<OrganizationsListProps> = {
    tableHeight: 300,
    pageNumber: 1,
    pageSize: 20,
    maxTableHeight: "75vh",
    enableSearch: true,
  };

  constructor(props: OrganizationsListProps) {
    super(props);
    this.state = {
      ...props,
      pageNumber: defaultTo(this.props.pageNumber, 1),
      currentPage: isNil(props.organizations) ? null : 1,
      loading: false,
      searchTerm: null,
      pageSizes: sortedUniq(
        [10, this.props.pageSize, 25, 50, 100].sort((a, b) => a - b),
      ),
    };
  }

  componentDidMount(): void {
    if (
      isNil(this.props.organizations) &&
      !isEmpty(this.props.organizationsBaseUrl)
    ) {
      this.handlePageChange(1, this.state.pageSize);
    }
  }

  render(): React.ReactNode {
    return (
      <Grid container>
        {this.props.enableSearch ? (
          <Grid item xs={12} className="mb-1">
            <TextField
              size="small"
              className="float-right"
              value={toString(toString(this.state.searchTerm))}
              label={I18n.t("frontend.search")}
              onChange={(event) => {
                this.setState({ searchTerm: event.currentTarget.value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        void this.reloadData();
                      }}
                    >
                      <Search />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="default"
                      onClick={() => {
                        this.setState({ searchTerm: null }, () => {
                          void this.reloadData();
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  void this.reloadData();
                  event.stopPropagation();
                }
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Box
            height={this.props.tableHeight}
            width="100%"
            maxHeight={this.props.maxTableHeight}
          >
            <DataGrid
              paginationMode="server"
              pagination={isNil(this.state.organizations) ? undefined : true}
              paginationModel={{
                pageSize: this.state.pageSize,
                page: (this.state.currentPage || 1) - 1,
              }}
              rowCount={defaultTo(this.state.totalItems, 0)}
              pageSizeOptions={this.state.pageSizes}
              rows={defaultTo(this.state.organizations, [])}
              columns={this.gridColDef()}
              loading={this.state.loading}
              onRowClick={(params) => this.handleRowSelected(params.row)}
              onPaginationModelChange={(model, details) =>
                this.handlePageChange(model.page + 1, model.pageSize)
              }
              initialState={{
                density: defaultTo(this.props.density, "standard"),
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  gridColDef(): GridColDef[] {
    const defaultWidth = 130;
    const largeWidth = 250;
    const columns: GridColDef[] = [
      { field: "id", headerName: "#" },
      {
        field: "logo",
        headerName: "Logo",
        align: "center",
        headerAlign: "center",
        type: "custom",

        renderCell: (params: GridCellParams) => (
          <Tooltip
            title={I18n.t("frontend.organizations.list.show_org", {
              name: params.row.name,
            })}
          >
            <Link
              underline={"none"}
              href={organizationPath(toInteger(params.row.id))}
              justifyContent={"center"}
              display={"flex"}
            >
              <OrganizationAvatar
                organization={params.row}
                size="small"
                variant="rounded"
              />
            </Link>
          </Tooltip>
        ),
      },
      {
        field: "name",
        headerName: I18n.t("activerecord.attributes.organization.name"),
        width: largeWidth,
        renderCell: (params: GridCellParams) => (
          <Tooltip
            title={I18n.t("frontend.organizations.list.show_org", {
              name: params.row.name,
            })}
          >
            <Link href={organizationPath(toInteger(params.row.id))}>
              {params.row.name}
            </Link>
          </Tooltip>
        ),
      },
      {
        field: "description",
        headerName: I18n.t("activerecord.attributes.organization.description"),
        width: largeWidth,
      },
    ];
    if (
      !isNil(this.props.organizationActions) &&
      !isEmpty(this.props.organizationActions)
    ) {
      columns.push({
        field: "actions",
        headerName: I18n.t("base.actions"),
        width: largeWidth,
        renderCell: (params: GridCellParams) => {
          return map(this.props.organizationActions, (action, index) =>
            this.buttonForOrganizationAction(action, params.row, index),
          );
        },
      });
    }
    return columns;
  }

  buttonForOrganizationAction(
    action: ItemAction,
    organization: Organization,
    key: string | number,
  ): React.ReactElement {
    const link = this.linkForOrganizationAction(action, organization);
    const title = defaultTo(
      action.title,
      I18n.t(`frontend.organizations.list.actions.${action.action}`),
    );
    if (!isNil(link)) {
      return (
        <ItemActionButton
          key={key}
          action={action}
          link={link}
          title={title}
          onComplete={() => {
            this.reloadData();
          }}
        />
      );
    } else {
      return null;
    }
  }

  linkForOrganizationAction(
    action: ItemAction,
    organization: Organization,
  ): string {
    if (!isNil(action.action)) {
      if (action.action === "switch")
        return organizationPath(organization.id) + "/switch_current";
      if (action.action === "remove_asset") {
        const assetId = toNumber(first(action.params));
        return isNil(assetId) || isNaN()
          ? null
          : removeAssetOrganizationJsonApiPath(assetId, organization.id);
      }
    } else if (!isEmpty(action.route)) {
      generateLinkForItemActionRoute(
        action.route,
        action.params,
        organization.id,
      );
    }
    return null;
  }

  handlePageChange(pageNumber: number, pageSize: number) {
    void this.loadOrganizations(pageNumber, pageSize);
  }

  handleRowSelected(data: GridRowModel) {
    if (!isNil(this.props.onSelect)) {
      this.props.onSelect(data as Organization);
    }
  }

  reloadData(force = true) {
    void this.loadOrganizations(
      defaultTo(this.state.currentPage, 1),
      this.state.pageSize,
      force,
    );
  }

  async loadOrganizations(
    pageNumber: number,
    pageSize: number,
    forceLoad = false,
  ) {
    try {
      if (
        forceLoad ||
        this.state.currentPage != pageNumber ||
        this.state.pageSize !== pageSize
      ) {
        this.setState({ loadingPage: pageNumber, loading: true });
        if (isNil(pageNumber)) return;
        const path = this.getUrl(pageSize, pageNumber);
        const jsonApiResponse =
          await loadDataFromUrl<
            JSONAPI.CollectionResourceDoc<string, OrganizationJSONAPIAttributes>
          >(path);
        const organizations =
          jsonApiResourceCollectionToFlatObjects<OrganizationJSONAPIAttributes>(
            jsonApiResponse,
          );

        this.setState({
          organizations,
          pageSize: pageSize,
          loading: false,
          loadingPage: null,
          pageNumber: pageNumber,
          currentPage: pageNumber,
          totalItems: toInteger(jsonApiResponse?.meta?.record_count),
          totalPages: toInteger(jsonApiResponse?.meta?.page_count),
        });
      }
    } catch (e) {
      logger.error(e);
    } finally {
      this.setState({ loadingPage: null, loading: false });
    }
  }

  getUrl(pageSize: number, pageNumber: number) {
    const includes: OrganizationIncludes[] = [];
    let path: string;
    if (isNil(this.props.organizationsBaseUrl)) {
      if (this.props.enableSearch && !isEmpty(this.state.searchTerm)) {
        path = organizationsJsonApiSearchPath(
          this.state.searchTerm,
          pageNumber,
          pageSize,
          includes,
        );
      } else {
        path = organizationsJsonApiPath(pageNumber, pageSize, includes);
      }
    } else {
      const params: ParamsType = [];
      if (this.props.enableSearch && !isEmpty(this.state.searchTerm)) {
        params.push(["search", this.state.searchTerm]);
      }
      path = applyParamsToBaseUrl(
        this.props.organizationsBaseUrl,
        pageNumber,
        pageSize,
        includes,
        params,
      );
    }

    return path;
  }
}

import { Close, Done, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Fab,
  FabProps,
  Grid,
  PropTypes,
  Tooltip,
} from "@mui/material";

import { defaultTo, isNil } from "lodash";
import * as React from "react";

export const FloatingButton: React.FunctionComponent<FabProps> = (props) => {
  const btnProps = {
    ...props,
  };
  return <Fab {...btnProps}>{props.children}</Fab>;
};

function scrollToTop() {
  $("body,html").animate(
    {
      scrollTop: 0,
    },
    500,
  );
}

interface FloatingButtonsProps {
  onSubmit?: () => void;
  onCancel?: () => void;

  submitBtnIcon?: React.ReactNode;
  submitBtnColor?: PropTypes.Color;
  disableSave?: boolean;
  disableCancel?: boolean;

  isProcessing?: boolean;

  saveTitle?: string;

  showScrollToTopBtn?: boolean;
  cancelTitle?: string;
  cancelIcon?: React.ReactNode;
  children?: React.ReactNode;
}

export const FloatingButtons: React.FunctionComponent<FloatingButtonsProps> = ({
  disableSave = false,
  isProcessing = false,
  showScrollToTopBtn = false,
  ...props
}: FloatingButtonsProps) => {
  if (isProcessing) {
    return (
      <Fab disabled={true}>
        <CircularProgress className="d-print-none" />
      </Fab>
    );
  }

  return (
    <Grid container spacing={2} className="d-print-none">
      {showScrollToTopBtn ? (
        <Grid item xs={12}>
          <Fab
            disabled={isNil(props.disableCancel) ? false : props.disableCancel}
            className="show-on-scroll-only"
            title={I18n.t("frontend.scroll_up")}
            size="medium"
            style={{ display: "none" }}
            onClick={scrollToTop}
          >
            <KeyboardArrowUp />
          </Fab>
        </Grid>
      ) : null}

      {React.Children.map(props.children, (c) =>
        isNil(c) || c == false ? null : (
          <Grid item xs={12}>
            <Box mx="auto">{c}</Box>
          </Grid>
        ),
      )}
      {isNil(props.onCancel) ? null : (
        <Grid item xs={12} className="d-print-none">
          <Tooltip
            title={
              isNil(props.cancelTitle)
                ? I18n.t("frontend.cancel")
                : props.cancelTitle
            }
          >
            <Fab
              className="cancel"
              size="medium"
              onClick={props.onCancel}
              aria-label={
                isNil(props.cancelTitle)
                  ? I18n.t("frontend.cancel")
                  : props.cancelTitle
              }
            >
              {defaultTo(props.cancelIcon, <Close />)}
            </Fab>
          </Tooltip>
        </Grid>
      )}
      {isNil(props.onSubmit) ? null : (
        <Grid item xs={12}>
          <Tooltip
            title={
              isNil(props.saveTitle)
                ? I18n.t("frontend.submit")
                : props.saveTitle
            }
          >
            <span>
              <Fab
                className="submit d-print-none"
                disabled={disableSave}
                onClick={props.onSubmit}
                aria-label={
                  isNil(props.saveTitle)
                    ? I18n.t("frontend.submit")
                    : props.saveTitle
                }
                color={props.submitBtnColor ?? "primary"}
              >
                {isNil(props.submitBtnIcon) ? <Done /> : props.submitBtnIcon}
              </Fab>
            </span>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

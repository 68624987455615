import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { debounce, defaultTo, isEmpty, isNil, map } from "lodash";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";
import { SensorFilter as SensorFilterInterface } from "../../json_api/sensor";
import { SensorTypeJSONObject } from "../../json_api/sensor_type";
import { IDType } from "../../utils/urls/url_utils";
import { SensorTypeAutocomplete } from "../sensor_types/sensor_types_autocomplete";

export interface SensorFilterProps {
  filter: SensorFilterInterface;
  baseAssetId: IDType;
  useAssetAutocomplete?: boolean;
  selectableAssets: AssetJSONObject[];
  selectableSensorTypes?: SensorTypeJSONObject[];

  onFilterChange: (newFilter: SensorFilterInterface) => void;
}

export const SensorFilter: React.FunctionComponent<SensorFilterProps> = ({
  useAssetAutocomplete = false,
  ...props
}) => {
  const [searchText, setSearchText] = React.useState<string>(
    props.filter.search,
  );

  React.useEffect(() => {
    setSearchText(props.filter.search);
  }, [props.filter.search]);

  React.useEffect(() => {
    if (searchText === null) {
      props.onFilterChange({
        ...props.filter,
        search: null,
      });
    }
  }, [searchText]);

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={12} lg={4}>
        {isNil(props.selectableAssets) && !useAssetAutocomplete ? (
          <Skeleton variant="rectangular" height={40} />
        ) : (
          <Autocomplete<AssetJSONObject | null, true>
            options={props.selectableAssets}
            multiple
            size="small"
            getOptionLabel={(o) => o.name}
            getOptionKey={(o) => o.id}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                label={I18n.t("frontend.sensors.sensor_filter.assets", {
                  count: 2,
                })}
                helperText={I18n.t(
                  "frontend.sensors.sensor_filter.asset_filter_helper_text",
                )}
              />
            )}
            onChange={(event, assets) => {
              if (isEmpty(assets)) {
                props.onFilterChange({
                  ...props.filter,
                  asset: null,
                });
              } else {
                props.onFilterChange({
                  ...props.filter,
                  asset: map(assets, (a) => a.id),
                });
              }
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        <SensorTypeAutocomplete
          assetId={props.baseAssetId}
          selectableSensorTypes={props.selectableSensorTypes}
          onSelectionChange={(sensorTypes) => {
            if (isEmpty(sensorTypes)) {
              props.onFilterChange({
                ...props.filter,
                sensor_type: null,
              });
            } else {
              props.onFilterChange({
                ...props.filter,
                sensor_type: map(sensorTypes, (a) => a.id),
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextField
          type="search"
          fullWidth
          size="small"
          label={I18n.t("frontend.sensors.sensor_filter.search_text")}
          onChange={(e) => {
            debounce((event) => {
              props.onFilterChange({
                ...props.filter,
                search: isEmpty(event.target.value) ? null : event.target.value,
              });
            }, 1000)(e);

            setSearchText(isEmpty(e.target.value) ? null : e.target.value);
          }}
          onReset={() => {
            setSearchText(null);
            props.onFilterChange({
              ...props.filter,
              search: null,
            });
          }}
          onKeyUp={(e) => {
            if (e.key == "Enter") {
              e.stopPropagation();
              props.onFilterChange({
                ...props.filter,
                search: isEmpty(searchText) ? null : searchText,
              });
            }
          }}
          helperText={I18n.t(
            "frontend.sensors.sensor_filter.search_text_filter_helper_text",
          )}
          InputProps={{
            endAdornment: isEmpty(searchText) ? null : (
              <>
                <Tooltip title={I18n.t("frontend.search")}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onFilterChange({
                        ...props.filter,
                        search: isEmpty(searchText) ? null : searchText,
                      });
                    }}
                  >
                    <Search fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

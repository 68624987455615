import { Box, Button, Grid, Typography } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { Device } from "../../models/device";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { error, info, success } from "../../utils/toasts";
import { deviceEndPairingPath, deviceStartPairingPath } from "../../utils/urls";
import { LoadingIcon } from "../common/icon";
export interface DevicePairingProps {
  device: Device;
  ibox: boolean;
}

export const DevicePairing: React.FunctionComponent<DevicePairingProps> = (
  props: DevicePairingProps,
) => {
  const [pairingTimes, setPairingTimes] = React.useState({
    pairingStart: isNil(props.device?.pairing_start)
      ? null
      : new Date(props.device.pairing_start),
    pairingEnd: isNil(props.device?.pairing_start)
      ? null
      : new Date(props.device.pairing_start),
    isPairing:
      !isNil(props.device.pairing_end) &&
      new Date(props.device.pairing_end) > new Date(),
  });

  const [loading, setLoading] = React.useState(false);
  const content = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {I18n.t("activerecord.models.device.one")}: {props.device.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <LoadingIcon size="4x" />
        ) : !pairingTimes.isPairing ? (
          <Button
            color="primary"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              void sendData<
                unknown,
                { pairing_start: string; pairing_end: string }
              >(deviceStartPairingPath(props.device.id), {}, "PATCH")
                .then((result) => {
                  const pairingEnd = new Date(result.pairing_end);
                  setPairingTimes({
                    pairingStart: new Date(result.pairing_start),
                    pairingEnd,
                    isPairing: true,
                  });
                  const now = new Date();
                  void success(
                    I18n.t("frontend.devices.device_pairing.pairing_started"),
                  );
                  void setTimeout(() => {
                    setPairingTimes({ ...pairingTimes, isPairing: false });
                    void info(
                      I18n.t(
                        "frontend.devices.device_pairing.pairing_mode_finished",
                      ),
                    );
                  }, pairingEnd.getTime() - now.getTime());
                })

                .catch((theError: Error) => {
                  logger.error(theError);
                  void error(
                    I18n.t(
                      "frontend.devices.device_pairing.error_starting_pairing",
                    ),
                    theError.message,
                  );
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            {I18n.t("frontend.devices.device_pairing.start_pairing")}
          </Button>
        ) : (
          <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={6}>
              <Box mx="auto">
                <CountdownCircleTimer
                  isPlaying
                  colors={"#004777"}
                  duration={
                    (pairingTimes.pairingEnd.getTime() - new Date().getTime()) /
                    1000
                  }
                >
                  {({ remainingTime }) => {
                    const minutes = Math.floor(remainingTime / 60);
                    const seconds = remainingTime % 60;

                    return (
                      <Box>
                        <Box>
                          <Typography variant="h6" align="center">
                            {I18n.t(
                              "frontend.devices.device_pairing.remaining",
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h5" align="center">
                            {`${minutes}:${seconds} min`}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }}
                </CountdownCircleTimer>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  void sendData<
                    unknown,
                    { pairing_start: string; pairing_end: string }
                  >(deviceEndPairingPath(props.device.id), {}, "PATCH")
                    .then((result) => {
                      const pairingEnd = new Date(result.pairing_end);
                      setPairingTimes({
                        pairingStart: new Date(result.pairing_start),
                        pairingEnd,
                        isPairing: false,
                      });

                      void success(
                        I18n.t(
                          "frontend.devices.device_pairing.pairing_stopped",
                        ),
                      );
                    })

                    .catch((theError: Error) => {
                      logger.error(theError);
                      void error(
                        I18n.t(
                          "frontend.devices.device_pairing.error_stopping_pairing",
                        ),
                        theError.message,
                      );
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
              >
                {I18n.t("frontend.devices.device_pairing.stop_pairing")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
  if (props.ibox) {
    return (
      <IBox>
        <IBoxTitle>
          <Typography variant="h5">
            {I18n.t("frontend.devices.device_pairing.heading")}
          </Typography>
        </IBoxTitle>
        <IBoxContent>{content}</IBoxContent>
      </IBox>
    );
  } else {
    return content;
  }
};

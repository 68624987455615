import { each, isEmpty } from "lodash";
import * as React from "react";
import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { NotificationsDropdownContainer } from "./container/notifications_dropdown_container";

const notificationDropdownContainerRoots: Root[] = [];
/**
 * Mount an event dropdown to all selected elements
 * @param selector A jquery selector for html elements
 */
export function initializeNotificationDropdown(selector: JQuery): void {
  if (isEmpty(selector)) return;
  selector.each((index: number, element: HTMLElement) => {
    const root = createRoot(element, {
      onRecoverableError(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
      },
    });
    notificationDropdownContainerRoots.push(root);
    root.render(
      <AppRoot>
        <NotificationsDropdownContainer
          loadNotifications={!(gon.disableNotificationLoad ?? false)}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove all react components from the selected elements
 */
export function destroy(): void {
  each(notificationDropdownContainerRoots, (root) => {
    root.unmount();
  });
  notificationDropdownContainerRoots.length = 0;
}

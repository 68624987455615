import { Delete, Edit, GetApp, Visibility } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { defaultTo, isEmpty, isNil, map, noop } from "lodash";
import * as React from "react";
import { ActiveStorageStoredFile } from "../../models/active_storage_stored_file";
import { iconForContentType } from "../../utils/icon_for_content_type";
import { activeStorageDownloadUrl } from "../../utils/urls";
import { CopyValueIcon } from "./copy_value";
import { useDrag } from "react-dnd";
import { DnDItemTypes } from "./drag_and_drop";

export interface AttachmentListProperties<
  RecordType extends ActiveStorageStoredFile = ActiveStorageStoredFile,
> {
  attachments?: RecordType[];
  onShow?(file: RecordType): void;
  onDelete?(file: RecordType): void;
  onDownload?(file: RecordType): void;
  onEdit?(file: RecordType): void;
  enableAttachmentDrag?: boolean;
}

type AttributedAttachmentList<
  T extends ActiveStorageStoredFile = ActiveStorageStoredFile,
> = React.FunctionComponent<AttachmentListProperties<T>>;

const AttachmentDropItemType = "attachment";
export function AttachmentList<T extends ActiveStorageStoredFile>(
  props: AttachmentListProperties<T>,
): React.ReactElement {
  return (
    <Grid container spacing={2}>
      {map(props.attachments, (attachment, index) => {
        const showFile = isNil(props.onShow)
          ? noop
          : () => {
              props.onShow(attachment);
            };
        return (
          <AttachmentItem
            key={attachment?.id ? `${attachment.id}` : `${index}`}
            enableDrag={props.enableAttachmentDrag}
            onDelete={props.onDelete}
            onDownload={props.onDownload}
            onEdit={props.onEdit}
            onShow={showFile}
            attachment={attachment}
          />
        );
      })}
    </Grid>
  );
}

interface AttachmentItemProps {
  attachment: ActiveStorageStoredFile;
  onShow?(file: ActiveStorageStoredFile): void;
  onDelete?(file: ActiveStorageStoredFile): void;
  onDownload?(file: ActiveStorageStoredFile): void;
  onEdit?(file: ActiveStorageStoredFile): void;
  enableDrag?: boolean;
}

const AttachmentItem: React.FunctionComponent<AttachmentItemProps> = ({
  attachment,
  onDelete,
  onEdit,
  onDownload,
  onShow,
  enableDrag = false,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDItemTypes.FILE_ATTACHMENT,
      item: attachment,

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [attachment],
  );
  return (
    <Grid
      ref={enableDrag ? drag : null}
      item
      xs={12}
      container
      className="align-items-center"
    >
      <Grid item xs={5} sm={4} xl={3} onClick={() => onShow(attachment)}>
        {isEmpty(attachment.preview_url) ? (
          iconForContentType(attachment.content_type)
        ) : (
          <img
            alt={attachment.filename}
            className="img-thumbnail thumb-120h img-fluid img-thumbnail mx-auto d-block mw-100"
            src={attachment.preview_url}
          />
        )}
      </Grid>
      <Grid item container xs={7} sm={5} xl={6}>
        <Grid item xs={12}>
          <Box style={{ overflowWrap: "anywhere" }}>
            {defaultTo(attachment.filename, attachment.title) as string}
          </Box>
        </Grid>

        {isNil(attachment.key) ? null : (
          <Grid item xs={12}>
            <Typography variant="caption">
              Key: <CopyValueIcon value={attachment.key} />
              {attachment.key}
            </Typography>
          </Grid>
        )}
        {isNil(attachment.created_at) ? null : (
          <Grid item xs={12}>
            <small
              className="align-middle"
              style={{ overflowWrap: "anywhere" }}
            >
              {moment(attachment.created_at).format("L LT")}
            </small>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        container
        displayPrint="none"
        direction="row-reverse"
      >
        <Grid item xs="auto">
          {isNil(onDelete) ? null : (
            <IconButton
              className="mr-2"
              onClick={() => {
                onDelete(attachment);
              }}
            >
              <Delete fontSize="inherit" />
            </IconButton>
          )}
          {isNil(onEdit) ? null : (
            <IconButton
              color="secondary"
              onClick={() => onEdit(attachment)}
              hidden={isNil(onEdit)}
            >
              <Edit fontSize="inherit" />
            </IconButton>
          )}
          <IconButton
            color="primary"
            onClick={() => onShow(attachment)}
            hidden={isNil(onShow)}
          >
            <Visibility fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => {
              if (onDownload) {
                onDownload(attachment);
              } else {
                window.open(activeStorageDownloadUrl(attachment.url), "_blank");
              }
            }}
          >
            <GetApp fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

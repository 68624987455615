import * as React from "react";
import ReactWeather, { useOpenWeather } from "react-open-weather";
interface WeatherDisplayProps {
  lat: number;
  lon: number;

  apiKey: string;
}

export const WeatherDisplay: React.FunctionComponent<WeatherDisplayProps> =
  function (props) {
    const { data, isLoading, errorMessage } = useOpenWeather({
      key: props.apiKey,
      lat: props.lat,
      lon: props.lon,
      lang: I18n.locale,
      unit: "metric", // values are (metric, standard, imperial)
    });

    return (
      //@ts-expect-error 2604
      <ReactWeather
        data={data}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    );
  };

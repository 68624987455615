export function getMaintenancePlanStyle(maintenancePlanType: string): string {
  switch (maintenancePlanType) {
    case "MaintenancePlan":
      return "maintenance-plan";
    case "InspectionPlan":
      return "inspection-plan";
    default:
      return "";
  }
}

/// <reference types="../definitions/index" />;
import { isEmpty, isNil } from "lodash";
import moment from "../initializers/moment";
import * as daterangepicker from "../definitions/bootstrap-daterangepicker";
import { redirectTo } from "./redirection";
import {
  createTimeRanges,
  createTimeScopeTranslationMap,
  getTimeRange,
} from "./time_scopes";
import { Moment } from "moment";

export function initialize(element: JQuery): void {
  const timeScopeMap = createTimeScopeTranslationMap();
  const baseUrl = element.attr("data-base-url");
  const timeScopes = element.attr("data-time-scopes")
    ? element.attr("data-time-scopes").split(",")
    : null;
  const momentLocale = moment.localeData();
  const rangesWithMaps = createTimeRanges(timeScopes);

  const options: daterangepicker.DateRangePickerOptions = {
    opens: "left",
    timePicker: true,
    timePickerIncrement: 1,
    timePickerSeconds: true,
    timePicker24Hour: true,
    showCustomRangeLabel: true,
    locale: {
      format: "L LT",
      customRangeLabel: I18n.t(
        "frontend.time_range_picker.select_custom_range",
      ),
      applyLabel: I18n.t("frontend.time_range_picker.apply"),
      cancelLabel: I18n.t("frontend.time_range_picker.cancel"),
      monthNames: momentLocale.months(),
      firstDay: momentLocale.firstDayOfWeek(),
      daysOfWeek: momentLocale.weekdaysShort(),
    },
    ranges: rangesWithMaps.ranges,
  };

  // load selected time range and scope from data attributes
  let startDate = parseDate(element.attr("data-start-date"));
  let endDate = parseDate(element.attr("data-end-date"));
  const timeScope = element.attr("data-time-scope");

  // overwrite time scope dates when none custom scope is set
  if (timeScope && timeScope !== "custom") {
    [startDate, endDate] = getTimeRange(timeScope);
  }

  // set time range if it could be loaded from data attributes
  if (!isNil(startDate) && !isNil(endDate)) {
    options.startDate = startDate;
    options.endDate = endDate;
  }

  element.daterangepicker(options, (startDate, endDate, timeScope) => {
    element.find("#time-scope-label").text(` (${timeScope})`);
    openTimeScope(baseUrl, startDate, endDate, timeScopeMap[timeScope]);
  });
}

function parseDate(dateString: string): Moment {
  if (isEmpty(dateString)) {
    return null;
  }
  const date = moment(dateString);

  if (!date.isValid()) {
    return null;
  }

  return date;
}

function openTimeScope(
  baseUrl: string,
  startDate: Date,
  endDate: Date,
  timeScope: string,
): void {
  if (timeScope === "custom") {
    redirectTo(
      `${baseUrl}/custom?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
    );
  } else if (!isEmpty(timeScope)) {
    redirectTo(`${baseUrl}/${timeScope}`);
  }
}

import * as React from "react";
import Widget from "./widget";

import { AppRoot } from "../components/common/app_root";
import { WidgetGroupDefinition } from "../components/widgets/widget_group";
import { WidgetGroupConfigSerialized } from "./widget_group.types";

export default class WidgetGroupWidget extends Widget<WidgetGroupConfigSerialized> {
  static getDomClassName(): string {
    return "widget-group-widget";
  }

  static widgetClassName(): string {
    return "WidgetGroupWidget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as WidgetGroupConfigSerialized;
  }
  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    const props = WidgetGroupDefinition.serializedConfigToProps(this.config);
    this.root?.render(
      <AppRoot>
        <WidgetGroupDefinition.Component {...props} />
      </AppRoot>,
    );
  }
}

import {
  Asset,
  MaintenancePlan,
} from "../../maintenance_plan_form/data/models";
import moment, { Moment } from "moment";
import { ErrorMap } from "../../../utils/error_map";

export interface MaintenancePlanWithLastJob extends MaintenancePlan {
  last_maintenance_job_at?: Date;
  runtime_since_last_maintenance_job?: number;
  runtime_until_next_maintenance_job?: number;
  next_runtime_at?: Date;
}

export interface MaintenanceJob {
  type: "MaintenanceJob" | "InspectionJob";
  executed?: boolean;
  description: string;
  asset_id?: string | number;
  maintenance_plan?: MaintenancePlanWithLastJob;
  maintenance_plan_id?: string | number;
  maintenance_period_id: string | number;
  maintenance_period_starts_at?: Moment;
  maintenance_period_ends_at?: Moment;
  maintenance_period_is_initial_period?: boolean;
  user_id?: string | number;
  user_type?: "GuestUser" | "User";
  user_attributes?: User;

  hidden?: boolean;
  /**
   * Errors stored per attribute
   */
  errors?: ErrorMap;
}

export interface InspectionJob extends MaintenanceJob {
  type: "InspectionJob";
  // hold te initial status value that was set
  initial_status_value?: number;
  status_measurement?: {
    note?: string;
    value?: number;
  };
}

export interface MaintenanceJobGroup {
  title: string;
  title_link: string;
  assets: AssetWithMaintenanceJobs[];
}

export interface AssetWithMaintenanceJobs {
  expanded: boolean;
  asset: Asset;
  maintenance_jobs: MaintenanceJob[];
}

export interface User {
  id?: string | number;
  name: string;
  type: "GuestUser" | "User";
}

export interface StatusMeasurement {
  measurement_id: string | number;
  user_id: string | number;
  user_type: string;
  user_attributes?: User;
  note: string;
  value: number;
}

export interface Maintenance {
  done_at: Moment;
  note: string;
  maintenance_jobs_attributes: MaintenanceJob[];
  status_measurements?: StatusMeasurement[];

  /**
   * Errors stored per attribute
   */
  errors?: ErrorMap;
}

export interface CreateMaintenanceRequest {
  maintenance: Maintenance;
}

export interface CreateMaintenanceErrorResponse {
  maintenance: {
    errors: ErrorMap;
    maintenance_jobs: Array<{
      asset_id: string | number;
      maintenance_plan_id: string | number;
      errors: ErrorMap;
    }>;
  };
}

export function getMaintanceJobType(
  maintenancePlan: MaintenancePlan,
): "MaintenanceJob" | "InspectionJob" {
  switch (maintenancePlan.type) {
    case "MaintenancePlan":
      return "MaintenanceJob";
    case "InspectionPlan":
      return "InspectionJob";
  }
}

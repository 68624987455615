import Pagination from "@mui/material/Pagination";
import * as JSONAPI from "jsonapi-typescript";
import { clone, findIndex, isEmpty, isNil, map } from "lodash";
import * as React from "react";

import { jsonApiPagingParamsArray } from "../../../json_api/jsonapi_tools";
import { ReportPlanJSONAPIAttributes } from "../../../json_api/report_plan";
import { loadDataFromUrl, sendData } from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import { success } from "../../../utils/toasts";
import {
  assetReportPlanSubscribePath,
  assetReportPlanUnsubscribePath,
} from "../../../utils/urls";
import { applyParamsDataToBaseUrl } from "../../../utils/urls/url_utils";
import { IBox, IBoxContent, IBoxFooter } from "../../common/ibox";
import { LoadingIcon } from "../../common/icon";
import { ReportPlanAttributes } from "../data/models";
import { ReportPlanItem } from "./report_plan_item";

interface ReportPlanListProperties {
  reportPlansUrl?: string;
  reportPlans?: ReportPlanAttributes[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
}

interface ReportPlanListState {
  isFetching: boolean;
  reportPlans?: ReportPlanAttributes[];
  loadedPage?: number;
  currentPage?: number;
  totalPages?: number;
}

export class ReportPlanList extends React.Component<
  ReportPlanListProperties,
  ReportPlanListState
> {
  constructor(props: ReportPlanListProperties) {
    super(props);
    this.state = {
      isFetching: false,
      reportPlans: props.reportPlans,
      loadedPage: !isNil(props.reportPlans) ? props.currentPage : null,
      currentPage: props.currentPage,
      totalPages: props.totalPages,
    };
  }
  componentDidMount(): void {
    if (!isNil(this.props.reportPlansUrl) && isNil(this.state.reportPlans)) {
      this.fetchReportPlans();
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <IBox>
          <IBoxContent>
            {this.state.isFetching ? (
              <div className="text-center">
                <LoadingIcon size="6x" />
              </div>
            ) : null}
            {isEmpty(this.state.reportPlans) ? (
              <div className="text-center">
                {I18n.t("frontend.report_plan_list.no_report_plans")}
              </div>
            ) : (
              map(this.state.reportPlans, (reportPlan, index) => {
                return (
                  <ReportPlanItem
                    reportPlan={reportPlan}
                    onSubscribe={(plan) => {
                      void this.onSubscribe(plan);
                    }}
                    onUnsubscribe={(plan) => {
                      void this.onUnsubscribe(plan);
                    }}
                    key={index}
                  />
                );
              })
            )}
          </IBoxContent>
          {this.state.totalPages > 1 ? (
            <IBoxFooter>
              <Pagination
                page={this.state.currentPage}
                count={this.state.totalPages}
                onChange={(event, pageNumber) => {
                  this.onSelectPage(pageNumber);
                }}
              />
            </IBoxFooter>
          ) : null}
        </IBox>
      </>
    );
  }

  onSelectPage(pageNumber: number): void {
    if (this.state.loadedPage != pageNumber) {
      this.setState(
        (prevState) => {
          return {
            loadedPage: prevState.currentPage,
            currentPage: pageNumber,
            isFetching: true,
            reportPlans: null as ReportPlanJSONAPIAttributes[],
          };
        },
        () => {
          this.fetchReportPlans();
        },
      );
    }
  }

  onSubscribe(reportPlan: ReportPlanAttributes): Promise<any> {
    return sendData(
      assetReportPlanSubscribePath(reportPlan.asset_id, reportPlan.id, "json"),
      "",
      "PATCH",
    )
      .then(
        (
          result: JSONAPI.SingleResourceDoc<
            string,
            ReportPlanJSONAPIAttributes
          >,
        ) => {
          this.handlPlanUpdate(result);
          return success(
            I18n.t("frontend.report_plan_list.subscribe_successful", {
              name: reportPlan.report_spec_name,
            }),
          );
        },
      )
      .catch((err) => {
        logger.error(err);
      });
  }

  onUnsubscribe(reportPlan: ReportPlanAttributes): Promise<any> {
    return sendData<
      string,
      JSONAPI.SingleResourceDoc<string, ReportPlanJSONAPIAttributes>
    >(
      assetReportPlanUnsubscribePath(
        reportPlan.asset_id,
        reportPlan.id,
        "json",
      ),
      "",
      "PATCH",
    )
      .then(
        (
          result: JSONAPI.SingleResourceDoc<
            string,
            ReportPlanJSONAPIAttributes
          >,
        ) => {
          this.handlPlanUpdate(result);
          return success(
            I18n.t("frontend.report_plan_list.unsubscribe_successful", {
              name: reportPlan.report_spec_name,
            }),
          );
        },
      )
      .catch((err) => {
        logger.error(err);
      });
  }

  handlPlanUpdate(
    planDoc: JSONAPI.SingleResourceDoc<string, ReportPlanJSONAPIAttributes>,
  ): void {
    const index = findIndex(
      this.state.reportPlans,
      (plan) => plan.id == planDoc.data.id,
    );

    if (isNil(index)) return;

    const plans = clone(this.state.reportPlans);
    plans[index] = {
      ...planDoc.data.attributes,
      id: planDoc.data.id,
      subscribed: planDoc.data.attributes.subscribed,
    };
    this.setState({ reportPlans: plans });
  }

  fetchReportPlans(force = false): void {
    if (this.state.currentPage != this.state.loadedPage) {
      loadDataFromUrl<
        JSONAPI.CollectionResourceDoc<string, ReportPlanJSONAPIAttributes>
      >(
        applyParamsDataToBaseUrl(
          `${this.props.reportPlansUrl}.json`,
          jsonApiPagingParamsArray(this.state.currentPage, this.props.pageSize),
        ),
      )
        .then((fetchedReports) => {
          this.setState((prevState) => {
            return {
              reportPlans: map(fetchedReports.data, (reportJSONAPI) => {
                return { ...reportJSONAPI.attributes, id: reportJSONAPI.id };
              }),
              loadedPage: prevState.currentPage,
              isFetching: false,
            };
          });
        })
        .catch((err) => {
          this.setState({
            reportPlans: null,
            loadedPage: null,
            currentPage: null,
            isFetching: false,
          });
        });
    }
  }
}

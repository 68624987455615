import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import {
  AssetInfoWidget as AssetInfoWidgetComponent,
  AssetInfoWidgetDefinition,
} from "../components/widgets/asset_info_widget";
import { AssetInfoWidgetConfigSerialized } from "./asset_info_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the AssetListWidget.
 *
 * @class AssetListWidget
 * @extends {Widget}
 */
export default class AssetInfoWidget extends Widget {
  static getDomClassName(): string {
    return "asset-info-widget";
  }

  static widgetClassName(): string {
    return "AssetInfoWidget";
  }

  config: AssetInfoWidgetConfigSerialized;

  readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as AssetInfoWidgetConfigSerialized;
  }

  protected initComponent(element: JQuery<HTMLElement>): void {
    super.initComponent(element);
    if (this.config) {
      const props = AssetInfoWidgetDefinition.serializedConfigToProps(
        this.config,
      );
      this.root.render(
        <AppRoot>
          <AssetInfoWidgetComponent {...props} encloseInIBox />
        </AppRoot>,
      );
    }
  }
  cleanup(): void {
    super.cleanup();
  }
}

import { ContentCopy } from "@mui/icons-material";
import { toString } from "lodash";
import * as React from "react";
import { success } from "../../utils/toasts";
import { SvgIconProps } from "@mui/material";

interface CopyValueProps {
  // Value to display and copy to clipboard
  value: string | number;
}
export const CopyValueIcon: React.FunctionComponent<
  CopyValueProps & SvgIconProps
> = (props) => (
  <ContentCopy
    {...props}
    fontSize="inherit"
    onClick={(e) => {
      e.stopPropagation();
      const valueToCopy = props.value;
      void navigator.clipboard.writeText(toString(valueToCopy)).then(() =>
        success(
          I18n.t("frontend.copied"),
          I18n.t("frontend.copied_to_clipboard", {
            text: valueToCopy,
          }),
        ),
      );
    }}
  />
);

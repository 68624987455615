export function translatedModelName(
  modelNameUnderscore: string,
  plural = false,
): string {
  return I18n.t(`activerecord.models.${modelNameUnderscore}`, {
    count: plural ? 2 : 1,
    defaultValue: modelNameUnderscore,
  });
}

export function translatedAttributeName(
  modelNameUnderscore: string,
  attributeNameUnderscore: string,
): string {
  return I18n.t(
    `activerecord.attributes.${modelNameUnderscore}.${attributeNameUnderscore}`,
    { defaultValue: attributeNameUnderscore },
  );
}

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import JSON from "json-typescript";
import { defaultTo, toString } from "lodash";
import React from "react";
import { AssetPropertyDefinitionJSONObject } from "../../json_api/asset_property_definition";
import { unitDisplayString } from "../../utils/unit_conversion";

interface AssetPropertiesListEntryProps {
  assetPropertyDefinition: AssetPropertyDefinitionJSONObject;
  assetProperties: JSON.Object;
  onAssetPropertyChange: (newProperties: JSON.Object) => void;
  readOnly?: boolean;
}

const AssetPropertiesListEntry: React.FC<AssetPropertiesListEntryProps> = ({
  assetPropertyDefinition: definition,
  assetProperties,
  onAssetPropertyChange,
  readOnly = true,
}) => {
  const [checkBoxHovered, setCheckBoxHovered] = React.useState(false);

  return (
    <>
      {definition.data_type === "boolean" && (
        <FormControl
          fullWidth
          sx={{ marginBottom: 0 }}
          onMouseEnter={() => {
            setCheckBoxHovered(true);
          }}
          onMouseLeave={() => {
            setCheckBoxHovered(false);
          }}
          size="small"
        >
          <FormControlLabel
            sx={{
              marginY: 0,
              marginX: 0,

              border: 1,
              borderRadius: "4px",
              borderColor: checkBoxHovered
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(0, 0, 0, 0.23)",
            }}
            control={
              <Checkbox
                id={definition.key}
                readOnly
                checked={Boolean(assetProperties[definition.key])}
                onChange={(e) => {
                  !readOnly &&
                    onAssetPropertyChange({
                      ...assetProperties,
                      [definition.key]: e.target.checked,
                    });
                }}
                size="small"
              />
            }
            label={definition.name || definition.key}
          />
          <FormHelperText>{definition.description}</FormHelperText>
        </FormControl>
      )}
      {definition.data_type === "integer" && (
        <TextField
          type="number"
          size="small"
          id={definition.key}
          fullWidth
          label={definition.name || definition.key}
          helperText={definition.description}
          value={parseInt(assetProperties[definition.key]?.toString())}
          InputProps={{
            readOnly,
            endAdornment: unitDisplayString(definition.unit as string),
          }}
          onChange={(e: any) => {
            onAssetPropertyChange({
              ...assetProperties,
              [definition.key]: parseInt(e.target.value),
            });
          }}
        />
      )}
      {definition.data_type === "float" && (
        <TextField
          type="number"
          size="small"
          id={definition.key}
          value={defaultTo(
            parseFloat(assetProperties[definition.key]?.toString()),
            "",
          )}
          fullWidth
          label={definition.name || definition.key}
          helperText={definition.description}
          InputProps={{
            readOnly,
            endAdornment: unitDisplayString(definition.unit as string),
          }}
          onChange={(e: any) => {
            onAssetPropertyChange({
              ...assetProperties,
              [definition.key]: parseFloat(e.target.value),
            });
          }}
        />
      )}
      {definition.data_type === "string" && (
        <TextField
          margin="dense"
          size="small"
          fullWidth
          label={definition.name || definition.key}
          helperText={definition.description}
          id={definition.key}
          value={toString(assetProperties[definition.key])}
          InputProps={{
            readOnly,
            endAdornment: unitDisplayString(definition.unit as string),
          }}
          onChange={(e) =>
            onAssetPropertyChange({
              ...assetProperties,
              [definition.key]: parseInt(e.target.value),
            })
          }
        />
      )}
    </>
  );
};

export default AssetPropertiesListEntry;

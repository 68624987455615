import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { ProductModelAttributes } from "../models/product_model";
import { AssetTypeJSONObject } from "./asset_type";
import { ManufacturerJSONObject } from "./manufacturer";

export const PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE = "product_models";

export interface ProductModelJSONObject
  extends ProductModelAttributes,
    JSON.Object {
  asset_type?: AssetTypeJSONObject;
  manufacturer?: ManufacturerJSONObject;
}

export type ProductModelJSONAPIAttributes =
  JSONAPI.AttributesObject<ProductModelJSONObject>;

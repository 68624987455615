import { AccountCircle } from "@mui/icons-material";
import { Avatar, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { User } from "../../models/user";

interface UserCardProps {
  // User to show. May be nillish to show just a placeholder
  user?: User;
}

export const UserCard: React.FunctionComponent<UserCardProps> = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} textAlign="center">
        <Avatar src={props.user?.avatar_thumb} style={{ margin: "auto" }}>
          {isEmpty(props.user?.avatar_thumb) ? <AccountCircle /> : null}
        </Avatar>
      </Grid>

      <Grid item xs={12} textAlign="center">
        <Typography variant="caption" textAlign="center" my="auto">
          {props.user?.first_name} {props.user?.last_name}
        </Typography>
      </Grid>
    </Grid>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { countBy, isNil, map, merge, toArray } from "lodash";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import {
  buildSensorCreateRequestPayload,
  buildSensorUpdateRequestPayload,
  SENSOR_JSONAPI_RESOURCE_TYPE,
  SensorFilter,
  SensorJSONAPIAttributes,
  SensorJSONObject,
} from "../../json_api/sensor";
import {
  api_asset_sensors_path,
  api_sensor_path,
  api_sensors_path,
} from "../../routes";
import {
  loadDataFromUrl,
  RequestMethod,
  sendJsonApiData,
} from "../../utils/jquery_helper";
import { JSONApiFormRequestMode } from "../../utils/jsonapi_form_tools";
import {
  jsonApiSensorPath,
  jsonApiSensorsPath,
  SensorIncludes,
} from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { createQuery } from "react-query-kit";
import { SensorLoader } from "../../json_api/sensor_loader";

export interface LoadSensorsQuery {
  page: number;
  pageSize: number;
  filter: SensorFilter;
  includes?: SensorIncludes[];
  // asset context / root asset to use for query, is tightedned through the filter
  assetId?: IDType;
}

export interface LoadSensorQuery {
  id: IDType;
  includes?: SensorIncludes[];
}

export interface ProcessedSensorsAttributes extends SensorJSONAPIAttributes {
  eventPatternsCount?: number;
  eventPatternsNoEventCount?: number;
  eventPatternsEventOccurredCount?: number;
  eventPatternsPendingCount?: number;
}

interface LoadSensorResponse {
  recordCount: number;
  pageCount: number;
  sensors: ProcessedSensorsAttributes[];
}

const SENSOR_DEFAULT_SORT = "sensor_translations.name";

function loadSensorsUrl(
  assetId: IDType,
  includeSubtree = false,
  includes: SensorIncludes[] = ["asset", "sensor_type"],
  filter?: SensorFilter,
  page = 1,
  pageSize = 30,
  sort?: string,
): string {
  const options = {
    asset_only: !includeSubtree,
    format: "json",
    page: {
      number: page,
      size: pageSize,
    },

    include: includes.join(","),
    sort: sort ? sort : SENSOR_DEFAULT_SORT,
    _options: true,
  };

  let url: string;

  const mergedOptions = merge(
    options,
    jsonApiFilterParamsArgumentsFromFilterObject(filter),
  );

  if (!isNil(assetId)) {
    url = api_asset_sensors_path(assetId, mergedOptions);
  } else {
    url = api_sensors_path(mergedOptions);
  }

  return url;
}

export const useLoadSensors = createQuery<LoadSensorResponse, LoadSensorsQuery>(
  {
    queryKey: [SENSOR_JSONAPI_RESOURCE_TYPE],

    fetcher: async (query) => {
      const url = loadSensorsUrl(
        query.assetId,
        true,
        query.includes,
        query.filter,
        query.page,
        query.pageSize,
      );
      return loadDataFromUrl<CollectionResourceDoc<string, SensorJSONObject>>(
        url,
      ).then((jsonApiResponse) => {
        const sensors: ProcessedSensorsAttributes[] = map(
          jsonApiResourceCollectionToFlatObjects<SensorJSONAPIAttributes>(
            jsonApiResponse,
          ),
          (s) => {
            const eventPatternStateCount = countBy(
              s.event_patterns,
              (ep) => ep.current_execution_state,
            );
            return {
              ...s,
              eventPatternsCount: s.event_patterns?.length,
              eventPatternsNoEventCount:
                eventPatternStateCount["no_event"] || 0,
              eventPatternsEventOccurredCount:
                eventPatternStateCount["event_occurred"] || 0,
              eventPatternsPendingCount: eventPatternStateCount["pending"] || 0,
            };
          },
        );
        return {
          recordCount: jsonApiResponse.meta.record_count as number,
          pageCount: jsonApiResponse.meta.page_count as number,
          sensors,
        };
      });
    },
  },
);

export const useLoadSensor = createQuery<SensorJSONObject, LoadSensorQuery>({
  queryKey: [SENSOR_JSONAPI_RESOURCE_TYPE],

  fetcher: async ({ includes, id }) => {
    const url = api_sensor_path(id, {
      include: toArray(includes).join(","),
      _options: true,
    });
    return loadDataFromUrl<SingleResourceDoc<string, SensorJSONObject>>(
      url,
    ).then((jsonApiResponse) => {
      return jsonApiSingleResourceToFlatObject<SensorJSONObject>(
        jsonApiResponse,
      );
    });
  },
});

async function submitChanges(
  sensorData: SensorJSONObject,
  mode: JSONApiFormRequestMode,
): Promise<SensorJSONObject> {
  let jsonApiSubmitData = null;

  let httpMethod: RequestMethod = "PATCH";
  let url: string;
  let data: SensorJSONObject = null;
  switch (mode) {
    case "create":
      httpMethod = "POST";
      url = jsonApiSensorsPath();
      data = { ...sensorData };
      delete data.id;
      jsonApiSubmitData = buildSensorCreateRequestPayload(data);
      jsonApiSubmitData.submitData.data.relationships = {
        asset: { data: { type: "assets", id: sensorData.asset_id as string } },
      };
      break;
    case "update":
      httpMethod = "PATCH";
      jsonApiSubmitData = buildSensorUpdateRequestPayload(sensorData);
      url = jsonApiSensorPath({
        sensorId: sensorData.id,
      });
      break;
  }

  const sentData = await sendJsonApiData<
    SingleResourceDoc<string, Partial<SensorJSONObject>>,
    SingleResourceDoc<string, SensorJSONObject>
  >(url, jsonApiSubmitData.submitData, httpMethod);

  return { ...sentData.data.attributes, id: sentData.data.id };
}

export const useUpdateSensor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (sensor: SensorJSONObject) => {
      return submitChanges(sensor, "update");
    },
    onSuccess: (data, sourceData) => {
      queryClient.invalidateQueries({
        queryKey: [SENSOR_JSONAPI_RESOURCE_TYPE],
      });
      // merge the sensor so we do not loose the included items
      return { ...sourceData, ...data };
    },
  });
};

export const useCreateSensor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (sensor: SensorJSONObject) => {
      return submitChanges(sensor, "create");
    },
    onSuccess: (data, sourceData) => {
      queryClient.invalidateQueries({
        queryKey: [SENSOR_JSONAPI_RESOURCE_TYPE],
      });

      return { ...sourceData, ...data };
    },
  });
};

import { ReduceStore } from "flux/utils";

import {
  clone,
  cloneDeep,
  each,
  find,
  includes,
  isEmpty,
  isNil,
  map,
  trim,
} from "lodash";

import moment, { Moment } from "moment";
import { TreeItem } from "../../../models/tree_item";
import { ErrorMap } from "../../../utils/error_map";
import {
  AllMaintenancePlanTypes,
  MaintenancePlanFilterSelection,
} from "../../maintenance_plan_form/data/models";
import {
  AddUserAction,
  ApplyDefaultUserAction,
  ApplyFilterStateAction,
  LoadInitialStateAction,
  MaintenanceAction,
  ResetErrorsAction,
  ResetStateAction,
  SetDefaultUserAction,
  SetErrorsAction,
  SetProcessingAction,
  ToggleAllMaintenanceJobGroupsAction,
  ToggleMaintenanceJobGroupAction as ToggleMaintenanceJobGroupAction,
  UpdateDoneAtAction,
  UpdateMaintenanceJobAction,
  UpdateNoteAction,
} from "./maintenance_actions";
import MaintenanceDispatcher from "./maintenance_dispatcher";
import { MaintenanceJob, MaintenanceJobGroup, User } from "./model";
import { logger } from "../../../utils/logger";
import { act } from "react";

export interface MaintenanceState {
  done_at: Moment;
  note: string;
  rootAssetId: string | number;
  assetTree: TreeItem;
  maintenanceJobGroups: MaintenanceJobGroup[];
  filter: MaintenancePlanFilterSelection;
  users: User[];
  defaultUser: User;
  isProcessing: boolean;
  errors?: ErrorMap;
  hasChanges: boolean;
}

type ActionHandler = (
  state: MaintenanceState,
  action: MaintenanceAction,
) => MaintenanceState;

export class MaintenanceStore extends ReduceStore<
  MaintenanceState,
  MaintenanceAction
> {
  constructor() {
    super(MaintenanceDispatcher);
  }

  getInitialState(): MaintenanceState {
    const today = moment();
    today.startOf("minute");
    return {
      done_at: today,
      note: "",
      rootAssetId: null,
      assetTree: null,
      maintenanceJobGroups: [],
      users: [],
      defaultUser: null,
      isProcessing: false,
      hasChanges: false,
      filter: {
        assetIds: [],
        maintenancePlanTypes: AllMaintenancePlanTypes,
      },
    };
  }

  reduce(state: MaintenanceState, action: MaintenanceAction): MaintenanceState {
    const actionHandler = (this as any)[action.type] as ActionHandler;

    if (isNil(actionHandler)) {
      // handle unknown actions
      return state;
    }

    return actionHandler.call(this, state, action);
  }

  RESET_STATE(
    state: MaintenanceState,
    action: ResetStateAction,
  ): MaintenanceState {
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: MaintenanceState,
    action: LoadInitialStateAction,
  ): MaintenanceState {
    const assetIds: number[] = [];
    const maintenanceJobGroups = cloneDeep(action.maintenanceJobGroups);
    each(maintenanceJobGroups, (group, groupIndex) => {
      each(group.assets, (asset, assetIndex) => {
        asset.expanded = groupIndex == 0 && assetIndex == 0;
        // collect asset ids for filter
        assetIds.push(parseInt(asset.asset.id as string));
        each(asset.maintenance_jobs, (job) => {
          if (!isNil(job.maintenance_period_starts_at)) {
            job.maintenance_period_starts_at = moment(
              job.maintenance_period_starts_at,
            );
          }
          if (!isNil(job.maintenance_period_ends_at)) {
            job.maintenance_period_ends_at = moment(
              job.maintenance_period_ends_at,
            );
          }
        });
      });
    });

    const filter: MaintenancePlanFilterSelection = {
      assetIds: assetIds,
      maintenancePlanTypes: ["InspectionPlan", "MaintenancePlan"],
    };

    return {
      ...this.getInitialState(),
      rootAssetId: action.rootAssetId,
      assetTree: action.assetTree,
      maintenanceJobGroups: maintenanceJobGroups,
      users: cloneDeep(action.users),
      hasChanges: false,
      filter: filter,
    };
  }

  UPDATE_DONE_AT(
    state: MaintenanceState,
    action: UpdateDoneAtAction,
  ): MaintenanceState {
    return {
      ...state,
      done_at: action.doneAt,
      hasChanges: true,
    };
  }

  UPDATE_NOTE(
    state: MaintenanceState,
    action: UpdateNoteAction,
  ): MaintenanceState {
    return {
      ...state,
      note: action.note,
      hasChanges: true,
    };
  }

  UPDATE_MAINTENANCE_JOB(
    state: MaintenanceState,
    action: UpdateMaintenanceJobAction,
  ): MaintenanceState {
    const maintenanceJobGroups = clone(state.maintenanceJobGroups);
    const maintenanceJobGroup = clone(
      maintenanceJobGroups[action.maintenanceJobGroupIndex],
    );
    maintenanceJobGroups[action.maintenanceJobGroupIndex] = maintenanceJobGroup;
    maintenanceJobGroup.assets = clone(maintenanceJobGroup.assets);
    const asset = clone(maintenanceJobGroup.assets[action.assetIndex]);
    maintenanceJobGroup.assets[action.assetIndex] = asset;
    asset.maintenance_jobs = clone(asset.maintenance_jobs);
    asset.maintenance_jobs[action.maintenanceIndex] = {
      ...action.maintenanceJob,
    };

    return {
      ...state,
      maintenanceJobGroups,
      hasChanges: true,
    };
  }

  SET_PROCESSING(
    state: MaintenanceState,
    action: SetProcessingAction,
  ): MaintenanceState {
    return {
      ...state,
      isProcessing: action.isProcessing,
    };
  }

  SET_DEFAULT_USER(
    state: MaintenanceState,
    action: SetDefaultUserAction,
  ): MaintenanceState {
    return {
      ...state,
      defaultUser: clone(action.user),
      hasChanges: true,
    };
  }

  APPLY_DEFAULT_USER(
    state: MaintenanceState,
    action: ApplyDefaultUserAction,
  ): MaintenanceState {
    const { defaultUser } = state;

    // no default user set
    if (isNil(defaultUser)) {
      return state;
    }

    // update all maintenance jobs
    const maintenanceJobGroups = this.updateAllMaintenanceJobGroups(
      state.maintenanceJobGroups,
      (maintenanceJob) => {
        // set user on maintenance jobs without user
        if (
          isNil(maintenanceJob.user_attributes) ||
          isEmpty(maintenanceJob.user_attributes.name)
        ) {
          maintenanceJob.user_attributes = clone(defaultUser);
          maintenanceJob.user_id = defaultUser.id;
          maintenanceJob.user_type = defaultUser.type;

          return true;
        }

        return false;
      },
    );

    return {
      ...state,
      maintenanceJobGroups,
      hasChanges: true,
    };
  }

  SET_ERRORS(
    state: MaintenanceState,
    action: SetErrorsAction,
  ): MaintenanceState {
    const maintenance = action.errorResponse?.maintenance;
    if (isNil(maintenance)) {
      const errTitles = map(
        (action.errorResponse as any).errors as any[],
        (err) => err?.title,
      );
      logger.warn(
        `MaintenanceStore:SET_ERRORS: No maintenance object found in error response. Errors: ${errTitles}`,
      );

      return state;
    }

    // find maintenance job with error and update it
    const maintenanceJobGroups = this.updateAllMaintenanceJobGroups(
      state.maintenanceJobGroups,
      (maintenanceJob) => {
        const jobWithErrors = find(
          maintenance?.maintenance_jobs,
          (jobWithErrors) => {
            return (
              jobWithErrors.asset_id ==
                maintenanceJob.maintenance_plan.asset_id &&
              jobWithErrors.maintenance_plan_id ==
                maintenanceJob.maintenance_plan.id
            );
          },
        );

        if (!isNil(jobWithErrors)) {
          maintenanceJob.errors = jobWithErrors.errors;

          return true;
        }

        return false;
      },
    );

    return {
      ...state,
      maintenanceJobGroups,
      errors: clone(maintenance.errors),
    };
  }

  RESET_ERRORS(
    state: MaintenanceState,
    action: ResetErrorsAction,
  ): MaintenanceState {
    // remove all error objects
    const maintenanceJobGroups = this.updateAllMaintenanceJobGroups(
      state.maintenanceJobGroups,
      (maintenanceJob) => {
        maintenanceJob.errors = null;

        return true;
      },
    );

    return {
      ...state,
      maintenanceJobGroups,
      errors: null,
    };
  }

  TOGGLE_ALL_MAINTENANCE_JOB_GROUPS(
    state: MaintenanceState,
    action: ToggleAllMaintenanceJobGroupsAction,
  ): MaintenanceState {
    const maintenanceJobGroups = state.maintenanceJobGroups.map(
      (maintenanceJobGroup) => ({
        ...maintenanceJobGroup,
        assets: maintenanceJobGroup.assets.map((asset) => ({
          ...asset,
          expanded: action.expanded,
        })),
      }),
    );

    return {
      ...state,
      maintenanceJobGroups,
    };
  }

  TOGGLE_MAINTENANCE_JOB_GROUP(
    state: MaintenanceState,
    action: ToggleMaintenanceJobGroupAction,
  ): MaintenanceState {
    const maintenanceJobGroups = clone(state.maintenanceJobGroups);
    const newMaintenanceJobGroup = {
      ...maintenanceJobGroups[action.maintenanceJobGroupIndex],
      assets: clone(
        maintenanceJobGroups[action.maintenanceJobGroupIndex].assets,
      ),
    };
    maintenanceJobGroups[action.maintenanceJobGroupIndex] =
      newMaintenanceJobGroup;
    newMaintenanceJobGroup.assets[action.assetIndex] = {
      ...newMaintenanceJobGroup.assets[action.assetIndex],
      expanded: action.expanded,
    };

    return {
      ...state,
      maintenanceJobGroups,
    };
  }

  APPLY_FILTER(
    state: MaintenanceState,
    action: ApplyFilterStateAction,
  ): MaintenanceState {
    const maintenanceJobGroups: MaintenanceJobGroup[] = [];

    each(state.maintenanceJobGroups, (maintenanceJobGroup) => {
      let assetsWithMaintenanceJobs = maintenanceJobGroup.assets;
      assetsWithMaintenanceJobs = map(assetsWithMaintenanceJobs, (asset) => {
        const maintenanceJobs = map(asset.maintenance_jobs, (mj) => {
          return {
            ...mj,
            hidden: !(
              includes(
                action.filter.maintenancePlanTypes,
                mj.maintenance_plan.type,
              ) &&
              includes(
                action.filter.assetIds,
                parseInt(asset.asset.id as string),
              )
            ),
          };
        });

        // create a new filtered asset holding the same maintenance jobs objects
        return {
          ...asset,
          maintenance_jobs: maintenanceJobs,
        };
      });

      if (!isEmpty(assetsWithMaintenanceJobs)) {
        maintenanceJobGroups.push({
          ...maintenanceJobGroup,
          assets: assetsWithMaintenanceJobs,
        });
      }
    });

    return { ...state, maintenanceJobGroups, filter: action.filter };
  }

  ADD_USER(state: MaintenanceState, action: AddUserAction): MaintenanceState {
    const users = clone(state.users);
    if (action.user.type == "User") {
      logger.warn(
        "MaintenanceStore:ADD_USER: Platform users cannot be added. Tried to add user: ",
        action.user,
      );
      return state;
    }

    const newUserName = trim(action.user.name ?? action.name);
    const theNewUser = action.user
      ? { ...action.user, name: newUserName }
      : ({
          id: undefined,
          type: "GuestUser",
          name: newUserName,
        } as User);

    const existingUser = users.find((user) => {
      if (action.user.id) {
        return user.type == "GuestUser" && user.id === action.user.id;
      } else {
        return user.type == "GuestUser" && user.name === newUserName;
      }
    });
    if (existingUser) {
      logger.warn(
        "MaintenanceStore:ADD_USER: User already exists. Tried to add user: ",
        action.user,
      );
      return state;
    }

    return {
      ...state,
      users: [...users, theNewUser].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    };
  }

  /** Performs an update operation on all maintenance jobs in all maintenance job groups.
   * If the update operation returns true, the maintenance job is considered updated.
   *
   *
   * @private
   * @param {MaintenanceJobGroup[]} maintenanceJobGroups - The maintenance job groups to update.
   * @param {(job: MaintenanceJob) => boolean} updateOperation - The update operation to perform on each maintenance job.
   * @return {*}  {MaintenanceJobGroup[]} - The updated maintenance job groups.
   * @memberof MaintenanceStore
   */
  private updateAllMaintenanceJobGroups(
    maintenanceJobGroups: MaintenanceJobGroup[],
    updateOperation: (job: MaintenanceJob) => boolean,
  ): MaintenanceJobGroup[] {
    const newMaintenanceJobGroups = clone(maintenanceJobGroups);

    // update all maintenance jobs
    newMaintenanceJobGroups.forEach(
      function (maintenanceJobGroup, jobGroupIndex) {
        const newMaintenanceJobGroup = clone(maintenanceJobGroup);
        newMaintenanceJobGroups[jobGroupIndex] = newMaintenanceJobGroup;
        newMaintenanceJobGroup.assets = clone(maintenanceJobGroup.assets);

        newMaintenanceJobGroup.assets.forEach((asset, assetIndex) => {
          const newAsset = clone(asset);
          let maintenanceJobUpdated = false;
          newAsset.maintenance_jobs.forEach((maintenanceJob, jobIndex) => {
            maintenanceJob = clone(maintenanceJob);

            // set user on maintenance jobs without user
            if (updateOperation(maintenanceJob)) {
              newAsset.maintenance_jobs[jobIndex] = maintenanceJob;
              maintenanceJobUpdated = true;
            }
          });

          if (maintenanceJobUpdated) {
            newMaintenanceJobGroup.assets[assetIndex] = newAsset;
          }
        });
      },
    );

    return newMaintenanceJobGroups;
  }
}

export default new MaintenanceStore();

import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Refresh, Search } from "@mui/icons-material";
import { DataGrid, GridDensity } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import type * as JSONAPI from "jsonapi-typescript";
import { defaultTo, isEmpty, isNil, map } from "lodash";
import { DateRange } from "moment-range";
import * as React from "react";
import moment from "../../initializers/moment";
import { DeviceJSONAPIAttributes } from "../../json_api/device";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { apiDeviceLogPath, apiDevicePath } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { MaterialUiDateRangePicker } from "../common/data_range_picker";
import { SialogicDialog } from "../common/sialogic_dialog";

interface DeviceLogTableProps {
  deviceId: IDType;
  density?: GridDensity;
}

interface DeviceLog {
  device_id: string;
  message: string;
  code: string;
  label: string;
  time: string;
}

export const DeviceLogTable: React.FunctionComponent<DeviceLogTableProps> = (
  props,
) => {
  const [dateRange, setDateRange] = React.useState<DateRange>(
    new DateRange(moment().subtract(1, "hour"), moment().endOf("day")),
  );

  // state for input fields
  const [code, setCode] = React.useState<string>(null);
  const [label, setLabel] = React.useState<string>(null);
  const [pageSettings, setPageSettings] = React.useState({
    size: 100,
    number: 1,
  });

  // state for querying logs
  const [queryParams, setQueryParams] = React.useState({
    device_id: props.deviceId,
    code: null,
    label: null,
    pageSettings: pageSettings,
  });

  const [selectedLogEntry, setSelectedLogEntry] =
    React.useState<DeviceLog>(null);

  const deviceLoadQuery = useQuery({
    queryKey: ["device", { id: props.deviceId }],
    enabled: !isNil(props.deviceId),
    queryFn: (data) => {
      return loadDataFromUrl<
        JSONAPI.SingleResourceDoc<string, DeviceJSONAPIAttributes>
      >(apiDevicePath(props.deviceId)).then((deviceDoc) => {
        return jsonApiSingleResourceToFlatObject(deviceDoc);
      });
    },
  });

  const loadLogsQuery = useQuery({
    queryKey: ["device_logs", queryParams, dateRange],
    enabled: !isNil(props.deviceId),

    queryFn: (data) => {
      return loadDataFromUrl<DeviceLog[]>(
        apiDeviceLogPath(props.deviceId, dateRange, code, label),
      ).then((deviceLogs) => {
        return map(deviceLogs, (log, index) => ({ ...log, id: index }));
      });
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <MaterialUiDateRangePicker
              type="datetime"
              dateFormat="L LTS"
              pickSeconds
              label={I18n.t("frontend.select_time_range")}
              value={[dateRange.start, dateRange.end]}
              onChange={(dateRange) => {
                setDateRange(new DateRange(dateRange.dateRange));
              }}
            />
          </Grid>
          <Grid item xs={12} md>
            <TextField
              type="search"
              fullWidth
              size="small"
              label={I18n.t("frontend.device_logs.search_code")}
              onChange={(event) => {
                if (isEmpty(event.target.value)) {
                  setCode(null);
                  setQueryParams((p) => ({ ...p, code: null }));
                } else {
                  setCode(event.target.value);
                }
              }}
              onReset={() => {
                setCode(null);
                setQueryParams((p) => ({ ...p, code: null }));
              }}
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  e.stopPropagation();
                  setQueryParams((p) => ({ ...p, code }));
                }
              }}
              helperText={I18n.t(
                "frontend.device_logs.search_code_helper_text",
              )}
              value={defaultTo(code, "")}
              InputProps={{
                endAdornment: isEmpty(code) ? null : (
                  <>
                    <Tooltip title={I18n.t("frontend.search")}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setQueryParams((p) => ({ ...p, code }));
                        }}
                      >
                        <Search fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md>
            <TextField
              type="search"
              fullWidth
              size="small"
              label={I18n.t("frontend.device_logs.search_label")}
              onChange={(event) => {
                if (isEmpty(event.target.value)) {
                  setLabel(null);
                  setQueryParams((p) => ({ ...p, label: null }));
                } else {
                  setLabel(event.target.value);
                }
              }}
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  e.stopPropagation();
                  setQueryParams((p) => ({ ...p, label }));
                }
              }}
              onReset={() => {
                setLabel(null);
                setQueryParams((p) => ({ ...p, label: null }));
              }}
              helperText={I18n.t(
                "frontend.device_logs.search_label_helper_text",
              )}
              value={defaultTo(label, "")}
              InputProps={{
                endAdornment: isEmpty(label) ? null : (
                  <>
                    <Tooltip title={I18n.t("frontend.search")}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setQueryParams((p) => ({ ...p, label }));
                        }}
                      >
                        <Search fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
          </Grid>
          <Grid item xs>
            <IconButton onClick={() => loadLogsQuery.refetch()}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        item
        style={{
          minHeight: 800,
          width: "100%",
          height: 800,
        }}
      >
        <DataGrid
          initialState={{ density: defaultTo(props.density, "standard") }}
          paginationMode="server"
          pagination={isNil(loadLogsQuery.data) ? undefined : true}
          paginationModel={{
            page: (pageSettings.number || 1) - 1,
            pageSize: pageSettings.size,
          }}
          rowCount={loadLogsQuery.data?.length || 0}
          rows={defaultTo(loadLogsQuery.data, [])}
          columns={[
            {
              field: "time",
              flex: 0.3,
              headerName: I18n.t("activerecord.attributes.device_log.time"),
              type: "dateTime",
              minWidth: 150,
              valueGetter: (value, row) => new Date(row.time),
            },
            {
              field: "label",
              flex: 0.15,
              headerName: I18n.t("activerecord.attributes.device_log.label"),
            },
            {
              field: "code",
              width: 150,
              flex: 0.15,
              headerName: I18n.t("activerecord.attributes.device_log.code"),
            },
            {
              field: "message",
              width: 450,
              flex: 0.6,
              headerName: I18n.t("activerecord.attributes.device_log.message"),
            },
          ]}
          loading={loadLogsQuery.isLoading}
          onRowClick={(params) => {
            setSelectedLogEntry(params.row as DeviceLog);
          }}
          onPaginationModelChange={(paginationModel) =>
            setPageSettings({
              size: paginationModel.pageSize,
              number: paginationModel.page + 1,
            })
          }
        />
        {isNil(selectedLogEntry) ? null : (
          <SialogicDialog
            open={!isNil(selectedLogEntry)}
            onClose={() => setSelectedLogEntry(null)}
            buttons={
              <Button
                onClick={() => {
                  setSelectedLogEntry(null);
                }}
              >
                {I18n.t("frontend.close")}
              </Button>
            }
          >
            <Grid container>
              {map(["time", "code", "label", "message"], (key, index) => (
                <Grid container item key={index} spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      {I18n.t(`activerecord.attributes.device_log.${key}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {defaultTo(
                        selectedLogEntry[key as keyof DeviceLog],
                        "---",
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </SialogicDialog>
        )}
      </Grid>
    </Grid>
  );
};

import { UIOptionsType } from "@rjsf/utils";
import { MeasurementCategorization } from "./measurement_categorization";
import { MeasurementValueDefinition } from "./measurement_value_definition";
export type MeasurementTypes =
  | "MeasurementTypes::IndependentMeasurementType"
  | "MeasurementTypes::DistributionMeasurementType";
export type MeasurementTypesUnderscore =
  | "independent_measurement_type"
  | "distribution_measurement_type";

export const AllMeasurementTypeTypes: MeasurementTypes[] = [
  "MeasurementTypes::IndependentMeasurementType",
  "MeasurementTypes::DistributionMeasurementType",
];

export const AllMeasurementTypeTypesUnderscore: MeasurementTypesUnderscore[] = [
  "independent_measurement_type",
  "distribution_measurement_type",
];

export enum MeasurementTypeVariants {
  independent,
  distribution,
}

export interface MeasurementTypeAttributes {
  id?: string | number;
  type?: MeasurementTypes;
  type_short: MeasurementTypesUnderscore;
  title?: string;
  description?: string;
  allow_attachments?: boolean;
  allow_measurement_notes?: boolean;
  measurement_categorization_id?: number | string;
  interval_unit?: string;
  meta_info?: UIOptionsType;
}

export interface MeasurementType extends MeasurementTypeAttributes {
  measurement_value_definitions?: MeasurementValueDefinition[];
  measurement_categorization?: MeasurementCategorization;
}

export interface MeasurementTypeSubmit extends MeasurementTypeAttributes {
  measurement_value_definitions_attributes?: MeasurementValueDefinition[];
  measurement_categorization?: MeasurementCategorization;
}

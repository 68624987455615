import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import JSONSchemaValidator from "@rjsf/validator-ajv8";
import Bluebird from "bluebird";
import { JSONSchema4 } from "json-schema";
import * as JSONType from "json-typescript";
import {
  cloneDeep,
  defaultTo,
  find,
  first,
  isEmpty,
  isNil,
  keys,
  map,
  sortBy,
  toString,
  upperFirst,
} from "lodash";
import * as React from "react";
import {
  loadDataFromUrl,
  sendData,
  sendJsonApiData,
} from "../../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../../utils/jsonapi_form_tools";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import { error, success } from "../../../utils/toasts";
import {
  assetDashboardWidgetsPath,
  assetTypesDashboardWidgetsPath,
  widgetUrl,
} from "../../../utils/urls";
import { assetDashboardPath } from "../../../utils/urls/dashboard_urls";
import { Form as MuiForm } from "../../common/json_schema_form/form";

import { Blob } from "@rails/activestorage";
import { SingleResourceDoc } from "jsonapi-typescript";
import { jsonApiSingleResourceToFlatObject } from "../../../json_api/jsonapi_tools";
import {
  WidgetJSONAPIAttributes,
  WidgetJSONObject,
} from "../../../json_api/widget";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { Widget } from "../../../models/widget";
import {
  api_widget_available_sensors_path,
  api_widget_path,
  api_widgets_sensor_types_path,
  api_widgets_types_path,
  store_attachments_api_widget_path,
  widget_config_schema_path,
} from "../../../routes";
import { ColorUtils } from "../../../utils/colors";
import { ASFileUpload, UploadDelegate } from "../../../utils/file_uploader";
import { IDType } from "../../../utils/urls/url_utils";
import { Dropzone } from "../../common/file_dropzone/dropzone";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";
import { AppContext } from "../../common/app_context/app_context_provider";

import { DashboardType } from "../../../models/dashboard";
import { UiSchema } from "@rjsf/utils";
import { SialogicQueryClient } from "../../common/sialogic_query_client";

export interface WidgetEditorSensorType {
  name: string;
  identifier: string;
}

export interface WidgetEditorAvailableSensor {
  name: string;
  id: IDType;
  asset_name: string;
  asset_id: IDType;
  key: string;
  sensor_type: string;
}

export type WidgetAttachments = Record<string, ActiveStorageStoredFile>;
export interface WidgetTypeInfo {
  name: string;
  identifier: string;
  description?: string;
}

export interface WidgetEditFormProps {
  widgetId: IDType;
  widgetTypeIdentifier?: string;
  widgetTypes?: WidgetTypeInfo[];
  assetId?: IDType;
  assetTypeId?: IDType;
  dashboardId?: IDType;
  dashboardType?: DashboardType;
  schema?: JSONType.Object;
  config?: JSONType.Object;
  attachments?: Record<string, ActiveStorageStoredFile>;
  referer?: string;
  sensorTypes?: WidgetEditorSensorType[];
  availableSensors?: WidgetEditorAvailableSensor[];
  disableTypeSelection?: boolean;
  onSaved?: (widget: Widget, editFinished: boolean) => void;
  onCancel?: () => void;
}

export interface WidgetEditorState {
  dataLoaded: boolean;
  widget: WidgetJSONObject;
  schema?: JSONType.Object;
  widgetTypes?: WidgetTypeInfo[];
  sensorTypes?: WidgetEditorSensorType[];
  availableSensors?: WidgetEditorAvailableSensor[];
  widgetType?: WidgetTypeInfo;
  loading?: boolean;
  valid?: boolean;
  attachments?: Record<string, ActiveStorageStoredFile>;
  currentTab: "base" | "config" | "attachments";
  uploads?: Record<string, { file: File; progress: number }>;
}
export class WidgetEditForm extends React.Component<
  WidgetEditFormProps,
  WidgetEditorState
> {
  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;
  constructor(props: WidgetEditFormProps) {
    super(props);

    let widget: WidgetJSONObject = props.widgetId ? null : {};

    if (props.config) {
      widget = { config: props.config };
    }
    if (props.attachments) {
      widget ||= {};
      widget.attachments = props.attachments;
    }

    this.state = {
      widget: widget,
      currentTab: "base",

      dataLoaded: false,
      loading: false,
      widgetTypes: props.widgetTypes,
      widgetType: null,
      valid: false,
      sensorTypes: props.sensorTypes,
      availableSensors: isEmpty(props.availableSensors)
        ? null
        : props.availableSensors,
      schema: this.processSchema(this.props.schema),
      uploads: {},
    };
  }

  async saveBlobAsAttachment(attachmentName: string, blob: Blob) {
    if (isNil(this.props.widgetId)) {
      this.setState({});
    } else {
      const attachments = {
        [attachmentName]: isNil(blob) ? null : blob.signed_id,
      };
      await sendData(
        store_attachments_api_widget_path(this.props.widgetId),
        { attachments },
        "PATCH",
      ).then((result) => {
        this.setState({
          attachments: result as Record<string, ActiveStorageStoredFile>,
        });
      });
    }
  }
  processSchema(sourceSchema: JSONSchema4): JSONSchema4 {
    if (isNil(sourceSchema)) {
      return null;
    }

    const newSchema = cloneDeep(sourceSchema);
    // support both "definitions" and "$defs" for JSON schema
    const definitions: Record<string, JSONSchema4> =
      newSchema?.definitions ||
      (newSchema?.$defs as Record<string, JSONSchema4>);
    // populate "sensor_type" list
    if (
      !isEmpty(this.state.sensorTypes) &&
      definitions &&
      (definitions as JSONType.Object)?.["sensor_type"]
    ) {
      const sensorTypeDefinition = definitions?.["sensor_type"];
      if (
        sensorTypeDefinition.type === "string" &&
        isEmpty(sensorTypeDefinition.oneOf) &&
        isEmpty(sensorTypeDefinition.enum)
      ) {
        sensorTypeDefinition.oneOf = map(this.state.sensorTypes, (st) => ({
          const: st.identifier,
          title: st.name,
        }));
      }
    }

    // populate "sensor_type" list
    if (
      !isEmpty(this.state.availableSensors) &&
      definitions &&
      ((definitions as JSONType.Object)?.["attribute_key"] ||
        (definitions as JSONType.Object)?.["sensor_attribute_key"])
    ) {
      const attributeKeyDefinition = defaultTo(
        definitions?.["attribute_key"],
        definitions?.["sensor_attribute_key"],
      );

      if (
        attributeKeyDefinition.type === "string" &&
        isEmpty(attributeKeyDefinition.enum) &&
        isEmpty(attributeKeyDefinition.onOf)
      ) {
        attributeKeyDefinition.oneOf = map(
          sortBy(this.state.availableSensors, (s) => s.key),
          (st) => ({
            const: st.key,
            title: `${st.key} - ${st.asset_name} - ${st.name} - ${st.sensor_type}`,
          }),
        );
      }
    }
    // populate "color_scheme" list
    if ((definitions as JSONType.Object)?.["color_scheme"]) {
      const colorSchemeDefinition = definitions?.["color_scheme"];
      if (
        colorSchemeDefinition.type === "string" &&
        isEmpty(colorSchemeDefinition.enum)
      ) {
        colorSchemeDefinition.oneOf = map(
          /*this.props.colorSchemes,
          (scheme) => ({
            const: scheme,
            title: scheme,
          })*/
          ColorUtils.colorSchemes,
          (scheme) => ({
            const: scheme.id,
            title: scheme.title,
          }),
        );
      }
    }

    return newSchema;
  }

  componentDidMount(): void {
    let promise = Promise.resolve();

    if (isEmpty(this.props.sensorTypes) && isEmpty(this.state.sensorTypes)) {
      promise = promise.then(() => this.loadSensorTypes());
    }

    if (isEmpty(this.state.availableSensors)) {
      promise = promise.then(() => this.loadSensorInfo());
    }

    if (isEmpty(this.props.widgetTypes) && isEmpty(this.state.widgetTypes)) {
      promise = promise.then(() => this.loadWidgetTypes());
    }
    if (
      !isNil(this.props.widgetTypeIdentifier) &&
      !isNil(this.props.widgetId) &&
      !this.state.dataLoaded
    ) {
      if (isNil(this.state.schema)) {
        promise = promise.then(() => {
          if (isNil(this.state.schema)) {
            return this.loadWidgetSchema(this.props.widgetTypeIdentifier);
          } else {
            return Promise.resolve();
          }
        });
      }

      if (isNil(this.state.widget?.config)) {
        promise = promise.then<void>(() => void this.loadWidget());
      }
    }
    void promise.then(() => {
      this.setState({ loading: false, dataLoaded: true });
      this.processSchema(this.state.schema);
    });
  }

  handleFormErrors(errors: any): void {
    if (isEmpty(errors)) {
      this.setState({});
    }
  }

  setFormValid(formErrors: any): void {
    if (isEmpty(formErrors) && !isEmpty(this.state.widgetType)) {
      this.setState({});
    }
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return (
        <Card>
          <CardHeader title="Loading ..." />
          <CardContent>
            <Skeleton variant="rounded" height={300} />
            <Skeleton variant="rounded" height={200} />
            <Skeleton variant="rounded" height={400} />
            <Skeleton variant="rounded" height={100} />
          </CardContent>
        </Card>
      );
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={this.state.currentTab}
                onChange={(e, newValue) => {
                  this.setState({
                    currentTab: newValue as "base" | "config" | "attachments",
                  });
                }}
                aria-label="basic tabs example"
              >
                <Tab label={"Base"} value="base" />
                <Tab
                  label={"Config"}
                  value="config"
                  disabled={isEmpty(this.state.widgetType)}
                />
                {!isEmpty(this.state.attachments) ? (
                  <Tab
                    label={"Attachments"}
                    value="attachments"
                    disabled={isEmpty(this.state.widgetType)}
                  />
                ) : null}
              </Tabs>
            </Box>
          </Grid>

          {this.state.currentTab == "base" ? (
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={I18n.t(
                    "frontend.widgets.widget_editor.general_settings",
                  )}
                ></CardHeader>
                <CardContent>
                  {isNil(this.state.widget) && this.props.widgetId ? (
                    <Skeleton variant="rounded" height={300} />
                  ) : (
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={"Widget Name"}
                          value={this.state.widget.name ?? ""}
                          onChange={(event) => {
                            this.setState({
                              widget: {
                                ...this.state.widget,
                                name: event.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                      {isEmpty(this.state.widgetTypes) ? null : (
                        <Grid item xs={12}>
                          <TextField
                            select
                            label={I18n.t(
                              "frontend.widgets.widget_editor.widget_type_label",
                            )}
                            fullWidth
                            value={toString(this.state.widgetType?.identifier)}
                            onChange={(event) => {
                              this.applyWidgetType(event.target.value);
                            }}
                          >
                            {this.state.widgetTypes?.map((t, index) => (
                              <MenuItem key={index} value={t.identifier}>
                                {t.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}

                      {isEmpty(this.state.widgetType?.description) ? null : (
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            {I18n.t("frontend.description")}
                          </Typography>
                          <Box marginY={1}>
                            <Typography variant="body1">
                              {this.state.widgetType.description}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          {isEmpty(this.state.schema) ||
          this.state.currentTab != "config" ? null : (
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={I18n.t(
                    "frontend.widgets.widget_editor.widget_config_heading",
                  )}
                />

                <CardContent>
                  <MuiForm
                    validator={JSONSchemaValidator}
                    schema={this.state.schema}
                    uiSchema={
                      (this.state.schema["x-ui-schema"] as UiSchema) || {}
                    }
                    formData={this.state.widget?.config || {}}
                    disabled={this.state.loading}
                    onChange={(event) =>
                      this.setState({
                        widget: {
                          ...this.state.widget,
                          config: event.formData as JSONType.Object,
                        },
                      })
                    }
                    showErrorList={"top"}

                    //disabled={!this.props.editable  }
                  >
                    <div />
                  </MuiForm>
                </CardContent>
              </Card>
            </Grid>
          )}
          {isEmpty(this.state.attachments) ||
          this.state.currentTab != "attachments" ? null : (
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {I18n.t("frontend.widgets.widget_editor.attachments_heading")}
                </Typography>
              </Grid>

              {map(keys(this.state.attachments).sort(), (name) => {
                const attachment = this.state.attachments[name];

                return (
                  <Grid item xs={12} lg={4} key={name}>
                    <Card>
                      <CardHeader
                        titleTypographyProps={{ variant: "h5" }}
                        title={upperFirst(name)}
                      />

                      <CardContent>
                        <Dropzone
                          filesLimit={1}
                          initialFiles={
                            isNil(attachment) ? null : [attachment.url]
                          }
                          uploading={!isNil(this.state.uploads[name])}
                          acceptedFileTypes={[
                            "image/png",
                            "image/jpeg",
                            "image/svg+xml",
                            "application/pdf",
                            "model/vnd.gltf+json",
                            "model/gltf-binary",
                            "model/gltf-json",
                          ]}
                          onDelete={(file) => {
                            void this.saveBlobAsAttachment(name, null).then(
                              () => {
                                void success(
                                  I18n.t("frontend.success"),
                                  I18n.t(
                                    "frontend.widgets.widget_editor.file_removed",
                                    { file: file.name },
                                  ),
                                );
                                if (this.props.onSaved) {
                                  this.props.onSaved(
                                    {
                                      ...this.state.widget,
                                      attachments: this.state.attachments,
                                    },
                                    false,
                                  );
                                }
                              },
                            );
                          }}
                          uploadProgressPercent={
                            this.state.uploads[name]?.progress
                          }
                          fileSelectionChanged={(newFile) => {
                            const theFile = first(newFile);
                            if (
                              isEmpty(theFile) ||
                              theFile?.name === attachment?.filename
                            ) {
                              return;
                            }
                            const uploads = defaultTo(this.state.uploads, {});

                            uploads[name] = {
                              file: theFile,
                              progress: 0,
                            };
                            this.setState({ uploads }, () => {
                              const deletegate: UploadDelegate = {
                                onProgress: (progress, asFileUpload) => {
                                  const uploads = this.state.uploads;
                                  uploads[name].progress = progress;
                                  this.setState({ uploads });
                                },
                              };
                              const fileUpload = new ASFileUpload(
                                first(newFile),
                                deletegate,
                              );

                              fileUpload
                                .upload()
                                .then((blob) =>
                                  this.saveBlobAsAttachment(name, blob).then(
                                    () => {
                                      void success(
                                        I18n.t("frontend.success"),
                                        I18n.t(
                                          "frontend.widgets.widget_editor.file_saved",
                                          {
                                            attachmentName: name,
                                            file: theFile.name,
                                          },
                                        ),
                                      );
                                      if (this.props.onSaved) {
                                        this.props.onSaved(
                                          {
                                            ...this.state.widget,
                                            attachments: this.state.attachments,
                                          },
                                          false,
                                        );
                                      }
                                    },
                                  ),
                                )
                                .catch((theError) => {
                                  void error(
                                    I18n.t("frontend.error"),
                                    "Error uploading file",
                                  );
                                  console.log(theError);
                                })
                                .finally(() => {
                                  const uploads = this.state.uploads;
                                  delete uploads[name];
                                  this.setState({ uploads });
                                });
                            });
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}

          <FixedBottomArea id="fixed-bottom-area">
            <FloatingButtons
              isProcessing={this.state.loading}
              onSubmit={() => {
                void this.saveConfig(this.state.widget.config);
              }}
              onCancel={() => {
                this.setState({ loading: false }, () => {
                  if (this.props.onCancel) {
                    this.props.onCancel();
                  } else {
                    this.navigateBack();
                  }
                });
              }}
              disableSave={this.state.loading || !this.state.valid}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.sensors.form.submit_title")}
            />
          </FixedBottomArea>
        </Grid>
      </>
    );
  }

  submitUrl(): string {
    if (isNil(this.props.widgetId)) {
      if (this.props.dashboardType == "asset_type_dashboard") {
        return assetTypesDashboardWidgetsPath(
          this.props.assetTypeId,
          this.props.dashboardId,
        );
      } else {
        return assetDashboardWidgetsPath(
          this.props.assetId,
          this.props.dashboardId,
        );
      }
    } else {
      return widgetUrl(this.props.widgetId, true);
    }
  }

  navigateBack() {
    if (!isNil(this.context.referrer)) {
      redirectTo(this.context.referrer);
    } else {
      if (!isNil(this.props.assetId) && !isNil(this.props.dashboardId))
        redirectTo(
          assetDashboardPath(this.props.assetId, this.props.dashboardId),
        );
    }
  }
  saveConfig(config: JSONType.Object): Bluebird<void> {
    this.setState({ loading: true });
    const { submitData, mode } = buildJsonApiSubmitData(
      {
        id: this.props.widgetId,
        // assure that the config is an object
        config: JSON.stringify(config || {}),
        name: this.state.widget.name,
      },
      "widgets",
    );

    (submitData as Record<string, any>)["widget_class_name"] =
      this.state.widgetType.identifier;
    (submitData as Record<string, any>)["attachments"] = this.state.attachments;

    return sendJsonApiData<unknown, { status: string }>(
      this.submitUrl(),
      submitData,
      mode == "create" ? "POST" : "PATCH",
    )
      .then(() => {
        success("Widget saved!", "Widget was updated successfully.");
        this.setState({ loading: false });

        if (this.props.onSaved) {
          this.props.onSaved(this.state.widget, true);
        } else {
          this.navigateBack();
        }
      })
      .catch((err) => {
        error("Problems saving the widget!", "Widget could not be saved.");
        logger.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  async loadWidgetSchema(type: string = null): Promise<void> {
    let schema: JSONType.Object;
    if (isNil(this.state.widgetType) && isNil(type)) return;

    try {
      this.setState({ loading: true });
      schema = await loadDataFromUrl<JSONType.Object>(
        widget_config_schema_path(type ?? this.state.widgetType?.identifier),
      );
      this.dataLoadingComplete(schema, this.state.widget);
    } catch (err) {
      logger.error("Error loading widget config.", err);
      this.setState({ loading: false });
    }
  }

  async loadSensorInfo(): Promise<void> {
    try {
      this.setState({ loading: true });
      const types = await SialogicQueryClient.fetchQuery({
        queryKey: [
          "widget_attribute_keys",
          this.props.assetId,
          this.props.widgetId,
          this.state.widgetType?.identifier,
        ],
        queryFn: () =>
          loadDataFromUrl<WidgetEditorAvailableSensor[]>(
            api_widget_available_sensors_path(this.props.assetId),
          ),
      });

      this.setState({
        availableSensors: sortBy(types, "name"),
      });
    } catch (err) {
      logger.error("Error loading sensor types.", err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadSensorTypes(): Promise<void> {
    try {
      this.setState({ loading: true });
      const types = await SialogicQueryClient.fetchQuery({
        queryKey: ["widget_sensor_types"],
        queryFn: () =>
          loadDataFromUrl<WidgetEditorSensorType[]>(
            api_widgets_sensor_types_path(),
          ),
      });

      this.setState({
        sensorTypes: sortBy(types, "name"),
      });
    } catch (err) {
      logger.error("Error loading sensor types.", err);
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  async loadWidgetTypes(): Promise<void> {
    try {
      this.setState({ loading: true });
      const types = await SialogicQueryClient.fetchQuery({
        queryKey: ["widget_types"],
        queryFn: () =>
          loadDataFromUrl<WidgetTypeInfo[]>(api_widgets_types_path()),
      });

      this.setState(
        {
          widgetTypes: sortBy(types, "name"),
        },
        () => this.applyWidgetType(this.props.widgetTypeIdentifier),
      );
    } catch (err) {
      logger.error("Error loading widget types.", err);
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  applyWidgetType(newType: string) {
    const selectedWidgetType = find(
      this.state.widgetTypes,
      (wt) => wt.identifier === newType,
    );
    if (isNil(selectedWidgetType)) {
      this.setState({ valid: false });
      return;
    } else {
      this.setState({ valid: true });
    }
    this.setState({ widgetType: selectedWidgetType }, () => {
      this.setFormValid(null);
      void this.loadWidgetSchema();
    });
  }

  async loadWidget(): Promise<JSONType.Object> {
    if (!isNil(this.state.widget)) {
      return this.state.widget.config;
    }
    try {
      this.setState({ loading: true });
      const widgetDoc = await loadDataFromUrl<
        SingleResourceDoc<string, WidgetJSONAPIAttributes>
      >(
        api_widget_path(this.props.widgetId, {
          _options: true,
          format: "json",
        }),
      );
      const widget = jsonApiSingleResourceToFlatObject(widgetDoc);

      this.dataLoadingComplete(this.state.schema, widget);
      return widget.config;
    } catch (err) {
      logger.error("Error loading widget config.", err);
    } finally {
      this.setState({ loading: false });
    }
    return null;
  }

  dataLoadingComplete(schema: JSONType.Object, widget: WidgetJSONObject): void {
    if (!isNil(schema) && !isNil(widget)) {
      this.setState({ loading: false });
    }
    this.setState({
      schema: this.processSchema(schema),
      widget: { ...widget },
      attachments: widget?.attachments,
    });
  }
}

import { defaultTo, first, isEmpty, isNil, merge } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { SensorValueType } from "../../models/sensor";
import {
  registerSensorUpdates,
  unregisterSensorUpdates,
  unregisterSensorUpdatesForSubscriberIds,
} from "../../utils/sensor_updates";
import { sensorUrl } from "../../utils/urls";
import { WidgetBox } from "./widget_box";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { SensorLoader } from "../../json_api/sensor_loader";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { OffsetGaugeWidgetConfigSerialized } from "../../widgets/offset_gauge_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { computeOffsetValue } from "./algorithm/offset_values";
import { OffsetGaugeWidgetProps } from "./offset_gauge_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetTimestampGridItem } from "./widget_timestamp";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";

const MAX_NUM_LAST_VALUES = 5;

const getGaugeRange = (
  calculationMode: string,
  totalValueRange: { min: number; max: number },
  maxDelta: number,
): [number, number] => {
  if (calculationMode === "value" && totalValueRange) {
    return [
      defaultTo(totalValueRange.min, -Math.abs(maxDelta)),
      defaultTo(totalValueRange.max, Math.abs(maxDelta)),
    ];
  }
  return [-Math.abs(maxDelta), Math.abs(maxDelta)];
};

export const OffsetGaugeWidget: React.FunctionComponent<
  OffsetGaugeWidgetProps
> = ({
  updateEnabled = true,
  valueDisplay = "tick",
  ...props
}: OffsetGaugeWidgetProps) => {
  const [title, setTitle] = React.useState(props.sensor?.name || props.title);
  const [value, setValue] = React.useState(props.value);
  const [timestamp, setTimestamp] = React.useState(props.timestamp);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [offsetValue, setOffsetValue] = React.useState(props.offsetValue);
  const [displayUnit, setDisplayUnit] = React.useState(props.unit);
  const [sensor, setSensor] = React.useState(props.sensor);
  const [baseValue, setBaseValue] = React.useState(props.baseValue);
  const [contentLinkUrl, setContentLinkUrl] = React.useState(
    props.contentLinkUrl,
  );
  const [titleLinkUrl, setTitleLinkUrl] = React.useState(props.titleLinkUrl);
  const [lastValues, setLastValues] = React.useState([0]);
  const [displayTrend, setDisplayTrend] = React.useState(false);
  const [dataUpdateEnabled, setDataUpdateEnabled] = React.useState(
    updateEnabled && props.timeRange.contains(moment()),
  );

  const loadSensor = (sensorId: number): Promise<SensorJSONAPIAttributes> => {
    return SensorLoader.getInstance()
      .getSensors([sensorId])
      .then((sensors) => {
        return sensors?.[0];
      })
      .catch((e) => {
        logger.error(e);
        return null as SensorJSONAPIAttributes;
      });
  };

  useEffect(() => {
    if (isNil(sensor)) {
      loadSensor(props.sensorId as number).then((loadedSensor) => {
        if (loadedSensor) {
          setSensor(loadedSensor);
          setTitle((props.title as string) ?? loadedSensor?.name);
        }
      });
    }
  }, [props.sensorId]);

  useEffect(() => {
    setDataUpdateEnabled(updateEnabled && props.timeRange?.contains(moment()));
  }, [updateEnabled, props.timeRange?.start, props.timeRange?.end]);

  // Register sensor updates when data update is enabled
  useEffect(() => {
    if (!sensor) return;
    const listener = { handleSensorValueUpdate };
    const id = (sensor.id as number) || (props.sensorId as number);
    let subscriptionIds: number[];
    if (
      dataUpdateEnabled &&
      (props.ignoreTimeScope || props.timeRange?.contains(moment()))
    ) {
      subscriptionIds = registerSensorUpdates(listener, [id]);
    }
    return () => {
      // unregister the listener when the component is unmounted, or when the data update is disabled
      // regardless if it has been registered or not
      unregisterSensorUpdatesForSubscriberIds(subscriptionIds, [id]);
    };
  }, [sensor, dataUpdateEnabled, props.timeRange?.start, props.timeRange?.end]);

  //Update title when sensor name or title changes
  useEffect(() => {
    setTitle(props.sensor?.name || props.title);
  }, [props.sensor?.name, props.title]);

  // Update sensor data when sensor timestamp changes
  useEffect(() => {
    if (props.timestamp !== timestamp) {
      setTimestamp(props.timestamp);
    }
  }, [props.timestamp]);

  // Update sensor data when sensor display unit changes
  useEffect(() => {
    setDisplayUnit(sensor?.display_unit || props.unit);
  }, [props.unit, sensor?.display_unit]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    if (props.value !== value) {
      setValue(convertToUnit(props.value as number, props.unit, displayUnit));
    }
  }, [props.value]);

  // Update sensor when sensor changes
  useEffect(() => {
    if (props.sensor !== sensor) {
      setSensor(props.sensor);
    }
  }, [props.sensor]);

  // Update sensor data when sensor link url changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  useEffect(() => {
    setBaseValue(props.baseValue);
  }, [props.baseValue]);

  useEffect(() => {
    setOffsetValue(
      defaultTo(
        computeOffsetValue(
          baseValue,
          value as number,
          defaultTo(props.calculationMode, "base_value_offset"),
        ),
        props.offsetValue,
      ),
    );
  }, [value, baseValue, props.offsetValue, props.calculationMode]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      const newLastValues = lastValues.slice(0, MAX_NUM_LAST_VALUES - 1);
      newLastValues.unshift(value as number);
      setLastValues(newLastValues);
      setValue(
        convertToUnit(
          value as number,
          unit,
          sensor?.display_unit || sensor?.attribute_key_unit,
        ),
      );
      setTimestamp(time);
      setDisplayTrend(
        displayTrend ||
          (newLastValues.length >= MAX_NUM_LAST_VALUES &&
            newLastValues[MAX_NUM_LAST_VALUES - 1] !== 0.0),
      );
    },
    [baseValue, displayTrend, props.calculationMode, sensor],
  );

  const gaugeRange = useMemo(() => {
    const totalValueRange = props.totalValueRange
      ? {
          min: props.totalValueRange.min ?? -Math.abs(props.maxDelta),
          max: props.totalValueRange.max ?? Math.abs(props.maxDelta),
        }
      : undefined;
    return getGaugeRange(
      props.calculationMode,
      totalValueRange,
      props.maxDelta,
    );
  }, [props.calculationMode, props.totalValueRange, props.maxDelta]);

  const trend = useMemo(() => {
    if (displayTrend) {
      return lastValues.reduce((acc, value) => acc + value) / lastValues.length;
    }
    return undefined;
  }, [displayTrend, lastValues]);

  const link = useMemo(() => {
    if (isNil(props.titleLinkUrl) || isEmpty(props.titleLinkUrl)) {
      return sensorUrl(
        {
          id: props.sensorId,
          assetId: props.assetId,
        },
        "html",
      );
    }
    return props.titleLinkUrl;
  }, [props.titleLinkUrl, props.sensorId, props.assetId]);

  const gaugeChart = (
    <PlotlyGaugeChart
      value={offsetValue}
      divId={`widget-${props.widgetId}-diagram-container`}
      unit={displayUnit || props.unit}
      height={fullscreen ? 600 : props.gaugeHeight}
      maxWidth={fullscreen ? "80%" : null}
      range={gaugeRange}
      valueRanges={props.valueRanges}
      displayValueByTick={valueDisplay === "tick"}
      trendValue={trend}
    />
  );

  return (
    <>
      <WidgetBox
        {...props}
        title={title}
        titleLinkUrl={titleLinkUrl}
        contentLinkUrl={defaultTo(contentLinkUrl, link)}
        onFullscreen={(fullscreen) => {
          setFullscreen(fullscreen);
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            {gaugeChart}
          </Grid>
          {isNil(timestamp) ? null : (
            <WidgetTimestampGridItem
              timestamp={getTimeString(null, timestamp)}
              align="center"
            />
          )}
        </Grid>
      </WidgetBox>
    </>
  );
};

function serializedConfigToProps(
  config: OffsetGaugeWidgetConfigSerialized,
): OffsetGaugeWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    updateEnabled: !config.disable_update,
    calculationMode: config.calc_mode,
    totalValueRange: config.total_value_range,
    value: config.value,
    hideValue: config.hide_value,

    maxDelta: config.max_delta,
    baseValue: config.base_value,
    valueRanges: config.value_ranges,
    valueDisplay: config.value_display,
    unit: config.display_unit,
    sensorId: config.sensor_id,
    assetId: config.asset_id,
    offsetValue: config.offset_value,
    showTimeRange: config.show_time_range,
    gaugeHeight: config.gauge_height,
  } as OffsetGaugeWidgetProps);
}

export const OffsetGaugeWidgetDefinition: SialogicWidgetDefinition<
  typeof OffsetGaugeWidget,
  typeof serializedConfigToProps
> = {
  Component: OffsetGaugeWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import ActionCable, { Subscription } from "@rails/actioncable";
import { each, isEmpty, isNil, values } from "lodash";
import { logger } from "../utils/logger";

const WILDCARD_ATTRIBUTE_ID = -1;

export interface EventSubscription<T> {
  subscriptionId: number;
  f: T;
  modelId: number;
}
export abstract class ModelDataChannel<
  EventSubscriberInterface,
  EventSubscriberCableValue,
> {
  subscriptionsByModelId: { [modelId: number]: Subscription };
  modelListeners: {
    [modelId: number]: EventSubscription<EventSubscriberInterface>[];
  };

  lastId: number = 1;

  constructor() {
    this.subscriptionsByModelId = {};
    this.modelListeners = {};
  }

  /**
   * Subscribe to a context state machine channel
   * @param modelId The context state machine id to listen to
   */
  subscribe<T = any>(modelId: number, ...args: T[]) {
    if (!this.isSubscribed(modelId)) {
      const channel = App.cable.subscriptions.create(
        this.getChannelNameWithParams(modelId, args),
        {
          connected: (...args: T[]) => this.onConnect(modelId, args),
          disconnected: (...args: T[]) => this.onDisconnect(modelId, args),
          received: (data: EventSubscriberCableValue) => {
            this.handleDataMessage(data, this.modelListeners[modelId]);
            this.handleDataMessage(
              data,
              this.modelListeners[WILDCARD_ATTRIBUTE_ID],
            );
          },
        },
      );

      this.subscriptionsByModelId[modelId] = channel;
    }
  }

  public isSubscribed(modelId: number) {
    return !isNil(this.subscriptionsByModelId[modelId]);
  }

  protected onConnect(modelId: number, ...args: any[]): void {}

  protected onDisconnect(modelId: number, ...args: any[]): void {}

  protected abstract handleDataMessage(
    data: EventSubscriberCableValue,
    listeners: EventSubscription<EventSubscriberInterface>[],
  ): void;

  protected abstract getChannelNameWithParams(
    modelId: number,
    ...args: any[]
  ): string | ActionCable.ChannelNameWithParams;
  /**
   * Unsubscribe from a context state machine channel
   * @param modelId The context state machine id to unsubscribe from
   */
  unsubscribe(modelId: number) {
    const channel = this.subscriptionsByModelId[modelId];
    if (!isNil(channel)) {
      logger.debug("Unsubscribing from model", modelId);
      channel.unsubscribe();
      delete this.subscriptionsByModelId[modelId];
    }
  }

  /**
   * Unsubscribe from all active context state machine channels
   */
  unsubscribeAll() {
    each(values(this.subscriptionsByModelId), (subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionsByModelId = {};
  }

  /**
   * Adds an Event listener to be notified if a certain value changes. When there is no subscription a subscription will be issues
   *
   *
   * @param {EventSubscriberInterface} listener Listener to be notified
   * @param {number} [modelId] The model id to listen on. -1 for all
   */
  addEventListener(
    listener: EventSubscriberInterface,
    modelId: number = WILDCARD_ATTRIBUTE_ID,
  ): number {
    if (!this.isSubscribed(modelId)) {
      logger.debug("Autosubscribing for model", modelId);
      this.subscribe(modelId);
    }

    let subscribers = this.modelListeners[modelId];
    if (isNil(subscribers)) {
      subscribers = [];
      this.modelListeners[modelId] = subscribers;
    }

    let subscriber = subscribers.find((l) => l.f == listener);
    if (!subscriber) {
      subscriber = { subscriptionId: this.lastId++, f: listener, modelId };
      subscribers.push(subscriber);
    }
    return subscriber.subscriptionId;
  }

  /**
   * Remove a event listener. If the listener is the last listener for the model, the subscription will be removed.
   *
   * @param {EventSubscriberInterface} listener Listener to be notified
   * @param {number} [modelId] The model id to listen on. -1 for all models
   */
  removeEventListener(
    listener: EventSubscriberInterface,
    modelId: number = WILDCARD_ATTRIBUTE_ID,
  ) {
    const subscribers = this.modelListeners[modelId];
    if (isEmpty(subscribers)) {
      if (this.isSubscribed(modelId) && modelId !== WILDCARD_ATTRIBUTE_ID) {
        this.unsubscribe(modelId);
      }
      return;
    }

    const index = subscribers.findIndex((l) => l.f == listener);
    if (index !== -1) {
      subscribers.splice(index, 1);
    }

    if (isEmpty(subscribers)) {
      // If there are no more listeners, unsubscribe
      if (this.isSubscribed(modelId) && modelId !== WILDCARD_ATTRIBUTE_ID) {
        this.unsubscribe(modelId);
      }
    }
  }

  /**
   * Remove a event listener
   * @param {EventSubscriberInterface} listener Listener to be notified
   * @param {number} [modelId] The model id to listen on. -1 for all models
   */
  removeEventListenerId(id: number, modelId: number = WILDCARD_ATTRIBUTE_ID) {
    if (isNil(id)) return false;
    let subscribers: EventSubscription<EventSubscriberInterface>[][];
    if (isNil(modelId) || modelId === WILDCARD_ATTRIBUTE_ID) {
      subscribers = values(this.modelListeners);
    } else {
      subscribers = [this.modelListeners[modelId]];
    }

    if (isEmpty(subscribers)) {
      if (this.isSubscribed(modelId) && modelId !== WILDCARD_ATTRIBUTE_ID) {
        this.unsubscribe(modelId);
      }
      return false;
    }

    let removed = false;
    each(subscribers, (modelSubscribers) => {
      const index = modelSubscribers.findIndex((l) => l.subscriptionId == id);
      if (index !== -1) {
        const subscription = modelSubscribers[index];
        modelSubscribers.splice(index, 1);

        if (
          isEmpty(modelSubscribers) &&
          this.isSubscribed(subscription.modelId) &&
          subscription.modelId !== WILDCARD_ATTRIBUTE_ID
        ) {
          this.unsubscribe(subscription.modelId);
        }
        removed = true;
      }
    });

    return removed;
  }
}

export default ModelDataChannel;

import { Container } from "flux/utils";
import { has, isEmpty, isNil } from "lodash";
import moment from "moment";
import * as React from "react";
import "../../../utils/flux_container_adapter";

import { dialog } from "../../../utils/dialog";
import {
  HttpError,
  RequestMethod,
  sendData,
} from "../../../utils/jquery_helper";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";
import * as Actions from "../data/measurement_actions";
import MeasurementStore, {
  MeasurementState,
  MeasurementStore as MeasurementStoreType,
} from "../data/measurement_store";
import { MeasurementForm } from "../views/measurement_form";
import { MeasurementErrorResponse } from "../data/model";

interface MaintenanceContainerBaseProps {}

/**
 * Base class of maintenance plan container.
 * This is never used directly but extended with the Flux.Container mixin.
 */
class MeasurementContainerBase extends React.Component<
  MaintenanceContainerBaseProps,
  MeasurementState
> {
  static getStores(): MeasurementStoreType[] {
    return [MeasurementStore];
  }

  static calculateState(prevState: MeasurementState): MeasurementState {
    return MeasurementStore.getState();
  }

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;
  render(): React.ReactNode {
    const state = this.state;

    return (
      <MeasurementForm
        measurement={state.measurement}
        users={state.users}
        isProcessing={state.isProcessing}
        onSubmit={() => {
          void this.onSaveMeasurement();
        }}
        onCancel={() => this.onCancel()}
        onUpdateMeasurement={Actions.updateMeasurement}
        onUpdateMeasurementValue={Actions.updateMeasurementValue}
        onDelete={
          isEmpty(this.state.measurement?.id) ? null : () => this.onDelete()
        }
      />
    );
  }

  private onSaveMeasurement(): Promise<void> {
    try {
      Actions.setProcessing(true);
      Actions.resetErrors();

      // Use signed id of new files to connect file to measurement
      const createdAt = moment.isMoment(this.state.measurement.created_at)
        ? this.state.measurement.created_at.toISOString(true)
        : this.state.measurement.created_at;

      const mode = isNil(this.state.measurement.id) ? "create" : "update";
      const clonedMeasurement = { ...this.state.measurement };
      delete clonedMeasurement.id;
      delete clonedMeasurement.created_by;
      delete clonedMeasurement.measurement_plan;
      delete clonedMeasurement.errors;
      //clonedMeasurement.m

      const measurement = {
        ...clonedMeasurement,
        created_by_id: this.state.measurement.created_by?.id,
        created_by_type: this.state.measurement.created_by?.type,
        created_at: createdAt,
        documents: this.state.measurement?.documents.map((document) => {
          return document.blob ? document.blob.signed_id : document.signed_id;
        }),
      };

      const formUrl =
        mode === "create"
          ? url_helper.createMeasurementPath(
              this.state.measurement.measurement_plan.id,
              "json",
            )
          : url_helper.updateMeasurementPath(this.state.measurement.id, "json");
      const method = mode === "create" ? "POST" : "PATCH";

      return sendData(formUrl, { measurement }, method)
        .then(() => {
          this.redirect();
        })
        .catch((error) => {
          Actions.setProcessing(false);

          // load errors from response if present
          if (has(error, "request.responseJSON")) {
            Actions.setErrors(
              (error as HttpError).request
                .responseJSON as MeasurementErrorResponse,
            );
          }

          void toast.error(
            I18n.t("frontend.measurement_form.error"),
            I18n.t("frontend.measurement_form.error_title"),
            true,
          );
        });
    } catch (error) {
      if (error instanceof Error) {
        logger.logError(error);
      }
      Actions.setProcessing(false);
      void toast.error(
        I18n.t("frontend.measurement_form.error"),
        I18n.t("frontend.measurement_form.error_title"),
        true,
      );
    }
  }

  private onCancel(): void {
    Actions.setProcessing(false);
    this.redirect();
  }

  private onDelete() {
    const id = this.state?.measurement?.id;
    const assetId =
      this.state?.measurement?.asset_id ??
      this.state.measurement?.measurement_plan?.asset.id;
    const measurementPlanId =
      this.state?.measurement?.measurement_plan_id ??
      this.state.measurement?.measurement_plan?.id;
    if (!isNil(id)) {
      void dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          text: I18n.t("frontend.are_you_sure"),
          title: I18n.t("frontend.delete"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            Actions.setProcessing(true);
            const url: string = url_helper.measurementPath(id, "json");
            const method: RequestMethod = "DELETE";
            sendData(url, null, method)
              .then(() => {
                redirectTo(
                  url_helper.assetMeasurementPlansMeasurementsPath(
                    assetId,
                    measurementPlanId,
                  ),
                );
              })
              .catch(() => {
                Actions.setProcessing(false);
                void toast.error(
                  I18n.t("frontend.measurement_form.error_on_delete"),
                  I18n.t("frontend.measurement_form.error_title"),
                  true,
                );
              });
          } else {
            Actions.setProcessing(false);
          }
        });
    }
  }

  private redirect(): void {
    const { measurement } = this.state;

    if (!isNil(measurement.id)) {
      redirectTo(url_helper.measurementPath(measurement.id));
    } else if (
      !isNil(measurement.measurement_plan_id) ||
      !isNil(measurement?.measurement_plan?.id)
    ) {
      redirectTo(
        url_helper.assetMeasurementPlansMeasurementsPath(
          measurement.measurement_plan?.asset?.id,
          measurement.measurement_plan_id ?? measurement?.measurement_plan?.id,
        ),
      );
    } else if (
      !isNil(this.context.referrer) &&
      !isEmpty(this.context.referrer)
    ) {
      // redirect to referrer
      redirectTo(this.context.referrer);
    }
  }
}

/**
 * A Flux container component that connects the measurement form to the store and actions.
 */
export const MeasurementContainer =
  Container.create<MaintenanceContainerBaseProps>(
    MeasurementContainerBase as any,
  ) as React.ComponentType<MaintenanceContainerBaseProps>;

import RestoreIcon from "@mui/icons-material/RestoreFromTrash";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  chain,
  cloneDeep,
  filter,
  findIndex,
  includes,
  isNil,
  some,
} from "lodash";
import moment from "moment";
import * as React from "react";

import { dialog } from "../../../utils/dialog";
import { sendData } from "../../../utils/jquery_helper";
import * as toast from "../../../utils/toasts";
import * as url_helper from "../../../utils/urls";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { InfluxJournal } from "../data/model";

export interface RestoreSensorDataFormProps {
  assetId: string | number;
  influxJournals: InfluxJournal[];
  journalEntriesRemoved(entries: InfluxJournal[]): void;
  onCancel(): void;
}

export interface RestoreSensorDataFormState {
  influxJournals: InfluxJournal[];
  selectAll: boolean;
  processing: boolean;
}

interface RestoreSensorDataRequest {
  influx_journal_ids: Array<number | string>;
}

interface RestoreSensorDataResponse {
  success?: boolean;
  journal_entries: InfluxJournal[];
  error?: string;
}
export class RestoreSensorDataForm extends React.Component<
  RestoreSensorDataFormProps,
  RestoreSensorDataFormState
> {
  static getDerivedStateFromProps(
    props: RestoreSensorDataFormProps,
    current_state: RestoreSensorDataFormState,
  ): RestoreSensorDataFormState {
    if (current_state.influxJournals?.length !== props.influxJournals?.length) {
      return {
        ...current_state,
        influxJournals: cloneDeep(props.influxJournals),
      };
    } else {
      return null;
    }
  }

  constructor(props: RestoreSensorDataFormProps) {
    super(props);

    this.state = {
      influxJournals: cloneDeep(props.influxJournals),
      selectAll: false,
      processing: false,
    };
  }

  render(): React.ReactNode {
    return (
      <Grid container>
        <Grid item xs={12}>
          <IBox>
            <IBoxTitle>
              <Typography variant="h5">
                {I18n.t("frontend.manage_sensor_data_form.restore_sensor_data")}
              </Typography>
            </IBoxTitle>
            <IBoxContent>
              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <FormControlLabel
                    label={I18n.t(
                      "frontend.manage_sensor_data_form.select_all",
                    )}
                    control={
                      <Checkbox
                        checked={this.state.selectAll ?? false}
                        value="true"
                        onChange={(event) => this.onSelectAll(event)}
                      />
                    }
                  />
                </Grid>

                <Grid item container xs={12}>
                  <Grid item xs={1}>
                    <InputLabel shrink>
                      {I18n.t("frontend.selected")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>
                      {I18n.t("activerecord.models.sensor", { count: 1 })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel shrink>
                      {I18n.t("frontend.manage_sensor_data_form.time_range")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>
                      {I18n.t("frontend.manage_sensor_data_form.done_by")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>
                      {I18n.t("frontend.manage_sensor_data_form.done_at")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <InputLabel shrink>{I18n.t("frontend.actions")}</InputLabel>
                  </Grid>
                </Grid>
                {this.state.influxJournals.map((influxJournal) =>
                  this.renderInfluxJournalEntry(influxJournal),
                )}
              </Grid>
            </IBoxContent>
          </IBox>
          <FixedBottomArea key="bot-buttons">
            <FloatingButtons
              showScrollToTopBtn
              isProcessing={this.state.processing}
              onSubmit={() => this.onRestore(this.getSelectedJournalIds())}
              onCancel={() => this.props.onCancel()}
              submitBtnIcon={<RestoreIcon />}
              saveTitle={I18n.t("frontend.restore")}
            />
          </FixedBottomArea>
        </Grid>
      </Grid>
    );
  }

  private renderInfluxJournalEntry(influxJournal: InfluxJournal) {
    if (isNil(influxJournal)) return null;
    return (
      <Grid item xs={12} container key={`influx-journal-${influxJournal.id}`}>
        <Grid item xs={1}>
          <Checkbox
            checked={influxJournal.selected ?? false}
            value="true"
            onChange={(event) => this.onSelectJournal(influxJournal, event)}
          />
        </Grid>
        <Grid item xs={2}>
          {influxJournal.sensor_name}
        </Grid>
        <Grid item xs={3}>
          {moment(influxJournal.time_range_start).format("L LT")} -{" "}
          {moment(influxJournal.time_range_end).format("L LT")}
        </Grid>
        <Grid item xs={2}>
          {influxJournal.done_by.name}
        </Grid>
        <Grid item xs={2}>
          {moment(influxJournal.done_at).format("L LT")}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="small"
            aria-label={I18n.t("frontend.restore")}
            title={I18n.t("frontend.restore")}
            onClick={() => this.onRestore([influxJournal.id])}
          >
            <RestoreIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  private onRestore(influxJournalIds: Array<number | string>) {
    return dialog
      .fire({
        title: I18n.t("frontend.manage_sensor_data_form.restore_sensor_data"),
        text: I18n.t("frontend.manage_sensor_data_form.restore_dialog_text"),
        showConfirmButton: true,
        showCancelButton: true,
        icon: "question",
        cancelButtonText: I18n.t("frontend.cancel"),
        confirmButtonText: I18n.t("frontend.restore"),
      })
      .then((result) => {
        if (result.isDismissed) {
          return;
        }

        this.setState({ processing: true });

        return sendData<RestoreSensorDataRequest, RestoreSensorDataResponse>(
          url_helper.manageSensorRestoreDataUrl(this.props.assetId),
          {
            influx_journal_ids: influxJournalIds,
          },
          "POST",
        ).then((resp) => {
          void toast.success(
            I18n.t("frontend.manage_sensor_data_form.restore_success"),
          );
          this.props.journalEntriesRemoved?.(resp.journal_entries);
          // Remove restored journal entries
          this.setState((prevState) => {
            return {
              influxJournals: filter(
                prevState.influxJournals,
                (journal) => !includes(influxJournalIds, journal.id),
              ),
            };
          });
        });
      })
      .catch(() => {
        void toast.error(
          I18n.t("frontend.manage_sensor_data_form.error_on_restore"),
          I18n.t("frontend.manage_sensor_data_form.error_title"),
          true,
        );
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  }

  private onSelectJournal(
    influxJournal: InfluxJournal,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    event.persist();

    this.setState((prevState) => {
      const influxJournals = [...prevState.influxJournals];
      const journalIndex = findIndex(
        influxJournals,
        (j) => j.id === influxJournal.id,
      );

      if (journalIndex > -1) {
        influxJournals[journalIndex] = {
          ...influxJournal,
          selected: event.target.checked,
        };
      }

      const selectAll = !some(influxJournals, (j) => !j.selected);

      return {
        influxJournals,
        selectAll,
      };
    });
  }

  private onSelectAll(event: React.ChangeEvent<HTMLInputElement>): void {
    event.persist();

    this.setState((prevState) => {
      const influxJournals = cloneDeep(prevState.influxJournals);

      influxJournals.forEach((influxJournal) => {
        influxJournal.selected = event.target.checked;
      });

      return {
        influxJournals,
        selectAll: event.target.checked,
      };
    });
  }

  private getSelectedJournalIds(): Array<string | number> {
    return chain(this.state.influxJournals)
      .filter((j) => j.selected)
      .map((j) => j.id)
      .value();
  }
}

import { Device } from "../models/device";
import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";

import { AssetJSONObject } from "./asset";
import { ProductModelJSONObject } from "./product_model";

export const DEVICE_JSONAPI_RESOURCE_TYPE = "devices";
export interface DeviceJsonApiAttributes extends Device {
  pairing_start: string;
  pairing_end: string;
}

export interface DeviceJSONObject extends DeviceJsonApiAttributes, JSON.Object {
  asset?: AssetJSONObject;
  productModel?: ProductModelJSONObject;
}

export type DeviceJSONAPIAttributes =
  JSONAPI.AttributesObject<DeviceJSONObject>;

import { clone, includes, isNil, pull } from "lodash";
import * as React from "react";
import { Component } from "react";

import {
  AllMaintenancePlanTypes,
  AllMaintenancePlanTypesUnderscore,
  MaintenancePlanType,
  MaintenancePlanTypeFilterSelection,
} from "../../maintenance_plan_form/data/models";

import { CheckBox, Square } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { MaintenancePlanIcon } from "./maintenance_plan_icon";

export interface MaintenancePlanTypeFilterProps {
  className?: string;
  filter: MaintenancePlanTypeFilterSelection;

  onFilterChanged: (filter: MaintenancePlanTypeFilterSelection) => void;
}

export interface MaintenancePlanTypeFilterState {}

export class MaintenancePlanTypeFilter extends Component<
  MaintenancePlanTypeFilterProps,
  MaintenancePlanTypeFilterState
> {
  render(): React.ReactNode {
    // do not render anything if filter or callback not valid
    if (isNil(this.props.filter) || isNil(this.props.onFilterChanged))
      return null;

    return (
      <ButtonGroup className={this.props.className} size="small">
        {AllMaintenancePlanTypes.map((maintenancePlanType, index) => (
          <Button
            variant="outlined"
            key={index}
            onClick={(e) => {
              this.handleMaintenancePlanTypeSelection(maintenancePlanType);
            }}
            startIcon={
              includes(
                this.props.filter.maintenancePlanTypes,
                maintenancePlanType,
              ) ? (
                <CheckBox fontSize="inherit" />
              ) : (
                <Square />
              )
            }
            endIcon={
              <MaintenancePlanIcon maintenancePlanType={maintenancePlanType} />
            }
          >
            <span className="d-none d-sm-inline">
              {I18n.t(
                `activerecord.attributes.maintenance_plan.maintenance_type_list.${AllMaintenancePlanTypesUnderscore[index]}`,
                { count: 2 },
              )}
            </span>
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  private handleMaintenancePlanTypeSelection(
    maintenanceType: MaintenancePlanType,
  ) {
    const maintenancePlanTypes = clone(this.props.filter.maintenancePlanTypes);
    if (includes(maintenancePlanTypes, maintenanceType)) {
      pull(maintenancePlanTypes, maintenanceType);
    } else {
      maintenancePlanTypes.push(maintenanceType);
    }
    this.props.onFilterChanged({ ...this.props.filter, maintenancePlanTypes });
  }
}

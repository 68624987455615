import { Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import { defaultTo, isEmpty, isNil, map, toNumber } from "lodash";
import * as React from "react";
import { EventPatternJSONAPIAttributes } from "../../json_api/event_pattern";
import { ModelErrors, modelPropertyError } from "../../json_api/jsonapi_tools";
import {
  EVENT_PATTERN_COMPARATOR_REQUIRED_FIELDS,
  EventPatternComparator,
} from "../../models/event_pattern";
import { unitDisplayString } from "../../utils/unit_conversion";
interface EventPatternConditionFieldsProps {
  eventPattern: EventPatternJSONAPIAttributes;

  errors?: ModelErrors<EventPatternJSONAPIAttributes>;
  defaultComparator?: EventPatternComparator;

  // Overrides display unit of the selcted sensor
  unit?: string;
  readonly?: boolean;
  comparators?: EventPatternComparator[];
  onComparatorSelect(
    newComparator: EventPatternComparator,
    changes: Record<keyof EventPatternJSONAPIAttributes, number>,
  ): void;

  fieldSize?: "small" | "medium";
}

export const EventPatternConditionFields: React.FunctionComponent<
  EventPatternConditionFieldsProps
> = ({ fieldSize = "small", ...props }) => {
  const reqFields =
    EVENT_PATTERN_COMPARATOR_REQUIRED_FIELDS[props.eventPattern.comparator] ||
    [];
  const numFields = reqFields?.length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          select
          size={fieldSize}
          disabled={props.readonly}
          InputProps={{
            readOnly: props.readonly,
          }}
          title={I18n.t(
            `activerecord.attributes.event_patterns/event_pattern.comparators.${defaultTo(
              props.eventPattern.comparator,
              "inside range",
            )}`,
          )}
          error={!isEmpty(props.errors?.comparator)}
          value={defaultTo(
            props.eventPattern.comparator,
            props.defaultComparator || "inside range",
          )}
          helperText={modelPropertyError(props.errors, "comparator")}
          label={I18n.t(
            "activerecord.attributes.event_patterns/event_pattern.comparator",
          )}
          onChange={(e) =>
            props.onComparatorSelect(
              e.target.value as EventPatternComparator,
              null,
            )
          }
        >
          {map(
            props.comparators || EVENT_PATTERN_COMPARATOR_REQUIRED_FIELDS,
            (value, key) => (
              <MenuItem key={key} value={key}>
                {I18n.t(
                  `activerecord.attributes.event_patterns/event_pattern.comparators.${key}`,
                )}
              </MenuItem>
            ),
          )}
        </TextField>
      </Grid>
      {map(reqFields, (fieldName) => (
        <Grid item key={fieldName} xs={numFields == 1 ? 12 : 6}>
          <TextField
            size={fieldSize}
            required
            fullWidth
            value={
              isNil(props.eventPattern[fieldName])
                ? ""
                : props.eventPattern[fieldName]
            }
            title={I18n.t(
              `activerecord.attributes.event_patterns/event_pattern.${fieldName}`,
            )}
            label={I18n.t(
              `activerecord.attributes.event_patterns/event_pattern.${fieldName}`,
            )}
            onChange={(e) => {
              const change: Record<
                keyof EventPatternJSONAPIAttributes,
                number
              > = {};
              change[fieldName] = toNumber(e.target.value);
              props.onComparatorSelect(props.eventPattern.comparator, change);
            }}
            error={!isEmpty(props.errors?.[fieldName])}
            helperText={modelPropertyError(props.errors, fieldName)}
            disabled={props.readonly}
            InputProps={{
              type: "number",
              readOnly: props.readonly,
              endAdornment: isEmpty(
                props.eventPattern?.sensor?.display_unit,
              ) ? null : (
                <InputAdornment position="end">
                  {unitDisplayString(
                    defaultTo(
                      props.unit,
                      props.eventPattern?.sensor?.display_unit,
                    ),
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

import { useQuery } from "@tanstack/react-query";
import { CollectionResourceDoc } from "jsonapi-typescript";
import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { jsonApiResourceCollectionToFlatObjects } from "../../../json_api/jsonapi_tools";
import { ProductModelJSONObject } from "../../../json_api/product_model";
import { api_product_models_path } from "../../../routes";
import { loadDataFromUrl } from "../../../utils/jquery_helper";
import {
  AssetTemplateFormContextProviderProps,
  AssetTemplateFormContextType,
} from "./asset_template_form_context_provider.types";

import { Skeleton } from "@mui/material";
import { groupBy, isEmpty, isNil } from "lodash";
import { forEachAssetTemplate } from "../tools/tree_tools";
import { AssetTemplateFormContext } from "./asset_template_form_context";

async function loadProductModels(
  assetTypeIds: number[],
): Promise<ProductModelJSONObject[]> {
  if (isNil(assetTypeIds) || isEmpty(assetTypeIds)) return null;

  const assetTypeIdsArray = Array.from(assetTypeIds);
  const url = api_product_models_path({
    _options: true,
    filter: { asset_type_id: assetTypeIdsArray.join(",") },
  });
  const productModelsDoc =
    await loadDataFromUrl<
      CollectionResourceDoc<string, ProductModelJSONObject>
    >(url);
  const productModels =
    jsonApiResourceCollectionToFlatObjects(productModelsDoc);
  return productModels;
}

export const AssetTemplateFormContextProvider: FunctionComponent<
  AssetTemplateFormContextProviderProps
> = (props) => {
  const assetTypeIdsFromTemplates = useMemo(() => {
    const assetTypeIds = new Set<number>();
    forEachAssetTemplate(props.templates, (assetTemplate) => {
      assetTypeIds.add(assetTemplate.asset_type_id);
    });
    return Array.from(assetTypeIds);
  }, [props.templates]);

  const { isLoading, data: productModels } = useQuery({
    queryKey: ["productModels", { assetTypeIds: assetTypeIdsFromTemplates }],
    queryFn: async (q) => {
      if (isEmpty(assetTypeIdsFromTemplates)) return null;

      return loadProductModels(assetTypeIdsFromTemplates) as Promise<
        ProductModelJSONObject[]
      >;
    },
    enabled: !isEmpty(assetTypeIdsFromTemplates),
  });

  const [context, setContext] = useState<AssetTemplateFormContextType>({
    productModelsForAssetTypeIds: null,
  });

  React.useEffect(() => {
    if (isEmpty(productModels)) {
      setContext((prev) => ({ ...prev, productModelsForAssetTypeIds: null }));
      return;
    }

    if (productModels) {
      const productModelsForAssetTypeIds = groupBy<ProductModelJSONObject>(
        productModels,
        (productModel) => productModel.asset_type_id as number,
      );
      setContext((prev) => ({ ...prev, productModelsForAssetTypeIds }));
    }
  }, [productModels]);

  return (
    <AssetTemplateFormContext.Provider value={context}>
      {isLoading ? <Skeleton height={500} /> : props.children}
    </AssetTemplateFormContext.Provider>
  );
};

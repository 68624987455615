import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BackIcon from "@mui/icons-material/NavigateBefore";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  each,
  isEmpty,
  defaultTo,
  isNil,
  values,
  last,
  first,
  toString,
  noop,
  fill,
  reject,
  findIndex,
  isString,
  every,
  flatten,
  isFunction,
  keyBy,
  toArray,
  map,
  toInteger,
  Dictionary,
  find,
  trim,
  chain,
  findKey,
  get,
  set,
} from "lodash";
import * as React from "react";

import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementPlan } from "../../../models/measurement_plan";
import { MeasurementTypes } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { dialog } from "../../../utils/dialog";
import { TypedErrorMap } from "../../../utils/error_map";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButton, FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { MeasurementUnitSelect } from "../../common/measurement_unit_select";
import { NotificationSettingsInput } from "../../common/notification_settings_input";
import { RotationInput } from "../../common/rotation_input/rotation_input";
import { FormMode } from "../data/measurement_plan_actions";
import { MeasurementPlanCreationMode } from "./measurement_plan_form_step_1";
import { MeasurementValueDefinitionList } from "./measurement_value_definition_list";

interface MeasurementPlanDetailsFormProperties {
  measurementPlan: MeasurementPlan;

  createBy: MeasurementPlanCreationMode;
  typeOfMeasurement?: MeasurementTypes;
  allowMvdDelete?: boolean;
  allowEdit?: boolean;
  mode: FormMode;

  // measurement unit in case of distribution measurements
  unit?: string;
  // unit for intervall values

  availableMeasurementCategorizations: MeasurementCategorization[];
  processing?: boolean;

  onAddMeasurementValueDefinition?: () => void;
  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void;
  onMoveMeasurementValueDefinition?: (index: number, direction: 1 | -1) => void;
  onRemoveMeasurementValueDefinition?: (
    mvd: MeasurementValueDefinition,
    index: number,
  ) => void;

  onCategorizationSelect: (mCat: MeasurementCategorization) => void;
  onUpdateMeasurementPlan: (mPlan: MeasurementPlan) => void;
  onUnitSelect: (unit: string) => void;
  onSubmit?: (plan: MeasurementPlan) => void;
  onCancel?: () => void;
  onBack?: () => void;
  onToggleEdit?: (editable: boolean) => void;
  onDelete?: () => void;
  errors?: TypedErrorMap<MeasurementPlan>;
}

/**
 * A list of maintenace plans of an asset.
 */
export class MeasurementPlanDetailsForm extends React.Component<MeasurementPlanDetailsFormProperties> {
  constructor(props: MeasurementPlanDetailsFormProperties) {
    super(props);
    const measurementValueDefintions = get(props, [
      "measurementPlan",
      "measurement_type",
      "measurement_value_definitions",
    ]);
    const measurementType = defaultTo(
      get(props, ["measurementPlan", "measurement_type"]),
      { measurement_value_definitions: [] },
    );
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} key="base-data-row">
          <IBox>
            <IBoxTitle>
              <h4>
                {I18n.t(
                  "frontend.measurement_plan_form.create_measurement_plan_step_2_heading",
                  {},
                )}
              </h4>
            </IBoxTitle>
            <IBoxContent>
              <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} xl={6}>
                    <TextField
                      inputProps={{ readOnly: this.props.allowEdit === false }}
                      fullWidth={true}
                      id="name-text-field"
                      size="small"
                      label={I18n.t(
                        "activerecord.attributes.measurement_plan.title",
                      )}
                      required={true}
                      value={toString(
                        this.props.measurementPlan?.measurement_type?.title,
                      )}
                      error={
                        !isNil(this.props.errors?.measurement_type) &&
                        !isEmpty(this.props.errors?.measurement_type?.title)
                      }
                      helperText={
                        isNil(this.props.errors?.measurement_type)
                          ? ""
                          : this.props.errors?.measurement_type?.title
                      }
                      onChange={(changeEvent) => {
                        this.setMeasurementPlanAttribute("measurement_type", {
                          ...this.props.measurementPlan.measurement_type,
                          title: changeEvent.target.value,
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <RotationInput
                      rrule={this.props.measurementPlan.rrule}
                      rotationType="planned_after_time"
                      allowChooseRotationType={false}
                      allowEdit={this.props.allowEdit}
                      onApplyChange={(rrule, rotationType, criticalValue) => {
                        this.setMeasurementPlanAttribute("rrule", rrule);
                      }}
                      showDelete={true}
                      showLabel={false}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} xl={6}>
                    <NotificationSettingsInput
                      value={this.props.measurementPlan.notification_setting}
                      options={[
                        "none",
                        "one_day_ahead",
                        "two_days_ahead",
                        "one_week_ahead",
                      ]}
                      allowEdit={this.props.allowEdit}
                      error={this.props.errors?.notification_setting}
                      onChange={(value) =>
                        this.setMeasurementPlanAttribute(
                          "notification_setting",
                          value,
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} xl={6} sx={{ paddingTop: 0 }}>
                  <FormGroup>
                    <FormControlLabel
                      label={I18n.t(
                        "frontend.measurement_plan_form.allow_attachments",
                      )}
                      control={
                        <Checkbox
                          size="small"
                          checked={
                            this.props.measurementPlan?.measurement_type
                              ?.allow_attachments == true
                          }
                          value="allow_attachments"
                          onChange={(changeEvent) => {
                            this.setMeasurementPlanAttribute(
                              "measurement_type",
                              {
                                ...this.props.measurementPlan?.measurement_type,
                                allow_attachments: changeEvent.target.checked,
                              },
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      label={I18n.t(
                        "frontend.measurement_plan_form.allow_measurement_notes",
                      )}
                      control={
                        <Checkbox
                          size="small"
                          checked={
                            this.props.measurementPlan.measurement_type
                              ?.allow_measurement_notes == true
                          }
                          value="allow_measurement_notes"
                          onChange={(changeEvent) => {
                            this.setMeasurementPlanAttribute(
                              "measurement_type",
                              {
                                ...this.props.measurementPlan?.measurement_type,
                                allow_measurement_notes:
                                  changeEvent.target.checked,
                              },
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </IBoxContent>
          </IBox>
        </Grid>
        <Grid item xs={12} key="props-row">
          {this.props.typeOfMeasurement ===
          "MeasurementTypes::DistributionMeasurementType" ? (
            <IBox key="ibox-add-props">
              <IBoxContent>
                <Grid container>
                  <Grid item xs={12} className="mt-3">
                    <MeasurementUnitSelect
                      allowEdit={
                        this.props.allowEdit &&
                        this.props.createBy !== "template"
                      }
                      unit={toString(this.props.unit)}
                      id={`select-unit-select`}
                      labelText={I18n.t(
                        "frontend.measurement_plan_form.select_measurement_value_unit",
                      )}
                      onChange={(newUnit) => {
                        if (
                          !isNil(this.props.onUpdateMeasurementValueDefinition)
                        ) {
                          this.props.onUnitSelect?.(newUnit);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-3">
                    <MeasurementUnitSelect
                      allowEdit={
                        this.props.allowEdit &&
                        this.props.createBy !== "template"
                      }
                      unit={
                        isEmpty(
                          this.props.measurementPlan?.measurement_type
                            ?.interval_unit,
                        )
                          ? ""
                          : this.props.measurementPlan?.measurement_type
                              ?.interval_unit
                      }
                      id={`select-interval-unit-label`}
                      labelText={I18n.t(
                        "frontend.measurement_plan_form.select_interval_unit",
                      )}
                      onChange={(newUnit) => {
                        if (
                          !isNil(this.props.onUpdateMeasurementValueDefinition)
                        ) {
                          this.setMeasurementPlanAttribute("measurement_type", {
                            ...this.props.measurementPlan?.measurement_type,
                            interval_unit: newUnit,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-3">
                    <TextField
                      select
                      fullWidth
                      label={I18n.t(
                        "frontend.measurement_plan_form.select_categorization",
                      )}
                      disabled={
                        this.props.createBy === "template" ||
                        this.props.allowEdit === false
                      }
                      id="select-categorization-select"
                      value={toString(
                        this.props.measurementPlan?.measurement_type
                          ?.measurement_categorization?.id,
                      )}
                      onChange={(event) => {
                        this.handleCategorizationSelect?.(
                          event.target.value,
                        );
                      }}
                    >
                      <MenuItem key="none" value="">
                        <i>{I18n.t("frontend.no_selection")}</i>
                      </MenuItem>
                      {this?.props.availableMeasurementCategorizations?.map(
                        (cat, index) => (
                          <MenuItem key={index} value={cat.id}>
                            {cat.title}
                          </MenuItem>
                        ),
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </IBoxContent>
            </IBox>
          ) : null}
        </Grid>
        <Grid item xs={12} key="value-list-row">
          <IBox>
            <IBoxTitle>
              <h4>
                {this.props.typeOfMeasurement ===
                "MeasurementTypes::DistributionMeasurementType"
                  ? I18n.t(
                      "frontend.measurement_plan_form.distribution_intervals_heading",
                    )
                  : I18n.t(
                      "frontend.measurement_plan_form.measurement_values_heading",
                    )}
              </h4>
            </IBoxTitle>
            <IBoxContent>
              <Grid container>
                <Grid item xs={12}>
                  <MeasurementValueDefinitionList
                    errors={
                      this.props.errors?.measurement_type
                        ?.measurement_value_definitions
                    }
                    allowEdit={this.props.allowEdit}
                    unit={this.props.unit}
                    interval_unit={
                      this.props.measurementPlan?.measurement_type
                        ?.interval_unit
                    }
                    allowDelete={this.props.allowMvdDelete}
                    categorization={
                      this.props.measurementPlan.measurement_type
                        .measurement_categorization
                    }
                    measurementValueDefinitions={
                      this.props.measurementPlan.measurement_type
                        .measurement_value_definitions
                    }
                    typeOfMeasurement={this.props.typeOfMeasurement}
                    onAddMeasurementValueDefinition={
                      this.props.onAddMeasurementValueDefinition
                    }
                    onUpdateMeasurementValueDefinition={
                      this.props.onUpdateMeasurementValueDefinition
                    }
                    onMoveMeasurementValueDefinition={
                      this.props.onMoveMeasurementValueDefinition
                    }
                    onRemoveMeasurementValueDefinition={
                      this.props.onRemoveMeasurementValueDefinition
                    }
                  ></MeasurementValueDefinitionList>
                </Grid>
              </Grid>
            </IBoxContent>
          </IBox>
        </Grid>
        <FixedBottomArea key="bot-buttons">
          <FloatingButtons
            showScrollToTopBtn={true}
            isProcessing={this.props.processing}
            onCancel={
              this.props.allowEdit === false ? null : this.props.onCancel
            }
            onSubmit={
              this.props.allowEdit === false
                ? null
                : () => {
                    this.props.onSubmit(this.props.measurementPlan);
                  }
            }
          >
            {" "}
            <Grid container>
              {isNil(this.props.onBack) ? (
                <></>
              ) : (
                <Grid item xs={12}>
                  <FloatingButton
                    title={I18n.t("frontend.back")}
                    size="medium"
                    //color="primary"
                    onClick={() => {
                      if (this.props.onBack) this.props.onBack();
                    }}
                  >
                    <BackIcon />
                  </FloatingButton>
                </Grid>
              )}
              {!isNil(this.props.onToggleEdit) &&
              this.props.allowEdit !== false ? (
                <></>
              ) : (
                <Grid item xs={12}>
                  <FloatingButton
                    title={I18n.t("frontend.edit")}
                    disabled={this.props.processing}
                    size="medium"
                    onClick={() => {
                      if (this.props.onToggleEdit)
                        this.props.onToggleEdit(true);
                    }}
                  >
                    <EditIcon />
                  </FloatingButton>
                </Grid>
              )}
              {this.props.mode === "create" || isNil(this.props.onDelete) ? (
                <></>
              ) : (
                <Grid item xs={12}>
                  <FloatingButton
                    disabled={this.props.processing}
                    title={I18n.t("frontend.delete")}
                    size="medium"
                    color="secondary"
                    onClick={() => {
                      if (this.props.onDelete) this.props.onDelete();
                    }}
                  >
                    <DeleteIcon />
                  </FloatingButton>
                </Grid>
              )}
            </Grid>
          </FloatingButtons>
        </FixedBottomArea>
      </Grid>
    );
  }

  setMeasurementPlanAttribute(
    attributeName: keyof MeasurementPlan,
    attributeValue: any,
  ) {
    const measurementPlan = {
      ...this.props.measurementPlan,
    };
    set(measurementPlan, attributeName, attributeValue);

    this.props.onUpdateMeasurementPlan(measurementPlan);
  }

  handleCategorizationSelect(categoryId: string) {
    const selectedCategorization =
      categoryId == ""
        ? null
        : this.props?.availableMeasurementCategorizations.find(
            (categorization) => categorization.id == categoryId,
          );

    const anAssignedCategory = find(
      this.props.measurementPlan?.measurement_type
        ?.measurement_value_definitions,
      (mvd) => !isNil(mvd.measurement_category_id),
    );

    if (
      (!isNil(
        this.props.measurementPlan?.measurement_type
          ?.measurement_categorization,
      ) && // category was assigned prior
        !isNil(anAssignedCategory) && // at least one category has been assigned
        selectedCategorization?.id !=
          this.props.measurementPlan?.measurement_type
            ?.measurement_categorization?.id) ||
      (isNil(selectedCategorization) && !isNil(anAssignedCategory))
    ) {
      // no category was selected and categrories are assigned)
      // categorization change !!! Will remove all categories
      void dialog
        .fire({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: I18n.t("frontend.no"),
          confirmButtonText: I18n.t("frontend.yes"),
          title: I18n.t(
            "frontend.measurement_plan_form.category_select_popup.popup_title",
          ),
          html: I18n.t(
            "frontend.measurement_plan_form.category_select_popup.confirm_text",
          ),
        })
        .then((result) => {
          if (!result.isConfirmed) {
            return;
          }
          this.props.onCategorizationSelect(selectedCategorization);
        });
    } else {
      this.props.onCategorizationSelect(selectedCategorization);
    }
  }
}

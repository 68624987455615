import { Avatar, Box, Grid, Typography } from "@mui/material";

import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { UserJSONObject } from "../../json_api/user";

import { applyParamsToBaseUrl, IDType } from "../../utils/urls/url_utils";
import { ItemSearchAutocomplete } from "../common/item_autocomplete";

export interface UsersAutocompleteProps {
  maxItems: number;
  user?: UserJSONObject;
  users?: UserJSONObject[];

  organizationId?: IDType;
  label?: string;
  inputId?: string;
  disabled?: boolean;

  variant?: "standard" | "filled" | "outlined";
  loadSearchResults?: boolean;
  width: number | string;
  /** Base url for retrieving assets. Exected to accept 'search' parameter
   *
   *
   * @type {string}
   * @memberof User
   */
  usersBaseUrl?: string;

  onSelect?: (asset: UserJSONObject) => void;
}

export class UsersAutocomplete extends React.Component<UsersAutocompleteProps> {
  static defaultProps: Partial<UsersAutocompleteProps> = {
    width: 300,

    loadSearchResults: true,
    maxItems: 5,
    disabled: false,
  };

  constructor(props: UsersAutocompleteProps) {
    super(props);
  }
  render(): React.ReactNode {
    return (
      <ItemSearchAutocomplete<UserJSONObject>
        selectedItem={defaultTo(this.props.user, null)}
        items={this.props.users}
        maxItems={this.props.maxItems}
        itemType="users"
        disabled={this.props.disabled}
        label={this.props.label}
        variant={this.props.variant}
        inputId={this.props.inputId}
        width={this.props.width}
        itemIcon={(user) => {
          if (isNil(user)) return null;

          return <Avatar src={user.avatar_thumb} />;
        }}
        optionLabel={(user: UserJSONObject) => {
          if (isNil(user)) return null;
          return `${user.first_name} ${user.last_name} | ID #${user.id}`;
        }}
        onSelect={(user) => {
          this.props.onSelect(user);
        }}
        urlForRequest={(searchTerm) => this.generateRequestUrl(searchTerm)}
        renderOption={(liProps, user) => {
          return (
            <Box component="li" {...liProps}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs="auto">
                  <Avatar src={user.avatar_thumb} />
                </Grid>
                <Grid item xs>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textPrimary">
                        {user.first_name} {user.last_name} ({user.id})
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {defaultTo(user.email, "")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        }}
      />
    );
  }

  generateRequestUrl(searchTerm: string) {
    return applyParamsToBaseUrl(
      this.props.usersBaseUrl,
      1,
      this.props.maxItems,
      null,
      [["search", searchTerm]],
    );
  }
}

import AssetEventsWidget from "./asset_events_widget";
import AssetListWidget from "./asset_list_widget";
import { AssetMaintenanceTimelineWidget } from "./asset_maintenance_timeline_widget";
import AssetMapWidget from "./asset_map_widget";

import BarChartWidget from "./bar_chart_widget";
import CandleStickChartWidget from "./candle_stick_chart_widget";
import CommentWidget from "./comment_widget";
import LevelIndicatorWidget from "./level_indicator_widget";
import ModelViewerWidget from "./model_viewer_widget";
import OrganizationsWidget from "./organizations_widget";
import PercentageWidget from "./percentage_widget";
import SensorValueWidget from "./sensor_value_widget";
import StateWidget from "./state_widget";
import ValueDifferenceWidget from "./value_difference_widget";
import AssetInfoWidget from "./asset_info_widget";
import AssetMaintenanceInfoWidget from "./asset_maintenance_info_widget";
import AssetPartsWidget from "./asset_parts_widget";
import ErrorWidget from "./error_widget";
import ImageWidget from "./image_widget";
import KpiWidget from "./kpi_widget";
import LineDiagramWidget from "./line_diagram_widget";
import LinkWidget from "./link_widget";
import OffsetGaugeWidgetWidget from "./offset_gauge_widget";
import SensorAggregationWidget from "./sensor_aggregation_widget";
import SensorGroupWidget from "./sensor_group_widget";
import SensorValueTableWidget from "./sensor_value_table_widget";
import SvgAnimationWidget from "./svg_animation_widget";
import WeatherWidget from "./weather_widget";
import Widget from "./widget";
import { WidgetConfigSerialized } from "./widget.types";
import WidgetGroupWidget from "./widget_group";

export const WIDGET_CLASSES: Array<typeof Widget<WidgetConfigSerialized>> = [
  LineDiagramWidget,
  SensorValueWidget,
  ValueDifferenceWidget,
  LevelIndicatorWidget,
  PercentageWidget,
  AssetEventsWidget,
  AssetListWidget,
  AssetPartsWidget,
  AssetMaintenanceInfoWidget,
  AssetMaintenanceTimelineWidget,
  AssetMapWidget,
  CommentWidget,
  KpiWidget,
  ModelViewerWidget,
  AssetInfoWidget,
  BarChartWidget,
  CandleStickChartWidget,
  StateWidget,
  SvgAnimationWidget,
  OrganizationsWidget,
  OffsetGaugeWidgetWidget,
  WeatherWidget,
  ImageWidget,
  SensorValueTableWidget,
  SensorAggregationWidget,
  SensorGroupWidget,
  ErrorWidget,
  LinkWidget,
  WidgetGroupWidget,
];

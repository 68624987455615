/// <reference types="../../../definitions/index" />;
import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { MeasurementValueHeaderProps } from "./measurement_value_header";

export const MeasurementDistributionValueHeader: React.FunctionComponent<
  MeasurementValueHeaderProps
> = (props: MeasurementValueHeaderProps) => {
  if (
    props.measurementType?.type !==
    "MeasurementTypes::DistributionMeasurementType"
  )
    return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body2">
          {I18n.t("activerecord.attributes.measurement_value_definition.title")}
        </Typography>
      </Grid>
      <Grid item xs={2} xl={1}>
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.value")}
        </Typography>
      </Grid>
      <Grid item xs={2} xl={1} className="text-center">
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.percent")}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.category")}
        </Typography>
      </Grid>
      <Grid item xs={3} xl={5}>
        <Typography variant="body2">
          {I18n.t("activemodel.attributes.measurement_value.note")}
        </Typography>
      </Grid>
    </Grid>
  );
};

import { Theme as MuiTheme } from "@rjsf/mui";
//import { TextWidget } from "./text_widget";

import { ThemeProps } from "@rjsf/core";

/**
 * Change styling of json form by extending material UI theme
 */
export const Theme: ThemeProps<any> = {
  ...MuiTheme,

  widgets: {
    ...MuiTheme.widgets,
  },
} as ThemeProps;

import {
  Delete,
  Edit,
  Visibility,
  Alarm,
  List,
  Home,
} from "@mui/icons-material";
import { Chip, Link, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { EventPatternJSONObject } from "../../json_api/event_pattern";
import { eventPatternIntervalDescription } from "../../models/event_pattern";
import {
  asset_event_pattern_path,
  asset_sensor_sensor_event_pattern_path,
  edit_asset_sensor_sensor_event_pattern_path,
} from "../../routes";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { useEventPatternsQuery } from "./event_pattern_data";
import { SialogicDialog } from "../common/sialogic_dialog";
import { formatTimestampDuration } from "../../utils/format_timestamp_duration";

export type EventPatternListItemAction =
  | "show"
  | "edit"
  | "delete"
  | "eventList"
  | "showLastEvent";

export type EventPatternListItemActionFun = (
  item: EventPatternJSONObject,
  mode: EventPatternListItemAction,
) => void;
interface EventPatternListProps {
  sensorId: IDType;
  requestItemAction?: EventPatternListItemActionFun;
}
export const EventPatternList: React.FunctionComponent<
  EventPatternListProps
> = ({ sensorId, requestItemAction }) => {
  const [pageSettings, setPageSettings] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [filter, setFilter] = React.useState({
    sensor_id: sensorId,
  });
  const {
    data: { items: eventPatterns, totalItems, totalPages },
    isLoading,
  } = useEventPatternsQuery({
    variables: {
      page: pageSettings.page,
      pageSize: pageSettings.pageSize,
      filter,
      includes: ["sensor", "asset", "last_event"],
    },
    enabled: !isNil(sensorId),
    placeholderData: { items: [], totalItems: -1, totalPages: -1 },
  });

  const gridColDef = React.useMemo(() => {
    return [
      {
        field: "id",
        type: "number",
        headerName: "ID",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "name",
        type: "string",
        flex: 1,
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.name",
        ),
      },
      {
        field: "pattern_type",
        type: "string",
        flex: 1,
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.pattern_type",
        ),
        valueGetter: (value, row) => {
          switch (row.pattern_type) {
            case "EventPatterns::SensorDataTransmissionEventPattern":
              return I18n.t(
                "activerecord.models.event_patterns/sensor_data_transmission_event_pattern",
                {
                  count: 1,
                },
              );
            case "EventPatterns::SensorEventPattern":
              return I18n.t(
                "activerecord.models.event_patterns/sensor_event_pattern",
                {
                  count: 1,
                },
              );
            default:
              return "---";
          }
        },
        align: "center",
      },

      {
        field: "asset",
        type: "string",
        flex: 1,
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.asset",
        ),
        valueGetter: (value, row) => (row.asset || row.sensor?.asset)?.name,
      },
      {
        field: "sensor",
        flex: 1,
        type: "string",
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.sensor",
        ),
        valueGetter: (value, row) => {
          return row.sensor?.name;
        },
      },
      {
        field: "condition",
        type: "string",
        flex: 1,
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.condition",
        ),
        valueGetter: (value, row) =>
          eventPatternIntervalDescription(row, row.sensor.attribute_key_unit),
      },
      {
        field: "current_execution_state",
        type: "string",
        flex: 1,
        align: "center",
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.current_execution_state",
        ),
        minWidth: 150,
        valueGetter: (value, row) =>
          I18n.t(
            `activerecord.attributes.event_patterns/event_pattern_execution_state.states.${row.current_execution_state || "no_event"}`,
          ),
        renderCell: (params) =>
          params.value && (
            <Chip
              label={params.value}
              size="small"
              color={
                params.row.current_execution_state == "no_event"
                  ? "primary"
                  : "warning"
              }
            />
          ),
      },

      {
        field: "last_event",
        type: "dateTime",
        headerName: I18n.t(
          "activerecord.attributes.event_patterns/event_pattern.last_event",
        ),
        flex: 1,
        valueGetter: (value, row): Date => {
          return row.last_event?.from ? new Date(row.last_event?.from) : null;
        },
        align: "center",
        renderCell: ({ value, row }) =>
          isNil(value) ? (
            "---"
          ) : (
            <Tooltip
              title={I18n.t("frontend.event_patterns.index.show_last_event")}
            >
              <Link
                textAlign="center"
                onClick={(e) => {
                  e.stopPropagation();
                  if (requestItemAction)
                    requestItemAction(row, "showLastEvent");
                }}
              >
                {moment(value).format("L LT")}
              </Link>
            </Tooltip>
          ),
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 200,
        getActions: (params) => {
          if (isNil(requestItemAction)) return [];

          return [
            <GridActionsCellItem
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                if (e.ctrlKey || e.metaKey) {
                  redirectTo(
                    asset_sensor_sensor_event_pattern_path(
                      params.row.asset_id,
                      params.row.sensor_id,
                      params.row.id,
                    ),
                    "_blank",
                  );
                } else {
                  if (requestItemAction) requestItemAction(params.row, "show");
                }
              }}
              onAuxClick={(e: React.MouseEvent) => {
                redirectTo(
                  asset_sensor_sensor_event_pattern_path(
                    params.row.asset_id,
                    params.row.sensor_id,
                    params.row.id,
                  ),
                  "_blank",
                );
              }}
              label={I18n.t("frontend.show")}
              title={I18n.t("frontend.show")}
              icon={<Visibility />}
            />,
            <GridActionsCellItem
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                if (requestItemAction) requestItemAction(params.row, "edit");
              }}
              label={I18n.t("frontend.edit")}
              title={I18n.t("frontend.edit")}
              icon={<Edit />}
              onAuxClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                redirectTo(
                  edit_asset_sensor_sensor_event_pattern_path(
                    params.row.asset_id,
                    params.row.sensor_id,
                    params.row.id,
                  ),
                  "_blank",
                );
              }}
            />,
            <GridActionsCellItem
              showInMenu
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                let url = asset_event_pattern_path(
                  params.row.asset_id,
                  params.row.id,
                );
                if (
                  params.row.pattern_type ==
                    "EventPatterns::SensorEventPattern" ||
                  params.row.pattern_type ==
                    "EventPatterns::SensorDataTransmissionEventPattern"
                ) {
                  url = asset_sensor_sensor_event_pattern_path(
                    params.row.asset_id,
                    params.row.sensor_id,
                    params.row.id,
                  );
                }
                if (e.ctrlKey || e.metaKey) {
                  redirectTo(url, "_blank");
                } else {
                  redirectTo(url);
                }
              }}
              label={I18n.t("frontend.event_patterns.index.pattern_home")}
              title={I18n.t("frontend.event_patterns.index.pattern_home")}
              icon={<Home />}
            />,

            // makes only sense if there is a last event

            <GridActionsCellItem
              hidden={isNil(params.row.last_event)}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                requestItemAction(params.row, "showLastEvent");
              }}
              label={I18n.t(
                "frontend.event_patterns.index.last_event_details",
                { name: params.row.name },
              )}
              icon={<Alarm />}
              showInMenu
            />,
            // makes only sense if there is a last event

            <GridActionsCellItem
              hidden={isNil(params.row.last_event)}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                requestItemAction(params.row, "eventList");
              }}
              label={I18n.t("frontend.event_patterns.index.show_events", {
                name: params.row.name,
              })}
              title={I18n.t("frontend.event_patterns.index.show_events", {
                name: params.row.name,
              })}
              icon={<List />}
            />,
            <GridActionsCellItem
              color="error"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                if (requestItemAction) requestItemAction(params.row, "delete");
              }}
              showInMenu
              label={I18n.t("frontend.delete")}
              icon={<Delete />}
            />,
          ];
        },
      },
    ] as GridColDef<EventPatternJSONObject>[];
  }, [requestItemAction]);

  return (
    <DataGrid<EventPatternJSONObject>
      paginationModel={pageSettings}
      onPaginationModelChange={(model) => setPageSettings(model)}
      rowCount={defaultTo(totalItems, 0)}
      pageSizeOptions={[10, 25, 50]}
      paginationMode="server"
      columns={gridColDef}
      onRowDoubleClick={(row) => {
        if (requestItemAction) requestItemAction(row.row, "show");
      }}
      rows={eventPatterns}
      loading={isLoading}
    />
  );
};

import { GridDensity } from "@mui/x-data-grid";
import * as JSONAPI from "jsonapi-typescript";
import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  OrganizationsWidget as OrganizationsWidgetComponent,
  OrganizationsWidgetDefinition,
} from "../components/widgets/organizations_widget";
import { OrganizationJSONAPIAttributes } from "../json_api/organization";
import { ItemAction } from "../models/item_action";
import { OrganizationsWidgetConfigSerialized } from "./organizations_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the OrganizationsWidget.
 *
 * @class OrganizationsWidget
 * @extends {Widget}
 */
export default class OrganizationsWidget extends Widget {
  pageSize?: number;
  pageNumber?: number;
  parentOrganizationId: number;
  tableHeight?: number;
  tableDensity?: GridDensity;
  organizationsBaseUrl?: string;
  actions?: ItemAction[];
  enableSearch?: boolean;

  config: OrganizationsWidgetConfigSerialized;

  static getDomClassName(): string {
    return "organizations-widget";
  }

  static widgetClassName(): string {
    return "OrganizationsWidget";
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);

    this.config = element.data("config") as OrganizationsWidgetConfigSerialized;
  }
  organizationsFromData(): JSONAPI.CollectionResourceDoc<
    string,
    OrganizationJSONAPIAttributes
  > {
    return this.rootElement.data(
      "organizations",
    ) as JSONAPI.CollectionResourceDoc<string, OrganizationJSONAPIAttributes>;
  }

  protected initComponent(element: JQuery) {
    this.initRoot(element);
    const orgResourceCollection = this.organizationsFromData();

    const props = OrganizationsWidgetDefinition.serializedConfigToProps(
      this.config,
    );
    this.root.render(
      <AppRoot>
        <OrganizationsWidgetComponent
          {...props}
          dashboardSettings={this.dashboardSettings}
        />
      </AppRoot>,
    );
  }

  cleanup(): void {
    super.cleanup();
  }
}

import { each, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { SensorAggregationWidgetConfigSerialized } from "../../widgets/sensor_aggregation_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ValueDisplay } from "../common/value_display";
import { SensorAggregationWidgetProps } from "./sensor_aggregation_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { loadSensorData } from "./utils/load_sensor_data";
import { WidgetBox } from "./widget_box";
import { convertToUnit } from "../../utils/unit_conversion";
import { getTimeString } from "../../utils/time_strings";

export const SensorAggregationWidget: React.FC<
  SensorAggregationWidgetProps
> = ({ encloseInWidgetBox = true, ...props }: SensorAggregationWidgetProps) => {
  const [sensor, setSensor] = useState(null);
  const [value, setValue] = useState<SensorValueType>(props.value);
  const [timestamp, setTimestamp] = useState<Moment | null>(
    isNil(props.timestamp) ? null : moment(props.timestamp),
  );
  const [title, setTitle] = useState(props.title as string);
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);
  const [timeRange, setTimeRange] = useState(props.timeRange);
  const [sensorIds, setSensorIds] = useState(props.sensorIds);

  // Update sensor data when sensor title changes
  useEffect(() => {
    setTitle(props.title as string);
  }, [props.title]);

  // Update sensor data when sensor link url changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  // Update sensor data when sensor ids change
  useEffect(() => {
    if (props.sensorIds !== sensorIds) {
      setSensorIds(props.sensorIds);
    }
  }, [props.sensorIds]);

  // Update sensor data when time range changes
  useEffect(() => {
    if (
      ((!props.timeRange?.start ||
        timeRange?.start?.isSame(props.timeRange?.start)) &&
        !props.timeRange?.end) ||
      timeRange?.end?.isSame(props.timeRange?.end)
    ) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange?.start, props.timeRange?.end]);

  // Load sensor data when time range changes
  useEffect(() => {
    if (sensor?.id != props.sensorIds[0]) {
      // only load new sensors if the sensor id has changed
      void loadSensorData(props.sensorIds[0], timeRange).then(
        (sensorWithValue) => {
          const { sensor, value, status, timestamp } = sensorWithValue || {};
          // set sensor dependent values
          setSensor(sensor);
          setValue(value);
          setTimestamp(moment(timestamp));
        },
      );
    }
  }, [props.sensorIds, timeRange?.start, timeRange?.end]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      if (
        !isNumber(value) ||
        !props.dataUpdateEnabled ||
        sensorIds !== props.sensorIds ||
        (!isNil(timeRange?.start) && time.isBefore(timeRange?.start)) ||
        (!isNil(timeRange?.end) && time.isAfter(timeRange?.end))
      ) {
        return;
      }

      const targetUnit = props.displayUnit || sensor?.display_unit || unit;
      if (!isNil(unit) && !isNil(unit)) {
        value = convertToUnit(props.value, unit, targetUnit);
      }

      setValue(value);
      setTimestamp(time);
    },
    [
      timeRange?.start,
      timeRange?.end,
      props.sensorIds,
      props.displayUnit,
      sensor?.display_unit,
    ],
  );

  // Subscribe to sensor data updates
  useEffect(() => {
    const subscriber = { handleSensorValueUpdate };
    const subscriptions: number[] = [];
    if (WidgetController.getInstance()) {
      if (props.dataUpdateEnabled && timeRange?.contains(moment())) {
        props.sensorIds.forEach((sensorId) => {
          subscriptions.push(
            WidgetController.getInstance().sensorDataChannel.addEventListener(
              subscriber,
              sensorId,
            ),
          );
        });
      }
    }
    return () => {
      each(subscriptions, (sId) =>
        WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
          sId,
        ),
      );
    };
  }, [
    props.dataUpdateEnabled,
    props.sensorIds,
    timeRange?.start,
    timeRange?.end,
  ]);

  const valueDisplay = (
    <ValueDisplay
      value={value}
      unit={props.unit}
      iconName={props.iconName}
      iconSize={props.iconSize}
      precision={props.precision}
      timestamp={getTimeString(props.timeScopeName, timestamp)}
      vertical={props.vertical}
      shadowText={props.shadowText}
    />
  );

  return !encloseInWidgetBox ? (
    valueDisplay
  ) : (
    <WidgetBox
      {...props}
      title={title}
      titleLinkUrl={titleLinkUrl}
      contentLinkUrl={contentLinkUrl}
    >
      {valueDisplay}
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: SensorAggregationWidgetConfigSerialized,
): SensorAggregationWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    dataUpdateEnabled: !config.disable_update,
    value: config?.value,
    sensorIds: config.sensor_ids,
    timestamp: config?.timestamp,
    iconName: config?.icon_name,
    iconSize: config?.icon_size,
    vertical: config?.vertical,
    precision: config?.precision,
    shadowText: config.text_shadow,
  });
}

export const SensorAggregationWidgetDefinition: SialogicWidgetDefinition<
  typeof SensorAggregationWidget,
  typeof serializedConfigToProps
> = {
  Component: SensorAggregationWidget,
  serializedConfigToProps,
};

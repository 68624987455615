import { filter } from "lodash";
import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { getTranslatedProp } from "../../../utils/globalize";
import { Icon } from "../../common/icon";
import { AssetWithMaintenanceJobs, MaintenanceJob, User } from "../data/model";
import {
  InspectionJobListEntry,
  InspectionJobListEntryProps,
} from "./inspection_job_list_entry";
import {
  MaintenanceJobListEntry,
  MaintenanceJobListEntryProps,
} from "./maintenance_job_list_entry";

export function JobListEntry(props: MaintenanceJobListEntryProps) {
  switch (props.maintenanceJob.type) {
    case "InspectionJob":
      return (
        <InspectionJobListEntry {...(props as InspectionJobListEntryProps)} />
      );
    case "MaintenanceJob":
      return <MaintenanceJobListEntry {...props} />;
  }
}

interface MaintenanceJobListProps {
  asset: AssetWithMaintenanceJobs;
  users: User[];
  onUpdateMaintenanceJob: (
    maintenanceIndex: number,
    maintenanceJob: MaintenanceJob,
  ) => void;
  onGuestUserAdded: (name: string, newUser: User) => void;
  onToggle: (expanded: boolean) => void;
}

export class MaintenanceJobList extends React.Component<MaintenanceJobListProps> {
  shouldComponentUpdate(nextProps: MaintenanceJobListProps): boolean {
    return (
      this.props.asset !== nextProps.asset ||
      this.props.users !== nextProps.users
    );
  }

  render(): React.ReactNode {
    return (
      <Accordion expanded={this.props.asset.expanded}>
        <AccordionSummary
          id={`asset-job-list-${this.props.asset.asset.id}`}
          className="collapse-button"
          onClick={() => this.toggle()}
        >
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs>
              <Typography variant="subtitle2">
                {getTranslatedProp(this.props.asset.asset, "name")}
              </Typography>
            </Grid>

            <Grid item xs="auto">
              <small className="mr-3">
                {this.maintenanceGroupCountString()}
              </small>
              <Icon
                icon={this.props.asset.expanded ? "chevron-up" : "chevron-down"}
              />
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          {this.props.asset.maintenance_jobs.map((maintenanceJob, index) => {
            return (
              <JobListEntry
                key={index}
                maintenanceJob={maintenanceJob}
                users={this.props.users}
                onUpdateMaintenanceJob={(maintenanceJob) =>
                  this.props.onUpdateMaintenanceJob(index, maintenanceJob)
                }
                onGuestUserAdded={(name: string, newUser: User) =>
                  this.props.onGuestUserAdded(name, newUser)
                }
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  private maintenanceGroupCountString(): string {
    const total = this.props.asset.maintenance_jobs.length;
    const selected = filter(
      this.props.asset.maintenance_jobs,
      (job) => job.executed === true,
    ).length;

    return I18n.t("frontend.maintenance_form.executed_items", {
      selected_count: selected,
      total_count: total,
    });
  }
  private toggle() {
    this.props.onToggle(!this.props.asset.expanded);
  }
}

import { Comment as CommentIcon, KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Skeleton,
} from "@mui/material";
import { find, findIndex, isNil, map, remove, toInteger } from "lodash";
import * as React from "react";
import {
  CommentCableMessage,
  CommentsChannel,
} from "../../channels/comments_channel";
import { Comment, CommentWithChildren } from "../../models/comment";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { commentUrl, commentsUrl } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { CommentFilter, CommentFilterItems } from "./comment_filter";
import { CommentForm } from "./comment_form";
import { AppContext } from "../common/app_context/app_context_provider";
import { useQueries, useQuery } from "@tanstack/react-query";
import { DateRange } from "moment-range";
import { use } from "chai";

interface CommentThreadProps {
  itemId?: IDType;
  itemType?: string;
  comments?: CommentWithChildren[];
  timeRange?: DateRange;
  initiallyFormOpen?: boolean;
  limit?: number;
  tags?: string[];
  showFilter?: boolean;
  sort?: "asc" | "desc";
}

function buildNewCommentsOnCommentAdded(
  comment: Comment,
  comments: Comment[],
  limit: number,
  sort: "asc" | "desc",
) {
  if (comments) {
    if (isNil(find(comments, (c) => c.id == comment.id))) {
      if (sort == "desc") {
        const newComments = [comment, ...comments];
        if (!isNil(limit) && newComments.length > limit) {
          newComments.splice(newComments.length - 1, 1);
        }
        return newComments;
      } else {
        const newComments = [...comments, comment];
        if (!isNil(limit) && newComments.length > limit) {
          newComments.splice(0, 1);
        }
        return newComments;
      }
    }
  } else {
    return [comment];
  }
}
export const CommentThread: React.FunctionComponent<CommentThreadProps> = ({
  initiallyFormOpen = false,
  showFilter = true,
  ...props
}) => {
  const context = React.useContext(AppContext);
  const [comments, setComments] = React.useState<CommentWithChildren[]>(
    props.comments,
  );

  const [filter, setFilter] = React.useState<CommentFilter>({});

  const [tags, setTags] = React.useState(props.tags);

  const [serverError, setServerError] = React.useState<string>(null);
  const [formOpen, setFormOpen] = React.useState(initiallyFormOpen);
  React.useEffect(() => {
    if (!isNil(serverError)) {
      void toasts.error(I18n.t("base.error"), serverError);
    }
  }, [serverError]);

  React.useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);
  const channel = React.useRef<CommentsChannel>(null);

  const handleNewCommentMessage = React.useCallback(
    (comment: Comment) => {
      // get comment html
      void loadDataFromUrl(commentUrl(comment))
        .then((response: Comment) => {
          setComments(
            buildNewCommentsOnCommentAdded(
              response,
              comments,
              props.limit,
              props.sort,
            ),
          );
        })
        .catch((error: Error) => {
          setServerError(error.message);
        });
    },
    [comments, props.sort, props.limit],
  );

  React.useEffect(() => {
    if (props.timeRange) {
      setFilter((oldFilter) => ({
        ...oldFilter,
        from: props.timeRange.start,
        to: props.timeRange.end,
      }));
    }
  }, [props.timeRange]);
  const handleCommentUpdateMessage = React.useCallback(
    (comment: Comment) => {
      // get comment html
      void loadDataFromUrl(commentUrl(comment))
        .then((response: Comment) => {
          const index = findIndex(comments, (c) => c.id == comment.id);
          if (index != -1) {
            const newComments = [...comments];
            newComments[index] = response;
            setComments(newComments);
          }
        })
        .catch((error: Error) => {
          setServerError(error.message);
        });
    },
    [comments, props.sort, props.limit],
  );

  const handleCommentMessage = React.useCallback(
    (comment: CommentCableMessage): void => {
      try {
        switch (comment.action) {
          case "new_comment": {
            handleNewCommentMessage(comment);
            break;
          }
          case "comment_updated": {
            handleCommentUpdateMessage(comment);
            break;
          }
          case "comment_deleted": {
            const id = comment.id;
            setComments(remove(comments, (c) => c.id == id));
          }
        }
      } catch (error) {
        logger.warn(error);
      }
    },
    [props.itemId, props.itemType, props.limit, props.sort, comments],
  );

  React.useEffect(() => {
    const c = new CommentsChannel();
    const subscriber = {
      handleCommentMessage: () => handleCommentMessage,
    };
    c.subscribe(props.itemType, toInteger(props.itemId), subscriber);
    channel.current = c;
    return () => {
      // return cleanup function that closes the socke
      channel.current.unsubscribe(
        props.itemType,
        toInteger(props.itemId),
        subscriber,
      );
    };
  }, []);

  const handleCommentSave = React.useCallback(
    (comment: Comment) => {
      const newComments = [...comments];
      const index = findIndex(newComments, (c) => c.id == comment.id);
      if (index != -1) {
        newComments[index] = comment;
        setComments(newComments);
      }
    },
    [comments],
  );
  const handleCommentDelete = React.useCallback(
    (comment: Comment) => {
      setComments(remove(comments, (c) => c.id == comment.id));
    },
    [comments],
  );

  const commentsQuery = useQuery<CommentWithChildren[]>({
    queryKey: [commentsUrl(props, "json", props.limit, filter, props.sort)],
    queryFn: (context) => {
      return loadDataFromUrl(context.queryKey[0] as string);
    },
  });

  React.useEffect(() => {
    if (commentsQuery.isLoadingError) {
      setServerError(commentsQuery.error.message);
    }
  }, [commentsQuery.isLoadingError]);

  React.useEffect(() => {
    if (commentsQuery.data) {
      setComments(commentsQuery.data);
    }
  }, [commentsQuery.data]);

  return (
    <Grid container spacing={2}>
      {showFilter ? (
        <Grid item xs={12}>
          <CommentFilterItems
            availableTags={tags}
            filter={filter}
            onFilterChanged={(f) => setFilter(f)}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box margin={2}>
          <Accordion defaultExpanded={formOpen}>
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
              <Box mr={2}>
                <CommentIcon fontSize="inherit" />
              </Box>
              {I18n.t("frontend.comments.comment_thread.new_comment")}
            </AccordionSummary>
            <AccordionDetails>
              <CommentForm
                heading={I18n.t("frontend.comments.comment_thread.new_comment")}
                comment={{
                  itemId: props.itemId,
                  itemType: props.itemType,
                  user: context.user,
                }}
                onCommentAdded={(comment) =>
                  setComments(
                    buildNewCommentsOnCommentAdded(
                      comment,
                      comments,
                      props.limit,
                      props.sort,
                    ),
                  )
                }
                onCommentSaved={handleCommentSave}
                readonly={false}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
      {commentsQuery.isLoading ? (
        <Grid item xs={12}>
          <Skeleton height={200} variant="rectangular" />
          <Skeleton height={200} variant="rectangular" />
          <Skeleton height={200} variant="rectangular" />
        </Grid>
      ) : (
        <>
          {map(comments, (c, index) => (
            <Grid item xs={12} key={index}>
              <CommentForm
                comment={c}
                readonly
                onCommentSaved={handleCommentSave}
                onCommentDeleted={handleCommentDelete}
              />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

import { last, snakeCase } from "lodash";
import {
  IDType,
  applyParamsDataToBaseUrl,
  localizedBasePath,
} from "./url_utils";
export function widgetSchemaUrl(widgetClassName: string): string {
  return `/schemas/widgets/${snakeCase(widgetClassName)}`;
}

export function widgetTypesUrl(): string {
  return `${localizedBasePath()}/types/widgets`;
}

export function assetDashboardWidgetUrl(
  assetId: IDType,
  dashboardId: IDType,
  widgetId: IDType,
): string {
  return `${localizedBasePath()}/assets/${assetId}/dashboards/${dashboardId}/widgets/${widgetId}`;
}

export function assetDashboardWidgetsPath(
  assetId: IDType,
  dashboardId: IDType,
): string {
  return `${localizedBasePath()}/assets/${assetId}/dashboards/${dashboardId}/widgets.json`;
}

export function assetTypesDashboardWidgetsPath(
  assetTypeId: IDType,
  dashboardId: IDType,
): string {
  return `${localizedBasePath()}/asset_types/${assetTypeId}/asset_type_dashboards/${dashboardId}/widgets.json`;
}

export function widgetUrl(widgetId: IDType, api = false): string {
  return `${localizedBasePath(api)}/widgets/${widgetId}`;
}

export function widgetsUrl(api = true): string {
  return `${localizedBasePath(api)}/widgets`;
}

export function widgetConfigUrl(widgetId: IDType): string {
  return `${localizedBasePath(true)}/widgets/${widgetId}/config.json`;
}

export function widgetEditUrl(widgetId: IDType, referrer?: string): string {
  let url = new URL(
    `${localizedBasePath()}/widgets/${widgetId}/edit`,
    window.location.origin,
  );
  url = applyParamsDataToBaseUrl(url, [["referrer", referrer]]);
  return url.pathname + url.search;
}

/** Creates a link to backend edit view
 *
 *
 * @export
 * @param {string} widgetClassName Scoped or unscoped widget class name
 * @param {IDType} id Id of widget
 * @return {*}  {string}
 */
export function adminWidgetEditPath(
  widgetClassName: string,
  id: IDType,
): string {
  return `${localizedBasePath()}/admin/Widgets~${last(widgetClassName.split("::"))}/${id}/edit`;
}

export type DashboardsWidgetActions =
  | "move_up"
  | "move_down"
  | "move_left"
  | "move_right"
  | "change_area"
  | "change_size"
  | "update_position"
  | "update_row"
  | "change_height"
  | "config_change";
export function dashboardsWidgetActionUrl(
  dashboardsWidgetId: IDType,
  action: DashboardsWidgetActions,
) {
  if (action == "config_change") return null;

  return `${localizedBasePath(
    true,
  )}/dashboards_widgets/${dashboardsWidgetId}/${action}`;
}

import React from "react";
import PropTypes from "prop-types";

import { DAYS } from "../../../constants/index";
import translateLabel from "../../../utils/translateLabel";
import { MenuItem, TextField, Grid, Radio } from "@mui/material";

const RepeatMonthlyOnThe = ({
  id,
  mode,
  onThe,
  hasMoreModes,
  handleChange,
  translations,
}) => {
  const isActive = mode === "on the";

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item sm={2} display={{ xs: "none" }} />
      <Grid item xs="auto">
        {hasMoreModes && (
          <Radio
            id={id}
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on the"
            value="on the"
            checked={isActive}
            onChange={handleChange}
          />
        )}
      </Grid>

      <Grid item xs="auto">
        <TextField
          label={translateLabel(translations, "repeat.monthly.on_the")}
          select
          id={`${id}-which`}
          name="repeat.monthly.onThe.which"
          aria-label="Repeat monthly on the which"
          value={onThe.which}
          size="small"
          disabled={!isActive}
          onChange={handleChange}
        >
          <MenuItem value="First">
            {translateLabel(translations, "numerals.first")}
          </MenuItem>
          <MenuItem value="Second">
            {translateLabel(translations, "numerals.second")}
          </MenuItem>
          <MenuItem value="Third">
            {translateLabel(translations, "numerals.third")}
          </MenuItem>
          <MenuItem value="Fourth">
            {translateLabel(translations, "numerals.fourth")}
          </MenuItem>
          <MenuItem value="Last">
            {translateLabel(translations, "numerals.last")}
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item xs="auto">
        <TextField
          select
          id={`${id}-day`}
          name="repeat.monthly.onThe.day"
          aria-label="Repeat monthly on the day"
          value={onThe.day}
          size="small"
          disabled={!isActive}
          onChange={handleChange}
        >
          {DAYS.map((day) => (
            <MenuItem key={day} value={day}>
              {translateLabel(translations, `days.${day.toLowerCase()}`)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};
RepeatMonthlyOnThe.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  onThe: PropTypes.shape({
    which: PropTypes.oneOf(["First", "Second", "Third", "Fourth", "Last"])
      .isRequired,
    day: PropTypes.oneOf(DAYS).isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatMonthlyOnThe;

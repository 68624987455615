import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { merge } from "lodash";
import {
  EventPatternAttributes,
  EventPatternType,
} from "../models/event_pattern";
import {
  api_event_pattern_path,
  api_sensor_data_transmission_event_pattern_path,
  api_sensor_event_pattern_path,
} from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import {
  JSONAPISubmitData,
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../utils/jsonapi_form_tools";
import { IDType } from "../utils/urls/url_utils";
import {
  ASSET_JSONAPI_RESOURCE_TYPE,
  AssetJSONAPIAttributesObject,
} from "./asset";
import {
  ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetEventTypeJSONAPIAttributes,
} from "./asset_event_type";
import {
  CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE,
  ContextStateMachineJSONObject,
} from "./context_state_machines";
import {
  ModelErrors,
  jsonApiSingleResourceToFlatObject,
} from "./jsonapi_tools";
import {
  SENSOR_JSONAPI_RESOURCE_TYPE,
  SensorJSONAPIAttributes,
} from "./sensor";
import { STATE_JSONAPI_RESOURCE_TYPE, StateJSONAPIAttributes } from "./state";
import { AssetEventJSONObject } from "./asset_event";

export interface EventPatternJSONObject
  extends EventPatternAttributes,
    JSON.Object {
  sensor?: SensorJSONAPIAttributes;
  asset?: AssetJSONAPIAttributesObject;
  asset_event_type?: AssetEventTypeJSONAPIAttributes;
  last_event?: AssetEventJSONObject;
  state?: StateJSONAPIAttributes;
  context_state_machine?: ContextStateMachineJSONObject;
}

export interface EventPatternJSONAPIAttributes
  extends JSONAPI.AttributesObject<EventPatternJSONObject> {}

export const EVENT_PATTERN_CREATABLE_FIELDS: (keyof EventPatternJSONAPIAttributes)[] =
  [
    "name",
    "min",
    "max",
    "value",
    "comparator",
    "cool_down_time",
    "time_threshold",
    "severity_level",
    "enabled",
    "eval_duration",
    "repeated_notification_after",
    "enabled_repeated_notification",
  ];

export const EVENT_PATTERN_UPDATABLE_FIELDS = EVENT_PATTERN_CREATABLE_FIELDS;

export const EVENT_PATTERN_JSONAPI_RESOURCE_TYPE = "event_patterns";
export const SENSOR_EVENT_PATTERN_JSONAPI_RESOURCE_TYPE =
  "sensor_event_patterns";
export const SENSOR_DATA_TRANSMISSION_EVENT_PATTERN_JSONAPI_RESOURCE_TYPE =
  "sensor_data_transmission_event_patterns";
export type EventPatternErrors = ModelErrors<EventPatternAttributes>;
export type EventPatternJsonApiIncludes =
  | "sensor"
  | "execution_state"
  | "asset"
  | "asset_event_type"
  | "state"
  | "context_state_machine"
  | "last_event";

export function eventPatternJsonApiUrl(
  id: IDType,
  patternType: EventPatternType,
  additionalPathOptions?: Record<string, string>,
): string {
  let url: string;
  const options = merge(
    { id, format: "json", _options: true },
    additionalPathOptions,
  );
  if (patternType == "EventPatterns::SensorEventPattern") {
    url = api_sensor_event_pattern_path(id, options);
  } else if (
    patternType == "EventPatterns::SensorDataTransmissionEventPattern"
  ) {
    url = api_sensor_data_transmission_event_pattern_path(id, options);
  } else {
    url = api_event_pattern_path(id, options);
  }

  return url;
}

export async function loadEventPattern(
  id: IDType,
  patternType?: EventPatternType,
  includes?: EventPatternJsonApiIncludes[],
): Promise<EventPatternJSONAPIAttributes> {
  const url = eventPatternJsonApiUrl(id, patternType, {
    include: includes?.join(","),
  });
  const loadedPattern =
    await loadDataFromUrl<
      JSONAPI.SingleResourceDoc<string, EventPatternJSONAPIAttributes>
    >(url);

  return jsonApiSingleResourceToFlatObject<EventPatternJSONAPIAttributes>(
    loadedPattern,
  );
}

export function buildEventPatternUpdateSubmitData(
  eventPattern: EventPatternJSONObject,
): JSONAPISubmitData<EventPatternJSONObject> {
  const resourceType =
    eventPattern.pattern_type == "EventPatterns::SensorEventPattern"
      ? SENSOR_EVENT_PATTERN_JSONAPI_RESOURCE_TYPE
      : SENSOR_DATA_TRANSMISSION_EVENT_PATTERN_JSONAPI_RESOURCE_TYPE;
  const data = buildJsonApiSubmitData(
    eventPattern,
    resourceType,
    EVENT_PATTERN_UPDATABLE_FIELDS,
  );

  return addRelatedObjectsToEventPattern(eventPattern, data, true);
}

export function buildEventPatternCreateRequestPayload(
  eventPattern: EventPatternJSONObject,
): JSONAPISubmitData<EventPatternJSONObject> {
  const data = buildJsonApiSubmitData(
    eventPattern,
    EVENT_PATTERN_JSONAPI_RESOURCE_TYPE,
    EVENT_PATTERN_CREATABLE_FIELDS,
  );

  return addRelatedObjectsToEventPattern(eventPattern, data, false);
}

function addRelatedObjectsToEventPattern(
  eventPattern: EventPatternJSONObject,
  data: JSONAPISubmitData<EventPatternJSONObject>,
  setNullIfNotDefined: boolean,
) {
  addHasOneRelationToJsonApiSubmitData(
    data.submitData,
    "asset",
    ASSET_JSONAPI_RESOURCE_TYPE,
    eventPattern.asset_id,
    setNullIfNotDefined,
  );
  addHasOneRelationToJsonApiSubmitData(
    data.submitData,
    "asset_event_type",
    ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE,
    eventPattern.asset_event_type_id,
    setNullIfNotDefined,
  );
  addHasOneRelationToJsonApiSubmitData(
    data.submitData,
    "sensor",
    SENSOR_JSONAPI_RESOURCE_TYPE,
    eventPattern.sensor_id,
    setNullIfNotDefined,
  );
  addHasOneRelationToJsonApiSubmitData(
    data.submitData,
    "state",
    STATE_JSONAPI_RESOURCE_TYPE,
    eventPattern.state_id,
    setNullIfNotDefined,
  );
  addHasOneRelationToJsonApiSubmitData(
    data.submitData,
    "context_state_machine",
    CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE,
    eventPattern.context_state_machine_id,
    setNullIfNotDefined,
  );

  return data;
}

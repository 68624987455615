import { Business } from "@mui/icons-material";
import { Avatar, AvatarProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { defaultTo, isEmpty } from "lodash";
import * as React from "react";
import { Organization } from "../../models/organization";
import { useTheme, Theme } from "@mui/material";

type Size = "tiny" | "small" | "medium" | "large" | "line" | "xlarge";

interface StyledAvatarProps extends AvatarProps {
  size?: boolean;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(1),
  aspectRatio: "1",
  maxWidth: "100%",
}));

export interface OrganizationIconProps {
  organization: Organization;
  size?: Size;
  variant?: "circular" | "rounded" | "square";
}

function iconStyle(size: Size, theme: Theme) {
  switch (size) {
    case "line":
      return { height: "1em", width: "1em" };
    case "tiny":
      return { height: 20, width: 20 };
    case "small":
      return { height: 30, width: 30 };
    case "medium":
      return { height: 50, width: 50 };
    case "large":
      return { height: 100, width: 100 };
    case "xlarge":
      return { height: 200, width: 200 };
    default:
      return {};
  }
}

export const OrganizationAvatar: React.FunctionComponent<
  OrganizationIconProps
> = (props) => {
  const theme = useTheme();
  const sx = React.useMemo(
    () => iconStyle(props.size, theme),
    [props.size, theme],
  );
  return (
    <StyledAvatar
      title={props.organization?.name}
      variant={defaultTo(props.variant, "rounded")}
      alt={props.organization?.name}
      src={props.organization?.icon_url}
      sx={sx}
    >
      {isEmpty(props.organization?.icon_url) ? (
        <Business fontSize="inherit" style={{ maxHeight: "1em" }} />
      ) : null}
    </StyledAvatar>
  );
};

export const OrganizationDefaultIcon = Business;

import { Grid, Typography } from "@mui/material";
import { isEmpty, merge } from "lodash";
import * as React from "react";
import { LinkWidgetConfigSerialized } from "../../widgets/link_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { getIconForName } from "../common/icon_for_name";
import { LinkWidgetProps } from "./link_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

const LinkWidget: React.FunctionComponent<LinkWidgetProps> = (props) => {
  // implement display content here ...

  const content = (
    <Grid container alignItems="center" justifyContent="center" spacing={4}>
      <Grid item>
        {props.image?.url ? (
          <img
            title={props.text}
            src={props.image.url}
            style={{
              maxHeight: props.image.max_height,
              maxWidth: props.image.max_width,
            }}
          />
        ) : isEmpty(props.icon) ? null : (
          getIconForName(props.icon?.icon)
        )}
      </Grid>
      <Grid item>
        <Typography variant="h5">{props.text}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <WidgetBox {...props} contentLinkUrl={props.url} titleLinkUrl={props.url}>
      {content}
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: LinkWidgetConfigSerialized,
): LinkWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    text: config.link_text,
    url: config.url,
    linkTarget: config.target,
    icon: config.icon,
    image: config.image,
  });
}

export const LinkWidgetDefinition: SialogicWidgetDefinition = {
  Component: LinkWidget,
  serializedConfigToProps: serializedConfigToProps,
};

import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  List,
  Switch,
  Typography,
} from "@mui/material";

import { isEmpty } from "lodash";
import moment from "moment";
import * as React from "react";
import { rruleToText } from "../../../utils/rotation_description";
import { assetReportPlansReportsPath } from "../../../utils/urls";
import { IBox, IBoxContent } from "../../common/ibox";
import { ReportPlanAttributes } from "../data/models";

import { EmailNotificationIcon } from "../../common/email_notification_icon";

interface ReportPlanItemProperties {
  reportPlan: ReportPlanAttributes;
  onSubscribe?: (plan: ReportPlanAttributes) => void;
  onUnsubscribe?: (plan: ReportPlanAttributes) => void;
}

export const ReportPlanItem: React.FunctionComponent<ReportPlanItemProperties> =
  function (props) {
    const isMounted = React.useRef(false);
    const [subscribed, setSubscribed] = React.useState(
      props.reportPlan.subscribed,
    );

    React.useEffect(() => {
      if (isMounted.current) {
        // avoid initial execuction
        if (subscribed) {
          props.onSubscribe(props.reportPlan);
        } else {
          props.onUnsubscribe(props.reportPlan);
        }
      } else {
        isMounted.current = true;
      }
    }, [subscribed]);

    return (
      <IBox>
        <IBoxContent>
          <Grid container className="align-items-center">
            <Grid item xs={12} sm={3}>
              <a
                href={assetReportPlansReportsPath(
                  props.reportPlan.asset_id,
                  props.reportPlan.id,
                  "html",
                )}
                title={I18n.t("frontend.report_plan_list.show_report_list")}
              >
                <h2 className="auto-hyphen">
                  {props.reportPlan.report_spec_name}
                </h2>
              </a>
            </Grid>
            <Grid item container xs={9} sm={6} lg={5}>
              <Grid item xs={3}>
                <small>
                  {I18n.t("activerecord.models.asset", { count: 1 })}
                </small>
                <br />
                <strong className="auto-hyphen">
                  {props.reportPlan.asset_name}
                </strong>
              </Grid>
              <Grid item xs={3}>
                <small>
                  {I18n.t("activerecord.attributes.report_plan.rrule", {
                    count: 1,
                  })}
                </small>
                <br />
                <strong>{rruleToText(props.reportPlan.rrule)}</strong>
              </Grid>
              <Grid item xs={6}>
                <small>{I18n.t("frontend.report_plan_list.last_report")}</small>
                <br />
                <strong>
                  {isEmpty(props.reportPlan.last_report_created_at)
                    ? I18n.t("frontend.report_plan_list.no_last_report")
                    : moment(props.reportPlan.last_report_created_at).format(
                        "L LT",
                      )}
                </strong>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} lg={2} justifyItems="flex-end">
              <ButtonGroup>
                <Button
                  className="btn-primary auto-hyphen align-items-center"
                  size="small"
                  title={I18n.t("frontend.report_plan_list.show_report_list")}
                  href={assetReportPlansReportsPath(
                    props.reportPlan.asset_id,
                    props.reportPlan.id,
                    "html",
                  )}
                  disabled={isEmpty(props.reportPlan.last_report_created_at)}
                  startIcon={<List />}
                >
                  <span className="d-none d-lg-inline">
                    {I18n.t("frontend.report_plan_list.show_report_list")}
                  </span>
                </Button>
                <Button
                  className="auto-hyphen align-items-center"
                  size="small"
                  href={props.reportPlan.last_report_pdf}
                  target="_blank"
                  title={I18n.t(
                    "frontend.report_plan_list.show_last_report_pdf",
                  )}
                  disabled={isEmpty(props.reportPlan.last_report_pdf)}
                >
                  <span className="d-none d-lg-inline">
                    {I18n.t("frontend.report_plan_list.show_last_report_pdf")}
                  </span>
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={subscribed}
                        onChange={(e) => setSubscribed(e.target.checked)}
                      />
                    }
                    title={
                      subscribed
                        ? I18n.t("frontend.report_plan_list.subscribed")
                        : I18n.t("frontend.report_plan_list.unsubscribed")
                    }
                    label={
                      <Typography>
                        <EmailNotificationIcon enabled={subscribed} />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </IBoxContent>
      </IBox>
    );
  };

import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { OrganizationAttributes } from "../models/organization";
import { ManufacturerJSONObject } from "./manufacturer";

export interface OrganizationJSONObject
  extends OrganizationAttributes,
    JSON.Object {
  manufacturer?: ManufacturerJSONObject;
}

export type OrganizationJSONAPIAttributes =
  JSONAPI.AttributesObject<OrganizationJSONObject>;
export const ORGANIZATION_JSONAPI_RESOURCE_TYPE = "organizations";

import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { isArray, isEmpty } from "lodash";
import { ActiveStorageStoredFile } from "../models/active_storage_stored_file";
import {
  MeasurementAttributes,
  MeasurementValueAttributes,
} from "../models/measurement";
import { MeasurementCategory } from "../models/measurement_category";
import { MeasurementPlanAttributes } from "../models/measurement_plan";
import { MeasurementValueDefinitionAttributes } from "../models/measurement_value_definition";
import { AssetJsonApiAttributes } from "./asset";
import { MeasurementPlanJSONApiAttributes } from "./measurement_plan";
import {
  MeasurementTypeJsonAttributes,
  MeasurementTypeJsonObject,
} from "./measurement_type";

export interface MeasurementJSONApiAttributes extends MeasurementAttributes {
  created_at?: string;
  measurement_plan_title?: string;
  meta_info: JSON.Object;
  attachments?: ActiveStorageStoredFile[];
}
interface MeasurementValueJsonObject
  extends JSON.Object,
    MeasurementValueAttributes {}
interface MeasurementJsonObject
  extends JSON.Object,
    MeasurementJSONApiAttributes {
  measurement_type?: MeasurementTypeJsonObject;
  measurement_values?: MeasurementValueJsonObject[];
}

export type MeasurementJSONAPIAttributesObject =
  JSONAPI.AttributesObject<MeasurementJsonObject>;

interface ExtractedMeasurementData {
  assets?: AssetJsonApiAttributes[];
  measurementPlans: MeasurementPlanJSONApiAttributes[];
  measurementTypes: MeasurementTypeJsonAttributes[];
  measurementValues: MeasurementValueAttributes[];
  measurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurements: MeasurementJSONAPIAttributesObject[];
  measurementCategories: MeasurementCategory[];
}

export function extractIncludedFromJsonApiListResponse(
  resp:
    | JSONAPI.CollectionResourceDoc<string, MeasurementJSONAPIAttributesObject>
    | JSONAPI.SingleResourceDoc<string, MeasurementJSONAPIAttributesObject>,
): ExtractedMeasurementData {
  const data: ExtractedMeasurementData = {
    assets: [],
    measurementPlans: [],
    measurementTypes: [],
    measurementValues: [],
    measurementValueDefinitions: [],
    measurements: [],
    measurementCategories: [],
  };

  resp?.included?.forEach((includedItem) => {
    let arr: Array<
      | MeasurementPlanAttributes
      | MeasurementValueDefinitionAttributes
      | MeasurementValueAttributes
      | MeasurementCategory
      | AssetJsonApiAttributes
    >;
    if (
      includedItem.type === "measurement_plans" ||
      includedItem.type === "measurement_plan"
    ) {
      arr = data.measurementPlans;
    } else if (includedItem.type === "measurement_value_definitions") {
      arr = data.measurementValueDefinitions;
    } else if (
      includedItem.type === "measurement_category" ||
      includedItem.type === "measurement_categories"
    ) {
      arr = data.measurementCategories;
    } else if (includedItem.type === "assets") {
      arr = data.assets;
    } else if (
      includedItem.type === "measurement_type" ||
      includedItem.type === "measurement_types"
    ) {
      arr = data.measurementTypes;
    } else {
      // no known models
      return;
    }
    arr.push({ ...includedItem.attributes, id: includedItem.id });
  });

  if (isEmpty(resp.data)) return data;

  const dataItems = isArray(resp?.data) ? resp.data : [resp.data];

  dataItems.forEach((measurementItem) => {
    if (measurementItem?.attributes?.measurement_values) {
      data.measurementValues.push(
        ...measurementItem.attributes.measurement_values,
      );
    }
    data.measurements.push({
      ...measurementItem.attributes,
      id: parseInt(measurementItem.id),
    });
  });

  return data;
}

import * as React from "react";

import { useState } from "react";
import { ErrorBoundary } from "../../common/error_boundary";

import { isEmpty } from "lodash";
import { Action } from "../../../actions/actions.types";
import { IDType } from "../../../utils/urls/url_utils";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicDialog } from "../../common/sialogic_dialog";
import { SensorDisplay } from "../../sensors/sensor_display";
import { AssetPropertiesWidget } from "../../widgets/asset_properties_widget";
import { DashboardContext } from "../dashboard_context/dashboard_context";
import { DashboardActionContext } from "./dashboard_action_context";
import { DashboardActionContextType } from "./dashboard_action_context.types";
import { DashboardActionContextProviderProps } from "./dashboard_action_context_provider.types";

export const DashboardActionContextProvider: React.FunctionComponent<
  DashboardActionContextProviderProps
> = ({ children }) => {
  const appContext = React.useContext(AppContext);
  const dashboardCtx = React.useContext(DashboardContext);

  const [currentAction, setCurrentAction] = useState<Action | null>(null);

  const [shownSensors, setShownSensors] = useState<IDType[]>([]);

  // memoized context
  const context = React.useMemo<DashboardActionContextType>(() => {
    return {
      performAction(action, context) {
        if (action && context) {
          setCurrentAction(action);
          if (context?.sensorId) setShownSensors(() => [context.sensorId]);
          setDialogOpen(true);
        }
      },
      addSensorToDisplay(sensorId) {
        setShownSensors((prev) => [...prev, sensorId]);
      },
      resetSensorsToDisplay() {
        setShownSensors([]);
      },
    };
  }, [setCurrentAction]);

  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <DashboardActionContext.Provider value={context}>
      {children}
      <SialogicDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setCurrentAction(null);
        }}
      >
        {!dialogOpen ? null : (
          <ErrorBoundary>
            {currentAction.action_type == "asset_properties" && (
              <AssetPropertiesWidget assetId={dashboardCtx.asset?.id} />
            )}
            {currentAction.action_type == "sensor" &&
              !isEmpty(shownSensors) && (
                <SensorDisplay sensorIds={shownSensors} />
              )}
          </ErrorBoundary>
        )}
      </SialogicDialog>
    </DashboardActionContext.Provider>
  );
};

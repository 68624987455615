import { isEmpty, isNil, sumBy } from "lodash";
export interface ValueItem {
  value?: number;
  percent?: number;
}

/**
 * @param measurementValues
 */
/**  Computes and adds 'percent' attribute to measurement value.
 * This is used, e.g., for the display of distribution measurement types.
 *
 * @export
 * @template T Type that provides value and percent properties for calculation and storage
 * @template T2 Type extending ValueItem that should be returned
 * @param {T[]} measurementValues
 * @returns {T2[]}
 */
export function computeValuePercent<T extends T2, T2 extends ValueItem>(
  measurementValues: T[],
): T2[] {
  if (isEmpty(measurementValues)) {
    return [];
  }

  const sum = sumBy(measurementValues, "value");

  return measurementValues.map((measurementValue) => {
    if (isNil(measurementValue.value)) {
      return measurementValue;
    }

    return {
      ...measurementValue,
      percent:
        isNil(sum) || isNil(measurementValue.value)
          ? undefined
          : (measurementValue.value / sum) * 100.0,
    };
  });
}

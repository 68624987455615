import { SensorPointFeatureType } from "./map.types";

export function addLocationDataFeaturePopup(
  feature: SensorPointFeatureType,
  layer: L.Layer,
): void {
  let popup = "";
  if (feature.properties) {
    popup += `<h4>${feature.properties.sensor_name} (${feature.properties.asset_name})</h4>`;

    if (feature.properties.timestamp) {
      popup += `<p>${moment(feature.properties.timestamp).format("L LT")}</p>`;
    }
  }
  if (feature.geometry.type === "Point") {
    popup += `<p>${I18n.t("frontend.lat")}: ${
      feature.geometry.coordinates[1]
    }, ${I18n.t("frontend.lon")}: ${
      feature.geometry.coordinates[0]
    }, ${I18n.t("frontend.height")}: ${feature.geometry.coordinates[2]}</p>`;
  }
  layer.bindPopup(popup);
}

import * as React from "react";

import { isNil } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { TreeItem } from "../../models/tree_item";
import { AppRoot } from "../common/app_root";
import { MaintenancePlanContainer } from "./containers/maintenance_plan_container";
import { loadInitialState, resetState } from "./data/maintenance_plan_actions";
import { AssetGroups, MaintenanceType } from "./data/models";

const maintenancePlanFormRoots: Root[] = [];
/**
 * Initialize react component MaintenancePlanContainer within all elements with data-toggle="maintenance-plan-form".
 * Initial state is loaded fron "data-assets" and "data-maintenance-types".
 * State is expected to be in JSON format.
 */
export function initializeMaintenancePlanContainer(): void {
  $('[data-toggle="maintenance-plan-form"]').each((index, element) => {
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <MaintenancePlanContainer />
      </AppRoot>,
    );
    maintenancePlanFormRoots.push(root);

    const rootAssetId = $(element).data("root-asset-id");
    let assetGroups: AssetGroups = $(element).data("asset-groups");
    assetGroups = isNil(assetGroups) ? [] : assetGroups;
    let maintenanceTypes: MaintenanceType[] =
      $(element).data("maintenance-types");
    maintenanceTypes = isNil(maintenanceTypes) ? [] : maintenanceTypes;

    const assetTree: TreeItem[] = $(element).data(
      "asset-item-tree",
    ) as TreeItem[];

    loadInitialState(rootAssetId, assetGroups, maintenanceTypes, assetTree[0]);
  });
}

/**
 * Remove react component from all elements with data-toggle="maintenance-plan-form"
 */
export function destroyMaintenancePlanContainer(): void {
  maintenancePlanFormRoots.forEach((root) => root.unmount());
  maintenancePlanFormRoots.length = 0;
  resetState();
}

import { MaintenancePlan } from "./models";
import MaintenanceDueDispatcher from "./maintenance_due_dispatcher";
export interface ResetStateAction {
  type: "RESET_STATE";
}

export interface LoadInitialStateAction {
  type: "LOAD_INITIAL_STATE";
  dueMaintenances: MaintenancePlan[];
}

export interface AddDueMaintenanceAction {
  type: "ADD_DUE_MAINTENANCE";
  maintenancePlan: MaintenancePlan;
}

export interface RemoveDueMaintenanceAction {
  type: "REMOVE_DUE_MAINTENANCE";
  maintenancePlan: MaintenancePlan;
}

export type MaintenanceDueAction =
  | ResetStateAction
  | LoadInitialStateAction
  | AddDueMaintenanceAction
  | RemoveDueMaintenanceAction;

/**
 * Resets the state of the store.
 */
export function resetState(): void {
  MaintenanceDueDispatcher.dispatch({
    type: "RESET_STATE",
  });
}

/**
 * Load initial state of store
 * @param dueMaintenances A list of due maintenances
 */
export function loadInitialState(dueMaintenances: MaintenancePlan[]): void {
  MaintenanceDueDispatcher.dispatch({
    type: "LOAD_INITIAL_STATE",
    dueMaintenances,
  });
}

/**
 * Adds a maintenance plan to the list of due maintenances
 * @param maintenancePlan
 */
export function addDueMaintenance(maintenancePlan: MaintenancePlan): void {
  MaintenanceDueDispatcher.dispatch({
    type: "ADD_DUE_MAINTENANCE",
    maintenancePlan,
  });
}

export function removeDueMaintenance(maintenancePlan: MaintenancePlan): void {
  MaintenanceDueDispatcher.dispatch({
    type: "REMOVE_DUE_MAINTENANCE",
    maintenancePlan,
  });
}

import {
  ArrowDownward,
  ArrowDropDown,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  BugReport,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  defaultTo,
  isEmpty,
  isNil,
  toInteger,
  toString,
  upperCase,
} from "lodash";
import * as React from "react";

import { sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import {
  DashboardsWidgetActions,
  adminWidgetEditPath,
  dashboardsWidgetActionUrl,
  widgetEditUrl,
  widgetUrl,
} from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";

import Add from "@mui/icons-material/Add";
import {
  DashboardArea,
  DashboardSettings,
  DashboardWidgetHeight,
  DashboardWidgetSize,
} from "../../widgets/widget.types";
import { AppContext } from "../common/app_context/app_context_provider";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";
import { WidgetActionArgs } from "../dashboard/dashboard_context/dashboard_context.types";
export interface WidgetAdminDropdownProps {
  adminUrl?: string;
  dashboardSettings?: DashboardSettings;
  widgetId?: IDType;
  onSettingsChange?(newSettings: DashboardSettings): void;
  requestDebug?(): void;
}

function getDerivedSettingsForAction(
  oldSettings: DashboardSettings,
  action: DashboardsWidgetActions,
  args: WidgetActionArgs,
): DashboardSettings {
  const dashboardSettings = { ...oldSettings };

  switch (action) {
    case "change_area":
      dashboardSettings.area = args.area;
      break;
    case "move_down":
      dashboardSettings.row = defaultTo(oldSettings.row, 0) + 1;
      break;
    case "move_up":
      dashboardSettings.row = defaultTo(oldSettings.row, 0) - 1;
      break;
    case "update_row":
      dashboardSettings.row = args.row;
      break;
    case "move_left":
      dashboardSettings.position = defaultTo(oldSettings.position, 0) - 1;
      break;
    case "move_right":
      dashboardSettings.position = defaultTo(oldSettings.position, 0) + 1;
      break;
    case "update_position":
      dashboardSettings.position = args.position;
      break;
    case "change_height":
      dashboardSettings.height = args.height;
      break;
    case "change_size":
      dashboardSettings.size = args.size;
      break;
    default:
      return oldSettings;
  }
  return dashboardSettings;
}

async function handleDashboardsWidgetAction(
  id: IDType,
  action: DashboardsWidgetActions,
  args: WidgetActionArgs,
  dashboardSettingsBefore: DashboardSettings,
  onSettingsChange?: (
    settings: DashboardSettings,
    action: DashboardsWidgetActions,
    args: WidgetActionArgs,
  ) => void,
) {
  if (onSettingsChange) {
    onSettingsChange(
      getDerivedSettingsForAction(dashboardSettingsBefore, action, args),
      action,
      args,
    );
  } else {
    // handle request for change
    return sendData(dashboardsWidgetActionUrl(id, action), args, "PATCH")
      .then((resp) => {
        window.location.reload();
      })
      .catch((e: Error) => {
        logger.logError(e);
      });
  }
}

async function deleteWidget(widgetId: IDType): Promise<void> {
  await sendData(widgetUrl(widgetId, true), {}, "DELETE");
}

export const WidgetAdminDropdown: React.FunctionComponent<
  WidgetAdminDropdownProps
> = (props) => {
  if (isEmpty(props.dashboardSettings)) return null;
  const context = React.useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dashboardContext = React.useContext(DashboardContext);

  const dbArea = defaultTo(props.dashboardSettings?.area, "center");
  const [position, setPosition] = React.useState<number>(
    props.dashboardSettings.position,
  );
  const [row, setRow] = React.useState<number>(props.dashboardSettings.row);
  const id = React.useId();
  const buttonId = React.useId();
  return (
    <>
      <IconButton
        id={buttonId}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ArrowDropDown />
      </IconButton>

      <Menu
        id={id}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {context.user?.isAdmin
          ? [
              <MenuItem
                key="editinbackend"
                href={adminWidgetEditPath(
                  props.dashboardSettings.widget_type,
                  props.dashboardSettings.widget_id,
                )}
                component={Link}
              >
                <ListItemText>
                  {I18n.t("frontend.edit_in_backend")}
                </ListItemText>
              </MenuItem>,
              props.requestDebug ? (
                <MenuItem onClick={props.requestDebug} key="debug">
                  <ListItemIcon>
                    <BugReport fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>
                    {I18n.t("frontend.write_debug_info")}
                  </ListItemText>
                </MenuItem>
              ) : null,
            ]
          : null}

        {dashboardContext.onNewDashboardItem ? (
          <MenuItem
            key="newWidget"
            onClick={() => {
              if (dashboardContext.onNewDashboardItem) {
                dashboardContext.onNewDashboardItem();
              }
            }}
          >
            <ListItemIcon>
              <Add fontSize="inherit" />
            </ListItemIcon>
            <ListItemText>{I18n.t("base.new")}</ListItemText>
          </MenuItem>
        ) : null}

        {isNil(props.widgetId)
          ? null
          : [
              dashboardContext?.onEditDashboardItem ? (
                <MenuItem
                  key="editwidget"
                  onClick={() => {
                    handleClose();
                    dashboardContext?.onEditDashboardItem?.(
                      props.dashboardSettings,
                    );
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>{I18n.t("base.edit")}</ListItemText>
                </MenuItem>
              ) : (
                <MenuItem
                  key="editwidget"
                  href={widgetEditUrl(props.widgetId as number)}
                  LinkComponent={Link}
                  component={Link}
                >
                  <ListItemIcon>
                    <Edit fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>{I18n.t("base.edit")}</ListItemText>
                </MenuItem>
              ),
              <MenuItem
                key="deleteWidget"
                onClick={() => {
                  if (confirm("Sure?")) {
                    void deleteWidget(props.widgetId).then(() => {
                      handleClose();
                      window.location.reload();
                    });
                  } else {
                    handleClose();
                  }
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="inherit" />
                </ListItemIcon>
                <ListItemText>{I18n.t("base.delete")}</ListItemText>
              </MenuItem>,
            ]}
        {isNil(props.dashboardSettings?.dashboards_widget_id)
          ? null
          : [
              <Divider key="divRow" />,
              <MenuItem key="prhead">
                <ListItemText>Position & Row</ListItemText>
              </MenuItem>,
              <MenuItem key="posedit">
                <Grid container>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <TextField
                        name="widget_row_select"
                        label="Row"
                        id={`widget_${props.dashboardSettings.dashboards_widget_id}_row_field`}
                        value={toString(row)}
                        onChange={(e) =>
                          setRow(toInteger(e.currentTarget.value))
                        }
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "update_row",
                              { row: row },
                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                            e.stopPropagation();
                          }
                        }}
                        type="number"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonGroup size="small" variant="text">
                        <Button
                          onClick={() => {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "move_up",
                              null,

                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                          }}
                        >
                          <ArrowUpward />
                        </Button>
                        <Button
                          onClick={() => {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "move_down",
                              null,
                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                          }}
                        >
                          <ArrowDownward />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        name="widget_position_select"
                        label="Position"
                        id={`widget_${props.dashboardSettings.dashboards_widget_id}_pos_field`}
                        value={toString(position)}
                        onChange={(e) =>
                          setPosition(toInteger(e.currentTarget.value))
                        }
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "update_position",
                              { position: position },
                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                            e.stopPropagation();
                          }
                        }}
                        type="number"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonGroup variant="text" size="small">
                        <Button
                          size="small"
                          onClick={() => {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "move_left",
                              null,
                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                          }}
                        >
                          <ArrowLeft />
                        </Button>
                        <Button
                          onClick={() => {
                            void handleDashboardsWidgetAction(
                              props.dashboardSettings?.dashboards_widget_id,
                              "move_right",
                              null,
                              props.dashboardSettings,
                              props.onSettingsChange ||
                                dashboardContext?.onUpdateDashboardItem,
                            );
                          }}
                        >
                          <ArrowRight />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>,
              <Divider key="div2" />,
              <MenuItem key="fields2">
                <Grid container spacing={2}>
                  <Grid item xs>
                    <TextField
                      select
                      size="small"
                      fullWidth
                      label="Area"
                      value={toString(dbArea)}
                      onChange={(event) => {
                        void handleDashboardsWidgetAction(
                          props.dashboardSettings?.dashboards_widget_id,
                          "change_area",
                          { area: event.target.value as DashboardArea },
                          props.dashboardSettings,
                          props.onSettingsChange ||
                            dashboardContext?.onUpdateDashboardItem,
                        );
                      }}
                    >
                      {["left_sidebar", "center", "right_sidebar"].map(
                        (area) => (
                          <MenuItem key={area} value={area}>
                            {upperCase(area)}
                          </MenuItem>
                        ),
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      select
                      size="small"
                      fullWidth
                      label="Size"
                      name="widget_size_select"
                      value={defaultTo(props.dashboardSettings?.size, "medium")}
                      onChange={(event) => {
                        void handleDashboardsWidgetAction(
                          props.dashboardSettings?.dashboards_widget_id,
                          "change_size",
                          { size: event.target.value as DashboardWidgetSize },
                          props.dashboardSettings,
                          props.onSettingsChange ||
                            dashboardContext?.onUpdateDashboardItem,
                        );
                      }}
                    >
                      {[
                        "xxsmall",
                        "xsmall",
                        "small",
                        "medium",
                        "large",
                        "xlarge",
                        "xxlarge",
                      ].map((size) => (
                        <MenuItem key={size} value={size}>
                          {upperCase(size)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      select
                      size="small"
                      fullWidth
                      label="Height"
                      name="widget_height_select"
                      value={defaultTo(props.dashboardSettings?.height, "auto")}
                      onChange={(event) => {
                        void handleDashboardsWidgetAction(
                          props.dashboardSettings?.dashboards_widget_id,
                          "change_height",
                          {
                            height: event.target.value as DashboardWidgetHeight,
                          },
                          props.dashboardSettings,
                          props.onSettingsChange ||
                            dashboardContext?.onUpdateDashboardItem,
                        );
                      }}
                    >
                      {[
                        ["auto", "auto"],
                        ["xsmall_height", "xsmall"],
                        ["small_height", "small"],
                        ["medium_height", "medium"],
                        ["large_height", "large"],
                        ["xlarge_height", "xlarge"],
                        ["xxlarge_height", "xxlarge"],
                      ].map((size) => (
                        <MenuItem key={size[0]} value={size[0]}>
                          {upperCase(size[1])}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </MenuItem>,
            ]}
      </Menu>
    </>
  );
};

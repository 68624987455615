export enum SensorTypeValueTypes {
  continous = 1,
  step = 2,
  binary = 3,
  enumerated_value = 4,
}

export interface SensorTypeAttributes {
  id: string | number;
  name?: string;
  measurement_type?: string;
  compatible_units?: string;
  value_type?: number;

  icon?: string;
}

export function getSensorTypeNameTranslation(sensorType: string): string {
  return I18n.t(
    `activerecord.attributes.sensor_type.sensor_types.${sensorType}`,
    {
      defaultValue: sensorType,
    },
  );
}

import { DateRange } from "moment-range";

import { isEmpty, isNil, map, reject, toString, values } from "lodash";
import moment from "moment";
import { getStateColor } from "../../utils/colors";
import { unitDisplayString } from "../../utils/unit_conversion";
import { getFormatedStringForValue } from "../../utils/value_format";
import { ChartData } from "../plotly_time_series_line_diagram_base.types";
import { StateData } from "./chart_data_loader.types";

export interface ChartStatistics {
  name?: string;
  min: number;
  max: number;
  average: number;
  unit: string;
  minMaxDiv: number;
  sum: number;
  count: number;
}

export type ChartStatType = keyof ChartStatistics;

/** Generates a information string for statistics
 *
 *
 * @export
 * @param {('min' | 'max' | 'minMaxDiv' | 'average')} statName
 * @param {ChartStatistics} stats
 * @returns
 */
export function chartStatisticsTextLine(
  statName: ChartStatType,
  stats: ChartStatistics,
  numberFractionDigits = 3,
  unit?: string,
) {
  return `${
    isNil(stats[statName])
      ? "---"
      : getFormatedStringForValue(stats[statName], numberFractionDigits)
  } ${unit ? toString(unitDisplayString(unit)) : ""}`;
}

/** Builds a HTML text
 *
 *
 * @export
 * @param {ChartStatistics[]} statistics
 * @param {(index: number) => string} colorFun
 * @returns {string}
 */
export function buildStatisticsHTML(
  statistics: ChartStatistics[],
  colorFun: (index: number) => string,
): string {
  const statisticsText: string[] = [];

  statistics?.forEach((statistics, index) => {
    if (!isNil(statistics)) {
      statisticsText.push(
        `<div class="col-12 col-lg-6" data-toggle="tootltip" title="${toString(statistics.name)}">
          <i class="fa fa-circle ml-1" style="color: ${colorFun(index)};" ></i>
          <small class="ml-1">
          <strong class="ml-1 mr-1">Min:</strong>${chartStatisticsTextLine(
            "min",
            statistics,
          )}
          <strong class="ml-1 mr-1">Max:</strong>${chartStatisticsTextLine(
            "max",
            statistics,
          )}
          <strong class="ml-1 mr-1">Diff:</strong>${chartStatisticsTextLine(
            "minMaxDiv",
            statistics,
          )}
          <strong class="ml-1 mr-1">\u00D8:</strong>${chartStatisticsTextLine(
            "average",
            statistics,
          )}
          </small>
          </div>`,
      );
    }
  });

  return `<div class="row">${statisticsText.join("\n")}</div>`;
}

/**
 * Computes statistics for given cart data
 *
 * @export
 * @param {DateRange} range Timerange to compute statistics. If null or undefined the whole dataset is used
 * @param {ChartData[]} data Chart Data
 * @returns {ChartStatistics[]} an array of computed statistics. Indices correllate with input indices
 */
export function computeChartStatisticsForTimeData(
  range: DateRange,
  data: ChartData[],
): ChartStatistics[] {
  const computedData = data.map((dataset) => {
    let min = Infinity;
    let max = -Infinity;
    let sum = 0;
    let count = 0;
    (dataset.y as number[]).forEach((value, index) => {
      const timestamp = moment(dataset.x[index] as Date);

      // skip nil values and values outside of selected range
      if (isNil(value) || (!isNil(range) && !range.contains(timestamp))) {
        return;
      }

      min = Math.min(min, value);
      max = Math.max(max, value);
      sum += value;
      ++count;
    });

    if (min === Infinity) {
      min = null;
    }
    if (max === -Infinity) {
      max = null;
    }
    if (sum === 0) {
      sum = null;
    }

    return {
      name: dataset.name,
      min,
      max,
      sum,
      count,
      average: count == 0 || isNaN(sum) ? null : sum / count,
      unit: unitDisplayString(dataset.unit),
      minMaxDiv: min !== null && max !== null ? max - min : null,
    } as ChartStatistics;
  });
  return reject<ChartStatistics>(computedData, isNil);
}

function buildStateLegendHtml(stateData: StateData[]): string {
  if (isEmpty(stateData)) return "";

  return map(
    stateData,
    (priticularStateData) =>
      `<div class="row mx-2 mt-1">
          <div class="col-12">
            <h6>${priticularStateData.state_context.name}</h6>
          </div>
      ${map(
        values(priticularStateData.possible_states),
        (state, index) =>
          `<div class="col">
          <i class="fa fa-square ml-1" style="color: ${getStateColor(
            state,
            index,
          )};" ></i>
          <small>
          ${state.name}
          </small>
      </div>`,
      ).join("")}
      </div>`,
  ).join("");
}

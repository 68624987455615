import * as JSONAPI from "jsonapi-typescript";
import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  AssetListWidget as AssetListWidgetComponent,
  AssetListWidgetDefinition,
} from "../components/widgets/asset_list_widget";
import { WidgetController } from "../controller/widget_controller";
import { AssetJSONAPIAttributesObject } from "../json_api/asset";
import { Asset } from "../models/asset";
import { ItemAction } from "../models/item_action";
import { AssetListWidgetConfigSerialized } from "./asset_list_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the AssetListWidget.
 *
 * @class AssetListWidget
 * @extends {Widget}
 */
export default class AssetListWidget extends Widget {
  static getDomClassName(): string {
    return "asset-list-widget";
  }

  static widgetClassName(): string {
    return "AssetListWidget";
  }

  actions: ItemAction[];

  config: AssetListWidgetConfigSerialized;
  readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    this.config = element.data("config") as AssetListWidgetConfigSerialized;
    this.actions = this.config.actions;
  }

  assetsFromData(): JSONAPI.CollectionResourceDoc<
    string,
    AssetJSONAPIAttributesObject
  > {
    return this.rootElement.data("assets") as JSONAPI.CollectionResourceDoc<
      string,
      AssetJSONAPIAttributesObject
    >;
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = AssetListWidgetDefinition.serializedConfigToProps(
      this.config,
    );
    props.onAssetSelect = (assetId, asset) =>
      this.handleAssetSelection(assetId, asset);

    this.root.render(
      <AppRoot>
        <AssetListWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  handleAssetSelection(assetId: number, asset: Asset) {
    void WidgetController.getInstance().handleItemSelection({
      itemType: "Asset",
      itemId: assetId,
      source: this,
      item: asset,
    });
  }

  cleanup(): void {
    super.cleanup();
  }
}

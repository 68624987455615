import { isNil, merge, isNumber } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { getTimeString } from "../../utils/time_strings";
import { useCallback, useEffect, useState } from "react";
import { KpiWidgetConfigSerialized } from "../../widgets/kpi_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ValueDisplay } from "../common/value_display";
import { KpiWidgetProps } from "./kpi_widget.types";
import { WidgetBox } from "./widget_box";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { loadSensorData } from "./utils/load_sensor_data";

export const KpiWidget: React.FunctionComponent<KpiWidgetProps> = ({
  ...props
}: KpiWidgetProps) => {
  const [value, setValue] = useState(props.value);
  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [sensor, setSensor] = useState(
    props.sensors ? props.sensors.a : props.sensors.b,
  );
  const [timeRange, setTimeRange] = useState(props.timeRange);

  // Update sensor data when time range changes
  useEffect(() => {
    if (
      ((!props.timeRange?.start ||
        timeRange?.start?.isSame(props.timeRange?.start)) &&
        !props.timeRange?.end) ||
      timeRange?.end?.isSame(props.timeRange?.end)
    ) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange?.start, props.timeRange?.end]);

  // Load sensor data when time range changes
  useEffect(() => {
    if (sensor?.id != props.sensors?.a?.id && props.sensors?.b?.id) {
      // only load new sensors if the sensor id has changed
      void loadSensorData(props.sensors?.a?.id, timeRange).then(
        (sensorWithValue) => {
          if (sensorWithValue && "sensors" in sensorWithValue) {
            const { sensors, value, status, timestamp } = sensorWithValue;
            // set sensor dependent values
            setSensor(sensors);
            setValue(value);
            setTimestamp(moment(timestamp));
          }
        },
      );
    }
  }, [props.sensors, timeRange?.start, timeRange?.end]);

  // apply value and timestamp changes from props
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
    if (props.timestamp !== timestamp) {
      setTimestamp(props.timestamp);
    }
  }, [props.value, props.timestamp]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      if (
        !isNumber(value) ||
        (sensorId !== props.sensors?.a?.id &&
          sensorId !== props.sensors?.b?.id) ||
        (!isNil(timeRange) && !timeRange.contains(time))
      ) {
        return;
      }

      setValue(value);
      setTimestamp(time);
    },
    [timeRange?.start, timeRange?.end, props.sensors?.a?.id],
  );

  // no data update for now as we are not able to compute the kpi value in frontend
  // // Subscribe to sensor data updates
  // useEffect(() => {
  //   const subscriber = { handleSensorValueUpdate };
  //   if (WidgetController.getInstance()) {
  //     if (props.updateEnabled && timeRange?.contains(moment())) {
  //       WidgetController.getInstance().sensorDataChannel.subscribe(
  //         props.sensors.a?.id,
  //       );
  //       WidgetController.getInstance().sensorDataChannel.subscribe(
  //         props.sensors.b?.id,
  //       );
  //       WidgetController.getInstance().sensorDataChannel.addEventListener(
  //         subscriber,
  //         props.sensors.a?.id,
  //       );
  //       WidgetController.getInstance().sensorDataChannel.removeEventListener(
  //         subscriber,
  //         props.sensors.a?.id,
  //       );
  //       WidgetController.getInstance().sensorDataChannel.removeEventListener(
  //         subscriber,
  //         props.sensors.b?.id,
  //       );
  //     }
  //   }
  //   return () => {
  //     WidgetController.getInstance().sensorDataChannel.removeEventListener(
  //       subscriber,
  //       props.sensors.a?.id,
  //     );
  //     WidgetController.getInstance().sensorDataChannel.removeEventListener(
  //       subscriber,
  //       props.sensors.b?.id,
  //     );
  //   };
  // }, [
  //   props.updateEnabled,
  //   props.sensors?.a?.id,
  //   props.sensors?.b?.id,
  //   timeRange?.start,
  //   timeRange?.end,
  // ]);

  const valueDisplay = (
    <ValueDisplay
      value={value}
      unit={props.units?.display_unit || props.units?.unit}
      iconName={props.icon?.name}
      iconSize={props.icon?.size}
      precision={props.precision}
      timestamp={getTimeString(null, timestamp)}
      vertical={props.vertical}
    />
  );

  return (
    <>
      {!props.encloseInIBox ? (
        valueDisplay
      ) : (
        <WidgetBox
          {...props}
          title={props.title}
          titleLinkUrl={props.titleLinkUrl}
          contentLinkUrl={props.contentLinkUrl}
        >
          {valueDisplay}
        </WidgetBox>
      )}
    </>
  );
};

function serializedConfigToProps(
  config: KpiWidgetConfigSerialized,
): KpiWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    sensors: config.sensors,
    units: config.units,
    value: config.value,
    icon: config.icon,
    timestamp: moment(config.timestamp),
    vertical: config.vertical,
    precision: config.precision,
  } as KpiWidgetProps);
}

export const KpiWidgetDefinition: SialogicWidgetDefinition<
  typeof KpiWidget,
  typeof serializedConfigToProps
> = {
  Component: KpiWidget,
  serializedConfigToProps: serializedConfigToProps,
};

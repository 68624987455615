import { createQuery } from "react-query-kit";
import {
  ASSET_EVENT_JSONAPI_RESOURCE_TYPE,
  AssetEventJsonApiFilter,
  AssetEventJsonApiInclude,
  AssetEventJSONObject,
  DEFAULT_ASSET_EVENT_INCLUDES,
} from "../../json_api/asset_event";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";

import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { merge } from "lodash";
import { api_asset_event_path, api_asset_events_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { ExtensiblePageSettings } from "../common/page_size";

interface LoadAssetEventsQueryParams {
  filter: AssetEventJsonApiFilter;
  includes?: AssetEventJsonApiInclude[];
  pageOptions: ExtensiblePageSettings;
  sort?: string;
}

type LoadAssetEventsQueryResponse = LoadItemsResult<AssetEventJSONObject>;

function requestOptionsForFilter(
  filter: AssetEventJsonApiFilter,
  pageSettings: ExtensiblePageSettings,
  sort = "-from",
  includes: AssetEventJsonApiInclude[] = DEFAULT_ASSET_EVENT_INCLUDES,
) {
  const params = jsonApiFilterParamsArgumentsFromFilterObject(filter);
  const options: Record<string, string | number> = {};
  options["include"] = includes.join(",");
  options["page[size]"] = pageSettings.size;
  options["page[number]"] = pageSettings.number;
  options["sort"] = sort || "-from";

  return merge(options, params);
}

export const useLoadAssetEventsQuery = createQuery<
  LoadAssetEventsQueryResponse,
  LoadAssetEventsQueryParams
>({
  queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ filter, pageOptions, sort, includes }, { signal }) => {
    const requestOptions = requestOptionsForFilter(
      filter,

      pageOptions,
      sort || "-from",
      includes || DEFAULT_ASSET_EVENT_INCLUDES,
    );

    const responseDoc = await loadDataFromUrl<
      CollectionResourceDoc<string, AssetEventJSONObject>
    >(
      api_asset_events_path({
        ...requestOptions,
        _options: true,
        format: "json",
      }),
      "json",
      signal,
    );

    return loadItemResultForJsonApiCollectionResourceDoc(responseDoc);
    // Load asset events here
  },
});

export const useLoadAssetEventQuery = createQuery<
  AssetEventJSONObject,
  { id: IDType; includes?: AssetEventJsonApiInclude[] }
>({
  queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],

  fetcher: async ({ id, includes = DEFAULT_ASSET_EVENT_INCLUDES }) => {
    const data = await loadDataFromUrl<
      SingleResourceDoc<string, AssetEventJSONObject>
    >(
      api_asset_event_path(id, {
        _options: true,
        include: (includes || DEFAULT_ASSET_EVENT_INCLUDES).join(","),
      }),
    );
    return jsonApiSingleResourceToFlatObject(data);
  },
});

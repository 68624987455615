import * as React from "react";

import JQuery from "jquery";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { ReportPlanJSONAPIAttributes } from "../../json_api/report_plan";
import { AppRoot } from "../common/app_root";
import { ReportPlanAttributes } from "./data/models";
import { ReportPlanList } from "./views/report_plan_list";

const reportPlanListRoots: Root[] = [];
export function initializeReportPlanList(
  itemOrSelector: JQuery | string = '[data-toggle="report-plan-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);
    const reportsJSON = el.data("items") as JSONAPI.CollectionResourceDoc<
      string,
      ReportPlanJSONAPIAttributes
    >;
    let reportPlans: ReportPlanAttributes[] = undefined;
    if (!isEmpty(reportsJSON.data)) {
      reportPlans = reportsJSON.data.map((report, index) => {
        return { ...report.attributes, id: report.id };
      });
    }

    const reportPlanUrl = el.data("items-url") as string;
    let currentPage = parseInt(el.data("current-page")) || 1;
    currentPage = isNaN(currentPage) ? 1 : currentPage;
    const totalPages = parseInt(el.data("total-pages"));
    const pageSize = parseInt(el.data("page-size"));
    const root = createRoot(element);
    reportPlanListRoots.push(root);
    root.render(
      <AppRoot>
        <ReportPlanList
          reportPlans={reportPlans}
          reportPlansUrl={reportPlanUrl}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </AppRoot>,
    );
  });
}

/**
 *  Destroy Report Plan List in given container
 */
export function destroyReportPlanList(): void {
  reportPlanListRoots.forEach((root) => {
    root.unmount();
  });
  reportPlanListRoots.length = 0;
}

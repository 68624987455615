import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { SensorTypeAttributes } from "../models/sensor_type";

export const SENSOR_TYPE_JSONAPI_RESOURCE_TYPE = "sensor_types";
export interface SensorTypeJSONObject
  extends SensorTypeAttributes,
    JSON.Object {}

export type SensorTypeJSONAPIAttributes =
  JSONAPI.AttributesObject<SensorTypeJSONObject>;

import { isNil } from "lodash";
import * as React from "react";

import { IconButton } from "./icon_button";

export interface MiniPuttonProps {
  onOk?: () => void;
  onCancel?: () => void;
  okTitle?: string;
  okIcon?: string;
  cancelTitle?: string;
  cancelIcon?: string;
  className?: string;
}

export class MiniButtons extends React.Component<MiniPuttonProps> {
  static defaultProps: MiniPuttonProps = {
    className: "",
    okTitle: I18n.t("frontend.submit"),
    cancelTitle: I18n.t("frontend.cancel"),
    okIcon: "check",
    cancelIcon: "times",
  };

  render(): React.ReactNode {
    return (
      <div className="mini-btns">
        {isNil(this.props.onOk) ? null : (
          <IconButton
            icon={this.props.okIcon}
            className={`text-success ${this.props.className}`}
            title={this.props.okTitle}
            onClick={this.props.onOk}
          />
        )}
        {isNil(this.props.onCancel) ? null : (
          <IconButton
            icon={this.props.cancelIcon}
            className={`ml-1 text-danger ${this.props.className}`}
            title={this.props.cancelTitle}
            onClick={this.props.onCancel}
          />
        )}
      </div>
    );
  }
}

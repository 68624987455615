/// <reference types="../../../definitions/index" />;
import { Avatar, ListItemAvatar, ListItemText, MenuItem } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { redirectTo } from "../../../utils/redirection";
import { Icon } from "../../common/icon";
import { IDType } from "../../../utils/urls/url_utils";

interface MaintenanceListEntryProps {
  /**
   * Name of the asset
   */
  assetName?: string;
  assetID?: IDType;
  rootAssetName?: string;
  rootAssetId?: IDType;
  /**
   * Name of the maintenance
   */
  name?: string;
  /**
   * Icon for the maintenance
   */
  icon?: string;
  /**
   * Url to the maintenance page
   */
  url?: string;
  /**
   * Maintenance due in
   */
  dueAt?: string;
}

export function MaintenanceListEntry(props: MaintenanceListEntryProps) {
  return (
    <MenuItem
      onClick={(event) => handleClick(props.url, event)}
      title={`${props.assetName}: ${props.name} - ${`${I18n.t("frontend.maintenance_due_dropdown.due_at")} ${props.dueAt}`}`}
    >
      <ListItemAvatar>
        <Avatar>
          <Icon icon={props.icon}></Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <strong className="event-list-entry-heading">{props.name}</strong>
        <br />
        <span className="event-list-entry-description">
          {props.assetName}
          {props.rootAssetName && props.rootAssetId != props.assetID
            ? ` (${props.rootAssetName})`
            : null}
        </span>
        <br />
        <small className="text-muted">
          {I18n.t("frontend.maintenance_due_dropdown.due_at")} {props.dueAt}
        </small>
      </ListItemText>
    </MenuItem>
  );
}

function handleClick(url: string, event: React.MouseEvent<HTMLElement>) {
  if (isEmpty(url)) {
    return;
  }

  event.preventDefault();
  redirectTo(url);
}

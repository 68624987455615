import { MenuItem, TextField } from "@mui/material";
import { isNil, map } from "lodash";
import * as React from "react";

interface JumpToElementDropdownProperties {
  titlesWithIds: [string, string][];
  buttonTitle: string;
}

export const JumpToElementDropdown: React.FunctionComponent<
  JumpToElementDropdownProperties
> = (props: JumpToElementDropdownProperties) => {
  return (
    <TextField
      fullWidth
      value={""}
      size="small"
      select
      variant="outlined"
      label={props.buttonTitle}
      onChange={(event) => {
        const id = event.target.value;
        jumpToId(id);
      }}
    >
      {map(props.titlesWithIds, ([title, targetHtmlId], index) => {
        return (
          <MenuItem key={index} value={targetHtmlId}>
            {title}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

function jumpToId(id: string) {
  const elementOffset = $(`#${id}`).offset();
  if (!isNil(elementOffset)) {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: elementOffset.top - 50,
      },
      500,
    );
  }
}

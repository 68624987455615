import * as JSONAPI from "jsonapi-typescript";
import {
  defaultTo,
  Dictionary,
  each,
  groupBy,
  keyBy,
  mapValues,
  sortBy,
  uniqBy,
} from "lodash";

import { AssetJsonApiAttributes } from "../../../json_api/asset";
import {
  extractIncludedFromJsonApiListResponse,
  MeasurementJSONApiAttributes,
  MeasurementJSONAPIAttributesObject,
} from "../../../json_api/measurement";
import { MeasurementPlanJSONApiAttributes } from "../../../json_api/measurement_plan";
import { MeasurementTypeJsonAttributes } from "../../../json_api/measurement_type";
import { MeasurementValueAttributes } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinitionAttributes } from "../../../models/measurement_value_definition";
import { computeValuePercent } from "../../../utils/calculate_percentage";

interface MeasurementsData {
  assets: AssetJsonApiAttributes[];
  measurementPlan: MeasurementPlanJSONApiAttributes;
  measurementTypeById: Dictionary<MeasurementTypeJsonAttributes>;
  measurementValueByMeasurementId: Dictionary<MeasurementValueAttributes[]>;
  measurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurementValueDefinitionsByMeasurementId: Dictionary<
    MeasurementValueDefinitionAttributes[]
  >;
  measurementCategoryById: Dictionary<MeasurementCategory>;
  measurements: MeasurementJSONApiAttributes[];
}
export function handleJsonApiMeasurements(
  fetchedMeasurements:
    | JSONAPI.CollectionResourceDoc<string, MeasurementJSONAPIAttributesObject>
    | JSONAPI.SingleResourceDoc<string, MeasurementJSONAPIAttributesObject>,
): MeasurementsData {
  const includedData =
    extractIncludedFromJsonApiListResponse(fetchedMeasurements);
  const measurementPlan = includedData.measurementPlans[0];

  let measurementValuesJson = groupBy(
    includedData.measurementValues,
    "measurement_id",
  );

  if (
    measurementPlan?.measurement_type_type === "distribution_measurement_type"
  ) {
    // calculate percentages for distributions
    measurementValuesJson = mapValues(measurementValuesJson, (values) =>
      computeValuePercent<
        MeasurementValueAttributes,
        MeasurementValueAttributes
      >(values),
    );
  }

  const measurementValueDefinitionsMap = keyBy(
    includedData.measurementValueDefinitions,
    "id",
  );
  let measurementValueDefinitionsByMeasurementId: Dictionary<
    MeasurementValueDefinitionAttributes[]
  > = {};

  each(measurementValuesJson, (measurementValues, measurementId) => {
    each(measurementValues, (mv) => {
      const mvd =
        measurementValueDefinitionsMap[mv.measurement_value_definition_id];
      const arr =
        measurementValueDefinitionsByMeasurementId[measurementId] ?? [];
      arr.push(mvd);
      measurementValueDefinitionsByMeasurementId[measurementId] = arr;
    });
  });

  const measurementTypeById = keyBy(
    includedData.measurementTypes,
    (mt) => mt.id,
  );

  measurementValueDefinitionsByMeasurementId = mapValues(
    measurementValueDefinitionsByMeasurementId,
    (mvds) => {
      return sortBy(uniqBy(mvds, "id"), "position");
    },
  );

  // sort values by definition position value
  measurementValuesJson = mapValues(measurementValuesJson, (values) => {
    return sortBy(values, (v: MeasurementValueAttributes) =>
      defaultTo(
        measurementValueDefinitionsMap[v.measurement_value_definition_id]
          ?.position,
        0,
      ),
    );
  });

  const measurementCategoriesMap = keyBy(
    includedData.measurementCategories,
    (item) => item.id,
  );

  return {
    assets: includedData.assets,
    measurementPlan,
    measurementTypeById,
    measurementValueByMeasurementId: measurementValuesJson,
    measurementValueDefinitions: includedData.measurementValueDefinitions,
    measurementValueDefinitionsByMeasurementId:
      measurementValueDefinitionsByMeasurementId,
    measurements: includedData.measurements,
    measurementCategoryById: measurementCategoriesMap,
  };
}

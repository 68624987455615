import { Business } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import {
  compact,
  debounce,
  defaultTo,
  find,
  isEmpty,
  isFunction,
  isNil,
  isString,
  memoize,
  noop,
  toString,
} from "lodash";
import * as React from "react";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiFieldsParamsArray,
} from "../../json_api/jsonapi_tools";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { applyParamsToBaseUrl } from "../../utils/urls/url_utils";
import { SialogicQueryClient } from "./sialogic_query_client";

interface IItemType extends JSONAPI.AttributesObject {
  id?: string | number;
  name?: string;
  icon_url?: string;
}

export interface ItemAutocompleteProps<ItemType extends IItemType> {
  inputId?: string;
  maxItems: number;
  selectedItem?: ItemType;
  items?: ItemType[];
  itemType?: string;
  fallbackIcon?: React.ReactNode;
  loadSearchResults?: boolean;
  width: number | string;
  helperText?: string;

  size?: "small" | "medium";
  disabled?: boolean;
  variant?: "outlined" | "standard" | "filled";
  label?: string;
  /** Base url for retrieving organizations. Exected to accept 'search' parameter
   *
   *
   * @type {string}
   * @memberof ItemAutocompleProps
   */
  baseUrl?: string;
  urlForRequest?: (searchTerm: string) => string;

  onSelect?: (org: ItemType) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ItemType,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  itemIcon?: (option: ItemType) => React.ReactNode;
  optionLabel?: (option: ItemType) => string;
}

export interface ItemAutocompleteState<ItemType extends IItemType> {
  searchTerm?: string;
  lastLookupSearchTerm?: string;
  loading?: boolean;
  items?: ItemType[];
  selectedItem?: ItemType;
  open: boolean;
}

export class ItemSearchAutocomplete<
  ItemType extends IItemType,
> extends React.Component<
  ItemAutocompleteProps<ItemType>,
  ItemAutocompleteState<ItemType>
> {
  static defaultProps: Partial<ItemAutocompleteProps<any>> = {
    onSelect: noop,
    width: 300,
    maxItems: 4,
    baseUrl: null,
    variant: "standard",
    fallbackIcon: <Business />,
    disabled: false,
  };

  constructor(
    props:
      | ItemAutocompleteProps<ItemType>
      | Readonly<ItemAutocompleteProps<ItemType>>,
  ) {
    super(props);
    this.state = {
      items: defaultTo(this.props.items, compact([this.props.selectedItem])),
      selectedItem: defaultTo(this.props.selectedItem, null),
      searchTerm: null,
      lastLookupSearchTerm: null,
      open: false,
    };
  }
  render(): React.ReactNode {
    const debouncedLoad = debounce((searchTerm: string) => {
      if (searchTerm) {
        void this.loadItems(searchTerm);
      }
    }, 1000);
    const label = defaultTo(
      this.props.label,
      I18n.t("frontend.type_to_search"),
    );
    return (
      <Autocomplete<ItemType>
        size={this.props.size}
        open={this.state.open}
        inputValue={
          isNil(this.state.searchTerm)
            ? defaultTo(this.state.selectedItem?.name, "")
            : toString(this.state.searchTerm)
        }
        value={
          // default to null as an unselected value. undefined is not allowed as it marks the component as uncontrolled input
          // typing as non nullable seems to be wrong here as setting the null works best
          defaultTo(this.state.selectedItem, null)
        }
        isOptionEqualToValue={(option, value) => value?.id == option?.id}
        onInputChange={(event, searchTerm) => {
          this.setState({ searchTerm });
          debouncedLoad(searchTerm);
        }}
        onChange={(event, selectedItem, reason, details) => {
          if (!isString(selectedItem)) {
            this.setState({
              selectedItem: selectedItem,
              searchTerm: null,
              open: false,
              loading: false,
            });
            if (!isNil(this.props.onSelect)) {
              this.props.onSelect(selectedItem);
            }
          }
        }}
        filterOptions={(org) => org}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disabled={this.props.disabled}
        loading={this.state.loading}
        options={this.state.items}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        getOptionLabel={(option) => {
          return isNil(this.props.optionLabel)
            ? toString(option?.name)
            : this.props.optionLabel(option);
        }}
        noOptionsText={I18n.t("base.no_results")}
        renderOption={defaultTo(this.props.renderOption, (props, value) => (
          <Box component="li" {...props}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs="auto">
                {this.itemIcon(value)}
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="textPrimary">
                  {value.name}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={true}
            variant={this.props.variant}
            label={label}
            placeholder={label}
            helperText={this.props.helperText}
            InputProps={{
              ...params.InputProps,
              id: this.props.inputId,

              startAdornment: (
                <Box mx={1}>{this.itemIcon(this.state.selectedItem)}</Box>
              ),
            }}
          />
        )}
        style={{ width: this.props.width }}
      />
    );
  }

  itemIcon(item: ItemType): React.ReactNode {
    if (isFunction(this.props.itemIcon)) return this.props.itemIcon(item);

    return (
      <Avatar
        variant="rounded"
        src={item?.icon_url}
        sx={{ width: 24, height: 24 }}
      >
        {isEmpty(item?.icon_url) ? this.props.fallbackIcon : null}
      </Avatar>
    );
  }

  async loadItems(
    searchTerm: string,
    maxItems: number = this.props.maxItems,
  ): Promise<void> {
    try {
      if (
        this.state.lastLookupSearchTerm != searchTerm &&
        searchTerm.length > 2
      ) {
        const includes: string[] = [];
        let path = null;
        if (isNil(this.props.urlForRequest)) {
          if (!isNil(this.props.baseUrl) && !isNil(this.props.itemType)) {
            path = applyParamsToBaseUrl(
              this.props.baseUrl,
              1,
              maxItems,
              includes,
              jsonApiFieldsParamsArray<IItemType>(this.props.itemType, [
                "id",
                "name",
                "icon_url",
              ]),
            );
          }
        } else {
          path = this.props.urlForRequest(searchTerm);
        }

        if (!isNil(path) && !isEmpty(path)) {
          this.setState({
            lastLookupSearchTerm: searchTerm,
            loading: true,
            open: true,
            items: compact([this.state.selectedItem]),
          });

          const items = await SialogicQueryClient.fetchQuery({
            queryKey: ["itemSearch", path],
            queryFn: async () => {
              const jsonApiResponsePromise = memoize((path: string) =>
                loadDataFromUrl<
                  JSONAPI.CollectionResourceDoc<string, ItemType>
                >(path),
              );
              const jsonApiResponse = await jsonApiResponsePromise(path);
              const items =
                jsonApiResourceCollectionToFlatObjects<ItemType>(
                  jsonApiResponse,
                );

              return items;
            },
          });

          if (!isNil(this.state.selectedItem)) {
            // already selected items must be contained in items array. So add it if not already contained
            if (isNil(find(items, (i) => i.id == this.state.selectedItem.id))) {
              items.unshift(this.state.selectedItem);
            }
          }
          this.setState({
            items,
            loading: false,
          });
        }
      }
    } catch (e) {
      this.setState({ loading: false });
      logger.error(e);
    }
  }
}

import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";

import { cloneDeep, defaultTo, get, isEmpty, isNil } from "lodash";
import * as React from "react";
import { getTranslatedProp } from "../../../utils/globalize";
import { getMaintenancePlanStyle } from "../../../utils/maintenance_plan_utils";
import { IBox, IBoxContent } from "../../common/ibox";
import { MaintenancePlanIcon } from "../../common/maintenance/maintenance_plan_icon";
import { MaintenanceJob, User } from "../data/model";
import { MaintenanceStatusField } from "./maintenance_status_field";
import { UserGuestUserAutocomplete } from "./user_guest_user_autocomplete";
import { onMaintenanceUserUpdated } from "./maintenance_job_list_data";

export interface MaintenanceJobListEntryProps {
  maintenanceJob: MaintenanceJob;

  initialStatusValue?: number;
  users: User[];
  onUpdateMaintenanceJob: (maintenanceJob: MaintenanceJob) => void;
  onGuestUserAdded: (name: string, newUser: User) => void;
}

type NameUserAutocompleteValue = User & { acceptSelection?: string };
const filter = createFilterOptions<NameUserAutocompleteValue>();

export class MaintenanceJobListEntry extends React.Component<MaintenanceJobListEntryProps> {
  shouldComponentUpdate(nextProps: MaintenanceJobListEntryProps): boolean {
    return (
      this.props.maintenanceJob !== nextProps.maintenanceJob ||
      this.props.users !== nextProps.users
    );
  }

  render(): React.ReactNode {
    return (
      <form
        name={`maintenance-plan-form-${this.props.maintenanceJob.maintenance_plan_id}`}
      >
        <IBox
          className={`float-top mb-3 maintenance-list-entry${
            this.props.maintenanceJob.hidden ? " d-none" : ""
          } ${getMaintenancePlanStyle(
            this.props.maintenanceJob.maintenance_plan.type,
          )}`}
        >
          <IBoxContent className="py-2">
            <Grid container spacing={2}>
              <Grid item container xs={12} md={7} spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        title={I18n.t("frontend.maintenance_form.executed")}
                        name="executed"
                        size="small"
                        checked={this.props.maintenanceJob.executed}
                        color="primary"
                        onChange={(event) => this.onExecutedUpdated(event)}
                      />
                    }
                    label={
                      <Typography>
                        <MaintenancePlanIcon
                          maintenancePlan={
                            this.props.maintenanceJob.maintenance_plan
                          }
                          size="lg"
                        />

                        {getTranslatedProp(
                          this.props.maintenanceJob.maintenance_plan,
                          "name",
                        )}
                      </Typography>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item xs={12} md={6} className="mt-2">
                  <UserGuestUserAutocomplete
                    user={this.props.maintenanceJob.user_attributes || null}
                    users={this.props.users}
                    disabled={!this.props.maintenanceJob.executed}
                    onUserSelect={(name, selectedUser) => {
                      this.onUserUpdated(name, selectedUser);
                    }}
                    label={I18n.t("frontend.maintenance_form.done_by")}
                    placeholder={I18n.t("frontend.maintenance_form.done_by")}
                    userErrors={
                      get(this.props.maintenanceJob, "errors.user") as string
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={I18n.t(
                      "activerecord.attributes.maintenance_job.description",
                    )}
                    placeholder={I18n.t(
                      "frontend.maintenance_form.enter_maintenance_job_description",
                    )}
                    type="text"
                    name="description"
                    disabled={!this.props.maintenanceJob.executed}
                    value={defaultTo(this.props.maintenanceJob.description, "")}
                    multiline
                    size="small"
                    onChange={(event) => this.onDescriptionChanged(event)}
                    error={
                      !isNil(
                        get(this.props.maintenanceJob, "errors.description"),
                      )
                    }
                    helperText={
                      get(
                        this.props.maintenanceJob,
                        "errors.description",
                      ) as string
                    }
                  />
                </Grid>
                <div className="clearfix"></div>
              </Grid>
              <Grid item xs={12} md={5}>
                <MaintenanceStatusField
                  maintenanceJob={this.props.maintenanceJob}
                />
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </form>
    );
  }

  private onExecutedUpdated(event: React.ChangeEvent<HTMLInputElement>): void {
    event.persist();

    const newMaintenanceJob = cloneDeep(this.props.maintenanceJob);
    newMaintenanceJob.executed = event.target.checked;
    this.props.onUpdateMaintenanceJob(newMaintenanceJob);
  }

  private onDescriptionChanged(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    event.persist();

    const newMaintenanceJob = cloneDeep(this.props.maintenanceJob);
    newMaintenanceJob.description = event.target.value;
    this.props.onUpdateMaintenanceJob(newMaintenanceJob);
  }

  private onUserUpdated(name: string, selectedUser: User): void {
    onMaintenanceUserUpdated(name, selectedUser, this.props);
  }
}

import { Box, Grid, Typography } from "@mui/material";

import { defaultTo, isEmpty, isNil, toNumber, toString } from "lodash";
import * as React from "react";
import {
  AssetJSONAPIAttributesObject,
  AssetJSONObject,
} from "../../json_api/asset";
import { jsonApiFieldsParamsArray } from "../../json_api/jsonapi_tools";
import { Organization } from "../../models/organization";
import { assetsJsonApiSearchPath } from "../../utils/urls";
import {
  IDType,
  ParamType,
  applyParamsToBaseUrl,
} from "../../utils/urls/url_utils";
import { ItemSearchAutocomplete } from "../common/item_autocomplete";
import { OrganizationAvatar } from "../common/organization_icon";

export interface AssetsAutocompleteProps {
  maxItems: number;
  asset?: AssetJSONAPIAttributesObject;
  assets?: AssetJSONAPIAttributesObject[];
  rootsOnly?: boolean;
  assetTreeOnly?: boolean;
  contextAssetId?: IDType;
  label?: string;
  helperText?: string;
  inputId?: string;

  variant?: "standard" | "filled" | "outlined";
  size?: "small" | "medium";
  loadSearchResults?: boolean;
  width: number | string;
  /** Base url for retrieving assets. Exected to accept 'search' parameter
   *
   *
   * @type {string}
   * @memberof AssetsAutocomplete
   */
  assetsBaseUrl?: string;

  onSelect?: (asset: AssetJSONObject) => void;
}

export class AssetsAutocomplete extends React.Component<AssetsAutocompleteProps> {
  static defaultProps: Partial<AssetsAutocompleteProps> = {
    width: 300,
    rootsOnly: true,
    loadSearchResults: true,
    maxItems: 5,
    assetTreeOnly: false,
  };

  constructor(props: AssetsAutocompleteProps) {
    super(props);
  }
  render(): React.ReactNode {
    return (
      <ItemSearchAutocomplete<AssetJSONObject>
        size={this.props.size}
        selectedItem={this.props.asset}
        items={this.props.assets}
        maxItems={this.props.maxItems}
        itemType="assets"
        label={this.props.label}
        helperText={this.props.helperText}
        variant={this.props.variant}
        inputId={this.props.inputId}
        width={this.props.width}
        itemIcon={(asset) => {
          if (isNil(asset)) return null;

          return (
            <OrganizationAvatar
              organization={asset.operator as Organization}
              size="small"
            />
          );
        }}
        optionLabel={(asset: AssetJSONObject) => {
          if (isNil(asset)) return null;
          return `${asset.name} ${
            isEmpty(asset.serial)
              ? ""
              : ` | ${I18n.t("activerecord.attributes.asset.serial")}: ${
                  asset.serial
                }`
          } | ID #${asset.id}`;
        }}
        onSelect={(asset) => {
          this.props.onSelect(asset);
        }}
        urlForRequest={(searchTerm) => this.generateRequestUrl(searchTerm)}
        renderOption={(liProps, asset) => {
          return (
            <Box component="li" {...liProps} key={asset.id}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs="auto">
                  <OrganizationAvatar
                    organization={asset.operator as Organization}
                  />
                </Grid>
                <Grid item container xs>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textPrimary">
                      {asset.name} ({asset.id})
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {defaultTo(asset.asset_type_name, "")}
                      {isEmpty(asset.operator_name)
                        ? ""
                        : ` | ${asset.operator_name}`}
                      {isEmpty(asset.serial)
                        ? null
                        : ` | ${I18n.t(
                            "activerecord.attributes.asset.serial",
                          )}: ${asset.serial})`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        }}
      />
    );
  }

  generateRequestUrl(searchTerm: string) {
    const additionalParams: ParamType[] = [];

    if (this.props.rootsOnly) {
      additionalParams.push(["root_only", "true"]);
    }
    if (
      this.props.assetTreeOnly &&
      !isNil(this.props.contextAssetId) &&
      !isNaN(toNumber(this.props.contextAssetId))
    ) {
      additionalParams.push(["asset_tree_only", "true"]);
      additionalParams.push([
        "context_asset_id",
        toString(this.props.contextAssetId),
      ]);
    }
    return isNil(this.props.assetsBaseUrl)
      ? assetsJsonApiSearchPath(
          searchTerm,
          1,
          this.props.maxItems,
          ["operator", "root"],
          jsonApiFieldsParamsArray<AssetJSONObject>("assets", [
            "id",
            "name",
            "operator_id",
            "operator_name",
            "asset_type_name",
            "operator",
            "serial",
            "subtree_ids",
          ])
            .concat(
              jsonApiFieldsParamsArray<AssetJSONObject>("organizations", [
                "id",
                "name",
                "icon_url",
              ]),
            )
            .concat(additionalParams),
        )
      : applyParamsToBaseUrl(
          this.props.assetsBaseUrl,
          1,
          this.props.maxItems,
          ["operator", "root"],

          jsonApiFieldsParamsArray<AssetJSONObject>("assets", [
            "id",
            "name",
            "operator_id",
            "operator_name",
            "asset_type_name",
            "operator",
            "serial",
          ])
            .concat(
              jsonApiFieldsParamsArray<AssetJSONObject>("organizations", [
                "id",
                "name",
                "icon_url",
              ]),
            )
            .concat(additionalParams),
        );
  }
}

import { Add, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { defaultTo, isEmpty, isNil, merge, times } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { useTheme } from "@mui/material/styles";

import { Moment } from "moment";
import { WidgetController } from "../../controller/widget_controller";
import { AssetJSONObject } from "../../json_api/asset";
import {
  AssetEventJSONObject,
  AssetEventJsonApiFilter,
} from "../../json_api/asset_event";
import { jsonApiFilterParamsArgumentsFromFilterObject } from "../../json_api/jsonapi_tools";
import { EventNotification } from "../../models/event_notification";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { useLoadAssetWithSubtreeQuery } from "../assets/asset_data";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { ExtensiblePageSettings } from "../common/page_size";
import { PageSizeSelect } from "../common/page_size_select";
import { SialogicDialog } from "../common/sialogic_dialog";
import { AssetEventDetails } from "./asset_event_details";
import { AssetEventFilter } from "./asset_event_filter";
import { AssetEventFormDialog } from "./asset_event_form_dialog";
import { AssetEventItem } from "./asset_event_item";
import { useLoadAssetEventsQuery } from "./asset_events_data";
export interface AssetEventListProps {
  assetId?: IDType;
  asset?: AssetJSONObject;
  eventPatternId?: IDType;
  withCard?: boolean;

  displayFilter?: boolean | Record<keyof AssetEventFilter, boolean>;
  onBack?: () => void;
}

export const AssetEventList: FunctionComponent<AssetEventListProps> = ({
  displayFilter = true,
  ...props
}) => {
  const [selectableAssets, setSelectableAssets] =
    React.useState<Array<AssetJSONObject | null>>(null);
  const [pageSettings, setPageSettings] =
    React.useState<ExtensiblePageSettings>({
      number: 1,
      size: 10,
    });

  const [pagingMetaInfo, setPagingMetaInfo] = React.useState({
    totalPages: -1,
    totalItems: -1,
  });

  const [filter, setFilter] = React.useState<AssetEventFilter>(() => {
    return {
      typeId: null,
      assetIds: props.asset?.subtree_ids
        ? props.asset?.subtree_ids
        : props.assetId
          ? [props.assetId]
          : null,
      from: null,
      to: null,
      eventPatternId: props.eventPatternId,
      search: null,
    };
  });

  const [showDetailEvent, setShowDetailEvent] =
    React.useState<AssetEventJSONObject>(null);
  const [loading, setLoading] = React.useState(false);

  const [reloadData, setReloadData] = React.useState(0);

  // an effect to reload the event list when a new event for the asset was created
  React.useEffect(() => {
    const listener = {
      handleNewEvent: (
        event: EventNotification,
        time: Moment,
        assetId: number,
        eventId: number,
      ) => {
        if (pageSettings.number == 1) {
          // if first page is visible reload to make the event appear
          setReloadData(reloadData + 1);
        }
      },
    };
    const subscriptionId =
      WidgetController.getInstance().assetNotificationChannel.addEventListener(
        listener,
        props.asset?.id as number,
      );
    return () => {
      WidgetController.getInstance().assetNotificationChannel.removeEventListenerId(
        subscriptionId,
      );
    };
  }, []);

  const { data: asset, isLoading: assetLoading } = useLoadAssetWithSubtreeQuery(
    {
      variables: { assetId: props.assetId },
      enabled: !isNil(props.assetId),
      initialData: defaultTo(props.asset, undefined),
    },
  );

  React.useEffect(() => {
    if (asset?.subtree) {
      filter.assetIds = asset.subtree_ids;
      // we have to create new object since there are circular dependencies in the result object from json api
      setSelectableAssets(
        asset.subtree.map((a) => ({
          id: a.id,
          root: {
            id: asset.id,
            root_id: asset.id,
            name: asset?.name,
            asset_type_name: asset.asset_type_name,
            asset_type_id: asset.asset_type_id,
          },
          name: a.name,
          asset_type_name: a.asset_type_name,
        })),
      );
    }
  }, [asset]);

  const eventsQuery = useLoadAssetEventsQuery({
    variables: {
      filter: {
        asset: filter.assetIds,
        start_after: filter.from,
        end_before: filter.to,
        severity_level: filter.severity_level,
        event_type: filter.typeId,
        event_pattern: filter.eventPatternId,
        search: filter.search,
      },
      // extract the page options from the page settings, as they contain more info than just the page number and size
      pageOptions: { number: pageSettings.number, size: pageSettings.size },
    },
    // enable load if an asset is selected or if the filter has assetIds
    enabled: isEmpty(props.assetId) || !isEmpty(filter.assetIds),
    placeholderData: { items: null, totalItems: -1, totalPages: -1 },
  });

  React.useEffect(() => {
    const ps = { ...pagingMetaInfo };
    if (!isNil(eventsQuery.data?.totalItems)) {
      ps.totalItems = eventsQuery.data?.totalItems;
    }

    if (!isNil(eventsQuery.data?.totalPages)) {
      ps.totalPages = eventsQuery.data?.totalPages;
    }

    setPagingMetaInfo(ps);
  }, [eventsQuery.data?.totalItems, eventsQuery.data?.totalPages]);

  React.useEffect(() => {
    setLoading(eventsQuery.isLoading || assetLoading);
  }, [eventsQuery.isLoading, assetLoading]);

  const [assetEventFormOpen, setAssetEventFormOpen] = React.useState(false);
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("activerecord.models.asset_event", { count: 2 })}
            subheader={isNil(asset) ? null : asset.name}
            action={
              isNil(props.assetId) ? null : (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setAssetEventFormOpen(true);
                  }}
                  title={I18n.t(
                    "frontend.asset_events.asset_event_list.add_event",
                  )}
                >
                  <Add fontSize="inherit" />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AssetEventFilter
                  filterShowConfig={
                    displayFilter === false
                      ? {
                          assetIds: false,
                          typeId: false,
                          from: false,
                          to: false,
                          severity_level: false,
                          search: false,
                        }
                      : displayFilter == true
                        ? {}
                        : displayFilter
                  }
                  useAssetAutocomplete={isNil(props.assetId)}
                  selectableAssets={selectableAssets}
                  filter={filter}
                  onFilterChange={(f) => setFilter(f)}
                  assetId={asset?.id || props.assetId}
                />
              </Grid>

              {loading ? (
                <>
                  {times(pageSettings.size, (i) => (
                    <Grid item xs={12} key={i}>
                      <Skeleton variant="rectangular" height={60} />
                    </Grid>
                  ))}
                </>
              ) : isEmpty(eventsQuery.data?.items) ? (
                <Grid item xs={12} p={4}>
                  <Typography textAlign="center">
                    {I18n.t("frontend.asset_events.asset_event_list.no_events")}
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} p={4}>
                    <Typography variant="h6">
                      {I18n.t("activerecord.models.asset_event", {
                        count: 2,
                      })}
                    </Typography>
                  </Grid>

                  {eventsQuery.data?.items.map((e, index) => (
                    <AssetEventItem
                      key={index}
                      event={e}
                      onShowDetails={(e) => setShowDetailEvent(e)}
                    />
                  ))}
                </>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={4} mx={2} justifyContent="space-between">
              <Grid item xs={2}>
                <PageSizeSelect
                  pageSize={pageSettings.size}
                  onSelectPageSize={(newPageSize) => {
                    setPageSettings({ ...pageSettings, size: newPageSize });
                  }}
                />
              </Grid>
              <Grid item alignContent="center">
                <Pagination
                  size="small"
                  page={pageSettings.number}
                  count={defaultTo(pagingMetaInfo.totalPages, undefined)}
                  onChange={(p, newPage) => {
                    setPageSettings({ ...pageSettings, number: newPage });
                  }}
                />
              </Grid>
              {isNil(pagingMetaInfo.totalItems) ? null : (
                <Grid item>
                  <Typography variant="caption">
                    {I18n.t("frontend.total")}: {pagingMetaInfo.totalItems}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardActions>
        </Card>
        {isNil(showDetailEvent) ? null : (
          <SialogicDialog
            open={!isNil(showDetailEvent)}
            fullScreen={dialogFullScreen}
            allowFullScreen
            onClose={() => {
              setShowDetailEvent(null);
            }}
            title={I18n.t(
              "frontend.asset_events.asset_event_list.asset_details_heading",
            )}
            buttons={
              <Button
                size="small"
                onClick={() => {
                  setShowDetailEvent(null);
                }}
              >
                {I18n.t("frontend.close")}
              </Button>
            }
          >
            <AssetEventDetails assetEvent={showDetailEvent} />
          </SialogicDialog>
        )}
        <AssetEventFormDialog
          assetId={asset?.id ?? props.assetId}
          asset={asset}
          selectableAssets={selectableAssets}
          open={assetEventFormOpen}
          onClose={() => setAssetEventFormOpen(false)}
          onSave={(event) => {
            setReloadData(reloadData + 1);
            setAssetEventFormOpen(false);
          }}
        />
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              redirectTo("back");
            }}
            submitBtnIcon={<Add />}
            submitBtnColor="primary"
            onSubmit={() => {
              setAssetEventFormOpen(true);
            }}
            saveTitle={I18n.t(
              "frontend.asset_events.asset_event_list.add_event",
            )}
            disableSave={assetEventFormOpen}
          />
        </FixedBottomArea>
      </Grid>
    </Grid>
  );
};

function requestOptionsForFilter(
  filter: AssetEventFilter,
  pageSettings: ExtensiblePageSettings,
) {
  const theJsonApiFilter: AssetEventJsonApiFilter = {
    asset: filter.assetIds,
    event_type: filter.typeId,
    start_after: filter.from,
    end_before: filter.to,
    severity_level: filter.severity_level,
    search: filter.search,
  };
  const params = jsonApiFilterParamsArgumentsFromFilterObject(theJsonApiFilter);
  const options: Record<string, string | number> = {};
  options["include"] = "root_asset,asset,event_type,user,event_pattern";
  options["page[size]"] = pageSettings.size;
  options["page[number]"] = pageSettings.number;
  options["sort"] = "-from";

  return merge(options, params);
}

import {
  compact,
  defaultTo,
  each,
  forEach,
  isEmpty,
  isNil,
  map,
  sortBy,
  toString,
  uniq,
} from "lodash";
import { PlainTimeSeriesLoadResult } from "../../fetchers/time_series_data_fetcher";
import {
  GroupedData,
  GroupedDataHash,
  SensorsByAssetsHash,
} from "./sensor_value_table_widget.types";
import { getValueString } from "../../utils/value_format";
import { unitDisplayString } from "../../utils/unit_conversion";
import { Sensor, SensorValueType } from "../../models/sensor";
import { GridColDef } from "@mui/x-data-grid";
import { IDType } from "../../utils/urls/url_utils";
import { roundDateSeconds } from "../../utils/date";
import { time_series_api_sensor_path } from "../../routes";
import { DateRange } from "moment-range";
import { BinaryChartDataLoadResult } from "../../charting/chart_data/chart_data_loader.types";

export function groupAndMergeDataFromPlainTimeSeries(
  loadedResults: PlainTimeSeriesLoadResult[],
  groupedDataHash: GroupedDataHash = {},
  sensorIdsByAssetId: SensorsByAssetsHash,
  groupByAsset: boolean,
): GroupedDataHash {
  // first group the data by time (and asset)
  //
  // INPUT from API:
  // [ { key_id: attributeKeyId1,
  //     x: [t1, t2, t3, ...],
  //     y: [v1, v2, v3, ...],
  //        ...
  //   }, ... ]
  //
  // RESULT after group and merge:
  // {
  //   "timeGroup1": {
  //     timestamp: t1,
  //     measures: {
  //       "attributeKeyId1": v1,
  //       "attributeKeyId2": v2,
  //       ...
  //     },
  //     id: "timeGroup1"
  //   }, ...
  //  ]
  each(loadedResults, (loadedData) => {
    loadedData?.data.forEach((data) => {
      addOrCreateValueTimeGroup(
        new Date(data.t),
        data.v,
        toString(loadedData.sensor.attribute_key_id),
        groupedDataHash,
        sensorIdsByAssetId,
        groupByAsset,
      );
    });
  });
  return groupedDataHash;
}

/** Returns the display value (string) for a sensor value.
 *
 *
 * @param {number} value
 * @param {Sensor} sensor
 * @return {*}  {string}
 * @memberof SensorValueTableWidget
 */
function displayValue(value: number, sensor: Sensor): string {
  return `${getValueString(
    value,
    defaultTo(sensor?.precision, sensor?.import_precision),
  )} ${unitDisplayString(
    defaultTo(sensor?.display_unit, sensor?.attribute_key_unit),
  )}`;
}

function columnNameForSensor(sensor: Sensor): string {
  return `${sensor.name}`;
}

/** Builds the grid column definitions for the table.
 *
 *
 * @param {Sensor[]} sensors
 * @return {*}  {GridColDef[]}
 * @memberof SensorValueTableWidget
 */
export function sensorValueTableGridColDef(
  sensors: Sensor[],
  assetNameByAssetId: Record<IDType, string>,
  sensorColumnSorting: string[] = null,
  sensorIdsByAssetId: SensorsByAssetsHash,
  groupByAsset: boolean,
): GridColDef[] {
  const assetIds = defaultTo(uniq(map(sensors, "asset_id")), []);
  const sensorsById: { [sensorId: string]: Sensor } = {};

  forEach(assetIds, (assetId: string) => {
    const assetSensors = defaultTo(sensorIdsByAssetId[assetId], {});
    forEach(assetSensors, (value, key) => {
      sensorsById[key] = value;
    });
  });

  // const defaultWidth = 130;
  // const largeWidth = 200;
  const columns: GridColDef<GroupedData>[] = [
    {
      field: "timestamp",
      type: "dateTime",

      headerName: I18n.t(
        "frontend.widgets.sensor_value_table_widget.timestamp",
      ),
      valueGetter: (value, row) => {
        return row.timestamp;
      },
      valueFormatter: (value, row) => moment(value).format("L LTS"),

      flex: 1,
    },
  ];
  // add the asset column if groupByAsset is enabled
  if (groupByAsset) {
    columns.push({
      field: "assetId",
      headerName: I18n.t("activerecord.models.asset.one"),
      flex: 1,
      valueGetter: (value, row) => {
        return defaultTo(assetNameByAssetId[row.assetId], row.assetId);
      },
    });
  }
  let sensorsToUse = sensors;
  if (!isEmpty(sensorColumnSorting)) {
    const criteria = compact(
      map(sensorColumnSorting, (sortCriterion) => {
        if (sortCriterion == "asset") return "asset_id";
        if (sortCriterion == "sensor_type") return "sensor_type_id";
        if (sortCriterion == "context") return "sensor_context";
        if (sortCriterion == "context2") return "sensor_context2";
        if (sortCriterion == "attribute_key") return "key";
        if (sortCriterion == "name") return "name";

        return null;
      }),
    );
    if (!isEmpty(criteria)) sensorsToUse = sortBy(sensors, criteria);
  }
  // add a column for each sensor
  each(sensorsToUse, (sensor) => {
    const key_id = sensor.attribute_key_id;
    columns.push({
      field: `measures.${key_id}`,
      type: "number",

      valueGetter: (value, row) => row.measures[key_id],
      valueFormatter: (value, row) => {
        displayValue(value, sensor);
      },
      headerName: columnNameForSensor(sensor),
      //width: defaultWidth,
      flex: 1,
    } as GridColDef<GroupedData>);
  });

  return columns;
}
/** Returns the asset id for a given attribute key id.
 *
 *
 * @param {(string | number)} attributeKeyId
 * @return {*}  {string}
 * @memberof SensorValueTableWidget
 */
function getAssetIdByAttributeKey(
  attributeKeyId: string | number,
  sensorIdsByAssetId: SensorsByAssetsHash,
): string {
  let foundAssetId: IDType = null;

  each(sensorIdsByAssetId, (sensorById) => {
    each(sensorById, (sensorInfo) => {
      if (
        sensorInfo.asset_id &&
        toString(sensorInfo.attribute_key_id) == toString(attributeKeyId)
      ) {
        foundAssetId = sensorInfo.asset_id;
      }
    });
  });
  return foundAssetId ? toString(foundAssetId) : null;
}

/** Creates a group id for a given time and asset id.
 *
 *
 * @param {Date} time
 * @param {IDType} assetId
 * @param {boolean} groupByAsset
 * @return {*}  {string}
 * @memberof SensorValueTableWidget
 */
function groupString(time: Date, assetId: IDType, groupByAsset: boolean) {
  const timeGroup = moment(time).format("L LTS");

  const groupId = groupByAsset ? `${timeGroup}_${assetId}` : timeGroup;
  return groupId;
}

/** Adds a new value to the groupedDataHash or creates a new group for the time if it does not exist.
 *  If the groupByAsset is enabled, the group id will be a combination of the time and the asset id.
 *
 *
 * @param {Date} time
 * @param {SensorValueType} value
 * @param {IDType} key_id
 * @param {GroupedDataHash} groupedDataHash
 * @memberof SensorValueTableWidget
 */
export function addOrCreateValueTimeGroup(
  time: Date,
  value: SensorValueType,
  key_id: IDType,
  groupedDataHash: GroupedDataHash,
  sensorIdsByAssetId: SensorsByAssetsHash,
  groupByAsset: boolean,
): void {
  const assetId: string = defaultTo(
    getAssetIdByAttributeKey(key_id, sensorIdsByAssetId),
    "",
  );

  // determine the group id, i.e. the row to put the value in
  const groupId = groupString(time, assetId, groupByAsset);

  if (isNil(groupedDataHash[groupId])) {
    groupedDataHash[groupId] = {
      timestamp: roundDateSeconds(time),
      measures: {
        [key_id]: value,
      },
      id: groupId,
      assetId: assetId,
    };
  } else {
    groupedDataHash[groupId].measures = {
      ...groupedDataHash[groupId].measures,
      [key_id]: value,
    };
  }
}

/** Builds the binary data urls for the sensors.
 * The urls are used to fetch the binary data from the API.
 *
 * @export
 * @param {Sensor[]} sensors
 * @param {DateRange} timeRange
 * @param {number} [maxEntries=undefined]
 * @return {*}  {(Array<{ baseUrl: string; dataType: "text" | "number" }>)}
 */
export function sensorValueTableBuildBinaryDataUrls(
  sensors: Sensor[],
  timeRange: DateRange,
  maxEntries: number = undefined,
): Array<{ baseUrl: string; dataType: "text" | "number" }> {
  return sensors.map((s) => ({
    baseUrl: time_series_api_sensor_path(null as number, {
      id: s.id,
      format: "bin",
      // set secret parameter _options to mark this object  as routing options
      _options: true,
      min_time: timeRange.start.toISOString(),
      max_time: timeRange.end.toISOString(),
      limit: maxEntries,
      order: "desc",
    }),
    dataType: s.value_type == "text" ? "text" : "number",
  }));
}

/** Groups the data by time and merges it into a single object.
 *
 *
 * @param {(BinaryChartDataLoadResult<any, string | number>[])} timesAndValuesPerSensor
 * @param {GroupedDataHash} [groupedDataHash={}]
 * @return {*}  {GroupedDataHash}
 * @memberof SensorValueTableWidget
 */
export function groupAndMergeDataFromBinaryData(
  timesAndValuesPerSensor: BinaryChartDataLoadResult<any, string | number>[],
  sensorIdsByAssetId: SensorsByAssetsHash,
  groupByAsset: boolean,
): GroupedDataHash {
  const groupedDataHash: GroupedDataHash = {};
  // first group the data by time (and asset)
  //
  // INPUT from API:
  // [ { key_id: attributeKeyId1,
  //     x: [t1, t2, t3, ...],
  //     y: [v1, v2, v3, ...],
  //        ...
  //   }, ... ]
  //
  // RESULT after group and merge:
  // {
  //   "timeGroup1": {
  //     timestamp: t1,
  //     measures: {
  //       "attributeKeyId1": v1,
  //       "attributeKeyId2": v2,
  //       ...
  //     },
  //     id: "timeGroup1"
  //   }, ...
  //  ]

  each(timesAndValuesPerSensor, (loadedData) => {
    const timesAndValues = loadedData.data;
    each(timesAndValues.x, (x, index) => {
      addOrCreateValueTimeGroup(
        new Date(x),
        timesAndValues.y[index],
        toString(timesAndValues.key_id),
        groupedDataHash,
        sensorIdsByAssetId,
        groupByAsset,
      );
    });
  });
  return groupedDataHash;
}

import { Grid, Link, Typography } from "@mui/material";
import { flatten, map, noop } from "lodash";
import * as React from "react";
import { TreeItem } from "../../../models/tree_item";
import { AppRoot } from "../../common/app_root";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { MaintenanceButtonToolbar } from "../../common/maintenance/maintenance_button_toolbar";
import { AssetWithIndex as AssetWithIdentifier } from "../../common/select_asset_modal";
import { AssetIdentifier } from "../data/maintenance_plan_actions";
import { AssetGroups, MaintenancePlan, MaintenanceType } from "../data/models";
import { MaintenancePlanList } from "./maintenance_plan_list";

interface MaintenancePlanFormProps {
  assetTree: TreeItem;
  assetGroups: AssetGroups;
  maintenanceTypes: MaintenanceType[];
  maintenancePlansToDelete: MaintenancePlan[];
  isProcessing: boolean;
  isOpen?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onAddMaintenancePlan: (assetGroupIndex: number, assetIndex: number) => void;
  onAddInspectionPlan: (assetGroupIndex: number, assetIndex: number) => void;
  onRemoveMaintenancePlan: (
    assetGroupIndex: number,
    assetIndex: number,
    maintenancePlanIndex: number,
  ) => void;
  onUpdateMaintenancePlan: (
    assetGroupIndex: number,
    assetIndex: number,
    maintenancePlanIndex: number,
    maintenancePlan: MaintenancePlan,
  ) => void;
  onChangeMaintenancePlanOrder: (
    assetGroupIndex: number,
    assetIndex: number,
    maintenancePlanIndex: number,
    direction: number,
  ) => void;
  onCopyMaintenancePlan: (
    assetIndices: AssetIdentifier[],
    maintenancePlan: MaintenancePlan,
  ) => void;
  onMoveMaintenancePlan: (
    assetGroupIndex: number,
    assetIndex: number,
    maintenancePlanIndex: number,
    destAsset: AssetIdentifier,
  ) => void;

  onToggle: (
    assetGroupIndex: number,
    assetIndex: number,
    open: boolean,
  ) => void;
  onToggleAll?: (open: boolean) => void;
}

interface MaintenancePlanFormState {}

function assetGroupId(ag: any, index: number) {
  return `asset-plan-group-${index}`;
}
/**
 * A form to configure maintenance plans
 */
export class MaintenancePlanForm extends React.Component<
  MaintenancePlanFormProps,
  MaintenancePlanFormState
> {
  constructor(props: MaintenancePlanFormProps) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaintenanceButtonToolbar
            jumpToTitlesIds={map(this.props.assetGroups, (ag, index) => [
              ag.title,
              assetGroupId(ag, index),
            ])}
            assets={flatten(map(this.props.assetGroups, "assets"))}
            onFilterChanged={noop}
            onToggleAll={this.props.onToggleAll}
          />
        </Grid>
        {this.props.assetGroups.map((assetGroup, assetGroupIndex) => {
          return (
            <Grid item container xs={12} key={assetGroupIndex} spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  id={assetGroupId(assetGroup, assetGroupIndex)}
                >
                  <Link href={assetGroup.title_link}>{assetGroup.title}</Link>
                </Typography>
              </Grid>
              {assetGroup.assets.map((asset, assetIndex) => {
                return (
                  <Grid item xs={12} key={assetIndex}>
                    <MaintenancePlanList
                      assetTree={this.props.assetTree}
                      isOpen={asset.open}
                      assets={this.getAssetWithIdentifier()}
                      maintenanceTypes={this.props.maintenanceTypes}
                      asset={asset}
                      index={assetIndex}
                      key={assetIndex}
                      onAddMaintenancePlan={() =>
                        this.props.onAddMaintenancePlan(
                          assetGroupIndex,
                          assetIndex,
                        )
                      }
                      onAddInspectionPlan={() =>
                        this.props.onAddInspectionPlan(
                          assetGroupIndex,
                          assetIndex,
                        )
                      }
                      onRemoveMaintenancePlan={(maintenancePlanIndex) =>
                        this.props.onRemoveMaintenancePlan(
                          assetGroupIndex,
                          assetIndex,
                          maintenancePlanIndex,
                        )
                      }
                      onUpdateMaintenancePlan={(
                        maintenancePlanIndex,
                        maintenancePlan,
                      ) =>
                        this.props.onUpdateMaintenancePlan(
                          assetGroupIndex,
                          assetIndex,
                          maintenancePlanIndex,
                          maintenancePlan,
                        )
                      }
                      onChangeMaintenancePlanOrder={(
                        maintenancePlanIndex,
                        direction,
                      ) =>
                        this.props.onChangeMaintenancePlanOrder(
                          assetGroupIndex,
                          assetIndex,
                          maintenancePlanIndex,
                          direction,
                        )
                      }
                      onCopyMaintenancePlan={this.props.onCopyMaintenancePlan}
                      onMoveMaintenancePlan={(maintenancePlanIndex, newAsset) =>
                        this.props.onMoveMaintenancePlan(
                          assetGroupIndex,
                          assetIndex,
                          maintenancePlanIndex,
                          newAsset,
                        )
                      }
                      onToggle={(assetIndex, open) => {
                        this.props.onToggle(assetGroupIndex, assetIndex, open);
                      }}
                    />
                  </Grid>
                );
              })}
              <hr />
            </Grid>
          );
        })}
        <FixedBottomArea>
          <FloatingButtons
            showScrollToTopBtn={true}
            isProcessing={this.props.isProcessing}
            onSubmit={this.props.onSubmit}
            onCancel={this.props.onCancel}
          />
        </FixedBottomArea>
      </Grid>
    );
  }

  private getAssetWithIdentifier(): AssetWithIdentifier[] {
    const assets: AssetWithIdentifier[] = [];

    this.props.assetGroups.forEach((assetGroup, assetGroupIndex) => {
      assetGroup.assets.forEach((asset, assetIndex) => {
        assets.push({
          ...asset,
          assetGroupIndex,
          assetIndex,
        });
      });
    });

    return assets;
  }
}

import { Grid, Tab, Tabs } from "@mui/material";
import { concat, find, isEmpty, isNil, reject } from "lodash";
import * as React from "react";

import { redirectTo } from "../../../utils/redirection";
import * as url_helper from "../../../utils/urls";
import { InfluxJournal, SensorGroup } from "../data/model";
import { DeleteSensorDataForm } from "./delete_sensor_data_form";
import { RestoreSensorDataForm } from "./restore_sensor_data_form";
import { SialogicContext } from "../../common/app_context/app_context_provider.types";
import { AppContext } from "../../common/app_context/app_context_provider";

export interface ManageSensorDataFormProps {
  assetId: string | number;
  sensorGroups: SensorGroup[];
  influxJournals: InfluxJournal[];
}

export interface ManageSensorDataFormState {
  mode: "delete" | "restore";
  influxJournals: InfluxJournal[];
}

export class ManageSensorDataForm extends React.Component<
  ManageSensorDataFormProps,
  ManageSensorDataFormState
> {
  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  constructor(props: ManageSensorDataFormProps) {
    super(props);

    this.state = {
      influxJournals: props.influxJournals,
      mode: "delete",
    };
  }

  render(): React.ReactNode {
    return (
      <>
        <Grid container className="mb-2">
          <Grid item xs={12}>
            <Tabs
              value={this.state.mode}
              onChange={(event, newMode) => {
                this.setState({ mode: newMode });
              }}
              aria-label="Sensor Data Management Tabs"
            >
              <Tab
                label={I18n.t(
                  "frontend.manage_sensor_data_form.delete_sensor_data",
                )}
                value={"delete"}
              />
              <Tab
                label={I18n.t(
                  "frontend.manage_sensor_data_form.restore_sensor_data",
                )}
                value="restore"
              />
            </Tabs>
          </Grid>
        </Grid>

        {this.state.mode === "delete" ? (
          <DeleteSensorDataForm
            assetId={this.props.assetId}
            sensorGroups={this.props.sensorGroups}
            journalEntriesAdded={(entries) =>
              this.onJournalEntriesAdded(entries)
            }
            onCancel={() => this.onCancel()}
          />
        ) : null}
        {this.state.mode === "restore" ? (
          <RestoreSensorDataForm
            assetId={this.props.assetId}
            journalEntriesRemoved={(entries) =>
              this.onJournalEntriesRemoved(entries)
            }
            influxJournals={this.state.influxJournals}
            onCancel={() => this.onCancel()}
          />
        ) : null}
      </>
    );
  }
  private onCancel() {
    this.redirect();
  }

  private onJournalEntriesAdded(entries: InfluxJournal[]) {
    this.setState({
      influxJournals: concat(this.state.influxJournals, entries),
    });
  }

  private onJournalEntriesRemoved(entries: InfluxJournal[]) {
    this.setState({
      influxJournals: reject(this.state.influxJournals, (journal) =>
        isNil(find(entries, (e) => e.id == journal.id)),
      ),
    });
  }
  private redirect() {
    if (isEmpty(this.context.referrer)) {
      redirectTo(url_helper.assetPath(this.props.assetId));
    } else {
      redirectTo(this.context.referrer);
    }
  }
}

/// <reference types="../../../definitions/index" />;
import {
  Avatar,
  Badge,
  BadgeProps,
  IconButton,
  Link,
  styled,
} from "@mui/material";
import * as React from "react";
import { Icon } from "../../common/icon";
import { fontSize } from "@mui/system";
import { NotificationsOutlined } from "@mui/icons-material";

interface DropdownButtonProps {
  id?: string;
  /**
   * Tooltip for dropdown button
   */
  title?: string;

  /**
   * Icon for dropdown button
   */
  icon?: React.ReactElement;

  /**
   * Additional icon css classes
   */
  iconClassName?: string;

  /**
   * The number of unnoticed events
   */
  unnoticedEvents?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontSize: 8,
  },
}));

/**
 * A dropdown button with a number label for unnoticed events.
 * No label is shown if there are no unnotived events.
 */
export class DropdownButton extends React.Component<DropdownButtonProps> {
  static defaultProps: DropdownButtonProps = {
    icon: <NotificationsOutlined />,

    unnoticedEvents: 0,
  };

  constructor(props: DropdownButtonProps) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <IconButton
        id={this.props.id || "notification-dropdown"}
        title={this.props.title}
        onClick={this.props.onClick}
      >
        <StyledBadge badgeContent={this.props.unnoticedEvents} color="primary">
          {this.props.icon}
        </StyledBadge>
      </IconButton>
    );
  }
}

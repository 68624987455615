import {
  Add,
  Close,
  Edit,
  KeyboardArrowLeft,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { compact, defaultTo, isEmpty, isNil, merge } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import { useTheme } from "@mui/material/styles";
import { AssetJSONObject, loadAsset } from "../../json_api/asset";
import {
  AssetEventTypeJSONObject,
  AssetEventTypeJsonApiFilter,
} from "../../json_api/asset_event_type";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
  jsonApiFilterParamsArgumentsFromFilterObject,
  metaDataFromJsonApiCollectionResourceDoc,
} from "../../json_api/jsonapi_tools";
import { api_asset_event_types_path, api_asset_type_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { ExtensiblePageSettings } from "../common/page_size";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { AssetTypeJSONAPIAttributes } from "../../json_api/asset_type";
import { ResourcePermission } from "../../models/resource_permission";
import { Icon } from "../common/icon";
import { SeverityLevelChip } from "../common/severity_level";
import { AssetEventTypeDetails } from "./asset_event_type_details";
import { AssetEventTypeFormDialog } from "./asset_event_type_form_dialog";
import { SialogicDialog } from "../common/sialogic_dialog";

export interface AssetEventTypeListProps {
  assetId?: IDType;
  asset?: AssetJSONObject;
  assetTypeId?: IDType;
  assetType?: AssetTypeJSONAPIAttributes;
  withFab?: boolean;
  permissions?: ResourcePermission;
  onBack?: () => void;
}

export const AssetEventTypeList: FunctionComponent<AssetEventTypeListProps> = ({
  withFab = true,
  ...props
}) => {
  const [asset, setAsset] = React.useState(props.asset);
  const [assetType, setAssetType] = React.useState(props.assetType);
  const [assetEventTypes, setAssetEventTypes] =
    React.useState<AssetEventTypeJSONObject[]>(null);
  const [selectableAssets, setSelectableAssets] =
    React.useState<Array<AssetJSONObject | null>>(null);
  const [pageSettings, setPageSettings] =
    React.useState<ExtensiblePageSettings>({
      number: 1,
      size: 20,
      totalPages: null,
      totalRecords: null,
    });

  const [showDetailEventType, setShowDetailEventType] =
    React.useState<AssetEventTypeJSONObject>(null);
  const [editEventType, setEditEventType] =
    React.useState<AssetEventTypeJSONObject>(null);

  // Event types need to bel oaded initally so we initialize loading with true
  const [loading, setLoading] = React.useState(true);
  const [runningLoads, setRunningLoads] = React.useState(0);
  const [reloadData, setReloadData] = React.useState(0);
  React.useEffect(() => {
    setLoading(runningLoads > 0);
  }, [runningLoads]);

  React.useEffect(() => {
    if (isNil(props.asset) && !isNil(props.assetId)) {
      setRunningLoads(runningLoads + 1);
      loadAsset(props.assetId, ["subtree"])
        .then((asset) => {
          setAsset(asset);
        })
        .catch((e) => {
          logger.error(e);
          void error(I18n.t("frontend.error"), e.message);
        })
        .finally(() => {
          setRunningLoads(runningLoads - 1);
        });
    } else if (!isNil(props.asset)) {
      setAsset(props.asset);
    }
  }, [props.assetId, props.asset]);

  React.useEffect(() => {
    if (isNil(props.assetType) && !isNil(props.assetTypeId)) {
      setRunningLoads(runningLoads + 1);
      loadDataFromUrl<SingleResourceDoc<string, AssetTypeJSONAPIAttributes>>(
        api_asset_type_path(props.assetTypeId),
      )
        .then((assetType) => {
          setAssetType(jsonApiSingleResourceToFlatObject(assetType));
        })
        .catch((e) => {
          logger.error(e);
          void error(I18n.t("frontend.error"), e.message);
        })
        .finally(() => {
          setRunningLoads(runningLoads - 1);
        });
    }
  }, [props.assetTypeId]);

  React.useEffect(() => {
    // Load event types only if asset or asset type set
    if (isNil(asset?.id) && isNil(assetType?.id)) return;

    let options: Record<string, string | number> = {};

    options["include"] = "asset_types";
    options["page[size]"] = pageSettings.size;
    options["page[number]"] = pageSettings.number;
    options["sort"] = "id";

    const filter: AssetEventTypeJsonApiFilter = {};
    const assetId = asset?.id ?? props.assetId;
    if (!isNil(assetId)) {
      filter["assets"] = assetId;
    }

    const assetTypeId = assetType?.id ?? props.assetTypeId;
    if (assetTypeId) {
      filter["asset_types"] = assetTypeId;
    }

    if (!isEmpty(filter)) {
      const theFilter = jsonApiFilterParamsArgumentsFromFilterObject(filter);
      options = merge(options, theFilter);
    }
    const url = api_asset_event_types_path({
      ...options,
      _options: true,
    });
    setRunningLoads(runningLoads + 1);

    loadDataFromUrl<CollectionResourceDoc<string, AssetEventTypeJSONObject>>(
      url,
    )
      .then((loadedEvents) => {
        const events = jsonApiResourceCollectionToFlatObjects(loadedEvents);
        setAssetEventTypes(events);
        const metaInfo = metaDataFromJsonApiCollectionResourceDoc(loadedEvents);
        setPageSettings({
          ...pageSettings,
          totalPages: metaInfo.page_count,
          totalRecords: metaInfo.record_count,
        });
      })
      .catch((e) => {
        logger.error(e);
        void error(I18n.t("frontend.error"), e.message);
      })
      .finally(() => {
        setRunningLoads(runningLoads - 1);
      });
  }, [
    pageSettings.number,
    pageSettings.size,
    asset?.id,
    assetType?.id,
    reloadData,
  ]);

  const [assetEventTypeFormOpen, setAssetEventTypeFormOpen] =
    React.useState(false);
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={`${I18n.t("activerecord.models.asset_event_type", { count: 2 })}
              ${I18n.t("frontend.for")} (${props.assetId ? I18n.t("activerecord.models.asset.one") : I18n.t("activerecord.models.asset_type.one")})`}
            subheader={
              isNil(asset) ? defaultTo(assetType?.name, null) : asset.name
            }
            action={
              isNil(props.assetId) ? null : (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setAssetEventTypeFormOpen(true);
                  }}
                  title={
                    props.assetId
                      ? I18n.t(
                          "frontend.asset_event_types.asset_event_type_list.add_event_type_for_asset",
                        )
                      : I18n.t(
                          "frontend.asset_event_types.asset_event_type_list.add_event_type_for_asset_type",
                        )
                  }
                >
                  <Add fontSize="inherit" />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              {loading ? (
                <>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={500} />
                  </Grid>
                </>
              ) : isEmpty(assetEventTypes) ? (
                <Grid item xs={12} p={4}>
                  <Typography textAlign="center">
                    {I18n.t(
                      "frontend.asset_event_types.asset_event_type_list.no_event_types",
                    )}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} p={4}>
                  <DataGrid<AssetEventTypeJSONObject>
                    onRowDoubleClick={(params) => {
                      setShowDetailEventType(params.row);
                    }}
                    columns={[
                      { field: "id", headerName: "#" },
                      {
                        field: "icon",
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.icon",
                        ),
                        renderCell: (params) => {
                          return <Icon icon={params.row.icon} />;
                        },
                      },
                      {
                        field: "default_severity_level",
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.default_severity_level",
                        ),
                        type: "string",
                        renderCell: (params) => (
                          <SeverityLevelChip
                            severityLevel={params.row.default_severity_level}
                          />
                        ),
                      },
                      {
                        field: "slug",
                        type: "string",
                        flex: 0.2,
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.slug",
                        ),
                      },
                      {
                        field: "name",
                        type: "string",
                        flex: 0.2,
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.name",
                        ),
                      },
                      {
                        field: "description",
                        type: "string",
                        flex: 0.2,
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.description",
                        ),
                      },
                      {
                        field: "message",
                        type: "string",
                        flex: 0.2,
                        headerName: I18n.t(
                          "activerecord.attributes.asset_event_type.message",
                        ),
                      },

                      {
                        field: "actions",
                        type: "actions",
                        getActions: (params) =>
                          compact([
                            <GridActionsCellItem
                              icon={<Visibility />}
                              label={I18n.t("frontend.show")}
                              onClick={() => {
                                setShowDetailEventType(params.row);
                              }}
                              key="show"
                            />,
                            props.permissions?.update ? (
                              <GridActionsCellItem
                                icon={<Edit />}
                                label={I18n.t("frontend.edit")}
                                onClick={() => {
                                  setEditEventType(params.row);
                                  setAssetEventTypeFormOpen(true);
                                }}
                                key={"edit"}
                              />
                            ) : null,
                          ]),
                      },
                    ]}
                    onPaginationModelChange={(model) => {
                      setPageSettings({
                        ...pageSettings,
                        number: model.page,
                        size: model.pageSize,
                      });
                    }}
                    rows={assetEventTypes}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        {assetEventTypeFormOpen ? (
          isNil(editEventType) ? (
            <AssetEventTypeFormDialog
              open={assetEventTypeFormOpen}
              assetTypeId={props.assetTypeId}
              assetId={props.assetId}
              onClose={() => {
                setAssetEventTypeFormOpen(false);
                setEditEventType(null);
              }}
              onSave={(assetEventType) => {
                setAssetEventTypeFormOpen(false);
                setEditEventType(null);
                setReloadData(reloadData + 1);
              }}
            />
          ) : (
            <AssetEventTypeFormDialog
              open={assetEventTypeFormOpen}
              onClose={() => {
                setAssetEventTypeFormOpen(false);
                setEditEventType(null);
              }}
              onSave={(assetEventType) => {
                setAssetEventTypeFormOpen(false);
                setEditEventType(null);
                setReloadData(reloadData + 1);
              }}
              assetEventType={editEventType}
            />
          )
        ) : null}
        {isNil(showDetailEventType) ? null : (
          <SialogicDialog
            open={!isNil(showDetailEventType)}
            fullScreen={dialogFullScreen}
            onClose={() => {
              setShowDetailEventType(null);
            }}
            title={I18n.t(
              "frontend.asset_event_types.asset_event_type_list.asset_event_type_details_heading",
            )}
            buttons={
              <Button
                size="small"
                onClick={() => {
                  setShowDetailEventType(null);
                }}
              >
                {I18n.t("frontend.close")}
              </Button>
            }
          >
            <AssetEventTypeDetails assetEventType={showDetailEventType} />
          </SialogicDialog>
        )}
        {withFab ? (
          <FixedBottomArea>
            <FloatingButtons
              showScrollToTopBtn
              isProcessing={loading}
              cancelIcon={<KeyboardArrowLeft />}
              onCancel={() => {
                redirectTo("back");
              }}
              submitBtnIcon={<Add />}
              submitBtnColor="primary"
              onSubmit={() => {
                props.permissions?.create
                  ? setAssetEventTypeFormOpen(true)
                  : null;
              }}
              saveTitle={
                asset
                  ? I18n.t(
                      "frontend.asset_event_types.asset_event_type_list.add_event_type_for_asset",
                    )
                  : I18n.t(
                      "frontend.asset_event_types.asset_event_type_list.add_event_type_for_asset_type",
                    )
              }
              disableSave={assetEventTypeFormOpen}
            />
          </FixedBottomArea>
        ) : null}
      </Grid>
    </Grid>
  );
};

/// <reference types="../../definitions/index" />;
import { Grid, TextField } from "@mui/material";
import { each, find, has, isEmpty, isNil, some } from "lodash";
import * as React from "react";

import {
  Permission,
  ResourcePermissions,
  ResourcePermissionsGroup,
} from "../../models/permission";
import { RoleDefinition } from "../../models/role_definition";
import { sendData } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import * as toast from "../../utils/toasts";
import * as url_helper from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { PermissionsGroupForm } from "./permission_group_form";
import { ResourcePermissionStructure } from "./permission_structure";

interface RoleDefinitionFormProps {
  roleDefinition: RoleDefinition;
  readOnly?: boolean;

  organizationId?: number;
}

interface RoleDefinitionFormState {
  roleDefinition: RoleDefinition;
  permissionGroups: ResourcePermissionsGroup[];
  isProcessing: boolean;
}

export class RoleDefinitionForm extends React.Component<
  RoleDefinitionFormProps,
  RoleDefinitionFormState
> {
  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;

  constructor(props: RoleDefinitionFormProps) {
    super(props);

    this.state = {
      roleDefinition: props.roleDefinition,
      permissionGroups: this.buildPermissionGroup(
        props.roleDefinition.permissions_attributes,
      ),
      isProcessing: false,
    };
  }

  render(): React.ReactNode {
    return (
      <>
        <Grid container>
          {isNil(this.state.roleDefinition?.errors?.base) ? null : (
            <Grid item xs={12}>
              <div className="error-report">
                <h5>
                  {I18n.t("frontend.role_definition_form.base_errors_heading")}
                </h5>
                <ul>
                  <li>{this.state.roleDefinition?.errors?.base as string}</li>
                </ul>
              </div>
            </Grid>
          )}

          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <h4>{I18n.t("activerecord.models.role_definition.one")}</h4>
              </IBoxTitle>
              <IBoxContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="mb-2">
                    <TextField
                      fullWidth
                      disabled={this.props.readOnly}
                      name="role_definition[role]"
                      label={I18n.t(
                        "activerecord.attributes.role_definition.role",
                      )}
                      value={this.state.roleDefinition.role ?? ""}
                      error={has(this.state, "roleDefinition.errors.role")}
                      helperText={
                        this.state.roleDefinition?.errors?.role as string
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => this.onChangeRole(event)}
                    />
                  </Grid>

                  <Grid item xs={12} className="mb-2">
                    <TextField
                      fullWidth
                      disabled={this.props.readOnly}
                      name="role_definition[description]"
                      label={I18n.t(
                        "activerecord.attributes.role_definition.description",
                      )}
                      value={this.state.roleDefinition.description ?? ""}
                      error={has(
                        this.state,
                        "roleDefinition.errors.description",
                      )}
                      helperText={
                        this.state.roleDefinition?.errors?.description as string
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => this.onChangeDescription(event)}
                      multiline
                    />
                  </Grid>
                </Grid>
              </IBoxContent>
            </IBox>
          </Grid>

          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <h4>{I18n.t("activerecord.models.permission.other")}</h4>
              </IBoxTitle>
              <IBoxContent>
                {this.state.permissionGroups.map((permissionGroup, index) => {
                  return (
                    <PermissionsGroupForm
                      readOnly={this.props.readOnly}
                      key={index}
                      permissionGroup={permissionGroup}
                      onChange={(permissionGroup) =>
                        this.onChangePermissionGroup(index, permissionGroup)
                      }
                    />
                  );
                })}
              </IBoxContent>
            </IBox>
          </Grid>
        </Grid>
        <FixedBottomArea id="fixed-bottom-area">
          {this.props.readOnly ? null : (
            <FloatingButtons
              isProcessing={this.state.isProcessing}
              onSubmit={() => void this.onSubmit()}
              onCancel={() => void this.onCancel()}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.role_definition_form.submit_title")}
            />
          )}
        </FixedBottomArea>
      </>
    );
  }

  private onChangeRole(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    event.persist();
    this.setState((prevState) => ({
      roleDefinition: {
        ...prevState.roleDefinition,
        role: event.target.value,
      },
    }));
  }

  private onChangeDescription(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    event.persist();
    this.setState((prevState) => ({
      roleDefinition: {
        ...prevState.roleDefinition,
        description: event.target.value,
      },
    }));
  }

  private onChangePermissionGroup(
    index: number,
    permissionGroup: ResourcePermissionsGroup,
  ) {
    this.setState((prevState) => {
      const permissionGroups = [...prevState.permissionGroups];
      permissionGroups[index] = permissionGroup;

      return {
        permissionGroups,
      };
    });
  }

  private onSubmit() {
    this.setState({ isProcessing: true });
    const roleDefinition = {
      ...this.state.roleDefinition,
      permissions_attributes: [],
    } as RoleDefinition;
    this.state.permissionGroups.forEach((permissionGroup) => {
      permissionGroup.permissions.forEach((resourcePermissions) => {
        each(resourcePermissions.actions, (enabled, action) => {
          const prevPermission = find(
            this.state.roleDefinition.permissions_attributes,
            (permission) => {
              return (
                permission.resource === resourcePermissions.resource &&
                permission.action === action
              );
            },
          );
          if (enabled) {
            roleDefinition.permissions_attributes.push({
              id: prevPermission?.id,
              resource: resourcePermissions.resource,
              action: action,
            });
          } else if (!isNil(prevPermission)) {
            roleDefinition.permissions_attributes.push({
              ...prevPermission,
              _destroy: true,
            });
          }
        });
      });
    });
    const formUrl = isNil(this.state.roleDefinition.id)
      ? url_helper.roleDefinitionsPath("json", this.props.organizationId)
      : url_helper.roleDefinitionPath(
          this.state.roleDefinition.id,
          "json",
          this.props.organizationId,
        );
    const method = isNil(this.state.roleDefinition.id) ? "POST" : "PATCH";

    return sendData<any, RoleDefinition>(
      formUrl,
      { role_definition: roleDefinition },
      method,
    )
      .then((roleDefinition) => {
        this.setState((prevState) => ({
          roleDefinition: {
            ...prevState.roleDefinition,
            id: roleDefinition.id,
          },
          isProcessing: false,
        }));
        this.redirect();
      })
      .catch((error) => {
        this.setState({ isProcessing: false });
        // load errors from response if present
        if (has(error, "request.responseJSON")) {
          this.setState((prevState) => ({
            roleDefinition: {
              ...prevState.roleDefinition,
              errors: error.request.responseJSON,
            },
          }));
        }

        void toast.error(
          I18n.t("frontend.role_definition_form.error"),
          I18n.t("frontend.role_definition_form.error_title"),
          true,
        );
      });
  }

  private onCancel() {
    this.setState({ isProcessing: false });
    this.redirect();
  }

  private redirect(): void {
    const { roleDefinition } = this.state;

    if (!isNil(roleDefinition.id)) {
      redirectTo(url_helper.roleDefinitionPath(roleDefinition.id));
    } else if (
      !isNil(this.context.referrer) &&
      !isEmpty(this.context.referrer)
    ) {
      // redirect to referrer
      redirectTo(this.context.referrer);
    } else {
      redirectTo(url_helper.roleDefinitionsPath());
    }
  }

  private buildPermissionGroup(
    permissions: Permission[],
  ): ResourcePermissionsGroup[] {
    const permissionGroups = [] as ResourcePermissionsGroup[];

    each(ResourcePermissionStructure, (resources, groupName) => {
      const permissionGroup = {
        groupName,
        permissions: [],
      } as ResourcePermissionsGroup;
      permissionGroups.push(permissionGroup);

      each(resources, (resource: any, resourceName) => {
        const permission = {
          resource: resourceName,
          actions: {},
        } as ResourcePermissions;
        permissionGroup.permissions.push(permission);

        resource?.actions?.forEach((action: string) => {
          const checked = some(
            permissions,
            (permission) =>
              permission.resource === resourceName &&
              permission.action === action,
          );
          permission.actions[action] = checked;
        });
      });
    });

    // return sorted permission groups
    return permissionGroups.sort((a, b) =>
      a.groupName.localeCompare(b.groupName),
    );
  }
}

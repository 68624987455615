import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { Location } from "../models/location";
import { AssetJSONObject } from "./asset";
import { OrganizationJSONObject } from "./organization";

export const LOCATION_JSON_API_TYPE = "locations";

/**
 * An array of keys representing the attributes that can be used to create a Location object.
 *
 * The attributes included are:
 * - `lat`: Latitude of the location.
 * - `lon`: Longitude of the location.
 * - `street`: Street address of the location.
 * - `state`: State where the location is situated.
 * - `city`: City where the location is situated.
 * - `zip`: ZIP code of the location.
 * - `level`: Level or floor of the location.
 * - `localizable_item_id`: ID of the localizable item associated with the location.
 * - `localizable_item_type`: Type of the localizable item associated with the location.
 * - `room`: Room number or identifier within the location.
 * - `note`: Additional notes about the location.
 * - `country_code_iso_2`: ISO 3166-1 alpha-2 country code of the location.
 * - `title`: Title or name of the location.
 */
export const LOCATION_CREATABLE_ATTRIBUTES: (keyof Location)[] = [
  "lat",
  "lon",
  "street",
  "state",
  "city",
  "zip",
  "level",
  "room",
  "note",
  "country_code_iso_2",
  "title",
];

export const LOCATION_UPDATABLE_ATTRIBUTES: (keyof Location)[] = [
  "lat",
  "lon",
  "street",
  "state",
  "city",
  "zip",
  "level",
  "room",
  "note",
  "country_code_iso_2",
  "title",
];

export type LocationIncludes = "asset" | "organization";

export interface LocationJSONObject extends Location, JSON.Object {
  asset?: AssetJSONObject;
  organization?: OrganizationJSONObject;
}
export type LocationJSONAPIAttributes =
  JSONAPI.AttributesObject<LocationJSONObject>;

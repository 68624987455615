import { clamp, defaultTo, isNil } from "lodash";
import { SensorValueRange } from "../../../models/sensor_value_range";
import { Moment } from "moment";
export class SensorPercentageValueCalculator {
  /** Numerator value at the beginning of the relevant time interval
   *
   *
   * @type {number}
   * @memberof SensorPercentageValueCalculator
   */
  numeratorBaseValue: number;

  /** Denominator value at the beginning of the relevant time interval
   *
   *
   * @type {number}
   * @memberof SensorPercentageValueCalculator
   */

  denominatorBaseValue: number;
  numValue: number;
  numTime: Moment;
  denomValue: number;
  denomTime: Moment;
  range: SensorValueRange;

  limitToRange?: boolean;

  constructor(
    numeratorBaseValue: number,
    denominatorBaseValue: number,
    range?: SensorValueRange,
  ) {
    this.range = range;
    this.denominatorBaseValue = defaultTo(denominatorBaseValue, 0);
    this.numeratorBaseValue = defaultTo(numeratorBaseValue, 0);
    this.limitToRange = !isNil(range);
  }

  protected nanValue() {
    return 0.0;
  }

  setDenominatorBaseValue(value: number) {
    this.denominatorBaseValue = defaultTo(value, 0);
  }

  setNumeratorBaseValue(value: number) {
    this.numeratorBaseValue = defaultTo(value, 0);
  }

  calculateValue(): number {
    if (
      isNil(this.numValue) ||
      isNil(this.denomValue) ||
      isNil(this.denominatorBaseValue) ||
      isNil(this.numeratorBaseValue)
    )
      return null;

    if (this.denominatorBaseValue - this.denomValue == 0) {
      // return 0 as percentage instead of NaN
      return this.nanValue();
    }

    const value =
      ((this.numValue - this.numeratorBaseValue) /
        (this.denomValue - this.denominatorBaseValue)) *
      100.0;

    if (this.limitToRange && !isNil(this.range)) {
      return clamp(value, this.range.min, this.range.max);
    }

    return value;
  }

  setValueRange(range: SensorValueRange) {
    this.range = range;
  }

  setNumeratorValue(value: number, time: Moment) {
    this.numValue = value;
    this.numTime = time;
  }
  setDenominatorValue(value: number, time: Moment) {
    this.denomValue = value;
    this.denomTime = time;
  }
  lastValueTimestamp(): Moment {
    if (!isNil(this.numTime) && !isNil(this.denomTime)) {
      return this.numTime.isAfter(this.denomTime)
        ? this.numTime
        : this.denomTime;
    } else if (!isNil(this.numTime)) {
      return this.numTime;
    } else if (!isNil(this.denomTime)) {
      return this.denomTime;
    } else {
      return null;
    }
  }
}

import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { SingleResourceDoc } from "jsonapi-typescript";
import { Sensor, SensorValue } from "../models/sensor";
import { buildJsonApiSubmitData } from "../utils/jsonapi_form_tools";

export type SensorValueJSONObject = SensorValue & JSON.Object;

import { isEmpty } from "lodash";
import { api_sensor_path, api_sensors_path } from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { SensorIncludes } from "../utils/urls";
import { IDType } from "../utils/urls/url_utils";
import { AssetJSONAPIAttributesObject } from "./asset";
import { EventPatternJSONAPIAttributes } from "./event_pattern";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "./jsonapi_tools";
import { SensorTypeJSONAPIAttributes } from "./sensor_type";
import { SensorValueRangeJSONObject } from "./sensor_value_range";

export interface SensorJSONObject extends Partial<Sensor>, JSON.Object {
  last_value?: SensorValueJSONObject;
  total_value_range?: SensorValueRangeJSONObject;
  value_ranges?: SensorValueRangeJSONObject[];
}

export interface SensorJSONAPIAttributes
  extends JSONAPI.AttributesObject<SensorJSONObject> {
  sensor_type?: SensorTypeJSONAPIAttributes;
  asset?: AssetJSONAPIAttributesObject;
  event_patterns?: EventPatternJSONAPIAttributes[];
}

export interface SensorFilter {
  asset?: IDType[];
  sensor_type?: IDType[];
  search?: string;
}

const updatableResourceFields: (keyof Sensor)[] = [
  "attribute_key_unit",
  "description",
  "display_unit",
  "enabled",
  "derived",
  "exp_data_period",
  "import_precision",
  "key",
  "manufacturer",
  "model",
  "name",
  "precision",
  "sampling_rate_unit",
  "sampling_rate_value",
  "sensor_type_id",
  "sensor_context",
  "sensor_context2",
  "serial",
  "short_name",
  "total_value_range",
];

const creatableFields = [
  "asset",
  "attribute_key_unit",
  "attribute_key",
  "description",
  "display_unit",
  "enabled",
  "exp_data_period",
  "feature",
  "hidden",
  "derived",
  "import_precision",
  "key",
  "manufacturer",
  "name",
  "precision",
  "sampling_rate_unit",
  "sampling_rate_value",
  "sensor_type_id",
  "sensor_context",
  "sensor_context2",
  "serial",
  "short_name",
  "total_value_range",
];

export const SENSOR_JSONAPI_RESOURCE_TYPE = "sensors";
export function buildSensorUpdateRequestPayload(sensorData: SensorJSONObject) {
  return buildJsonApiSubmitData(
    sensorData,
    SENSOR_JSONAPI_RESOURCE_TYPE,
    updatableResourceFields,
  );
}

export function buildSensorCreateRequestPayload(sensorData: SensorJSONObject) {
  return buildJsonApiSubmitData(
    sensorData,
    SENSOR_JSONAPI_RESOURCE_TYPE,
    creatableFields,
  );
}

/** Loads a sensor from remote
 *
 *
 * @export
 * @param {IDType} sensorId
 * @return {*}  {SensorJSONAPIAttributes}
 */
export async function loadSensor(
  sensorId: IDType,
): Promise<SensorJSONAPIAttributes> {
  const resp = await loadDataFromUrl<
    SingleResourceDoc<string, SensorJSONAPIAttributes>
  >(api_sensor_path(sensorId, { format: "json" }));

  return jsonApiSingleResourceToFlatObject(resp);
}

/** Loads a sensor from remote
 *
 *
 * @export
 * @param {IDType} sensorId
 * @return {*}  {SensorJSONAPIAttributes}
 */
export async function loadSensors(
  sensorIds: IDType[],
  assetIds: IDType[],
  includes?: SensorIncludes[],
): Promise<SensorJSONAPIAttributes[]> {
  const options: any = {
    format: "json",
    _options: true,
  };
  if (sensorIds?.length > 0) {
    options["filter[id]"] = sensorIds.join(",");
  }
  if (assetIds?.length > 0) {
    options["filter[asset_id]"] = assetIds.join(",");
  }
  if (!isEmpty(includes)) {
    options["include"] = includes.join(",");
  }
  const path = api_sensors_path(options);
  const resp =
    await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, SensorJSONAPIAttributes>
    >(path);

  return jsonApiResourceCollectionToFlatObjects(resp);
}

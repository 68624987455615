import * as React from "react";
import { AppRoot } from "../components/common/app_root";
import { WeatherWidget as WeatherWidgetComponent } from "../components/widgets/weather_widget";
import { WeatherWidgetConfigSerialized } from "./weather_widget.types";
import Widget from "./widget";

/**
 * Javascript implementation of the WeatherWidget.
 *
 * @class WeatherWidget
 * @extends {Widget}
 */
export default class WeatherWidget extends Widget {
  lat: number;
  lon: number;
  apiKey: string;
  provider: string;
  unit: string;
  showForecast?: boolean;

  static getDomClassName(): string {
    return "weather-widget";
  }

  static widgetClassName(): string {
    return "WeatherWidget";
  }

  protected constructor(element: JQuery<HTMLElement>) {
    super(element);
  }

  protected initComponent(element: JQuery) {
    super.initComponent(element);
    const props = WeatherWidgetComponent.serializedConfigToProps(this.config);
    this.root.render(
      <AppRoot>
        <WeatherWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data("config") as WeatherWidgetConfigSerialized;
    this.apiKey = config.api_key;
    this.lat = config.lat;
    this.lon = config.lon;
  }
  cleanup(): void {
    super.cleanup();
  }
}

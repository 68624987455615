import { Cancel, Search } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as JSONAPI from "jsonapi-typescript";
import { DivIcon, LatLngExpression, Marker } from "leaflet";
import {
  defaultTo,
  first,
  isArray,
  isEmpty,
  isNil,
  map,
  toArray,
  toNumber,
  toString,
} from "lodash";
import * as React from "react";
import * as ReactLeaflet from "react-leaflet";
import { AssetJSONAPIAttributesObject } from "../../json_api/asset";
import { LocationJSONObject } from "../../json_api/location";
import { Asset } from "../../models/asset";
import { Localizable, Location } from "../../models/location";
import { getTranslatedProp } from "../../utils/globalize";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { error, success } from "../../utils/toasts";
import { assetPath, geocodePath } from "../../utils/urls";
import { AttributeRow } from "../common/attribute_row";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { LoadingWrapper } from "../common/loading_wrapper";
import { useCreateLocation, useUpdateLocation } from "./location_data";

const PREFIX = "LocationPicker";

const classes = {
  title: `${PREFIX}-title`,
};

const StyledIBox = styled(IBox)({
  [`& .${classes.title}`]: {
    fontSize: 14,
  },
});

interface LocalizableJSONAPIAttributes {
  data: Localizable;
}
export interface LocationPickerProps {
  localizableId?: number;
  localizableType?: string;
  localizableItem?: Localizable;
  location?: LocationJSONObject;
  enableLocationUpdate?: boolean;
  showSearch?: boolean;
  mapCenter?: [number, number];
  maxZoom?: number;
  tileUrl?: string;
  mapHeight?: number;

  onLocationSelected?: (location: LocationJSONObject) => void;

  searchResultApplied?: (searchResult: GeocoderResultAddress) => void;
  // save location to backend on change
  saveLocationOnChange: boolean;
}

export interface GeocoderResultAddress {
  road?: string;
  city?: string;
  city_district?: string;
  country?: string;
  county?: string;
  town?: string;
  postcode?: string;
  building?: string;
  country_code?: string;
  house_number?: string;
  suburb?: string;
}
interface GeocoderResult {
  lat: number;
  lon: number;
  display_name: string;
  type: string;
  icon?: string;
  address: GeocoderResultAddress;
}

const DEFAULT_CENTER: [number, number] = [52.3776796, 13.077254];
export const LocationPicker: React.FunctionComponent<LocationPickerProps> = ({
  enableLocationUpdate = true,
  saveLocationOnChange = true,
  showSearch = true,
  mapCenter: mapCent = DEFAULT_CENTER,
  maxZoom = 20,
  mapHeight = 600,
  ...props
}) => {
  const markerRef = React.createRef<Marker>();

  let initialLocation: LocationJSONObject = null;
  if (!isNil(props.location)) {
    initialLocation = {
      id: isNaN(props.location?.id) ? null : props.location?.id,
      ...props.location,
    };
  }
  const [mapCenter, setMapCenter] = React.useState<[number, number]>(
    isNil(initialLocation) ||
      isNil(initialLocation?.lat) ||
      isNil(initialLocation?.lon)
      ? defaultTo(mapCent, DEFAULT_CENTER)
      : [initialLocation.lat, initialLocation.lon],
  );
  const [loading, setLoading] = React.useState(false);
  const [draggable, setDraggable] = React.useState<boolean>(false);

  const [searchTerm, setSearchTerm] = React.useState<string>(null);

  const [searchResult, setSearchResult] = React.useState<GeocoderResult>(null);

  const [location, setLocation] =
    React.useState<LocationJSONObject>(initialLocation);

  const [localizableItem, setLocalizableItem] = React.useState<Localizable>(
    props.localizableItem,
  );
  const [localizableId, setLocalizableId] = React.useState(props.localizableId);

  React.useEffect(() => {
    if (props.location !== location) {
      setLocation(props.location);
    }
  }, [props.location?.lat, props.location?.lon]);
  React.useEffect(() => {
    if (
      !isNil(location) &&
      (isNil(initialLocation) ||
        (location.lat !== initialLocation.lat &&
          location.lon !== initialLocation.lon))
    ) {
      props.onLocationSelected?.(location);
      if (saveLocationOnChange === true && !isNil(localizableId)) {
        saveLocation(location);
      }
    }
  }, [location?.lat, location?.lon]);

  React.useEffect(() => {
    if (isNil(localizableId)) return;
    if (
      isNil(localizableItem) ||
      toNumber(localizableId) !== toNumber(localizableItem.id)
    ) {
      loadDataFromUrl<
        JSONAPI.SingleResourceDoc<string, AssetJSONAPIAttributesObject>
      >(assetPath(localizableId, "json"))
        .then((resultAsset) => {
          const asset: Asset = {
            id: localizableId,
            ...resultAsset.data.attributes,
          };
          if (
            !isNil(asset.name) &&
            isEmpty((asset as Record<string, string>)["name_" + I18n.locale])
          ) {
            (asset as Record<string, string>)["name_" + I18n.locale] =
              asset.name;
          }

          assetLoadFinished(asset, location);
        })
        .catch((err) => {
          logger.error(`Error loading asset ${localizableId}`, err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [localizableId]);

  const assetLoadFinished = React.useCallback(
    (asset: Asset, location: LocationJSONObject) => {
      setLocalizableItem(asset as Localizable);
      setLoading(isNil(asset) || isNil(location));
      setLocation(location);
    },
    [],
  );

  const OnMapClick: () => React.ReactElement = React.useCallback(() => {
    ReactLeaflet.useMapEvent("click", (mouseEvent) => {
      if (draggable || isNil(location)) {
        mouseEvent.originalEvent.stopPropagation();
        const latLng = mouseEvent.latlng;
        const newLocation = {
          ...location,
          lat: latLng.lat,
          lon: latLng.lng,
        };
        setLocation(newLocation);

        return false;
      }
    });
    return null;
  }, [draggable, location?.lat, location?.lon]);

  const getMarkerText = React.useCallback(() => {
    const name = (localizableItem as Asset)?.asset_type_name;
    return isNil(name) ? null : <small>{name}</small>;
  }, [localizableItem]);

  const SetViewOnClick: (props: {
    coords: LatLngExpression;
  }) => React.ReactElement = React.useCallback((props) => {
    const map = ReactLeaflet.useMap();
    map.setView(props.coords, map.getZoom());

    return null;
  }, []);

  const getDraggableMarker = React.useCallback(
    (title: string) => {
      let mapLoc: [number, number];
      if (!isNil(location) && !isNil(location.lat) && !isNil(location.lon)) {
        mapLoc = [location.lat, location.lon];
      } else {
        return null;
      }

      return (
        <ReactLeaflet.Marker
          draggable={draggable && enableLocationUpdate}
          eventHandlers={{
            dragend: () => {
              handleDragEnd();
            },
          }}
          position={mapLoc}
          ref={markerRef}
        >
          <ReactLeaflet.Popup minWidth={120}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">{title}</Typography>
                {getMarkerText()}
              </Grid>

              {enableLocationUpdate ? (
                <Grid item container xs={12}>
                  <Grid item>
                    <Typography variant="body1">
                      {draggable
                        ? I18n.t(
                            "frontend.location_picker.drag_marker_to_change_location",
                          )
                        : I18n.t(
                            "frontend.location_picker.press_button_to_enable_dragging",
                          )}
                    </Typography>
                  </Grid>
                  <Grid item>{getDragToggleButton()}</Grid>
                </Grid>
              ) : null}
            </Grid>
          </ReactLeaflet.Popup>
        </ReactLeaflet.Marker>
      );
    },
    [draggable, markerRef],
  );

  const getDragToggleButton = React.useCallback(() => {
    return (
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => {
          setDraggable(!draggable);
        }}
      >
        {draggable
          ? I18n.t("frontend.location_picker.stop_location_drag")
          : I18n.t("frontend.location_picker.enable_dragging")}
      </Button>
    );
  }, [draggable]);

  const handleDragEnd = React.useCallback(() => {
    const marker = markerRef.current;
    if (marker != null) {
      const latlng = marker.getLatLng();

      const newLocation = {
        ...location,
        lat: latlng.lat,
        lon: latlng.lng,
      };
      setLocation(newLocation);
    }
  }, [markerRef, location]);

  const searchCoordinates = React.useCallback(async () => {
    if (!isNil(searchTerm)) {
      if (searchTerm.length < 3) {
        void toasts.error(
          I18n.t("frontend.location_picker.geocode.search_term_error"),
          I18n.t("frontend.location_picker.geocode.search_term_too_short"),
        );
        return;
      }

      const results = await loadDataFromUrl<GeocoderResult[]>(
        geocodePath(searchTerm),
      );
      if (isEmpty(results)) {
        void toasts.warn(
          I18n.t("frontend.location_picker.geocode.no_results"),
          I18n.t("frontend.location_picker.geocode.search_returned_no_results"),
        );
        return;
      }

      const result = first(results);
      setSearchResult(result);
      setMapCenter([result.lat, result.lon]);
    }
  }, [searchTerm]);

  const { mutateAsync: updateLocation } = useUpdateLocation();
  const { mutateAsync: createLocation } = useCreateLocation();
  const saveLocation = React.useCallback((theLocation: Location) => {
    if (isNil(theLocation?.lat) || isNil(theLocation?.lon)) {
      return;
    }

    let promise: Promise<LocationJSONObject>;
    if (isNil(theLocation?.id) || isNaN(theLocation?.id)) {
      const locationToCreate: LocationJSONObject = {
        lat: theLocation.lat,
        lon: theLocation.lon,
      };
      if (props.localizableType === "asset") {
        locationToCreate["asset_id"] = { id: props.localizableId };
      } else if (props.localizableType === "organization") {
        locationToCreate["organization"] = { id: props.localizableId };
      }

      promise = createLocation(locationToCreate);
    } else {
      promise = updateLocation({
        lat: theLocation.lat,
        lon: theLocation.lon,
      });
    }

    promise
      .then((result) => {
        if (result.location) {
          setLocation(result.location as LocationJSONObject);
        }
        return success(
          I18n.t("frontend.location_picker.location_updated"),
          I18n.t("frontend.location_picker.location_saved_to_db"),
        );
      })
      .catch((err) => {
        void error(
          I18n.t("base.error"),
          I18n.t("frontend.location_picker.location_could_not_be_saved"),
        );
        logger.error(err);
      });
  }, []);

  const title = getTranslatedProp(localizableItem, "name");

  const searchResAttr = React.useMemo(() => {
    return (
      [
        "county",
        "postcode",
        "city",
        "town",
        "city_district",
        ["road", "house_number"],
      ] as (keyof GeocoderResultAddress)[]
    ).map((att) => {
      const val = map(
        isArray(att) ? att : [att],
        (att) => searchResult?.address?.[att as keyof GeocoderResultAddress],
      )
        .join(" ")
        .trim();
      if (isEmpty(val)) return null;
      let translateKey = att;
      if (isArray(att)) {
        translateKey = first(att);
      }
      return (
        <AttributeRow
          key={att}
          attributeName={I18n.t(`base.geocode.address.${translateKey}`)}
          value={val}
          dense
          divider
        />
      );
    });
  }, [searchResult]);

  return (
    <StyledIBox>
      <IBoxTitle>
        <Typography variant="h5">
          {isNil(title)
            ? I18n.t("frontend.location_picker.title")
            : I18n.t("frontend.location_picker.title_w_name", {
                name: title,
              })}
        </Typography>
      </IBoxTitle>
      <IBoxContent>
        <LoadingWrapper loading={loading}>
          <Grid container>
            {showSearch ? (
              <Grid item xs={12} marginBottom={1}>
                <TextField
                  label={I18n.t(
                    "frontend.location_picker.geocode.search_location_text",
                  )}
                  onChange={(event) => {
                    setSearchTerm(event.currentTarget.value);
                  }}
                  value={toString(searchTerm)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      void searchCoordinates();
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                />
                <IconButton
                  onClick={() => {
                    void searchCoordinates();
                  }}
                  size="large"
                >
                  <Search />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSearchResult(null);
                    setSearchTerm(null);
                  }}
                  size="large"
                >
                  <Cancel />
                </IconButton>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <ReactLeaflet.MapContainer
                style={{ height: mapHeight }}
                center={defaultTo(mapCent, DEFAULT_CENTER)}
                zoom={12}
                maxZoom={maxZoom}
                attributionControl
              >
                <OnMapClick />
                <SetViewOnClick coords={mapCent} />
                <ReactLeaflet.TileLayer
                  maxZoom={maxZoom}
                  maxNativeZoom={maxZoom}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {getDraggableMarker(title)}
                {isNil(searchResult) ? null : (
                  <ReactLeaflet.Marker
                    attribution="Data licensed under Open Database License (ODbL)"
                    position={[searchResult.lat, searchResult.lon]}
                    title={searchResult.display_name}
                    icon={
                      new DivIcon({
                        html: `<i class="fas fa-map-pin fa-3x" style="color: #dd0000"></i>`,
                        iconSize: [20, 36],
                        iconAnchor: [10, 36],
                        popupAnchor: [0, -36],
                        className: "map-icon",
                      })
                    }
                  >
                    <ReactLeaflet.Popup minWidth={120}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.title} variant="h6">
                            {searchResult.display_name}
                          </Typography>
                        </Grid>

                        <Grid container item xs={12}>
                          {searchResAttr}
                        </Grid>
                        <Grid item xs={12} marginTop={1}>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                              const newLocation = {
                                ...location,
                                lat: toNumber(searchResult.lat),
                                lon: toNumber(searchResult.lon),
                              };
                              if (props.searchResultApplied) {
                                props.searchResultApplied(searchResult.address);
                              }
                              setLocation(newLocation);
                            }}
                          >
                            {I18n.t("frontend.location_picker.set_as_location")}
                          </Button>
                        </Grid>
                      </Grid>
                    </ReactLeaflet.Popup>
                  </ReactLeaflet.Marker>
                )}
              </ReactLeaflet.MapContainer>
            </Grid>
            {enableLocationUpdate ? (
              <Grid item xs={12}>
                {getDragToggleButton()}
              </Grid>
            ) : null}
          </Grid>
        </LoadingWrapper>
      </IBoxContent>
    </StyledIBox>
  );
};

import { isEmpty, sortBy, uniqBy } from "lodash";
import * as React from "react";

import * as JSONType from "json-typescript";
import { Root, createRoot } from "react-dom/client";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { AppRoot } from "../../common/app_root";
import {
  WidgetEditForm,
  WidgetEditorAvailableSensor,
  WidgetEditorSensorType,
} from "./widget_config_editor";

const widgetEditorFormRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeWidgetEditorForm(
  selector: JQuery = $('[data-toggle="widget-editor-form"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);
    const widgetId = jqElement.data("widget-id") as number;
    const widgetType = jqElement.data("widget-type") as string;
    const schemaJson = JSON.parse(
      element.getAttribute("data-widget-schema"),
    ) as JSONType.Object;
    const widgetName = jqElement.data("widget-name") as string;
    const configJson = JSON.parse(
      element.getAttribute("data-widget-config"),
    ) as JSONType.Object;

    const attachmentData = element.getAttribute("data-widget-attachments");
    let attachments: Record<string, ActiveStorageStoredFile>;
    if (!isEmpty(attachmentData)) {
      attachments = JSON.parse(attachmentData) as Record<
        string,
        ActiveStorageStoredFile
      >;
    }
    let sensorTypes = jqElement.data(
      "sensor-types",
    ) as WidgetEditorSensorType[];

    sensorTypes = uniqBy(sortBy(sensorTypes, "name"), "name");

    const availableSensors = jqElement.data(
      "available-sensors",
    ) as WidgetEditorAvailableSensor[];
    const assetId = jqElement.data("asset-id") as number;
    const assetTypeId = jqElement.data("asset-type-id") as number;
    const dashboardId = jqElement.data("dashboard-id") as number;
    const dashboardType = jqElement.data("dashboard-type") as string;
    const referrer = jqElement.data("referer") as string;
    const root = createRoot(element);
    widgetEditorFormRoots.push(root);
    root.render(
      <AppRoot>
        <WidgetEditForm
          referer={referrer}
          sensorTypes={sensorTypes}
          widgetId={widgetId}
          widgetTypeIdentifier={widgetType}
          assetId={assetId}
          assetTypeId={assetTypeId}
          dashboardId={dashboardId}
          attachments={attachments}
          availableSensors={availableSensors}
          dashboardType={
            dashboardType as "asset_type_dashboard" | "asset_dashboard"
          }
          schema={schemaJson}
          config={configJson}
        />
      </AppRoot>,
    );
  });
}

/**
 * Remove react component from all ellements
 */
export function destroyWidgetEditorForm(): void {
  widgetEditorFormRoots.forEach((r) => r.unmount());
  widgetEditorFormRoots.length = 0;
}

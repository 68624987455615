import moment, { Moment } from "moment";

import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import { FaSizeType } from "../components/common/icon";
import {
  SensorValueWidget as SensorValueWidgetComponent,
  SensorValueWidgetDefinition,
} from "../components/widgets/sensor_value_widget";
import { SensorValueRange } from "../models/sensor_value_range";
import {
  SensorValueDisplayMode,
  SensorValueWidgetConfigSerialized,
} from "./sensor_value_widget.types";
import Widget from "./widget";

/** Widget encapsulating a sensor value display that can be updated programmatically
 * requires a set of spans as child elements to set the values
 * <span class="value"></span><span class="unit"></span>
 * ans <small|span class="timestamp">
 *
 * reads initial values from data attributes if provided:
 * data-sensor-id: sensor id
 * data-attribute-key-id: attribute key id to subscribe
 * data-unit: unit string
 * data-value: value
 * data-time: timestamp in ISO 8601 format
 * data-disable-update: disables the data update
 * @class SensorValuePanel
 * @extends {Widget}
 */
export class SensorValueWidget extends Widget<SensorValueWidgetConfigSerialized> {
  timestampElement: JQuery;

  sensorId: number;
  assetId: number;

  unit: string;
  precision?: number;

  value: number;
  timestamp: Moment;
  timeScope: string;

  updateEnabled: boolean;
  hideValue: boolean;

  mode: SensorValueDisplayMode;

  sensorType?: string;
  measurementType?: string;
  iconName?: string;
  iconSize?: FaSizeType;
  vertical?: boolean;
  status?: string;

  valueRanges?: SensorValueRange[];

  textShadow?: boolean;
  /** DEfines usage of range color and icons */
  useValueRange?: boolean;

  static getDomClassName(): string {
    return "sensor-value-widget";
  }

  protected readMembersFromElement(element: JQuery) {
    super.readMembersFromElement(element);

    const config = element.data("config") as SensorValueWidgetConfigSerialized;
  }

  protected initComponent(element: JQuery, render = true) {
    super.initComponent(element);

    if (render) {
      const props = SensorValueWidgetDefinition.serializedConfigToProps(
        this.config,
      );
      this.root.render(
        <AppRoot>
          <SensorValueWidgetComponent
            {...props}
            dashboardSettings={this.dashboardSettings}
          />
        </AppRoot>,
      );
    }
  }
  cleanup(): void {
    super.cleanup();
  }
}

export default SensorValueWidget;

import { defaultTo, get, isNil } from "lodash";

export const Redirects: string[] = [];

export function resetRedirects() {
  Redirects.splice(0);
}
/**
 * Redirects to the given url. Redirect is mocked if code is run in test environment
 * @param url To url to redirect to
 */
export function redirectTo(
  url: string | "back" = "back",
  target: string = null,
): void {
  /* istanbul ignore else  */
  if (
    typeof process !== "undefined" &&
    !isNil(process) &&
    (get(process, "env.NODE_ENV", "production") === "test" ||
      get(process, "env.RAILS_ENV", "production") === "test")
  ) {
    // mock redirect for unit tests
    Redirects.push(url);
  } else {
    if (defaultTo(url, "back") === "back") {
      window.history.back();
    } else {
      if (isNil(target)) {
        window.location.assign(url);
      } else {
        window.open(url, target);
      }
    }
  }
}

export function openInNewWindow(url: string): void {
  /* istanbul ignore else  */
  if (
    typeof process !== "undefined" &&
    !isNil(process) &&
    (get(process, "env.NODE_ENV", "production") === "test" ||
      get(process, "env.RAILS_ENV", "production") === "test")
  ) {
    // mock redirect for unit tests
    Redirects.push(url);
  } else {
    window.open(url, "_blank");
  }
}

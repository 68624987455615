import React from "react";
import PropTypes from "prop-types";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";
import { Grid, MenuItem, Radio, TextField } from "@mui/material";

const RepeatMonthlyOn = ({
  id,
  mode,
  on,
  hasMoreModes,
  handleChange,
  translations,
}) => {
  const isActive = mode === "on";

  return (
    <Grid item xs={12} className={`${!isActive && "opacity-50"}`}>
      <Grid container spacing={2}>
        <Grid item display={{ xs: "none" }} sm={2} />
        <Grid item xs="auto">
          {hasMoreModes && (
            <Radio
              id={id}
              name="repeat.monthly.mode"
              aria-label="Repeat monthly on"
              value="on"
              checked={isActive}
              onChange={handleChange}
            />
          )}
        </Grid>

        <Grid item xs="auto">
          <TextField
            select
            id={`${id}-day`}
            name="repeat.monthly.on.day"
            aria-label="Repeat monthly on a day"
            value={on.day}
            size="small"
            fullWidth
            style={{ minWidth: 80 }}
            label={translateLabel(translations, "repeat.monthly.on_day")}
            disabled={!isActive}
            onChange={numericalFieldHandler(handleChange)}
          >
            {[...new Array(31)].map((day, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  );
};
RepeatMonthlyOn.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["on", "on the"]).isRequired,
  on: PropTypes.shape({
    day: PropTypes.number.isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatMonthlyOn;

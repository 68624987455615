import { groupBy, isEmpty, isNil, mapValues } from "lodash";
import { AssetPropertyDefinitionJSONObject } from "../../json_api/asset_property_definition";

/**
 * Groups and filters property definitions based on provided allow and deny lists for keys and groups.
 *
 * @param propertyDefinitions - Array of property definitions to be grouped and filtered.
 * @param keysAllowList - List of keys to allow. If not empty, only these keys will be included.
 * @param keysDenyList - List of keys to deny. If keysAllowList is empty and this is not empty, these keys will be excluded.
 * @param groupsAllowList - List of groups to allow. If not empty, only these groups will be included.
 * @param groupsDenyList - List of groups to deny. If groupsAllowList is empty and this is not empty, these groups will be excluded.
 * @param hidePropertiesWithoutGroup - If true, properties without a group will be hidden.
 * @returns An object where keys are group names and values are arrays of property definitions, filtered and sorted based on the provided lists.
 */
export function groupedPropertyDefinitions(
  propertyDefinitions: AssetPropertyDefinitionJSONObject[],
  keysAllowList: string[],
  keysDenyList: string[],
  groupsAllowList: string[],
  groupsDenyList: string[],
  hidePropertiesWithoutGroup: boolean,
) {
  if (!propertyDefinitions) {
    return null;
  }
  // first, filter propertyDefinitions by allowedKeys and blockedKeys
  if (!isEmpty(keysAllowList)) {
    propertyDefinitions = propertyDefinitions.filter((ap) =>
      keysAllowList.includes(ap.key),
    );
  } else if (!isEmpty(keysDenyList)) {
    propertyDefinitions = propertyDefinitions.filter(
      (ap) => !keysDenyList.includes(ap.key),
    );
  }
  // ... then group these filtered keys
  let propertyDefinitionsGrouped = groupBy(
    propertyDefinitions,
    (ap) => ap.group,
  );
  if (!isEmpty(groupsAllowList)) {
    propertyDefinitionsGrouped = mapValues(
      propertyDefinitionsGrouped,
      (apGroup) => {
        return apGroup.filter((ap) => groupsAllowList.includes(ap.group));
      },
    );
  } else if (!isEmpty(groupsDenyList)) {
    propertyDefinitionsGrouped = mapValues(
      propertyDefinitionsGrouped,
      (apGroup) => {
        return apGroup.filter((ap) => !groupsDenyList.includes(ap.group));
      },
    );
  }
  if (propertyDefinitionsGrouped.hasOwnProperty("null")) {
    propertyDefinitionsGrouped["null"] = hidePropertiesWithoutGroup
      ? []
      : propertyDefinitionsGrouped["null"];
  }
  // sort definitions within groups
  propertyDefinitionsGrouped = mapValues(
    propertyDefinitionsGrouped,
    (apGroup) => {
      return apGroup.length > 1
        ? apGroup.sort((ap1, ap2) => {
            if (isNil(ap1.position)) return 1;
            if (isNil(ap2.position)) return -1;
            return ap1.position < ap2.position ? -1 : 1;
          })
        : apGroup;
    },
  );
  return propertyDefinitionsGrouped;
}

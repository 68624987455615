import {
  compact,
  defaultTo,
  find,
  first,
  isEmpty,
  isNil,
  isNull,
} from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { SingleResourceDoc } from "jsonapi-typescript";
import { AssetJSONObject, loadAsset } from "../../json_api/asset";
import {
  AssetEventJSONAPIAttributes,
  AssetEventJSONObject,
  validateAssetEvent,
} from "../../json_api/asset_event";
import {
  ModelErrors,
  jsonApiSingleResourceToFlatObject,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import { api_asset_event_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { error } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { AssetEventTypeSelect } from "../asset_event_types/asset_event_type_select";
import { AssetTreeSelect } from "../assets/asset_tree_select";
import { MaterialUiDatePicker } from "../common/date_picker";
import { EventSeveritySelect } from "./event_severity_selection";
import { useQuery } from "@tanstack/react-query";
export interface AssetEventFormFieldsProps {
  asset?: AssetJSONObject;
  assetId?: IDType;
  assetEventId?: IDType;
  assetEvent?: AssetEventJSONAPIAttributes;
  selectableAssets?: AssetJSONObject[];
  selectFromAssetTreeWithAssetId?: IDType;
  errors?: ModelErrors<AssetEventJSONObject>;
  onChangeAssetEvent: (assetEvent: AssetEventJSONObject) => void;
  onRequestValidation?: (assetEvent: AssetEventJSONObject) => void;
}

export const AssetEventFormFields: FunctionComponent<
  AssetEventFormFieldsProps
> = (props) => {
  const isNew = React.useCallback(
    () => isNil(props.assetEventId) && isNil(props.assetEvent?.id),
    [props.assetEventId, props.assetEvent?.id],
  );

  const [asset, setAsset] = React.useState(
    props.asset ?? props.assetEvent?.asset,
  );
  const [assetEvent, setAssetEvent] = React.useState(props.assetEvent);
  const [errors, setErrors] = React.useState<ModelErrors<AssetEventJSONObject>>(
    {},
  );

  const [toExpanded, setToExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [runningLoads, setRunningLoads] = React.useState(0);

  const requestValidation = React.useCallback(
    (theAssetEvent: AssetEventJSONObject = null) => {
      const eventToValidate = defaultTo(theAssetEvent, assetEvent);
      if (props.onRequestValidation) {
        props.onRequestValidation(eventToValidate);
      } else {
        setErrors(validateAssetEvent(eventToValidate));
      }
    },
    [props.onRequestValidation, assetEvent],
  );

  React.useEffect(() => {
    setErrors(defaultTo(props.errors, {}));
  }, [props.errors]);

  const assetQuery = useQuery({
    queryKey: ["asset", { id: props.assetId }],
    queryFn: () => {
      if (props.assetId) {
        return loadAsset(props.assetId);
      }
    },
    enabled: isNil(asset) && !isNil(props.assetId),
  });

  // determine load state
  React.useEffect(() => {
    setLoading(runningLoads > 0);
  }, [assetQuery.isLoading]);

  React.useEffect(() => {
    if (assetQuery.data) {
      setAsset(assetQuery.data);
    }
  }, [assetQuery.data]);

  React.useEffect(() => {
    setAssetEvent({ ...assetEvent, asset_id: asset?.id, item_id: asset?.id });
    setEditDisabled(false);
  }, [asset]);

  React.useEffect(() => {
    if (assetEvent != props.assetEvent) {
      props.onChangeAssetEvent(assetEvent);
    }
    setErrors({});
  }, [assetEvent, props.assetEvent]);

  React.useEffect(() => {
    if (
      (isNil(props.assetEventId) && isNil(props.assetEvent)) ||
      isNull(props.assetEvent)
    ) {
      // initialize asset event as none is given and no event to load by id
      setAssetEvent({
        asset_id: props.assetId || asset?.id,
        item_id: props.assetId,
        item_type: "Asset",
        from: new Date().toISOString(), // now
        severity_level: "info", //
      });
    } else {
      if (
        isNil(props.assetEvent) ||
        (props.assetEvent?.id != props.assetEventId &&
          !isNil(props.assetEventId))
      )
        setRunningLoads(runningLoads + 1);
      void loadDataFromUrl<
        SingleResourceDoc<string, AssetEventJSONAPIAttributes>
      >(
        api_asset_event_path(props.assetEventId, {
          id: props.assetEventId,
          format: "json",
          include: "asset,root_asset,event_type",
          _options: true,
        }),
      )
        .then((assetEvent) => {
          const assetEventObj = jsonApiSingleResourceToFlatObject(assetEvent);
          setAsset(assetEventObj.asset);
          setAssetEvent(assetEventObj);
        })
        .catch((e) => {
          error(
            I18n.t("frontend.error"),
            I18n.t(
              "frontend.asset_events.asset_event_form.error_loading_event",
            ),
          );
        })
        .finally(() => {
          setRunningLoads(runningLoads - 1);
        });
    }
  }, [props.assetEventId]);

  const [editDisabled, setEditDisabled] = React.useState(true);

  React.useEffect(() => {
    setEditDisabled(isNil(asset) || loading);
  }, [asset, loading]);
  const title = isNew()
    ? I18n.t("frontend.asset_events.asset_event_form.title_new")
    : I18n.t("frontend.asset_events.asset_event_form.title_edit");

  return (
    <>
      {loading ? (
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rectangular" height={50} />
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} pb={2}>
            {!isNew() && asset ? (
              <Typography>
                {I18n.t("activerecord.models.asset.one")}: {asset.name}
              </Typography>
            ) : !isNil(props.selectFromAssetTreeWithAssetId) ? (
              <>
                <Typography>
                  {I18n.t(
                    "frontend.asset_events.asset_event_form.select_asset",
                  )}
                </Typography>
                <AssetTreeSelect
                  assetId={props.selectFromAssetTreeWithAssetId}
                  multiselect={false}
                  maxHeight={300}
                  onSelectionChange={(newSelection) => {
                    const selectedAssetId = first(newSelection);

                    if (isNil(selectedAssetId)) {
                      setAsset(null);
                    } else {
                      const asset = find(
                        props.selectableAssets,
                        (a) => a.id == selectedAssetId,
                      );
                      setAsset(asset);
                    }
                  }}
                />
              </>
            ) : props.selectableAssets ? (
              <TextField
                size="small"
                select
                fullWidth
                label={I18n.t(
                  "frontend.asset_events.asset_event_list.asset_for_event",
                )}
                helperText={I18n.t(
                  "frontend.asset_events.asset_event_list.asset_for_event_helper_text",
                )}
                value={defaultTo(asset?.id || props.assetId, null)}
                onChange={(event) => {
                  const selectedAsset = find(
                    props.selectableAssets,
                    (a) => a.id == event.target.value,
                  );
                  if (selectedAsset) {
                    setAsset(selectedAsset);
                  }
                }}
              >
                {props.selectableAssets?.map((a, index) => (
                  <MenuItem value={a.id} key={index}>
                    {a.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {editDisabled ? (
              <Grow in={editDisabled}>
                <Typography variant="body2">
                  {I18n.t(
                    "frontend.asset_events.asset_event_form.select_asset_to_enable_editing",
                  )}
                </Typography>
              </Grow>
            ) : null}
          </Grid>
        </>
      )}

      <>
        <Grid item xs={12}>
          <AssetEventTypeSelect
            eventTypeLoadFilter={{ category: ["user", "asset", ""] }}
            selectedTypeSlug="generic"
            loadingTreeMode="asset"
            required
            assetId={asset?.id || props.assetId}
            selectedTypeId={
              assetEvent?.event_type?.id || assetEvent?.event_type_id
            }
            disabled={editDisabled}
            onTypeSelect={(type) => {
              setAssetEvent({
                ...assetEvent,
                event_type: type,
                event_type_id: type?.id,
              });
            }}
            error={compact([
              modelPropertyError(errors, "event_type"),
              modelPropertyError(errors, "event_type_id"),
            ]).join()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onBlur={() => requestValidation()}
            fullWidth
            size="small"
            required
            disabled={editDisabled}
            label={I18n.t("activerecord.attributes.asset_event.name")}
            onChange={(e) =>
              setAssetEvent({ ...assetEvent, name: e.target.value })
            }
            value={assetEvent?.name || ""}
            error={!isEmpty(errors.name)}
            helperText={modelPropertyError(errors, "name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onBlur={() => requestValidation()}
            fullWidth
            type="text"
            multiline
            disabled={editDisabled}
            size="small"
            label={I18n.t("activerecord.attributes.asset_event.description")}
            onChange={(e) =>
              setAssetEvent({
                ...assetEvent,
                description: e.target.value,
              })
            }
            value={assetEvent?.description || ""}
            error={!isEmpty(errors.description)}
            helperText={isEmpty(errors.description) ? null : errors.description}
          />
        </Grid>
        <Grid item xs={12}>
          <MaterialUiDatePicker
            disabled={editDisabled}
            required
            type="datetime"
            label={I18n.t("activerecord.attributes.asset_event.from")}
            helperText={I18n.t(
              "frontend.asset_events.asset_event_form.from_helper_text",
            )}
            dateFormat="L LT"
            value={assetEvent?.from ? moment(assetEvent.from) : null}
            onChange={(date) => {
              const event = {
                ...assetEvent,
                from: date?.toISOString(),
              };
              requestValidation(event);
              setAssetEvent(event);
            }}
            error={modelPropertyError(errors, "from")}
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded={false}
            expanded={toExpanded}
            onChange={(event, expaned) => setToExpanded(expaned)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="with-to-date"
            >
              {I18n.t(
                "frontend.asset_events.asset_event_form.with_to_accordion",
              )}
            </AccordionSummary>
            <AccordionDetails>
              <MaterialUiDatePicker
                disabled={editDisabled}
                type="datetime"
                label={I18n.t("activerecord.attributes.asset_event.to")}
                dateFormat="L LT"
                value={isNil(assetEvent?.to) ? null : moment(assetEvent.to)}
                helperText={I18n.t(
                  "frontend.asset_events.asset_event_form.to_helper_text",
                )}
                minDate={
                  isNil(assetEvent?.from) ? null : moment(assetEvent.from)
                }
                onChange={(date) => {
                  const event = {
                    ...assetEvent,
                    to: date?.toISOString(),
                  };
                  requestValidation(event);
                  setAssetEvent(event);
                }}
                endAdornment={
                  <Tooltip
                    title={I18n.t(
                      "frontend.asset_events.asset_event_form.remove_to",
                    )}
                  >
                    <IconButton
                      onClick={() => {
                        setAssetEvent({ ...assetEvent, to: null });
                      }}
                      size="small"
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                }
                error={modelPropertyError(errors, "to")}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <EventSeveritySelect
            onBlur={() => {
              requestValidation();
            }}
            disabled={editDisabled}
            required
            severity={assetEvent?.severity_level || null}
            onSeveritySelect={(sl) => {
              setAssetEvent({ ...assetEvent, severity_level: sl });
            }}
            error={modelPropertyError(errors, "severity_level")}
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="with-additional-options"
            >
              {I18n.t("frontend.asset_events.asset_event_form.event_details")}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onBlur={() => {
                      requestValidation();
                    }}
                    disabled={editDisabled}
                    label={I18n.t("activerecord.attributes.asset_event.code")}
                    size="small"
                    fullWidth
                    value={assetEvent?.code ?? ""}
                    onChange={(e) =>
                      setAssetEvent({
                        ...assetEvent,
                        code: e.target.value,
                      })
                    }
                    error={!isEmpty(errors.code)}
                    helperText={
                      isEmpty(errors.code)
                        ? I18n.t(
                            "frontend.asset_events.asset_event_form.code_helper_text",
                          )
                        : modelPropertyError(errors, "code")
                    }
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onBlur={() => requestValidation()}
                    fullWidth
                    label={I18n.t(
                      "activerecord.attributes.asset_event.message",
                    )}
                    disabled={editDisabled}
                    size="small"
                    type="text"
                    value={assetEvent?.message ?? ""}
                    onChange={(e) =>
                      setAssetEvent({
                        ...assetEvent,
                        message: e.target.value,
                      })
                    }
                    multiline
                    error={!isEmpty(errors.message)}
                    helperText={
                      isEmpty(errors.message)
                        ? I18n.t(
                            "frontend.asset_events.asset_event_form.message_helper_text",
                          )
                        : modelPropertyError(errors, "code")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onBlur={() => requestValidation()}
                    label={I18n.t("activerecord.attributes.asset_event.action")}
                    fullWidth
                    size="small"
                    type="text"
                    multiline
                    value={assetEvent?.action ?? ""}
                    onChange={(e) =>
                      setAssetEvent({
                        ...assetEvent,
                        action: e.target.value,
                      })
                    }
                    error={!isEmpty(errors.action)}
                    helperText={
                      isEmpty(errors.message)
                        ? I18n.t(
                            "frontend.asset_events.asset_event_form.action_helper_text",
                          )
                        : errors.message
                    }
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>
    </>
  );
};

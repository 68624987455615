import { isNil } from "lodash";

export function percentageValue(value: number, min = 0, max = 100): number {
  if (isNil(value)) {
    return 0.0;
  }
  const delta = Math.abs(max - min);
  const minDelta = value - min;
  return minDelta / (delta / 100.0);
}

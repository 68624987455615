/**
 * Helper class to compute linear value trends
 * @member {number} slope Slope of linear equation (m)
 * @member {number} intercept Intercept of linear equation (n)
 */
export class ValueTrend {
  /**
   * Default constructor
   * @param {number} slope Slope of linear equation (m)
   * @param {number} intercept Intercept of linear equation (n)
   */
  constructor(
    public slope: number,
    public intercept: number,
  ) {}

  /**
   * Return value on trend line at certain point in time
   * @param {number} x A value on the x axis
   * @return {number} y value for x value
   */
  getY(x: number): number {
    return x * this.slope + this.intercept;
  }

  /**
   * Return x value for a certain y value
   * @param {number} y A value on the y axis
   * @return {number} x alue
   */
  getX(y: number): number {
    return (y - this.intercept) / this.slope;
  }
}

/**
 * Helper class to compute time based linear value trends
 * @member {number} slope Slope of linear equation (m)
 * @member {number} intercept Intercept of linear equation (n)
 */
export class DateValueTrend {
  /**
   * Default constructor
   * @param {number} slope Slope of linear equation (m)
   * @param {number} intercept Intercept of linear equation (n)
   */
  constructor(slope: number, intercept: number) {
    this.valueTrend = new ValueTrend(slope, intercept);
  }

  /**
   * Return value on trend line at certain point in time
   * @param {Date}timestamp A point in time
   * @return {number} Value of trend line at point in time
   */
  getValue(timestamp: Date): number {
    return this.valueTrend.getY((timestamp.getTime() * 0.001) / 3600);
  }

  /**
   * Return date for a certain value
   * @param {number} value A value
   * @return {Date} Date where the trend has the value
   */
  getDate(value: number): Date {
    return new Date(this.valueTrend.getX(value) * 1000.0 * 3600);
  }

  private valueTrend: ValueTrend;
}

import { Box } from "@mui/material";
import * as React from "react";
type FixedBottomAreaProps = React.HTMLAttributes<HTMLDivElement>;

export const FixedBottomArea: React.FunctionComponent<FixedBottomAreaProps> = (
  props,
) => (
  <Box displayPrint="none" id="fixed-bottom-area">
    {props.children}
  </Box>
);
